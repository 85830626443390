import * as Constants from "../constants";

const initialState = {
  value: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
  case Constants.POS_TOTAL_BALANCE_SUCCESS:
    return {
      ...state,
      value: action.payload
    };
  default:
    return state;
  }
}