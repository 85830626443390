export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_REQUEST_SUCCESS";
export const NOTIFICATION_LIST_NEXT_PAGE = "NOTIFICATION_LIST_NEXT_PAGE";

export const NOTIFICATION_UNREAD_COUNT_REQUEST = "NOTIFICATION_UNREAD_COUNT_REQUEST";
export const NOTIFICATION_UNREAD_COUNT_SUCCESS = "NOTIFICATION_UNREAD_COUNT_SUCCESS";

export const NOTIFICATION_READ_REQUEST = "NOTIFICATION_READ_REQUEST";
export const NOTIFICATION_READ_SUCCESS = "NOTIFICATION_READ_SUCCESS";

export const NOTIFICATION_DID_RECEIVE = "NOTIFICATION_DID_RECEIVE";

export const NOTIFICATION_ALL_LIST_REQUEST = "NOTIFICATION_ALL_LIST_REQUEST";
export const NOTIFICATION_ALL_LIST_SUCCESS = "NOTIFICATION_ALL_LIST_SUCCESS";

export const NOTIFICATION_MARK_ALL_READ_REQUEST = "NOTIFICATION_MARK_ALL_READ_REQUEST";
export const NOTIFICATION_MARK_ALL_READ_SUCCESS = "NOTIFICATION_MARK_ALL_READ_SUCCESS";

export const NOTIFICATION_USER_BLOCK = {
  username: "Anonymous (Deleted User)",
  lastName: "(Deleted User)",
};

// Human Resource
export const HUMAN_RESOURCE_PATH = "/human-resource";
export const APPRAISAL_PATH = `${HUMAN_RESOURCE_PATH}/appraisal`;
export const LEAVE_PATH = `${HUMAN_RESOURCE_PATH}/leave`;
export const RECRUITMENT_PATH = `${HUMAN_RESOURCE_PATH}/recruitment`;
export const EMPLOYEE_DIRECTORY_PATH = `${HUMAN_RESOURCE_PATH}/employee-directory`;
export const PAYROLL_PATH = `${HUMAN_RESOURCE_PATH}/payroll`;
export const ATTENDANCE_PATH = `${HUMAN_RESOURCE_PATH}/attendance`;
export const CASH_ADVANCE_PATH = `${HUMAN_RESOURCE_PATH}/cash-advance`;

// Inventory
export const INVENTORY_PATH = "/inventory";
export const ITEM_CATALOG_PATH = `${INVENTORY_PATH}/items`;
export const STOCK_PATH = `${INVENTORY_PATH}/stock`;
export const ASSET_PATH = `${INVENTORY_PATH}/asset`;
export const DELIVERY_PATH = `${INVENTORY_PATH}/delivery`;

// Purchase
export const PURCHASE_PATH = "/purchase";
export const PURCHASE_LIST_PATH = `${PURCHASE_PATH}/list`;

// Sale
export const SALE_PATH = "/sale";
export const SALE_LIST_PATH = `${SALE_PATH}/sale-center`;
export const CUSTOMER_CENTER_PATH = `${SALE_PATH}/customer-center`;
export const SET_UP_PATH = `${SALE_PATH}/setup`;
export const SALE_SERVICE_PATH = `${SALE_PATH}/service`;

// Setting
export const SETTING_PATH = "/settings";
export const SETTING_PATH_USER = `${SETTING_PATH}/user-management`;
export const SETTING_PATH_EMAIL = `${SETTING_PATH}/email`;
export const SETTING_PATH_SET_UP = `${SETTING_PATH}/setup`;
export const SETTING_PATH_INTEGRATION = `${SETTING_PATH}/integrations`;

// Account
export const ACCOUNTING_PATH = "/accounting";
export const ACCOUNTING_PATH_LIST = `${ACCOUNTING_PATH}/list`;
export const ACCOUNTING_PATH_BANK = `${ACCOUNTING_PATH}/bank`;

// Website
export const WEBSITE_PATH = "/website";
export const WEBSITE_PATH_BANNER = `${WEBSITE_PATH}/store-front`;
export const WEBSITE_PATH_ORDER = `${WEBSITE_PATH}/e-commerce`;

// General start from x
export const NOTIFICATION_TYPE_UPDATE = 1;
export const NOTIFICATION_TYPE_DELETE = 2;
export const NOTIFICATION_TYPE_ARCHIVE = 3;
export const NOTIFICATION_TYPE_UNARCHIVE = 4;
export const NOTIFICATION_TYPE_COMMENT = 5;
export const NOTIFICATION_TYPE_REQUEST = 6;
export const NOTIFICATION_TYPE_APPROVE = 7;
export const NOTIFICATION_TYPE_CREATE = 9;

export const EMPLOYEE_ATTENDANCE_REQUEST_FOR_APPROVAL = 300;
export const EMPLOYEE_ATTENDANCE_REQUEST_FOR_JOIN = 301;
export const EMPLOYEE_ATTENDANCE_HAS_BEEN_REJECT = 302;
export const EMPLOYEE_ATTENDANCE_HAS_BEEN_APPROVE = 303;

export const NOTIFICATION_GENERAL_RANGE = [
  NOTIFICATION_TYPE_UPDATE,
  NOTIFICATION_TYPE_DELETE,
  NOTIFICATION_TYPE_ARCHIVE,
  NOTIFICATION_TYPE_UNARCHIVE,
  NOTIFICATION_TYPE_COMMENT,
  EMPLOYEE_ATTENDANCE_REQUEST_FOR_APPROVAL,
  EMPLOYEE_ATTENDANCE_REQUEST_FOR_JOIN,
  EMPLOYEE_ATTENDANCE_HAS_BEEN_REJECT,
  EMPLOYEE_ATTENDANCE_HAS_BEEN_APPROVE,
  NOTIFICATION_TYPE_CREATE
];

// LeaveAllocation start from 3x
export const NOTIFICATION_TYPE_LEAVE_ALLOCATION_SUBMIT = 30;
export const NOTIFICATION_TYPE_LEAVE_ALLOCATION_REJECT = 31;
export const NOTIFICATION_TYPE_LEAVE_ALLOCATION_APPROVE = 32;
export const NOTIFICATION_TYPE_LEAVE_ALLOCATION_CANCEL = 33;

// LeaveRequest start from 5x
export const NOTIFICATION_TYPE_LEAVE_REQUEST_SUBMIT = 50;
export const NOTIFICATION_TYPE_LEAVE_REQUEST_APPROVE = 51;
export const NOTIFICATION_TYPE_LEAVE_REQUEST_REJECT = 52;
export const NOTIFICATION_TYPE_LEAVE_REQUEST_CANCEL = 53;

// OfferLetter start from 7x
export const NOTIFICATION_TYPE_OFFER_LETTER_SUBMIT = 70;
export const NOTIFICATION_TYPE_OFFER_LETTER_APPROVE = 71;
export const NOTIFICATION_TYPE_OFFER_LETTER_REJECT = 72;
export const NOTIFICATION_TYPE_OFFER_LETTER_CANCEL = 73;

// Inventory start from 1xx
// StockIn start from 10x
export const NOTIFICATION_TYPE_STOCK_IN_REQUEST = 100;
export const NOTIFICATION_TYPE_STOCK_IN_APPROVE = 101;
export const NOTIFICATION_TYPE_STOCK_IN_REJECT = 102;
export const NOTIFICATION_TYPE_STOCK_IN_CANCEL = 103;

// StockOut start from 13x
export const NOTIFICATION_TYPE_STOCK_OUT_REQUEST = 130;
export const NOTIFICATION_TYPE_STOCK_OUT_APPROVE = 131;
export const NOTIFICATION_TYPE_STOCK_OUT_REJECT = 132;
export const NOTIFICATION_TYPE_STOCK_OUT_CANCEL = 133;

// StockTransfer start from 15x
export const NOTIFICATION_TYPE_STOCK_TRANSFER_REQUEST = 150;
export const NOTIFICATION_TYPE_STOCK_TRANSFER_APPROVE = 151;
export const NOTIFICATION_TYPE_STOCK_TRANSFER_REJECT = 152;
export const NOTIFICATION_TYPE_STOCK_TRANSFER_CANCEL = 153;

// StockAdjustment start from 17x
export const NOTIFICATION_TYPE_STOCK_ADJUSTMENT_REQUEST = 170;
export const NOTIFICATION_TYPE_STOCK_ADJUSTMENT_APPROVE = 171;
export const NOTIFICATION_TYPE_STOCK_ADJUSTMENT_REJECT = 172;
export const NOTIFICATION_TYPE_STOCK_ADJUSTMENT_CANCEL = 173;

// DeliveryOrder
export const NOTIFICATION_TYPE_DELIVERY_ORDER_ASSIGN = 180;

// AssetRequest start from 19x
export const NOTIFICATION_TYPE_ASSET_REQUEST_REQUEST = 190;
export const NOTIFICATION_TYPE_ASSET_REQUEST_APPROVE = 191;
export const NOTIFICATION_TYPE_ASSET_REQUEST_REJECT = 192;
export const NOTIFICATION_TYPE_ASSET_REQUEST_CANCEL = 193;

// Purchase start from 2xx
// PurchaseOrder start from 20x
export const NOTIFICATION_TYPE_PURCHASE_ORDER_REQUEST = 200;
export const NOTIFICATION_TYPE_PURCHASE_ORDER_APPROVE = 201;
export const NOTIFICATION_TYPE_PURCHASE_ORDER_REJECT = 202;
export const NOTIFICATION_TYPE_PURCHASE_ORDER_CANCEL = 203;
export const NOTIFICATION_TYPE_PURCHASE_ORDER_FORCE_CLOSE = 204;
export const NOTIFICATION_TYPE_PURCHASE_ORDER_PROCESS = 205;

// Accounting Start from 3xx
// Expense
export const NOTIFICATION_TYPE_EXPENSE_SUBMIT = 700;
export const NOTIFICATION_TYPE_EXPENSE_APPROVE = 701;
export const NOTIFICATION_TYPE_EXPENSE_REJECT = 702;
export const NOTIFICATION_TYPE_EXPENSE_CANCEL = 703;

// Junk Out Start from 4xx
export const NOTIFICATION_TYPE_JUNK_OUT_REQUEST = 400;
export const NOTIFICATION_TYPE_JUNK_OUT_APPROVE = 401;
export const NOTIFICATION_TYPE_JUNK_OUT_REJECT = 402;
export const NOTIFICATION_TYPE_JUNK_OUT_CANCEL = 403;

export const NOTIFICATION_TYPE_QUOTATION_REQUEST_APPROVAL = 501;
export const NOTIFICATION_TYPE_QUOTATION_APPROVE = 502;
export const NOTIFICATION_TYPE_QUOTATION_REJECT = 503;