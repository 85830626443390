import { EMPLOYEE_CONTACT_SUCCESS } from "../constants";

const initialState = {
  data: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
  case EMPLOYEE_CONTACT_SUCCESS:
    return {
      ...state,
      [action.employeeId]: action.employeeContact
    };
  default:
    return state;
  }
}