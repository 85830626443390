import React, { Fragment } from "react";
import PropTypes from "prop-types";
import arrayMove from "array-move";
import { useDispatch } from "react-redux";
import { Table, Popconfirm } from "antd";
import { DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { 
  sortableContainer, 
  sortableElement, 
  sortableHandle 
} from "react-sortable-hoc";

import { 
  getBooleanString, 
  getQueryParamUrl 
} from "@modules/helpers";
import { getFilter } from "@/services/filter";
import { editFilter, reorderFilter, deleteFilter } from "@/actions/filter";
import { MenuOutlined } from "@ant-design/icons";

import "./index.less";

const SortableItem = sortableElement(props => <tr style={{color: "red"}} {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: "grab" }} />);

function ManageFilter(props) {

  const [dataSource, setDataSource] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const { entity } = props;

  React.useEffect(() => {
    onGetFilterLists();
  }, []);

  const onGetFilterLists = () => {
    setLoading(true);
    getFilter(entity).then((response) => {
      if (response != null) {
        setLoading(false);
        setDataSource(response);
      }
    }).catch(() => {
      setLoading(false);
      setDataSource([]);
    });
  };

  const query = getQueryParamUrl();
  const view = query.view;

  const dispatch = useDispatch();

  const onRemoveClick = (id) => {
    const onAction = dispatch(deleteFilter(id, entity));
    if (onAction != null) {
      onAction.then(() => {
        onGetFilterLists();
      });
    }
  };

  const setAsDefaultClick = (values) => {
    const newValues = {...values};
    newValues.entity = entity;
    newValues.isDefault = true;
    newValues.noUpdateUrl = true;
    const onAction = dispatch(editFilter(newValues));
    if (onAction != null) {
      onAction.then((response) => {
        if (response?.error == null) {
          onGetFilterLists();
        }
      });
    }
  };

  const columns = [
    {
      title: "Order",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      width: "40%",
      dataIndex: "name",
      className: "drag-visible",
    },
    {
      title: "Is Default",
      dataIndex: "isDefault",
      width: "10%",
      render: (isDefault) => getBooleanString(isDefault, true)
    },
    {
      title: "Actions",
      width: "30%",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <div>
            <a 
              disabled={row.isDefault} 
              onClick={() => setAsDefaultClick(row)}
              className={row.isDefault ? "text-mute" : "text-primary"} 
            >
              <CheckCircleOutlined /> Set as Default 
            </a> 
            <span className="mx-2">|</span>
            <Popconfirm 
              okText="Yes"
              cancelText="No" 
              title="Are you sure ?" 
              disabled={view == row.slug} 
              onConfirm={() => onRemoveClick(id)}
            >
              <a className={view == row.slug ? "text-mute" : "text-danger"}>
                <DeleteOutlined /> Remove 
              </a>
            </Popconfirm>
          </div>
        );
      }
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(dataSource, oldIndex, newIndex);
      const newValues = newData.map((value, index) => {
        const newValue = {};
        newValue.id = value.id;
        newValue.order = index + 1;
        return newValue;
      });
      dispatch(reorderFilter(newValues));
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      onSortEnd={onSortEnd}
      helperClass="row-dragging"
      {...props}
    />
  );

  const DraggableBodyRow = (restProps) => {
    const index = dataSource.findIndex(x => x.id === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Fragment>
      <Table 
        size="middle"
        columns={columns} 
        style={{
          zIndex: 0
        }}
        pagination={false}
        dataSource={dataSource} 
        rowKey={(value) => value.id}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        loading={{
          spinning: loading,
          tip: "Loading..."
        }}
      />
    </Fragment>
  );
}

ManageFilter.propTypes = {
  entity: PropTypes.string,
};

export default ManageFilter;