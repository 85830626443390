import {
  GETList, 
  GETDetail,
  PUTWithToken,
  GETWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";

// Helper
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/employee/v1";
const EDUCATION_URL = "/api/employee/education/v1";
const JOB_HISTORY_API_URL = "/api/job/history/v1";
const WORK_EXPERIENCE_URL = "/api/employee/experience/v1";
const LANGUAGE_URL = "/api/employee/language/v1";
const EMPLOYEE_PAYROLL_URL = "/api/employee/payroll/v1";
const SALE_COMMISSION_URL = "/api/invoice/v1/sale-commission";
const INVOICE_URL = "/api/invoice/v1/by";
const EMPLOYEE_PAYROLL_INCENTIVE_URL = "/api/employee-payroll-incentive/v1";

export const getEmployee = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function getEmployeeAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function createEmployee(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getEmployeeDetail(id) {
  return GETDetail(API_URL, id);
}

export function editEmployee(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveEmployee(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveEmployee(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteEmployee(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function terminateEmployee(id) {
  const url = `${API_URL}/${id}/terminate`;
  return PUTWithToken(url);
}

export function activeEmployee(id) {
  const url = `${API_URL}/${id}/active`;
  return PUTWithToken(url);
}

export function promoteEmployee(values) {
  const { employeeId } = values;
  const url = `${API_URL}/promote/${employeeId}`;
  return PUTWithToken(url, values);
}

export function getEmployeeJobHistory(id) {
  const url = `${JOB_HISTORY_API_URL}/list?employeeId=${id}`;
  return GETWithToken(url);
}

export function checkEmployeeCode(values) {
  const url = `${API_URL}/check-employee-code`;
  return POSTWithToken(url, values);
}

export function getEmployeeContact(id) {
  const url = `${API_URL}/${id}/contact`;
  return GETWithToken(url);
}

export function updateEmployeeConatct(values) {
  const url = `${API_URL}/${values.employeeId}/contact`;
  return PUTWithToken(url, values);
}

export function getEducation(id) {
  const url = `${EDUCATION_URL}/list/${id}`;
  return GETWithToken(url);
}

export function createEducation(values) {
  const url = `${EDUCATION_URL}/create`;
  return POSTWithToken(url, values);
}

export function editEducation(values) {
  const { id } = values;
  const url = `${EDUCATION_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function deleteEducation(id) {
  const url = `${EDUCATION_URL}/${id}`;
  return DELETEWithToken(url);
}

export function getWorkExperience(id) {
  const url = `${WORK_EXPERIENCE_URL}/${id}`;
  return GETWithToken(url);
}

export function createWorkExperience(values) {
  const url = `${WORK_EXPERIENCE_URL}/create`;
  return POSTWithToken(url, values);
}

export function editWorkExperience(values) {
  const { id } = values;
  const url = `${WORK_EXPERIENCE_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function deleteWorkExperience(id) {
  const url = `${WORK_EXPERIENCE_URL}/${id}`;
  return DELETEWithToken(url);
}

export function getLanguage(id) {
  const url = `${LANGUAGE_URL}/${id}`;
  return GETWithToken(url);
}

export function createLanguage(values) {
  const url = `${LANGUAGE_URL}/create`;
  return POSTWithToken(url, values);
}

export function editLanguage(values) {
  const { id } = values;
  const url = `${LANGUAGE_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function deleteLanguage(id) {
  const url = `${LANGUAGE_URL}/${id}`;
  return DELETEWithToken(url);
}

export function getEmployeePayroll(id) {
  const url = `${EMPLOYEE_PAYROLL_URL}/by-employee/${id}`;
  return GETWithToken(url);
}

export function createEmployeePayroll(values) {
  const url = `${EMPLOYEE_PAYROLL_URL}/create`;
  return POSTWithToken(url, values);
}

export function editEmployeePayroll(values) {
  const { employeeId } = values;
  const url = `${EMPLOYEE_PAYROLL_URL}/${employeeId}`;
  return PUTWithToken(url, values);
}

export function getEmploymentCardAndSignature(id) {
  const url = `${API_URL}/${id}/employment-card`;
  return GETWithToken(url);
}

export function editEmploymentCardAndSignature(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/employment-card`;
  return PUTWithToken(url, values);
}

export function getEmployeeLeaveSetting(id) {
  const url = `${API_URL}/${id}/leave/setting`;
  return GETWithToken(url, null);
}

export function getLeaveBalanceHistory(values) {
  const { leaveTypeId, employeeId } = values;
  const url = `/api/leave-balance/v1/${leaveTypeId}/${employeeId}/history`;
  return GETWithToken(url, null);
}

export function editEmployeeLeaveSetting(values) {
  const { employeeId } = values;
  const url = `${API_URL}/${employeeId}/leave/setting`;
  return POSTWithToken(url, values);
}

export function getEmployeeSaleCommission(values) {
  const { id } = values;
  const url = `${INVOICE_URL}/${id}`;
  values = getDefaultCurrentDateRange(values, "from", "to", "month");
  return GETList(url, values);
}

export function updateEmployeeSaleCommission(values) {
  const { invoiceItemId } = values;
  const url = `${SALE_COMMISSION_URL}/${invoiceItemId}`;
  return PUTWithToken(url, values);
}

export function getEmployeePayrollIncentive(values) {
  const { employeeId, payslipId } = values;
  const url = `${EMPLOYEE_PAYROLL_INCENTIVE_URL}/employee/${employeeId}/${payslipId}`;
  return GETWithToken(url);
}

export function createEmployeePayrollIncentive(values) {
  const url = `${EMPLOYEE_PAYROLL_INCENTIVE_URL}/create/payslip-sale-incentive`;
  return POSTWithToken(url, values);
}

export function getTotalSaleHistory(values) {
  const url = "/api/employee/v1/total-commission-sale";
  return GETWithToken(url, values);
}