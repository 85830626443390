import * as ActivityConstants from "@/constants/activity-log";

const initialState = {
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ActivityConstants.ACTIVITY_LOG_SUCCESS:
    return {
      ...state,
      data: action.payload
    };
  case ActivityConstants.ACTIVITY_LOG_SUCCESS_NEXT:
    return {
      ...state,
      data: [
        ...state.data, 
        ...action.payload
      ]
    };
  default:
    return state;
  }
};