export const WORKING_DAY_LIST_REQUEST = "WORKING_DAY_LIST_REQUEST"; 
export const WORKING_DAY_LIST_SUCCESS = "WORKING_DAY_LIST_REQUEST_SUCCESS";
export const WORKING_DAY_CREATE_REQUEST = "WORKING_DAY_CREATE_REQUEST"; 
export const WORKING_DAY_DETAIL_REQUEST = "WORKING_DAY_DETAIL_REQUEST"; 
export const WORKING_DAY_ARCHIVE_REQUEST = "WORKING_DAY_ARCHIVE_REQUEST"; 
export const WORKING_DAY_DELETE_REQUEST = "WORKING_DAY_DELETE_REQUEST"; 
export const WORKING_DAY_UNARCHIVE_REQUEST = "WORKING_DAY_UNARCHIVE_REQUEST"; 
export const WORKING_DAY_EDIT_REQUEST = "WORKING_DAY_EDIT_REQUEST"; 
export const WORKING_DAY_CREATE_FORM_NAME = "createWorkingDay"; 
export const WORKING_DAY_EDIT_FORM_NAME = "editWorkingDay"; 
export const WORKING_DAY_AUTOCOMPLETE_REQUEST = "WORKING_DAY_AUTOCOMPLETE_REQUEST";
export const WORKING_DAY_AUTOCOMPLETE_SUCCESS = "WORKING_DAY_AUTOCOMPLETE_SUCCESS";
export const WORKING_DAY_SUBMIT_RULES = {
  name: {
    required: true,
  },
  workingHours: {
    required: true,
  }
};
export const READ_WORKING_DAY = 136000;
export const CREATE_WORKING_DAY = 136001;
export const UPDATE_WORKING_DAY = 136002;
export const DELETE_WORKING_DAY = 136003;
export const ARCHIVE_WORKING_DAY = 136004;
export const UNARCHIVE_WORKING_DAY = 136005;