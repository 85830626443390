const INVENTORY_PATH = "/inventory";

const ITEM_CATALOG_PATH = "items";
const STOCK_PATH = "stock";
const ASSET_PATH = "asset";

export const inventory = [
  //item catalog
  {
    name: "Item Specification Template List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification-template`,
  },
  {
    name: "Item Specification Template Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification-template`,
  },
  {
    name: "Create Item Specification Template",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification-template`,
  },
  {
    name: "Edit Item Specification Template",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification-template`,
  },
  {
    name: "Item Specification List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification`,
  },
  {
    name: "Item Specification Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification`,
  },
  {
    name: "Create Item Specification",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification`,
  },
  {
    name: "Edit Item Specification",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item-specification`,
  },
  {
    name: "Item List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item`,
  },
  {
    name: "Item Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item`,
  },
  {
    name: "Create Item",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item`,
  },
  {
    name: "Edit Item",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/item`,
  },
  {
    name: "Customize Item List",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/customize-item`,
  },
  {
    name: "Customize Item Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/customize-item`,
  },
  {
    name: "Create Customize Item",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/customize-item`,
  },
  {
    name: "Edit Customize Item",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/customize-item`,
  },
  {
    name: "Attribute List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/attribute`,
  },
  {
    name: "Attribute Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/attribute`,
  },
  {
    name: "Create Attribute",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/attribute`,
  },
  {
    name: "Edit Attribute",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/attribute`,
  },
  {
    name: "Price List List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/price-list`,
  },
  {
    name: "Price List Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/price-list`,
  },
  {
    name: "Create Price List",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/price-list`,
  },
  {
    name: "Edit Price List",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/price-list`,
  },
  {
    name: "Department List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/department`,
  },
  {
    name: "Department Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/department`,
  },
  {
    name: "Create Department",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/department`,
  },
  {
    name: "Edit Department",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/department`,
  },
  {
    name: "Category List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/category`,
  },
  {
    name: "Category Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/category`,
  },
  {
    name: "Create Category",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/category`,
  },
  {
    name: "Edit Category",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/category`,
  },
  {
    name: "Brand List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/brand`,
  },
  {
    name: "Brand Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/brand`,
  },
  {
    name: "Create Brand",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/brand`,
  },
  {
    name: "Edit Brand",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/brand`,
  },
  {
    name: "Tag List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/tag`,
  },
  {
    name: "Tag Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/tag`,
  },
  {
    name: "Create Tag",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/tag`,
  },
  {
    name: "Edit Tag",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/tag`,
  },
  {
    name: "Supplier List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier`,
  },
  {
    name: "Supplier Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier`,
  },
  {
    name: "Create Supplier",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier`,
  },
  {
    name: "Edit Supplier",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier`,
  },
  {
    name: "Supplier Type List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier-type`,
  },
  {
    name: "Supplier Type Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier-type`,
  },
  {
    name: "Create Supplier Type",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier-type`,
  },
  {
    name: "Edit Supplier Type",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/supplier-type`,
  },
  {
    name: "Warranty List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/warranty`,
  },
  {
    name: "Warranty Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/warranty`,
  },
  {
    name: "Create Warranty",
    type: "create",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/warranty`,
  },
  {
    name: "Edit Warranty",
    type: "edit",
    slug: `${INVENTORY_PATH}/${ITEM_CATALOG_PATH}/warranty`,
  },
  //stock
  {
    name: "Warehouse List",
    type: "list",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/warehouse`,
  },
  {
    name: "Warehouse Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/warehouse`,
  },
  {
    name: "Create Warehouse",
    type: "create",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/warehouse`,
  },
  {
    name: "Edit Warehouse",
    type: "edit",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/warehouse`,
  },
  {
    name: "Stock Transfer List",
    type: "list",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-transfer`,
  },
  {
    name: "Stock Transfer Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-transfer`,
  },
  {
    name: "Stock Transfer Print",
    type: "print",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-transfer`,
  },
  {
    name: "Create Stock Transfer",
    type: "create",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-transfer`,
  },
  {
    name: "Edit Stock Transfer",
    type: "edit",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-transfer`,
  },
  {
    name: "Stock Out List",
    type: "list",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-out`,
  },
  {
    name: "Stock Out Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-out`,
  },
  {
    name: "Stock Out Print",
    type: "print",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/stock-out`,
  },
  {
    name: "Item Movement Log​​ List",
    type: "list",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out`,
  },
  {
    name: "Item Movement Log Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out`,
  },
  {
    name: "Junk Out List",
    type: "list",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out`,
  },
  {
    name: "Junk Out Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out`,
  },
  {
    name: "Create Junk Out",
    type: "create",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out`,
  },
  {
    name: "Edit Junk Out",
    type: "edit",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out`,
  },
  {
    name: "Junk Out Type List",
    type: "list",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out-type`,
  },
  {
    name: "Junk Out Type Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out-type`,
  },
  {
    name: "Create Junk Out Type",
    type: "create",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out-type`,
  },
  {
    name: "Edit Junk Out Type",
    type: "edit",
    slug: `${INVENTORY_PATH}/${STOCK_PATH}/junk-out-type`,
  },
  //asset
  {
    name: "Asset Request List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-request`,
  },
  {
    name: "Asset Request Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-request`,
  },
  {
    name: "Asset List List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-list`,
  },
  {
    name: "Asset List Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-list`,
  },
  {
    name: "Asset List Print",
    type: "print",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-list`,
  },
  {
    name: "Asset Log List",
    type: "list",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-log`,
  },
  {
    name: "Asset Log Detail",
    type: "detail",
    slug: `${INVENTORY_PATH}/${ASSET_PATH}/asset-log`,
  },
];