// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Upload, message, Form } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

// Util
import { getAccessToken } from "@/shared/utils";

const { Dragger } = Upload;

class FileUpload extends Component {

  UNSAFE_componentWillReceiveProps(newProps) {
    const { value } = newProps;
    if (value == "") {
      this.setState({
        fileUrl: null,
      });
    } else {
      this.setState({
        fileUrl: value,
      });
    }
  }

  beforeUpload = (file) => {
    const { beforeUpload } = this.props;
    if (beforeUpload) {
      return beforeUpload(file);
    }
    return;
  }

  renderFileList = (fileList) => {
    fileList && fileList.map((value) => {
      const { 
        url,
        response,
      } = value;
      value.url = url || response && response.url;
      value.thumbUrl = url || response && response.url;
      delete value.size;
      delete value.type;
      delete value.percent;
      delete value.response;
      delete value.originFileObj;
      delete value.lastModified;
      delete value.lastModifiedDate;
      return value;
    });
    return fileList;
  };

  handleOnChange = (info) => {
    const { fileList } = info;
    const data = fileList.filter(value => value.status == "done");
    const { onChange } = this.props;
    let { file: { status } } = info;
    if (data && data.length == fileList.length) {
      const fileList = this.renderFileList(data);
      if (onChange) {
        onChange(fileList);
      }
      message.success("File uploaded successfully");
    } else if (status === "removed") {
      let { fileList } = info;
      fileList = this.renderFileList(fileList);
      if (onChange) {
        onChange(fileList);
      }
      message.success("File removed successfully");
    } else if (status === "error") {
      message.error("File upload failed");
    }
  }

  render () {
    const {
      path,
      label,
      value,
      required,
      multiple,
      listType,
      meta: { touched, error },
    } = this.props;
    const token = getAccessToken();
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <FormItem
        help={typeof(error) == "string" ? error : error && error._error}
        label={label}
        required={required}
        validateStatus={validateStatus}
      >
        {!value || value.length == 0 ? (
          <Dragger 
            name="file"
            withCredentials
            multiple={multiple}
            showUploadList={false}
            beforeUpload={this.beforeUpload}
            action={`/api/file/v1/upload?path=${path}`}
            headers={{Authorization: `Bearer ${token && token.accessToken}`}}
            onChange={this.handleOnChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        ) : (
          <Upload
            listType={listType}
            showUploadList={true}
            defaultFileList={[value]}
            className='upload-list-inline'
            onChange={this.handleOnChange}
          />
        )}
      </FormItem>
    );
  }
}

FileUpload.propTypes = {
  meta: PropTypes.any,
  value: PropTypes.any,
  path: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  touched: PropTypes.string,
  fileUrl: PropTypes.string,
  listType: PropTypes.string,
  fileName: PropTypes.string,
  onChange: PropTypes.func,
  beforeUpload: PropTypes.func,
};

FileUpload.defaultProps = {
  name: "file",
  multiple: true,
  listType: "picture",
};

export default FileUpload;