// Libs
import React, { Component } from "react";
import { FieldArray } from "redux-form";

// Components
import Table from "./table";

class FieldArrayInline extends Component {

  render() {
    return (
      <FieldArray
        component={Table}
        rerenderOnEveryChange={true}
        {...this.props}
      />
    );
  }
}

export default FieldArrayInline;  