import * as Constants from "../constants";

const initialState = {
  list: []
};

export default function (state = initialState, action) {
  switch (action.type) {
  case Constants.SYNC_ITEM_LOGIN_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  case Constants.SYNC_ITEM_SYNC_RESET_SUCCESS:
    return {
      ...state,
      list: []
    };
  default:
    return state;
  }
}