import { schema } from "normalizr";
import { CustomerSchema } from "@sale/customer-center/customer/schema";
import { PaymentTermSchema } from "@sale/setup/payment-term/schema";
import { SaleOrderItemArraySchema } from "./sale-order-item";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";

const SaleOrderSchema = new schema.Entity("saleOrders",{
  customer: CustomerSchema,
  employee: EmployeeSchema,
  paymentTerm: PaymentTermSchema,
  saleOrderItems: SaleOrderItemArraySchema,
  commissionCustomer: CustomerSchema
},
{
  idAttribute: "saleOrderNo",
}
);
const SaleOrderArraySchema = new schema.Array(SaleOrderSchema);

export { SaleOrderSchema, SaleOrderArraySchema };