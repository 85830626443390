import moment from "moment";
import { DEFAULT_ASYNC_DATE_FORMAT } from "@/constants/default-constants"; 

export const OPTION_TYPE_TO_DAY = 1;
export const OPTION_TYPE_YESTERDAY = 2;
export const OPTION_TYPE_30_DAY = 3;
export const OPTION_TYPE_THIS_WEEK = 4;
export const OPTION_TYPE_LAST_WEEK = 5;
export const OPTION_TYPE_THIS_MONTH = 6;
export const OPTION_TYPE_LAST_MONTH = 7;
export const OPTION_TYPE_THIS_YEAR = 8;
export const OPTION_TYPE_LAST_YEAR = 9;
export const OPTION_TYPE_ALL = 10;
export const OPTION_TYPE_CUSTOM = 11;

export const OPTION_TYPE_TO_DAY_TITLE = "Today";
export const OPTION_TYPE_YESTERDAY_TITLE = "Yesterday";
export const OPTION_TYPE_30_DAY_TITLE = "Last 30 days";
export const OPTION_TYPE_THIS_WEEK_TITLE = "This week";
export const OPTION_TYPE_LAST_WEEK_TITLE = "Last week";
export const OPTION_TYPE_THIS_MONTH_TITLE = "This month";
export const OPTION_TYPE_LAST_MONTH_TITLE = "Last month";
export const OPTION_TYPE_THIS_YEAR_TITLE = "This year";
export const OPTION_TYPE_LAST_YEAR_TITLE = "Last year";
export const OPTION_TYPE_ALL_TITLE = "All";
export const OPTION_TYPE_CUSTOM_TITLE = "Custom";

export const DATASOURCE = [
  {
    id: OPTION_TYPE_TO_DAY,
    name: OPTION_TYPE_TO_DAY_TITLE
  },
  {
    id: OPTION_TYPE_YESTERDAY,
    name: OPTION_TYPE_YESTERDAY_TITLE
  },
  {
    id: OPTION_TYPE_30_DAY,
    name: OPTION_TYPE_30_DAY_TITLE
  },
  {
    id: OPTION_TYPE_THIS_WEEK,
    name: OPTION_TYPE_THIS_WEEK_TITLE
  },
  {
    id: OPTION_TYPE_LAST_WEEK,
    name: OPTION_TYPE_LAST_WEEK_TITLE
  },
  {
    id: OPTION_TYPE_THIS_MONTH,
    name: OPTION_TYPE_THIS_MONTH_TITLE
  },
  {
    id: OPTION_TYPE_LAST_MONTH,
    name: OPTION_TYPE_LAST_MONTH_TITLE
  },
  {
    id: OPTION_TYPE_THIS_YEAR,
    name: OPTION_TYPE_THIS_YEAR_TITLE
  },
  {
    id: OPTION_TYPE_LAST_YEAR,
    name: OPTION_TYPE_LAST_YEAR_TITLE
  },
  {
    id: OPTION_TYPE_ALL,
    name: OPTION_TYPE_ALL_TITLE
  },
  {
    id: OPTION_TYPE_CUSTOM,
    name: OPTION_TYPE_CUSTOM_TITLE
  },
];

export const renderDateRangeOption = (value) => {
  let dateObject = {
    from: null,
    to: null,
    dateType: null
  };

  const startYesterday = moment().subtract(1, "day").startOf("days").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endYesterday = moment().subtract(1, "day").endOf("days").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startToday = moment().startOf("days").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endToday = moment().endOf("days").format(DEFAULT_ASYNC_DATE_FORMAT);

  const endLast30Day = moment().subtract(30, "days").endOf("days").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startThisWeek = moment().startOf("week").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endThisWeek = moment().endOf("week").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startThisMonth = moment().startOf("month").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endThisMonth = moment().endOf("month").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startOfLastWeek = moment().subtract(1, "weeks").startOf("week").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endOfLastWeek = moment().subtract(1, "weeks").endOf("week").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startOfLastMonth = moment().subtract(1, "month").startOf("month").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endOfLastMonth = moment().subtract(1, "month").endOf("month").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startThisYear = moment().startOf("year").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endThisYear = moment().endOf("year").format(DEFAULT_ASYNC_DATE_FORMAT);

  const startLastYear = moment().subtract(1, "year").startOf("year").format(DEFAULT_ASYNC_DATE_FORMAT);
  const endLastYear = moment().subtract(1, "year").endOf("year").format(DEFAULT_ASYNC_DATE_FORMAT);

  switch(value) {
  case OPTION_TYPE_30_DAY:
    dateObject = {
      dateType: OPTION_TYPE_30_DAY,
      from: endLast30Day,
      to: endToday,
    };
    break;
  case OPTION_TYPE_TO_DAY:
    dateObject = {
      dateType: OPTION_TYPE_TO_DAY,
      from: startToday,
      to: endToday,
    };
    break;
  case OPTION_TYPE_THIS_WEEK:
    dateObject = {
      dateType: OPTION_TYPE_THIS_WEEK,
      from: startThisWeek,
      to: endThisWeek,
    };
    break;
  case OPTION_TYPE_LAST_WEEK:
    dateObject = {
      dateType: OPTION_TYPE_LAST_WEEK,
      from: startOfLastWeek,
      to: endOfLastWeek,
    };
    break;
  case OPTION_TYPE_THIS_MONTH:
    dateObject = {
      dateType: OPTION_TYPE_THIS_MONTH,
      from: startThisMonth,
      to: endThisMonth,
    };
    break;
  case OPTION_TYPE_LAST_MONTH:
    dateObject = {
      dateType: OPTION_TYPE_LAST_MONTH,
      from: startOfLastMonth,
      to: endOfLastMonth,
    };
    break;
  case OPTION_TYPE_THIS_YEAR:
    dateObject = {
      dateType: OPTION_TYPE_THIS_YEAR,
      from: startThisYear,
      to: endThisYear,
    };
    break;
  case OPTION_TYPE_LAST_YEAR:
    dateObject = {
      dateType: OPTION_TYPE_LAST_YEAR,
      from: startLastYear,
      to: endLastYear,
    };
    break;
  case OPTION_TYPE_YESTERDAY:
    dateObject = {
      dateType: OPTION_TYPE_YESTERDAY,
      from: startYesterday,
      to: endYesterday,
    };
    break;
  default:
    dateObject;
  }
  return dateObject;
};