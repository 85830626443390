import { 
  GETList,
  GETWithToken, 
  POSTWithToken,
} from "./base-service";

const API_URL = "/api/notification/v1";
const NOTIFICATION_VIEWER_API_URL = "/api/notification/viewer/v1";

export const getNotificationUnreadCount = () => {
  const url = `${API_URL}/unread/count` ;
  return GETWithToken(url);
};

export const getNotification = () => {
  const values = { limit: 10 };
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export const markAllRead = () => {
  const url = `${NOTIFICATION_VIEWER_API_URL}/read/all`;
  return POSTWithToken(url);
};

export const notificationRead = (notificationIds) => {
  const data = { notificationIds };
  const url = `${NOTIFICATION_VIEWER_API_URL}/read`;
  return POSTWithToken(url, data);
};

export const getNotificationWithSeeMore = ({page}) => {
  const numberPerPage = 10;
  const offset = page * numberPerPage;
  const limit = numberPerPage;
  const params = {
    offset,
    limit
  };
  const url = `${API_URL}/list`;
  return GETWithToken(url, params);
};