import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styled from "styled-components";
import { 
  Input,
  Form,
  Tooltip,
  Button,
  Card,
  Col,
  Row,
  Image,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import NoImage from "@/images/background/noImage.png";

const { Paragraph } = Typography;

const CardWrapper = styled(Card)`
  margin-bottom: 20px !important;
  border-radius: 5px !important;
  cursor: pointer;
  img {
    object-fit: contain !important;
  }
`;

class AutoCompleteAdvanceField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputNumber: null,
    };
  }

    onChange = (checked, value) => {
      let { 
        mode,
        onHandleChange,
        input: { value: inputValue },
        input: { onChange: onReduxFormChange }, 
      } = this.props;
      let newValue = null;
      if (checked) {
        if (mode == "single") {
          newValue = value;
        } else {
          if (inputValue == null) {
            newValue = [value];
          } else {
            newValue = [
              ...inputValue,
              value
            ];
          }
        }
      } else {
        if (mode == "single") {
          newValue = null;
        } else {
          newValue = _.remove(inputValue, (val) => val != value);
        }
      }
      onReduxFormChange(newValue);
      if (onHandleChange) {
        onHandleChange(newValue);
      }
      this.forceUpdate();
    }

    onInputChange = (event) => {
      const { onSearchClick } = this.props;
      const value = event.target.value;
      if (value) {
        this.setState({
          inputNumber: value,
        });
      } else {
        onSearchClick(null);
      }
    }

    onPressEnter = (event) => {
      event.preventDefault();
      const { onSearchClick } = this.props;
      const value = event.target.value;
      this.setState({
        inputNumber: value,
      });
      onSearchClick(value);
    }

    render() {
      const { 
        label, 
        required, 
        onSearchClick, 
        loading, 
        mode,
        displaySearch,
        extraFilter,
        dataSource,
        isFirstLoad,
        meta: { error, touched },
        input: { value },
      } = this.props;
      const validateStatus = classnames(error && touched ? "error" : "success");
      return (
        <Form.Item 
          label={label} 
          required={required}
          validateStatus={validateStatus}
          help={mode == "single" ? error : (error && typeof error == "string") ? error : (error && error._error)}
        >
          {displaySearch && (
            <div className="w-100 d-flex">
              <Input 
                autoFocus
                allowClear
                placeholder="Search..."
                onChange={this.onInputChange} 
                onPressEnter={this.onPressEnter}
                className="mb-2"
                style={{
                  width: extraFilter ? "50%" : "85%"
                }}
              />
              <Button 
                type="primary"
                icon={isFirstLoad && loading ? null : <SearchOutlined />}
                className="ml-1" 
                style={{
                  width: "15%"
                }}
                onClick={() => onSearchClick(this.state.inputNumber)}
              >
                {isFirstLoad && loading ? "Searching..." : "Search"}
              </Button>
              {extraFilter && (
                <div className="ml-1 mt-1" 
                  style={{
                    width: "30%"
                  }}>
                  {extraFilter}
                </div>
              )}
            </div>
          )}
          <Row gutter={[12]}>
            {dataSource?.map((data, index) => {
              const { 
                id, 
                name,
                partNumber,
                imageUrl,
                barcode,
              } = data;
              const isChecked = _.includes(value, id);
              return (
                <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24} key={index}>
                  <CardWrapper 
                    bordered
                    key={index} 
                    onClick={() => this.onChange(!isChecked, id) }
                    style={{ borderRadius: 5, borderColor: isChecked ? "#335E9C" : "#eee"}}
                    cover={
                      <Image
                        preview={false}
                        height="170px"
                        className="p-2"
                        src={imageUrl ? imageUrl : NoImage}
                        placeholder={
                          <Image
                            preview={false}
                            src={NoImage}
                            width="170px"
                          />
                        }
                      />
                    }
                  >
                    <Card.Meta
                      title={
                        <div className="mt-3 align-item-center">
                          <Tooltip placement="right" title={name}>
                            <Paragraph ellipsis={true} className="mb-0">
                              {name}
                            </Paragraph>
                          </Tooltip>
                        </div>
                      }
                    />
                    <React.Fragment>
                      <div>
                        {partNumber != null ? <Paragraph ellipsis={true} className="mb-0">SKU : {partNumber}</Paragraph> : "N/A"}
                      </div>
                      <div>
                        {barcode != null ? <Paragraph ellipsis={true} className="mb-0">UPC : {barcode}</Paragraph> : "N/A"}
                      </div>
                    </React.Fragment>
                  </CardWrapper>
                </Col>
              );
            })}
          </Row>
        </Form.Item>
      );
    }
}

AutoCompleteAdvanceField.propTypes = {
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  input: PropTypes.object,
  required: PropTypes.bool,
  label: PropTypes.string,
  onHandleChange: PropTypes.func,
  onSearch: PropTypes.func,
  meta: PropTypes.object,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  columns: PropTypes.any,
  displaySearch: PropTypes.bool,
  dataSource: PropTypes.array,
  scroll: PropTypes.any,
  extraFilter: PropTypes.node,
  titleName: PropTypes.string,
  titleKey: PropTypes.string,
  textLength: PropTypes.number,
  columnsWidth: PropTypes.string,
  optionRender: PropTypes.func,
  onTableChange: PropTypes.func,
  valueKey: PropTypes.string,
  isHasCheckAll: PropTypes.bool,
  onSearchClick: PropTypes.bool,
  displayDefaultColumns: PropTypes.bool,
  queryStorage: PropTypes.string,
  name: PropTypes.string,
  isStringValue: PropTypes.any, 
  valueType: PropTypes.any,
  onChecked: PropTypes.any,
  fieldName: PropTypes.string,
  isFirstLoad: PropTypes.bool,
  sorter: PropTypes.bool,
};

AutoCompleteAdvanceField.defaultProps = {
  loading: false,
  displaySearch: true,
  textLength: 250,
  titleKey: "name",
  titleName: "Name",
  columnsWidth: "40%",
  scroll: {
    y: 400
  },
  displayDefaultColumns: true,
  showDescription:true,
};

export default AutoCompleteAdvanceField;