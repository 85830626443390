import { SubmissionError } from "redux-form";

export const isValidEmail = (email) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const removeEmptyObjectFromArray = (array) => {
  if (array) {
    return array.filter(value => Object.keys(value).length !== 0);
  }
  return array;
};

export const validate = (rules, values) => {
  var errors = {};
  var isError = false;
  for (var field in rules) {
    var rule = rules[field];
    var value = rule["value"];
    if (values != null) {
      value = values[field];
    }
    var type = rule["type"];
  
    if (rule["required"] == true && (value == null || typeof value == "string" && value.trim() == "")) {
      errors[field] = rule["message"] || "This field is required";
      isError = true;
    }
  
    if (rule["required"] == true && rule.type == "array" && (value == null || value.length == 0)) {
      errors[field] = { _error: rule["message"] || "Please select at least 1 of these" };
      isError = true;
    }
      
    if (rule.validation && rule.type == "array" && value && value.length > 0) {
      const validation = rule.validation(value);
      if (validation) {
        errors[field] = { _error: validation.message };
        isError = true;
      }
    }
  
    if (type == "password" && value != null && value.length < 6) {
      errors[field] = rule["message"] || "Password must be 6 characters or more";
      isError = true;
    }
  
    if (Object.prototype.hasOwnProperty.call(rule, "equalsTo") && value != null) {
      var equalsTo = rule["equalsTo"];
      if (equalsTo && equalsTo.value != value) {
        const message = equalsTo.message || `${field} and ${equalsTo.name} do not match!`;
        errors[field] = message;
        errors[equalsTo.name] = message;
        isError = true;
      }
    }
  
    if (type == "email") {
      if (rule["required"] == true) {
        if (value != "" && value !== null && isValidEmail(value)) {
          errors[field] = rule["message"] || "Invalid email address";
          isError = true;
        } else if (value == null && value == "") {
          errors[field] = rule["message"] || "This field is required";
          isError = true;
        }
      } else {
        if (value != null && value != "" && isValidEmail(value)) {
          errors[field] = rule["message"] || "Invalid email address";
          isError = true;
        }
      }
    }
  
    if (type == "object") {
      const { ...subRules } = rule;
      const result = validate(subRules, values[field]);
      if (result != null) {
        errors[field] = result;
        isError = true;
      }
    }
  
    if (Object.prototype.hasOwnProperty.call(rule, "compare") && value != null) {
      var compare = rule["compare"];
      if (compare && compare.value > value) {
        const message = compare.message || `${compare.name} can not greater than ${field} !`;
        errors[field] = message;
        errors[compare.name] = message;
        isError = true;
      }
      if (compare && compare.condition) { 
        if (compare.condition) {
          const message = compare.message;
          errors[field] = message;
          errors[compare.name] = message;
          isError = true;
        }
      }
    }
  
    if (rule["required"] == true && rule["min"] != null) {
      if (typeof rule.min == "function") {
        if (rule.min(value)) {
          errors[field] = rule["minMessage"] || "Value must be greater than current value";
          isError = true;
        }
      } else {
        if (value < rule["min"]) {
          errors[field] = rule["minMessage"] || `Value must be greater than or equal to ${rule["min"]}`;
          isError = true;
        }
      }
    }
  
    if (rule["required"] == true && rule["max"] != null && value > rule["max"]) {
      errors[field] = rule["maxMessage"] || `Value must be less than or equal to ${rule["max"]}`;
      isError = true;
    }

    if (rule.type == "arrayInline") {
      if (rule["required"] == true) {
        value = removeEmptyObjectFromArray(value);
        if (!value || !value.length) {
          errors[field] = { _error: rule["message"] || "Please select at least 1 of these" };
          isError = true;
        } else {
          const subFields = rule.subFields;
          if (subFields) {
            const arrayErrors = [];
            value && value.map((subValue, index) => {
              const subErrors = {};
              subFields.map((sub) => {
                const { name, type, rule } = sub;
                if (type && type == "number") {
                  if (sub.min != null && subValue[name] < sub.min) {
                    subErrors[name] = sub["minMessage"] || `Value must be greater than ${subValue[name]}`;
                  }
                  if (sub.max != null && subValue[name] > sub.max) {
                    subErrors[name] = sub["maxMessage"] || `Value must be less than ${subValue[name]} or equal to ${sub.max}`;
                  }
                  if (subValue[name] == null) {
                    subErrors[name] = "This field is required";
                  }
                } else if (type && type == "object") {
                  let newRule = rule;
                  if (typeof rule == "function") {
                    newRule = rule(subValue);
                  }
                  subErrors[name] = validate(newRule, subValue[name]);
                  if (subErrors[name] == null) {
                    delete subErrors[name];
                  }
                }
                else {
                  if (!subValue || !subValue[name]) {
                    subErrors[name] = "This field is required";
                  }
                }
                arrayErrors[index] = subErrors;
              });
              if (removeEmptyObjectFromArray(arrayErrors).length) {
                errors[field] = arrayErrors;
                isError = true;
              } else {
                if (rule.validation) {
                  const validation = rule.validation(value);
                  if (validation) {
                    errors[field] = { _error: validation.message };
                    isError = true;
                  }
                }
              }
            });
          }
        }
      } else {
        let arrayErrors = [];
        const subFields = rule.subFields;
        value = removeEmptyObjectFromArray(value);
        if (rule.customValidation && typeof rule.customValidation == "function") {
          arrayErrors = rule.customValidation();
          if (arrayErrors.length > 0) {
            errors[field] = arrayErrors;
            isError = true;
          }
        } else {
          if (subFields) {
            const arrayErrors = [];
            value && value.map((subValue, index) => {
              const subErrors = {};
              subFields.map((sub) => {
                const { name, type, required=true } = sub;
                if (required) {
                  if (type && type == "number") {
                    if (sub.min != null && subValue[name] < sub.min) {
                      subErrors[name] = sub["minMessage"] || `Value must be greater than ${subValue[name]}`;
                    }
                    if (sub.max != null && subValue[name] > sub.max) {
                      subErrors[name] = sub["maxMessage"] || `Value must be less than ${subValue[name]} or equal to ${sub.max}`;
                    }
                    if (subValue[name] == null) {
                      subErrors[name] = "This field is required";
                    }
                  } else if (type && typeof(type) == "function") {
                    if (type(subValue)) {
                      subErrors[name] = "This field is required";
                    }
                  }
                  else {
                    if (!subValue || !subValue[name]) {
                      subErrors[name] = "This field is required";
                      arrayErrors[index] = subErrors;
                    }
                  }
                }
                arrayErrors[index] = subErrors;
              });
              if (removeEmptyObjectFromArray(arrayErrors).length) {
                errors[field] = arrayErrors;
                isError = true;
              } else {
                if (rule.validation) {
                  const validation = rule.validation(value);
                  if (validation) {
                    errors[field] = { _error: validation.message };
                    isError = true;
                  }
                }
              }
            });
          }
        }
      }
    }

    if (rule.type == "arrayInlineCustomField") {
      const arrayErrors = [];
      const subErrors = {};
      const subFields = rule.subFields;
      subFields.map((sub, index) => {
        const { name, required, inputKey } = sub;
        if (required == true && value[index][name] == null) {
          subErrors[name] = "This field is required";
          arrayErrors[inputKey] = subErrors;
        }
      });
      if (arrayErrors.length > 0) {
        errors[field] = arrayErrors;
        isError = true;
      }
    }
  }
  return isError ? errors : null;
};

export const createSubmitAction = (
  rules, 
  action,
  beforeSubmit,
  shouldDispatch = true,
  beforeSubmissionError,
) => async (values, dispatch) => {
  let error = null;
  error = validate(rules, values);
  if (error != null) {
    if (beforeSubmissionError) {
      beforeSubmissionError(values);
    }
    throw new SubmissionError(error);
  } else {
    let newValues = values;
    if (beforeSubmit) {
      newValues = await beforeSubmit(values);
    }
    if (!shouldDispatch) {
      return action(newValues);
    }
    return dispatch(action(newValues));
  }
};

export const renderFileList = (fileList) => {
  fileList && fileList.map((value) => {
    const {
      id,
      url,
    } = value;
    value.uid = id;
    value.url = url;
    value.thumbUrl = url;
    return value;
  });
  return fileList;
};

export const activityType = (value) => { 
  let status = null; 
  switch(value) { 
  case 1: 
    status = "Created"; 
    break; 
  case 2: 
    status = "Updated"; 
    break; 
  case 3: 
    status = "Archived"; 
    break; 
  case 4: 
    status = "Deleted"; 
    break; 
  default: 
    status; 
  } 
  return status; 
};

export const combineLocale = (locale, rootKey) => {
  const keys = Object.keys(locale);
  let result = {};
  for (const key of keys) {
    const value = locale[key];
    const mainKey = rootKey != null ? `${rootKey}.${key}` : `${key}`;
    if (typeof value == "object") {
      const nestedResult = combineLocale(value, mainKey);
      result = {
        ...result,
        ...nestedResult,
      };
    } else {
      result[mainKey] = locale[key]; 
    }
  }
  return result;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};