// Libs
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { reduxForm, SubmissionError } from "redux-form";
import { Form, Button } from "antd";
import { connect } from "react-redux";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Verification from "../verifyToken";
import AuthenticationLayout from "@/components/layout/authentication-layout";

// Util
import { loginUserPasswordIfNeeded } from "@/actions/authentication";
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_RULES,
  MACHINE_ID
} from "@/constants/authentication";

// Component
import Input from "@modules/cores/components/Input";

// Helper
import { createSubmitAction } from "@/helpers";

const LoginForm = (props) => {
  const [verified, setVerified] = React.useState(false);
  const [otp, setOtp] = React.useState(null);
  const [verifyToken, setVerifyToken] = React.useState(null);

  const { handleSubmit, loading } = props;
  const beforeSubmit = (values) => {
    const newValues = { ...values };
    const { username, password } = values;
    newValues.username = username.trim();
    newValues.password = password.trim();
    return newValues;
  };

  const handleSubmits = (values, dispatch) => {
    return createSubmitAction(
      LOGIN_REQUEST_RULES,
      loginUserPasswordIfNeeded,
      beforeSubmit
    )(values, dispatch).then((response) => {
      if (response?.errorCode === 442) {
        setVerified(true);
        setVerifyToken(response?.payload?.verifyToken);
      } else {
        throw new SubmissionError({ password: response?.message });
      }
    });
  };

  return (
    <>
      {verified ? (
        <Verification
          otp={otp}
          onSetOtp={setOtp}
          setVerified={setVerified}
          verifyToken={verifyToken}
        />
      ) : (
        <AuthenticationLayout>
          <Form onFinish={handleSubmit(handleSubmits)} layout="vertical">
            <Input
              autoFocus
              name="username"
              icon={<UserOutlined />}
              placeholder="Username"
            />
            <Input
              type="Password"
              name="password"
              icon={<LockOutlined />}
              placeholder="Password"
              autoComplete="new-password"
            />
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Link to="/forgot-password">
                Forgot password
              </Link>
            </div>
            <Button
              block
              ghost
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Log in
            </Button>
          </Form>
        </AuthenticationLayout>
      )}
    </>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const subDomain = location.host.split(".")[0];
  const machineId = localStorage.getItem(MACHINE_ID);
  return {
    loading: state.request[LOGIN_REQUEST],
    initialValues: {
      subDomain,
      machineId
    }
  };
};

export default connect(mapStateToProps)(reduxForm()(LoginForm));