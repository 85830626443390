import React from "react";
import nationalityDataSource from "./data-source";
import AutoComplete from "../../autocomplete-static";

class NationalityAutoComplete extends React.Component {

  render() {
    const dataSource = [];
    nationalityDataSource.map((value) => {
      dataSource.push(
        {
          id: value,
          name: value
        }
      );
    });
    return (
      <AutoComplete 
        required
        stringValue
        name="nationality"
        label="Nationality"
        dataSource={dataSource}
      />
    );
  }
}

export default NationalityAutoComplete;
