/* eslint-disable indent */
import React from "react";
import { Menu } from "antd";
import Media from "react-media";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link, withRouter } from "react-router-dom";

// component
import FontAwesome from "@modules/cores/components/font-awesome";

// resource
import resource from "./menu-resource";
import { getNavBarDisplayAllowed } from "@modules/utils/value-selector";

// Constants
import { LOCALE_KH } from "@/constants/locale";

class HeaderMenu extends React.Component {
  renderMenuItem = () => {
    const { renderDisplayNavBarAllowed, locale } = this.props;
    return resource.map((value) => {
      const { link, icon, subMenus, languageKey } = value;
      const shouldDisplay = renderDisplayNavBarAllowed(subMenus);
      if (shouldDisplay) {
        return (
          <Menu.Item
            key={link}
            className={locale == LOCALE_KH ? "khmer-moul" : ""}
          >
            <Link to={link}>
              {typeof icon == "string" ? (
                <FontAwesome name={icon} className="pr-1" />
              ) : (
                icon
              )}
              <FormattedMessage id={languageKey} />
            </Link>
          </Menu.Item>
        );
      }
    });
  };

  renderMenu = () => {
    const {
      mode,
      children,
      location: { pathname },
    } = this.props;
    const path = pathname.split("/");
    const selectedKeys = `/${path[1]}`;
    return (
      <Menu
        mode={mode}
        defaultSelectedKeys={[selectedKeys]}
        selectedKeys={[selectedKeys]}
      >
        {this.renderMenuItem()}
        {children}
      </Menu>
    );
  };

  render() {
    const { isCollapseType } = this.props;
    return (
      <>
        {isCollapseType ? (
          <Media query="(min-width: 1025px) and (max-width: 1366px)">
            {(matches) =>
              matches ? (
                <div style={{ width: 650 }}>{this.renderMenu()}</div>
              ) : (
                this.renderMenu()
              )
            }
          </Media>
        ) : (
          <Media queries={{ small: { maxWidth: 1180 } }}>
            {(matches) =>
              matches.small ? (
                <div style={{ width: 650 }}>{this.renderMenu()}</div>
              ) : (
                <div style={{ minWidth: 800 }} className="w-100">
                  {this.renderMenu()}
                </div>
              )
            }
          </Media>
        )}
      </>
    );
  }
}

HeaderMenu.propTypes = {
  mode: PropTypes.string,
  isCollapseType: PropTypes.bool,
  theme: PropTypes.string,
  children: PropTypes.node,
  locale: PropTypes.string,
  location: PropTypes.object,
  renderDisplayNavBarAllowed: PropTypes.func,
};

HeaderMenu.defaultProps = {
  mode: "horizontal",
};

const mapStateToProps = (state) => {
  return {
    locale: state.locale.locale,
    renderDisplayNavBarAllowed: (subMenu) =>
      getNavBarDisplayAllowed(state, subMenu),
  };
};

export default connect(mapStateToProps)(withRouter(HeaderMenu));
