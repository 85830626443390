import React from "react";
import { denormalize } from "normalizr";
import _ from "lodash";
import numeral from "numeral";

import { AppsArraySchema } from "@/schemas/app";
import { CurrentUserSchema } from "@/schemas/current-user";
import { 
  GENERAL_SETTING,
  CURRENCY, 
  SALE_SETTING, 
  SALE_TAX,
  COMPANY,
  POS_SETTING,
  EMPLOYEE_SETTING,
  ATTENDANCE_SETTING,
  ACCOUNTING_SETTING,
  BANK_SETTING,
  ITEM_SETTING,
  PAY_ROLL_SETTING,
  CASH_ADVANCED_SETTING,
} from "@/constants/configuration";
import * as ModulesSlugs from "@/constants/modules";
import combineLocale from "@/localization";
import { CURRENCY_DEFAULT_NUMBER_FORMAT } from "@/constants/default-constants";
import { CURRENCY_SYMBOL_POSITION_FRONT_ID } from "@modules/settings/setup/currency/constants";

export const getLoading = (state, requestName) => {
  const loading = state.request[requestName];
  return loading;
};

export const getError = (state, requestName) => {
  return state.error[requestName];
};

export const getCurrentUser = (state) => {
  const { entities, currentUser } = state;
  const currentUserEntities = denormalize(
    currentUser,
    CurrentUserSchema,
    entities
  );
  return currentUserEntities && currentUserEntities;
};

export const getCurrentPermissions = (state) => {
  const { entities, currentUser } = state;
  const currentUserEntities = denormalize(
    currentUser,
    CurrentUserSchema,
    entities
  );
  return currentUserEntities && currentUserEntities.permissions;
};

export const getCurrencyExchange = (state) => {
  const { entities, currentUser } = state;
  const currentUserEntities = denormalize(
    currentUser,
    CurrentUserSchema,
    entities
  );
  return currentUserEntities && currentUserEntities.currencyExchanges;
};

export const getAutoComplete = (state, requestName) => {
  const autoComplete = state.autoComplete[requestName];
  const dataSource = autoComplete == null ? null : autoComplete["list"];
  return dataSource;
};

export const getDashboardData = (state, requestName) => {
  const dashboard = state.dashboard[requestName];
  const dataSource = dashboard == null ? null : dashboard;
  return dataSource;
};

export const isInventoryInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.STOCK_SLUG);
};

export const isPayrollInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.PAY_ROLL_SLUG);
};

export const isLeaveInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.LEAVE_SLUG);
};

export const isAppraisalInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.APPRAISAL_SLUG);
};

export const isAccountingInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.ACCOUNTING_SLUG);
};

export const isEmployeeInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.EMPLOYEE_SLUG);
};

export const isSaleInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.SALE_ORDER_SLUG);
};

export const isServiceInstalled = (state) => {
  return isModulesInstalled(state, ModulesSlugs.SERVICE_SLUG);
};

export const getAccountingSetting = (state, propertyName) => {
  let setting = getConfiguration(state, ACCOUNTING_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getBankSetting = (state, propertyName) => {
  let setting = getConfiguration(state, BANK_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getItemSetting = (state, propertyName) => {
  let setting = getConfiguration(state, ITEM_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getSaleSetting = (state, propertyName) => {
  let setting = getConfiguration(state, SALE_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getPosSetting = (state, propertyName) => {
  let setting = getConfiguration(state, POS_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getPayrollSetting = (state, propertyName) => {
  let setting = getConfiguration(state, PAY_ROLL_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getCashAdvancedSetting = (state, propertyName) => {
  let setting = getConfiguration(state, CASH_ADVANCED_SETTING);
  if (propertyName) {
    setting = setting[propertyName];
  }
  return setting;
};

export const getPermissionAllowed = (state, permissionCode) => {
  const permissions = getCurrentPermissions(state);
  let authorized = false;
  const isAuthorized = permissions.find(value => value == permissionCode);
  if (isAuthorized) {
    authorized = true;
  }
  return authorized;
};

export const getNavBarDisplayAllowed = (state, subMenu) => {
  const permissions = getCurrentPermissions(state);
  let displayNavBar = false;
  const permissionCode = permissions.map((value) => Number(value));
  subMenu.map((sub) => {
    if (_.includes(permissionCode, sub)) {
      displayNavBar = true;
    }
  });
  return displayNavBar;
};

export const getConfiguration = (state, moduleName, propertyName) => {
  const currentUser = state.currentUser;
  const configuration = currentUser.configuration;
  if (configuration) {
    const moduleSetting = configuration[moduleName];
    if (moduleSetting) {
      if (propertyName) {
        const value = moduleSetting[propertyName];
        return value;
      }
      return moduleSetting;
    }
    return undefined;
  }
  return null;
};

export const getInstalledModules = (state) => {
  const { currentUser, entities } = state;
  const moduleIds = currentUser.modules;
  const moduleEntities = denormalize(moduleIds, AppsArraySchema, entities);
  return moduleEntities;
};

export const isModulesInstalled = (state, slug) => {
  const moduleEntities = getInstalledModules(state); 
  let isInstalled = false;
  moduleEntities.some(value => {
    const installed = value.children && value.children.find((val => val.slug == slug));
    if (installed) {
      isInstalled = installed != null;
      return true;
    }
  });
  return isInstalled;
};

export const getDefaultCurrency = (state) => {
  const defaultCurrency = getConfiguration(state, GENERAL_SETTING, CURRENCY);
  return defaultCurrency;
};

export const getDefaultCompany = (state) => {
  const currentUser = getCurrentUser(state);
  return currentUser && currentUser[COMPANY] ;
};

export const getDefaultTax = (state) => {
  const defaultTax = getConfiguration(state, SALE_SETTING, SALE_TAX);
  return defaultTax;
};

export const getEmployeeSettings = (state) => {
  const employeeSetting = getConfiguration(state, EMPLOYEE_SETTING);
  return employeeSetting;
};

export const getAttendanceSettings = (state) => {
  const attendanceSetting = getConfiguration(state, ATTENDANCE_SETTING);
  return attendanceSetting;
};

export const getDefaultCurrencyValueForWidget = (state, value, currency) => {
  const defaultCurrency = currency || getDefaultCurrency(state);
  return renderCurrencyValue(value, defaultCurrency, false, true);
};

export const getDefaultCurrencyValue = (state, value, isAccounting=false, currency, isDanger=true, showSymbol=true, onlyNumber) => {
  const defaultCurrency = currency || getDefaultCurrency(state);
  if (onlyNumber) {
    return renderCurrencyValue(value, defaultCurrency, false, showSymbol);
  }
  if (!isAccounting && value < 0) {
    return (
      <div className={isDanger && "text-danger"}>
        {renderCurrencyValue(value, defaultCurrency, false, showSymbol)}
      </div>
    );
  }
  return renderCurrencyValue(value, defaultCurrency, isAccounting, showSymbol);
};

export function renderCurrencyValue(value, currency, isAccounting, showSymbol) {
  const { numberFormat, symbolPosition, symbol } = currency;
  let format = CURRENCY_DEFAULT_NUMBER_FORMAT;
  if (numberFormat) {
    format = numberFormat;
  }
  let formattedValue = numeral(value).format(format);
  if (formattedValue == "NaN") {
    value = 0;
    formattedValue = numeral(value).format(format);
  }
  if (isAccounting && value < 0) {
    value = Math.abs(value);
    formattedValue = `(${numeral(value).format(format)})`;
  }
  return renderCurrencySymbolPosition(formattedValue, symbolPosition, symbol, showSymbol);
}

export function renderCurrencySymbolPosition(value, symbolPosition, symbol, showSymbol) {
  if (showSymbol == true) {
    if (symbolPosition && symbol) {
      if (symbolPosition == CURRENCY_SYMBOL_POSITION_FRONT_ID) {
        return `${symbol} ${value}`;
      }
      return `${value} ${symbol}`;
    }
  }
  return value;
}

export const getPageTitle = (state, page) => {
  const companyName = getDefaultCompany(state).name;
  const title = `${page} | ${companyName}`;
  document.title = title;
};

export const getDefaultRedirectPath = (state, dataSource, path) => {
  let allowedParentMenus = [];
  const allowedMenus = [];
  let redirectPath = null;
  dataSource.menus && dataSource.menus.map((value) => {
    if (value.sub && value.sub.length > 0) {
      value.sub.map((subValue) => {
        if (getPermissionAllowed(state, subValue.code)) {
          allowedMenus.push(subValue);
          allowedParentMenus.push(value);
        }
      });
      allowedParentMenus = _.uniqBy(allowedParentMenus, (value) => value.id);
      const firstParentMenu = allowedParentMenus && allowedParentMenus[0];
      const firstPage = allowedMenus && allowedMenus[0];
      redirectPath = `${path}/${firstParentMenu && firstParentMenu.slug}/${firstPage && firstPage.slug}`;
    } else {
      allowedParentMenus.push(value);
      allowedParentMenus = _.uniqBy(allowedParentMenus, (value) => value.id);
      const firstParentMenu = allowedParentMenus && allowedParentMenus[0];
      redirectPath = `${path}/${firstParentMenu && firstParentMenu.slug}`;
    }
  });
  return redirectPath;
};

export const getLocalizedString = (state, key) => {
  const { locale: { locale } } =  state;
  return combineLocale[locale] && combineLocale[locale][key];
};