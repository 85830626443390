export const SALE_COMMISSION_TYPE_SALE = 1;
export const SALE_COMMISSION_TYPE_SALE_TITLE = "Sale Date";
export const SALE_COMMISSION_TYPE_PAID = 2;
export const SALE_COMMISSION_TYPE_PAID_TITLE = "Paid Date";

export const SALE_COMMISSION_TYPE_DATA_SOURCE = [
  {
    id: SALE_COMMISSION_TYPE_SALE,
    name: SALE_COMMISSION_TYPE_SALE_TITLE,
  },
  {
    id: SALE_COMMISSION_TYPE_PAID,
    name: SALE_COMMISSION_TYPE_PAID_TITLE,
  }
];