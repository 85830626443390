export const CUSTOMER_COMMISSION_LIST_REQUEST = "CUSTOMER_COMMISSION_LIST_REQUEST";
export const CUSTOMER_COMMISSION_LIST_SUCCESS = "CUSTOMER_COMMISSION_LIST_REQUEST_SUCCESS";

export const CUSTOMER_COMMISSION_AUTOCOMPLETE_REQUEST = "CUSTOMER_COMMISSION_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_COMMISSION_AUTOCOMPLETE_SUCCESS = "CUSTOMER_COMMISSION_AUTOCOMPLETE_SUCCESS";

export const CUSTOMER_COMMISSION_DETAIL_REQUEST = "CUSTOMER_COMMISSION_DETAIL_REQUEST";

export const CUSTOMER_COMMISSION_ARCHIVE_REQUEST = "CUSTOMER_COMMISSION_ARCHIVE_REQUEST";
export const CUSTOMER_COMMISSION_DELETE_REQUEST = "CUSTOMER_COMMISSION_DELETE_REQUEST";
export const CUSTOMER_COMMISSION_UNARCHIVE_REQUEST = "CUSTOMER_COMMISSION_UNARCHIVE_REQUEST";
export const CLEAR_CUSTOMER_COMMISSION_REQUEST = "CLEAR_CUSTOMER_COMMISSION_REQUEST";
export const CANCEL_CUSTOMER_COMMISSION_REQUEST = "CANCEL_CUSTOMER_COMMISSION_REQUEST";

export const READ_CUSTOMER_COMMISSION = 232000;
export const DELETE_CUSTOMER_COMMISSION = 232003;
export const ARCHIVE_CUSTOMER_COMMISSION = 232004;
export const UNARCHIVE_CUSTOMER_COMMISSION = 232005;
export const CLEAR_CUSTOMER_COMMISSION = 232006;
export const CANCEL_CUSTOMER_COMMISSION = 232007;
export const PRINT_CUSTOMER_COMMISSION = 232008;
export const READ_CUSTOMER_COMMISSION_SELF_SERVICE = 232012;

export const CUSTOMER_COMMISSION_STATUS_DRAFT_ID = 1;
export const CUSTOMER_COMMISSION_STATUS_DRAFT_NAME = "Draft";
export const CUSTOMER_COMMISSION_STATUS_CLEAR_ID = 2;
export const CUSTOMER_COMMISSION_STATUS_CLEAR_NAME = "Cleared";
export const CUSTOMER_COMMISSION_STATUS_CANCEL_ID = 3;
export const CUSTOMER_COMMISSION_STATUS_CANCEL_NAME = "Cancelled";

export const CLEAR_CUSTOMER_COMMISSION_FORM_NAME = "clearCustomerCommission";

export const CUSTOMER_COMMISSION_STATUS_DATASOURCE = [
  {
    id: CUSTOMER_COMMISSION_STATUS_DRAFT_ID,
    name: CUSTOMER_COMMISSION_STATUS_DRAFT_NAME,
  },
  {
    id: CUSTOMER_COMMISSION_STATUS_CLEAR_ID,
    name: CUSTOMER_COMMISSION_STATUS_CLEAR_NAME,
  },
  {
    id: CUSTOMER_COMMISSION_STATUS_CANCEL_ID,
    name: CUSTOMER_COMMISSION_STATUS_CANCEL_NAME,
  },
];