// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Table, Spin, Modal } from "antd";
import { connect } from "react-redux";
import _ from "lodash";

// Components
import PanelBox from "@cores/components/panel-box";
import DetailBox from "@cores/components/detail-box";
import CurrencyBox from "@cores/components/currency-box";
import SummaryBox from "@cores/components/summary-box";
import PayrollIncentiveForm from "@modules/hr/payroll/payslip-batch/components/form-component/payroll-incentive";

// Helper
import { renderMonth } from "@/modules/helpers";
import { paySlipStatus } from "../../helpers";
import { 
  PAYSLIP_CONFIRM_REQUEST, 
  PAYSLIP_COMPUTATION_CREATE_REQUEST 
} from "../../constants";
import { getPermissionAllowed } from "@modules/utils/value-selector";
import { CREATE_EMPLOYEE_PAYROLL_INCENTIVE, salaryTypes } from "@modules/hr/employee-directory/employee/constants";
import { EARNING_TYPE_CATEGORY_SALE_INCENTIVE } from "@modules/hr/payroll/earning-type/constants";
import { PAYROLL_INCENTIVE_CREATE_FORM } from "@modules/hr/payroll/payslip-batch/constants";

class PayslipDetail extends Component {

  state = {
    visible: false,
    computationId: null,
  };

  onVisible = (computationId) => {
    this.setState({
      visible: true,
      computationId,
    });
  }

  onDismiss = () => {
    this.setState({
      visible: false,
    });
  }

  columnsPayslipInput = () => {
    return [
      {
        title: "No",
        dataIndex: "no",
        width: "2%",
        render: (_, row, index) => index + 1
      },
      {
        title: "Name",
        dataIndex: "earningTypeInput",
        width: "30%",
        render: (text, row) => {
          return (
            <>
              {row?.earningTypeInput !== null ?<>{row?.earningTypeInput?.name}</> : <>{row?.deductionTypeInput?.name}</>}
            </>
          );
        }
      },
      {
        title: "Parameter",
        dataIndex: "parameter",
        width: "30%",
        render: (text, row) => {
          return (
            <>
              {row?.earningTypeInput !== null ?<>{row?.earningTypeInput?.parameter}</> : <>{row?.deductionTypeInput?.parameter}</>}
            </>
          );
        }
      },
      {
        title: "Value",
        dataIndex: "value",
        width: "30%",
        render: (value, row) => {
          if (value != null) {
            return value;
          } else {
            return (
              <>
                {row?.earningTypeInput !== null ?<>{row?.earningTypeInput?.defaultValue}</> : <>{row?.deductionTypeInput?.defaultValue}</>}
              </>
            );
          }
        }
      }
    ];
  }

  columnsPayslipComputation = () => {
    const { allowedCreateEmployeePayrollIncentive } = this.props;
    return [
      {
        title: "No",
        dataIndex: "no",
        width: "2%",
        render: (_, row, index) => index + 1
      },
      {
        title: "Name",
        dataIndex: "earningType",
        width: "20%",
        render: (text, row) => {
          return row.earningType ? row.earningType.name : row.deductionType ? row.deductionType.name : "";
        }
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "20%",
      },
      {
        title: "Rate",
        dataIndex: "rate",
        width: "20%",
        render: (text, row) => (`${row.rate} %`)
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: "20%",
        render: (amount) => <CurrencyBox value={amount} />
      },
      {
        title: "Total",
        dataIndex: "total",
        width: "20%",
        render: (total, row) => {
          if (row.earningType && row.earningType.category == EARNING_TYPE_CATEGORY_SALE_INCENTIVE && allowedCreateEmployeePayrollIncentive) {
            return (
              <a className="text-primary" onClick={() => this.onVisible(row.id)}>
                <CurrencyBox value={total} />
              </a>
            );
          }
          return <CurrencyBox value={total} />;
        }
      }
    ];
  }

  columnsPayslipVariables = () => {
    return [
      {
        title: "No",
        dataIndex: "no",
        width: "2%",
        render: (_, row, index) => index + 1
      },
      {
        title: "Name",
        dataIndex: "label",
        width: "20%",
      },
      {
        title: "Value",
        dataIndex: "value",
        width: "20%",
        render: (value, row) => {
          if (row.name == "salaryType") {
            const matchedType = salaryTypes.find(type => type.id == value);
            if (matchedType) {
              return matchedType.name;
            }
          }
          return value;
        }
      },
      {
        title: "Notation",
        dataIndex: "notation",
        width: "50%",
      },
    ];
  }

  render() {
    const {
      entity: {
        id,
        name,
        month,
        year,
        status,
        inputs,
        employee,
        variables,
        computations,
      },
      profilePage,
      computePayslipLoading,
      confirmPayslipLoading,
    } = this.props;
    const { visible, computationId } = this.state;
    let totalComputation = 0;
    computations && computations.map((value) => {
      totalComputation += value.total;
    });
    return (
      <Spin spinning={computePayslipLoading == true || confirmPayslipLoading == true } tip="Computing...">
        <PanelBox title="General Info">
          <Row>
            <Col md="12" xs="12">
              <DetailBox
                leftCol="3"
                rightCol="9"
                title="Name"
                data={name}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Employee">
                {employee && (
                  <Link to={`../../employee-directory/employee/${employee.id}`} disabled={profilePage}>
                    {employee.firstName} {employee.lastName}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Salary Structure">
                {employee && employee.payroll && employee.payroll.salaryStructure && (
                  <Link to={`../salary-structure/${employee.payroll.salaryStructure.id}`} disabled={profilePage}>
                    {employee.payroll.salaryStructure.name}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Month"
                data={renderMonth(month)}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Year"
                data={year}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Status"
                data={paySlipStatus(status)}
              />
            </Col>
          </Row>
        </PanelBox>
        <PanelBox title="Inputs">
          <Table 
            size="small"
            pagination={false}
            columns={this.columnsPayslipInput()}
            dataSource={_.without(inputs, undefined)}
          />
        </PanelBox>
        <PanelBox title="Computations">
          <Table 
            size="small"
            pagination={false}
            columns={this.columnsPayslipComputation()}
            dataSource={computations}
          />
          <Row>
            <Col xs="12" md={{
              size: 5,
              offset: 7
            }} 
            className="text-right font-weight-bold mt-3">
              <SummaryBox 
                label="Total"
                value={totalComputation}
              />
            </Col>
          </Row>
        </PanelBox>
        {variables && (
          <PanelBox title="Variables">
            <Table 
              size="small"
              pagination={false}
              dataSource={variables}
              columns={this.columnsPayslipVariables()}
            />
          </PanelBox>
        )}
        <Modal
          title="Incentive"
          width="1200px"
          visible={visible}
          footer={null}
          onCancel={this.onDismiss}
          destroyOnClose={true}
        >
          {visible && (
            <PayrollIncentiveForm 
              year={year}
              month={month}
              payslipId={id}
              onDismiss={this.onDismiss}
              form={PAYROLL_INCENTIVE_CREATE_FORM}
              payslipComputationId={computationId}
              employeeId={employee && employee.id}
              computations={computations && computations.filter(computation => computation.earningType != null && computation.earningType.category != EARNING_TYPE_CATEGORY_SALE_INCENTIVE)}
            />
          )}
        </Modal>
      </Spin>
    );
  }
}

PayslipDetail.propTypes = {
  entity: PropTypes.object,
  profilePage: PropTypes.bool,
  computePayslipLoading: PropTypes.bool,
  confirmPayslipLoading: PropTypes.bool,
  allowedCreateEmployeePayrollIncentive: PropTypes.bool,
};

PayslipDetail.defaultProps = {
  computePayslipLoading: false,
};

const mapStateToProps = (state) => {
  const { request } = state;
  return {
    confirmPayslipLoading: request[PAYSLIP_CONFIRM_REQUEST],
    computePayslipLoading: request[PAYSLIP_COMPUTATION_CREATE_REQUEST],
    allowedCreateEmployeePayrollIncentive: getPermissionAllowed(state, CREATE_EMPLOYEE_PAYROLL_INCENTIVE)
  };
};

export default connect(mapStateToProps)(PayslipDetail);