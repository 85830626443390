import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getEmployeeAutoComplete, createEmployee } from "@modules/hr/employee-directory/employee/actions";
import FormComponentAutoComplete from "@modules/hr/employee-directory/employee/components/form-component";

// Constant 
import { 
  EMPLOYEE_AUTOCOMPLETE_REQUEST,
  CREATE_EMPLOYEE,
  EMPLOYEE_CREATE_REQUEST
} from "@modules/hr/employee-directory/employee/constants";

import { createEmployeeSubmitRule } from "@modules/hr/employee-directory/employee/helpers";

class EmployeeAutoComplete extends Component {

  columns = () => [
    {
      title: "Department",
      dataIndex: "department",
      width: "30%",
      render: (department) => department && department.name
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "30%",
      render: (position) => position && position.name
    },
  ]

  renderForm = (props) => {
    return (
      <FormComponentAutoComplete 
        isAutoComplete={true}
        {...props}
      />
    );
  }

  render() {
    const { 
      ids,
      extra,
      ...props
    } = this.props; 
    const newExtra = Object.assign({ids}, extra);
    return (
      <AutoComplete
        formComponent={this.renderForm}
        createAction={createEmployee}
        permissionCode={CREATE_EMPLOYEE}
        submitRules={createEmployeeSubmitRule}
        createRequestName={EMPLOYEE_CREATE_REQUEST}
        columns={this.columns}
        optionRender={(values) => `${values.firstName} ${values.lastName}`}
        extra={newExtra}
        {...props}
      />
    );
  }
}

EmployeeAutoComplete.propTypes = {
  ids: PropTypes.array,
  extra: PropTypes.object,
};

EmployeeAutoComplete.defaultProps = {
  label: "Employee",
  name: "employeeId",
  required: true,
  action: getEmployeeAutoComplete,
  requestName: EMPLOYEE_AUTOCOMPLETE_REQUEST,
};

export default EmployeeAutoComplete;