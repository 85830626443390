import React from "react";
import _ from "lodash";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { withRouter } from "react-router-dom";
import { Card,  Row, Col, Modal, message, Tabs, Button } from "antd";
import { SettingOutlined, ContainerOutlined, FileSyncOutlined } from "@ant-design/icons";

// Components
import List from "./list";
import Header from "./header";
import ImportForm from "./import";
import PreviewForm from "./preview";
import ManageFilter from "./manage-filters";
import NoPermission from "@/components/no-permission";

// Helpers
import { 
  getQueryParamUrl,
  getBreadcrumbPageLabel,
  updateQueryParamUrl,
  updateQueryParamToLocalStorage,
} from "@modules/helpers";
import { getAll } from "@/actions/filter";
import { importList, createBatch } from "@/services/import";
import { getPermissionAllowed, getPageTitle } from "@modules/utils/value-selector";
import { OPTION_TYPE_CUSTOM, renderDateRangeOption, OPTION_TYPE_ALL } from "@cores/components/auto-complete/date-range-option/constants";

import { getEntityName } from "@modules/helpers";
import { FILTER_LIST_REQUEST } from "@/constants/filter";
import { FilterArraySchema } from "../../../schemas/filter";
import "./index.less";

const defaultFilterKeys = ["query", "archive"];

class ListLayout extends React.Component {

  state = {
    visible: false,
    visibleManageFilter: false,
    visiblePreview: false,
    loadingImport: false,
    previewData: [],
  }

  onImportClick = () => {
    this.setState({
      visible: true
    });    
  }

  componentDidMount() {
    const { getFilterList } = this.props;
    getFilterList();
  }

  onDismiss = () => {
    this.setState({
      visible: false,
      visiblePreview: false,
      visibleManageFilter: false,
    });
  }

  onImportLoading = (status) => {
    this.setState({
      loadingImport: status
    });
  }

  onManageFilterClick = () => {
    this.setState({
      visibleManageFilter: true,
    });
  }

  onImportSubmit = (values) => {
    let { importModule, reducerName } = this.props;
    const newImportModule = importModule || reducerName; 
    this.onImportLoading(true);
    const newValues = {...values};
    newValues.preview = values.previewBeforeImport;
    delete newValues.previewBeforeImport;
    return importList(newImportModule, newValues).then((response) => {
      this.onImportLoading(false);
      if (values.previewBeforeImport) {
        this.setState({
          visiblePreview: true,
          visible: false,
          previewData: response,
        });
      } else {
        this.handleReload();
        this.onDismiss();
      }
    }).catch((error) => {
      this.onImportLoading(false);
      message.error(error.message);
      return error;
    });
  }

  onPreviewSubmit = (values) => {
    let { importModule, reducerName } = this.props;
    const newImportModule = importModule || reducerName; 
    const newValues = {
      datas: values,
    };
    this.onImportLoading(true);
    return createBatch(newImportModule, newValues).then(() => {
      this.handleReload();
      this.onImportLoading(false);
      this.onDismiss();
    }).catch((error) => {
      this.onImportLoading(false);
      message.error(error.message);
      return error;
    });
  }

  onTabChange = (slug) => {
    const { filterListObjectIds, entities } = this.props;
    const filtersDataSource = denormalize(filterListObjectIds, FilterArraySchema, entities);
    const query = {};
    if (slug != "all") {
      const matched = filtersDataSource && filtersDataSource.find((value) => value.slug == slug);
      if (matched && matched.filters) {
        for (const key in matched.filters) {
          query[key] = matched.filters[key].value;
          if (key == "dateType") {
            if (matched.filters[key] && matched.filters[key].value == OPTION_TYPE_CUSTOM) {
              query.from = matched.filters[key].from;
              query.to = matched.filters[key].to;
            } else {
              if (matched.filters[key].value == OPTION_TYPE_ALL) {
                query.dateType = OPTION_TYPE_ALL;
                delete query.from;
                delete query.to;
              } else {
                const { from, to } = renderDateRangeOption(matched.filters[key].value);
                query.from = from;
                query.to = to;
              }
            }
          }
        }
      }
      query.view = slug;
    } else {
      query.view = slug;
    }
    updateQueryParamUrl(query);
    updateQueryParamToLocalStorage(query);
  };

  render() {
    const { 
      match: { path }, 
      title,
      entities,
      schema,
      objectIds,
      creatable,
      watchable, 
      createAllowed,
      treeTable,
      extraButtons,
      childrenColumnName,
      defaultExpandAllRows,
      newDataSource,
      pageTitle,
      actionDropdown,
      viewAllowed,
      breadcrumbs,
      newTitle,
      isPermissionAllowed,
      importAllowed,
      importable,
      reducerName,
      importFilePath,
      showHeader,
      showTitle,
      filterKeys,
      previewColumns,
      filterListObjectIds,
      extraEntitySummaryClassName,
      loading,
      requestName,
      printEntity,
      extraContent,
      extraEntitySummaryComponent: ExtraEntitySummaryComponent,
    } = this.props;
    const { 
      visible, 
      loadingImport, 
      visiblePreview, 
      previewData, 
      visibleManageFilter,
      extraFilters,
    } = this.state;
    const col = ExtraEntitySummaryComponent != null ? 18 : 24;
    pageTitle(`${getBreadcrumbPageLabel(newTitle || title)} List`);
    let dataSource = denormalize(objectIds, schema, entities); 
    if (treeTable) {
      if (newDataSource) {
        dataSource = newDataSource(dataSource);
      } else {
        dataSource.map((objectEntity, index) => {
          objectEntity.key = index;
          if (childrenColumnName) {
            const newObjectEntity = objectEntity[childrenColumnName];
            newObjectEntity.map((childrenObjectEntity, childrenIndex) => {
              childrenObjectEntity.key = `${index}-${childrenIndex}`;
            });
          }
        });
      }
    } else {
      if (newDataSource) {
        dataSource = newDataSource(dataSource);
      }
      dataSource.map((objectEntity, index) => {
        objectEntity.key = index;
      });
    }
  
    const newFilterKeys = [
      ...filterKeys,
      ...defaultFilterKeys
    ];
    const isMatch = window.matchMedia("(max-width: 767px)").matches;
    const isAllowed = typeof(isPermissionAllowed) == "function" ? isPermissionAllowed(dataSource) : isPermissionAllowed;
    const query = getQueryParamUrl();
    const activeKey = query.view || "all";
    let filtersDataSource = denormalize(filterListObjectIds, FilterArraySchema, entities);
    filtersDataSource = _.orderBy(filtersDataSource, ["order"], ["asc"]);
    return (
      <React.Fragment>
        {(isAllowed || viewAllowed) ? (
          <React.Fragment>
            {showHeader && (
              <Header 
                path={path}
                title={title} 
                showTitle={showTitle}
                breadcrumbs={breadcrumbs}
                extraContent={extraContent}
                extraButtons={extraButtons}
                actionDropdown={actionDropdown}
                onImportClick={this.onImportClick}
                createable={creatable && createAllowed}
                importable={importable && importAllowed}
                watchable={watchable}
              />
            )}
            {loading == false &&  (
              <Row>
                <Col xxl={col} xl={col} lg={col} xs={24} className="filter-tabs">
                  {showHeader == true && showTitle == true && filtersDataSource && filtersDataSource.length > 0 ? (
                    <Tabs 
                      type="card"
                      activeKey={activeKey} 
                      onChange={(key) => this.onTabChange(key)} 
                      style={{margin: 10}}
                      tabBarExtraContent={
                        <Button 
                          ghost
                          type="primary"
                          icon={<SettingOutlined />}
                          onClick={() => this.onManageFilterClick()}
                        >
                          Manage
                        </Button>
                      }
                    >
                      <Tabs.TabPane tab="All" key="all">
                        {activeKey == "all" && (
                          <List 
                            {...this.props} 
                            multiple={false}
                            printEntity={printEntity}
                            filterKeys={newFilterKeys}
                            entity={getEntityName(title)}
                            requestName={`${requestName}_ALL`}
                            filterDataSource={filtersDataSource}
                          />
                        )}
                      </Tabs.TabPane>
                      {filtersDataSource.map((value) => {
                        return (
                          <Tabs.TabPane 
                            tab={value.name} 
                            key={value.slug} 
                            closable={true}
                          >
                            {activeKey == value.slug && (
                              <List 
                                {...this.props} 
                                multiple={true}
                                printEntity={printEntity}
                                filterKeys={newFilterKeys}
                                entity={getEntityName(title)}
                                filterDataSource={filtersDataSource}
                                extraFilterOnFirstLoad={extraFilters}
                                requestName={`${requestName}_${value.slug}`} 
                              />
                            )}
                          </Tabs.TabPane>
                        );
                      })}
                    </Tabs>
                  ) : (
                    <Fragment>
                      {loading == false && ((filtersDataSource && filtersDataSource.length == 0) || (showHeader == false && showTitle == false)) && (
                        <List 
                          {...this.props} 
                          className="m-2"
                          printEntity={printEntity}
                          filterKeys={newFilterKeys}
                          entity={getEntityName(title)}
                          requestName={`${requestName}_SINGLE`}
                          filterDataSource={filtersDataSource}
                        />
                      )}
                    </Fragment>
                  )}
                </Col>
                {ExtraEntitySummaryComponent && (
                  <Col xxl={6} xl={6} lg={8} xs={24}>
                    <Card 
                      bordered={false} 
                      bodyStyle={{padding: "16px"}} 
                      className={isMatch ? `${extraEntitySummaryClassName} mx-3`: `${extraEntitySummaryClassName} mt-lg-2 mr-lg-2 ml-lg-0 mx-sm-3`}
                    > 
                      <ExtraEntitySummaryComponent 
                        {...this.props}
                      />
                    </Card>
                  </Col>
                )}
              </Row>
            )}
          </React.Fragment>
        ) : (
          <NoPermission /> 
        )}
        <Modal
          footer={null}
          maskClosable={false}
          onCancel={this.onDismiss}
          destroyOnClose={true}
          visible={visible || visiblePreview || visibleManageFilter}
          width={visiblePreview ? "1300px" : "900px"}
          title={
            <React.Fragment>
              
              {visibleManageFilter ? <ContainerOutlined /> : <FileSyncOutlined />} {`${visibleManageFilter ? `Manage Filters of ${title}` : visiblePreview ? `Preview ${title}` : visible ? `Import ${title}` : ""}`}
            </React.Fragment>
          }
        >
          {visible && (
            <ImportForm 
              loading={loadingImport}
              form={`import${reducerName}`}
              onSubmit={this.onImportSubmit}
              importFilePath={importFilePath}
            />
          )}
          {visiblePreview && (
            <PreviewForm 
              treeTable={treeTable}
              columns={previewColumns}
              dataSource={previewData}
              form={`preview${reducerName}`}
              onSubmit={this.onPreviewSubmit}
              loading={loadingImport}
              childrenColumnName={childrenColumnName}
              defaultExpandAllRows={defaultExpandAllRows}
            />
          )}
          {visibleManageFilter && (
            <ManageFilter 
              entity={getEntityName(title)}
              dataSource={filtersDataSource}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

ListLayout.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
  entities: PropTypes.object,
  schema: PropTypes.any,
  objectIds: PropTypes.array,
  filterKeys: PropTypes.array,
  creatable: PropTypes.bool,
  createAllowed: PropTypes.bool,
  treeTable: PropTypes.bool,
  childrenColumnName: PropTypes.string,
  onExpand: PropTypes.any,
  defaultExpandAllRows: PropTypes.bool,
  extraButtons: PropTypes.any,
  pageTitle: PropTypes.any,
  newDataSource: PropTypes.func,
  viewAllowed: PropTypes.bool,
  breadcrumbs: PropTypes.any,
  newTitle: PropTypes.string,
  importable: PropTypes.bool,
  reducerName: PropTypes.any,
  actionDropdown: PropTypes.any,
  importFilePath: PropTypes.any,
  importModule: PropTypes.any,
  importAllowed: PropTypes.bool,
  isPermissionAllowed: PropTypes.any,
  extraEntitySummaryComponent: PropTypes.any,
  showHeader: PropTypes.bool,
  showTitle: PropTypes.bool,
  extraEntitySummaryClassName: PropTypes.string,
  previewColumns: PropTypes.any,
  getFilterList: PropTypes.func,
  filterListObjectIds: PropTypes.array,
  loading: PropTypes.bool,
  requestName: PropTypes.string,
  printEntity: PropTypes.string,
  extraContent: PropTypes.any,
  watchable: PropTypes.bool,
};

ListLayout.defaultProps = {
  loading: true,
  watchable: false,
  dataSource: [],
  filterKeys: [],
  creatable: true,
  defaultExpandAllRows: false,
  actionDropdown: [],
  showHeader: true,
  showTitle: true,
};

const mapStateToProps = (state, ownProps) => {
  const { title, permission, reducerName, reducerKey="list" } = ownProps;
  const createCode = Number(permission && permission.code) + 1;
  const importCode = Number(permission && permission.code) + 8;
  const entity = getEntityName(title);
  return {
    entities: state.entities,
    objectIds: state[reducerName][reducerKey],
    filterListObjectIds: state.filter && state.filter[entity],
    loading: state.request[FILTER_LIST_REQUEST],
    createAllowed: getPermissionAllowed(state, createCode),
    viewAllowed: permission && getPermissionAllowed(state, permission && permission.code),
    importAllowed: permission && getPermissionAllowed(state, importCode),
    pageTitle: (page) => getPageTitle(state, page),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { title } = ownProps;
  const entity = getEntityName(title);
  return {
    getFilterList: () => dispatch(getAll(entity))
  };
};

const ListLayoutConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListLayout);

export default withRouter(ListLayoutConnect);