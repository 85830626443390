import React from "react";
import PropTypes from "prop-types";
import { useApi } from "@modules/hooks";
import { getCustomerCommissionDetailWithCurrentUser } from "@modules/accounting/list/customer-commission/services";
import DetailComponent from "@modules/accounting/list/customer-commission/components/detail-component";

function CustomerCommissionDetail(props) {

  const { response } = useApi(getCustomerCommissionDetailWithCurrentUser, props.id, [props.id]);

  if (response == null) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <DetailComponent 
        entity={response}
        profilePage={true}
      />
    </div>
  );
}

CustomerCommissionDetail.propTypes = {
  id: PropTypes.number,
};

export default CustomerCommissionDetail;