export const FILTER_LIST_REQUEST = "FILTER_REQUEST";
export const FILTER_LIST_SUCCESS = "FILTER_REQUEST_SUCCESS";

export const FILTER_CREATE_REQUEST = "FILTER_CREATE_REQUEST";
export const FILTER_CREATE_SUCCESS = "FILTER_CREATE_SUCCESS";

export const FILTER_EDIT_REQUEST = "FILTER_EDIT_REQUEST";
export const FILTER_EDIT_SUCCESS = "FILTER_EDIT_SUCCESS";

export const FILTER_DELETE_REQUEST = "FILTER_DELETE_REQUEST";
export const FILTER_DELETE_SUCCESS = "FILTER_DELETE_SUCCESS";

export const FILTER_REORDER_REQUEST = "FILTER_REORDER_REQUEST";
export const FILTER_REORDER_SUCCESS = "FILTER_REORDER_SUCCESS";