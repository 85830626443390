export const LOAN_LIST_REQUEST = "LOAN_LIST_REQUEST"; 
export const LOAN_LIST_SUCCESS = "LOAN_LIST_REQUEST_SUCCESS";

export const LOAN_CREATE_REQUEST = "LOAN_CREATE_REQUEST"; 
export const LOAN_DETAIL_REQUEST = "LOAN_DETAIL_REQUEST"; 
export const LOAN_ARCHIVE_REQUEST = "LOAN_ARCHIVE_REQUEST"; 
export const LOAN_DELETE_REQUEST = "LOAN_DELETE_REQUEST"; 

export const LOAN_UNARCHIVE_REQUEST = "LOAN_UNARCHIVE_REQUEST"; 
export const LOAN_EDIT_REQUEST = "LOAN_EDIT_REQUEST"; 

export const LOAN_CREATE_FORM_NAME = "createLoan"; 
export const LOAN_EDIT_FORM_NAME = "editLoan";
export const PAY_LOAN_FORM_NAME = "payLoan";
export const PAYMENT_SCHEDULES_FIELD_NAME = "paymentSchedules";

export const LOAN_AUTOCOMPLETE_REQUEST = "LOAN_AUTOCOMPLETE_REQUEST";
export const LOAN_AUTOCOMPLETE_SUCCESS = "LOAN_AUTOCOMPLETE_SUCCESS";
export const LOAN_POST_REQUEST = "LOAN_POST_REQUEST";
export const LOAN_PAY_REQUEST = "LOAN_PAY_REQUEST";
export const LOAN_DECREASE_REQUEST = "LOAN_DECREASE_REQUEST";
export const LOAN_INCREASE_REQUEST = "LOAN_INCREASE_REQUEST";

export const LOAN_SUBMIT_RULES = (values) => {
  const { loanType } = values;
  let rules = {
    loanType: {
      required: true
    },
    chartAccountId: {
      required: true
    },
    bankId: {
      required: true
    },
    loanDate: {
      required: true
    },
    amount: {
      required: true
    },
    expenseChartAccountId: {
      required: true
    }
  };
  if (loanType == LOAN_TYPE_TERM_LOAN) {
    const newRules = {
      amount: {
        required: true
      },
      tenure: {
        required: true
      },
      interestRate: {
        required: true
      },
      currencyId: {
        required: true
      },
      firstPaymentDate: {
        required: true
      },
      monthlyPayment: {
        required: true
      },
      paymentSchedules: {
        required: true,
        type: "arrayInline",
        subFields: [
          {
            name: "dueDate"
          },
          {
            name: "principal",
            type: "number",
          },
          {
            name: "interest",
            type: "number",
          },
          {
            name: "totalPayment",
            type: "number",
          },
          {
            name: "outstanding",
            type: "number",
          },
        ],
      }
    };
    rules = Object.assign(rules, newRules);
  }
  if (loanType == LOAN_TYPE_TRADE_FINANCE_LOAN) {
    const newRules = {
      duration: {
        required: true
      },
      supplierId: {
        required: true
      },
    };
    rules = Object.assign(rules, newRules);
  }
  return rules;
};

export const MODAL_TYPE_INCREASE = 11;
export const MODAL_TYPE_INCREASE_TITLE = "Increase";
export const MODAL_TYPE_DECREASE = 22;
export const MODAL_TYPE_DECREASE_TITLE = "Decrease";
export const MODAL_TYPE_PAY_OFF = 33;
export const MODAL_TYPE_PAY_OFF_TITLE = "Pay off";

export const READ_LOAN = 261000;
export const CREATE_LOAN = 261001;
export const UPDATE_LOAN = 261002;
export const DELETE_LOAN = 261003;
export const ARCHIVE_LOAN = 261004;
export const UNARCHIVE_LOAN = 261005;
export const POST_LOAN = 261012;
export const PAY_LOAN = 261013;
export const PAY_OFF_LOAN  = 261014;
export const LOAN_INCREASE = 261015;
export const LOAN_DECREASE = 261016;

export const LOAN_TYPE_TERM_LOAN = 1;
export const LOAN_TYPE_TERM_LOAN_TITLE = "Term Loan";
export const LOAN_TYPE_TRADE_FINANCE_LOAN = 2;
export const LOAN_TYPE_TRADE_FINANCE_LOAN_TITLE = "Trade Finance Loan";
export const LOAN_TYPE_OVER_DRAFT = 3;
export const LOAN_TYPE_OVER_DRAFT_TITLE = "Over Draft Loan";

export const LOAN_TYPE_DATA_SOURCE = [
  {
    id: LOAN_TYPE_TERM_LOAN,
    name: LOAN_TYPE_TERM_LOAN_TITLE,
  },
  {
    id: LOAN_TYPE_TRADE_FINANCE_LOAN,
    name: LOAN_TYPE_TRADE_FINANCE_LOAN_TITLE,
  },
  {
    id: LOAN_TYPE_OVER_DRAFT,
    name: LOAN_TYPE_OVER_DRAFT_TITLE,
  }
];

export const LOAN_STATUS_DRAFT = 1;
export const LOAN_STATUS_DRAFT_TITLE = "Draft";
export const LOAN_STATUS_POST = 2;
export const LOAN_STATUS_POST_TITLE = "Active";
export const LOAN_STATUS_PARTIAL_PAY = 3;
export const LOAN_STATUS_PARTIAL_PAY_TITLE = "Partial Pay";
export const LOAN_STATUS_PAID = 4;
export const LOAN_STATUS_PAID_TITLE = "Paid";

export const LOAN_STATUS_STATUS_DATA_SOURCE = [
  {
    id: LOAN_STATUS_DRAFT,
    name: LOAN_STATUS_DRAFT_TITLE,
  },
  {
    id: LOAN_STATUS_POST,
    name: LOAN_STATUS_POST_TITLE,
  },
  {
    id: LOAN_STATUS_PARTIAL_PAY,
    name: LOAN_STATUS_PARTIAL_PAY_TITLE,
  },
  {
    id: LOAN_STATUS_PAID,
    name: LOAN_STATUS_PAID_TITLE,
  }
];

export const PAYMENT_SCHEDULES_STATUS_UN_PAID = 1;
export const PAYMENT_SCHEDULES_STATUS_UN_PAID_TITLE = "Unpaid";

export const PAYMENT_SCHEDULES_STATUS_SHORTAGE = 2;
export const PAYMENT_SCHEDULES_STATUS_SHORTAGE_TITLE = "Shortage";

export const PAYMENT_SCHEDULES_STATUS_OVERDUE = 3;
export const PAYMENT_SCHEDULES_STATUS_OVERDUE_TITLE = "Overdue";

export const PAYMENT_SCHEDULES_STATUS_PAID = 4;
export const PAYMENT_SCHEDULES_STATUS_PAID_TITLE = "Paid";

export const PAYMENT_SCHEDULES_STATUS_DATA_SOURCE = [
  {
    id: PAYMENT_SCHEDULES_STATUS_UN_PAID,
    name: PAYMENT_SCHEDULES_STATUS_UN_PAID_TITLE,
  },
  {
    id: PAYMENT_SCHEDULES_STATUS_SHORTAGE,
    name: PAYMENT_SCHEDULES_STATUS_SHORTAGE_TITLE,
  },
  {
    id: PAYMENT_SCHEDULES_STATUS_OVERDUE,
    name: PAYMENT_SCHEDULES_STATUS_OVERDUE_TITLE,
  },
  {
    id: PAYMENT_SCHEDULES_STATUS_PAID,
    name: PAYMENT_SCHEDULES_STATUS_PAID_TITLE,
  }
];