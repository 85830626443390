// Libs
import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

// Components
import Input from "@cores/components/Input";
import Title from "@cores/components/title";
import CheckBox from "@cores/components/check-box";
import InputPhoneNumber from "@cores/components/input-phone-number";
import UserAutoComplete from "@cores/components/auto-complete/user-account";
import ProvinceAutoComplete from "@cores/components/auto-complete/province";
import ChartAccountAutoComplete from "@cores/components/auto-complete/chart-of-account";

// Constants
import { CHART_ACCOUNT_TYPE_ASSET } from "@modules/accounting/list/chart-of-account/constants";

class FormComponent extends Component {

  render() {
    const { 
      entity,
      editable,
      stockInApproval,
      stockOutApproval,
      stockTransferApproval,
      stockAdjustmentApproval,
      isAccountingInstalled
    } = this.props;
    let managerIds = [];
    let chartAccountIds = [];
    let pendingChartAccountIds = [];
    if (editable) {
      const {
        managerId,
        chartAccountId,
        pendingChartAccountId,
      } = entity;
      managerIds.push(managerId);
      chartAccountIds.push(chartAccountId);
      pendingChartAccountIds.push(pendingChartAccountId);
    } else {
      const { chartAccountId } = this.props;
      if (isAccountingInstalled) {
        chartAccountIds.push(chartAccountId);
        pendingChartAccountIds.push(chartAccountId);

      }
    }
    return (
      <Row>
        <Col md="6" xs="12">
          <Input 
            autoFocus
            required
            name="name"
            label="Name"
          />
        </Col>
        <Col md="6" xs="12">
          <Input 
            required
            name="code"
            label="Code"
          />
        </Col>
        <Col md="6" xs="12">
          <UserAutoComplete
            label="Manager"
            name="managerId"
            editing={editable}
            ids={[managerIds]}
            required={stockInApproval || stockOutApproval || stockTransferApproval || stockAdjustmentApproval}
          />
        </Col>
        <Col md="6" xs="12">
          <InputPhoneNumber
            name="phoneNumber"
            label="Phone Number"
          />
        </Col>
        <Col md="6" xs="12">
          <Input
            name="city"
            label="City"
          />
        </Col>
        <Col md="6" xs="12">
          <ProvinceAutoComplete />
        </Col>
        <Col md="6" xs="12">
          <Input 
            rows={4}
            name="addressLine"
            label="Address Line"
            type="TextArea"
          />
        </Col>
        <Col md="6" xs="12">
          <Input 
            rows={4}
            type="TextArea"
            name="secondAddressLine"
            label="Second Address Line"
          />
        </Col>
        <Col md="12" xs="12">
          <CheckBox
            name="stockInApproval"
            label="Require Stock In Approval"
          />
        </Col>
        <Col md="12" xs="12">
          <CheckBox
            name="stockOutApproval"
            label="Require Stock Out Approval"
          />
        </Col>
        <Col md="12" xs="12">
          <CheckBox
            name="stockTransferApproval"
            label="Require Stock Transfer Approval"
          />
        </Col>
        <Col md="12" xs="12">
          <CheckBox
            name="stockAdjustmentApproval"
            label="Require Stock Adjustment Approval"
          />
        </Col>
        {isAccountingInstalled && (
          <Fragment>
            <Col xs="12">
              <Title title="Accounting" />
            </Col>
            <Col md="6" xs="12">
              <ChartAccountAutoComplete
                ids={chartAccountIds}
                type={[CHART_ACCOUNT_TYPE_ASSET]}
              />
            </Col>
            <Col md="6" xs="12">
              <ChartAccountAutoComplete
                ids={pendingChartAccountIds}
                name="pendingChartAccountId"
                label="Pending Chart of Account"
                type={[CHART_ACCOUNT_TYPE_ASSET]}
              />
            </Col>
          </Fragment>
        )}
      </Row>
    );
  }
}

FormComponent.propTypes = {
  entity: PropTypes.object,
  editable: PropTypes.bool,
  stockInApproval: PropTypes.bool,
  stockOutApproval: PropTypes.bool,
  stockTransferApproval: PropTypes.bool,
  stockAdjustmentApproval: PropTypes.bool,
  isAccountingInstalled: PropTypes.bool,
  chartAccountId: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const selector = formValueSelector(formName);
  return {
    stockInApproval: selector(state, "stockInApproval"),
    stockOutApproval: selector(state, "stockOutApproval"),
    stockTransferApproval: selector(state, "stockTransferApproval"),
    stockAdjustmentApproval: selector(state, "stockAdjustmentApproval"),
  };
};

export default connect(mapStateToProps)(FormComponent);