export const SHIPPER_LIST_REQUEST = "SHIPPER_LIST_REQUEST";
export const SHIPPER_LIST_SUCCESS = "SHIPPER_LIST_REQUEST_SUCCESS";
export const SHIPPER_CREATE_REQUEST = "SHIPPER_CREATE_REQUEST";
export const SHIPPER_DETAIL_REQUEST = "SHIPPER_DETAIL_REQUEST";
export const SHIPPER_ARCHIVE_REQUEST = "SHIPPER_ARCHIVE_REQUEST";
export const SHIPPER_DELETE_REQUEST = "SHIPPER_DELETE_REQUEST";
export const SHIPPER_UNARCHIVE_REQUEST = "SHIPPER_UNARCHIVE_REQUEST";
export const SHIPPER_EDIT_REQUEST = "SHIPPER_EDIT_REQUEST";
export const SHIPPER_AUTO_COMPLETE_REQUEST = "SHIPPER_AUTO_COMPLETE_REQUEST";
export const SHIPPER_AUTO_COMPLETE_SUCCESS = "SHIPPER_AUTO_COMPLETE_REQUEST_SUCCESS";
export const SHIPPER_CREATE_FORM_NAME = "createShipper";
export const SHIPPER_EDIT_FORM_NAME = "editShipper";

export const SHIPPER_SUBMIT_RULES = (values) => {
  const { isRequiredChartAccount } = values;
  return {
    name: {
      required: true,
    },
    emailAddress: {
      required: true,
    },
    phoneNumber: {
      required: true,
    },
    payableChartAccountId: {
      required: isRequiredChartAccount,
    },
  };
};
export const READ_SHIPPER = 222000;
export const CREATE_SHIPPER = 222001;
export const UPDATE_SHIPPER = 222002;
export const ARCHIVE_SHIPPER = 222004;
export const DELETE_SHIPPER = 222003;
export const UNARCHIVE_SHIPPER = 222005;
export const ASSIGN_CHART_OF_ACCOUNT_SIPPER = 222011;