export const STATUS_ACTIVE_ID = 1;
export const STATUS_DEACTIVE_ID = -1;
export const STATUS_BOOLEAN_TRUE_ID = 1;
export const STATUS_BOOLEAN_FALSE_ID = 0;
export const STATUS_ACTIVE_NAME = "Active";
export const STATUS_DEACTIVE_NAME = "Deactive";
export const STATUS_DATASOURCE = [
  {
    id: STATUS_ACTIVE_ID,
    name: STATUS_ACTIVE_NAME
  },
  {
    id: STATUS_DEACTIVE_ID,
    name: STATUS_DEACTIVE_NAME,
  }
];