export const LEAVE_SETTING_SUBMIT_RULES = {
  numberHourPerDay: {
    required: true,
  },
  emailTemplate: {
    type: "object",
    value: {
      required: true
    }
  },
};
export const LEAVE_SETTING_PATH = "/human-resource/leave/leave-setting";

export const READ_LEAVE_SETTING_CODE = 158011;
export const UPDATE_LEAVE_SETTING_CODE = 158012;