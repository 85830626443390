import React from "react";
import AutoComplete from "../../autocomplete-static";

class TitleAutoComplete extends React.Component {

  render() {
    const dataSource = [
      {
        id: "Miss",
        name: "Miss"
      },
      {
        id: "Mr.",
        name: "Mr."
      },
      {
        id: "Mrs.",
        name: "Mrs."
      },
      {
        id: "Ms.",
        name: "Ms."
      }
    ];
    return (
      <AutoComplete 
        required
        stringValue
        label="Title"
        name="title"
        dataSource={dataSource}
        {...this.props}
      />
    );
  }
}

export default TitleAutoComplete;