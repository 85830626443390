import { READ_PURCHASE_ORDER } from "@modules/purchase/list/purchase-order/constants";
import { READ_PURCHASE_ORDER_TYPE } from "@modules/purchase/list/purchase-order-type/constants";
import { READ_SHIPPER } from "@modules/purchase/list/shipper/constants";
import { READ_SUPPLIER } from "@modules/purchase/list/supplier/constants";
import { READ_SUPPLIER_TYPE } from "@modules/purchase/list/supplier-type/constants";
import { READ_SHIPPER_PAYMENT } from "@modules/purchase/list/shipper-payment/constants";
import { READ_PO_SETTING } from "@modules/purchase/list/setting/constants";

// Report
import { PURCHASE_REPORT_MENU } from "./reports";

export default {
  sideBarTitle: "Purchase",
  hasDashboard: true,
  isFixed: false,
  slug: "purchase",
  display: true,
  menus: [
    {
      id: 1,
      title: "List",
      slug: "list",
      icon: "UnorderedListOutlined",
      sub: [
        {
          id: 1,
          title: "Purchase Order",
          slug: "purchase-order",
          code: READ_PURCHASE_ORDER,
        },
        {
          id: 2,
          title: "Purchase Order Type",
          slug: "purchase-order-type",
          code: READ_PURCHASE_ORDER_TYPE,
        },
        {
          id: 3,
          title: "Supplier",
          slug: "supplier",
          code: READ_SUPPLIER,
        },
        {
          id: 4,
          title: "Supplier Type",
          slug: "supplier-type",
          code: READ_SUPPLIER_TYPE,
        },
        {
          id: 5,
          title: "Shipper",
          slug: "shipper",
          code: READ_SHIPPER,
        },
        {
          id: 6,
          title: "Shipper Payment",
          slug: "shipper-payment",
          code: READ_SHIPPER_PAYMENT,
        },
        {
          id: 7,
          title: "Setting",
          slug: "purchase-order-setting",
          code: READ_PO_SETTING,
        },
      ]
    },
    {
      id: 2,
      title: "Report",
      slug: "report",
      icon: "FileTextOutlined",
      sub: PURCHASE_REPORT_MENU,
    },
  ]
};