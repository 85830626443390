import { READ_CUSTOMER } from "@sale/customer-center/customer/constants";
import { READ_SALE_INVOICE } from "@sale/sale-center/sale-invoice/constants";
import { READ_SALE_ORDER } from "@sale/sale-center/sale-order/constants";
import { READ_PROMOTION } from "@sale/sale-center/promotion/constants";
import { READ_PAYMENT_TERM } from "@sale/setup/payment-term/constants";
import { READ_COMPANY_GROUP } from "@sale/customer-center/company-group/constants";
import { READ_SALE_COMMISSION } from "@sale/setup/sale-commission/constants";
import { READ_CUSTOMER_COMPANY } from "@sale/customer-center/customer-company/constants";
import { READ_SALE_TEAM } from "@sale/setup/sale-team/constants";
import { READ_INSTALLATION } from "@sale/service/installation/constants";
import { READ_INSTALLATION_TEMPLATE } from "@sale/service/installation-template/constants";
import { READ_ITEM_INSTALLATION_CODE } from "@sale/service/item-installation/constants";
import { READ_SERVICE } from "@sale/service/service/constants";
import { READ_SALE_SETTING_CODE } from "@sale/setup/setting/constants";
import { READ_QUOTATION } from "@sale/sale-center/quotation/constants";
import { READ_BID } from "@sale/sale-center/bid/constants";
import { READ_SALE_RECEIPT } from "@sale/sale-center/sale-receipt/constants";
import { READ_CUSTOMER_GROUP } from "@sale/customer-center/customer-group/constants";

// import { DETAILED_REPORT_SALE_ORDER_CODE } from "@modules/reports/constants";

// Constants
import { SALE_REPORT_MENU } from "./reports";

export default {
  sideBarTitle: "Sale",
  hasDashboard: true,
  isFixed: false,
  slug: "sale",
  display: true,
  menus: [
    {
      id: 1,
      title: "Sale Center",
      slug: "sale-center",
      icon: "DollarOutlined",
      sub: [
        {
          id: 5,
          title: "Quotation",
          slug: "quotation",
          code: READ_QUOTATION,
        },
        {
          id: 6,
          title: "Sale Order",
          slug: "sale-order",
          code: READ_SALE_ORDER,
        },
        {
          id: 7,
          title: "Sale Invoice",
          slug: "sale-invoice",
          code: READ_SALE_INVOICE,
        },
        {
          id: 8,
          title: "Sale Receipt",
          slug: "sale-receipt",
          code: READ_SALE_RECEIPT,
        },
        {
          id: 13,
          title: "Bid",
          slug: "bid",
          code: READ_BID,
        },
        {
          id: 9,
          title: "Promotion",
          slug: "promotion",
          code: READ_PROMOTION,
        },
        {
          id: 10,
          title: "Detailed Sale",
          slug: "detailed-sale",
          // code: DETAILED_REPORT_SALE_ORDER_CODE,
        },
      ]
    },
    {
      id: 2,
      title: "Customer Center",
      slug: "customer-center",
      icon: "TeamOutlined",
      sub: [
        {
          id: 1,
          title: "Customer",
          slug: "customer",
          code: READ_CUSTOMER,
        },
        {
          id: 2,
          title: "Customer Company",
          slug: "customer-company",
          code: READ_CUSTOMER_COMPANY,
        },
        {
          id: 3,
          title: "Customer Group",
          slug: "customer-group",
          code: READ_CUSTOMER_GROUP,
        },
        {
          id: 4,
          title: "Company Group",
          slug: "company-group",
          code: READ_COMPANY_GROUP,
        },
      ],
    },
    {
      id: 3,
      title: "Services",
      slug: "services",
      icon: "ToolOutlined",
      sub: [
        {
          id: 1,
          title: "Service Entry",
          slug: "service-entry",
          code: READ_SERVICE,
        },
        {
          id: 2,
          title: "Item Installation",
          slug: "item-installation",
          code: READ_ITEM_INSTALLATION_CODE,
        },
        {
          id: 3,
          title: "Installation",
          slug: "installation",
          code: READ_INSTALLATION,
        },
        {
          id: 4,
          title: "Installation Template",
          slug: "installation-template",
          code: READ_INSTALLATION_TEMPLATE,
        },
      ]
    },
    {
      id: 4,
      title: "Setup",
      slug: "setup",
      icon: "ControlOutlined",
      sub: [
        {
          id: 4,
          title: "Payment Term",
          slug: "payment-term",
          code: READ_PAYMENT_TERM,
        },
        {
          id: 10,
          title: "Sale Commission",
          slug: "sale-commission",
          code: READ_SALE_COMMISSION,
        },
        {
          id: 12,
          title: "Sale Team",
          slug: "sale-team",
          code: READ_SALE_TEAM,
        },
        {
          id: 14,
          title: "Sale Setting",
          slug: "sale-setting",
          code: READ_SALE_SETTING_CODE,
        },
      ]
    },
    {
      id: 5,
      title: "Report",
      slug: "report",
      icon: "FileTextOutlined",
      sub: SALE_REPORT_MENU,
    },
  ]
};