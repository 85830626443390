import * as AutoCompleteConstants from "../../constants/auto-complete";

export default function(state={}, action) {
  switch (action.type) {
  case AutoCompleteConstants.SET_AUTOCOMPLETE:
    return setAutoComplete(state, action);
  }
  return state;
}

function setAutoComplete(state, action) {
  const { autoComplete, requestName } = action;
  const requestObject = {
    [requestName]: {
      list: [],
      search: [],
    }
  };
  requestObject[requestName]["list"] = autoComplete;
  return Object.assign({}, state, requestObject);
}