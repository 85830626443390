import { schema } from "normalizr";
import { LeaveTypeSchema } from "../../leave-type/schema";
import { LeavePolicyRuleArraySchema } from "./rules";

const LeavePolicySchema = new schema.Entity("leavePolicies", {
  leaveType: LeaveTypeSchema,
  leavePolicyRules: LeavePolicyRuleArraySchema
});
const LeavePolicyArraySchema = new schema.Array(LeavePolicySchema);

export { 
  LeavePolicySchema, 
  LeavePolicyArraySchema
};