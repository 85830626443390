import React from "react";
import PropTypes from "prop-types";
import { Table, Button, Form } from "antd";
import { connect } from "react-redux";
import { 
  formValueSelector,
  arrayPush,
  change,
} from "redux-form";
import classnames from "classnames";
import _ from "lodash";
const FormItem = Form.Item;

import { clearErrorForm } from "@modules/utils/action-creator";

class TableFieldArrayInline extends React.Component {

  componentDidMount() {
    const { onFirstAdd } = this.props;
    if (onFirstAdd) {
      this.onAddNewClick();
    }
    this.onEventLoad();
  }

  handleEventLister = (event) => {
    if (event.altKey && event.which === 78) {
      event.preventDefault();
      this.onAddNewClick();
    }
  }

  onEventLoad = () => {
    const { shouldAddEventListerOnFirstLoad } = this.props;
    if (shouldAddEventListerOnFirstLoad) {
      window.addEventListener("keydown", this.handleEventLister);
    }
    
  }

  componentWillUnmount() {
    const { shouldAddEventListerOnFirstLoad } = this.props;
    if (shouldAddEventListerOnFirstLoad) {
      window.removeEventListener("keydown", this.handleEventLister);
    }
  }

  onAddNewClick = () => {
    let { arrayPush, defaultInitializeValue, onAddNew, dataSource } = this.props;
    if (onAddNew) {
      onAddNew(dataSource);
    }
    if (typeof defaultInitializeValue == "function") {
      defaultInitializeValue = defaultInitializeValue(dataSource);
    }
    arrayPush(defaultInitializeValue);
  }

  onDeleteClick = (index) => {
    const { fields: { remove } } = this.props;
    remove(index);
  }

  onBlur = () => {
    window.removeEventListener("keydown", this.handleEventLister);
  }

  onFocus = () => {
    window.addEventListener("keydown", this.handleEventLister);
  }

  renderExistingData = (index, existedKey) => {
    const {
      dataSource,
      newExisted,
    } = this.props;
    const objectEntity = dataSource[index];
    const key = objectEntity[existedKey];
    let existingData = _.filter(dataSource, function(value) {
      return value[existedKey] !== key;
    });
    if (newExisted) {
      existingData = existingData.concat(newExisted);
    }
    const existingDataArray = [];
    existingData.map((values) => {
      if (values[existedKey]) {
        values.existedKey = values[existedKey];
        existingDataArray.push(values);
      }
    });
    return existingDataArray;
  }

  columns = () => {
    const { fields, columns, updateField, clearErrorForm } = this.props;
    if (columns) {
      return columns(fields, this.onDeleteClick, this.renderExistingData, updateField, clearErrorForm, this.onBlur, this.onFocus);
    }
  }

  renderFooter = () => {
    return (
      <Button
        ghost
        size="small"
        type="primary"
        style={{padding: "0 5px"}}
        onClick={this.onAddNewClick}
      >
        Add New
      </Button>
    );
  }

  render() {
    const {
      dataSource,
      required,
      tableSize,
      label,
      footer,
      rowKey,
      hasChild,
      style,
      className,
      components,
      meta: { error },
      expandedRowRender,
      expandedRowKeys,
      defaultExpandAllRows,
    } = this.props;
    const validateStatus = classnames(error ? "error" : "success");
    return (
      <FormItem 
        label={label} 
        help={error}
        required={required}
        className={validateStatus}
      >
        {hasChild ? (
          dataSource && dataSource.length > 0 && (
            <Table
              rowKey={rowKey}
              size={tableSize}
              style={style}
              columns={this.columns()} 
              dataSource={dataSource}
              pagination={false}
              components={components}
              defaultExpandAllRows
              footer={footer ? this.renderFooter: null}
              className={`table-responsive mb-1 ${className}`}
            /> 
          )
        ) : (
          <Table
            size={tableSize}
            style={style}
            rowKey={rowKey}
            columns={this.columns()} 
            dataSource={dataSource}
            pagination={false}
            components={components}
            expandedRowRender={expandedRowRender}
            footer={footer ? this.renderFooter: null}
            defaultExpandAllRows={defaultExpandAllRows}
            className={`table-responsive mb-1 ${className}`}
            expandedRowKeys={expandedRowKeys && expandedRowKeys}
          /> 
        )}
      </FormItem>
    ); 
  }
}

TableFieldArrayInline.propTypes = {
  fields: PropTypes.any,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  columns: PropTypes.any,
  footer: PropTypes.bool,
  rowKey: PropTypes.string,
  tableSize: PropTypes.string,
  style: PropTypes.object,
  hasChild: PropTypes.bool,
  arrayPush: PropTypes.func,
  onAddNew: PropTypes.func,
  className: PropTypes.string,
  onFirstAdd: PropTypes.bool,
  updateField: PropTypes.func,
  newExisted: PropTypes.array,
  dataSource: PropTypes.array,
  confirmTitle: PropTypes.string,
  clearErrorForm: PropTypes.func,
  expandedRowRender: PropTypes.func,
  expandedRowKeys: PropTypes.array,
  defaultExpandAllRows: PropTypes.bool,
  components: PropTypes.any,
  defaultInitializeValue: PropTypes.object,
  shouldAddEventListerOnFirstLoad: PropTypes.bool,
};

TableFieldArrayInline.defaultProps = {
  footer: true,
  hasChild: false,
  onFirstAdd: true,
  tableSize: "small",
  dataSource: [],
  shouldAddEventListerOnFirstLoad: true,
  defaultExpandAllRows: false,
  expandedRowKeys: [],
  defaultInitializeValue: {},
};

const mapStateToProps = (state, ownProps) => {
  const { meta: { form }, fields: { name } } = ownProps;
  const selector = formValueSelector(form);
  return {
    dataSource: selector(state, name),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { meta: { form }, fields: { name } } = ownProps;
  return {
    updateField: (field, value) => dispatch(change(form, field, value)),
    arrayPush: (values) => dispatch(arrayPush(form, name, values)),
    clearErrorForm: () => dispatch(clearErrorForm(form, name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableFieldArrayInline);