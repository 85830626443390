export const JOB_OPENING_LIST_REQUEST = "JOB_OPENING_LIST_REQUEST"; 
export const JOB_OPENING_LIST_SUCCESS = "JOB_OPENING_LIST_REQUEST_SUCCESS";
export const JOB_OPENING_CREATE_REQUEST = "JOB_OPENING_CREATE_REQUEST"; 
export const JOB_OPENING_DETAIL_REQUEST = "JOB_OPENING_DETAIL_REQUEST"; 
export const JOB_OPENING_ARCHIVE_REQUEST = "JOB_OPENING_ARCHIVE_REQUEST"; 
export const JOB_OPENING_DELETE_REQUEST = "JOB_OPENING_DELETE_REQUEST"; 
export const JOB_OPENING_UNARCHIVE_REQUEST = "JOB_OPENING_UNARCHIVE_REQUEST"; 
export const JOB_OPENING_EDIT_REQUEST = "JOB_OPENING_EDIT_REQUEST"; 
export const JOB_OPENING_CREATE_FORM_NAME = "createJobOpening"; 
export const JOB_OPENING_EDIT_FORM_NAME = "editJobOpening"; 
export const JOB_OPENING_AUTOCOMPLETE_REQUEST = "JOB_OPENING_AUTOCOMPLETE_REQUEST";
export const JOB_OPENING_AUTOCOMPLETE_SUCCESS = "JOB_OPENING_AUTOCOMPLETE_SUCCESS";
export const JOB_OPENING_STATUS_TO_OPEN_REQUEST = "JOB_OPENING_STATUS_TO_OPEN_REQUEST";
export const JOB_OPENING_STATUS_TO_CLOSE_REQUEST = "JOB_OPENING_STATUS_TO_CLOSE_REQUEST";

export const JOB_OPENING_STATUS_DRAFT = 1;
export const JOB_OPENING_STATUS_DRAFT_TITLE = "Draft";
export const JOB_OPENING_STATUS_OPEN = 2;
export const JOB_OPENING_STATUS_OPEN_TITLE = "Open";
export const JOB_OPENING_STATUS_CLOSED = 3;
export const JOB_OPENING_STATUS_CLOSED_TITLE = "Closed";
export const JOB_OPENING_STATUS_EXPIRED = 4;
export const JOB_OPENING_STATUS_EXPIRED_TITLE = "Expired";

export const JOB_OPENING_SUBMIT_RULES = {
  title: {
    required: true,
  },
  numberOfHiring: {
    required: true,
  },
  expireDate: {
    required: true,
  },
  description: {
    required: true,
  },
  requirement: {
    required: true,
  }
};

export const JOB_OPENING_STATUS = [
  {
    id: JOB_OPENING_STATUS_DRAFT,
    name: JOB_OPENING_STATUS_DRAFT_TITLE
  },
  {
    id: JOB_OPENING_STATUS_OPEN,
    name: JOB_OPENING_STATUS_OPEN_TITLE,
  },
  {
    id: JOB_OPENING_STATUS_CLOSED,
    name: JOB_OPENING_STATUS_CLOSED_TITLE,
  },
  {
    id: JOB_OPENING_STATUS_EXPIRED,
    name: JOB_OPENING_STATUS_EXPIRED_TITLE,
  }
];

export const READ_JOB_OPENING = 161000;
export const CREATE_JOB_OPENING = 161001;
export const UPDATE_JOB_OPENING = 161002;
export const DELETE_JOB_OPENING = 161003;
export const ARCHIVE_JOB_OPENING = 161004;
export const UNARCHIVE_JOB_OPENING = 161005;
export const PRINT_JOB_OPENING = 161007;
export const OPEN_JOB_OPENING = 161011;
export const CLOSE_JOB_OPENING = 161012;