import { schema } from "normalizr";
import { EmployeeSchema } from "./index";
import { LeaveTypeSchema } from "@modules/hr/leave/leave-type/schema";
import { LeavePolicySchema } from "@modules/hr/leave/leave-policy/schema";

const EmployeeLeaveApproverSchema = new schema.Entity("employeeLeaveApprovers", {
  employee: EmployeeSchema
});

const EmployeeLeavePolicySchema = new schema.Entity("employeeLeavePolicies", {
  leavePolicy: LeavePolicySchema,
  leaveType: LeaveTypeSchema
});

const EmployeeLeavePolicyArraySchema = new schema.Array(EmployeeLeavePolicySchema);
const EmployeeLeaveApproverArraySchema = new schema.Array(EmployeeLeaveApproverSchema);

const EmployeeLeaveSettingSchema = new schema.Entity("employeeLeaveSettings", {
  employee: EmployeeSchema,
  employeeLeaveApprovers: EmployeeLeaveApproverArraySchema,
  employeeLeavePolicies: EmployeeLeavePolicyArraySchema
});

export { EmployeeLeaveSettingSchema };