// Libs
import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import Media from "react-media";
import styled from "styled-components";

const ContentWrapperWithCollapse = styled(Layout.Content)`
  margin: ${props => props.isFullScreen ? "0px" : `55px 0px 0px ${!props.shouldToggle ? "0px" : props.collapsed ? "80px" : "230px"}`};
`;

const Content = styled(Layout.Content)`
  margin: 55px 0px 0px 0px !important;
`;

class LayoutContent extends Component {

  render() {
    const { children, collapsed, shouldToggle, isFullScreen, theme } = this.props;
    return (
      <Media query={{ maxWidth: 1024 }}>
        {matches =>
          !matches ? (
            <Fragment>
              <ContentWrapperWithCollapse
                theme={theme}
                collapsed={collapsed}
                shouldToggle={shouldToggle}
                isFullScreen={isFullScreen}
              >
                {children}
              </ContentWrapperWithCollapse>
            </Fragment>
          ) : (
            <Content theme={theme}>
              {children}
            </Content>
          )}
      </Media>
    );
  }
}

LayoutContent.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  shouldToggle: PropTypes.bool,
  isFullScreen: PropTypes.bool,
};

export default LayoutContent;