export const BANK_SETTING_SUBMIT_RULES = {
  bankDepositCreditChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  bankWithdrawDebitChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
};

export const READ_BANK_SETTING = 175013;
export const UPDATE_BANK_SETTING = 175014;

export const BANK_SETTING_PATH = "/accounting/banking/bank-setting";

export const BANK_DEPOSIT_CREDIT_CHART_OF_ACCOUNT_REQUEST = "BANK_DEPOSIT_CREDIT_CHART_OF_ACCOUNT_REQUEST";
export const BANK_WITHDRAW_DEBIT_CHART_OF_ACCOUNT_REQUEST = "BANK_WITHDRAW_DEBIT_CHART_OF_ACCOUNT_REQUEST";
