import React, { Component } from "react";
import { Button, Form } from "antd";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Row, Col } from "reactstrap";

// Components 
import Input from "@cores/components/Input";

class CheckForm extends Component {

  render() {
    const { 
      icon, 
      label, 
      handleSubmit,
      loading 
    } = this.props;
    return (
      <Form onFinish={() => handleSubmit()} layout="vertical">
        <Row>
          <Col md="12" sm="12" xs="12">
            <Input 
              rows="4"
              label="Description"
              name="description"
              type="TextArea"
            />
          </Col>
          <Col md="12" sm="12" xs="12">
            <Button 
              ghost
              icon={icon}
              type="primary"
              htmlType="submit"
              loading={loading}
              className="float-right" 
            >
              {label}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

CheckForm.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default reduxForm()(CheckForm);