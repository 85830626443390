import { CLEAR_ERROR } from "@/constants/clear-error";

export default function (state, action) {
  switch (action.type) {
  case CLEAR_ERROR: {
    if (state && state.submitErrors) {
      return {
        ...state,
        submitErrors: {
          ...state.submitErrors,
          [action.field]: undefined
        },
      };
    }
    return state;
  }
  default:
    return state;
  }
}