import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { Card, Button, PageHeader } from "antd";
import { SaveOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

// Helpers
import { getBreadcrumbPageLabel } from "@modules/helpers";
import Breadcrumb from "@cores/components/breadcrumb";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      saveAndContinueCreateLoading: false,
    };
    this.onEventLoad();
  }

  onSave = () => {
    const { onSave } = this.props;
    this.setState({
      saveLoading: true,
    }, () => {
      onSave();
    });
  }

  onSaveAndContinueCreate = () => {
    const { onSaveAndContinueCreate } = this.props;
    this.setState({
      saveAndContinueCreateLoading: true,
    }, () => {
      onSaveAndContinueCreate();
    });
  }

  onEventLoad = () => {
    window.addEventListener("keydown", this.handleEventLister);
  }

  handleEventLister = (event) => {
    const { history, match: { path }, } = this.props;
    const currentPath = path.split("/");
    currentPath.splice(-1,1);
    const previousPath = currentPath.join("/");
    if (event.which === 27) {
      event.preventDefault();
      history.push(previousPath);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEventLister);
    this.setState({
      saveLoading: false,
      saveAndContinueCreateLoading: false,
    });
  }

  render() {
    const {  
      title,
      loading, 
      match: { path },
      shouldDisabled,
      extraButtons: ExtraButtons,
    } = this.props;
    const currentPath = path.split("/");
    currentPath.splice(-1,1);
    const previousPath = currentPath.join("/");
    const { saveLoading, saveAndContinueCreateLoading } = this.state;
    return (
      <Card style={{padding:0}} bodyStyle={{padding:0}}>
        <PageHeader
          title={getBreadcrumbPageLabel(title)}
          className="site-page-header"
          subTitle={status ? status : null}
          extra={[
            !shouldDisabled && (
              <Button 
                type="primary"
                onClick={this.onSave}
                icon={<SaveOutlined />}
                className="mr-1"
                loading={saveLoading && loading}
              >
                <FormattedMessage id="createLayout.save" />
              </Button>
            ),
            !shouldDisabled && (
              <Button 
                type="primary"
                className="mr-1"
                icon={<PlusCircleOutlined />}
                onClick={this.onSaveAndContinueCreate}
                loading={saveAndContinueCreateLoading && loading}
              >
                <FormattedMessage id="createLayout.saveAndNew" />
              </Button>
            ),
            ExtraButtons && (
              <ExtraButtons {...this.props} />
            ),
            <Link to={previousPath} key="discard">
              <Button 
                ghost
                type="primary"
                icon={<CloseCircleOutlined />}
              >
                <FormattedMessage id="createLayout.discard" />
              </Button>
            </Link>
          ]}
          breadcrumbRender={() => (
            <Breadcrumb 
              breadcrumbs={[
                getBreadcrumbPageLabel(title),
                "Create"
              ]} 
            />
          )}
        >
        </PageHeader>
      </Card>
    );
  }
}

Header.propTypes = {
  match: PropTypes.any,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  history: PropTypes.func,
  extraButtons: PropTypes.any,
  breadcrumbs: PropTypes.array,
  shouldDisabled: PropTypes.bool,
  shouldContinueEdit: PropTypes.bool,
  shouldContinueDesign: PropTypes.bool,
  onSaveAndContinueEdit: PropTypes.func,
  onSaveAndContinueCreate: PropTypes.func,
};

export default withRouter(Header);