export const ITEM_SPECIFICATION_TEMPLATE_LIST_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_LIST_REQUEST"; 
export const ITEM_SPECIFICATION_TEMPLATE_LIST_SUCCESS = "ITEM_SPECIFICATION_TEMPLATE_LIST_REQUEST_SUCCESS";

export const ITEM_SPECIFICATION_TEMPLATE_CREATE_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_CREATE_REQUEST"; 
export const ITEM_SPECIFICATION_TEMPLATE_DETAIL_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_DETAIL_REQUEST"; 
export const ITEM_SPECIFICATION_TEMPLATE_ARCHIVE_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_ARCHIVE_REQUEST"; 
export const ITEM_SPECIFICATION_TEMPLATE_DELETE_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_DELETE_REQUEST"; 

export const ITEM_SPECIFICATION_TEMPLATE_UNARCHIVE_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_UNARCHIVE_REQUEST"; 
export const ITEM_SPECIFICATION_TEMPLATE_EDIT_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_EDIT_REQUEST"; 

export const ITEM_SPECIFICATION_TEMPLATE_CREATE_FORM_NAME = "createItemSpecificationTemplate"; 
export const ITEM_SPECIFICATION_TEMPLATE_EDIT_FORM_NAME = "editItemSpecificationTemplate";

export const ITEM_SPECIFICATION_TEMPLATE_AUTOCOMPLETE_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_AUTOCOMPLETE_REQUEST";
export const ITEM_SPECIFICATION_TEMPLATE_AUTOCOMPLETE_SUCCESS = "ITEM_SPECIFICATION_TEMPLATE_AUTOCOMPLETE_SUCCESS";
export const ITEM_SPECIFICATION_TEMPLATE_AUTO_COMPLETE_REQUEST = "ITEM_SPECIFICATION_TEMPLATE_AUTO_COMPLETE_REQUEST";

export const ITEM_SPECIFICATION_TEMPLATE_SUBMIT_RULES = {
  name: {
    required: true,
  },
  specificationTemplates: {
    required: true,
    type: "array",
  }
};

export const READ_ITEM_SPECIFICATION_TEMPLATE = 270000;
export const CREATE_ITEM_SPECIFICATION_TEMPLATE = 270001;
export const UPDATE_ITEM_SPECIFICATION_TEMPLATE = 270002;
export const DELETE_ITEM_SPECIFICATION_TEMPLATE = 270003;
export const ARCHIVE_ITEM_SPECIFICATION_TEMPLATE = 270004;
export const UNARCHIVE_ITEM_SPECIFICATION_TEMPLATE = 270005;