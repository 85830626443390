import React from "react";

// Human Resource
import { READ_EMPLOYEE } from "@modules/hr/employee-directory/employee/constants";
import { READ_POSITION } from "@modules/hr/employee-directory/position/constants";
import { READ_DEPARTMENT } from "@modules/hr/employee-directory/department/constants";
import { READ_JOB_TYPE } from "@modules/hr/employee-directory/job-type/constants";
import { READ_EMPLOYMENT_CARD } from "@modules/hr/employee-directory/employment-card/constants";
import { READ_EMPLOYEE_SETTING_CODE } from "@/modules/hr/employee-directory/setting/constants";
import { READ_JOB_OPENING } from "@modules/hr/recruitment/job-opening/constants";
import { READ_JOB_APPLICANT } from "@modules/hr/recruitment/job-applicant/constants";
import { READ_RECRUITMENT_STAGE } from "@modules/hr/recruitment/recruitment-stage/constants";
import { READ_OFFER_TERM } from "@modules/hr/recruitment/offer-term/constants";
import { READ_OFFER_LETTER } from "@modules/hr/recruitment/offer-letter/constants";
import { READ_RECRUITMENT_SETTING_CODE } from "@/modules/hr/recruitment/setting/constants";
import { READ_LEAVE_REQUEST } from "@modules/hr/leave/leave-request/constants";
import { READ_LEAVE_ALLOCATION } from "@modules/hr/leave/leave-allocation/constants";
import { READ_LEAVE_TYPE } from "@modules/hr/leave/leave-type/constants";
import { READ_LEAVE_POLICY } from "@modules/hr/leave/leave-policy/constants";
import { READ_HOLIDAY_GROUP } from "@modules/hr/leave/holiday-group/constants";
import { READ_LEAVE_SETTING_CODE } from "@modules/hr/leave/setting/constants";
import { READ_WORKING_DAY } from "@modules/hr/attendance/working-day/constants";
import { VIEW_ATTENDANCE_SUMMARY } from "@modules/hr/attendance/attendance-entry/constants";
import { READ_ATTENDANCE_SETTING_CODE } from "@modules/hr/attendance/setting/constants";
import { READ_PLAN } from "@modules/hr/appraisal/plan/constants";
import { READ_FORM } from "@modules/hr/appraisal/form/constants";
import { READ_ANNIVERSARY } from "@modules/hr/appraisal/anniversary/constants";
import { READ_PAYSLIP } from "@/modules/hr/payroll/payslip/constants";
import { READ_PAYSLIP_BATCH_CODE } from "@/modules/hr/payroll/payslip-batch/constants";
import { READ_SALARY_STRUCTURE } from "@modules/hr/payroll/salary-structure/constants";
import { READ_EARNING_TYPE } from "@modules/hr/payroll/earning-type/constants";
import { READ_DEDUCTION_TYPE } from "@modules/hr/payroll/deduction-type/constants";
import { READ_PAYROLL_SETTING_CODE } from "@modules/hr/payroll/setting/constants";
import { READ_INCENTIVE_TEMPLATE } from "@modules/hr/payroll/incentive-template/constants";
import { READ_CASH_ADVANCED } from "@modules/hr/cash-advanced/cash-advanced/constants";
import { READ_CASH_ADVANCED_SETTING_CODE } from "@modules/hr/cash-advanced/setting/constants";

// Delivery 
import { READ_DELIVERY_ORDER } from "@modules/delivery/delivery-management/delivery-order/constants";
import { READ_DELIVERY_TRACKING } from "@modules/delivery/delivery-management/delivery-tracking/constants";

// Inventory
import { READ_TAG } from "@/modules/inventory/item/tag/constants";
import { READ_ITEM } from "@modules/inventory/item/item/constants";
import { READ_BRAND } from "@/modules/inventory/item/brand/constants";
import { READ_SUPPLIER } from "@modules/purchase/list/supplier/constants";
import { READ_CATEGORY } from "@modules/inventory/item/category/constants";
import { READ_ATTRIBUTE } from "@modules/inventory/item/attribute/constants";
import { READ_PRICE_LIST } from "@/modules/inventory/item/price-list/constants";
import { READ_SUPPLIER_TYPE } from "@modules/purchase/list/supplier-type/constants";
import { READ_CUSTOMIZE_ITEM } from "@/modules/inventory/item/customize-item/constants";
import { READ_WARRANTY } from "@/modules/inventory/item/warranty/constants";
import { READ_JUNK_OUT } from "@modules/inventory/stock/junk-out/constants";
import { READ_WAREHOUSE } from "@modules/inventory/stock/warehouse/constants";
import { READ_STOCK_OUT } from "@modules/inventory/stock/stock-out/constants";
import { READ_STOCK_SETTING } from "@modules/inventory/stock/setting/constants";
import { READ_STOCK_TRANSFER } from "@modules/inventory/stock/stock-transfer/constants";
import { READ_ITEM_MOVEMENT_LOG } from "@modules/inventory/stock/item-movement-log/constants";
import { READ_ASSET } from "@modules/inventory/asset/asset/constants";
import { READ_ASSET_REQUEST } from "@modules/inventory/asset/asset-request/constants";
import { READ_ASSET_LOG } from "@modules/inventory/asset/asset-log/constants";
import { READ_ITEM_SPECIFICATION } from "@modules/inventory/item/item-specification/constants";
import { READ_ITEM_SPECIFICATION_TEMPLATE } from "@modules/inventory/item/item-specification-template/constants";

// Accounting
import { READ_JOURNAL_ENTRY_BATCH } from "@modules/accounting/list/journal-entry/constants";
import { READ_CHART_OF_ACCOUNT } from "@modules/accounting/list/chart-of-account/constants";
import { READ_EXPENSE } from "@modules/accounting/list/expense/constants";
import { READ_CUSTOMER_DEPOSIT } from "@modules/accounting/list/customer-deposit/constants";
import { READ_SUPPLIER_DEPOSIT } from "@modules/accounting/list/supplier-deposit/constants";
import { READ_SUPPLIER_REFUND } from "@modules/accounting/list/supplier-refund/constants";
import { READ_CUSTOMER_REFUND } from "@modules/accounting/list/customer-refund/constants";
import { READ_PAYMENT_METHOD } from "@modules/accounting/list/payment-method/constants";
import { READ_CUSTOMER_COMMISSION } from "@modules/accounting/list/customer-commission/constants";
import { READ_END_OF_DAY } from "@modules/accounting/list/end-of-day/constants";
import { READ_ACCOUNTING_SETTING } from "@modules/accounting/list/setting/constants";
import { READ_BANK } from "@modules/accounting/banking/bank/constants";
import { READ_CHEQUE_ENTRY } from "@modules/accounting/banking/cheque-entry/constants";
import { READ_CONTRACTOR } from "@/modules/accounting/list/contractor/constants";
import { READ_BANK_TRANSFER } from "@modules/accounting/banking/bank-transfer/constants";
import { READ_BANK_ENTRY } from "@modules/accounting/banking/bank-entry/constants";
import { READ_BANK_SETTING } from "@modules/accounting/banking/setting/constants";

// Purchase
import { READ_PURCHASE_ORDER } from "@modules/purchase/list/purchase-order/constants";
import { READ_PURCHASE_ORDER_TYPE } from "@modules/purchase/list/purchase-order-type/constants";
import { READ_SHIPPER } from "@modules/purchase/list/shipper/constants";

// Sale
import { READ_CUSTOMER } from "@sale/customer-center/customer/constants";
import { READ_SALE_INVOICE } from "@sale/sale-center/sale-invoice/constants";
import { READ_SALE_ORDER } from "@sale/sale-center/sale-order/constants";
import { READ_PROMOTION } from "@sale/sale-center/promotion/constants";
import { READ_PAYMENT_TERM } from "@sale/setup/payment-term/constants";
import { READ_COMPANY_GROUP } from "@sale/customer-center/company-group/constants";
import { READ_SALE_COMMISSION } from "@sale/setup/sale-commission/constants";
import { READ_CUSTOMER_COMPANY } from "@sale/customer-center/customer-company/constants";
import { READ_SALE_TEAM } from "@sale/setup/sale-team/constants";
import { READ_INSTALLATION } from "@sale/service/installation/constants";
import { READ_INSTALLATION_TEMPLATE } from "@sale/service/installation-template/constants";
import { READ_ITEM_INSTALLATION_CODE } from "@sale/service/item-installation/constants";
import { READ_SERVICE } from "@sale/service/service/constants";
import { READ_SALE_SETTING_CODE } from "@sale/setup/setting/constants";
import { READ_QUOTATION } from "@sale/sale-center/quotation/constants";
import { READ_BID } from "@sale/sale-center/bid/constants";
import { READ_SALE_RECEIPT } from "@sale/sale-center/sale-receipt/constants";

// App
import { READ_APP } from "@modules/apps/constants";

// Pos
import { READ_SALE_HISTORY } from "@modules/point-of-sale/sale-history/constants";
import { READ_POS } from "@modules/point-of-sale/pos/constants";

// Settings
import { READ_ROLE } from "@modules/settings/user-management/role/constants";
import { READ_USER_ACCOUNT } from "@modules/settings/user-management/user-account/constants";
import { READ_LOG_IN_APPROVAL } from "@modules/settings/user-management/log-in-approval/constants";
import { READ_EMAIL_ACCOUNT } from "@modules/settings/email/email-account/constants";
import { READ_EMAIL_TEMPLATE_CODE } from "@modules/settings/email/email-template/constants";
import { READ_TAX } from "@modules/settings/setup/tax/constants";
import { READ_COMPANY } from "@modules/settings/setup/company/constants";
import { READ_CURRENCY } from "@modules/settings/setup/currency/constants";
import { READ_PRINT_FORMAT } from "@modules/settings/setup/print-format/constants";
import { CONFIGURATION_READ } from "@modules/settings/setup/configuration/constants";
import { READ_CUSTOMER_FIELD } from "@modules/settings/setup/custom-field/constants";
import { READ_NO_SERIES_LIST } from "@modules/settings/setup/no-series-list/constants";
import { READ_GENERAL_SETTING } from "@modules/settings/setup/general-setting/constants";
import { READ_REPORT_TEMPLATE } from "@modules/settings/setup/report-template/constants";
import { READ_CURRENCY_EXCHANGE } from "@modules/settings/setup/currency-exchange/constants";
import { READ_INTEGRATION } from "@modules/settings/integrations/integration/constants";
import { READ_GOAL } from "@modules/settings/goal/goal/constants";

// Reports
import {
  EMPLOYEE_LIST_REPORT,
  JOB_APPLICANT_REPORT,
  JOB_OPENING_REPORT,
  ATTENDANCE_REPORT,
  ATTENDANCE_LOG_REPORT,
  LEAVE_REQUEST_REPORT,
  STOCK_REPORT,
  STOCK_VALUES_REPORT,
  STOCK_VALUES_DETAIL_REPORT,
  STOCK_REORDER_REPORT,
  ITEM_MOVEMENT_LOG_REPORT,
  DELIVERY_ORDER_REPORT,
  ITEM_SALE_REPORT,
  ITEM_AGING_REPORT_CODE,
  PRICE_LIST_REPORT,
  ITEM_REPORT,
  ITEM_FORECASTING_REPORT,
  ASSET_REPORT,
  REPORT_JOURNAL,
  REPORT_GENERAL_LEDGER,
  REPORT_TRAIL_BALANCE,
  REPORT_INCOME_STATEMENT_CODE,
  REPORT_BALANCE_SHEET_CODE,
  REPORT_END_OF_DAY_CODE,
  REPORT_END_OF_DAY_SUMMARY_CODE,
  REPORT_CUSTOMER_REFUND_CODE,
  REPORT_CUSTOMER_DEPOSIT_CODE,
  REPORT_CUSTOMER_DEPOSIT_LOG,
  REPORT_CUSTOMER_DEPOSIT_DETAIL,
  REPORT_SUPPLIER_DEPOSIT,
  REPORT_SUPPLIER_DEPOSIT_LOG,
  REPORT_SUPPLIER_DEPOSIT_DETAIL,
  REPORT_SUPPLIER_REFUND,
  REPORT_EXPENSE_CODE,
  REPORT_BANK_LOG_CODE,
  REPORT_BANK_CODE,
  REPORT_ACCOUNTING_RECEIVABLE_DETAIL_CODE,
  REPORT_ACCOUNTING_RECEIVABLE_AGEING_SUMMERY_CODE,
  REPORT_VAT_REQUEST,
  READ_PURCHASE_ORDER_REPORT_CODE,
  READ_PURCHASE_ORDER_SUMMARY_REPORT_CODE,
  DETAILED_REPORT_SALE_ORDER_CODE,
  REPORT_SALE_ORDER_CODE,
  REPORT_QUOTATION_CODE,
  REPORT_SALE_COMMISSION,
} from "@modules/reports/constants";

// Website
import { READ_BANNER } from "@modules/website/store-front/banner/constants";

export default [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: <svg className="bi bi-bar-chart mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5h-2v12h2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
    </svg>,
    languageKey: "headerMenu.dashboard",
    subMenus: [
      READ_SALE_ORDER,
      READ_SALE_INVOICE,
      READ_EXPENSE,
      READ_CHART_OF_ACCOUNT, 
    ],
  },
  {
    title: "HR",
    link: "/human-resource",
    icon: <svg className="bi bi-people mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.995-.944v-.002.002zM7.022 13h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zm7.973.056v-.002.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
    </svg>,
    languageKey: "headerMenu.hr",
    subMenus: [
      READ_EMPLOYEE,
      READ_POSITION,
      READ_DEPARTMENT,
      READ_JOB_TYPE,
      READ_EMPLOYMENT_CARD,
      READ_EMPLOYEE_SETTING_CODE,
      READ_JOB_OPENING,
      READ_JOB_APPLICANT,
      READ_RECRUITMENT_STAGE,
      READ_OFFER_TERM,
      READ_OFFER_LETTER,
      READ_RECRUITMENT_SETTING_CODE,
      READ_LEAVE_REQUEST,
      READ_LEAVE_ALLOCATION,
      READ_LEAVE_TYPE,
      READ_LEAVE_POLICY,
      READ_HOLIDAY_GROUP,
      READ_LEAVE_SETTING_CODE,
      READ_WORKING_DAY,
      VIEW_ATTENDANCE_SUMMARY,
      READ_ATTENDANCE_SETTING_CODE,
      READ_PLAN,
      READ_FORM,
      READ_ANNIVERSARY,
      READ_PAYSLIP,
      READ_PAYSLIP_BATCH_CODE,
      READ_SALARY_STRUCTURE,
      READ_EARNING_TYPE,
      READ_DEDUCTION_TYPE,
      READ_PAYROLL_SETTING_CODE,
      READ_INCENTIVE_TEMPLATE,
      READ_CASH_ADVANCED,
      READ_CASH_ADVANCED_SETTING_CODE
    ]
  },
  {
    title: "Inventory",
    link: "/inventory",
    icon: <svg className="bi bi-box-seam mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
    </svg>,
    languageKey: "headerMenu.inventory",
    subMenus: [
      READ_TAG,
      READ_ITEM,
      READ_BRAND,
      READ_SUPPLIER,
      READ_CATEGORY,
      READ_ATTRIBUTE,
      READ_PRICE_LIST,
      READ_DEPARTMENT,
      READ_SUPPLIER_TYPE,
      READ_CUSTOMIZE_ITEM,
      READ_WARRANTY,
      READ_JUNK_OUT,
      READ_WAREHOUSE,
      READ_STOCK_OUT,
      READ_STOCK_SETTING,
      READ_STOCK_TRANSFER,
      READ_ITEM_MOVEMENT_LOG,
      READ_ASSET,
      READ_ASSET_REQUEST,
      READ_ASSET_LOG,
      READ_ITEM_SPECIFICATION,
      READ_ITEM_SPECIFICATION_TEMPLATE,
    ]
  },
  {
    title: "Delivery",
    link: "/delivery",
    icon: <svg className="bi bi-truck mr-1" width="1.2em" height="1.2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5v7h-1v-7a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5v1A1.5 1.5 0 0 1 0 10.5v-7zM4.5 11h6v1h-6v-1z"/>
      <path fillRule="evenodd" d="M11 5h2.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5h-1v-1h1a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4.5h-1V5zm-8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
      <path fillRule="evenodd" d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
    </svg>,
    languageKey: "headerMenu.delivery",
    subMenus: [
      READ_DELIVERY_ORDER,
      READ_DELIVERY_TRACKING,
    ]
  },
  {
    title: "Accounting",
    link: "/accounting",
    icon: <svg className="bi bi-file-spreadsheet mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M4 1h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H4z"/>
      <path fillRule="evenodd" d="M13 6H3V5h10v1zm0 3H3V8h10v1zm0 3H3v-1h10v1z"/>
      <path fillRule="evenodd" d="M5 14V6h1v8H5zm4 0V6h1v8H9z"/>
    </svg>,
    languageKey: "headerMenu.accounting",
    subMenus: [
      READ_JOURNAL_ENTRY_BATCH,
      READ_CHART_OF_ACCOUNT,
      READ_EXPENSE,
      READ_CUSTOMER_DEPOSIT,
      READ_SUPPLIER_DEPOSIT,
      READ_SUPPLIER_REFUND,
      READ_CUSTOMER_REFUND,
      READ_PAYMENT_METHOD,
      READ_CUSTOMER_COMMISSION,
      READ_END_OF_DAY,
      READ_ACCOUNTING_SETTING,
      READ_BANK,
      READ_CHEQUE_ENTRY,
      READ_BANK_TRANSFER,
      READ_BANK_ENTRY,
      READ_BANK_SETTING,
      READ_CONTRACTOR
    ]
  },
  {
    title: "Purchase",
    link: "/purchase",
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-folder-symlink mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16">
      <path d="m11.798 8.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z"/>
      <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm.694 2.09A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09l-.636 7a1 1 0 0 1-.996.91H2.826a1 1 0 0 1-.995-.91l-.637-7zM6.172 2a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"/>
    </svg>,
    languageKey: "headerMenu.purchase",
    subMenus: [
      READ_PURCHASE_ORDER,
      READ_PURCHASE_ORDER_TYPE,
      READ_SHIPPER,
      READ_SUPPLIER,
      READ_SUPPLIER_TYPE,
    ]
  },
  {
    title: "Sale",
    link: "/sale",
    icon: <svg className="bi bi-cart3 mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
    </svg>,
    languageKey: "headerMenu.sale",
    subMenus: [
      READ_CUSTOMER,
      READ_SALE_INVOICE,
      READ_SALE_ORDER,
      READ_PROMOTION,
      READ_DELIVERY_ORDER,
      READ_PAYMENT_TERM,
      READ_COMPANY_GROUP,
      READ_SALE_COMMISSION,
      READ_CUSTOMER_COMPANY,
      READ_SALE_TEAM,
      READ_INSTALLATION,
      READ_INSTALLATION_TEMPLATE,
      READ_ITEM_INSTALLATION_CODE,
      READ_SERVICE,
      READ_SALE_SETTING_CODE,
      READ_QUOTATION,
      READ_BID,
      READ_SALE_RECEIPT
    ]
  },
  {
    title: "POS",
    link: "/point-of-sale",
    icon: <svg className="bi bi-upc-scan mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"/>
      <path d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
    </svg>,
    languageKey: "headerMenu.pos",
    subMenus: [
      READ_POS,
      READ_SALE_HISTORY
    ]
  },
  {
    title: "Website",
    link: "/website",
    icon: <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" className="bi bi-globe mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M1.018 7.5h2.49c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5zM2.255 4H4.09a9.266 9.266 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.024 7.024 0 0 0 2.255 4zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.5 1.077c-.67.204-1.335.82-1.887 1.855-.173.324-.33.682-.468 1.068H7.5V1.077zM7.5 5H4.847a12.5 12.5 0 0 0-.338 2.5H7.5V5zm1 2.5V5h2.653c.187.765.306 1.608.338 2.5H8.5zm-1 1H4.51a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm1 2.5V8.5h2.99a12.495 12.495 0 0 1-.337 2.5H8.5zm-1 1H5.145c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12zm-2.173 2.472a6.695 6.695 0 0 1-.597-.933A9.267 9.267 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM1.674 11H3.82a13.651 13.651 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm8.999 3.472A7.024 7.024 0 0 0 13.745 12h-1.834a9.278 9.278 0 0 1-.641 1.539 6.688 6.688 0 0 1-.597.933zM10.855 12H8.5v2.923c.67-.204 1.335-.82 1.887-1.855A7.98 7.98 0 0 0 10.855 12zm1.325-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm.312-3.5h2.49a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.91 4a9.277 9.277 0 0 0-.64-1.539 6.692 6.692 0 0 0-.597-.933A7.024 7.024 0 0 1 13.745 4h-1.834zm-1.055 0H8.5V1.077c.67.204 1.335.82 1.887 1.855.173.324.33.682.468 1.068z"/>
    </svg>,
    languageKey: "headerMenu.website",
    subMenus: [
      READ_BANNER
    ]
  },
  {
    title: "Reports",
    link: "/reports",
    icon: <svg className="bi bi-clipboard-data mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
      <path fillRule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
      <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z"/>
    </svg>,
    languageKey: "headerMenu.reports",
    subMenus: [
      EMPLOYEE_LIST_REPORT,
      JOB_APPLICANT_REPORT,
      JOB_OPENING_REPORT,
      ATTENDANCE_REPORT,
      ATTENDANCE_LOG_REPORT,
      LEAVE_REQUEST_REPORT,
      STOCK_REPORT,
      STOCK_VALUES_REPORT,
      STOCK_VALUES_DETAIL_REPORT,
      STOCK_REORDER_REPORT,
      ITEM_MOVEMENT_LOG_REPORT,
      DELIVERY_ORDER_REPORT,
      ITEM_SALE_REPORT,
      ITEM_AGING_REPORT_CODE,
      PRICE_LIST_REPORT,
      ITEM_REPORT,
      ITEM_FORECASTING_REPORT,
      ASSET_REPORT,
      REPORT_JOURNAL,
      REPORT_GENERAL_LEDGER,
      REPORT_TRAIL_BALANCE,
      REPORT_INCOME_STATEMENT_CODE,
      REPORT_BALANCE_SHEET_CODE,
      REPORT_END_OF_DAY_CODE,
      REPORT_END_OF_DAY_SUMMARY_CODE,
      REPORT_CUSTOMER_REFUND_CODE,
      REPORT_CUSTOMER_DEPOSIT_CODE,
      REPORT_CUSTOMER_DEPOSIT_LOG,
      REPORT_CUSTOMER_DEPOSIT_DETAIL,
      REPORT_SUPPLIER_DEPOSIT,
      REPORT_SUPPLIER_DEPOSIT_LOG,
      REPORT_SUPPLIER_DEPOSIT_DETAIL,
      REPORT_SUPPLIER_REFUND,
      REPORT_EXPENSE_CODE,
      REPORT_BANK_LOG_CODE,
      REPORT_BANK_CODE,
      REPORT_ACCOUNTING_RECEIVABLE_DETAIL_CODE,
      REPORT_ACCOUNTING_RECEIVABLE_AGEING_SUMMERY_CODE,
      REPORT_VAT_REQUEST,
      READ_PURCHASE_ORDER_REPORT_CODE,
      READ_PURCHASE_ORDER_SUMMARY_REPORT_CODE,
      DETAILED_REPORT_SALE_ORDER_CODE,
      REPORT_SALE_ORDER_CODE,
      REPORT_QUOTATION_CODE,
      REPORT_SALE_COMMISSION,
    ]
  },
  {
    title: "Apps",
    link: "/apps",
    icon: <svg className="bi bi-grid mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
    </svg>,
    languageKey: "headerMenu.apps",
    subMenus: [
      READ_APP
    ]
  },
  {
    title: "Settings",
    link: "/settings",
    icon: <svg className="bi bi-gear mr-1" width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-5px"}}>
      <path fillRule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"/>
      <path fillRule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"/>
    </svg>,
    languageKey: "headerMenu.settings",
    subMenus: [
      READ_USER_ACCOUNT,
      READ_ROLE,
      READ_LOG_IN_APPROVAL,
      READ_EMAIL_ACCOUNT,
      READ_EMAIL_TEMPLATE_CODE,
      READ_TAX,
      READ_COMPANY,
      READ_CURRENCY,
      READ_PRINT_FORMAT,
      CONFIGURATION_READ,
      READ_CUSTOMER_FIELD,
      READ_NO_SERIES_LIST,
      READ_GENERAL_SETTING,
      READ_REPORT_TEMPLATE,
      READ_CURRENCY_EXCHANGE,
      READ_INTEGRATION,
      READ_GOAL,
    ]
  },
];