import React from "react";
import AutoComplete from "../../autocomplete-static";

class MaritalStatusAutoComplete extends React.Component {

  render() {
    const dataSource = [
      {
        id: "Single",
        name: "Single"
      },
      {
        id: "Married",
        name: "Married."
      },
      {
        id: "Divorced",
        name: "Divorced"
      },
      {
        id: "Windowed",
        name: "Windowed"
      }
    ];
    return (
      <AutoComplete 
        required
        stringValue
        label="Marital Status"
        name="maritalStatus"
        dataSource={dataSource}
        {...this.props}
      />
    );
  }
}

export default MaritalStatusAutoComplete;