import { schema } from "normalizr";
import { PermissionArraySchema } from "./permission";
import { PermissionChildrenArraySchema } from "./permission-children";

const PermissionGroupSchema = new schema.Entity("permissionGroups", {
  permissions: PermissionArraySchema,
  children: PermissionChildrenArraySchema,
});
const PermissionGroupArraySchema = new schema.Array(PermissionGroupSchema);

export { PermissionGroupSchema, PermissionGroupArraySchema };
