// Lib
import React, { Component } from "react";
import PropTypes from "prop-types";

// Component
import FilterComponent from "@cores/components/filter-box";
import AsyncDateRangePicker from "@cores/components/async-date-range-picker";
import AsyncLeaveTypeAutoComplete from "@cores/components/async-auto-complete/leave-type";
import AsyncEmployeeAutoComplete from "@cores/components/async-auto-complete/employee";
import AsyncStatusAutoComplete from "@cores/components/async-auto-complete/async-status-auto-complete";
import { LEAVE_REQUEST_STATUS } from "../../constants";

class FilterBox extends Component {
  render() {
    const { isCurrentUserPage, ...props } = this.props;
    return (
      <FilterComponent {...props}>
        <AsyncLeaveTypeAutoComplete />
        <AsyncDateRangePicker
          toName="endDate"
          fromName="startDate"
          typeOfRange="date"
        />
        {!isCurrentUserPage && <AsyncEmployeeAutoComplete />}
        <AsyncStatusAutoComplete dataSource={LEAVE_REQUEST_STATUS} />
      </FilterComponent>
    );
  }
}

FilterBox.propTypes = {
  isCurrentUserPage: PropTypes.bool,
};

export default FilterBox;
