import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { Row, Col } from "reactstrap";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Modal, Dropdown, Menu } from "antd";
import { SmallDashOutlined, PlusCircleOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import { CheckCircleOutlined, CloseCircleOutlined, FileSearchOutlined, PrinterOutlined, EditOutlined } from "@ant-design/icons";

// Constants
import { 
  LEAVE_REQUEST_LIST_REQUEST, 
  LEAVE_REQUEST_STATUS_DRAFT, 
  LEAVE_REQUEST_CREATE_FORM_NAME,
  LEAVE_REQUEST_CREATE_REQUEST,
  LEAVE_REQUEST_EDIT_FORM_NAME,
  LEAVE_REQUEST_EDIT_REQUEST,
  LEAVE_REQUEST_STATUS_SUBMITTED,
} from "@modules/hr/leave/leave-request/constants";

// Components
import ListLayout from "@/components/layout/list-layout";
import DetailComponent from "./components/detail-component";
import FilterComponent from "@modules/hr/leave/leave-request/components/filter-box";
import CreateLeaveRequest from "@modules/hr/leave/leave-request/pages/create-leave-request";
import EditLeaveRequest from "@modules/hr/leave/leave-request/pages/edit-leave-request";

// Schema
import { LeaveRequestArraySchema } from "@modules/hr/leave/leave-request//schema";

// Action 
import * as ModalConstants from "./constants";
import { 
  getDayLabel, 
  toShortDate,
  modalConfirmDialog,
  modalConfirmArchive,
  modalConfirmUnarchive,
} from "@modules/helpers";
import { leaveRequestStatus } from "@modules/hr/leave/leave-request/helpers";
import { 
  REPORT_TEMPLATE_FORMAT_PDF,
  REPORT_TEMPLATE_FORMAT_EXCEL,
} from "@modules/settings/setup/report-template/constants";
import { 
  getAll, 
  archiveLeaveRequest, 
  unarchiveLeaveRequest,
  updateStatusToSubmit,
  updateStatusToCancel,
} from "@modules/hr/leave/leave-request/actions";
import * as services from "@/services/print-format";
import { REPORT_TEMPLATE_ENGINE_STIMULSOFT } from "src/modules/settings/setup/report-template/constants";
import { getAccessToken } from "src/shared/utils";

class LeaveRequest extends Component {

  state = {
    visible: false,
    type: null,
    title: null,
    leaveId: null,
    reload: null,
    stimulsoftLoaded: false,
  }

  loadStimulsoftReport = () => {
    if (this.state.stimulsoftLoaded == false) {
      let reportScript = document.querySelector("script[src=\"/report/stimulsoft.reports.js\"]");
      if (!reportScript) {
        reportScript = document.createElement("script");
        reportScript.type = "application/javascript";
        reportScript.src = "/report/stimulsoft.reports.js";
        document.body.appendChild(reportScript);
      } else {
        this.loadStimulsoftViewer();
      }
      reportScript.addEventListener("load", this.loadStimulsoftViewer);
    }
  };

  loadStimulsoftViewer = () => {
    let viewerScript = document.querySelector("script[src=\"/report/stimulsoft.viewer.js\"]");
    if (!viewerScript) {
      viewerScript = document.createElement("script");
      viewerScript.type = "application/javascript";
      viewerScript.src = "/report/stimulsoft.viewer.js";
      document.body.appendChild(viewerScript);
      viewerScript.addEventListener("load", this.onStimulsoftScriptLoaded);
    } else {
      this.onStimulsoftScriptLoaded();
    }
  };

  onStimulsoftScriptLoaded = () => {
    this.setState({
      stimulsoftLoaded: true
    });
  }

  componentDidMount() {
    this.loadStimulsoftReport();
  }

  onVisible = (type, title, leaveId) => {
    this.setState({
      visible: true,
      type,
      title,
      leaveId,
    });
  }

  onDismiss = () => {
    this.setState({
      visible: false,
      type: null,
      title: null,
      leaveId: null,
      reload: null,
    });
  }

  onArchive = (id) => {
    const { reload } = this.state;
    const { archiveAction } = this.props;
    modalConfirmArchive(id, "leave request", archiveAction, () => {
      reload();
    });
  }

  onUnarchive = (id) => {
    const { reload } = this.state;
    const { unarchiveAction } = this.props;
    modalConfirmUnarchive(id, "leave request", unarchiveAction, () => {
      reload();
    });
  }

  onSuccess = () => {
    const { reload } = this.state;
    reload();
    this.onDismiss();
  }

  arrayBuffer2String = (buffer) => {
    return String.fromCharCode.apply(null, new Uint8Array(buffer));
  }

  onPrintClick = (id) => {
    const values = {
      id, 
      entity: "LeaveRequest", 
    };
    services
      .getPrintFormatTemplate(values)
      .then((response) => {
        let data = null;
        let reportType = null;
        const contentType = response.headers["content-type"];
        if (contentType == "application/pdf") {
          reportType = REPORT_TEMPLATE_FORMAT_PDF;
          data = response.data;
        } else if (contentType == "application/json; charset=utf-8") {
          const responseString = this.arrayBuffer2String(response.data);
          data = JSON.parse(responseString);
          if (data.engine == "stimulsoft") {
            reportType = REPORT_TEMPLATE_ENGINE_STIMULSOFT;
            const Stimulsoft = window.Stimulsoft;
            const viewerFrame = document.getElementById("stiPrintReportFrame");
            if (viewerFrame) {
              document.body.removeChild(viewerFrame);
            }
            const viewer = new Stimulsoft.Viewer.StiViewer(undefined, "StiViewer", false);
            const report = new Stimulsoft.Report.StiReport();
            window.StiOptions.WebServer.url = `/stimulsoft?token=${getAccessToken().accessToken}`;
            window.StiOptions.WebServer.encryptData = false;
            Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkA7O0by5iYg3RMl+uVw+MLyU5ammyq1KqqRoZ1EYrLOKC9l9" + 
              "exQ+CcTwr4fzpzxXVXOGulB2GDqGLKaLwtiYGfS0qTP3U9zGEAqWMXyCbGuHs7WMEh4c3XfFu342ztaTpzKjM3LuRK" + 
              "BWhw6qwp3JbE119KtrBBaQiUHI6/v6mG+i4Z6CPRCa+WMxay4ypKUsaXM3iyVa3atjD/GeYGspP8ww91yziZVXlQnT" + 
              "SBJfO5cPxy3gasrLlZRdFzg4//qr0l8asV5+O6u88zLE0d0PJoYQLIbx3su6igwN/i9uargZsHvGpYFrPQWhNBBULb" + 
              "G4wifK+E4YwOpEmPqox4DD17PqW9YuC31MUpZ38C2B7rmod6cWHKw3PtMLiU1fN+STId4u087iaek7gN59HxTSvKJu" + 
              "QWjgrfwXqkE6q4ttyzlg7Tdf5HglkgIYy2nVx9Wkj0yJucK3obwzj+g6a05O/5+KSMs9rjknZL1T3isodRv0lB1nDp" + 
              "VNSNN8hJ//9gzc8=";
            fetch(data.content)
              .then(res => res.json())
              .then((template) => {
                report.load(template);
                const param = report.dictionary.variables.getByName("id");
                if (param != null) {
                  param.value = id;
                }
                viewer.report = report;
                viewer.renderHtml("stimulsoft");
                viewer.onShowReport = () => {
                  report.print();
                };
              });
          } else {
            reportType = REPORT_TEMPLATE_FORMAT_EXCEL;
          }
        }
        this.onPrintPopUp(data);
        this.setState({
          error: false,
          loading: false,
          reportType,
        });
      }).catch(() => {
        this.setState({
          error: true,
          loading: false,
          reportType: null,
        });
      });
  }

  onPrintPopUp = (data) => {
    var file = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(file);
    const iframe = document.createElement("iframe");
    iframe.id = "iframe-print";
    iframe.src = url;
    iframe.style = "display: none";
    document.body.append(iframe);
    iframe.focus();
    iframe.contentWindow.print();
  }

  columns = () => {
    return [
      {
        title: "Start Date",
        dataIndex: "startDate",
        sortable: true,
        width: "15%",
        render: (startDate) => toShortDate(startDate)
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        sortable: true,
        width: "15%",
        render: (endDate) => toShortDate(endDate)
      },
      {
        title: "Duration",
        dataIndex: "totalDays",
        sortable: false,
        width: "20%",
        render: (totalDays) => getDayLabel(totalDays)
      },
      {
        title: "Leave Type",
        dataIndex: "leaveType",
        sortable: true,
        width: "20%",
        render: (text, row) => {
          const { leaveType } = row;
          if (leaveType) {
            return (
              <Fragment>
                {leaveType.name}
              </Fragment>
            );
          }
        }
      },
      {
        title: "Status",
        dataIndex: "requestStatus",
        width: "10%",
        render: (status) => leaveRequestStatus(status)
      },
      {
        title: "Actions",
        dataIndex: "action",
        width: "5%",
        render: (_, row) => {
          return (
            <Dropdown overlay={() => this.menu(row)} placement="bottomRight">
              <Button icon={<SmallDashOutlined />} type="primary" ghost size="small" />
            </Dropdown>
          );
        }
      }
    ];
  }

  updateStatusToSubmit = (id) => {
    const { updateStatusToSubmit } = this.props;
    modalConfirmDialog(
      "submit this leave request",
      null,
      updateStatusToSubmit,
      id,
    );
  }

  updateStatusToCancel = (id) => {
    const { updateStatusToCancel } = this.props;
    modalConfirmDialog(
      "cancel this leave request",
      null,
      updateStatusToCancel,
      id,
    );
  }

  menu = (row) => {
    return (
      <Menu>
        <Menu.Item 
          disabled={row.requestStatus != LEAVE_REQUEST_STATUS_DRAFT}
          onClick={() => this.updateStatusToSubmit(row.id)}
        >
          <CheckCircleOutlined />Submit
        </Menu.Item>
        <Menu.Item 
          disabled={row.requestStatus > LEAVE_REQUEST_STATUS_SUBMITTED}
          onClick={() => this.updateStatusToCancel(row.id)}
        >
          <CloseCircleOutlined />Cancel 
        </Menu.Item>
        <Menu.Item onClick={() => this.onVisible(ModalConstants.MODAL_DETAIL, ModalConstants.MODAL_DETAIL_TITLE, row.id)}>
          <FileSearchOutlined />Detail 
        </Menu.Item>
        <Menu.Item onClick={() => this.onPrintClick(row.id)}>
          <PrinterOutlined />Print 
        </Menu.Item>
        <Menu.Item 
          disabled={row.requestStatus != LEAVE_REQUEST_STATUS_DRAFT}
          onClick={() => this.onVisible(ModalConstants.MODAL_EDIT, ModalConstants.MODAL_EDIT_TITLE, row.id)}
        >
          <EditOutlined />Edit 
        </Menu.Item>
        <Menu.Item onClick={() => row.state == 1 ? this.onArchive(row.id) : this.onUnarchive(row.id)}>
          {row.state == 1 ? <DeleteOutlined /> : <UndoOutlined />} {row.state == 1 ? "Archive" : "Unarchive"}
        </Menu.Item>
      </Menu>
    );
  }

  renderFilterComponent = (props) => {
    return (
      <FilterComponent 
        isCurrentUserPage={true}
        {...props}
      />
    );
  }

  render() {
    const extraButtons = [];
    const { permission, id, onSubmit, createLoading, editLoading, userId } = this.props;
    const { visible, title, type, leaveId } = this.state;
    const filterKeys = ["leaveType", "startDate", "endDate"];
    extraButtons.push(
      <Button 
        ghost
        type="primary" 
        icon={<PlusCircleOutlined />}
        onClick={() => this.onVisible(ModalConstants.MODAL_CREATE, ModalConstants.MODAL_CREATE_TITLE)}
      >
        Create
      </Button>
    );
    return (
      <Fragment>
        <ListLayout 
          className="m-0"
          action={getAll}
          tableSize="small"
          title="Leave Request"
          permission={permission}
          filterKeys={filterKeys}
          columns={this.columns()}
          reducerName="leaveRequest"
          isPermissionAllowed={true}
          showTitle={false}
          extraButtons={extraButtons}
          filterComponent={this.renderFilterComponent}
          schema={LeaveRequestArraySchema}
          getReload={(reload) => {
            this.setState({
              reload
            });
          }}
          requestName={LEAVE_REQUEST_LIST_REQUEST}
          extraFilter={(filter) => {
            filter.employee = [id];
            return filter;
          }}
        />
        <Modal
          title={title}
          width="1300px"
          visible={visible}
          footer={null}
          maskClosable={false}
          keyboard={false}
          onCancel={this.onDismiss}
          destroyOnClose={true}
        >
          {(type == ModalConstants.MODAL_DETAIL && visible) && (
            <DetailComponent id={leaveId} />
          )}
          {(type == ModalConstants.MODAL_CREATE && visible) && (
            <CreateLeaveRequest 
              className="m-0"
              fullWrapper={true} 
              showHeader={false} 
              shouldContinueCreate={true}
              onCreateSuccess={this.onSuccess}
              userId={userId}
            >
              <Row>
                <Col md="12">
                  <Button 
                    ghost
                    type="primary"
                    icon={<SaveOutlined />}
                    className="float-right"
                    loading={createLoading}
                    onClick={() => onSubmit(LEAVE_REQUEST_CREATE_FORM_NAME)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </CreateLeaveRequest>
          )}
          {(type == ModalConstants.MODAL_EDIT && visible) && (
            <EditLeaveRequest 
              id={leaveId}
              userId={userId}
              className="m-0"
              fullWrapper={true} 
              showHeader={false} 
              notRedirectPage={true}
              onEditSuccess={this.onSuccess}
            >
              <Row>
                <Col md="12">
                  <Button 
                    ghost
                    type="primary"
                    loading={editLoading}
                    icon={<SaveOutlined />}
                    className="float-right"
                    onClick={() => onSubmit(LEAVE_REQUEST_EDIT_FORM_NAME)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </EditLeaveRequest>
          )}
        </Modal>
        <div style={{ height: 1200, display: "none" }} id="stimulsoft"></div>
      </Fragment>
    );
  }
}

LeaveRequest.propTypes = {
  id: PropTypes.number,
  userId: PropTypes.number,
  match: PropTypes.object,
  onSubmit: PropTypes.func,
  editLoading: PropTypes.bool,
  permission: PropTypes.object,
  archiveAction: PropTypes.func,
  createLoading: PropTypes.bool,
  unarchiveAction: PropTypes.func,
  updateStatusToCancel: PropTypes.func,
  updateStatusToSubmit: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    editLoading: state.request[LEAVE_REQUEST_EDIT_REQUEST],
    createLoading: state.request[LEAVE_REQUEST_CREATE_REQUEST],
  };
};

const mapDispatchToProps = (dispatch) => { 
  return {
    onSubmit: (form) => dispatch(submit(form)),
    archiveAction: (id) => dispatch(archiveLeaveRequest(id, true)),
    unarchiveAction: (id) => dispatch(unarchiveLeaveRequest(id)),
    updateStatusToCancel: (id) => dispatch(updateStatusToCancel(id)),
    updateStatusToSubmit: (id) => dispatch(updateStatusToSubmit(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequest);