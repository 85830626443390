export const API_END_POINT_ITEM_CATALOG = {
  title: "Item Catalog",
  endPoint: "/api/item-catalog/v1/sync",
  icon: "UnorderedListOutlined",
  permissionCode: 265001
};
export const API_END_POINT_ITEM_DEPARTMENT = {
  title: "Item Department",
  endPoint: "/api/item-department/v1/sync",
  icon: "ApartmentOutlined",
  permissionCode: 223011
};
export const API_END_POINT_BRAND = {
  title: "Brand",
  endPoint: "/api/brand/v1/sync",
  icon: "BuildOutlined",
  permissionCode: 200011
};
export const API_END_POINT_CATEGORY = {
  title: "Category",
  endPoint: "/api/category/v1/sync",
  icon: "ProfileOutlined",
  permissionCode: 201011,
};
export const API_END_POINT_COMPANY = {
  title: "Company Branch",
  endPoint: "/api/company/branch/v1/sync",
  icon: "BankOutlined",
  permissionCode: 102011
};
export const API_END_POINT_ITEM = {
  title: "Item",
  endPoint: "/api/product/v1/sync",
  icon: "AppstoreOutlined",
  permissionCode: 204029
};
export const API_END_POINT_BANNER = {
  title: "Banner",
  endPoint: "/api/banner/v1/sync",
  icon: "FileImageOutlined",
  permissionCode: 251011
};

export const SYNC_BOX_DATA_SOURCE = [
  API_END_POINT_ITEM_CATALOG,
  API_END_POINT_ITEM_DEPARTMENT,
  API_END_POINT_BRAND,
  API_END_POINT_CATEGORY,
  API_END_POINT_COMPANY,
  API_END_POINT_ITEM,
  API_END_POINT_BANNER,
];

export const READ_SYNCHRONIZE = 273001;