// Libs
import React from "react";
import { Modal, Table } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import {
  toShortDate,
  getQueryParamUrl,
} from "@modules/helpers";
import { useApi } from "@modules/hooks";

// Action 
import { getCustomerCommissionWithCurrentUser } from "@accounting/list/customer-commission/actions";

// Schema
import { CustomerCommissionArraySchema } from "@accounting/list/customer-commission/schema";

// Constant
import { DEFAULT_ASYNC_DATE_FORMAT } from "@/constants/default-constants";
import { CUSTOMER_COMMISSION_LIST_REQUEST } from "@accounting/list/customer-commission/constants";

// Components
import ListLayout from "@/components/layout/list-layout";
import CurrencyBox from "@cores/components/currency-box";
import CustomerCommissionDetail from "./customer-commission-detail";
import * as Constants from "@cores/components/auto-complete/date-range-option/constants";
import FilterComponent from "@accounting/list/customer-commission/components/filter-box";

// Helper
import { renderCustomerCommissionStatus } from "@accounting/list/customer-commission/helper";
import { getCustomerCommissionTotal } from "src/modules/accounting/list/customer-commission/services";

function CustomerCommissionList(props) {

  const [visible, setVisible] = React.useState({
    visible: false,
    customerCommissionNo: null,
  });

  let query = getQueryParamUrl();
  const dateType = query.dateType;
  let values = {
    dateType: dateType,
    query: query.query,
    status: query.status,
    customer: query.customer,
    customerCompany: query.customerCompany,
    to: query.to || moment().format(DEFAULT_ASYNC_DATE_FORMAT),
    from: query.from || moment().format(DEFAULT_ASYNC_DATE_FORMAT),
  };
  if (dateType == Constants.OPTION_TYPE_ALL) {
    values = {
      status: query.status,
      customer: query.customer,
      customerCompany: query.customerCompany,
      query: query.query,
      dateType: Constants.OPTION_TYPE_ALL,
    };
  }

  const { response } = useApi(getCustomerCommissionTotal, values, [props.location]);

  const renderColumn = () => {
    return (
      [
        {
          title: "No",
          sortable: true,
          width: "10%",
          dataIndex: "customerCommissionNo",
          sortKey: "customerCommissionNo",
          render: (customerCommissionNo) => {
            return (
              <a onClick={() => setVisible({
                visible: true,
                customerCommissionNo
              })}>
                <>
                  {customerCommissionNo}
                </>
              </a>
            );
          }
        },
        {
          title: "Customer",
          dataIndex: "customer",
          width: "20%",
          render: (customer) => {
            if (customer) {
              return (
                <Link to={`/sale/customer-center/customer/${customer.customerNo}`}>
                  {customer.firstName} {customer.lastName}
                </Link>
              );
            }
            return "N/A";
          }
        },
        {
          title: "Company",
          dataIndex: "company",
          width: "20%",
          render: (_, row) => {
            if (row.customer && row.customer.customerCompany) {
              return (
                <Link to={`/sale/customer-center/customer-company/${row.customer.customerCompany.customerCompanyNo}`}>
                  {row.customer.customerCompany.name}
                </Link>
              );
            }
            return "N/A";
          }
        },
        {
          title: "Reference",
          sortable: true,
          dataIndex: "reference",
          sortKey: "reference",
          width: "10%",
          render: (reference) => {
            return (
              <Link to={`/sale/sale-center/sale-invoice/${reference}`}>
                {reference}
              </Link>
            );
          }
        },
        {
          title: "Amount",
          sortable: true,
          dataIndex: "amount",
          sortKey: "amount",
          width: "15%",
          render: (amount) => <CurrencyBox value={amount} />
        },
        {
          title: "Date",
          dataIndex: "date",
          sortKey: "date",
          width: "10%",
          render: (date) => toShortDate(date)
        },
        {
          title: "Status",
          dataIndex: "status",
          sortKey: "status",
          width: "10%",
          render: (status) => renderCustomerCommissionStatus(status)
        },
      ]
    );
  };

  const { permission } = props;
  const filterKeys = [
    "to",
    "from",
    "status",
    "dateType",
    "customer",
    "customerCompany",
  ];
  return (
    <>
      <ListLayout
        className="m-0"
        creatable={false}
        tableSize="small"
        showHeader={false}
        showTitle={false}
        permission={permission}
        filterKeys={filterKeys}
        shouldDisplay={query?.customer != null || query?.customerCompany != null}
        title="CustomerCommission"
        isPermissionAllowed={true}
        columns={renderColumn()}
        reducerName="customerCommission"
        filterComponent={FilterComponent}
        schema={CustomerCommissionArraySchema}
        action={getCustomerCommissionWithCurrentUser}
        requestName={CUSTOMER_COMMISSION_LIST_REQUEST}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={4}>
                  <b className="float-right">Total Amount: </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <b><CurrencyBox value={response?.total} /></b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <Modal
        width="1200px"
        footer={null}
        visible={visible.visible}
        title="Cash Advanced Detail"
        onCancel={() => setVisible({
          visible: false,
          customerCommissionNo: null
        })}
      >
        <CustomerCommissionDetail
          id={visible.customerCommissionNo}
        />
      </Modal>
    </>
  );
}

CustomerCommissionList.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  permission: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withRouter(CustomerCommissionList);