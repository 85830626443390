import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, Container } from "reactstrap";
import { Button, Typography, Result } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// image
import BackgroundImage from "@/images/background/background.svg";
import NotFoundImage from "@/images/background/not-found.svg";
import CompanyNotFoundImage from "@/images/background/company-not-found.svg";

// Constants
import * as AuthenticationConstants from "@/constants/authentication";

const Wrapper = styled.div`
  height: 100vh;
  background-size: 100%;
  background-position: center;
  background-image: url(${BackgroundImage});
`;

const { Title } = Typography;

class CompanyNotFound extends Component {

  removeTokenFromLocalStorage = () => {
    localStorage.removeItem(AuthenticationConstants.LOGIN_TYPE);
    localStorage.removeItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
    location.reload();
  }
  
  render() {
    const { status, name } = this.props;
    const Text = () => {
      return (
        <Title level={4} className="text-center mb-0">
          {status == 404 ? (
            <Fragment>
              <img 
                className="w-100 mb-3" 
                src={CompanyNotFoundImage} 
              />
              <h5>The company with subdomain <b>{name}</b> you were looking for does not exist.</h5>
            </Fragment>
          ) : (
            status == 401 ? (
              <Fragment>
                <Result
                
                  status="403"
                  title={<h1>401</h1>}
                  subTitle={<h4>Your access token already expired</h4>}
                  extra={
                    <Button 
                      ghost
                      type="primary"
                      className="mt-3"
                      icon={<SyncOutlined />}
                      onClick={this.removeTokenFromLocalStorage}
                    >
                      Refresh 
                    </Button>
                  }
                />
              </Fragment>
            ) : (
              <Result
                status="500"
                title={<h1>500</h1>}
                subTitle="Something went wrong !"
              />
            )
          )}
        </Title>
      );
    };
    return (
      <Wrapper>
        <Container className="h-100">
          <Row className="h-100">
            <Col md="6" xs="12" className="text-center mb-md-auto mt-auto">
              <img 
                className="w-75" 
                src={NotFoundImage} 
              />
            </Col>
            <Col md="6" xs="12" className="text-center mt-md-auto mb-auto">
              <Text />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}

CompanyNotFound.propTypes = {
  name: PropTypes.string,
  backTo: PropTypes.string,
  status: PropTypes.number
};

export default CompanyNotFound;