import * as WorkExperienceConstants from "../constants";

export default function(state = {}, action) {
  switch (action.type) {
  case WorkExperienceConstants.WORK_EXPERIENCE_SUCCESS:
    return {
      ...state,
      [action.employeeId]: action.workExperience
    };
  case WorkExperienceConstants.WORK_EXPERIENCE_ADD_NEW:
    return {
      ...state,
      [action.employeeId]: [
        action.id, 
        ...state[action.employeeId]
      ]
    };
  case WorkExperienceConstants.WORK_EXPERIENCE_DELETE: {
    const deletedIndex = state[action.employeeId].indexOf(action.id);
    let workExperience = state[action.employeeId];
    workExperience.splice(deletedIndex, 1);
    return {
      ...state,
      [action.employeeId]: workExperience
    };
  }
  default:
    return state;
  }
}