// Libs
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse as AntCollapse } from "antd";
import { CaretDownOutlined, CaretUpOutlined  } from "@ant-design/icons";

const { Panel } = AntCollapse;

function PanelBox(props) {

  const [collapse, setCollapse] = useState(props.collapse);

  const onChange = () => {
    setCollapse(!collapse);
    if (props.onChange) {
      props.onChange(collapse);
    }
  };

  const {
    title,
    style,
    children,
    className,
    activeKey,
    collapseByDefault,
    childrenClassName,
  } = props;
  return (
    <AntCollapse 
      bordered={false}
      onChange={onChange}
      defaultActiveKey={collapseByDefault ? [activeKey] : null}
      className={`${className} ${collapse ? "mb-0" : "mb-3"}`}
    >
      <Panel 
        style={style}
        header={title} 
        key={activeKey}
        showArrow={false} 
        extra={collapse ? <CaretUpOutlined /> : <CaretDownOutlined />}
      >
        <div className={childrenClassName}>
          {children}
        </div>
      </Panel>
    </AntCollapse>
  );
}

PanelBox.propTypes = {
  title: PropTypes.any,
  style: PropTypes.object,
  collapse: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  activeKey: PropTypes.string,
  collapseByDefault: PropTypes.bool,
  childrenClassName: PropTypes.string,
};

PanelBox.defaultProps = {
  activeKey: "1",
  collapse: true,
  collapseByDefault: true,
  childrenClassName: "mt-2",
};

export default PanelBox;