import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import withWarnUnsavedForm from "./withWarnUnsavedForm";

export const withReduxForm = ({
  disableWarnUnsavedForm = false,
  leaveMessage,
  ...reduxFormProps
}, mapStateToProps, mapDispatchToProps,) => (WrappedComponent) => {

  const ReduxForm = reduxForm({
    ...reduxFormProps
  })(WrappedComponent);

  const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ReduxForm);

  if (disableWarnUnsavedForm) {
    return ConnectedComponent;
  }
  return withWarnUnsavedForm({
    formName: reduxFormProps.form,
    leaveMessage: leaveMessage,
  })(ConnectedComponent);
};