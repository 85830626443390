export const COMMENT_REQUEST = "COMMENT_REQUEST";
export const COMMENT_SUCCESS = "COMMENT_SUCCESS";
export const COMMENT_SUCCESS_NEXT_PAGE = "COMMENT_SUCCESS_NEXT_PAGE";
export const COMMENT_CREATE_REQUEST = "COMMENT_CREATE_REQUEST";
export const COMMENT_CREATE_SUCCESS = "COMMENT_CREATE_SUCCESS";
export const COMMENT_UPDATE_REQUEST = "COMMENT_UPDATE_REQUEST";
export const COMMENT_DELETE_REQUEST = "COMMENT_DELETE_REQUEST";
export const COMMENT_DELETE_SUCCESS = "COMMENT_DELETE_SUCCESS";
export const COMMENT_SUBMIT_RULES = {
  text: {
    required: true,
  },
};
export const CREATE_COMMENT_FORM_NAME = "createCommentForm";
export const EDIT_COMMENT_FORM_NAME = "editCommentForm";

export const READ_COMMENT_CODE = 101000;
export const CREATE_COMMENT_CODE = 101001;
export const UPDATE_COMMENT_CODE = 101002;
export const DELETE_COMMENT_CODE = 101003;