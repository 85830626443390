import * as UserConstants from "../constants";

export default function(state = {}, action) {
  switch (action.type) {
  case UserConstants.SESSION_SUCCESS: 
    return {
      ...state,
      [action.employeeId]: action.sessions
    };
  case UserConstants.SESSION_DELETE: {
    const deletedIndex = state[action.employeeId].indexOf(action.id);
    let sessions = [
      ...state[action.employeeId]
    ];
    sessions.splice(deletedIndex, 1);
    return {
      ...state,
      [action.employeeId]: sessions
    };
  }
  case UserConstants.SESSION_DELETE_ALL: {
    let sessions = [
      ...state[action.employeeId]
    ];
    sessions.splice(1, sessions.length - 1);
    return {
      ...state,
      [action.employeeId]: sessions
    };
  }
  case UserConstants.SESSION_SUCCESS_NEXT_PAGE: { 
    return { 
      ...state, 
      [action.employeeId] : [ 
        ...state[action.employeeId],  
        ...action.sessions
      ]  
    }; 
  }
  default:
    return state;
  }
}