import React, { Component, Fragment } from "react";
import * as _ from "lodash";
import * as moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Badge, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

// Component
import Calendar from "./calendar";
import { 
  DEFAULT_MOMENT_FORMAT,
  DEFAULT_DISPLAY_DATE_FORMAT
} from "@/constants/default-constants";

const CalendarContainer = styled.div`
  .ant-fullcalendar-year-select {
    display: none !important;
  }

  .ant-fullcalendar-month-select {
    display: none !important;
  }

  .ant-fullcalendar-header .ant-radio-group {
    display: none !important;
  }

  .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

class AsyncCalendar extends Component {

  dateCellRender = (value, dataSource) => {
    const groupedByDataSource = _.groupBy(dataSource, (data) => { 
      const date =  moment(data.attandanceDate, DEFAULT_MOMENT_FORMAT);
      return date.format(DEFAULT_DISPLAY_DATE_FORMAT);
    });
    const listData = groupedByDataSource[value.format(DEFAULT_DISPLAY_DATE_FORMAT)] || [];
    return (
      <ul className="events">
        {listData.map((item) => (
          <Fragment key={item.id}>
            <li>
              <Badge status="success" text={item.timeIn && item.timeIn.formattedValue} />
            </li>
            <li>
              {item.timeOut && <Badge status="error" text={item.timeOut.formattedValue} />} 
            </li>
          </Fragment>
        ))}
      </ul>
    );
  }

  render() {
    const { dataSource, loading } = this.props; 
    return (
      <Spin 
        spinning={loading} 
        tip="Loading..."
        indicator={<LoadingOutlined spin />}
      >
        <CalendarContainer>
          <Calendar 
            dataSource={dataSource}
            dateCellRender={this.dateCellRender}
          />
        </CalendarContainer>
      </Spin>
    );
  }
}

AsyncCalendar.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
};

export default withRouter(AsyncCalendar);