import { schema } from "normalizr";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";

const UserSchema = new schema.Entity("users", {
  employee: EmployeeSchema
});

const UserArraySchema = new schema.Array(UserSchema);

export {
  UserSchema,
  UserArraySchema,
};