import { END_OF_DAY_CUSTOMER_COMMISSION_SUCCESS } from "../constants";

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
  case END_OF_DAY_CUSTOMER_COMMISSION_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  default:
    return state;
  }
}