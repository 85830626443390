export default {
  "dashboard": "Dashboard",
  "hr": "HR",
  "inventory": "Inventory",
  "delivery": "Delivery",
  "accounting": "Accounting",
  "purchase": "Purchase",
  "sale": "Sale",
  "apps": "Apps",
  "website": "Website",
  "pos": "POS",
  "reports": "Reports",
  "settings": "Settings",
};
