export const DELIVERY_ORDER_REQUEST= "DELIVERY_ORDER_REQUEST";
export const DELIVERY_ORDER_SUCCESS= "DELIVERY_ORDER_REQUEST_SUCCESS";
  
export const DELIVERY_ORDER_AUTOCOMPLETE_REQUEST = "DELIVERY_ORDER_AUTOCOMPLETE_REQUEST";
export const DELIVERY_ORDER_AUTOCOMPLETE_SUCCESS = "DELIVERY_ORDER_AUTOCOMPLETE_SUCCESS";

export const DELIVERY_ORDER_CREATE_REQUEST= "DELIVERY_ORDER_CREATE_REQUEST";
export const DELIVERY_ORDER_DETAIL_REQUEST= "DELIVERY_ORDER_DETAIL_REQUEST";
export const DELIVERY_ORDER_BY_NO_REQUEST= "DELIVERY_ORDER_BY_NO_REQUEST";
export const DELIVERY_ORDER_EDIT_REQUEST= "DELIVERY_ORDER_EDIT_REQUEST";
export const DELIVERY_ORDER_ARCHIVE_REQUEST= "DELIVERY_ORDER_ARCHIVE_REQUEST";
export const DELIVERY_ORDER_UNARCHIVE_REQUEST= "DELIVERY_ORDER_UNARCHIVE_REQUEST";
export const DELIVERY_ORDER_DELETE_REQUEST= "DELIVERY_ORDER_DELETE_REQUEST";
export const DELIVERY_ORDER_BATCH_DELETE_REQUEST = "DELIVERY_ORDER_BATCH_DELETE_REQUEST";
export const DELIVERY_ORDER_ASSIGN_EMPLOYEE_REQUEST = "DELIVERY_ORDER_ASSIGN_EMPLOYEE_REQUEST";
export const DELIVERY_ORDER_START_REQUEST = "DELIVERY_ORDER_START_REQUEST";
export const DELIVERY_ORDER_CANCEL_REQUEST = "DELIVERY_ORDER_CANCEL_REQUEST";
export const DELIVERY_ORDER_COMPLETE_REQUEST = "DELIVERY_ORDER_COMPLETE_REQUEST";
export const DELIVERY_ORDER_SET_TO_DRAFT_REQUEST = "DELIVERY_ORDER_SET_TO_DRAFT_REQUEST";
export const DELIVERY_ORDER_LOG_CREATE_REQUEST = "DELIVERY_ORDER_LOG_CREATE_REQUEST";

export const DELIVERY_ORDER_CREATE_FORM_NAME = "createDeliveryOrder";
export const DELIVERY_ORDER_EDIT_FORM_NAME = "editDeliveryOrder";
export const DELIVERY_ORDER_ASSIGN_FORM_NAME = "assignEmployee";

export const DELIVERY_ORDER_STATUS_DRAFT = 1;
export const DELIVERY_ORDER_STATUS_DRAFT_TITLE = "Draft";
export const DELIVERY_ORDER_STATUS_ASSIGNED = 2;
export const DELIVERY_ORDER_STATUS_ASSIGNED_TITLE = "Assigned";
export const DELIVERY_ORDER_STATUS_STARTED = 3;
export const DELIVERY_ORDER_STATUS_STARTED_TITLE = "Started";
export const DELIVERY_ORDER_STATUS_CANCELLED = 4;
export const DELIVERY_ORDER_STATUS_CANCELLED_TITLE = "Cancelled";
export const DELIVERY_ORDER_STATUS_COMPLETED = 5;
export const DELIVERY_ORDER_STATUS_COMPLETED_TITLE = "Completed";
export const DELIVERY_ORDER_STATUS_DELIVERED_ID = 6;
export const DELIVERY_ORDER_STATUS_DELIVERED_TITLE_NAME = "Delivered";

export const DELIVERY_ORDER_SERIAL_STATUS_PENDING = 1;
export const DELIVERY_ORDER_SERIAL_STATUS_PENDING_TITLE = "Pending";
export const DELIVERY_ORDER_SERIAL_STATUS_ASSIGN = 2;
export const DELIVERY_ORDER_STATUS_ASSIGN_TITLE = "Assigned";
export const DELIVERY_ORDER_STATUS_DELIVERED = 3;
export const DELIVERY_ORDER_STATUS_DELIVERED_TITLE = "Delivered";

export const MODAL_TYPE_START = 1;
export const MODAL_TYPE_START_TITLE = "Start Delivery";
export const MODAL_TYPE_COMPLETE = 2;
export const MODAL_TYPE_COMPLETE_TITLE = "Complete Delivery";
export const MODAL_TYPE_CANCEL = 3;
export const MODAL_TYPE_CANCEL_TITLE = "Cancel Delivery";
export const MODAL_TYPE_SET_TO_DRAFT = 4;
export const MODAL_TYPE_SET_TO_DRAFT_TITLE = "Set To Draft Delivery";
export const MODAL_TYPE_ADD_LOG = 5;
export const MODAL_TYPE_ADD_LOG_TITLE = "Add Log";

export const DELIVERY_ORDER_TYPE_NORMAL = 1;
export const DELIVERY_ORDER_TYPE_EXPRESS = 2;

export const DELIVERY_ORDER_STATUS = [
  {
    id: DELIVERY_ORDER_STATUS_DRAFT,
    name: DELIVERY_ORDER_STATUS_DRAFT_TITLE,
  },
  {
    id: DELIVERY_ORDER_STATUS_ASSIGNED,
    name: DELIVERY_ORDER_STATUS_ASSIGNED_TITLE,
  },
  {
    id: DELIVERY_ORDER_STATUS_STARTED,
    name: DELIVERY_ORDER_STATUS_STARTED_TITLE,
  },
  {
    id: DELIVERY_ORDER_STATUS_CANCELLED,
    name: DELIVERY_ORDER_STATUS_CANCELLED_TITLE,
  },
  {
    id: DELIVERY_ORDER_STATUS_DELIVERED_ID,
    name: DELIVERY_ORDER_STATUS_DELIVERED_TITLE_NAME,
  },
  {
    id: DELIVERY_ORDER_STATUS_COMPLETED,
    name: DELIVERY_ORDER_STATUS_COMPLETED_TITLE,
  },
];

export const DELIVERY_ORDER_TYPES = [
  {
    id: DELIVERY_ORDER_TYPE_NORMAL,
    name: "Normal"
  },
  {
    id: DELIVERY_ORDER_TYPE_EXPRESS,
    name: "Express"
  }
];

export const TYPE_ASSIGN_EMPLOYEE = 1;
export const TYPE_START = 2;

export const DELIVERY_ORDER_START_FORM_NAME = "startDeliveryOrder";
export const DELIVERY_ORDER_ITEMS_FIELD_NAME = "deliveryOrderItems";
export const DELIVERY_ORDER_ITEM_SERIAL_FORM_NAME = "itemSerial";

export const READ_DELIVERY_ORDER = 199000;
export const CREATE_DELIVERY_ORDER = 199001;
export const UPDATE_DELIVERY_ORDER = 199002;
export const DELETE_DELIVERY_ORDER = 199003;
export const ARCHIVE_DELIVERY_ORDER = 199004;
export const UNARCHIVE_DELIVERY_ORDER = 199005;
export const BATCH_DELETE_DELIVERY_ORDER = 199006;
export const PRINT_DELIVERY_ORDER = 199007;
export const IMPORT_DELIVERY_ORDER = 199008;
export const EXPORT_DELIVERY_ORDER = 199009;
export const REPORT_DELIVERY_ORDER = 199010;
export const START_DELIVERY_ORDER = 199011;
export const CANCEL_DELIVERY_ORDER = 199012;
export const COMPLETE_DELIVERY_ORDER = 199013;
export const ASSIGN_DELIVERY_ORDER = 199014;