// Employee
import { READ_EMPLOYEE } from "@modules/hr/employee-directory/employee/constants";
import { READ_POSITION } from "@modules/hr/employee-directory/position/constants";
import { READ_DEPARTMENT } from "@modules/hr/employee-directory/department/constants";
import { READ_JOB_TYPE } from "@modules/hr/employee-directory/job-type/constants";
import { READ_EMPLOYMENT_CARD } from "@modules/hr/employee-directory/employment-card/constants";
import { READ_EMPLOYEE_SETTING_CODE } from "@/modules/hr/employee-directory/setting/constants";

// Recruitment
import { READ_JOB_OPENING } from "@modules/hr/recruitment/job-opening/constants";
import { READ_JOB_APPLICANT } from "@modules/hr/recruitment/job-applicant/constants";
import { READ_RECRUITMENT_STAGE } from "@modules/hr/recruitment/recruitment-stage/constants";
import { READ_OFFER_TERM } from "@modules/hr/recruitment/offer-term/constants";
import { READ_OFFER_LETTER } from "@modules/hr/recruitment/offer-letter/constants";
import { READ_RECRUITMENT_SETTING_CODE } from "@/modules/hr/recruitment/setting/constants";

// Leave
import { READ_LEAVE_REQUEST } from "@modules/hr/leave/leave-request/constants";
import { READ_LEAVE_ALLOCATION } from "@modules/hr/leave/leave-allocation/constants";
import { READ_LEAVE_TYPE } from "@modules/hr/leave/leave-type/constants";
import { READ_LEAVE_POLICY } from "@modules/hr/leave/leave-policy/constants";
import { READ_HOLIDAY_GROUP } from "@modules/hr/leave/holiday-group/constants";
import { READ_LEAVE_SETTING_CODE } from "@modules/hr/leave/setting/constants";

// Attendance
import { READ_WORKING_DAY } from "@modules/hr/attendance/working-day/constants";
import { VIEW_ATTENDANCE_SUMMARY } from "@modules/hr/attendance/attendance-entry/constants";
import { READ_ATTENDANCE_SETTING_CODE } from "@modules/hr/attendance/setting/constants";

// Appraisal
import { READ_PLAN } from "@modules/hr/appraisal/plan/constants";
import { READ_FORM } from "@modules/hr/appraisal/form/constants";
import { READ_ANNIVERSARY } from "@modules/hr/appraisal/anniversary/constants";

// Payroll
import { READ_PAYSLIP } from "@/modules/hr/payroll/payslip/constants";
import { READ_PAYSLIP_BATCH_CODE } from "@/modules/hr/payroll/payslip-batch/constants";
import { READ_SALARY_STRUCTURE } from "@modules/hr/payroll/salary-structure/constants";
import { READ_EARNING_TYPE } from "@modules/hr/payroll/earning-type/constants";
import { READ_DEDUCTION_TYPE } from "@modules/hr/payroll/deduction-type/constants";
import { READ_PAYROLL_SETTING_CODE } from "@modules/hr/payroll/setting/constants";
import { READ_INCENTIVE_TEMPLATE } from "@modules/hr/payroll/incentive-template/constants";

// Cash Advanced 
import { READ_CASH_ADVANCED } from "@modules/hr/cash-advanced/cash-advanced/constants";
import { READ_CASH_ADVANCED_SETTING_CODE } from "@modules/hr/cash-advanced/setting/constants";

//Contract
import { READ_CONTRACT } from "@/modules/hr/contract/contract/constants";

import { READ_MEETING } from "@/modules/hr/meeting/meeting/constants";
import { READ_ROOM } from "@/modules/hr/meeting/room/constants";

// Report
import { HUMAN_RESOURCE_REPORT_MENU } from "./reports";

export default {
  sideBarTitle: "Human Resource",
  hasDashboard: true,
  isFixed: false,
  slug: "human-resource",
  display: true,
  menus: [
    {
      id: 1,
      title: "Employee Directory",
      slug: "employee-directory",
      icon: "UserOutlined",
      sub: [
        {
          id: 1,
          title: "Employee",
          slug: "employee",
          code: READ_EMPLOYEE,
        },
        {
          id: 2,
          title: "Position",
          slug: "position",
          code: READ_POSITION,
        },
        {
          id: 3,
          title: "Department",
          slug: "department",
          code: READ_DEPARTMENT,
        },
        {
          id: 4,
          title: "Job Type",
          slug: "job-type",
          code: READ_JOB_TYPE,
        },
        {
          id: 5,
          title: "Employment Card",
          slug: "employment-card",
          code: READ_EMPLOYMENT_CARD,
        },
        {
          id: 6,
          title: "Employee Setting",
          slug: "employee-setting",
          code: READ_EMPLOYEE_SETTING_CODE,
        },
      ]
    },
    {
      id: 2,
      title: "Recruitment",
      slug: "recruitment",
      icon: "FileSearchOutlined",
      sub: [
        {
          id: 1,
          title: "Job Opening",
          slug: "job-opening",
          code: READ_JOB_OPENING,
        },
        {
          id: 2,
          title: "Job Applicant",
          slug: "job-applicant",
          code: READ_JOB_APPLICANT,
        },
        {
          id: 3,
          title: "Recruitment Stage",
          slug: "stage",
          code: READ_RECRUITMENT_STAGE,
        },
        {
          id: 4,
          title: "Offer Letter",
          slug: "offer-letter",
          code: READ_OFFER_LETTER,
        },
        {
          id: 5,
          title: "Offer Term",
          slug: "offer-term",
          code: READ_OFFER_TERM,
        },
        {
          id: 6,
          title: "Recruitment Setting",
          slug: "recruitment-setting",
          code: READ_RECRUITMENT_SETTING_CODE,
        },
      ]
    },
    {
      id: 3,
      title: "Appraisal",
      slug: "appraisal",
      icon: "EditOutlined",
      sub: [
        {
          id: 1,
          title: "Anniversary",
          slug: "anniversary",
          code: READ_ANNIVERSARY,
        },
        {
          id: 2,
          title: "Plan",
          slug: "plan",
          code: READ_PLAN,
        },
        {
          id: 3,
          title: "Form",
          slug: "form",
          code: READ_FORM,
        },
      ]
    },
    {
      id: 4,
      title: "Leave",
      slug: "leave",
      icon: "AuditOutlined",
      sub: [
        {
          id: 1,
          title: "Leave Request",
          slug: "leave-request",
          code: READ_LEAVE_REQUEST,
        },
        {
          id: 2,
          title: "Leave Type",
          slug: "leave-type",
          code: READ_LEAVE_TYPE,
        },
        {
          id: 3,
          title: "Leave Allocation",
          slug: "leave-allocation",
          code: READ_LEAVE_ALLOCATION,
        },
        {
          id: 4,
          title: "Leave Policy",
          slug: "leave-policy",
          code: READ_LEAVE_POLICY,
        },
        {
          id: 5,
          title: "Holiday Group",
          slug: "holiday-group",
          code: READ_HOLIDAY_GROUP,
        },
        {
          id: 6,
          title: "Leave Setting",
          slug: "leave-setting",
          code: READ_LEAVE_SETTING_CODE,
        },
      ]
    },
    {
      id: 5,
      title: "Attendance",
      slug: "attendance",
      icon: "ClockCircleOutlined",
      sub: [
        {
          id: 1,
          title: "Views",
          slug: "view",
          code: VIEW_ATTENDANCE_SUMMARY,
        },
        {
          id: 2,
          title: "Working Day",
          slug: "working-day",
          code: READ_WORKING_DAY,
        },
        {
          id: 3,
          title: "Attendance Setting",
          slug: "attendance-setting",
          code: READ_ATTENDANCE_SETTING_CODE,
        },
      ]
    },
    {
      id: 6,
      title: "Payroll",
      slug: "payroll",
      icon: "DollarOutlined",
      sub: [
        {
          id: 1,
          title: "Payslip",
          slug: "payslip",
          code: READ_PAYSLIP,
        },
        {
          id: 2,
          title: "Payslip Batch",
          slug: "payslip-batch",
          code: READ_PAYSLIP_BATCH_CODE,
        },
        {
          id: 3,
          title: "Deduction Type",
          slug: "deduction-type",
          code: READ_DEDUCTION_TYPE,
        },
        {
          id: 4,
          title: "Earning Type",
          slug: "earning-type",
          code: READ_EARNING_TYPE,
        },
        {
          id: 5,
          title: "Salary Structure",
          slug: "salary-structure",
          code: READ_SALARY_STRUCTURE,
        },
        {
          id: 6,
          title: "Incentive Template",
          slug: "incentive-template",
          code: READ_INCENTIVE_TEMPLATE,
        },
        {
          id: 7,
          title: "Payroll Setting",
          slug: "payroll-setting",
          code: READ_PAYROLL_SETTING_CODE,
        },
      ]
    },
    {
      id: 7,
      title: "Cash Advanced",
      slug: "cash-advance",
      icon: "CreditCardOutlined",
      sub: [
        {
          id: 1,
          title: "Cash Advanced",
          slug: "cash-advanced",
          code: READ_CASH_ADVANCED,
        },
        {
          id: 2,
          title: "Cash Advanced Setting",
          slug: "cash-advanced-setting",
          code: READ_CASH_ADVANCED_SETTING_CODE,
        },
      ]
    },
    {
      id: 8,
      title: "Contract",
      slug: "contract",
      icon: "FileTextOutlined",
      sub: [
        {
          id: 1,
          title: "Contract",
          slug: "contract",
          code: READ_CONTRACT,
        },
      ]
    },
    {
      id: 9,
      title: "Meeting",
      slug: "meeting",
      icon: "FileTextOutlined",
      sub: [
        {
          id: 1,
          title: "Meeting",
          slug: "meetings",
          code: READ_MEETING,
        },
        {
          id: 2,
          title: "Room",
          slug: "room",
          code: READ_ROOM,
        },
      ]
    },
    {
      id: 10,
      title: "Report",
      slug: "report",
      icon: "FileTextOutlined",
      sub: HUMAN_RESOURCE_REPORT_MENU
    },
  ]
};