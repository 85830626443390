export const LEAVE_REQUEST_LIST_REQUEST = "LEAVE_REQUEST_LIST_REQUEST"; 
export const LEAVE_REQUEST_LIST_SUCCESS = "LEAVE_REQUEST_LIST_REQUEST_SUCCESS";
export const LEAVE_REQUEST_CREATE_REQUEST = "LEAVE_REQUEST_CREATE_REQUEST"; 
export const LEAVE_REQUEST_DETAIL_REQUEST = "LEAVE_REQUEST_DETAIL_REQUEST"; 
export const LEAVE_REQUEST_ARCHIVE_REQUEST = "LEAVE_REQUEST_ARCHIVE_REQUEST"; 
export const LEAVE_REQUEST_DELETE_REQUEST = "LEAVE_REQUEST_DELETE_REQUEST"; 
export const LEAVE_REQUEST_UNARCHIVE_REQUEST = "LEAVE_REQUEST_UNARCHIVE_REQUEST"; 
export const LEAVE_REQUEST_EDIT_REQUEST = "LEAVE_REQUEST_EDIT_REQUEST"; 
export const LEAVE_REQUEST_CREATE_FORM_NAME = "createLeaveRequest"; 
export const LEAVE_REQUEST_EDIT_FORM_NAME = "editLeaveRequest"; 
export const LEAVE_REQUEST_AUTOCOMPLETE_REQUEST = "LEAVE_REQUEST_AUTOCOMPLETE_REQUEST";
export const LEAVE_REQUEST_AUTOCOMPLETE_SUCCESS = "LEAVE_REQUEST_AUTOCOMPLETE_SUCCESS";
export const LEAVE_REQUEST_STATUS_TO_OPEN_REQUEST = "LEAVE_REQUEST_STATUS_TO_OPEN_REQUEST";
export const LEAVE_REQUEST_STATUS_TO_CLOSE_REQUEST = "LEAVE_REQUEST_STATUS_TO_CLOSE_REQUEST";

export const LEAVE_REQUEST_STATUS_DRAFT = 1;
export const LEAVE_REQUEST_STATUS_DRAFT_TITLE = "Draft";
export const LEAVE_REQUEST_STATUS_SUBMITTED = 2;
export const LEAVE_REQUEST_STATUS_SUBMITTED_TITLE = "Submitted";
export const LEAVE_REQUEST_STATUS_CANCELLED = 3;
export const LEAVE_REQUEST_STATUS_CANCELLED_TITLE = "Cancelled";
export const LEAVE_REQUEST_STATUS_APPROVED = 4;
export const LEAVE_REQUEST_STATUS_APPROVED_TITLE = "Approved";
export const LEAVE_REQUEST_STATUS_REJECT = 5;
export const LEAVE_REQUEST_STATUS_REJECT_TITLE = "Rejected";

export const LEAVE_REQUEST_APPROVE_REQUEST = "LEAVE_REQUEST_APPROVE_REQUEST";
export const LEAVE_REQUEST_REJECT_REQUEST = "LEAVE_REQUEST_REJECT_REQUEST";
export const LEAVE_REQUEST_SUBMIT_REQUEST = "LEAVE_REQUEST_SUBMIT_REQUEST";
export const LEAVE_REQUEST_CANCEL_REQUEST = "LEAVE_REQUEST_CANCEL_REQUEST";

export const LEAVE_REQUEST_SUBMIT_RULES = {
  duration: {
    required: true,
  },
  employeeId: {
    required: true,
  },
  leaveTypeId: {
    required: true,
  },
};

export const LEAVE_REQUEST_STATUS = [
  {
    id: LEAVE_REQUEST_STATUS_DRAFT,
    name: LEAVE_REQUEST_STATUS_DRAFT_TITLE
  },
  {
    id: LEAVE_REQUEST_STATUS_SUBMITTED,
    name: LEAVE_REQUEST_STATUS_SUBMITTED_TITLE,
  },
  {
    id: LEAVE_REQUEST_STATUS_CANCELLED,
    name: LEAVE_REQUEST_STATUS_CANCELLED_TITLE,
  },
  {
    id: LEAVE_REQUEST_STATUS_APPROVED,
    name: LEAVE_REQUEST_STATUS_APPROVED_TITLE,
  },
  {
    id: LEAVE_REQUEST_STATUS_REJECT,
    name: LEAVE_REQUEST_STATUS_REJECT_TITLE,
  }
];

export const LEAVE_REQUEST_DURATION_FIRST_HALF = 1; 
export const LEAVE_REQUEST_DURATION_SECOND_HALF = 2; 
export const LEAVE_REQUEST_DURATION_FULL_DAY = 3;
export const LEAVE_REQUEST_DURATION_FIRST_HALF_TITLE = "First Half"; 
export const LEAVE_REQUEST_DURATION_SECOND_HALF_TITLE = "Second Half";
export const LEAVE_REQUEST_DURATION_FULL_DAY_TITLE = "Full Day"; 

export const LEAVE_REQUEST_HALF_DAY_VALUE = 0.5;
export const LEAVE_REQUEST_FULL_DAY_VALUE = 1;

export const LEAVE_REQUEST_DURATION = [ 
  { 
    id: LEAVE_REQUEST_DURATION_FULL_DAY, 
    name: LEAVE_REQUEST_DURATION_FULL_DAY_TITLE
  }, 
  { 
    id: LEAVE_REQUEST_DURATION_FIRST_HALF, 
    name: LEAVE_REQUEST_DURATION_FIRST_HALF_TITLE 
  },
  { 
    id: LEAVE_REQUEST_DURATION_SECOND_HALF, 
    name: LEAVE_REQUEST_DURATION_SECOND_HALF_TITLE 
  } 
];

export const READ_LEAVE_REQUEST = 157000;
export const CREATE_LEAVE_REQUEST = 157001;
export const UPDATE_LEAVE_REQUEST = 157002;
export const DELETE_LEAVE_REQUEST = 157003;
export const ARCHIVE_LEAVE_REQUEST = 157004;
export const UNARCHIVE_LEAVE_REQUEST = 157005;
export const PRINT_LEAVE_REQUEST = 157007;