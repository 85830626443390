import { EMAIL_TEMPLATE_TYPE_VARIABLE_SUCCESS } from "../constants";

export default function (state = {}, action) {
  switch (action.type) {
  case EMAIL_TEMPLATE_TYPE_VARIABLE_SUCCESS:
    return {
      ...state,
      [action.id]: action.payload
    };
  default:
    return state;
  }
}