import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { formValueSelector, change} from "redux-form";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

// Component
import Input from "@cores/components/Input";
import Editor from "@cores/components/Editor";
import CheckBox from "@cores/components/check-box";
import FileUploader from "@cores/components/file-uploader";
import Select from "@cores/components/autocomplete-static";
import DateRangePicker from "@cores/components/date-range-picker";
import EmployeeAutoComplete from "@cores/components/auto-complete/employee";
import LeaveTypeAutoComplete from "@cores/components/auto-complete/leave-type";

import * as Constants from "../../constants";
import { getDayLabel } from "@modules/helpers";
import { calculateLeaveRequestDuration } from "../../helpers";

class FormComponent extends Component {

  state = {
    disabled: false,
    toDurationTypeId: null
  };

  UNSAFE_componentWillReceiveProps = (newProps) => {
    this.setState(calculateLeaveRequestDuration(this.props, newProps));
  }

  onLeaveTypeChange = (values) => {
    const { 
      updateIsAllowPartial, 
      updateIsPartialLeave,
      updateIsDocumentRequired,
    } = this.props;
    const { allowPartial, documentRequired } = values;
    updateIsAllowPartial(allowPartial);
    if (!allowPartial) {
      updateIsPartialLeave(allowPartial);
    }
    updateIsDocumentRequired(documentRequired);
  }

  render() {
    const leaveTypeIds = [];
    const employeeIds = [];
    const { 
      userId,
      editable,
      isAllowPartial,
      isPartialLeave,
      isDocumentRequired,
    } = this.props;
    if (editable) {
      const { 
        entity: { 
          leaveType, 
          employee
        } } = this.props;
      leaveTypeIds.push(leaveType && leaveType.id);
      employeeIds.push(userId != null ? userId : (employee && employee.id));
    } else {
      employeeIds.push(userId);
    }
    const { disabled, toDurationTypeId } = this.state;
    return (
      <Row>
        <Col md="6" xs="12">
          <EmployeeAutoComplete
            autoFocus
            disabled
            ids={employeeIds}
          />
        </Col>
        <Col md="6" xs="12">
          <LeaveTypeAutoComplete
            ids={leaveTypeIds}
            includeDetail
            onSelectChange={this.onLeaveTypeChange} 
          />
        </Col>
        {isAllowPartial && (
          <Col lg="12" md="12" sm="12" xs="12">
            <CheckBox 
              label="Partial Leave" 
              name="isPartialLeave" 
            />
          </Col>
        )}
        <Col lg="6" md="12" xs="12"> 
          <DateRangePicker
            required
            toName="endDate"
            label="Request Date"
            fromName="startDate" 
          />
        </Col>
        {isPartialLeave && (
          <Fragment>
            <Col md="3" xs="12">
              <Select 
                required
                name="fromDurationType" 
                label="Duration Type (Start Date)" 
                dataSource={Constants.LEAVE_REQUEST_DURATION} 
              />
            </Col> 
            <Col md="3" xs="12">
              <Select 
                required
                name="toDurationType" 
                disabled={disabled}
                label="Duration Type (End Date)" 
                unselectable={toDurationTypeId}
                dataSource={Constants.LEAVE_REQUEST_DURATION} 
              />
            </Col> 
          </Fragment>
        )}
        <Col lg="6" md="12" xs="12"> 
          <Input 
            required 
            disabled
            name="duration"
            label="Duration (days)"
          />
        </Col>
        {isDocumentRequired && (
          <Col xs="12">
            <FileUploader
              required
              label="Document"
              name="documentUrl"
              path="/hr/leave/request"
            />
          </Col>
        )}
        <Col xs="12">
          <Editor 
            required
            name="reason"
            label="Reason"
          />
        </Col>
      </Row>
    );
  }
}

FormComponent.propTypes = {
  entity: PropTypes.object,
  userId: PropTypes.object,
  editable: PropTypes.bool,
  isPartialLeave: PropTypes.bool,
  isAllowPartial: PropTypes.bool,
  isDocumentRequired: PropTypes.bool,
  updateIsAllowPartial: PropTypes.func,
  updateIsPartialLeave: PropTypes.func,
  updateIsDocumentRequired: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { entities } = state;
  const { formName } = ownProps;
  const selector = formValueSelector(formName);
  const endDate = selector(state, "endDate");
  const startDate = selector(state, "startDate");
  const toDurationType = selector(state, "toDurationType");
  const isAllowPartial = selector(state, "isAllowPartial");
  const isPartialLeave = selector(state, "isPartialLeave");
  const fromDurationType = selector(state, "fromDurationType");
  const isDocumentRequired = selector(state, "isDocumentRequired");
  return {
    entities,
    startDate,
    endDate,
    fromDurationType,
    toDurationType,
    isAllowPartial,
    isDocumentRequired,
    isPartialLeave,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    updateDuration: (value) => dispatch(change(formName, "duration", getDayLabel(value))),
    updateFromDurationType: (value) => dispatch(change(formName, "fromDurationType", value)),
    updateToDurationType: (value) => dispatch(change(formName, "toDurationType", value)),
    updateIsAllowPartial: (value) => dispatch(change(formName, "isAllowPartial", value)),
    updateIsPartialLeave: (value) => dispatch(change(formName, "isPartialLeave", value)),
    updateIsDocumentRequired: (value) => dispatch(change(formName, "isDocumentRequired", value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);