import React from "react";
import { Badge, Tooltip } from "antd";
import * as Constants from "../constants";
import * as moment from "moment";
import { DEFAULT_DISPLAY_TIME_FORMAT } from "@/constants/default-constants";

export const renderAttendanceStatus = (values) => {
  const { attendanceStatus, description } = values;
  let status = null;
  switch(attendanceStatus) {
  case Constants.ATTENDANCE_STATUS_INVALID:
    status = <Tooltip placement="top" title={description}>
      <Badge status="error" text={Constants.ATTENDANCE_STATUS_INVALID_TITLE} />
    </Tooltip>;
    break;
  case Constants.ATTENDANCE_STATUS_VALID:
    status = <Badge status="success" text={Constants.ATTENDANCE_STATUS_VALID_TITLE} />;
    break;
  case Constants.ATTENDANCE_STATUS_WAITING_APPROVAL:
    status = <Tooltip placement="top" title={description}>
      <Badge status="processing" text={Constants.ATTENDANCE_STATUS_WAITING_APPROVAL_TITLE} />
    </Tooltip>;
    break;
  case Constants.ATTENDANCE_STATUS_APPROVED:
    status = <Tooltip placement="top" title={description}>
      <Badge status="success" text={Constants.ATTENDANCE_STATUS_APPROVED_TITLE} />
    </Tooltip>;
    break;
  case Constants.ATTENDANCE_STATUS_REJECT:
    status = <Badge status="error" text={Constants.ATTENDANCE_STATUS_REJECT_TITLE} />;
    break;
  case Constants.ATTENDANCE_STATUS_PAID:
    status = <Badge status="success" text={Constants.ATTENDANCE_STATUS_PAID_TITLE} />;
    break;
  default:
    status;
  }
  return status;
};

export const editAttendanceSubmitRules = (values) => {
  const { timeIn, timeOut } =  values;
  const momentIn = moment(timeIn, DEFAULT_DISPLAY_TIME_FORMAT);
  const momentOut = moment(timeOut, DEFAULT_DISPLAY_TIME_FORMAT);
  const isValid = momentIn.isAfter(momentOut);
  const rules = {
    timeIn :{
      required: true,
    },
    timeOut :{
      required: true,
    },
    description: {
      required: true,
    }
  };
  rules.timeOut["compare"] = {
    name: "timeIn",
    message: "invalid time",
    condition: isValid,
  };
  return rules;
};

export const createAttendanceSubmitRules = (values) => {
  const { checkInTime, checkOutTime } =  values;
  const momentIn = moment(checkInTime, DEFAULT_DISPLAY_TIME_FORMAT);
  const momentOut = moment(checkOutTime, DEFAULT_DISPLAY_TIME_FORMAT);
  const isValid = momentIn.isAfter(momentOut);
  const rules = {
    attandanceDate: {
      required: true
    },
    checkInTime :{
      required: true,
    },
    checkOutTime :{
      required: true,
    },
    description: {
      required: true,
    },
  };
  rules.checkOutTime["compare"] = {
    name: "checkInTime",
    message: "invalid time",
    condition: isValid,
  };
  return rules;
};