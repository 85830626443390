// Libs
import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
import styled from "styled-components";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const WrapperCollapse = styled(Collapse)`
  .ant-collapse-item {
    border: none !important;

    .ant-collapse-header {
      padding: 0.50rem !important;
      i {
        width: 100%;
        left: 0 !important;
        margin-top: 0 !important;
        font-size: 10px !important;
        padding: 15px;
        svg {
          float: right;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0rem !important;
    }
  }
`;

const { Panel } = Collapse;

function CollapsePanel(props) {

  const [collapse, setCollapse] = React.useState(props.collapse);

  const onChange = () => {
    setCollapse(!collapse);
  };

  const {
    title,
    children,
    className,
    activeKey,
  } = props;
  return (
    <WrapperCollapse
      bordered={false}
      className={className}
      onChange={onChange}
      defaultActiveKey={activeKey}
    >
      <Panel
        key="1"
        showArrow={false} 
        header={title}
        extra={collapse ? <CaretUpOutlined /> : <CaretDownOutlined />} 
      >
        {children}
      </Panel>
    </WrapperCollapse>
  );
}

CollapsePanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  collapse: PropTypes.bool,
  className: PropTypes.string,
  activeKey: PropTypes.string,
};

CollapsePanel.defaultProps = {
  activeKey: "1",
};

export default CollapsePanel;