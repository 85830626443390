export const BANNER_LIST_REQUEST = "BANNER_LIST_REQUEST"; 
export const BANNER_LIST_SUCCESS = "BANNER_LIST_REQUEST_SUCCESS";

export const BANNER_CREATE_REQUEST = "BANNER_CREATE_REQUEST"; 
export const BANNER_DETAIL_REQUEST = "BANNER_DETAIL_REQUEST"; 
export const BANNER_ARCHIVE_REQUEST = "BANNER_ARCHIVE_REQUEST"; 
export const BANNER_DELETE_REQUEST = "BANNER_DELETE_REQUEST"; 

export const BANNER_UNARCHIVE_REQUEST = "BANNER_UNARCHIVE_REQUEST"; 
export const BANNER_EDIT_REQUEST = "BANNER_EDIT_REQUEST"; 

export const BANNER_CREATE_FORM_NAME = "createBanner"; 
export const BANNER_EDIT_FORM_NAME = "editBanner";
export const BANNER_ITEM_FIELD_NAME = "bannerItems";

export const BANNER_AUTOCOMPLETE_REQUEST = "BANNER_AUTOCOMPLETE_REQUEST";
export const BANNER_AUTOCOMPLETE_SUCCESS = "BANNER_AUTOCOMPLETE_SUCCESS";

export const BANNER_SUBMIT_RULES = (values) => {
  const { type } = values;
  return {
    name: {
      required: true,
    },
    code: {
      required: true,
    },
    type: {
      required: true,
    },
    value: {
      required: (type == BANNER_TYPE_SINGLE || type == BANNER_TYPE_EMBEDDED),
    },
    bannerItems: {
      required: type == BANNER_TYPE_MULTIPLE,
      type: "array",
    }
  }; 
};

export const BANNER_ITEM_SUBMIT_RULES = () => {
  return {
    title: {
      required: true,
    },
    imageUrl: {
      required: true,
    },
  };
};

export const READ_BANNER = 251000;
export const CREATE_BANNER = 251001;
export const UPDATE_BANNER = 251002;
export const DELETE_BANNER = 251003;
export const ARCHIVE_BANNER = 251004;
export const UNARCHIVE_BANNER = 251005;

export const BANNER_TYPE_SINGLE = 1;
export const BANNER_TYPE_SINGLE_TITLE = "Single";
export const BANNER_TYPE_MULTIPLE = 2;
export const BANNER_TYPE_MULTIPLE_TITLE = "Multiple";
export const BANNER_TYPE_EMBEDDED = 3;
export const BANNER_TYPE_EMBEDDED_TITLE = "Embed";

export const BANNER_TYPE_DATA_SOURCE = [
  {
    id: BANNER_TYPE_SINGLE,
    name: BANNER_TYPE_SINGLE_TITLE,
  },
  {
    id: BANNER_TYPE_MULTIPLE,
    name: BANNER_TYPE_MULTIPLE_TITLE,
  },
  {
    id: BANNER_TYPE_EMBEDDED,
    name: BANNER_TYPE_EMBEDDED_TITLE,
  }
];