import {
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
  createEditAction,
} from "@modules/utils/action-creator";
import { normalize } from "normalizr";
import * as service from "../services";
import * as Constants from "../constants";
import { StockOutArraySchema, StockOutSchema } from "../schema";
import { mergeEntities } from "@/actions/entity";
import { SALE_ORDER_STOCK_PROCESS } from "@sale/sale-center/sale-order/constants";
import { SaleOrderSchema } from "@sale/sale-center/sale-order/schema";

const path = "/inventory/stock/stock-out";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getStockOut,
    schema: StockOutArraySchema,
    requestName: Constants.STOCK_OUT_LIST_REQUEST,
    dispatch,
    getState,
  };
  return createListAction(options, values);
};

export const getStockOutAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getStockOutAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getStockOutDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getStockOutDetail,
    requestName: Constants.STOCK_OUT_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: StockOutSchema,
  };
  return createDetailAction(options, id);
};

export const deleteStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteStockOut,
    requestName: Constants.STOCK_OUT_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Stock out is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const archiveStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveStockOut,
    requestName: Constants.STOCK_OUT_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Stock out is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const unarchiveStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveStockOut,
    requestName: Constants.STOCK_OUT_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const submitStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitStockOut,
    schema: StockOutSchema,
    requestName: Constants.STOCK_OUT_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approveStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveStockOut,
    schema: StockOutSchema,
    requestName: Constants.STOCK_OUT_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectStockOut,
    schema: StockOutSchema,
    requestName: Constants.STOCK_OUT_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const cancelStockOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.cancelStockOut,
    schema: StockOutSchema,
    requestName: Constants.STOCK_OUT_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is canceled successfully!",
  };
  return createEntityActionAction(options, id);
};

export const processStockOut = (values) => (dispatch, getState) => {
  const redirectUrl = (json) => {
    return `${path}/${json.stockOutNo}`;
  };
  const options = {
    serviceMethod: service.processStockOut,
    requestName: Constants.STOCK_OUT_PROCESS_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
    schema: StockOutSchema,
    entityName: "stockOuts",
    successMessage: "Stock out is processed successfully",
  };
  return createEditAction(options, values);  
};

export const convertToStockOut = (values) => (dispatch, getState) => {

  const successAction = (response) => {
    const saleOrderNo = values.saleOrderNo;
    const status = SALE_ORDER_STOCK_PROCESS;
    const normalized = normalize({ saleOrderNo, status, stockOut: response }, SaleOrderSchema);
    dispatch(mergeEntities(normalized.entities));
  };
  
  const options = {
    serviceMethod: service.convertToStockOut,
    schema: StockOutSchema,
    requestName: Constants.STOCK_OUT_CREATE_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is created successfully!",
    successAction: successAction
  };
  return createEntityActionAction(options, values);
};

export const stockPushBack = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.stockPushBack,
    schema: StockOutSchema,
    requestName: Constants.STOCK_OUT_PUSH_BACK_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock out is pushed back successfully!",
  };
  return createEntityActionAction(options, id);
};
