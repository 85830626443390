export const SALE_COMMISSION_LIST_REQUEST = "SALE_COMMISSION_LIST_REQUEST"; 
export const SALE_COMMISSION_LIST_SUCCESS = "SALE_COMMISSION_LIST_REQUEST_SUCCESS";
export const SALE_COMMISSION_CREATE_REQUEST = "SALE_COMMISSION_CREATE_REQUEST"; 
export const SALE_COMMISSION_DETAIL_REQUEST = "SALE_COMMISSION_DETAIL_REQUEST"; 
export const SALE_COMMISSION_ARCHIVE_REQUEST = "SALE_COMMISSION_ARCHIVE_REQUEST"; 
export const SALE_COMMISSION_DELETE_REQUEST = "SALE_COMMISSION_DELETE_REQUEST"; 
export const SALE_COMMISSION_UNARCHIVE_REQUEST = "SALE_COMMISSION_UNARCHIVE_REQUEST"; 
export const SALE_COMMISSION_EDIT_REQUEST = "SALE_COMMISSION_EDIT_REQUEST"; 
export const SALE_COMMISSION_CREATE_FORM_NAME = "createSaleCommission"; 
export const SALE_COMMISSION_EDIT_FORM_NAME = "editSaleCommission"; 
export const SALE_COMMISSION_AUTOCOMPLETE_REQUEST = "SALE_COMMISSION_AUTOCOMPLETE_REQUEST";
export const SALE_COMMISSION_AUTOCOMPLETE_SUCCESS = "SALE_COMMISSION_AUTOCOMPLETE_SUCCESS";

export const SALE_COMMISSION_TYPE_ITEM_ID = 1;
export const SALE_COMMISSION_TYPE_ITEM_NAME = "Item";
export const SALE_COMMISSION_TYPE_EMPLOYEE_ID = 2;
export const SALE_COMMISSION_TYPE_EMPLOYEE_NAME = "Employee";  

export const SALE_COMMISSION_TYPE_DATA_SOURCE = [
  {
    id: SALE_COMMISSION_TYPE_ITEM_ID,
    name: SALE_COMMISSION_TYPE_ITEM_NAME,
  },
  {
    id: SALE_COMMISSION_TYPE_EMPLOYEE_ID,
    name: SALE_COMMISSION_TYPE_EMPLOYEE_NAME,
  }
];

export const READ_SALE_COMMISSION = 231000;
export const CREATE_SALE_COMMISSION = 231001;
export const UPDATE_SALE_COMMISSION = 231002;
export const DELETE_SALE_COMMISSION = 231003;
export const ARCHIVE_SALE_COMMISSION = 231004;
export const UNARCHIVE_SALE_COMMISSION = 231005;