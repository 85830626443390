export default {
  "edit": "Edit",
  "date": "Dates",
  "create": "Create",
  "delete": "Delete",
  "archive": "Archive",
  "unarchive": "Unarchive",
  "loading": "Loading...",
  "comment": "Comment",
  "send": "Send",
  "sending": "Sending...",
  "back": "Back",
  "writeComment": "Write a comment...",
  "activityLog": "Activity Log",
  "action": "Action",
  "additionalInfo": "Additional info",
  "print": "Print",
  "duplicate": "Duplicate",
  "creator": "Creator",
  "createdAt": "Created At",
  "updatedAt": "Updated At",
  "watchers": "Watchers",
  "save": "Save",
  "cancel": "Cancel",
  "seeMore": "See more",
};