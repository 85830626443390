// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";

// Components
import PanelBox from "@cores/components/panel-box";
import DetailBox from "@cores/components/detail-box";
import CurrencyBox from "@cores/components/currency-box";

// helper
import { 
  getMonthLabel, 
  renderDisplayTextFromTextArea
} from "@modules/helpers";
import { 
  renderPaymentType,
  renderCashAdvancedStatus, 
  cashAdvancedCalculator, 
  renderCashAdvancedListStatus
} from "../../helpers";

class CashAdvancedDetail extends Component {

  columns = () => {
    return [
      {
        title: "No",
        width: "10%",
        render: (_, row, index) => index + 1 
      },
      {
        title: "Date",
        dataIndex: "date",
        width: "20%",
      },
      {
        title: "Principle",
        dataIndex: "principleAmount",
        width: "20%",
        render: (principleAmount) => <CurrencyBox value={principleAmount} />
      },
      {
        title: "Interest",
        dataIndex: "interestAmount",
        width: "20%",
        render: (interestAmount) => <CurrencyBox value={interestAmount} />
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: "20%",
        render: (amount) => <CurrencyBox value={amount} />
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "20%",
        render: (status) => renderCashAdvancedListStatus(status),
      },
    ];
  }

  render() {
    const {
      entity,
      profilePage,
      entity: { 
        cashAdvancedNo,
        employee,
        currency,
        amount,
        duration,
        effectiveMonth,
        effectiveYear,
        notation,
        status,
        interestRate,
        paymentType,
        cashAdvancedLists,
      }
    } = this.props;
    let dataSource = [];
    if (cashAdvancedLists && cashAdvancedLists.length > 0) {
      dataSource = cashAdvancedLists.map((cashAdvanced) => {
        cashAdvanced.amount = cashAdvanced.totalAmount;
        cashAdvanced.date = `${moment(cashAdvanced.month, "MM").format("MMMM")}/${cashAdvanced.year}`;
        return cashAdvanced;
      });
    } else {
      dataSource = cashAdvancedCalculator(entity);
    }
    return (
      <React.Fragment>
        <PanelBox title="General Info" hasBorder>
          <Row>
            <Col md="6" xs="12">
              <DetailBox
                title="Cash Advanced No"
                data={cashAdvancedNo}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Employee"
              >
                {employee && (
                  <Link to={`/human-resource/employee-directory/employee/${employee.id}`} disabled={profilePage}>
                    {employee.firstName} {employee.lastName}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Currency">
                {currency && (
                  <Link to={`/settings/setup/currency/${currency.id}`} disabled={profilePage}>
                    {currency.name}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Amount"
                data={<CurrencyBox value={amount} />}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Duration"
                data={getMonthLabel(duration)}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Interest Rate (% per month)"
                data={interestRate != null ? `${interestRate} %` : ""}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Effective Month">
                {moment(effectiveMonth, "MM").format("MMMM")}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Effective Year">
                {effectiveYear}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Payment Type"
                data={renderPaymentType(paymentType)}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Status"
                data={renderCashAdvancedStatus(status)}
              />
            </Col>
            <Col xs="12">
              <DetailBox
                leftCol="3"
                rightCol="9"
                title="Notation"
                data={renderDisplayTextFromTextArea(notation)}
              />
            </Col>
          </Row>
        </PanelBox>
        <PanelBox title="Calculator">
          <Table 
            size="small"
            dataSource={dataSource || []}
            columns={this.columns()}
            pagination={false}
            scroll={{y: 400}}
          />
        </PanelBox>
      </React.Fragment>
    );
  }
}

CashAdvancedDetail.propTypes = {
  entity: PropTypes.object,
  profilePage: PropTypes.bool,
};

export default CashAdvancedDetail;