import { 
  LeaveTypeSchema,
  LeaveTypeArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as LeaveTypeConstants from "../constants";

const path = "/human-resource/leave/leave-type";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLeaveType,
    schema: LeaveTypeArraySchema,
    requestName: LeaveTypeConstants.LEAVE_TYPE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createLeaveType = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createLeaveType,
    requestName: LeaveTypeConstants.LEAVE_TYPE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getLeaveTypeAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getLeaveTypeAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getLeaveTypeDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLeaveTypeDetail,
    requestName: LeaveTypeConstants.LEAVE_TYPE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: LeaveTypeSchema,
  };
  return createDetailAction(options, id);
};

export const editLeaveType = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editLeaveType,
    requestName: LeaveTypeConstants.LEAVE_TYPE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "leaveTypes",
    schema: LeaveTypeSchema,
    successMessage: "Leave type is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveLeaveType = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveLeaveType,
    requestName: LeaveTypeConstants.LEAVE_TYPE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Leave type is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteLeaveType = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteLeaveType,
    requestName: LeaveTypeConstants.LEAVE_TYPE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Leave type is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveLeaveType = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveLeaveType,
    requestName: LeaveTypeConstants.LEAVE_TYPE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave type is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};