// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import { LoadingOutlined } from "@ant-design/icons";

import styled from "styled-components";

const Wrapper = styled.div`
  i {
    color: red;
    font-size: 7.5vh;
  }

  @media (max-width: 1024px) {
    i {
      font-size: 5vh;
    }

    h3 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
    }
  }
`;

class Loading extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      timer: setTimeout(() => {
        this.setState({
          show: true
        });
      }, props.afterDelay)
    };
  }

  componentWillUnmount() {
    const { timer } = this.state;
    clearTimeout(timer);
  }

  render() {
    const { show } = this.state;
    const { header, text } = this.props;
    return (
      <Container style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
        {show && 
          <Wrapper className="col-12 text-center">
            <LoadingOutlined />
            <h3 className="my-3">{header}</h3>
            <h5 className="text-mute">{text}</h5>
          </Wrapper>
        }
      </Container>
    );
  }
}

Loading.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  afterDelay: PropTypes.number
};

export default Loading;