export const CUSTOMER_DEPOSIT_LIST_REQUEST = "CUSTOMER_DEPOSIT_LIST_REQUEST";
export const CUSTOMER_DEPOSIT_LIST_SUCCESS = "CUSTOMER_DEPOSIT_LIST_REQUEST_SUCCESS";
export const CUSTOMER_DEPOSIT_AUTOCOMPLETE_REQUEST = "CUSTOMER_DEPOSIT_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_DEPOSIT_AUTOCOMPLETE_SUCCESS = "CUSTOMER_DEPOSIT_AUTOCOMPLETE_SUCCESS";
export const CUSTOMER_DEPOSIT_CREATE_REQUEST = "CUSTOMER_DEPOSIT_CREATE_REQUEST";
export const CUSTOMER_DEPOSIT_DETAIL_REQUEST = "CUSTOMER_DEPOSIT_DETAIL_REQUEST";
export const CUSTOMER_DEPOSIT_ARCHIVE_REQUEST = "CUSTOMER_DEPOSIT_ARCHIVE_REQUEST";
export const CUSTOMER_DEPOSIT_DELETE_REQUEST = "CUSTOMER_DEPOSIT_DELETE_REQUEST";
export const CUSTOMER_DEPOSIT_UNARCHIVE_REQUEST = "CUSTOMER_DEPOSIT_UNARCHIVE_REQUEST";
export const CUSTOMER_DEPOSIT_EDIT_REQUEST = "CUSTOMER_DEPOSIT_EDIT_REQUEST";
export const CUSTOMER_DEPOSIT_CREATE_FORM_NAME = "createCustomerDeposit";
export const CUSTOMER_DEPOSIT_EDIT_FORM_NAME = "editCustomerDeposit";
export const CUSTOMER_DEPOSIT_POST_FORM_NAME = "customerDepositPost";

export const CUSTOMER_DEPOSIT_HISTORY_LIST_REQUEST = "CUSTOMER_DEPOSIT_LIST_REQUEST";
export const CUSTOMER_DEPOSIT_HISTORY_LIST_SUCCESS = "CUSTOMER_DEPOSIT_LIST_SUCCESS";

export const READ_CUSTOMER_DEPOSIT = 181000;
export const CREATE_CUSTOMER_DEPOSIT = 181001;
export const UPDATE_CUSTOMER_DEPOSIT = 181002;
export const DELETE_CUSTOMER_DEPOSIT = 181003;
export const ARCHIVE_CUSTOMER_DEPOSIT = 181004;
export const UNARCHIVE_CUSTOMER_DEPOSIT = 181005;
export const POST_CUSTOMER_DEPOSIT = 181011;
export const PRINT_CUSTOMER_DEPOSIT = 181007;
export const ACKNOWLEDGE_CUSTOMER_DEPOSIT = 181012;
export const UNACKNOWLEDGE_CUSTOMER_DEPOSIT = 181013;

export const CUSTOMER_DEPOSIT_POST_REQUEST = "CUSTOMER_DEPOSIT_POST_REQUEST";

export const CUSTOMER_DEPOSIT_STATUS_DRAFT = 1;
export const CUSTOMER_DEPOSIT_STATUS_DRAFT_TITLE = "Draft";
export const CUSTOMER_DEPOSIT_STATUS_POSTED = 2;
export const CUSTOMER_DEPOSIT_STATUS_POSTED_TITLE = "Posted";

export const CUSTOMER_DEPOSIT_STATUS = [
  {
    id: CUSTOMER_DEPOSIT_STATUS_DRAFT,
    name: CUSTOMER_DEPOSIT_STATUS_DRAFT_TITLE
  },
  {
    id: CUSTOMER_DEPOSIT_STATUS_POSTED,
    name: CUSTOMER_DEPOSIT_STATUS_POSTED_TITLE,
  },
];