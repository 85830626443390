// Utils
import { 
  LogInApprovalSchema,
  LogInApprovalArraySchema, 
} from "../schema";
import {
  createListAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLoginApproval,
    schema: LogInApprovalArraySchema,
    requestName: Constants.LOG_IN_APPROVAL_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const approveLoginApproval = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveLoginApproval,
    schema: LogInApprovalSchema,
    requestName: Constants.LOG_IN_APPROVAL_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Log in approval is approved successfully!",
  };
  return createEntityActionAction(options, values);
};

export const rejectLoginApproval = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectLoginApproval,
    schema: LogInApprovalSchema,
    requestName: Constants.LOG_IN_APPROVAL_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Log in approval is rejected successfully!",
  };
  return createEntityActionAction(options, values);
};