import * as service from "../services";
import { 
  UserAccountArraySchema, 
  UserAccountSchema,
} from "../schema";
import * as Constants from "../constants";
import { SessionArraySchema } from "../schema/session";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createListWithSeeMoreAction,
} from "@modules/utils/action-creator";

const path = "/settings/user-management/user-account";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getUserAccount,
    schema: UserAccountArraySchema,
    requestName: Constants.USER_ACCOUNT_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createUserAccount = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createUserAccount,
    requestName: Constants.USER_ACCOUNT_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getUserAccountAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getUserAccountAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getUserAccountDetail = (id, extra) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getUserAccountDetail,
    requestName: Constants.USER_ACCOUNT_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: UserAccountSchema,
  };
  return createDetailAction(options, id, extra);
};

export const getUserProfile = () => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getUserProfile,
    requestName: Constants.USER_PROFILE_REQUEST,
    dispatch,
    getState,
    schema: UserAccountSchema,
  };
  return createDetailAction(options);
};

export const editUserAccount = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editUserAccount,
    requestName: Constants.USER_ACCOUNT_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "userAccounts",
    schema: UserAccountSchema,
    successMessage: "User account is updated successfully",
  };
  return createEditAction(options, values);
};

export const changePasswordUserAccount = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.changePasswordUserAccount,
    requestName: Constants.USER_ACCOUNT_CHANGE_PASSWORD_REQUEST,
    dispatch,
    getState,
    successMessage: "User account password is changed successfully",
  };
  return createEditAction(options, values);
};

export const resetPasswordUserAccount = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.resetPasswordUserAccount,
    requestName: Constants.USER_ACCOUNT_RESET_PASSWORD_REQUEST,
    dispatch,
    getState,
    successMessage: "User account password is reset successfully",
  };
  return createEditAction(options, values);
};

export const archiveUserAccount = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveUserAccount,
    requestName: Constants.USER_ACCOUNT_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "User account is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteUserAccount = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteUserAccount,
    requestName: Constants.USER_ACCOUNT_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "User account is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveUserAccount = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveUserAccount,
    requestName: Constants.USER_ACCOUNT_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "User account is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const updateUserStatus = (values) => (dispatch, getState) => {
  const { status } = values;
  let statusMessage = "deactivated";
  if (status == Constants.USER_ACCOUNT_STATUS_ACTIVE) {
    statusMessage = "activated";
  }
  const options = {
    serviceMethod: service.updateUserStatus,
    requestName: Constants.UPDATE_USER_ACCOUNT_STATUS_REQUEST,
    dispatch,
    getState,
    entityName: "userAccounts",
    schema: UserAccountSchema,
    successMessage: `User account is ${statusMessage} successfully`,
  };
  return createEditAction(options, values);
};

export const getUserSession = (id, page) => (dispatch, getState) => {

  const getSessionSuccess = (sessions, employeeId) => {
    return {
      type: Constants.SESSION_SUCCESS,
      sessions,
      employeeId,
    };
  };

  const getSessionSuccessAddArray = (sessions, employeeId) => {
    return {
      type: Constants.SESSION_SUCCESS_NEXT_PAGE,
      sessions,
      employeeId,
    };
  };

  const values = { id, page };

  const options = {
    serviceMethod: service.getUserSession,
    schema: SessionArraySchema,
    requestName: Constants.USER_SESSION,
    dispatch,
    getState,
    getDataSuccess: getSessionSuccess,
    getDataSuccessAddArray: getSessionSuccessAddArray,
  };
  return createListWithSeeMoreAction(options, values);
};

export const deleteSession = (id, employeeId) => (dispatch, getState) => {

  const successAction = () => {
    return {
      type: Constants.SESSION_DELETE,
      id,
      employeeId,
    };
  };

  const options = {
    serviceMethod: service.deleteSession,
    requestName: Constants.DELETE_SESSION_REQUEST,
    dispatch,
    getState,
    successMessage: "Session is deleted successfully",
    successAction,
  };
  return createDeleteAction(options, id);
};

export const deleteAllSession = (employeeId) => (dispatch, getState) => {

  const successAction = () => {
    return {
      type: Constants.SESSION_DELETE_ALL,
      employeeId,
    };
  };

  const options = {
    serviceMethod: service.deleteAllSession,
    requestName: Constants.DELETE_ALL_SESSION_REQUEST,
    dispatch,
    getState,
    successMessage: "All session is deleted successfully",
    successAction,
  };
  return createDeleteAction(options, employeeId);
};