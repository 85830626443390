export const SUBMIT_RULES = {
  purchaseOrderTax: {
    type: "object",
    value: {
      required: true
    }
  },
};

export const READ_PO_SETTING = 189032;
export const UPDATE_PO_SETTING = 189031;

export const PO_SETTING_PATH = "/purchase/list/purchase-order-setting";
