export const EARNING_TYPE_LIST_REQUEST = "EARNING_TYPE_LIST_REQUEST"; 
export const EARNING_TYPE_LIST_SUCCESS = "EARNING_TYPE_LIST_REQUEST_SUCCESS";
export const EARNING_TYPE_CREATE_REQUEST = "EARNING_TYPE_CREATE_REQUEST"; 
export const EARNING_TYPE_DETAIL_REQUEST = "EARNING_TYPE_DETAIL_REQUEST"; 
export const EARNING_TYPE_ARCHIVE_REQUEST = "EARNING_TYPE_ARCHIVE_REQUEST"; 
export const EARNING_TYPE_DELETE_REQUEST = "EARNING_TYPE_DELETE_REQUEST"; 
export const EARNING_TYPE_UNARCHIVE_REQUEST = "EARNING_TYPE_UNARCHIVE_REQUEST"; 
export const EARNING_TYPE_EDIT_REQUEST = "EARNING_TYPE_EDIT_REQUEST"; 
export const EARNING_TYPE_CREATE_FORM_NAME = "createEarningType"; 
export const EARNING_TYPE_EDIT_FORM_NAME = "editEarningType"; 
export const EARNING_TYPE_INPUT_FIELD_NAME = "inputs"; 
export const EARNING_TYPE_AUTOCOMPLETE_REQUEST = "EARNING_TYPE_AUTOCOMPLETE_REQUEST";
export const EARNING_TYPE_AUTOCOMPLETE_SUCCESS = "EARNING_TYPE_AUTOCOMPLETE_SUCCESS";

export const READ_EARNING_TYPE = 167000;
export const CREATE_EARNING_TYPE = 167001;
export const UPDATE_EARNING_TYPE = 167002;
export const DELETE_EARNING_TYPE = 167003;
export const ARCHIVE_EARNING_TYPE = 167004;
export const UNARCHIVE_EARNING_TYPE = 167005;

export const EARNING_TYPE_COMPUTATION_FIX = 1;
export const EARNING_TYPE_COMPUTATION_PERCENTAGE = 2;
export const EARNING_TYPE_COMPUTATION_FORMULA = 3;

export const EarningTypeComputationTypes = [
  {
    name: "Fix Amount",
    id: EARNING_TYPE_COMPUTATION_FIX,
  },
  {
    name: "Percentage",
    id: EARNING_TYPE_COMPUTATION_PERCENTAGE,
  },
  {
    name: "Formula",
    id: EARNING_TYPE_COMPUTATION_FORMULA,
  },
];

export const EARNING_TYPE_EMPLOYEE_VARIABLE = [
  {
    name: "employee",
    type: "object",
    children: [
      {
        name: "salary",
        variable: "employee.salary"
      },
      {
        name: "salaryCurrency",
        variable: "employee.salaryCurrency"
      },
      {
        name: "minorChildren",
        variable: "employee.minorChildren"
      },
      {
        name: "totalWorkingHours",
        variable: "employee.totalWorkingHours"
      },
      {
        name: "workingHours",
        variable: "employee.workingHours"
      }
    ]
  }
];

export const EARNING_TYPE_CATEGORIES_VARIABLE = [
  {
    name: "categories",
    type: "object",
    children: [
      {
        name: "Basic",
        variable: "categories.Basic"
      },
      {
        name: "Allowance",
        variable: "categories.Allowance"
      },
      {
        name: "Gross",
        variable: "categories.Gross"
      },
      {
        name: "Net",
        variable: "categories.Net"
      }
    ]
  },
];

export const EARNING_TYPE_CURRENCY_EXCHANGE_FUNCTION_VARIABLE = [
  {
    name: "currentExchange(amount: number, from: Currency, to: Currency)",
    type: "function"
  }
];

export const EARNING_TYPE_CATEGORY_BASIC = 1;
export const EARNING_TYPE_CATEGORY_ALLOWANCE = 2;
export const EARNING_TYPE_CATEGORY_GROSS = 3;
export const EARNING_TYPE_CATEGORY_NET = 4;
export const EARNING_TYPE_CATEGORY_SALE_INCENTIVE = 5;

export const EarningTypeCategories = [
  {
    name: "Basic",
    id: EARNING_TYPE_CATEGORY_BASIC,
  },
  {
    name: "Allowance",
    id: EARNING_TYPE_CATEGORY_ALLOWANCE,
  },
  {
    name: "Gross",
    id: EARNING_TYPE_CATEGORY_GROSS,
  },
  {
    name: "Sale Incentive",
    id: EARNING_TYPE_CATEGORY_SALE_INCENTIVE,
  },
];