export const EXPENSE_TYPE_LIST_REQUEST = "EXPENSE_TYPE_LIST_REQUEST";
export const EXPENSE_TYPE_LIST_SUCCESS = "EXPENSE_TYPE_LIST_REQUEST_SUCCESS";
export const EXPENSE_TYPE_AUTOCOMPLETE_REQUEST = "EXPENSE_TYPE_AUTOCOMPLETE_REQUEST";
export const EXPENSE_TYPE_AUTOCOMPLETE_SUCCESS = "EXPENSE_TYPE_AUTOCOMPLETE_SUCCESS";
export const EXPENSE_TYPE_CREATE_REQUEST = "EXPENSE_TYPE_CREATE_REQUEST";
export const EXPENSE_TYPE_DETAIL_REQUEST = "EXPENSE_TYPE_DETAIL_REQUEST";
export const EXPENSE_TYPE_ARCHIVE_REQUEST = "EXPENSE_TYPE_ARCHIVE_REQUEST";
export const EXPENSE_TYPE_DELETE_REQUEST = "EXPENSE_TYPE_DELETE_REQUEST";
export const EXPENSE_TYPE_UNARCHIVE_REQUEST = "EXPENSE_TYPE_UNARCHIVE_REQUEST";
export const EXPENSE_TYPE_EDIT_REQUEST = "EXPENSE_TYPE_EDIT_REQUEST";
export const EXPENSE_TYPE_CREATE_FORM_NAME = "createExpenseType";
export const EXPENSE_TYPE_EDIT_FORM_NAME = "editExpenseType";
export const EXPENSE_TYPE_SUBMIT_RULES = {
  name: {
    required: true
  },
};
export const READ_EXPENSE_TYPE = 41000;
export const CREATE_EXPENSE_TYPE = 41001;
export const UPDATE_EXPENSE_TYPE = 41002;
export const ARCHIVE_EXPENSE_TYPE = 41003;
export const UNARCHIVE_EXPENSE_TYPE = 41004;
export const DELETE_EXPENSE_TYPE = 41005;