export const ASSET_REQUEST_LIST_REQUEST = "ASSET_REQUEST_REQUEST";
export const ASSET_REQUEST_LIST_SUCCESS = "ASSET_REQUEST_REQUEST_SUCCESS";
export const ASSET_REQUEST_AUTOCOMPLETE_REQUEST = "ASSET_REQUEST_AUTOCOMPLETE_REQUEST";
export const ASSET_REQUEST_AUTOCOMPLETE_SUCCESS = "ASSET_REQUEST_AUTOCOMPLETE_SUCCESS";
export const ASSET_REQUEST_CREATE_REQUEST = "ASSET_REQUEST_CREATE_REQUEST";
export const ASSET_REQUEST_DETAIL_REQUEST = "ASSET_REQUEST_DETAIL_REQUEST";
export const ASSET_REQUEST_EDIT_REQUEST = "ASSET_REQUEST_EDIT_REQUEST";
export const ASSET_REQUEST_ARCHIVE_REQUEST = "ASSET_REQUEST_ARCHIVE_REQUEST";
export const ASSET_REQUEST_UNARCHIVE_REQUEST = "ASSET_REQUEST_UNARCHIVE_REQUEST";
export const ASSET_REQUEST_DELETE_REQUEST = "ASSET_REQUEST_DELETE_REQUEST";
export const ASSET_REQUEST_BATCH_DELETE_REQUEST = "ASSET_REQUEST_BATCH_DELETE_REQUEST";

export const ASSET_REQUEST_SUBMIT_REQUEST = "ASSET_REQUEST_SUBMIT_REQUEST";
export const ASSET_REQUEST_APPROVE_REQUEST = "ASSET_REQUEST_APPROVE_REQUEST";
export const ASSET_REQUEST_REJECT_REQUEST = "ASSET_REQUEST_REJECT_REQUEST";
export const ASSET_REQUEST_CANCEL_REQUEST = "ASSET_REQUEST_CANCEL_REQUEST";
export const ASSET_REQUEST_PROCESS_ITEM_REQUEST = "ASSET_REQUEST_PROCESS_ITEM_REQUEST";

export const ASSET_REQUEST_CREATE_FORM_NAME = "createAssetRequest";
export const ASSET_REQUEST_EDIT_FORM_NAME = "editAssetRequest";
export const ASSET_REQUEST_ITEM_CREATE_FORM_NAME = "createAssetRequestItem";
export const ASSET_REQUEST_ITEM_EDIT_FORM_NAME = "editAssetRequestItem";
export const ASSET_REQUEST_ITEM_ARRAY_FIELD_NAME = "assetRequestItems";
export const ASSET_REQUEST_REQUEST_ITEM_ARRAY_FIELD_NAME = "requestItems";
export const ASSET_REQUEST_RECEIVED_ITEM_FORM_NAME = "receiveItem";
export const ASSET_REQUEST_SCAN_SERIAL_FORM_NAME = "scanSerial";

export const ASSET_REQUEST_SUBMIT_RULES = (values) => {
  const { type } = values;
  return {
    type: {
      required: true,
    },
    employeeId: {
      required: type == ASSET_REQUEST_TYPE_EMPLOYEE,
    },
    companyBranchId: {
      required: type == ASSET_REQUEST_TYPE_COMPANY,
    },
    name: {
      required: true
    },
    assetRequestItems: {
      type: "arrayInline",
      message: "Please add at lease 1 item.",
      required: true,
      subFields: [
        {
          name: "itemId"
        },
        {
          name: "quantity",
          type: "number",
        },
      ],
    }
  };
};

export const ASSET_REQUEST_TYPE_EMPLOYEE = 1;
export const ASSET_REQUEST_TYPE_COMPANY = 2;
export const ASSET_REQUEST_TYPE_EMPLOYEE_NAME = "Employee";
export const ASSET_REQUEST_TYPE_COMPANY_NAME = "Company";

export const ASSET_REQUEST_TYPE = [
  {
    id: ASSET_REQUEST_TYPE_EMPLOYEE,
    name: ASSET_REQUEST_TYPE_EMPLOYEE_NAME
  },
  {
    id: ASSET_REQUEST_TYPE_COMPANY,
    name: ASSET_REQUEST_TYPE_COMPANY_NAME
  }
];

export const ASSET_REQUEST_STATUS_DRAFT = 1;
export const ASSET_REQUEST_STATUS_DRAFT_TITLE = "Draft";
export const ASSET_REQUEST_STATUS_SUBMITTED = 2;
export const ASSET_REQUEST_STATUS_SUBMITTED_TITLE = "Submitted";
export const ASSET_REQUEST_STATUS_APPROVED = 3;
export const ASSET_REQUEST_STATUS_APPROVED_TITLE = "Approved";
export const ASSET_REQUEST_STATUS_REJECTED = 4;
export const ASSET_REQUEST_STATUS_REJECTED_TITLE = "Rejected";
export const ASSET_REQUEST_STATUS_PROCESSED = 5;
export const ASSET_REQUEST_STATUS_PROCESSED_TITLE = "Processed";
export const ASSET_REQUEST_STATUS_CANCELLED = 6;
export const ASSET_REQUEST_STATUS_CANCELLED_TITLE= "Cancelled";

export const ASSET_REQUEST_STATUS = [
  {
    id: ASSET_REQUEST_STATUS_DRAFT,
    name: ASSET_REQUEST_STATUS_DRAFT_TITLE,
  },
  {
    id: ASSET_REQUEST_STATUS_SUBMITTED,
    name: ASSET_REQUEST_STATUS_SUBMITTED_TITLE,
  },
  {
    id: ASSET_REQUEST_STATUS_APPROVED,
    name: ASSET_REQUEST_STATUS_APPROVED_TITLE,
  },
  {
    id: ASSET_REQUEST_STATUS_REJECTED,
    name: ASSET_REQUEST_STATUS_REJECTED_TITLE,
  },
  {
    id: ASSET_REQUEST_STATUS_PROCESSED,
    name: ASSET_REQUEST_STATUS_PROCESSED_TITLE,
  },
  {
    id: ASSET_REQUEST_STATUS_CANCELLED,
    name: ASSET_REQUEST_STATUS_CANCELLED_TITLE,
  },
];

export const ASSET_REQUEST_PROCESS_ITEM_SUBMIT_RULES = {
  warehouseId: {
    required: true,
  },
  requestItems: {
    type: "array",
    message: "Request item must have at lease 1 item.",
    required: true
  }
};

export const READ_ASSET_REQUEST = 214000;
export const CREATE_ASSET_REQUEST = 214001;
export const UPDATE_ASSET_REQUEST = 214002;
export const DELETE_ASSET_REQUEST = 214003;
export const ARCHIVE_ASSET_REQUEST = 214004;
export const UNARCHIVE_ASSET_REQUEST = 214005;
export const PRINT_ASSET_REQUEST = 214007;
export const SUBMIT_ASSET_REQUEST = 214011;
export const APPROVE_ASSET_REQUEST = 214012;
export const REJECT_ASSET_REQUEST = 214013;
export const PROCESS_ASSET_REQUEST = 214014;
export const CANCEL_ASSET_REQUEST = 214015;