import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { submit as ReduxFormSubmit } from "redux-form";
import PropTypes from "prop-types";
import Loading from "react-loading-bar";
import { denormalize } from "normalizr";

// Form
import Form from "./form";
import { getPageTitle } from "@modules/utils/value-selector";
import { getBreadcrumbPageLabel } from "@modules/helpers";

// Action
import { getCustomFieldByEntity } from "@modules/settings/setup/custom-field/actions";
import { CustomFieldArraySchema } from "@modules/settings/setup/custom-field/schema";
import { CUSTOMER_FIELD_REQUEST } from "@modules/settings/setup/custom-field/constants";

class CreateLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shouldContinueCreate: false,
      shouldContinueEdit: false,
    };
    this.onEventLoad();
    this.getCustomField();
  }

  getCustomField = () => {
    const { getCustomFieldByEntity, title } = this.props;
    const entityName = title.replace(/\s/g, "");
    getCustomFieldByEntity(entityName);
  }

  onEventLoad = () => {
    window.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.which === 83) {
        event.preventDefault();
        this.onSave();
      } else if (event.altKey && event.which === 83) {
        event.preventDefault();
        this.onSaveAndContinueCreate();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", null);
  }

  handleSubmit = () => {
    const { submit } = this.props;
    submit();
  }

  onSave = () => {
    const { beforeSubmitConfirmation }  = this.props;
    if (beforeSubmitConfirmation != null) {
      const beforeSubmitConfirmationValue = beforeSubmitConfirmation();
      if (typeof beforeSubmitConfirmationValue === "object" && typeof beforeSubmitConfirmationValue.then === "function") {
        beforeSubmitConfirmationValue.then(() => {
          this.setState({
            shouldContinueCreate: false,
            shouldContinueEdit: false,
          },() => this.handleSubmit());
        });
      }
    } else {
      this.setState({
        shouldContinueCreate: false,
        shouldContinueEdit: false,
      },() => this.handleSubmit());
    }
  }

  onSaveAndContinueCreate = () => {
    this.setState({
      shouldContinueEdit: false,
      shouldContinueCreate: true,
    },() => this.handleSubmit());
  }

  onSaveAndContinueEdit = () => {
    this.setState({
      shouldContinueCreate: false,
      shouldContinueEdit: true,
    },() => this.handleSubmit());
  }

  render() {
    const { 
      title,
      form,
      rules,
      loading,
      children,
      fullWrapper,
      formComponent,
      createAction,
      requestName,
      extraButtons,
      beforeSubmit,
      beforeInitialValues,
      beforeSubmissionError,
      pageTitle,
      enableReinitialize,
      keepDirtyOnReinitialize,
      saveAndContinueKey,
      onCreateAndContinueCreateSuccess,
      customFields,
      entities,
      showHeader,
      onCreateSuccess,
      className,
      shouldDisabled,
      newSubmit,
      disabledMessage,
      shouldContinueCreate: shouldContinueCreateFromProp
    } = this.props;
    const { 
      shouldContinueCreate, 
      shouldContinueEdit
    } = this.state;
    pageTitle(`Create ${getBreadcrumbPageLabel(title)}`);
    const customFieldDataSource = denormalize(customFields, CustomFieldArraySchema, entities);
    return (
      <Fragment>
        <Loading
          show={loading}
          color="#FFFFFF"
          showSpinner={false}
        />
        {loading == false && (
          <Form
            showHeader={showHeader}
            fullWrapper={fullWrapper}
            form={form}
            newSubmit={newSubmit}
            rules={rules}
            title={title}
            className={className}
            onSave={this.onSave}
            requestName={requestName}
            extraButtons={extraButtons}
            createAction={createAction}
            beforeSubmit={beforeSubmit}
            formComponent={formComponent}
            shouldContinueCreate={shouldContinueCreateFromProp || shouldContinueCreate}
            shouldContinueEdit={shouldContinueEdit}
            onSaveAndContinueEdit={this.onSaveAndContinueEdit}
            onSaveAndContinueCreate={this.onSaveAndContinueCreate}
            beforeInitialValues={beforeInitialValues}
            beforeSubmissionError={beforeSubmissionError}
            onCreateAndContinueCreateSuccess={onCreateAndContinueCreateSuccess}
            enableReinitialize={enableReinitialize}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            saveAndContinueKey={saveAndContinueKey}
            onCreateSuccess={onCreateSuccess}
            customFieldDataSource={customFieldDataSource}
            shouldDisabled={shouldDisabled}
            disabledMessage={disabledMessage}
          > 
            {children}
          </Form>
        )}
      </Fragment>
    );
  }
}

CreateLayout.propTypes = {
  submit: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  createAction: PropTypes.func,
  formComponent: PropTypes.any,
  rules: PropTypes.any,
  requestName: PropTypes.string,
  form: PropTypes.string,
  className: PropTypes.string,
  fullWrapper: PropTypes.bool,
  beforeSubmit: PropTypes.func,
  extraButtons: PropTypes.any,
  beforeInitialValues: PropTypes.func,
  beforeSubmissionError: PropTypes.func,
  pageTitle: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  onCreateAndContinueCreateSuccess: PropTypes.func,
  keepDirtyOnReinitialize: PropTypes.bool,
  saveAndContinueKey: PropTypes.string,
  getCustomFieldByEntity: PropTypes.func,
  customFields: PropTypes.array,
  entities: PropTypes.object,
  showHeader: PropTypes.bool,
  onCreateSuccess: PropTypes.func,
  shouldContinueCreate: PropTypes.bool,
  shouldDisabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  newSubmit: PropTypes.func,
  beforeSubmitConfirmation: PropTypes.func,
};

CreateLayout.defaultProps = {
  showHeader: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  saveAndContinueKey: "edit",
};

const mapStateToProps = (state, ownProps) => {
  let { requestNames=[] } = ownProps;
  let loading = false;
  requestNames = requestNames.concat(CUSTOMER_FIELD_REQUEST);
  if (requestNames) {
    loading = requestNames.some(value => state.request[value]); 
  }
  return {
    loading,
    pageTitle: (page) => getPageTitle(state, page),
    entities: state.entities,
    customFields: state.customField.list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { form } = ownProps;
  return {
    submit: () => dispatch(ReduxFormSubmit(form)),
    getCustomFieldByEntity: (entityName) => dispatch(getCustomFieldByEntity(entityName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLayout);