export const SUPPLIER_REFUND_LIST_REQUEST = "SUPPLIER_REFUND_LIST_REQUEST";
export const SUPPLIER_REFUND_LIST_SUCCESS = "SUPPLIER_REFUND_LIST_REQUEST_SUCCESS";
export const SUPPLIER_REFUND_AUTOCOMPLETE_REQUEST = "SUPPLIER_REFUND_AUTOCOMPLETE_REQUEST";
export const SUPPLIER_REFUND_AUTOCOMPLETE_SUCCESS = "SUPPLIER_REFUND_AUTOCOMPLETE_SUCCESS";
export const SUPPLIER_REFUND_CREATE_REQUEST = "SUPPLIER_REFUND_CREATE_REQUEST";
export const SUPPLIER_REFUND_DETAIL_REQUEST = "SUPPLIER_REFUND_DETAIL_REQUEST";
export const SUPPLIER_REFUND_ARCHIVE_REQUEST = "SUPPLIER_REFUND_ARCHIVE_REQUEST";
export const SUPPLIER_REFUND_DELETE_REQUEST = "SUPPLIER_REFUND_DELETE_REQUEST";
export const SUPPLIER_REFUND_UNARCHIVE_REQUEST = "SUPPLIER_REFUND_UNARCHIVE_REQUEST";
export const SUPPLIER_REFUND_EDIT_REQUEST = "SUPPLIER_REFUND_EDIT_REQUEST";

export const SUPPLIER_REFUND_CREATE_FORM_NAME = "createSupplierRefund";
export const SUPPLIER_REFUND_EDIT_FORM_NAME = "editSupplierRefund";
export const SUPPLIER_REFUND_POST_FORM_NAME = "postRefund";

export const SUPPLIER_REFUND_HISTORY_LIST_REQUEST = "SUPPLIER_REFUND_LIST_REQUEST";
export const SUPPLIER_REFUND_HISTORY_LIST_SUCCESS = "SUPPLIER_REFUND_LIST_SUCCESS";

export const READ_SUPPLIER_REFUND = 240000;
export const CREATE_SUPPLIER_REFUND = 240001;
export const UPDATE_SUPPLIER_REFUND = 240002;
export const DELETE_SUPPLIER_REFUND = 240003;
export const ARCHIVE_SUPPLIER_REFUND = 240004;
export const UNARCHIVE_SUPPLIER_REFUND = 240005;
export const REPORT_SUPPLIER_REFUND = 240010;
export const POST_SUPPLIER_REFUND = 240011;
export const PRINT_SUPPLIER_REFUND = 240007;
export const ACKNOWLEDGE_SUPPLIER_REFUND = 240012;
export const UNACKNOWLEDGE_SUPPLIER_REFUND = 240013;

export const SUPPLIER_REFUND_POST_REQUEST = "SUPPLIER_REFUND_POST_REQUEST";

export const SUPPLIER_REFUND_STATUS_DRAFT = 1;
export const SUPPLIER_REFUND_STATUS_DRAFT_TITLE = "Draft";
export const SUPPLIER_REFUND_STATUS_POSTED = 2;
export const SUPPLIER_REFUND_STATUS_POSTED_TITLE = "Posted";

export const SUPPLIER_REFUND_STATUS = [
  {
    id: SUPPLIER_REFUND_STATUS_DRAFT,
    name: SUPPLIER_REFUND_STATUS_DRAFT_TITLE
  },
  {
    id: SUPPLIER_REFUND_STATUS_POSTED,
    name: SUPPLIER_REFUND_STATUS_POSTED_TITLE,
  },
];