import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FieldArray } from "redux-form";
import { Spin, Row, Col, Form, Card } from "antd";

// Component
import Header from "./header";
import Title from "@cores/components/title";
import { withReduxForm } from "@modules/cores/components/form";
import CustomFieldField from "@modules/settings/setup/custom-field/components/render-input";
import { renderCustomFieldValue } from "@modules/settings/setup/custom-field/helpers";

// Util
import { createSubmitAction } from "@/helpers";

function submit(values, dispatch, props) {
  const {
    editAction,
    beforeSubmit,
    beforeSubmissionError,
    newSubmit,
    customFieldDataSource,
    onEditSuccess,
    notRedirectPage,
  } = props;
  let { rules } = props;
  values.notRedirectPage = notRedirectPage;
  if (customFieldDataSource && customFieldDataSource.length > 0) {
    const subFields = [];
    customFieldDataSource.map((value, index) => {
      const { name, id, isRequired } = value;
      const customFieldSubmitRulesSubFields = {
        name: `${id}_${name}`,
        required: isRequired,
        inputKey: index
      };
      subFields.push(customFieldSubmitRulesSubFields);
    });
    const customFieldSubmitRules = {
      customFieldsArrayField: {
        type: "arrayInlineCustomField",
        subFields
      }
    };
    rules = Object.assign(rules, customFieldSubmitRules);
  }
  if (values.customFieldsArrayField && values.customFieldsArrayField.length > 0) {
    const newCustomFieldValues = [];
    values.customFieldsArrayField.map((value) => {
      let subValue = {};
      if (value) {
        const fieldKey = Object.keys(value).toString();
        const customFieldKey = fieldKey.split("_");
        const id = Number(customFieldKey[0]);
        subValue.id = id;
        subValue.customFieldValue = {
          value: value[fieldKey]
        };
      }
      newCustomFieldValues.push(subValue);
    });
    values.customFields = newCustomFieldValues;
  }
  if (newSubmit) {
    newSubmit(values, dispatch, props);
  } else {
    if (typeof rules === "function") {
      rules = rules(values);
    }
    return createSubmitAction(rules, editAction, () => {
      if (beforeSubmit) {
        return beforeSubmit(values);
      } else {
        return values;
      }
    }, true, () => {
      if (beforeSubmissionError) {
        beforeSubmissionError(values);
      }
    }
    )(values, dispatch).then((value) => {
      if (value != null) {
        if (onEditSuccess) {
          onEditSuccess(value);
        }
      }
    });
  }
}

class EditLayoutForm extends Component {

  render() {
    const {
      form,
      children,
      loading,
      spinTip,
      className,
      fullWrapper,
      showHeader,
      handleSubmit,
      objectEntities,
      customFieldDataSource,
      formComponent: FormComponent,
      ...props
    } = this.props;
    const isMatch = window.matchMedia("(max-width: 1440px)").matches;
    return (
      <Fragment>
        {showHeader && (
          <Header {...this.props} />
        )}
        <Row>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={(fullWrapper || isMatch) ? { span: 24, offset: 0 } : { span: 16, offset: 4 }}
          >
            <Spin tip={spinTip} spinning={loading}>
              <Card
                bordered={false}
                className={className}
                bodyStyle={{ padding: "16px" }}
              >
                {FormComponent && (
                  <Form onFinish={() => handleSubmit()} layout="vertical">
                    <FormComponent
                      {...props}
                      formName={form}
                      editable={true}
                      entity={objectEntities}
                    />
                    {customFieldDataSource.length > 0 && (
                      <Fragment>
                        <Title title="Additional info" />
                        <FieldArray
                          name="customFieldsArrayField"
                          component={CustomFieldField}
                          customFieldDataSource={customFieldDataSource}
                        />
                      </Fragment>
                    )}
                  </Form>
                )}
                {children}
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

EditLayoutForm.propTypes = {
  form: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  spinTip: PropTypes.string,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  formComponent: PropTypes.any,
  fullWrapper: PropTypes.bool,
  objectEntities: PropTypes.object,
  customFieldDataSource: PropTypes.array,
};

EditLayoutForm.defaultProps = {
  loading: false,
  showHeader: true,
  className: "m-3",
  spinTip: "Updating...",
};

const mapStateToProps = (state, ownProps) => {
  let { initialValues, customFieldDataSource } = ownProps;
  if (customFieldDataSource && customFieldDataSource.length > 0) {
    const customFieldsArrayField = [];
    customFieldDataSource.map((value) => {
      const { name, id, type, customFieldValue } = value;
      customFieldsArrayField.push({
        [`${id}_${name}`]: customFieldValue != null ? renderCustomFieldValue(customFieldValue.value, type) : null
      });
    });
    initialValues = Object.assign(initialValues, { customFieldsArrayField });
  }
  return {
    initialValues: {
      ...initialValues
    }
  };
};

const reduxForm = withReduxForm({
  onSubmit: submit
})(EditLayoutForm);

export default connect(mapStateToProps)(reduxForm);