// Libs
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Table, Badge } from "antd";

// Components
import PanelBox from "@cores/components/panel-box";
import DetailBox from "@cores/components/detail-box";
import AttachmentsBox from "@cores/components/attachment-box";
import { 
  toDate,
  getDayLabel,
  toShortDate,
  getBooleanString,
  renderDisplayTextFromTextArea, 
} from "@modules/helpers";

// helpers
import { 
  LEAVE_REQUEST_STATUS_APPROVED, 
  LEAVE_REQUEST_STATUS_SUBMITTED
} from "../../constants";
import { leaveRequestStatus, leaveRequestDurationType } from "../../helpers";

class LeaveRequestDetail extends Component {

  columns = (leaveRequestApprovals) => {
    const { currentUserPage } = this.props;
    return [
      {
        title: "Name",
        dataIndex: "employee",
        render: (employee) => {
          if (employee) {
            return (
              !currentUserPage ? (
                <Link to={`../../employee-directory/employee/${employee.id}`}>
                  {employee.firstName} {employee.lastName}
                </Link>
              ) : (
                <Fragment>
                  {employee.firstName} {employee.lastName}
                </Fragment>
              )
            );
          }
        }
      },
      {
        title: "Mandatory",
        dataIndex: "required",
        render: (required) => getBooleanString(required, true)
      },
      {
        title: "Approved",
        dataIndex: "approverId",
        render: (approverId, row) => {
          const { required } = row;
          const foundApprover = leaveRequestApprovals.find((value) => value.employeeLeaveApprover && value.employeeLeaveApprover.approverId == approverId);
          if (foundApprover) {
            return leaveRequestStatus(foundApprover.status);
          }
          if (required) {
            return <Badge status="processing" text="Waiting Approval" />;
          }
        }
      },
      {
        title: "Approved At",
        render: (_, row) => {
          const { approverId } = row;
          const foundApprover = leaveRequestApprovals.find((value) => value.employeeLeaveApprover && value.employeeLeaveApprover.approverId == approverId);
          if (foundApprover) {
            return toDate(foundApprover.createdAt);
          }
        }
      },
    ];
  }

  render() {
    const {
      entity: { 
        leaveType,
        employee,
        employee: {
          employeeLeaveSetting,
        },
        totalDays,
        requestStatus,
        reason,
        startDate,
        isPartialLeave,
        fromDurationType,
        toDurationType,
        endDate,
        documentUrl,
        approveDate,
        leaveRequestApprovals,
      },
      currentUserPage,
    } = this.props;
    return (
      <Fragment>
        <PanelBox title="General Info" hasBorder={employeeLeaveSetting != null}>
          <Row>
            {!currentUserPage && (
              <Col md="6" xs="12">
                <DetailBox title="Employee">
                  {employee && (
                    <Link to={`../../employee-directory/employee/${employee.id}`}>
                      {employee.firstName} {employee.lastName}
                    </Link>
                  )}
                </DetailBox>
              </Col>
            )}
            {currentUserPage ? (
              <Col md="6" xs="12">
                <DetailBox title="Leave Type">
                  {leaveType && (
                    <Fragment>
                      {leaveType.name}
                    </Fragment>
                  )}
                </DetailBox>
              </Col>
            ) : (
              <Col md="6" xs="12">
                <DetailBox title="Leave Type">
                  {leaveType && (
                    <Link to={`../leave-type/${leaveType.id}`}>
                      {leaveType.name}
                    </Link>
                  )}
                </DetailBox>
              </Col>
            )}
            <Col md="6" xs="12">
              <DetailBox 
                title="Start Date" 
                data={toShortDate(startDate)} 
              />
            </Col>
            {isPartialLeave &&
              <Col md="6" xs="12">
                <DetailBox 
                  title="Duration Type" 
                  data={leaveRequestDurationType(fromDurationType)} 
                />
              </Col>
            }
            <Col md="6" xs="12">
              <DetailBox 
                title="End Date" 
                data={toShortDate(endDate)} 
              />
            </Col>
            {isPartialLeave &&
              <Col md="6" xs="12">
                <DetailBox 
                  title="Duration Type" 
                  data={leaveRequestDurationType(toDurationType)} 
                />
              </Col>
            }
            <Col md="6" xs="12">
              <DetailBox 
                title="Duration" 
                data={getDayLabel(totalDays)}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox 
                title="Is Partial Leave" 
                data={getBooleanString(isPartialLeave, true)} 
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Status"
                data={leaveRequestStatus(requestStatus)}
              />
            </Col>
            {approveDate != null && requestStatus == LEAVE_REQUEST_STATUS_APPROVED && (
              <Fragment>
                <Col md="6" xs="12">
                  <DetailBox
                    title="Approve Date"
                    data={toShortDate(approveDate)}
                  />
                </Col>
              </Fragment>
            )}
            <Col md="12" xs="12">
              <DetailBox
                leftCol="3"
                rightCol="9"
                title="Reason"
                data={renderDisplayTextFromTextArea(reason)}
              />
            </Col>
          </Row>
        </PanelBox>
        {employeeLeaveSetting && (requestStatus == LEAVE_REQUEST_STATUS_SUBMITTED || requestStatus == LEAVE_REQUEST_STATUS_APPROVED) && (
          <PanelBox title="Approves" hasBorder={documentUrl != null}>
            <Row>
              <Col md="12" xs="12">
                <Table 
                  size="small"
                  pagination={false}
                  dataSource={employeeLeaveSetting.employeeLeaveApprovers} 
                  columns={this.columns(leaveRequestApprovals)}
                />
              </Col>
            </Row>
          </PanelBox>
        )}
        {documentUrl && (
          <PanelBox title="Attachment">
            <Row>
              <Col md="12" xs="12" className="d-flex flex-row">
                <AttachmentsBox 
                  title="Document" 
                  url={documentUrl} 
                />
              </Col>
            </Row>
          </PanelBox>
        )}
      </Fragment>
    );
  }
}

LeaveRequestDetail.propTypes = {
  entity: PropTypes.object,
  currentUserPage: PropTypes.bool,
};

export default LeaveRequestDetail;