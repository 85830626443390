export const SALARY_STRUCTURE_LIST_REQUEST = "SALARY_STRUCTURE_LIST_REQUEST"; 
export const SALARY_STRUCTURE_LIST_SUCCESS = "SALARY_STRUCTURE_LIST_REQUEST_SUCCESS";
export const SALARY_STRUCTURE_CREATE_REQUEST = "SALARY_STRUCTURE_CREATE_REQUEST"; 
export const SALARY_STRUCTURE_DETAIL_REQUEST = "SALARY_STRUCTURE_DETAIL_REQUEST"; 
export const SALARY_STRUCTURE_ARCHIVE_REQUEST = "SALARY_STRUCTURE_ARCHIVE_REQUEST"; 
export const SALARY_STRUCTURE_DELETE_REQUEST = "SALARY_STRUCTURE_DELETE_REQUEST"; 
export const SALARY_STRUCTURE_UNARCHIVE_REQUEST = "SALARY_STRUCTURE_UNARCHIVE_REQUEST"; 
export const SALARY_STRUCTURE_EDIT_REQUEST = "SALARY_STRUCTURE_EDIT_REQUEST"; 
export const SALARY_STRUCTURE_CREATE_FORM_NAME = "createSalaryStructure"; 
export const SALARY_STRUCTURE_EDIT_FORM_NAME = "editSalaryStructure"; 
export const SALARY_STRUCTURE_AUTOCOMPLETE_REQUEST = "SALARY_STRUCTURE_AUTOCOMPLETE_REQUEST";
export const SALARY_STRUCTURE_AUTOCOMPLETE_SUCCESS = "SALARY_STRUCTURE_AUTOCOMPLETE_SUCCESS";
export const SALARY_STRUCTURE_SUBMIT_RULES = { 
  name: {
    required: true,
  },
};
export const READ_SALARY_STRUCTURE = 174000;
export const CREATE_SALARY_STRUCTURE = 174001;
export const UPDATE_SALARY_STRUCTURE = 174002;
export const DELETE_SALARY_STRUCTURE = 174003;
export const ARCHIVE_SALARY_STRUCTURE = 174004;
export const UNARCHIVE_SALARY_STRUCTURE = 174005;

