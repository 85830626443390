export const warehouseSubmitRules = (values) => {
  const { 
    stockInApproval, 
    stockOutApproval, 
    stockTransferApproval,
    stockAdjustmentApproval,
    isRequiredChartAccount,
  } = values;
  const rules = {
    name: {
      required: true,
    },
    code: {
      required: true,
    },
    managerId: {
      required: stockInApproval || stockOutApproval || stockAdjustmentApproval || stockTransferApproval
    },
    chartAccountId: {
      required: isRequiredChartAccount,
    },
  };
  return rules;
};

