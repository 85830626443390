import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router";
import { connect } from "react-redux";
import { 
  isDirty, 
  hasSubmitSucceeded, 
  isSubmitting,
} from "redux-form";

class WarnAboutUnsavedChanges extends React.Component {

  componentDidUpdate() {
    const { 
      isFormDirty, 
      isSubmittedSucceeded, 
      leaveMessage, 
    } = this.props;
    this.promptUnsavedChange(isFormDirty, isSubmittedSucceeded, leaveMessage);
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  promptUnsavedChange(isUnsaved = false, submitted = false, leaveMessage) {
    window.onbeforeunload = isUnsaved && submitted == false && (() => leaveMessage);
  }

  render() {
    const { 
      children, 
      isFormDirty, 
      isSubmittedSucceeded, 
      leaveMessage, 
      isFormSubmitting,
    } = this.props;
    return (
      <Fragment>
        {children}
        <Prompt
          when={isFormDirty && !isSubmittedSucceeded && isFormSubmitting == false}
          message={leaveMessage}
        />
      </Fragment>
    );
  }
}

WarnAboutUnsavedChanges.propTypes = {
  isFormDirty: PropTypes.bool,
  isSubmittedSucceeded: PropTypes.bool,
  isFormSubmitting: PropTypes.bool,
  leaveMessage: PropTypes.string.isRequired,
  children: PropTypes.any,
};

WarnAboutUnsavedChanges.defaultProps = {
  leaveMessage: "Are you want you want to leave this page unsaved ?",
  validateQuery: true,
};

const withWarnUnsavedForm = ({ formName, leaveMessage }) => (WrappedComponent) => {

  const mapStateToProps = (state, ownProps) => {
    const formName = ownProps.formName || ownProps.children.props.form;
    return {
      isFormDirty: isDirty(formName)(state),
      isSubmittedSucceeded: hasSubmitSucceeded(formName)(state),
      isFormSubmitting: isSubmitting(formName)(state),
    };
  };
  
  const ConnectedWarnAboutUnsavedChanges = connect(mapStateToProps)(WarnAboutUnsavedChanges);

  const MainComponent = (props) => {
    return (
      <ConnectedWarnAboutUnsavedChanges 
        formName={formName} 
        leaveMessage={leaveMessage}
        {...props}
      >
        <WrappedComponent {...props} />
      </ConnectedWarnAboutUnsavedChanges>
    );
  };

  return withRouter(MainComponent);
};

export default withWarnUnsavedForm;