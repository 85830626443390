// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as _ from "lodash";
import Media from "react-media";
import { Form, DatePicker } from "antd";

// Utils
import {
  toQueryParamDate,
  getQueryParamUrl,
  updateQueryParamUrl,
  convertFieldToDisplay,
  updateQueryParamToLocalStorage,
  getQueryParamFromLocalStorage,
} from "@modules/helpers";
import * as Constants from "@/constants/default-constants";

// Component
import AsyncRangePicker from "../async-range-picker";

const FormItem = Form.Item;

const { RangePicker } = DatePicker;

class AsyncDateRangePicker extends Component {

  onChange = (value) => {
    const {
      fromName,
      toName,
      onChange,
      queryStorage,
    } = this.props;
    const from = value[0];
    const to = value[1];
    let query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const page = query.page;
    if (page && page > 1) {
      delete query.page;
    }
    query[fromName] = toQueryParamDate(from);
    query[toName] = toQueryParamDate(to);
    if (_.isEmpty(value)) {
      delete query[fromName];
      delete query[toName];
    }
    if (queryStorage == "url") {
      updateQueryParamUrl(query);
    } else {
      updateQueryParamToLocalStorage(query);
      this.forceUpdate();
    }
    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const {
      fromName,
      toName,
      required,
      showToday,
      typeOfRange,
      queryStorage,
    } = this.props;
    const defaultValue = [
      moment().startOf(typeOfRange),
      moment().endOf(typeOfRange),
    ];
    const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const fromValue = query[fromName];
    const toValue = query[toName];
    const placeholder = [
      convertFieldToDisplay(fromName),
      convertFieldToDisplay(toName),
    ];
    let value = [
      moment(fromValue, Constants.DEFAULT_ASYNC_DATE_FORMAT),
      moment(toValue, Constants.DEFAULT_ASYNC_DATE_FORMAT)
    ];
    if (fromValue == undefined && toValue == undefined) {
      value = null;
      if (showToday) {
        value = defaultValue;
      }
    }
    return (
      <FormItem>
        <RangePicker
          value={value}
          allowClear={required}
          onChange={this.onChange}
          defaultValue={defaultValue}
          className="ant-date-range-picker"
          placeholder={placeholder}
          format={Constants.DEFAULT_DISPLAY_DATE_FORMAT}
        />
      </FormItem>
    );
  }
}

AsyncDateRangePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool,
  fromName: PropTypes.string,
  toName: PropTypes.string,
  showToday: PropTypes.bool,
  typeOfRange: PropTypes.string,
  placeholder: PropTypes.string,
  queryStorage: PropTypes.string,
};

AsyncDateRangePicker.defaultProps = {
  required: true,
  showToday: false,
  fromName: "from",    
  toName: "to",
  typeOfRange: "month",
};

class DateRangePicker extends Component {

  render() {
    const { ...props } = this.props;
    return (
      <Media query={{maxWidth: 1024}}>
        {matches => matches ? (
          <AsyncRangePicker {...props} />
        ) : (
          <AsyncDateRangePicker {...props} />
        )}
      </Media>
    );
  }
}

export default DateRangePicker;