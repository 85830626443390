// Libs
import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Tabs, Button, message } from "antd";   
import { formValueSelector, change } from "redux-form";
import { SaveOutlined } from "@ant-design/icons";

// Components
import Input from "@cores/components/Input";
import Upload from "@cores/components/upload-image";


import TitleAutoComplete from "@cores/components/auto-complete/title";
import GeneralInfo from "./general-info";
import Contact from "./contact";
import Bio from "./bio";

// helpers
import * as services from "../../services"; 
import { renderGender } from "@modules/helpers";
import { getConfiguration } from "@modules/utils/value-selector";
import { EMPLOYEE_CODE_TYPE_MANUALLY_ID } from "../../constants";
import { EMPLOYEE_SETTING } from "@/constants/configuration";

const { TabPane } = Tabs;
class FormComponent extends Component {

  onChange = (value) => {
    const { update } = this.props;
    const gender = renderGender(value);
    update(gender);
  }

  onSaveAndContinueEditButton = () => {
    const { onSaveAndContinueEdit } = this.props;
    return (
      <Fragment>
        <p>In order to proceed, employee must be saved first.</p>
        <Button
          ghost
          type="primary"
          icon={<SaveOutlined />}
          onClick={onSaveAndContinueEdit}
        >
          Save &amp; Continue Edit
        </Button>
      </Fragment>
    );
  }

  onChangeCodeChange = (value)=> {
    this.debouncedSearch(value);
  };

  debouncedSearch = _.debounce((value) => {
    this.checkEmployeeCode(value);
  }, 1000);

  checkEmployeeCode = (value) => {
    if (value) {
      services
        .checkEmployeeCode({code: value})
        .then((response) => {
          return response;
        }).catch((error) => {
          message.error(error.message);
        });
    }
  }

  render() {
    const { 
      editable, 
      isAutoComplete,
      employeeCodeNoSeriesList,
    } = this.props;
    const positionIds = [];
    const departmentIds = [];
    const userAccountIds = [];
    if (editable) {
      const { 
        entity: { 
          position, 
          department, 
          user,
        } 
      } = this.props;
      positionIds.push(position && position.id);
      departmentIds.push(department && department.id);
      userAccountIds.push(user && user.id);
    }
    return (
      <React.Fragment>
        <Row>
          <Col
            xl="2" lg="3" md="4" xs="12"
            className="pr-md-0 mb-3 mb-md-2"
          >
            <Upload 
              name="profileImageUrl"
              path="hr/employee/profile"
              withWebcam
            />
          </Col>
          <Col xl="10" lg="9" md="8" xs="12">
            <Row>
              <Col md="12" xs="12">
                <TitleAutoComplete
                  autoFocus 
                  onChange={this.onChange} 
                />    
              </Col> 
              <Col md="6" xs="12">
                <Input
                  label="First Name"
                  name="firstName"
                  required        
                />
              </Col>
              <Col md="6" xs="12">
                <Input 
                  label="Last Name"
                  name="lastName"
                  required        
                />
              </Col>
              <Col md="6" xs="12">
                <Input    
                  label="Email Address"
                  name="emailAddress"
                />
              </Col>
              {employeeCodeNoSeriesList == EMPLOYEE_CODE_TYPE_MANUALLY_ID && (
                <Col xl="6" lg="6" md="6" sm="12" xs="12">
                  <Input
                    required
                    name="code"
                    label="Code"
                    handleChange={this.onChangeCodeChange}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col xs="12" className="mt-4">
            <Tabs>
              <TabPane tab="General Info" key="1">
                <GeneralInfo {...this.props} />
              </TabPane>
              {!isAutoComplete && (
                <TabPane tab="Bio" key="2">
                  <Bio 
                    onSaveAndContinueEditComponent={this.onSaveAndContinueEditButton}
                    {...this.props} 
                  />
                </TabPane>
              )}
              {!isAutoComplete && (
                <TabPane tab="Contact" key="3">
                  <Contact 
                    onSaveAndContinueEditComponent={this.onSaveAndContinueEditButton}
                    {...this.props}
                  />
                </TabPane>
              )}  
            </Tabs>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

FormComponent.propTypes ={
  update: PropTypes.func,
  openModalEdit: PropTypes.func,
  entity: PropTypes.object,
  editable: PropTypes.bool,
  formName: PropTypes.string,
  isAutoComplete: PropTypes.bool,
  openModalCreate: PropTypes.func,
  isInventoryInstalled: PropTypes.bool,
  onSaveAndContinueEdit: PropTypes.func,
  employeeCodeNoSeriesList: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const selector = formValueSelector(formName);
  return {
    isInventoryInstalled: selector(state, "isInventoryInstalled"),
    employeeCodeNoSeriesList: getConfiguration(state, EMPLOYEE_SETTING, "employeeCodeNoSeriesList"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    update: (value) => dispatch(change(formName, "gender", value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);  