import React, { Component , Fragment } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";

// Component
import Input from "@cores/components/Input";
import Title from "@cores/components/title";
import TimePicker from "@cores/components/time-picker";
import CheckBox from "@cores/components/check-box";
import Upload from "@cores/components/upload-image";
import Select from "@cores/components/autocomplete-static";
import RoleAutoComplete from "@cores/components/auto-complete/role";

// Constants
import { USER_ACCOUNT_STATUS } from "../../constants";

class FormComponent extends Component {

  handleChange = (value) => {
    const { updateField, useEmailAsLoginId } = this.props;
    if (useEmailAsLoginId) {
      updateField(value);
    }
  }

  onCheck = (checked) => {
    const { updateField, email, editable } = this.props;
    if (checked) {
      updateField(email);
    } else {
      if (editable) {
        const { entity: { clientId } } = this.props;
        updateField(clientId);
      } else {
        updateField(null);
      }
    }
  }

  render() {
    const { 
      email,
      entity,
      editable, 
      useEmailAsLoginId, 
      isGeneratePassword, 
    } = this.props;
    let roleIds = [];
    if (editable) {
      const { entity: { roles } } = this.props;
      if (roles) {
        roleIds = roles.map((value) => value.id);
      }
    }
    const disabled = entity && entity.employee != null;
    return (
      <Fragment>
        <Row>
          <Col
            xl="2" lg="3" md="4" xs="12"
            className="pr-md-0 mb-3 mb-md-2"
          >
            <Upload 
              name="profileImageUrl"
              path="/hr/user/profile"
              disabled={disabled}
              withWebcam
            />
          </Col>
          <Col xl="10" lg="9" md="8" xs="12">
            <Row>
              <Col md="12" xs="12">
                <Input
                  autoFocus
                  required        
                  name="firstName"
                  label="First Name"
                  disabled={disabled}
                />
                <Input
                  required        
                  name="lastName"
                  label="Last Name"
                  disabled={disabled}
                />
                <Input 
                  label="Email Address"
                  name="emailAddress"
                  handleChange={this.handleChange}
                  disabled={disabled}     
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" style={{marginTop: "40px"}}>
            <Input 
              required        
              label="Login ID"
              name="clientId"
              disabled={useEmailAsLoginId}
            />
          </Col>
          <Col xs="12">
            <CheckBox 
              label="Use email as login ID" 
              name="useEmailAsLoginId"
              handleChange={this.onCheck}
              disabled={email == null}
            />
          </Col>
          {editable && (
            <Col xs="12">
              <Select 
                name="status"
                label="Status"
                dataSource={USER_ACCOUNT_STATUS}
              />
            </Col>
          )}
          {!editable && (
            <Col xs="12">
              <CheckBox
                name="isGeneratePassword"
                label="Choose random password and send to email"
                disabled={email == null}
              />
            </Col>
          )}
          {!isGeneratePassword && !editable && ( 
            <Fragment>
              <Col md="6" xs="12">
                <Input 
                  required        
                  label="Password"
                  name="password"
                  type="Password"
                  autoComplete="new-password"
                />
              </Col>
              <Col md="6" xs="12">
                <Input 
                  required        
                  type="Password"
                  label="Confirm Password"
                  name="confirmPassword"
                  autoComplete="new-password"
                />
              </Col>
            </Fragment>
          )}
          <Col xs="12">
            <Title title="Restict" />
          </Col>
          <Col md="6" xs="12">
            <TimePicker 
              label="Login After" 
              name="loginAfter" 
            />
          </Col>
          <Col md="6" xs="12">
            <TimePicker 
              label="Login Before" 
              name="loginBefore" 
            />
          </Col>
          <Col xs="12">
            <CheckBox
              name="requireLoginApproval"
              label="Require Login Approval"
            />
          </Col>
          <Col xs="12">
            <RoleAutoComplete 
              required={false} 
              ids={roleIds}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

FormComponent.propTypes = {
  email: PropTypes.string,
  editable: PropTypes.bool,
  entity: PropTypes.object,
  updateField: PropTypes.func,
  clientId: PropTypes.string,
  useEmailAsLoginId: PropTypes.bool,
  isGeneratePassword: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const selector = formValueSelector(formName);
  return {
    email: selector(state, "emailAddress"),
    clientId: selector(state, "clientId"),
    useEmailAsLoginId: selector(state, "useEmailAsLoginId"),
    isGeneratePassword: selector(state, "isGeneratePassword")
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    updateField: (value) => dispatch(change(formName, "clientId", value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);  