import {
  GETAutoComplete,
  GETDetail,
  GETList,
  POSTWithToken,
  PUTWithToken,
  DELETEWithToken,
  GETWithToken,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/stock-out/v1";
const ITEM_SERIAL = "/api/item-serial/v1";

export function getStockOut(values) {
  const url = `${API_URL}/list`;
  values = getDefaultCurrentDateRange(values);
  values.filter.includeSaleOrder = true;
  return GETList(url, values);
}

export function getStockOutAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getStockOutDetail(stockOutNo) {
  const url = `${API_URL}/no`;
  return GETDetail(url, stockOutNo);
}

export function deleteStockOut(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function archiveStockOut(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function submitStockOut(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function approveStockOut(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectStockOut(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function cancelStockOut(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function stockPushBack(id) {
  const url = `${API_URL}/${id}/push-back`;
  return PUTWithToken(url);
}

export function checkSerial(serial) {
  const url = `${ITEM_SERIAL}/${serial}`;
  return GETWithToken(url);
}

export function processStockOut(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/process`;
  return PUTWithToken(url, values);
}

export function convertToStockOut(values) {
  const { saleOrderId } = values;
  const url = `${API_URL}/create/${saleOrderId}`;
  return POSTWithToken(url, values);
}

export function unarchiveStockOut(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}