import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Constants
import { getDefaultCurrencyValue } from "@modules/utils/value-selector";

class CurrencyBox extends Component {

  render() {
    let { renderCurrencyValue, value, nullText } = this.props;
    return (
      <Fragment>
        {nullText && value == null ? nullText : renderCurrencyValue(value)}
      </Fragment>
    );
  }
}

CurrencyBox.propTypes = {
  value: PropTypes.any,
  currencyConfiguration: PropTypes.object,
  renderCurrencyValue: PropTypes.func,
  nullText: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { currency, isAccounting: isAccountingProp, isDanger=true, showSymbol=true } = ownProps;
  let isAccounting = false;
  if (isAccountingProp) {
    isAccounting = isAccountingProp;
  }
  if (currency) {
    return {
      renderCurrencyValue: (value) =>  getDefaultCurrencyValue(state, value, isAccounting, currency, isDanger, showSymbol),
    };
  }
  return {
    renderCurrencyValue: (value) =>  getDefaultCurrencyValue(state, value, isAccounting, null, isDanger, showSymbol),
  };
};

export default connect(mapStateToProps)(CurrencyBox);