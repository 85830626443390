import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import PropTypes from "prop-types";
import { HomeOutlined, ReloadOutlined } from "@ant-design/icons";

class PageNotFound extends React.Component {

  handleReload = () => {
    location.reload();
  }
  
  render() {
    const { status, name, backTo, fullHeight } = this.props;
    return (      
      <Fragment>  
        <Result
          className={fullHeight ? "m-3" : "p-3 p-lg-0"}
          status={`${status}`}
          title={
            <Fragment>
              <div>
                <h3>{status}</h3>
              </div>
              {status == 404 ? `The ${name} you were looking for doesn't exist` : "Something went wrong !"}
            </Fragment>
          }
          extra={
            status == 404 ? (
              <Link to={backTo}>
                <Button 
                  ghost
                  icon={<HomeOutlined />}
                  type="primary"
                >
                  Back To {name} List
                </Button>
              </Link>
            ) : (
              <Button
                ghost
                type="primary"
                icon={<ReloadOutlined />}
                onClick={this.handleReload}
              >
                Retry
              </Button>
            )}
        />
      </Fragment>
    );
  }
}

PageNotFound.propTypes = {
  name: PropTypes.string,
  backTo: PropTypes.string,
  status: PropTypes.number,
  fullHeight: PropTypes.bool,
};

PageNotFound.defaultProps = {
  fullHeight: true
};

export default PageNotFound;