export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST"; 
export const BRAND_LIST_SUCCESS = "BRAND_LIST_REQUEST_SUCCESS";
export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST"; 
export const BRAND_DETAIL_REQUEST = "BRAND_DETAIL_REQUEST"; 
export const BRAND_ARCHIVE_REQUEST = "BRAND_ARCHIVE_REQUEST"; 
export const BRAND_DELETE_REQUEST = "BRAND_DELETE_REQUEST"; 
export const BRAND_UNARCHIVE_REQUEST = "BRAND_UNARCHIVE_REQUEST"; 
export const BRAND_EDIT_REQUEST = "BRAND_EDIT_REQUEST"; 
export const BRAND_CREATE_FORM_NAME = "createBrand"; 
export const BRAND_EDIT_FORM_NAME = "editBrand"; 
export const BRAND_AUTOCOMPLETE_REQUEST = "BRAND_AUTOCOMPLETE_REQUEST";
export const BRAND_AUTOCOMPLETE_SUCCESS = "BRAND_AUTOCOMPLETE_SUCCESS";
export const BRAND_SUBMIT_RULES = { 
  name: {
    required: true,
  },
};
export const READ_BRAND = 200000;
export const CREATE_BRAND = 200001;
export const UPDATE_BRAND = 200002;
export const DELETE_BRAND = 200003;
export const ARCHIVE_BRAND = 200004;
export const UNARCHIVE_BRAND = 200005;