export const PAYROLL_SETTING_PATH = "/human-resource/payroll/payroll-setting";
export const PAYROLL_SETTING_SUBMIT_RULES = {
  cashPaymentMethod: {
    type: "object",
    value: {
      required: true
    }
  },
  bankDepositPaymentMethod: {
    type: "object",
    value: {
      required: true
    }
  },
  payrollChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
};

export const READ_PAYROLL_SETTING_CODE = 173011;
export const CREATE_OR_UPDATE_PAYROLL_SETTING_CODE = 173012;