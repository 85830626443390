import { Modal } from "antd";
import * as _ from "lodash";
import * as QueryString from "query-string";
import axios from "axios";
import { getAccessToken } from "../shared/utils";
import * as BaseServiceConstants from "../constants/base-service";
import { NETWORK_ERROR_CODE } from "../constants/error";

function sendRequest(method, url, data, headers, extra) {
  var defaultHeaders = {
    "Content-Type": "application/json"
  };
  defaultHeaders = _.merge(headers, defaultHeaders);
  if (extra == null) {
    extra = {};
  }
  const requestOptions = {
    method,
    url,
    headers: defaultHeaders,
    data,
    ...extra
  };
  return axios(requestOptions).then(response => response.data).catch(err => {
    if (err.response == null) {
      err.errorCode = NETWORK_ERROR_CODE;
      throw err;
    } else {
      throw err.response.data;
    }
  });
}

export function sendRequestWithRawResponse(method, url, data, headers, extra) {
  var defaultHeaders = {
    "Content-Type": "application/json"
  };
  defaultHeaders = _.merge(headers, defaultHeaders);
  if (extra == null) {
    extra = {};
  }
  const requestOptions = {
    method,
    url,
    headers: defaultHeaders,
    data,
    ...extra
  };
  return axios(requestOptions).then(response => response).catch(err => {
    if (err.response == null) {
      err.errorCode = NETWORK_ERROR_CODE;
      throw err;
    } else {
      throw err.response.data;
    }
  });
}

function handleNoAccessToken() {
  return new Promise((resolve) => { 
    Modal.warning({
      title: "Invalid token or your account has been logged out.",
      content: "Please re-login to continue using this system!",
      width: "500px",
      keyboard: false,
      maskClosable: false,
      onOk() {
        window.location.href = "/login";
        return resolve();
      },
    });
  });
}

export function GET(url, data, headers, extra) {
  if (data) {
    const query = QueryString.stringify(data, { arrayFormat: "bracket" });
    if (url.indexOf("?") != -1) {
      url = `${url}&${query}`;
    } else {
      url = `${url}?${query}`;
    }
  }
  return sendRequest("GET", url, null, headers, extra);
}

export function GETWithRawResponse(url, data, headers, extra) {
  if (data) {
    const query = QueryString.stringify(data, { arrayFormat: "bracket" });
    if (url && url.indexOf("?") != -1) {
      url = `${url}&${query}`;
    } else {
      url = `${url}?${query}`;
    }
  }
  return sendRequestWithRawResponse("GET", url, null, headers, extra);
}

export function POST(url, data, headers, extra) {
  return sendRequest("POST", url, data, headers, extra);
}

export function PUT(url, data, headers) {
  return sendRequest("PUT", url, data, headers);
}

export function DELETE(url, data, headers) {
  return sendRequest("DELETE", url, data, headers);
}

export function GETList(url, values) {
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  let query = {};
  if (values) {
    const { 
      sort, 
      filter, 
      page = 0, 
      limit 
    } = values;
    const numberPerPage = limit;
    query = { ...sort, ...filter }; 
    if (limit != null) {
      query["offset"] = page * numberPerPage; 
      query["limit"] = numberPerPage;
    }
  }
  return GET(url, query, headers);
}

export function GETWithToken(url, query) {
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  return GET(url, query, headers);
}

export function GETDetail(path, id, extra) {
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  const url = `${path}/${id}`;
  return GET(url, extra, headers);
}

export function GETAutoComplete(url, query, rest={}) {
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  const page = 0;
  const numberPerPage = BaseServiceConstants.AUTO_COMPLETE_NUMBER_PER_PAGE;
  let params = {
    offset: page * numberPerPage,
    limit: numberPerPage,
  };
  if (query && typeof(query) == "object") {
    const { value, ...rest } = query;
    params.query = value;
    params = Object.assign({}, params, rest);
  } else {
    params.query = query;
  }
  params = Object.assign({}, params, rest);
  if (params.requestName) {
    delete params.requestName;
  }
  return GET(url, params, headers);
}

export function POSTWithToken(path, values) {
  const token = getAccessToken();
  if (token) {
    const headers = {
      Authorization: `Bearer ${token.accessToken}`
    };
    return POST(path, values, headers);
  }
  return handleNoAccessToken();
}

export function PUTWithToken(url, data) {
  const token = getAccessToken();
  if (token) {
    const headers = {
      Authorization: `Bearer ${token.accessToken}`
    };
    return PUT(url, data, headers);
  }
  return handleNoAccessToken();
}

export function DELETEWithToken(url) {
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  return DELETE(url, null, headers);
}

export function BATCHDELETEWithToken(url, data) {
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  return DELETE(url, data, headers);
}