import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import styled from "styled-components";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Card, Table, Spin, Dropdown, Menu, Button, message, Empty } from "antd";
import { LoadingOutlined, DownOutlined, PrinterOutlined, FilePdfOutlined } from "@ant-design/icons";

// Components
import Counter from "./counter";
import FilterBoxComponent from "@modules/cores/components/filter-box";

// Helpers
import {
  getSortKeys,
  getParamLimit,
  getPageNumber,
  getParamFilter,
  getQueryParamUrl,
  updateQueryParamUrl,
  updateQueryParamToLocalStorage,
} from "@modules/helpers";
import * as services from "@/services/print-format";
import {
  OPTION_TYPE_CUSTOM,
  renderDateRangeOption,
  OPTION_TYPE_ALL,
} from "@cores/components/auto-complete/date-range-option/constants";
import { DEFAULT_DISPLAY_DATE_TIME_FORMAT } from "@/constants/default-constants";
import { getPermissionAllowed } from "@modules/utils/value-selector";

import "./list.less";

const WrapperCard = styled(Card)`
  .ant-card-head-title {
    padding: 0;
  }
`;

class ListLayout extends React.Component {

  state = {
    lastUpdated: moment().format(DEFAULT_DISPLAY_DATE_TIME_FORMAT)
  }

  componentDidMount() {
    const { getReload, extraFilterOnFirstLoad, requestName } = this.props;
    localStorage.setItem("FILTER_ACTIVE", requestName);
    let query = getQueryParamUrl();
    localStorage.setItem(requestName, JSON.stringify(query));
    const isNull = localStorage.getItem("null");
    if (isNull) {
      localStorage.removeItem("null");
    }
    const newQuery = this.onGetFilterQuery(this.props);
    this.loadData(this.props, extraFilterOnFirstLoad, newQuery);
    if (getReload) {
      getReload(this.handleReload);
    }
  }

  onGetFilterQuery = (props) => {
    const { filterDataSource } = props;
    let query = getQueryParamUrl();
    if (query.view == null) {
      if (filterDataSource.length > 0) {
        const matched = filterDataSource.find((value) => value.isDefault == true);
        if (matched && matched.filters) {
          for (const key in matched.filters) {
            query[key] = matched.filters[key].value;
            if (key == "dateType") {
              if (matched.filters[key] && matched.filters[key].value == OPTION_TYPE_CUSTOM) {
                query.from = matched.filters[key].from;
                query.to = matched.filters[key].to;
              } else {
                if (matched.filters[key].value == OPTION_TYPE_ALL) {
                  query.dateType = OPTION_TYPE_ALL;
                  delete query.from;
                  delete query.to;
                } else {
                  const { from, to } = renderDateRangeOption(matched.filters[key].value);
                  query.from = from;
                  query.to = to;
                }
              }
            }
          }
          query.view = matched.slug;
          updateQueryParamUrl(query);
          updateQueryParamToLocalStorage(query);
        }
      }
      return query;
    } else {
      return {};
    }
  }

  componentWillUnmount() {
    const { requestName } = this.props;
    const iframes = document.getElementById("iframe-print");
    localStorage.removeItem("FILTER_ACTIVE");
    localStorage.removeItem(requestName);
    if (iframes) {
      document.body.removeChild(iframes);
    }
  }

  UNSAFE_componentWillReceiveProps(nexProps) {
    if (nexProps.location != this.props.location) {
      this.loadData(nexProps);
    }
  }

  loadData = (props, extraFilterOnFirstLoad, extraFilters) => {
    const { filterKeys, location, loadData, extraValues, extraFilter, requestName } = props;
    const page = getPageNumber(props);
    let filter = getParamFilter(props, filterKeys);
    const sort = getSortKeys(location);
    const limit = getParamLimit(props);
    if (extraFilter) {
      filter = extraFilter(filter);
    }
    if (extraFilterOnFirstLoad) {
      filter = Object.assign(filter, extraFilterOnFirstLoad);
    }
    if (extraFilters) {
      filter = Object.assign(filter, extraFilters);
    }
    let values = {
      page,
      limit,
      sort,
      filter,
      requestName,
    };
    if (extraValues) {
      values = Object.assign(values, extraValues);
    }
    loadData(values);
    this.setState({
      lastUpdated: moment().format(DEFAULT_DISPLAY_DATE_TIME_FORMAT)
    });
  }

  onChange = (pagination, filters, sorter) => {
    let query = getQueryParamUrl();
    let order = sorter.order;
    if (sorter.order == "ascend") {
      order = "ASC";
    }
    if (sorter.order == "descend") {
      order = "DESC";
    }
    if (sorter.order) {
      query["order"] = order;
      query["sortBy"] = sorter.field;
    } else {
      delete query.order;
      delete query.sortBy;
    }
    updateQueryParamUrl(query);
  }

  onPrint = (id) => {
    const { entity, printEntity } = this.props;
    const values = { id, entity: printEntity != null ? printEntity : entity };
    services.getPrintFormatTemplate(values).then((response) => {
      const contentType = response.headers["content-type"];
      if (contentType == "application/pdf") {
        var file = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(file);
        const iframe = document.createElement("iframe");
        iframe.id = "iframe-print";
        iframe.src = url;
        iframe.style = "display: none";
        document.body.append(iframe);
        iframe.focus();
        iframe.contentWindow.print();
      }
    }).catch((error) => {
      message.error(error.message);
    });
  };

  onPDFClick = (id) => {
    const { entity, printEntity } = this.props;
    const values = { id, entity: printEntity != null ? printEntity : entity };
    services.getPrintFormatTemplate(values).then((response) => {
      const contentType = response.headers["content-type"];
      if (contentType == "application/pdf") {
        var file = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(file);
        window.open(url, name);
      }
    }).catch((error) => {
      message.error(error.message);
    });
  };

  menu = (id) => (
    <Menu>
      <Menu.Item onClick={() => this.onPrint(id)}>
        <PrinterOutlined /> Print
      </Menu.Item>
      <Menu.Item onClick={() => this.onPDFClick(id)}>
        <FilePdfOutlined />  PDF
      </Menu.Item>
    </Menu>
  );

  columns = (dataSource) => {
    let { columns, printAllowed, printable } = this.props;
    let query = getQueryParamUrl();
    if (typeof (columns) == "function") {
      columns = columns(dataSource, this.handleReload);
    }
    columns = columns.map(column => {
      if (column.sortable == true) {
        column.sorter = true;
        column.sortOrder = false;
        if (query.sortBy == (column.sortKey || column.dataIndex)) {
          let order = query["order"];
          if (order == "ASC") {
            order = "ascend";
          }
          if (order == "DESC") {
            order = "descend";
          }
          column.sortOrder = order;
        }
      }
      return column;
    });
    const actionColumn = [
      {
        title: "Actions",
        width: "5%",
        dataIndex: "id",
        render: (_, row) => {
          return (
            <Dropdown overlay={() => this.menu(row.id)} placement="bottomRight">
              <Button size="small">
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          );
        }
      }];
    if (printAllowed && printable) {
      columns = [
        ...columns,
        ...actionColumn
      ];
    }
    return columns;
  }

  handlePaginationChange = (value) => {
    let query = getQueryParamUrl();
    query["page"] = value;
    updateQueryParamUrl(query);
  }

  handlePageSizeChange = (current, pageSize) => {
    let query = getQueryParamUrl();
    query["limit"] = pageSize;
    updateQueryParamUrl(query);
  }

  handleReload = () => {
    this.loadData(this.props);
  }

  renderFilterBoxComponent = () => {
    const {
      entity,
      loading,
      showHeader,
      showTitle,
      filterKeys,
      requestName,
      enableArchiveFilter,
      filterComponent: FilterComponent,
    } = this.props;
    let filter = (
      <FilterBoxComponent
        entity={entity}
        loading={loading}
        filterKeys={filterKeys}
        requestName={requestName}
        enableArchiveFilter={enableArchiveFilter}
        saveAble={showHeader == true && showTitle == true}
      />
    );
    if (FilterComponent) {
      filter = (
        <FilterComponent
          entity={entity}
          loading={loading}
          filterKeys={filterKeys}
          requestName={requestName}
          saveAble={showHeader == true && showTitle == true}
        />
      );
    }
    return filter;
  }

  renderTableComponent = (dataSource, defaultExpandAllRows) => {
    const {
      rowKey,
      scroll,
      loading,
      onExpand,
      summary,
      tableSize,
      rowSelection,
      rowClassName,
      tableLargeSize,
      childrenColumnName,
    } = this.props;
    const isMatch = window.matchMedia("(max-width: 1280px)").matches;
    return (
      <Table
        rowKey={rowKey}
        size={tableSize}
        pagination={false}
        summary={summary}
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined />,
          tip: <FormattedMessage id="listLayout.loading" />,
        }}
        onExpand={onExpand}
        dataSource={dataSource}
        onChange={this.onChange}
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        columns={this.columns(dataSource)}
        className="table-responsive main-table"
        childrenColumnName={childrenColumnName}
        defaultExpandAllRows={defaultExpandAllRows}
        scroll={scroll ? scroll : (isMatch && tableLargeSize) ? { x: "max-content" } : {}}
      />
    );
  }

  render() {
    const {
      schema,
      selected,
      loading,
      entities,
      objectIds,
      treeTable,
      hasSwitch,
      hasFilter,
      className,
      pagination,
      newDataSource,
      hasMainSwitch,
      selectedTitle,
      showPagination,
      childrenColumnName,
      defaultExpandAllRows,
      shouldDisplay = true,
      switchComponent: SwitchComponent,
      extraFilterComponent: ExtraFilterComponent,
      tableChildrenComponent: TableChildrenComponent,
    } = this.props;
    const { lastUpdated } = this.state;
    const page = getPageNumber(this.props);
    const defaultCurrent = page != null ? page + 1 : 1;
    let dataSource = denormalize(objectIds, schema, entities);
    if (treeTable) {
      if (newDataSource) {
        dataSource = newDataSource(dataSource);
      } else {
        dataSource.map((objectEntity, index) => {
          objectEntity.key = index;
          if (childrenColumnName) {
            const newObjectEntity = objectEntity[childrenColumnName];
            newObjectEntity.map((childrenObjectEntity, childrenIndex) => {
              childrenObjectEntity.key = `${index}-${childrenIndex}`;
            });
          }
        });
      }
    } else {
      if (newDataSource) {
        dataSource = newDataSource(dataSource);
      }
      dataSource.map((objectEntity, index) => {
        objectEntity.key = index;
      });
    }
    return (
      <WrapperCard
        bordered={false}
        className={className}
        bodyStyle={{ padding: "16px" }}
        headStyle={{ padding: "5px" }}
        title={
          <Fragment>
            {hasFilter && this.renderFilterBoxComponent()}
            {ExtraFilterComponent && (
              <ExtraFilterComponent />
            )}
          </Fragment>
        }
      >
        {hasMainSwitch ? (
          <SwitchComponent
            loading={loading}
            dataSource={dataSource}
          />
        ) : (
          <Fragment>
            {shouldDisplay ? (
              <>
                {showPagination && (
                  <Counter
                    className="mb-2"
                    loading={loading}
                    selected={selected}
                    pagination={pagination}
                    dataSource={dataSource}
                    selectedTitle={selectedTitle}
                    defaultCurrent={defaultCurrent}
                    onClick={this.handleReload}
                    lastUpdated={lastUpdated}
                    handlePageSizeChange={this.handlePageSizeChange}
                    handlePaginationChange={this.handlePaginationChange}
                  />
                )}
                {hasSwitch ? (
                  SwitchComponent && (
                    <SwitchComponent
                      loading={loading}
                      dataSource={dataSource}
                    />
                  )
                ) : defaultExpandAllRows && treeTable ? (
                  <Spin
                    tip={<FormattedMessage id="listLayout.loading" />}
                    spinning={loading}
                    className="d-block"
                    indicator={<LoadingOutlined />}
                  >
                    {!loading && (
                      this.renderTableComponent(dataSource, true)
                    )}
                  </Spin>
                ) : (
                  this.renderTableComponent(dataSource, false)
                )}
                {showPagination && (
                  <Counter
                    className="mt-2"
                    loading={loading}
                    selected={selected}
                    pagination={pagination}
                    dataSource={dataSource}
                    onClick={this.handleReload}
                    selectedTitle={selectedTitle}
                    defaultCurrent={defaultCurrent}
                    lastUpdated={lastUpdated}
                    handlePageSizeChange={this.handlePageSizeChange}
                    handlePaginationChange={this.handlePaginationChange}
                  />
                )}
              </>
            ) : (
              <>
                <Empty />
              </>
            )}
            {TableChildrenComponent && (
              <TableChildrenComponent
                dataSource={dataSource}
                {...this.props}
              />
            )}
          </Fragment>
        )}
      </WrapperCard>
    );
  }
}

ListLayout.propTypes = {
  tableSize: PropTypes.string,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  location: PropTypes.object,
  entities: PropTypes.object,
  schema: PropTypes.any,
  objectIds: PropTypes.array,
  filterKeys: PropTypes.array,
  filterComponent: PropTypes.any,
  showPagination: PropTypes.bool,
  treeTable: PropTypes.bool,
  childrenColumnName: PropTypes.string,
  onExpand: PropTypes.any,
  defaultExpandAllRows: PropTypes.bool,
  rowKey: PropTypes.string,
  newDataSource: PropTypes.func,
  hasSwitch: PropTypes.bool,
  hasFilter: PropTypes.bool,
  hasMainSwitch: PropTypes.bool,
  switchComponent: PropTypes.any,
  enableArchiveFilter: PropTypes.bool,
  extraFilterComponent: PropTypes.any,
  rowSelection: PropTypes.any,
  getReload: PropTypes.func,
  selected: PropTypes.number,
  className: PropTypes.string,
  tableChildrenComponent: PropTypes.any,
  extraFilterOnFirstLoad: PropTypes.any,
  tableLargeSize: PropTypes.bool,
  selectedTitle: PropTypes.string,
  requestName: PropTypes.string,
  scroll: PropTypes.bool,
  title: PropTypes.string,
  extraFilters: PropTypes.object,
  filterDataSource: PropTypes.array,
  showHeader: PropTypes.bool,
  showTitle: PropTypes.bool,
  printAllowed: PropTypes.bool,
  entity: PropTypes.string,
  printEntity: PropTypes.string,
  printable: PropTypes.bool,
  rowClassName: PropTypes.any,
  summary: PropTypes.any,
  shouldDisplay: PropTypes.bool,
};

ListLayout.defaultProps = {
  loading: false,
  tableSize: "small",
  columns: [],
  dataSource: [],
  filterKeys: [],
  creatable: true,
  showPagination: true,
  treeTable: false,
  defaultExpandAllRows: false,
  rowKey: "key",
  hasSwitch: false,
  hasFilter: true,
  actionDropdown: [],
  showHeader: true,
  showTitle: true,
  filterDataSource: [],
};

const mapStateToProps = (state, ownProps) => {
  const { requestName, reducerName, permission, reducerKey = "list" } = ownProps;
  const permissionPrint = permission && Number(permission.code) + 7;
  return {
    entities: state.entities,
    objectIds: state[reducerName][reducerKey],
    loading: state.request[requestName],
    pagination: state.pagination[requestName],
    printAllowed: getPermissionAllowed(state, permissionPrint),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { action } = ownProps;
  return {
    loadData: (values) => dispatch(action(values)),
  };
};

const ListLayoutConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListLayout);

export default withRouter(reduxForm({
  // TODO: Remove destroyOnUnmount after finished implementing this
  destroyOnUnmount: false,
  enableReinitialize: true
})(ListLayoutConnect));