import { normalize } from "normalizr";
import * as service from "../services";
import { 
  RoleArraySchema, 
  RoleSchema,
} from "../schema";
import * as Constants from "../constants";
import { PermissionGroupArraySchema } from "../schema/permission-group";
import { PermissionGroupByRoleArraySchema } from "../schema/permission-by-role-group"; 
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import { mergeEntities, syncEntities, syncArrayEntities } from "@/actions/entity";
import { UserRoleSchema } from "@/schemas/user-role";

const path = "/settings/user-management/role";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getRole,
    schema: RoleArraySchema,
    requestName: Constants.ROLE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getRoleAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getRoleAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const createRole = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createRole,
    requestName: Constants.ROLE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Role is created successfully",
  };
  return createNewEntityAction(options, values);
};

export const getRoleDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getRoleDetail,
    requestName: Constants.ROLE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: RoleSchema,
  };
  return createDetailAction(options, id);
};

export const getRoleDetailWithPermissions = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getRoleDetailWithPermissions,
    requestName: Constants.ROLE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: RoleSchema,
  };
  return createDetailAction(options, id);
};

export const editRole = (values) => (dispatch, getState) => {

  const onSuccess = (json) => {
    const normalized = normalize(json, UserRoleSchema);
    dispatch(mergeEntities(normalized.entities));
    const entityName = "userRoles";
    dispatch(syncEntities(normalized.entities[entityName][normalized.result], entityName));
  };

  const options = {
    serviceMethod: service.editRole,
    requestName: Constants.ROLE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "roles",
    schema: RoleSchema,
    onSuccess,
    successMessage: "Role is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveRole = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveRole,
    requestName: Constants.ROLE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Role is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteRole = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteRole,
    requestName: Constants.ROLE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Role is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveRole = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveRole,
    requestName: Constants.ROLE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Role is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const getPermissionGroup = () => (dispatch, getState) => {
  const successAction = (response) => {
    const normalized = normalize(response, PermissionGroupArraySchema);
    dispatch(mergeEntities(normalized.entities));
    return {
      type: Constants.PERMISSION_GROUP_LIST_SUCCESS,
      payload: normalized.result
    };
  };
  const options = {
    serviceMethod: service.getPermissionGroup,
    schema: RoleArraySchema,
    requestName: Constants.PERMISSION_GROUP_LIST_REQUEST,
    dispatch,
    getState,
    isDefaultResponse: false,
    successAction: successAction
  };
  return createListAction(options);
};

export const getPermissionGroupByRoleId = (id) => (dispatch, getState) => {
  const successAction = (response) => {
    const normalized = normalize(response, PermissionGroupByRoleArraySchema);
    dispatch(mergeEntities(normalized.entities));
    const entityName = "permissionGroupByRoles";
    dispatch(syncArrayEntities(normalized.entities[entityName], entityName));
    return {
      type: Constants.PERMISSION_LIST_SUCCESS,
      payload: normalized.result
    };
  };
  const options = {
    serviceMethod: service.getPermissionGroupByRoleId,
    requestName: Constants.PERMISSION_LIST_REQUEST,
    dispatch,
    getState,
    isDefaultResponse: false,
    successAction: successAction
  };
  return createListAction(options, id);
};