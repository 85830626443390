import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";   

class TableSeeMore extends Component {

  footerComponent = () => {
    const { hasNext, loading, onClick } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    if (hasNext && loading) {
      return (
        <div className="text-center pt-3">
          <Spin indicator={antIcon} />
        </div>
      );
    } else if (!loading && hasNext) {
      return (
        <a 
          onClick={onClick}
          className="d-flex align-items-center justify-content-center font-weight-bold" 
        >
          See More
        </a>
      );
    }
  }
  
  render() {
    const { loading, hasNext, ...props } = this.props;
    return (
      <Table 
        size="small"
        pagination={false}
        loading={{
          tip: "Loading...",
          spinning: loading,
        }}
        {...props}
        footer={(hasNext && loading || !loading && hasNext) ? this.footerComponent : undefined}
      />
    );
  }
}

TableSeeMore.propTypes = {
  loading: PropTypes.bool,
  hasNext: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TableSeeMore;