// Libs
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Container from "@/views/container";
import NotFound from "@/components/notfound";
import PrivateRoute from "@/components/private-route";
import LogIn from "@/components/authentication/login";
import Register from "@/components/authentication/register";
import ForgotPassword from "@/components/authentication/forgot-password";

class Router extends Component {

  render() {
    return (
      <Switch>
        <Route
          path="/register"
          component={Register}
        />
        <Route
          path="/forgot-password"
          component={ForgotPassword}
        />
        <PrivateRoute
          path="/"
          component={Container}
          authenticationComponent={LogIn}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Router;