const WEBSITE_PATH = "website";
const BANNER_PATH = "Banner";
const CONTACT_PATH = "contact";

export const website = [
  //banner 
  {
    name: "Banner List",
    type: "list",
    slug: `${WEBSITE_PATH}/${BANNER_PATH}/banner-list`,
  },
  {
    name: "Banner Detail",
    type: "detail",
    slug: `${WEBSITE_PATH}/${BANNER_PATH}/banner-list`,
  },
  {
    name: "Create Banner",
    type: "create",
    slug: `${WEBSITE_PATH}/${BANNER_PATH}/banner-list`,
  },
  {
    name: "Edit Banner",
    type: "edit",
    slug: `${WEBSITE_PATH}/${BANNER_PATH}/banner-list`,
  },
  //support
  {
    name: "Contact List",
    type: "list",
    slug: `${WEBSITE_PATH}/${CONTACT_PATH}/contact`,
  },
  {
    name: "Contact Detail",
    type: "detail",
    slug: `${WEBSITE_PATH}/${CONTACT_PATH}/contact`,
  },
];