export const SIDEBAR_LOAD_DATA = "SIDEBAR_LOAD_DATA";
export const SIDEBAR_PAGE_DASHBOARD = "SIDEBAR_PAGE_DASHBOARD";
export const SIDEBAR_PAGE_HUMAN_RESOURCE = "SIDEBAR_PAGE_HUMAN_RESOURCE";
export const SIDEBAR_PAGE_INVENTORY = "SIDEBAR_PAGE_INVENTORY";
export const SIDEBAR_PAGE_ACCOUNTING = "SIDEBAR_PAGE_ACCOUNTING";
export const SIDEBAR_PAGE_PURCHASE = "SIDEBAR_PAGE_PURCHASE";
export const SIDEBAR_PAGE_SALE = "SIDEBAR_PAGE_SALE";
export const SIDEBAR_PAGE_SETTING = "SIDEBAR_PAGE_SETTING";
export const SIDEBAR_PAGE_REPORT = "SIDEBAR_PAGE_REPORT";
export const SIDEBAR_PAGE_PROFILE = "SIDEBAR_PAGE_PROFILE";
export const SIDEBAR_PAGE_SYNC_ITEM = "SIDEBAR_PAGE_SYNC_ITEM";
export const SIDEBAR_PAGE_POINT_OF_SALE = "SIDEBAR_PAGE_POINT_OF_SALE";
export const SIDEBAR_PAGE_APP = "SIDEBAR_PAGE_APP";
export const SIDEBAR_PAGE_NOTIFICATION = "SIDEBAR_PAGE_NOTIFICATION";
export const SIDEBAR_PAGE_WEBSITE = "SIDEBAR_PAGE_WEBSITE";
export const SIDEBAR_PAGE_DELIVERY = "SIDEBAR_PAGE_DELIVERY";