import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/expense/v1";

export const getExpense = (values) => {
  const url = `${API_URL}/list?includes[]=paymentMethod`;
  if (!values.filter.noDateRange) {
    values = getDefaultCurrentDateRange(values);
  }
  return GETList(url, values);
};

export function createExpense(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getExpenseAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getExpenseDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editExpense(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveExpense(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveExpense(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteExpense(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function submitExpense(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function postExpense(id) {
  const url = `${API_URL}/${id}/post`;
  return PUTWithToken(url);
}

export function postBatchExpense(values) {
  const url = `${API_URL}/post-batch`;
  const newValues = {
    expenseIds: values,
  };
  return PUTWithToken(url, newValues);
}

export function processExpense(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/process`;
  return PUTWithToken(url, values);
}

export function approveExpense(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectExpense(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function draftExpense(id) {
  const url = `${API_URL}/${id}/draft`;
  return PUTWithToken(url);
}

export function payBatchExpense(values) {
  const url = `${API_URL}/process-batch`;
  return PUTWithToken(url, values);
}

export function approveExpenses(values) {
  const newValues = {
    ids: values,
  };
  const url = `${API_URL}/approve/multiple`;
  return PUTWithToken(url, newValues);
}

