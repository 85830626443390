import React, { Component } from "react";
import PropTypes from "prop-types";
import { Calendar as AntCalendar } from "antd";
import moment from "moment";

// helper
import { getQueryParamUrl } from "@modules/helpers";
import { DEFAULT_ASYNC_DATE_FORMAT } from "@/constants/default-constants";

class Calendar extends Component {
  
  render() {
    const { 
      dataSource, 
      dateCellRender,
    } = this.props;
    const query = getQueryParamUrl();
    const valueDate = query.from;
    let value = moment(valueDate, DEFAULT_ASYNC_DATE_FORMAT);
    if (isNaN(value.date())) {
      value = moment();
    }
    return (
      <AntCalendar
        value={value}
        dateCellRender={(value) => dateCellRender(value, dataSource)} 
      />
    );
  }
}

Calendar.propTypes = {
  dataSource: PropTypes.array,
  dateCellRender: PropTypes.func,
};

Calendar.defaultProps = {
  dataSource: []
};

export default Calendar;