// Lists
import { READ_JOURNAL_ENTRY_BATCH } from "@modules/accounting/list/journal-entry/constants";
import { READ_CHART_OF_ACCOUNT } from "@modules/accounting/list/chart-of-account/constants";
import { READ_EXPENSE } from "@modules/accounting/list/expense/constants";
import { READ_CUSTOMER_DEPOSIT } from "@modules/accounting/list/customer-deposit/constants";
import { READ_SUPPLIER_DEPOSIT } from "@modules/accounting/list/supplier-deposit/constants";
import { READ_SUPPLIER_REFUND } from "@modules/accounting/list/supplier-refund/constants";
import { READ_CUSTOMER_REFUND } from "@modules/accounting/list/customer-refund/constants";
import { READ_PAYMENT_METHOD } from "@modules/accounting/list/payment-method/constants";
import { READ_CUSTOMER_COMMISSION } from "@modules/accounting/list/customer-commission/constants";
import { READ_END_OF_DAY } from "@modules/accounting/list/end-of-day/constants";
import { READ_ACCOUNTING_SETTING } from "@modules/accounting/list/setting/constants";

// Banking
import { READ_BANK } from "@modules/accounting/banking/bank/constants";
import { READ_LOAN } from "@modules/accounting/banking/loan/constants";
import { READ_CHEQUE_ENTRY } from "@modules/accounting/banking/cheque-entry/constants";
import { READ_BANK_TRANSFER } from "@modules/accounting/banking/bank-transfer/constants";
import { READ_BANK_ENTRY } from "@modules/accounting/banking/bank-entry/constants";
import { READ_BANK_SETTING } from "@modules/accounting/banking/setting/constants";
import { READ_CONTRACTOR } from "@/modules/accounting/list/contractor/constants";

// Report
import { ACCOUNTING_REPORT_MENU } from "./reports";

export default {
  sideBarTitle: "Accounting",
  hasDashboard: true,
  isFixed: false,
  slug: "accounting",
  display: true,
  menus: [
    {
      id: "1",
      title: "List",
      slug: "list",
      icon: "UnorderedListOutlined",
      sub: [
        {
          id: "1",
          title: "Journal Entry",
          slug: "journal-entry",
          code: READ_JOURNAL_ENTRY_BATCH,
        },
        {
          id: "2",
          title: "Expense",
          slug: "expense",
          code: READ_EXPENSE,
        },
        {
          id: "3",
          title: "Supplier Deposit",
          slug: "supplier-deposit",
          code: READ_SUPPLIER_DEPOSIT,
        },
        {
          id: "4",
          title: "Supplier Refund",
          slug: "supplier-refund",
          code: READ_SUPPLIER_REFUND,
        },
        {
          id: "5",
          title: "Customer Deposit",
          slug: "customer-deposit",
          code: READ_CUSTOMER_DEPOSIT,
        },
        {
          id: "6",
          title: "Customer Refund",
          slug: "customer-refund",
          code: READ_CUSTOMER_REFUND,
        },
        {
          id: "7",
          title: "Customer Commission",
          slug: "customer-commission",
          code: READ_CUSTOMER_COMMISSION,
        },
        {
          id: "8",
          title: "Chart of Account",
          slug: "chart-of-account",
          code: READ_CHART_OF_ACCOUNT,
        },
        {
          id: "9",
          title: "Payment Method",
          slug: "payment-method",
          code: READ_PAYMENT_METHOD,
        },
        {
          id: "10",
          title: "Contractor",
          slug: "contractor",
          code: READ_CONTRACTOR,
        },
        {
          id: "11",
          title: "End of Day",
          slug: "end-of-day",
          code: READ_END_OF_DAY,
        },
        {
          id: "12",
          title: "Accounting Setting",
          slug: "accounting-setting",
          code: READ_ACCOUNTING_SETTING,
        },
      ]
    },
    {
      id: 2,
      title: "Banking",
      slug: "banking",
      icon: "BankOutlined",
      sub: [
        {
          id: "1",
          title: "Cheque Entry",
          slug: "cheque-entry",
          code: READ_CHEQUE_ENTRY,
        },
        {
          id: "2",
          title: "Bank",
          slug: "bank",
          code: READ_BANK,
        },
        {
          id: "3",
          title: "Bank Entry",
          slug: "bank-entry",
          code: READ_BANK_ENTRY,
        },
        {
          id: "4",
          title: "Bank Transfer",
          slug: "bank-transfer",
          code: READ_BANK_TRANSFER,
        },
        {
          id: "5",
          title: "Loan",
          slug: "loan",
          code: READ_LOAN,
        },

        {
          id: "6",
          title: "Bank Setting",
          slug: "bank-setting",
          code: READ_BANK_SETTING,
        },
      ]
    },
    {
      id: 3,
      title: "Report",
      slug: "report",
      icon: "FileTextOutlined",
      sub: ACCOUNTING_REPORT_MENU
    },
  ]
};