export const OFFER_TERM_LIST_REQUEST = "OFFER_TERM_LIST_REQUEST"; 
export const OFFER_TERM_LIST_SUCCESS = "OFFER_TERM_LIST_REQUEST_SUCCESS";
export const OFFER_TERM_CREATE_REQUEST = "OFFER_TERM_CREATE_REQUEST"; 
export const OFFER_TERM_DETAIL_REQUEST = "OFFER_TERM_DETAIL_REQUEST"; 
export const OFFER_TERM_ARCHIVE_REQUEST = "OFFER_TERM_ARCHIVE_REQUEST"; 
export const OFFER_TERM_DELETE_REQUEST = "OFFER_TERM_DELETE_REQUEST"; 
export const OFFER_TERM_UNARCHIVE_REQUEST = "OFFER_TERM_UNARCHIVE_REQUEST"; 
export const OFFER_TERM_EDIT_REQUEST = "OFFER_TERM_EDIT_REQUEST"; 
export const OFFER_TERM_CREATE_FORM_NAME = "createOfferTerm"; 
export const OFFER_TERM_EDIT_FORM_NAME = "editOfferTerm"; 
export const OFFER_TERM_AUTOCOMPLETE_REQUEST = "OFFER_TERM_AUTOCOMPLETE_REQUEST";
export const OFFER_TERM_AUTOCOMPLETE_SUCCESS = "OFFER_TERM_AUTOCOMPLETE_SUCCESS";
export const OFFER_TERM_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  code: {
    required: true
  }
};
export const READ_OFFER_TERM = 163000;
export const CREATE_OFFER_TERM = 163001;
export const UPDATE_OFFER_TERM = 163002;
export const DELETE_OFFER_TERM = 163003;
export const ARCHIVE_OFFER_TERM = 163004;
export const UNARCHIVE_OFFER_TERM = 163005;