export const ITEM_INSTALLATION_LIST_REQUEST = "ITEM_INSTALLATION_LIST_REQUEST";
export const ITEM_INSTALLATION_LIST_SUCCESS = "ITEM_INSTALLATION_LIST_REQUEST_SUCCESS";
export const ITEM_INSTALLATION_AUTOCOMPLETE_REQUEST = "ITEM_INSTALLATION_AUTOCOMPLETE_REQUEST";
export const ITEM_INSTALLATION_DETAIL_REQUEST= "ITEM_INSTALLATION_DETAIL_REQUEST";
export const ITEM_INSTALLATION_ARCHIVE_REQUEST= "ITEM_INSTALLATION_ARCHIVE_REQUEST";
export const ITEM_INSTALLATION_UNARCHIVE_REQUEST= "ITEM_INSTALLATION_UNARCHIVE_REQUEST";
export const ITEM_INSTALLATION_DELETE_REQUEST= "ITEM_INSTALLATION_DELETE_REQUEST";
export const ITEM_INSTALLATION_BATCH_DELETE_REQUEST= "ITEM_INSTALLATION_BATCH_DELETE_REQUEST";

export const ITEM_INSTALLATION_ASSIGN_EMPLOYEE_REQUEST = "ITEM_INSTALLATION_ASSIGN_EMPLOYEE_REQUEST";
export const ITEM_INSTALLATION_START_REQUEST = "ITEM_INSTALLATION_START_REQUEST";
export const ITEM_INSTALLATION_COMPLETE_REQUEST = "ITEM_INSTALLATION_COMPLETE_REQUEST";
export const ITEM_INSTALLATION_COMPLETE_MULTIPLE_REQUEST = "ITEM_INSTALLATION_COMPLETE_MULTIPLE_REQUEST";

export const ITEM_INSTALLATION_STATUS_DRAFT = 1;
export const ITEM_INSTALLATION_STATUS_DRAFT_TITLE = "Draft";
export const ITEM_INSTALLATION_STATUS_ASSIGNED = 2;
export const ITEM_INSTALLATION_STATUS_ASSIGNED_TITLE = "Assigned";
export const ITEM_INSTALLATION_STATUS_STARTED = 3;
export const ITEM_INSTALLATION_STATUS_STARTED_TITLE = "Started";
export const ITEM_INSTALLATION_STATUS_COMPLETED = 4;
export const ITEM_INSTALLATION_STATUS_COMPLETED_TITLE = "Completed";

export const ITEM_INSTALLATION_ASSIGN_FORM_NAME = "assignEmployee";

export const  ITEM_INSTALLATION_ASSIGN_EMPLOYEE_SUBMIT_RULES = {
  employeeId: {
    required: true
  },
};

export const ITEM_INSTALLATION_STATUS = [
  {
    id: ITEM_INSTALLATION_STATUS_DRAFT,
    name: ITEM_INSTALLATION_STATUS_DRAFT_TITLE,
  },
  {
    id: ITEM_INSTALLATION_STATUS_ASSIGNED,
    name: ITEM_INSTALLATION_STATUS_ASSIGNED_TITLE,
  },
  {
    id: ITEM_INSTALLATION_STATUS_STARTED,
    name: ITEM_INSTALLATION_STATUS_STARTED_TITLE,
  },
  {
    id: ITEM_INSTALLATION_STATUS_COMPLETED,
    name: ITEM_INSTALLATION_STATUS_COMPLETED_TITLE,
  },
];

export const READ_ITEM_INSTALLATION_CODE = 237000;
export const CREATE_ITEM_INSTALLATION_CODE = 237001;
export const UPDATE_ITEM_INSTALLATION_CODE = 237002;
export const DELETE_ITEM_INSTALLATION_CODE = 237003;
export const ARCHIVE_ITEM_INSTALLATION_CODE = 237004;
export const UNARCHIVE_ITEM_INSTALLATION_CODE = 237005;
export const ASSIGN_ITEM_INSTALLATION_CODE = 237011;
export const START_ITEM_INSTALLATION_CODE = 237012;
export const COMPLETE_ITEM_INSTALLATION_CODE = 237013;
export const PRINT_ITEM_INSTALLATION_CODE = 237007;