// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Form, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// Component
import Input from "@modules/cores/components/Input";

// Helper
import { createSubmitAction } from "@/helpers"; 

const REGISTER_REQUEST_RULES = (values) => {
  const { password, confirmPassword } = values;
  const rules = {
    username: {
      required: true,
    },
    password: {
      required: true,
    },
    confirmPassword: {
      required: true,
    },
  };
  rules.confirmPassword["compare"] = {
    name: "confirmPassword",
    condition: password != confirmPassword, 
    message: "Your entered password and confirmation password do not match",
  };
  return rules;
};

class RegisterForm extends Component {

  handleSubmit(values, dispatch) {
    return createSubmitAction(REGISTER_REQUEST_RULES(values))(values, dispatch);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onFinish={handleSubmit(this.handleSubmit)} layout="vertical">
        <Input
          icon={<UserOutlined />}
          name="username"
          placeholder="Username"
        />
        <Input
          type="Password"
          name="password"
          placeholder="Password"
          icon={<LockOutlined />}
          autoComplete="new-password"
        />
        <Input
          type="Password"
          icon={<LockOutlined />}
          name="confirmPassword"
          placeholder="Confirm Password"
          autoComplete="new-password"
        />
        <Button
          block
          ghost
          type="primary"
          htmlType="submit"
          loading={false}
        >
            Register
        </Button>
        <span className="mt-2 d-block text-center">
          Already have an account?
          <Link
            to="login"
            className="pl-1"
          >
            Login
          </Link>
        </span>
      </Form>
    );
  }
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
};

export default reduxForm()(RegisterForm);