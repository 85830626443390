import { getBasicToken } from "@/shared/utils";
import { GET } from "./base-service";

export const findCompany = (subDomain) => {
  if (subDomain == null) {
    return Promise.reject("Subdomain cannot be null");
  }
  const token = getBasicToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }
  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };
  return GET(`/api/company/v1/find/${subDomain}`, null, headers);
};