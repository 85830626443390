export const EXPENSE_LIST_REQUEST = "EXPENSE_LIST_REQUEST";
export const EXPENSE_LIST_SUCCESS = "EXPENSE_LIST_REQUEST_SUCCESS";
export const EXPENSE_AUTOCOMPLETE_REQUEST = "EXPENSE_AUTOCOMPLETE_REQUEST";
export const EXPENSE_AUTOCOMPLETE_SUCCESS = "EXPENSE_AUTOCOMPLETE_SUCCESS";
export const EXPENSE_CREATE_REQUEST = "EXPENSE_CREATE_REQUEST";
export const EXPENSE_DETAIL_REQUEST = "EXPENSE_DETAIL_REQUEST";
export const EXPENSE_ARCHIVE_REQUEST = "EXPENSE_ARCHIVE_REQUEST";
export const EXPENSE_DELETE_REQUEST = "EXPENSE_DELETE_REQUEST";
export const EXPENSE_UNARCHIVE_REQUEST = "EXPENSE_UNARCHIVE_REQUEST";
export const EXPENSE_EDIT_REQUEST = "EXPENSE_EDIT_REQUEST";
export const EXPENSE_CREATE_FORM_NAME = "createExpense";
export const EXPENSE_EDIT_FORM_NAME = "editExpense";
export const EXPENSE_LINE_FIELD_NAME = "expenseLines";
export const EXPENSE_ITEM_FIELD_NAME = "expenseItems";
export const EXPENSE_PAY_BATCH_FORM_NAME = "payBatchExpense";

export const READ_EXPENSE = 183000;
export const CREATE_EXPENSE = 183001;
export const UPDATE_EXPENSE = 183002;
export const DELETE_EXPENSE = 183003;
export const ARCHIVE_EXPENSE = 183004;
export const UNARCHIVE_EXPENSE = 183005;
export const PRINT_EXPENSE = 183007;
export const SUBMIT_EXPENSE = 183011;
export const APPROVE_EXPENSE = 183012;
export const REJECT_EXPENSE = 183013;
export const POST_EXPENSE = 183014;
export const ACKNOWLEDGE_EXPENSE = 183016;
export const UNACKNOWLEDGE_EXPENSE = 183017;
export const DRAFT_EXPENSE = 183018;
export const PAY_BATCH_EXPENSE = 183020;
export const POST_EXPENSES = 183022;

export const POST_EXPENSE_FORM_NAME = "postExpense";
export const PAY_EXPENSE_REQUEST = "PAY_EXPENSE_REQUEST";
export const POST_EXPENSE_REQUEST = "POST_EXPENSE_REQUEST";
export const REJECT_EXPENSE_REQUEST = "REJECT_EXPENSE_REQUEST";
export const APPROVE_EXPENSE_REQUEST = "APPROVE_EXPENSE_REQUEST";
export const SUBMIT_EXPENSE_REQUEST = "SUBMIT_EXPENSE_REQUEST";
export const DRAFT_EXPENSE_REQUEST = "DRAFT_EXPENSE_REQUEST";
export const EXPENSE_PAY_BATCH_REQUEST = "EXPENSE_PAY_BATCH_REQUEST";

export const EXPENSE_PAY_BATCH_LIST_REQUEST = "EXPENSE_PAY_BATCH_LIST_REQUEST";
export const EXPENSE_PAY_BATCH_LIST_SUCCESS = "EXPENSE_PAY_BATCH_LIST_REQUEST_SUCCESS";

export const EXPENSE_STATUS_DRAFT = 1;
export const EXPENSE_STATUS_DRAFT_TITLE = "Draft";
export const EXPENSE_STATUS_SUBMITTED = 2;
export const EXPENSE_STATUS_SUBMITTED_TITLE = "Submitted";
export const EXPENSE_STATUS_APPROVED = 3;
export const EXPENSE_STATUS_APPROVED_TITLE = "Approved";
export const EXPENSE_STATUS_REJECTED = 4;
export const EXPENSE_STATUS_REJECTED_TITLE = "Rejected";
export const EXPENSE_STATUS_PAID = 5;
export const EXPENSE_STATUS_PAID_TITLE = "Paid";
export const EXPENSE_STATUS_POSTED = 6;
export const EXPENSE_STATUS_POSTED_TITLE = "Posted";

export const EXPENSE_STATUS = [
  {
    id: EXPENSE_STATUS_DRAFT,
    name: EXPENSE_STATUS_DRAFT_TITLE
  },
  {
    id: EXPENSE_STATUS_SUBMITTED,
    name: EXPENSE_STATUS_SUBMITTED_TITLE,
  },
  {
    id: EXPENSE_STATUS_APPROVED,
    name: EXPENSE_STATUS_APPROVED_TITLE,
  },
  {
    id: EXPENSE_STATUS_REJECTED,
    name: EXPENSE_STATUS_REJECTED_TITLE,
  },
  {
    id: EXPENSE_STATUS_PAID,
    name: EXPENSE_STATUS_PAID_TITLE,
  },
  {
    id: EXPENSE_STATUS_POSTED,
    name: EXPENSE_STATUS_POSTED_TITLE,
  },
];

export const PAY_BATCH_SUBMIT_RULES_EXPENSE = {
  contractorId: {
    required: true,
  },
  expenseIds: {
    required: true,
    type: "array",
    message: "Please select at least 1 expense"
  },
};
