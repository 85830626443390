import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, InputNumber as AntInputNumber, message as AntMessage } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import styled from "styled-components";
import { FormText } from "reactstrap";

const FormItem = Form.Item;

const WrapperAntInputNumber = styled(AntInputNumber)`
  .ant-input-number-handler-wrap {
    opacity: 1;
  }
`;

const WrapperAddonAfter = styled.div`
  top: -34%;
  right: 23px;
  height: 30px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
`;

class InputNumberField extends Component {

  onChange = (value) => {
    if (value && typeof (value) == "string") {
      value = value.replace(/\D/g, "");
    }
    const {
      input: { onChange },
      handleChange,
      max,
      min,
      maxMessage,
      minMessage,
      showErrorMessage
    } = this.props;
    if (max != null && max < value) {
      let message = `value can not greater than ${max}`;
      if (maxMessage) {
        if (typeof (maxMessage) == "function") {
          message = maxMessage(max, value);
        } else {
          message = maxMessage;
        }
      }
      if (showErrorMessage) {
        AntMessage.error(message);
      }
      value = max;
    }
    if (min != null && min > value) {
      let message = `value can not smaller than ${min}`;
      if (minMessage) {
        message = maxMessage(min, value);
      }
      if (showErrorMessage) {
        AntMessage.error(message);
      }
      value = min;
    }
    onChange(value);
    if (handleChange) {
      handleChange(value);
    }
  }

  onBlur = () => {
    const { input: { onChange, value, onBlur } } = this.props;
    onChange(value);
    onBlur();
  }

  render() {
    const {
      tip,
      label,
      icon,
      placeholder,
      required,
      disabled,
      onPressEnter,
      meta: { touched, error },
      input: { value, onFocus },
      addonAfter,
      ...props
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <Fragment>
        <FormItem
          label={label}
          validateStatus={validateStatus}
          help={error}
          required={required}
        >
          <WrapperAntInputNumber
            className="w-100"
            value={value}
            prefix={icon}
            placeholder={placeholder}
            autoComplete="off"
            onChange={this.onChange}
            disabled={disabled}
            onPressEnter={onPressEnter}
            onBlur={this.onBlur}
            onFocus={onFocus}
            {...props}
          />
          {addonAfter && (
            <WrapperAddonAfter>{addonAfter}</WrapperAddonAfter>
          )}
        </FormItem>
        {tip && (
          <FormText
            color="muted"
            className="mt-0 mb-2"
          >
            {tip}
          </FormText>
        )}
      </Fragment>
    );
  }
}

InputNumberField.propTypes = {
  addonAfter: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  min: PropTypes.any,
  max: PropTypes.any,
  tip: PropTypes.string,
  minMessage: PropTypes.any,
  maxMessage: PropTypes.any,
  showErrorMessage: PropTypes.any,
};

InputNumberField.defaultProps = {
  required: false,
  showErrorMessage: false,
};

class InputNumber extends Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field
        noUpdateOnBlur
        component={InputNumberField}
        {...props}
      />
    );
  }
}

export default InputNumber;