import React from "react";
import PropTypes from "prop-types";
import AutoComplete from "../../autocomplete-static";

class GenderAutoComplete extends React.Component {

  render() {
    const dataSource = [
      {
        id: "Male",
        name: "Male"
      },
      {
        id: "Female",
        name: "Female"
      },
      {
        id: "Other",
        name: "Other"
      }
    ];
    const { label, name, required } = this.props;
    return (
      <AutoComplete 
        stringValue
        label={label}
        name={name}
        required={required}
        dataSource={dataSource}
      />
    );
  }
}

GenderAutoComplete.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
};

GenderAutoComplete.defaultProps = {
  required: true,
  name: "gender",
  label: "Gender",
};

export default GenderAutoComplete;
