// libs
import { message } from "antd";

// Utils
import * as service from "@/services/current-user"; 
import * as Constants from "@/constants/current-user";
import { CurrentUserSchema } from "@/schemas/current-user";
import { createDetailAction } from "@/modules/utils/action-creator";
import * as AuthenticationConstants from "@/constants/authentication";

export const getCurrentUser = (machineId) => (dispatch, getState) => {
  
  const successAction = (payload, id, json) => {
    const OneSignal = window.OneSignal;
    if (OneSignal) {
      OneSignal.setExternalUserId(`${window.location.host.split(".")[0]}-${json.user.id}`);
    }
    return {
      type: Constants.CURRENT_USER_SUCCESS,
      payload
    };
  };

  const errorHandler = (error) => {
    const { errorCode, statusCode } = error;
    if (errorCode && errorCode !== -1) {
      message.error(error.message);
    }
    if (statusCode == Constants.INVALID_ACCESS_TOKEN_STATUS_CODE) {
      localStorage.removeItem(AuthenticationConstants.USER_LAST_ACCESS);
      localStorage.removeItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
      localStorage.removeItem(AuthenticationConstants.LOGIN_TYPE);
      window.location.href = "/login";
    }
  };

  const options = {
    serviceMethod: service.getCurrentUser,
    requestName: Constants.CURRENT_USER_REQUEST,
    dispatch,
    getState,
    schema: CurrentUserSchema,
    successAction,
    errorHandler: errorHandler
  };
  return createDetailAction(options, machineId);
};