import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Input } from "antd";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Helper
import { 
  getQueryParamUrl, 
  updateQueryParamUrl,
  getQueryParamFromLocalStorage,
  updateQueryParamToLocalStorage,
} from "@modules/helpers";

function AsyncTextBox(props) {

  const { name, queryStorage, location } = props;
  const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
  const view = getQueryParamUrl()["view"];
  const [text, setText] = useState(query[name]);

  const onDebounce = _.debounce(queryObject => {
    if (queryStorage == "url") {
      updateQueryParamUrl(queryObject);
    } else {
      updateQueryParamToLocalStorage(queryObject);
    }
  }, 100);
  
  useEffect(() => {
    setText(query[name]);
  }, [location, view]);

  const onChange = (event) => {
    const { onChange } = props;
    const { target: { value } } = event; 
    const page = query.page;
    if (page && page > 1) {
      delete query.page;
    }
    query[name] = value;
    if (!value) {
      delete query[name];
    }
    setText(value);
    onDebounce(query);
    if (onChange) {
      onChange(value);
    }
  };

  const { placeholder, minWidth } = props;
  const defaultStyle = {
    width: "auto",
    minWidth: minWidth || "200px"
  };
  return (
    <Input 
      allowClear
      placeholder={placeholder}
      onChange={onChange}
      value={text}
      style={defaultStyle}
    />
  );
}

AsyncTextBox.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  minWidth: PropTypes.string,
  placeholder: PropTypes.string,
  queryStorage: PropTypes.string,
};

AsyncTextBox.defaultProps = {
  queryStorage: "local",
  placeholder: "Contains text",
};

export default withRouter(AsyncTextBox);