import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

// Components
import Input from "@cores/components/Input";
import InputPhoneNumber from "@cores/components/input-phone-number";

class Contact extends Component {

  render() {
    const { 
      editable,
      relationshipFieldName,
      onSaveAndContinueEditComponent: OnSaveAndContinueEditComponent, 
    } = this.props;
    return (
      <Fragment>
        {!editable ? (
          <OnSaveAndContinueEditComponent />
        ) : (
          <Row>
            <Col xs="12">
              <h6 className="text-primary">
                Contact Detail
              </h6>
            </Col>
            <Col md="6" xs="12">
              <InputPhoneNumber 
                label="Personal Phone"
                name="personalPhone"
              />
            </Col>
            <Col md="6" xs="12">
              <Input 
                label="Personal Email"
                name="personalEmail"
              />
            </Col>
            <Col md="6" xs="12">
              <Input 
                rows="4"
                type="TextArea"
                label="Current Address"
                name="currentAddress"
              />
            </Col>
            <Col md="6" xs="12">
              <Input 
                rows="4"
                type="TextArea"
                label="Permanent Address"
                name="permanentAddress"
              />
            </Col>
            <Col xs="12">
              <h6 className="text-primary">
                Emergency Contact
              </h6>
            </Col>
            <Col md="6" xs="12">
              <Input 
                label="Emergency Person"
                name="emergencyPerson"
              />
            </Col>
            <Col md="6" xs="12">
              <Input
                label="Relationship"
                name={relationshipFieldName ? relationshipFieldName : "relationship"}
              />
            </Col>
            <Col md="6" xs="12">
              <InputPhoneNumber 
                label="Phone Number"
                name="emergencyPhoneNumber"
              />
            </Col>
            <Col md="6" xs="12">
              <Input 
                rows="4"
                type="TextArea"
                label="Address"
                name="emergencyAddress"
              />
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

Contact.propTypes = {
  editable: PropTypes.bool,
  relationshipFieldName: PropTypes.string,
  onSaveAndContinueEditComponent: PropTypes.any,
};

export default Contact;