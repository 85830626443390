import { schema } from "normalizr";
import { SupplierSchema } from "../../supplier/schema";

const PurchaseOrderSchema = new schema.Entity("purchaseOrders",{
  supplier: SupplierSchema
},
{
  idAttribute: "purchaseOrderNo",
}
);
const PurchaseOrderArraySchema = new schema.Array(PurchaseOrderSchema);

export { PurchaseOrderSchema, PurchaseOrderArraySchema };