import {
  GETList,
  GETDetail,
  GETWithToken,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/stock-transfer/v1";

export const getStockTransfer = (values) => {
  const url = `${API_URL}/list`;
  values = getDefaultCurrentDateRange(values); 
  values.filter.includes = ["destinationWarehouse", "sourceWarehouse"];
  return GETList(url, values);
};

export function createStockTransfer(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getStockTransferDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editStockTransfer(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveStockTransfer(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveStockTransfer(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteStockTransfer(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function submitStockTransfer(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function cancelStockTransfer(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function postStockTransfer(id) {
  const url = `${API_URL}/${id}/post`;
  return PUTWithToken(url);
}

export function approveStockTransfer(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectStockTransfer(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function closeStockTransfer(id) {
  const url = `${API_URL}/${id}/close`;
  return PUTWithToken(url);
}

export function receiveStockTransfer(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/process`;
  return PUTWithToken(url, values);
}

export function getStockTransferReceivedHistory(id) {
  const url = `${API_URL}/${id}/stock-transfer-log`;
  return GETWithToken(url);
}