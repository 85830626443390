import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "antd";

import { renderDisplayTextFromTextArea } from "@modules/helpers";

class DetailBox extends React.Component {

  render() {
    const { 
      to, 
      title, 
      data, 
      link, 
      leftCol, 
      rightCol, 
      className,
      children,
      tag: Tag,
      type,
      onlyTitle,
      style,
    } = this.props;
    return (
      <Row className={className}>
        {title && <Col md={leftCol} xs="12">
          <Typography.Text strong>{title} {!onlyTitle && ":"}</Typography.Text>
        </Col>}
        <Col md={rightCol} xs="12" style={style}>
          {Tag ? <Tag>{data}</Tag> : type == "text-area" ? renderDisplayTextFromTextArea(data) : data}
          {link && to && (
            <Link to={to}>{link}</Link>
          )}
          {children}
        </Col>
      </Row>
    );
  }
}

DetailBox.propTypes = {
  title: PropTypes.any,
  data: PropTypes.any,
  link: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.any,
  leftCol: PropTypes.any,
  rightCol: PropTypes.any,
  className: PropTypes.string,
  tag: PropTypes.string,
  onlyTitle: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
};

DetailBox.defaultProps = {
  leftCol: "6",
  rightCol: "6",
  className: "mt-2 mb-2",
  onlyTitle: false
};

export default DetailBox;