export const STOCK_TRANSFER_LIST_REQUEST = "STOCK_TRANSFER_LIST_REQUEST";
export const STOCK_TRANSFER_LIST_SUCCESS = "STOCK_TRANSFER_LIST_REQUEST_SUCCESS";
export const STOCK_TRANSFER_CREATE_REQUEST = "STOCK_TRANSFER_CREATE_REQUEST";
export const STOCK_TRANSFER_DETAIL_REQUEST = "STOCK_TRANSFER_DETAIL_REQUEST";
export const STOCK_TRANSFER_ARCHIVE_REQUEST = "STOCK_TRANSFER_ARCHIVE_REQUEST";
export const STOCK_TRANSFER_DELETE_REQUEST = "STOCK_TRANSFER_DELETE_REQUEST";
export const STOCK_TRANSFER_UNARCHIVE_REQUEST = "STOCK_TRANSFER_UNARCHIVE_REQUEST";
export const STOCK_TRANSFER_EDIT_REQUEST = "STOCK_TRANSFER_EDIT_REQUEST";
export const DESTINATION_WAREHOUSE_REQUEST = "DESTINATION_WAREHOUSE_REQUEST";

export const STOCK_TRANSFER_CREATE_FORM_NAME = "createStockTransfer";
export const STOCK_TRANSFER_EDIT_FORM_NAME = "editStockTransfer";

export const STOCK_TRANSFER_ITEM_FILED_NAME = "stockTransferItems";
export const STOCK_TRANSFER_PROCESS_ITEM_FORM_NAME = "processItemForm";
export const STOCK_TRANSFER_PROCESS_ITEM_ARRAY_FIELD_NAME = "stockTransferItems";

export const STOCK_TRANSFER_ITEMS_SUBMIT_RULES = {
  itemId: {
    required: true
  },
  quantity: {
    required: true
  },
};

export const READ_STOCK_TRANSFER = 220000;
export const CREATE_STOCK_TRANSFER = 220001;
export const UPDATE_STOCK_TRANSFER = 220002;
export const DELETE_STOCK_TRANSFER = 220003;
export const ARCHIVE_STOCK_TRANSFER = 220004;
export const UNARCHIVE_STOCK_TRANSFER = 220005;
export const SUBMIT_STOCK_TRANSFER = 220011;
export const APPROVE_STOCK_TRANSFER = 220012;
export const REJECT_STOCK_TRANSFER = 220013;
export const POST_STOCK_TRANSFER = 220014;
export const CANCEL_STOCK_TRANSFER = 220015;
export const PRINT_STOCK_TRANSFER = 220007;
export const PROCESS_STOCK_TRANSFER = 220016;
export const CLOSE_STOCK_TRANSFER = 220017;

export const STOCK_TRANSFER_APPROVE_REQUEST = "STOCK_TRANSFER_APPROVE_REQUEST";
export const STOCK_TRANSFER_REJECT_REQUEST = "STOCK_TRANSFER_REJECT_REQUEST";
export const STOCK_TRANSFER_SUBMIT_REQUEST = "STOCK_TRANSFER_SUBMIT_REQUEST";
export const STOCK_TRANSFER_POST_REQUEST = "STOCK_TRANSFER_POST_REQUEST";
export const STOCK_TRANSFER_CANCEL_REQUEST = "STOCK_TRANSFER_CANCEL_REQUEST";
export const STOCK_TRANSFER_PROCESS_REQUEST = "STOCK_TRANSFER_PROCESS_REQUEST";
export const STOCK_TRANSFER_CLOSE_REQUEST = "STOCK_TRANSFER_CLOSE_REQUEST";

export const STOCK_TRANSFER_STATUS_DRAFT = 1;
export const STOCK_TRANSFER_STATUS_DRAFT_TITLE = "Draft";

export const STOCK_TRANSFER_STATUS_SUBMITTED = 2;
export const STOCK_TRANSFER_STATUS_SUBMITTED_TITLE = "Submitted";

export const STOCK_TRANSFER_STATUS_APPROVED = 3;
export const STOCK_TRANSFER_STATUS_APPROVED_TITLE = "Approved";

export const STOCK_TRANSFER_STATUS_REJECTED = 4;
export const STOCK_TRANSFER_STATUS_REJECTED_TITLE = "Rejected";

export const STOCK_TRANSFER_STATUS_PROCESSED = 5;
export const STOCK_TRANSFER_STATUS_PROCESSED_TITLE = "Processed";

export const STOCK_TRANSFER_STATUS_CANCEL = 6;
export const STOCK_TRANSFER_STATUS_CANCEL_TITLE = "Cancelled";

export const STOCK_TRANSFER_STATUS_STOCK_OUT = 7;
export const STOCK_TRANSFER_STATUS_STOCK_OUT_TITLE = "Stock Out";

export const STOCK_TRANSFER_STATUS_PROCESSING = 8;
export const STOCK_TRANSFER_STATUS_PROCESSING_TITLE = "Processing";

export const STOCK_TRANSFER_STATUS_CLOSED = 9;
export const STOCK_TRANSFER_STATUS_CLOSED_TITLE = "Closed";

export const STOCK_TRANSFER_STATUS_DATASOURCE = [
  {
    id: STOCK_TRANSFER_STATUS_DRAFT,
    name: STOCK_TRANSFER_STATUS_DRAFT_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_SUBMITTED,
    name: STOCK_TRANSFER_STATUS_SUBMITTED_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_APPROVED,
    name: STOCK_TRANSFER_STATUS_APPROVED_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_REJECTED,
    name: STOCK_TRANSFER_STATUS_REJECTED_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_PROCESSING,
    name: STOCK_TRANSFER_STATUS_PROCESSING_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_PROCESSED,
    name: STOCK_TRANSFER_STATUS_PROCESSED_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_CANCEL,
    name: STOCK_TRANSFER_STATUS_CANCEL_TITLE,
  },
  {
    id: STOCK_TRANSFER_STATUS_CLOSED,
    name: STOCK_TRANSFER_STATUS_CLOSED_TITLE,
  }
];