import { schema } from "normalizr";
import { CustomerSchema } from "@sale/customer-center/customer/schema";

const CustomerCommissionSchema = new schema.Entity("customerCommissions", {
  customer: CustomerSchema
}, {
  idAttribute: "customerCommissionNo",
});
const CustomerCommissionArraySchema = new schema.Array(CustomerCommissionSchema);

export { CustomerCommissionSchema, CustomerCommissionArraySchema };