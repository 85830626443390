export const TAG_LIST_REQUEST = "TAG_LIST_REQUEST"; 
export const TAG_LIST_SUCCESS = "TAG_LIST_REQUEST_SUCCESS";
export const TAG_CREATE_REQUEST = "TAG_CREATE_REQUEST"; 
export const TAG_DETAIL_REQUEST = "TAG_DETAIL_REQUEST"; 
export const TAG_ARCHIVE_REQUEST = "TAG_ARCHIVE_REQUEST"; 
export const TAG_DELETE_REQUEST = "TAG_DELETE_REQUEST"; 
export const TAG_UNARCHIVE_REQUEST = "TAG_UNARCHIVE_REQUEST"; 
export const TAG_EDIT_REQUEST = "TAG_EDIT_REQUEST"; 
export const TAG_CREATE_FORM_NAME = "createTag"; 
export const TAG_EDIT_FORM_NAME = "editTag"; 
export const TAG_AUTOCOMPLETE_REQUEST = "TAG_AUTOCOMPLETE_REQUEST";
export const TAG_AUTOCOMPLETE_SUCCESS = "TAG_AUTOCOMPLETE_SUCCESS";
export const TAG_SUBMIT_RULES = { 
  name: {
    required: true,
  },
};
export const READ_TAG = 210000;
export const CREATE_TAG = 210001;
export const UPDATE_TAG = 210002;
export const DELETE_TAG = 210003;
export const ARCHIVE_TAG = 210004;
export const UNARCHIVE_TAG = 210005;