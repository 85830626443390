import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";

// libs
import { Upload, message, Form } from "antd";
import classnames from "classnames";
const FormItem = Form.Item;

// utils
import { getAccessToken } from "@/shared/utils";

const Dragger = Upload.Dragger;

class FileUpload extends React.Component {

  state = {
    fileUrl: null,
    fileName: null,
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const { value, fileName } = newProps;
    if (value == "") {
      this.setState({
        fileUrl: null,
        fileName: null,
      });
    } else {
      this.setState({
        fileUrl: value,
        fileName: fileName,
      });
    }
  }

  handleChange = (info) => {
    const { onSuccess } = this.props;
    const status = info.file.status;
    if (status === "done") {
      this.setState({
        fileName: info.file.name,
        fileUrl: info.file.response.url,
      });
      const { onChange } = this.props;
      if (onChange) {
        onChange(info.file.response.url);
      }
      if (onSuccess) {
        onSuccess(info.file.response.url);
      }
      message.success("File uploaded successfully");
    } else if (status === "error") {
      message.error("File upload failed");
    }
    if (status === "removed") {
      this.setState({
        fileUrl: null,
      });
      const { onChange } = this.props;
      if (onChange) {
        onChange("");
      }
    }
  }

  render () {
    const {
      path,
      multiple,
      listType,
      required,
      label,
      beforeUpload,
      showUploadList,
      meta: { touched, error },
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    const { fileName, fileUrl } = this.state;
    const fileList = [{
      uid: "-1",
      name: `${fileName}`,
      status: "done",
      url: `${fileUrl}`,
      thumbUrl: `${ fileUrl}`,
    }];
    const token = getAccessToken();
    return (
      <FormItem 
        label={label} 
        validateStatus={validateStatus}
        help={error}
        required={required}
      >
        {!fileUrl ? (
          <Dragger 
            name="file"
            multiple={multiple}
            action={`/api/file/v1/upload?path=${path}`}
            onChange={this.handleChange}
            beforeUpload={beforeUpload}
            headers={{Authorization: `Bearer ${token && token.accessToken}`}}
            withCredentials
            showUploadList={showUploadList}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        ) : (
          <Upload
            listType={listType}
            showUploadList={true}
            defaultFileList={[...fileList]}
            className='upload-list-inline'
            onChange={this.handleChange}
          />
        )}
      </FormItem>
    );
  }
}

FileUpload.propTypes = {
  path: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  fileUrl: PropTypes.string,
  listType: PropTypes.string,
  fileName: PropTypes.string,
  meta: PropTypes.any,
  touched: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  beforeUpload: PropTypes.func,
  showUploadList: PropTypes.bool,
  icon: PropTypes.string,
  onSuccess: PropTypes.func,
};

FileUpload.defaultProps = {
  name: "file",
  multiple: false,
  listType: "picture",
  showUploadList: true,
  icon: "inbox",
};

export default withRouter(FileUpload);