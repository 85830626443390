import { 
  CompanySchema,
  CompanyArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as CompanyConstants from "../constants";

const path = "/settings/setup/company";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCompany,
    schema: CompanyArraySchema,
    requestName: CompanyConstants.COMPANY_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createCompany,
    requestName: CompanyConstants.COMPANY_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getCompanyAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getCompanyAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getCompanyDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCompanyDetail,
    requestName: CompanyConstants.COMPANY_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: CompanySchema,
  };
  return createDetailAction(options, id);
};

export const editCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editCompany,
    requestName: CompanyConstants.COMPANY_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "companies",
    schema: CompanySchema,
    successMessage: "Company is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveCompany = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveCompany,
    requestName: CompanyConstants.COMPANY_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Company is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteCompany = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteCompany,
    requestName: CompanyConstants.COMPANY_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Company is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveCompany = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveCompany,
    requestName: CompanyConstants.COMPANY_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Company is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};