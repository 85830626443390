import React, { createContext, useContext } from "react";
import Layout from "@/components/layout";

export const LayoutContext = createContext();

// eslint-disable-next-line react/prop-types
export const LayoutProvider = ({ children }) => {

  const [visibleLayout, setVisibleLayout] = React.useState(true);

  return (
    <LayoutContext.Provider 
      value={{
        visibleLayout,
        setVisibleLayout,
      }}
    >
      {visibleLayout ? (
        <Layout>
          {children}
        </Layout>
      ) : children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("Context must be used inside Context");
  }
  return context;
};