// Libs
import { 
  PurchaseOrderSchema,
  PurchaseOrderArraySchema, 
} from "../schema";
import { 
  PurchaseOrderHistoryArraySchema
} from "../schema/purchase-order-history";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createEntityActionAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as PurchaseOrderConstants from "../constants";

const path = "/purchase/list/purchase-order";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPurchaseOrder,
    schema: PurchaseOrderArraySchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createPurchaseOrder = (values) => (dispatch, getState) => {
  
  const redirectUrl = (json) => {
    return `${path}/${json.purchaseOrderNo}`;
  };
  const options = {
    serviceMethod: service.createPurchaseOrder,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
  };
  return createNewEntityAction(options, values);
};

export const getPurchaseOrderAutoComplete = (query, extra) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPurchaseOrderAutoComplete,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_AUTOCOMPLETE_REQUEST,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getPurchaseOrderDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPurchaseOrderDetail,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: PurchaseOrderSchema,
  };
  return createDetailAction(options, id);
};

export const editPurchaseOrder = (values) => (dispatch, getState) => {
  const redirectUrl = (json) => {
    return `${path}/${json.purchaseOrderNo}`;
  };
  const options = {
    serviceMethod: service.editPurchaseOrder,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
    entityName: "purchaseOrders",
    schema: PurchaseOrderSchema,
    syncArray: true,
    successMessage: "Purchase order is updated successfully",
  };
  return createEditAction(options, values);
};

export const archivePurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archivePurchaseOrder,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Purchase order is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const updatePurchaseOrderReference = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updatePurchaseOrderReference,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_UPDATE_REFERENCE_REQUEST,
    dispatch,
    getState,
    schema: PurchaseOrderSchema,
    entityName: "purchaseOrders",
    syncArray: true,
    successMessage: "Purchase order reference is updated successfully",
  };
  return createEditAction(options, values);
};

export const updatePurchaseOrderNote = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updatePurchaseOrderNote,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_UPDATE_NOTE_REQUEST,
    dispatch,
    getState,
    schema: PurchaseOrderSchema,
    entityName: "purchaseOrders",
    syncArray: true,
    successMessage: "Purchase order note is updated successfully",
  };
  return createEditAction(options, values);
};

export const deletePurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deletePurchaseOrder,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Purchase order is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchivePurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchivePurchaseOrder,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const submitPurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const processPurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.processPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_PROCESS_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is processed successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approvePurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approvePurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectPurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const voidPurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.voidPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_VOID_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is voided successfully!",
  };
  return createEntityActionAction(options, id);
};

export const receivePurchaseOrder = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.receivePurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_RECEIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is processed successfully!",
  };
  return createEntityActionAction(options, values);
};

export const cancelPurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.cancelPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is canceled successfully!",
  };
  return createEntityActionAction(options, id);
};

export const closePurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.closePurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_CLOSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is closed successfully!",
  };
  return createEntityActionAction(options, id);
};

export const payPurchaseOrder = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.payPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_PAY_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is paid successfully!",
  };
  return createEntityActionAction(options, values);
};

export const getPayBatchPurchaseOrder = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPurchaseOrder,
    schema: PurchaseOrderArraySchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_PAY_BATCH_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const payBatchPurchaseOrder = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.payBatchPurchaseOrder,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_PAY_BATCH_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order batch is paid successfully!",
  };
  return createEntityActionAction(options, values);
};

export const setToDraftPurchaseOrder = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.setToDraftPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_SET_TO_DRAFT_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is set to draft successfully!",
  };
  return createEntityActionAction(options, id);
};

export const getPurchaseOrderHistory = (id) => (dispatch, getState) => {

  const successAction = (payload) => {
    return {
      type: PurchaseOrderConstants.PURCHASE_ORDER_HISTORY_LIST_SUCCESS,
      id,
      payload,
    };
  };

  const options = {
    serviceMethod: service.getPurchaseOrderHistory,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_HISTORY_LIST_REQUEST,
    dispatch,
    getState,
    schema: PurchaseOrderHistoryArraySchema,
    successAction
  };
  return createDetailAction(options, id);
};

export const mergePurchaseOrder = (values) => (dispatch, getState) => {

  const redirectUrl = (json) => {
    return `${path}/${json.purchaseOrderNo}`;
  };

  const options = {
    serviceMethod: service.mergePurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_MERGE_REQUEST,
    dispatch,
    getState,
    redirectUrl,
    successMessage: "Purchase order is merged successfully!",
  };
  return createEntityActionAction(options, values);
};

export const splitPurchaseOrder = (values) => (dispatch, getState) => {

  const redirectUrl = (json) => {
    return `${path}/${json.purchaseOrderNo}`;
  };

  const options = {
    serviceMethod: service.splitPurchaseOrder,
    schema: PurchaseOrderSchema,
    requestName: PurchaseOrderConstants.PURCHASE_ORDER_SPLIT_REQUEST,
    dispatch,
    getState,
    redirectUrl,
    successMessage: "Purchase order is splitted successfully!",
  };
  return createEntityActionAction(options, values);
};