export const PURCHASE_ORDER_LIST_REQUEST = "PURCHASE_ORDER_LIST_REQUEST";
export const PURCHASE_ORDER_LIST_SUCCESS = "PURCHASE_ORDER_LIST_REQUEST_SUCCESS";
export const PURCHASE_ORDER_CREATE_REQUEST = "PURCHASE_ORDER_CREATE_REQUEST";
export const PURCHASE_ORDER_DETAIL_REQUEST = "PURCHASE_ORDER_DETAIL_REQUEST";
export const PURCHASE_ORDER_ARCHIVE_REQUEST = "PURCHASE_ORDER_ARCHIVE_REQUEST";
export const PURCHASE_ORDER_DELETE_REQUEST = "PURCHASE_ORDER_DELETE_REQUEST";
export const PURCHASE_ORDER_UNARCHIVE_REQUEST = "PURCHASE_ORDER_UNARCHIVE_REQUEST";
export const PURCHASE_ORDER_EDIT_REQUEST = "PURCHASE_ORDER_EDIT_REQUEST";
export const PURCHASE_ORDER_SUBMIT_REQUEST = "PURCHASE_ORDER_SUBMIT_REQUEST";
export const PURCHASE_ORDER_PROCESS_REQUEST = "PURCHASE_ORDER_PROCESS_REQUEST";
export const PURCHASE_ORDER_CANCEL_REQUEST = "PURCHASE_ORDER_CANCEL_REQUEST";
export const PURCHASE_ORDER_VOID_REQUEST = "PURCHASE_ORDER_VOID_REQUEST";
export const PURCHASE_ORDER_CLOSE_REQUEST = "PURCHASE_ORDER_CLOSE_REQUEST";
export const PURCHASE_ORDER_VERIFY_REQUEST = "PURCHASE_ORDER_VERIFY_REQUEST";
export const PURCHASE_ORDER_PAY_REQUEST = "PURCHASE_ORDER_PAY_REQUEST";
export const PURCHASE_ORDER_CREATE_FORM_NAME = "createPurchaseOrder";
export const PURCHASE_ORDER_EDIT_FORM_NAME = "editPurchaseOrder";
export const PURCHASE_ORDER_ITEM_FILED_NAME = "purchaseOrderItems";
export const PURCHASE_ORDER_SHIPMENT_FILED_NAME = "purchaseOrderShipments";
export const PURCHASE_ORDER_PROCESS_ITEM_FORM_NAME = "processItemForm";
export const PURCHASE_ORDER_PAY_FORM_NAME = "payPurchaseOrder";
export const PURCHASE_ORDER_PROCESS_ITEM_ARRAY_FIELD_NAME = "purchaseOrderItems";
export const PURCHASE_ORDER_PAY_BATCH_REQUEST = "PURCHASE_ORDER_PAY_BATCH_REQUEST";
export const PURCHASE_ORDER_PAY_BATCH_FORM_NAME = "payBatchPurchaseOrder";
export const PURCHASE_ORDER_PAY_BATCH_LIST_REQUEST = "PURCHASE_ORDER_PAY_BATCH_LIST_REQUEST";
export const PURCHASE_ORDER_PAY_BATCH_LIST_SUCCESS = "PURCHASE_ORDER_PAY_BATCH_LIST_REQUEST_SUCCESS";
export const PURCHASE_ORDER_HISTORY_LIST_REQUEST = "PURCHASE_ORDER_HISTORY_LIST_REQUEST";
export const PURCHASE_ORDER_HISTORY_LIST_SUCCESS = "PURCHASE_ORDER_HISTORY_LIST_SUCCESS";
export const PURCHASE_ORDER_MERGE_REQUEST = "PURCHASE_ORDER_MERGE_REQUEST";
export const PURCHASE_ORDER_SPLIT_REQUEST = "PURCHASE_ORDER_SPLIT_REQUEST";
export const PURCHASE_ORDER_AUTOCOMPLETE_REQUEST = "PURCHASE_ORDER_AUTOCOMPLETE_REQUEST";
export const PURCHASE_ORDER_UPDATE_REFERENCE_REQUEST = "PURCHASE_ORDER_UPDATE_REFERENCE_REQUEST";
export const PURCHASE_ORDER_UPDATE_NOTE_REQUEST = "PURCHASE_ORDER_UPDATE_REFERENCE_REQUEST";

export const PURCHASE_ORDER_APPROVE_REQUEST = "PURCHASE_ORDER_APPROVE_REQUEST";
export const PURCHASE_ORDER_REJECT_REQUEST = "PURCHASE_ORDER_REJECT_REQUEST";
export const PURCHASE_ORDER_POST_REQUEST = "PURCHASE_ORDER_POST_REQUEST";
export const PURCHASE_ORDER_RECEIVE_REQUEST = "PURCHASE_ORDER_RECEIVE_REQUEST";
export const PURCHASE_ORDER_SET_TO_DRAFT_REQUEST = "PURCHASE_ORDER_SET_TO_DRAFT_REQUEST";

export const PURCHASE_ORDER_MERGE_FORM_NAME = "mergePurchaseOrder";
export const PURCHASE_ORDER_SPLIT_FORM_NAME = "splitPurchaseOrder";

export const PURCHASE_ORDER_UPDATE_REFERENCE_FORM_NAME = "updatePurchaseOrderReference";
export const PURCHASE_ORDER_UPDATE_NOTE_FORM_NAME = "updatePurchaseOrderNote";

export const PURCHASE_ORDER_SUBMIT_RULES = {
  name: {
    required: true,
  },
  supplierId: {
    required: true,
  },
  purchaseOrderTypeId: {
    required: true,
  },
  purchaseOrderItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "itemId"
      },
      {
        name: "quantity",
        type: "number",
      },
    ],
  },
};

export const READ_PURCHASE_ORDER = 189000;
export const CREATE_PURCHASE_ORDER = 189001;
export const UPDATE_PURCHASE_ORDER = 189002;
export const ARCHIVE_PURCHASE_ORDER = 189004;
export const DELETE_PURCHASE_ORDER = 189003;
export const UNARCHIVE_PURCHASE_ORDER = 189005;
export const PRINT_PURCHASE_ORDER = 189007;
export const SUBMIT_PURCHASE_ORDER = 189011;
export const APPROVE_PURCHASE_ORDER = 189012;
export const REJECT_PURCHASE_ORDER = 189013;
export const RECEIVE_PURCHASE_ORDER = 189014;
export const CANCEL_PURCHASE_ORDER = 189015;
export const CLOSE_PURCHASE_ORDER = 189016;
export const VOID_PURCHASE_ORDER = 189017;
export const PAY_PURCHASE_ORDER = 189018;
export const PROCESS_PURCHASE_ORDER = 189019;
export const VERIFY_PURCHASE_ORDER = 25010;
export const SET_TO_DRAFT_PURCHASE_ORDER = 189021;
export const VIEW_PURCHASE_ORDER_HISTORY = 189022;
export const MERGE_PURCHASE_ORDER = 189023;
export const SPLIT_PURCHASE_ORDER = 189024;
export const UPDATE_PURCHASE_ORDER_REFERENCE = 189034;

export const PURCHASE_ORDER_STATUS_DRAFT = 1;
export const PURCHASE_ORDER_STATUS_DRAFT_TITLE = "Draft";
export const PURCHASE_ORDER_STATUS_SUBMITTED = 2;
export const PURCHASE_ORDER_STATUS_SUBMITTED_TITLE = "Submitted";
export const PURCHASE_ORDER_STATUS_APPROVED = 3;
export const PURCHASE_ORDER_STATUS_APPROVED_TITLE = "Approved";
export const PURCHASE_ORDER_STATUS_REJECTED = 4;
export const PURCHASE_ORDER_STATUS_REJECTED_TITLE = "Rejected";
export const PURCHASE_ORDER_STATUS_PROCESSING = 5;
export const PURCHASE_ORDER_STATUS_PROCESSING_TITLE = "Processing";
export const PURCHASE_ORDER_STATUS_CANCELLED = 6;
export const PURCHASE_ORDER_STATUS_CANCELLED_TITLE = "Cancelled";
export const PURCHASE_ORDER_STATUS_CLOSED = 7;
export const PURCHASE_ORDER_STATUS_CLOSED_TITLE = "Closed";
export const PURCHASE_ORDER_STATUS_VOIDED = 8;
export const PURCHASE_ORDER_STATUS_VOIDED_TITLE = "Voided";
export const PURCHASE_ORDER_STATUS_PAID = 9;
export const PURCHASE_ORDER_STATUS_PAID_TITLE = "Paid";

export const PURCHASE_ORDER_STATUS_DATASOURCE = [
  {
    id: PURCHASE_ORDER_STATUS_DRAFT,
    name: PURCHASE_ORDER_STATUS_DRAFT_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_SUBMITTED,
    name: PURCHASE_ORDER_STATUS_SUBMITTED_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_APPROVED,
    name: PURCHASE_ORDER_STATUS_APPROVED_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_REJECTED,
    name: PURCHASE_ORDER_STATUS_REJECTED_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_PROCESSING,
    name: PURCHASE_ORDER_STATUS_PROCESSING_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_CANCELLED,
    name: PURCHASE_ORDER_STATUS_CANCELLED_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_CLOSED,
    name: PURCHASE_ORDER_STATUS_CLOSED_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_VOIDED,
    name: PURCHASE_ORDER_STATUS_VOIDED_TITLE,
  },
  {
    id: PURCHASE_ORDER_STATUS_PAID,
    name: PURCHASE_ORDER_STATUS_PAID_TITLE,
  }
];
export const PROCESS_PURCHASE_ORDER_ITEM_SUBMIT_RULES = {
  warehouseId: {
    required: true,
  },
  purchaseOrderItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "itemCost",
        min: 0,
        type: "number",
      },
      {
        name: "receivedQuantity",
        min: 0,
        type: "number",
      },
    ]
  },
  purchaseOrderShipments: {
    required: false,
    type: "arrayInline",
    subFields: [
      {
        name: "shipperId"
      },
      {
        name: "cost",
      },
    ],
  },
};

export const PAY_BATCH_SUBMIT_RULES_PURCHASE_ORDER = {
  supplierId: {
    required: true,
  },
  purchaseOrderIds: {
    required: true,
    type: "array",
    message: "Please select at least 1 purchase order"
  },
};

export const PURCHASE_ORDER_MODAL_TYPE_PAY = 1;
export const PURCHASE_ORDER_MODAL_TYPE_PAY_TITLE = "Pay Purchase Order";
export const PURCHASE_ORDER_MODAL_TYPE_HISTORY = 2;
export const PURCHASE_ORDER_MODAL_TYPE_HISTORY_TITLE = "Purchase Order history";
export const PURCHASE_ORDER_MODAL_TYPE_SPLIT = 3;
export const PURCHASE_ORDER_MODAL_TYPE_SPLIT_TITLE = "Split Purchase Order";
export const PURCHASE_ORDER_MODAL_TYPE_MERGE = 4;
export const PURCHASE_ORDER_MODAL_TYPE_MERGE_TITLE = "Merge Purchase Order";
export const PURCHASE_ORDER_MODAL_TYPE_RECEIVE = 5;
export const PURCHASE_ORDER_MODAL_TYPE_RECEIVE_TITLE = "Receive Purchase oder";

export const PURCHASE_ORDER_MERGE_SUBMIT_RULES = {
  purchaseOrderIds: {
    required: true,
    type: "array"
  }
};

export const PURCHASE_ORDER_SPLIT_SUBMIT_RULES = {
  purchaseOrderItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "splitQuantity",
        type: "number",
        min: 1
      },
    ],
  }
};