import { Badge } from "antd";
import * as Constants from "../constants";

export const renderEmployeeStatus = (id) => {
  let status = null;
  switch(id) {
  case Constants.EMPLOYEE_STATUS_ACTIVE:
    status = <Badge status="success" text={Constants.EMPLOYEE_STATUS_ACTIVE_TITLE} />;
    break;
  case Constants.EMPLOYEE_STATUS_TERMINATE:
    status = <Badge status="error" text={Constants.EMPLOYEE_STATUS_TERMINATE_TITLE} />;
    break;
  default:
    status;
  }
  return status;
};

export const createEmployeeSubmitRule = (values) => {
  const { employeeCodeNoSeriesList } = values;
  const rules = {
    title: {
      required: true
    },
    firstName: {
      required: true
    },
    lastName: {
      required: true
    },
    code: {
      required: employeeCodeNoSeriesList == Constants.EMPLOYEE_CODE_TYPE_MANUALLY_ID
    },
    dateOfBirth: {
      required: true
    },
    gender: {
      required: true
    },
    joiningDate: {
      required: true
    },
  };
  return rules;
};

export const editEmployeeSubmitRule = (values) => {
  const { employeeCodeNoSeriesList } = values;
  const rules = {
    title: {
      required: true
    },
    firstName: {
      required: true
    },
    lastName: {
      required: true
    },
    code: {
      required: employeeCodeNoSeriesList == Constants.EMPLOYEE_CODE_TYPE_MANUALLY_ID
    },
    dateOfBirth: {
      required: true
    },
    gender: {
      required: true
    },
    joiningDate: {
      required: true
    },
    personalEmail: {
      type: "email"
    },
  };
  return rules;
};

export const renderModalTitle = (id) => {
  let title = null;
  switch (id) {
  case Constants.EMPLOYEE_EDUCATION_MODAL_ID:
    title = Constants.EMPLOYEE_EDUCATION_MODAL_TITLE;
    break;
  case Constants.EMPLOYEE_WORK_EXPERIENCE_MODAL_ID:
    title = Constants.EMPLOYEE_WORK_EXPERIENCE_MODAL_TITLE;
    break;
  case Constants.EMPLOYEE_LANGUAGE_MODAL_ID:
    title = Constants.EMPLOYEE_LANGUAGE_MODAL_TITLE;
    break;
  default:
    title = null;
  }
  return title;
};

export const renderStatusLanguage = (id) => {
  let title = null;
  switch (id) {
  case Constants.LANGUAGE_STATUS_BAD:
    title = Constants.LANGUAGE_STATUS_BAD_TITLE;
    break;
  case Constants.LANGUAGE_STATUS_GOOD:
    title = Constants.LANGUAGE_STATUS_GOOD_TITLE;
    break;
  case Constants.LANGUAGE_STATUS_AVERAGE:
    title = Constants.LANGUAGE_STATUS_AVERAGE_TITLE;
    break;
  case Constants.LANGUAGE_STATUS_EXCELLENT:
    title = Constants.LANGUAGE_STATUS_EXCELLENT_TITLE;
    break;
  default:
    title = null;
  }
  return title;
};

export const renderIdentificationType = (value) => {
  let type = "";
  switch(value) {
  case 1:
    type = "National Identification Card";
    break;
  case 2:
    type = "Passport";
    break;
  case 3:
    type = "Other";
    break;
  default:
    type = "";
  }
  return type;
};

export const renderSalaryTypeTitle = (id) => {
  let status = null;
  switch(id) {
  case Constants.EMPLOYEE_PAYROLL_SALARY_TYPE_MONTHLY:
    status = Constants.EMPLOYEE_PAYROLL_SALARY_TYPE_MONTHLY_TITLE;
    break;
  case Constants.EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY:
    status = Constants.EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY_TITLE;
    break;
  default:
    status;
  }
  return status;
};

export const renderPaymentMethodTypeTitle = (id) => {
  let status = null;
  switch(id) {
  case Constants.EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK:
    status = Constants.EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK_TITLE;
    break;
  case Constants.EMPLOYEE_PAYROLL_PAYMENT_METHOD_TYPE_CASH:
    status = Constants.EMPLOYEE_PAYROLL_PAYMENT_METHOD_TYPE_CASH_TITLE;
    break;
  default:
    status;
  }
  return status;
};

export const payrollSubmitRules = (values) => {
  const { paymentMethod, salaryType, enabledIncentive } = values;
  const rules = {
    salaryType: {
      required: true
    },
    salaryStructureId: {
      required: true
    },
    salary: {
      required: true
    },
    paymentMethod: {
      required: true
    },
    currencyId: {
      required: true
    },
    bankAccountNo: {
      required: paymentMethod == Constants.EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK
    },
    workingDayId: {
      required: salaryType == Constants.EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY
    },
    saleCommissionId: {
      required: enabledIncentive,
    },
    employeePayrollIncentives: {
      required: enabledIncentive,
      type: "arrayInline",
      subFields: [
        {
          name: "name"
        },
        {
          name: "percentage",
        },
      ]
    }
  };
  return rules;
};