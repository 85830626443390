// Utils
import { 
  WarehouseSchema,
  WarehouseArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

const path = "/inventory/stock/warehouse";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getWarehouse,
    schema: WarehouseArraySchema,
    requestName: Constants.WAREHOUSE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getWarehouseAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getWarehouseAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const createWarehouse = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createWarehouse,
    requestName: Constants.WAREHOUSE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getWarehouseDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getWarehouseDetail,
    requestName: Constants.WAREHOUSE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: WarehouseSchema,
  };
  return createDetailAction(options, id);
};

export const editWarehouse = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editWarehouse,
    requestName: Constants.WAREHOUSE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "warehouses",
    schema: WarehouseSchema,
    successMessage: "Warehouse is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveWarehouse = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveWarehouse,
    requestName: Constants.WAREHOUSE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Warehouse is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteWarehouse = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteWarehouse,
    requestName: Constants.WAREHOUSE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Warehouse is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveWarehouse = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveWarehouse,
    requestName: Constants.WAREHOUSE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Warehouse is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};