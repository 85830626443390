export const GENERAL_SETTING_SUBMIT_RULES = {
  language: {
    required: true
  },
  currency: {
    type: "object",
    value: {
      required: true
    }
  },
};

export const READ_GENERAL_SETTING = 104011;
export const UPDATE_GENERAL_SETTING = 104012;

export const READ_LOG_IN_APPROVAL_SETTING = 241013;
export const UPDATE_LOG_IN_APPROVAL_SETTING = 241014;

export const GENERAL_SETTING_PATH = "/settings/setup/general-setting";
