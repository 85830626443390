import React from "react";
import AutoComplete from "../../autocomplete-static";

class IdentificationTypeAutoComplete extends React.Component {

  render() {
    const dataSource = [
      {
        id: 1,
        name: "National Identification Card"
      },
      {
        id: 2,
        name: "Passport"
      },
      {
        id: 3,
        name: "Other"
      }
    ];
    return (
      <AutoComplete 
        required
        stringValue
        dataSource={dataSource}
        name="identificationType"
        label="Identification Type"
      />
    );
  }
}

export default IdentificationTypeAutoComplete;
