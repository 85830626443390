export const REPORT_TEMPLATE_LIST_REQUEST = "REPORT_TEMPLATE_LIST_REQUEST";
export const REPORT_TEMPLATE_LIST_SUCCESS = "REPORT_TEMPLATE_LIST_REQUEST_SUCCESS";
export const REPORT_TEMPLATE_AUTOCOMPLETE_REQUEST = "REPORT_TEMPLATE_AUTOCOMPLETE_REQUEST";
export const REPORT_TEMPLATE_AUTOCOMPLETE_SUCCESS = "REPORT_TEMPLATE_AUTOCOMPLETE_SUCCESS";
export const REPORT_TEMPLATE_TYPE_AUTOCOMPLETE_REQUEST = "REPORT_TEMPLATE_TYPE_AUTOCOMPLETE_REQUEST";
export const REPORT_TEMPLATE_CREATE_REQUEST = "REPORT_TEMPLATE_CREATE_REQUEST";
export const REPORT_TEMPLATE_DETAIL_REQUEST = "REPORT_TEMPLATE_DETAIL_REQUEST";
export const REPORT_TEMPLATE_ARCHIVE_REQUEST = "REPORT_TEMPLATE_ARCHIVE_REQUEST";
export const REPORT_TEMPLATE_DELETE_REQUEST = "REPORT_TEMPLATE_DELETE_REQUEST";
export const REPORT_TEMPLATE_UNARCHIVE_REQUEST = "REPORT_TEMPLATE_UNARCHIVE_REQUEST";
export const REPORT_TEMPLATE_EDIT_REQUEST = "REPORT_TEMPLATE_EDIT_REQUEST";
export const REPORT_TEMPLATE_CREATE_FORM_NAME = "createReportTemplate";
export const REPORT_TEMPLATE_EDIT_FORM_NAME = "editReportTemplate";

export const READ_REPORT_TEMPLATE = 118000;
export const CREATE_REPORT_TEMPLATE = 118001;
export const UPDATE_REPORT_TEMPLATE = 118002;
export const DELETE_REPORT_TEMPLATE = 118003;
export const ARCHIVE_REPORT_TEMPLATE = 118004;
export const UNARCHIVE_REPORT_TEMPLATE = 118005;

export const REPORT_TEMPLATE_PAGE_FORMAT_DATASOURCE = [
  {
    id: 1,
    name: "A3",
  },
  {
    id: 2,
    name: "A4",
  },
  {
    id: 3,
    name: "A5",
  },
  {
    id: 4,
    name: "Legal",
  },
  {
    id: 5,
    name: "Letter",
  },
  {
    id: 6,
    name: "Tabloid",
  },
  {
    id: 7,
    name: "Custom",
  },
];

export const REPORT_TEMPLATE_PAGE_FORMAT_CUSTOM = "Custom";

export const REPORT_TEMPLATE_ORIENTATION_DATASOURCE = [
  {
    name: "Portrait",
    value: "portrait",
  },
  {
    name: "Landscape",
    value: "landscape",
  }, 
];

export const REPORT_TEMPLATE_PAGE_SETUP_POSITION_VERTICAL_DATASOURCE = [
  { 
    id: 1, 
    name: "Top",
  }, 
  { 
    id: 2, 
    name: "Bottom",
  }, 
];

export const REPORT_TEMPLATE_PAGE_SETUP_POSITION_HORIZONTAL_DATASOURCE = [
  { 
    id: 1, 
    name: "Left",
  }, 
  { 
    id: 2, 
    name: "Center",
  }, 
  { 
    id: 3, 
    name: "Right",
  }, 
];


export const REPORT_TEMPLATE_FORMAT_PDF = "pdf";
export const REPORT_TEMPLATE_FORMAT_PDF_TITLE = "PDF";
export const REPORT_TEMPLATE_FORMAT_EXCEL = "xlsx";
export const REPORT_TEMPLATE_FORMAT_EXCEL_TITLE = "Excel";

export const REPORT_TEMPLATE_FORMAT_DATA_SOURCE = [
  {
    id: REPORT_TEMPLATE_FORMAT_PDF,
    name: REPORT_TEMPLATE_FORMAT_PDF_TITLE
  },
  {
    id: REPORT_TEMPLATE_FORMAT_EXCEL,
    name: REPORT_TEMPLATE_FORMAT_EXCEL_TITLE
  },
];

export const REPORT_TEMPLATE_ENGINE_HANDLEBARS = "handlebars";
export const REPORT_TEMPLATE_ENGINE_HANDLEBARS_TITLE = "Handlebars";
export const REPORT_TEMPLATE_ENGINE_REACT = "react";
export const REPORT_TEMPLATE_ENGINE_REACT_TITLE = "React";
export const REPORT_TEMPLATE_ENGINE_CARBONE = "carbone";
export const REPORT_TEMPLATE_ENGINE_CARBONE_TITLE = "Carbone";
export const REPORT_TEMPLATE_ENGINE_STIMULSOFT = "stimulsoft";
export const REPORT_TEMPLATE_ENGINE_STIMULSOFT_TITLE = "Stimulsoft";


export const REPORT_TEMPLATE_ENGINE_DATA_SOURCE = [
  {
    id: REPORT_TEMPLATE_ENGINE_HANDLEBARS,
    name: REPORT_TEMPLATE_ENGINE_HANDLEBARS_TITLE
  },
  {
    id: REPORT_TEMPLATE_ENGINE_REACT,
    name: REPORT_TEMPLATE_ENGINE_REACT_TITLE,
  },
  {
    id: REPORT_TEMPLATE_ENGINE_CARBONE,
    name: REPORT_TEMPLATE_ENGINE_CARBONE_TITLE
  },
  {
    id: REPORT_TEMPLATE_ENGINE_STIMULSOFT,
    name: REPORT_TEMPLATE_ENGINE_STIMULSOFT_TITLE
  },
];