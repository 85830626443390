import { 
  AssetSchema,
  AssetArraySchema, 
} from "../schema";
import {
  createListAction,
  createEntityActionAction
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getAsset,
    schema: AssetArraySchema,
    requestName: Constants.ASSET_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const transferAsset = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.transferAsset,
    requestName: Constants.ASSET_TRANSFER_REQUEST,
    dispatch,
    getState,
    schema: AssetSchema,
    entityName: "assets",
    successMessage: "Asset transfer successfully!",
  };
  return createEntityActionAction(options, values);
};

export const assetBackToStock  = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.assetBackToStock,
    requestName: Constants.ASSET_BACK_TO_STOCK_REQUEST,
    dispatch,
    getState,
    schema: AssetSchema,
    entityName: "assets",
    successMessage: "Asset has been return to stock successfully!",
  };
  return createEntityActionAction(options, values);
};