import React from "react";
import PropTypes from "prop-types";

// libs
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { Table, Button } from "antd";
import { withRouter } from "react-router-dom";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

// utils
import { confirmDeleteModal } from "@modules/helpers";
import { deleteEducation, getEducation } from "../../../actions";
import { EducationArraySchema } from "../../../schema/employee-education";
import { EDUCATION_REQUEST, EMPLOYEE_EDUCATION_MODAL_ID } from "../../../constants";

class EmployeeEducation extends React.Component {

  constructor(props) {
    super(props);
    this.getEmployeeEducation();
  }

  getEmployeeEducation() {
    const { 
      match: { params }, 
      getEmployeeEducation
    } = this.props;
    getEmployeeEducation(params.id);
  }

  confirmDelete = (id) => {
    const { 
      deleteEducation, 
      match: { params } 
    } = this.props;
    const values = {
      id,
      employeeId: params.id,
    };
    confirmDeleteModal(
      values,
      "education",
      deleteEducation
    );
  }

  columns = () => {
    const { openModalEdit } = this.props;
    return [
      {
        title: "School / University",
        dataIndex: "school",
      },
      {
        title: "From Year",
        dataIndex: "from",
      },
      {
        title: "To Year",
        dataIndex: "to",
      },
      {
        title: "Degree",
        dataIndex: "degree",
      },
      {
        title: "Major",
        dataIndex: "major",
      },
      {
        title: "Actions",
        render: (_, values) => {
          return (
            <div className="align-item-center justify-content-center">
              <EditOutlined 
                onClick={() => openModalEdit(values, EMPLOYEE_EDUCATION_MODAL_ID)} 
              />
              <DeleteOutlined 
                className="ml-2"
                onClick={() => this.confirmDelete(values.id)} 
              />
            </div>
          );
        }
      },
    ];
  };

  render() {
    const { 
      loading,
      entities,
      openModalCreate,
      match: { params },
      employeeEducation,
    } = this.props;
    const educationEntities = denormalize(
      employeeEducation[params.id], 
      EducationArraySchema, 
      entities
    );
    return (
      <Table 
        size="small"
        loading={loading}
        pagination={false}
        columns={this.columns()} 
        dataSource={educationEntities} 
        footer={() => {
          return (
            <Button 
              ghost
              size="small"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => openModalCreate(EMPLOYEE_EDUCATION_MODAL_ID)}
            >
              Add New
            </Button>
          );
        }}
      />
    );
  }
}

EmployeeEducation.propTypes = {
  match: PropTypes.any,
  loading: PropTypes.bool,
  entities: PropTypes.object,
  openModalEdit: PropTypes.func,
  deleteEducation: PropTypes.func,
  openModalCreate: PropTypes.func,
  employeeEducation: PropTypes.object,
  getEmployeeEducation: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { employeeEducation, entities, request } = state;
  return { 
    entities,
    employeeEducation,
    loading: request[EDUCATION_REQUEST],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeEducation: (id) => dispatch(getEducation(id)),
    deleteEducation: (id, employeeId) => dispatch(deleteEducation(id, employeeId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeEducation));