export const SUPPLIER_DEPOSIT_LIST_REQUEST = "SUPPLIER_DEPOSIT_LIST_REQUEST";
export const SUPPLIER_DEPOSIT_LIST_SUCCESS = "SUPPLIER_DEPOSIT_LIST_REQUEST_SUCCESS";
export const SUPPLIER_DEPOSIT_AUTOCOMPLETE_REQUEST = "SUPPLIER_DEPOSIT_AUTOCOMPLETE_REQUEST";
export const SUPPLIER_DEPOSIT_AUTOCOMPLETE_SUCCESS = "SUPPLIER_DEPOSIT_AUTOCOMPLETE_SUCCESS";
export const SUPPLIER_DEPOSIT_CREATE_REQUEST = "SUPPLIER_DEPOSIT_CREATE_REQUEST";
export const SUPPLIER_DEPOSIT_DETAIL_REQUEST = "SUPPLIER_DEPOSIT_DETAIL_REQUEST";
export const SUPPLIER_DEPOSIT_ARCHIVE_REQUEST = "SUPPLIER_DEPOSIT_ARCHIVE_REQUEST";
export const SUPPLIER_DEPOSIT_DELETE_REQUEST = "SUPPLIER_DEPOSIT_DELETE_REQUEST";
export const SUPPLIER_DEPOSIT_UNARCHIVE_REQUEST = "SUPPLIER_DEPOSIT_UNARCHIVE_REQUEST";
export const SUPPLIER_DEPOSIT_EDIT_REQUEST = "SUPPLIER_DEPOSIT_EDIT_REQUEST";
export const SUPPLIER_DEPOSIT_CREATE_FORM_NAME = "createSupplierDeposit";
export const SUPPLIER_DEPOSIT_EDIT_FORM_NAME = "editSupplierDeposit";
export const SUPPLIER_DEPOSIT_POST_FORM_NAME = "postSupplierDeposit";

export const READ_SUPPLIER_DEPOSIT = 188000;
export const CREATE_SUPPLIER_DEPOSIT = 188001;
export const UPDATE_SUPPLIER_DEPOSIT = 188002;
export const ARCHIVE_SUPPLIER_DEPOSIT = 188004;
export const UNARCHIVE_SUPPLIER_DEPOSIT = 188005;
export const DELETE_SUPPLIER_DEPOSIT = 188003;
export const PRINT_SUPPLIER_DEPOSIT = 188007;
export const POST_SUPPLIER_DEPOSIT = 188011;

export const SUPPLIER_DEPOSIT_POST_REQUEST = "SUPPLIER_DEPOSIT_POST_REQUEST";

export const SUPPLIER_DEPOSIT_HISTORY_LIST_REQUEST = "SUPPLIER_DEPOSIT_LIST_REQUEST";
export const SUPPLIER_DEPOSIT_HISTORY_LIST_SUCCESS = "SUPPLIER_DEPOSIT_LIST_SUCCESS";

export const SUPPLIER_DEPOSIT_STATUS_DRAFT = 1;
export const SUPPLIER_DEPOSIT_STATUS_DRAFT_TITLE = "Draft";
export const SUPPLIER_DEPOSIT_STATUS_POSTED = 2;
export const SUPPLIER_DEPOSIT_STATUS_POSTED_TITLE = "Posted";

export const SUPPLIER_DEPOSIT_STATUS = [
  {
    id: SUPPLIER_DEPOSIT_STATUS_DRAFT,
    name: SUPPLIER_DEPOSIT_STATUS_DRAFT_TITLE
  },
  {
    id: SUPPLIER_DEPOSIT_STATUS_POSTED,
    name: SUPPLIER_DEPOSIT_STATUS_POSTED_TITLE,
  },
];