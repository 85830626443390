import { CUSTOMER_DEPOSIT_HISTORY_LIST_SUCCESS } from "../constants";

export default function (state = {}, action) {
  switch (action.type) {
  case CUSTOMER_DEPOSIT_HISTORY_LIST_SUCCESS:
    return {
      ...state,
      [action.id]: action.payload
    };
  default:
    return state;
  }
}