import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";
import { getCustomerAutoComplete } from "@sale/customer-center/customer/actions";
import { CUSTOMER_AUTOCOMPLETE_REQUEST } from "@sale/customer-center/customer/constants";

class AsyncCustomerAutoComplete extends React.Component {

  render() {
    const { name, customerCompany, requestName, action, ...props } = this.props;
    let extra = {
      includes: ["customerCompany"],
    };
    if (customerCompany) {
      extra = Object.assign(extra, {customerCompany});
    } 
    return (
      <AsyncSelect 
        name={name}
        action={action}
        requestName={requestName}
        placeholder="Customer"
        extra={extra}
        optionRender={(values) => {
          if (values.customerCompany != null) {
            return `${values.firstName} ${values.lastName || ""} - ${values.customerCompany.name}`;
          }
          return `${values.firstName} ${values.lastName || ""}`;
        }}
        {...props}
      />
    );
  }
}

AsyncCustomerAutoComplete.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  requestName: PropTypes.string,
  customerCompany: PropTypes.array,
};

AsyncCustomerAutoComplete.defaultProps = {
  name: "customer",
  action: getCustomerAutoComplete,
  requestName: CUSTOMER_AUTOCOMPLETE_REQUEST,
};

export default withRouter(AsyncCustomerAutoComplete);