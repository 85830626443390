import * as PathConstants from "@/constants/notification";

export const ITEM_LIST_REQUEST = "ITEM_LIST_REQUEST";
export const ITEM_LIST_SUCCESS = "ITEM_LIST_REQUEST_SUCCESS";
export const ITEM_AUTOCOMPLETE_REQUEST = "ITEM_AUTOCOMPLETE_REQUEST";
export const ITEM_AUTOCOMPLETE_SUCCESS = "ITEM_AUTOCOMPLETE_SUCCESS";
export const ITEM_CREATE_REQUEST = "ITEM_CREATE_REQUEST";
export const ITEM_DETAIL_REQUEST = "ITEM_DETAIL_REQUEST";
export const ITEM_ARCHIVE_REQUEST = "ITEM_ARCHIVE_REQUEST";
export const ITEM_DELETE_REQUEST = "ITEM_DELETE_REQUEST";
export const ITEM_UNARCHIVE_REQUEST = "ITEM_UNARCHIVE_REQUEST";
export const ITEM_EDIT_REQUEST = "ITEM_EDIT_REQUEST";
export const ITEM_CREATE_FORM_NAME = "createItem";
export const ITEM_EDIT_FORM_NAME = "editItem";
export const ITEM_MEDIA_EDIT_FORM_NAME = "itemMediaForm";
export const ITEM_IN_STOCK_LIST_REQUEST = "ITEM_LIST_REQUEST";
export const ITEM_IN_STOCK_LIST_SUCCESS = "ITEM_LIST_SUCCESS";
export const ITEM_RESERVE_LOG_REQUEST = "ITEM_RESERVE_LOG_REQUEST";
export const ITEM_RESERVE_LOG_SUCCESS = "ITEM_RESERVE_LOG_SUCCESS";
export const ITEM_MODAL_GENERATE_BARCODE_FORM_NAME = "modalGenerateBarcode";
export const ITEM_MODAL_PREVIEW_BARCODE_FORM_NAME = "modalPreviewBarcode";
export const ITEM_TYPE_ASSEMBLY_FIELD_NAME = "itemAssemblies";
export const ITEM_VARIANT_FIELD_NAME = "generateVariant";

export const ITEM_MODAL_TYPE_GENERATE_BARCODE = 1;
export const ITEM_MODAL_TYPE_GENERATE_BARCODE_TITLE = "Generate";
export const ITEM_MODAL_TYPE_PREVIEW_BARCODE = 2; 
export const ITEM_MODAL_TYPE_PREVIEW_BARCODE_TITLE = "Preview"; 

export const COSTING_LOG_REQUEST = "COSTING_LOG_REQUEST";
export const COSTING_LOG_SUCCESS = "COSTING_LOG_SUCCESS";
export const ITEM_VARIANT_REQUEST = "ITEM_VARIANT_REQUEST";
export const ITEM_VARIANT_SUCCESS = "ITEM_VARIANT_REQUEST_SUCCESS";
export const ITEM_GENERATE_VARIANT_REQUEST = "ITEM_GENERATE_VARIANT_REQUEST";

export const READ_ITEM = 204000;
export const CREATE_ITEM = 204001;
export const UPDATE_ITEM = 204002;
export const ARCHIVE_ITEM = 204004;
export const DELETE_ITEM = 204003;
export const UNARCHIVE_ITEM = 204005;
export const VIEW_ITEM_IN_STOCK = 204011;
export const GENERATE_VARIANT_ITEM_CODE = 204012;
export const ASSIGN_ITEM_CHART_OF_ACCOUNT = 204013;
export const VIEW_ITEM_CHART_OF_ACCOUNT = 204014;
export const VIEW_ITEM_COST = 204016;
export const ASSIGN_ITEM_VALUATION_METHOD = 204019;
export const VIEW_ITEM_COSTING_LOG = 204020;
export const VIEW_ITEM_RESERVE_QUANTITY_LOG = 204021;

export const ITEM_TYPE_SERVICE_ID = 1;
export const ITEM_TYPE_SERVICE_NAME = "Service";
export const ITEM_TYPE_ASSEMBLY_ID = 2;
export const ITEM_TYPE_ASSEMBLY_NAME = "Assembly";
export const ITEM_TYPE_NON_INVENTORY_ID = 3;
export const ITEM_TYPE_NON_INVENTORY_NAME = "Non Inventory";
export const ITEM_TYPE_INVENTORY_PART_ID = 4;
export const ITEM_TYPE_INVENTORY_PART_NAME = "Inventory";
export const ITEM_TYPE_SUBSCRIPTION_ID = 5;
export const ITEM_TYPE_SUBSCRIPTION_NAME = "Subscription";

export const ITEM_RESERVE_TYPE_STOCK_TRANSFER_ID = 1;
export const ITEM_RESERVE_TYPE_STOCK_TRANSFER = "Stock Transfer";
export const ITEM_RESERVE_TYPE_STOCK_TRANSFER_LINK = `${PathConstants.STOCK_PATH}/stock-transfer`;

export const ITEM_RESERVE_TYPE_STOCK_OUT_ID = 2;
export const ITEM_RESERVE_TYPE_STOCK_OUT = "Stock Out";
export const ITEM_RESERVE_TYPE_STOCK_OUT_LINK = `${PathConstants.STOCK_PATH}/stock-out`;

export const ITEM_RESERVE_TYPE_JUNK_OUT_ID = 3;
export const ITEM_RESERVE_TYPE_JUNK_OUT = "Junk Out";
export const ITEM_RESERVE_TYPE_JUNK_OUT_LINK = `${PathConstants.STOCK_PATH}/junk-out`;

export const ITEM_RESERVE_TYPE_SALE_ORDER_ID = 4;
export const ITEM_RESERVE_TYPE_SALE_ORDER = "Sale Order";
export const ITEM_RESERVE_TYPE_SALE_ORDER_LINK = `${PathConstants.SALE_LIST_PATH}/sale-order`;

export const ITEM_RESERVE_TYPE_QUOTATION_ID = 5;
export const ITEM_RESERVE_TYPE_QUOTATION = "Quotation";
export const ITEM_RESERVE_TYPE_QUOTATION_LINK = `${PathConstants.SALE_LIST_PATH}/quotation`;

export const ITEM_TYPE_DATA_SOURCE = [
  {
    id: ITEM_TYPE_INVENTORY_PART_ID,
    name: ITEM_TYPE_INVENTORY_PART_NAME
  },
  {
    id: ITEM_TYPE_SERVICE_ID,
    name: ITEM_TYPE_SERVICE_NAME
  },
  {
    id: ITEM_TYPE_ASSEMBLY_ID,
    name: ITEM_TYPE_ASSEMBLY_NAME
  },
  {
    id: ITEM_TYPE_NON_INVENTORY_ID,
    name: ITEM_TYPE_NON_INVENTORY_NAME
  },
  {
    id: ITEM_TYPE_SUBSCRIPTION_ID,
    name: ITEM_TYPE_SUBSCRIPTION_NAME
  },
];

export const VALUATION_METHOD_TYPE_AVERAGE = 1;
export const VALUATION_METHOD_TYPE_AVERAGE_TITLE = "Average";
export const VALUATION_METHOD_TYPE_FIFO = 2;
export const VALUATION_METHOD_TYPE_FIFO_TITLE = "FIFO";

export const ITEM_VALUATION_METHOD_TYPE = [
  {
    id: VALUATION_METHOD_TYPE_AVERAGE,
    name: VALUATION_METHOD_TYPE_AVERAGE_TITLE,
  },
  {
    id: VALUATION_METHOD_TYPE_FIFO,
    name: VALUATION_METHOD_TYPE_FIFO_TITLE,
  },
];

export const ITEM_GENERATE_BARCODE_TYPE_BARCODE_128_ID = 1;
export const ITEM_GENERATE_BARCODE_TYPE_BARCODE_128_NAME = "Barcode 128";
export const ITEM_GENERATE_BARCODE_TYPE_QR_CODE_ID = 2;
export const ITEM_GENERATE_BARCODE_TYPE_QR_CODE_NAME = "QR Code";
export const ITEM_GENERATE_BARCODE_TYPE_DATASOURCE = [
  {
    id: ITEM_GENERATE_BARCODE_TYPE_BARCODE_128_ID,
    name: ITEM_GENERATE_BARCODE_TYPE_BARCODE_128_NAME
  },
  {
    id: ITEM_GENERATE_BARCODE_TYPE_QR_CODE_ID,
    name: ITEM_GENERATE_BARCODE_TYPE_QR_CODE_NAME
  },
];

export const ITEM_BARCODE_PRINT_TYPE_DEFAULT_ID = 1;
export const ITEM_BARCODE_PRINT_TYPE_DEFAULT_NAME = "Default";
export const ITEM_BARCODE_PRINT_TYPE_LABEL_PRINTER_ID = 2;
export const ITEM_BARCODE_PRINT_TYPE_LABEL_PRINTER_NAME = "Label Printer";
export const ITEM_BARCODE_PRINT_TYPE_DATASOURCE = [
  {
    id: ITEM_BARCODE_PRINT_TYPE_DEFAULT_ID,
    name: ITEM_BARCODE_PRINT_TYPE_DEFAULT_NAME
  },
  {
    id: ITEM_BARCODE_PRINT_TYPE_LABEL_PRINTER_ID,
    name: ITEM_BARCODE_PRINT_TYPE_LABEL_PRINTER_NAME
  },
];

export const ITEM_STOCK_LOG_MODAL = 1;
export const ITEM_RESERVE_LOG_MODAL = 2;
export const ITEM_COSTING_LOG_MODAL = 3;
export const ITEM_VARIANT_MODAL = 4;
export const ITEM_COSTING_MODAL = 5;

export const ITEM_STOCK_LOG_MODAL_TITLE = "Available Stock";
export const ITEM_RESERVE_LOG_MODAL_TITLE = "Reserve Quantity Log";
export const ITEM_COSTING_LOG_MODAL_TITLE = "Costing Log";
export const ITEM_VARIANT_MODAL_TITLE = "Generate Variant";
export const ITEM_COSTING_MODAL_TITLE = "Costing";

export const ITEM_MEDIA_TYPE_IMAGE = 1;
export const ITEM_MEDIA_TYPE_IMAGE_TYPE = "image/jpeg";
export const ITEM_MEDIA_TYPE_IMAGE_TITLE = "Image";
export const ITEM_MEDIA_TYPE_PDF = 2;
export const ITEM_MEDIA_TYPE_PDF_TYPE = "application/pdf";
export const ITEM_MEDIA_TYPE_PDF_TITLE = "PDF";
export const ITEM_MEDIA_TYPE_VIDEO = 3;
export const ITEM_MEDIA_TYPE_VIDEO_TYPE = "video/mp4";
export const ITEM_MEDIA_TYPE_VIDEO_TITLE = "Video";

export const MEDIA_TYPE_DATA_SOURCE = [
  {
    id: ITEM_MEDIA_TYPE_IMAGE,
    type: ITEM_MEDIA_TYPE_IMAGE_TYPE,
    name: ITEM_MEDIA_TYPE_IMAGE_TITLE
  },
  {
    id: ITEM_MEDIA_TYPE_PDF,
    type: ITEM_MEDIA_TYPE_PDF_TYPE,
    name: ITEM_MEDIA_TYPE_PDF_TITLE,
  },
  {
    id: ITEM_MEDIA_TYPE_VIDEO,
    type: ITEM_MEDIA_TYPE_VIDEO_TYPE,
    name: ITEM_MEDIA_TYPE_VIDEO_TITLE,
  },
];

export const READ_RESERVE_LOG = 274000;
export const CLOSE_RESERVE_LOG = 274011;
export const CREATE_RESERVE_LOG = 274001;

export const RESERVE_LOG_STATUS_DRAFT = 1;
export const RESERVE_LOG_STATUS_DRAFT_TITLE = "Draft";
export const RESERVE_LOG_STATUS_CLOSED = 2;
export const RESERVE_LOG_STATUS_CLOSED_TITLE = "Closed";

export const RESERVE_LOG_SUBMIT_RULES = {
  quantity: {
    required: true,
  },
  date: {
    required: true,
  }
};
