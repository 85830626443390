export const CART_LIST_REQUEST = "CART_LIST_REQUEST";
export const CART_LIST_SUCCESS = "CART_LIST_REQUEST_SUCCESS";
export const CART_DETAIL_REQUEST = "CART_DETAIL_REQUEST";
export const CART_ITEM_APPROVE_REQUEST = "CART_ITEM_APPROVE_REQUEST";
export const CART_ITEM_REJECT_REQUEST = "CART_ITEM_REJECT_REQUEST";

export const READ_ORDER = 256000;
export const APPROVE_SHOPPING_CART_ITEM = 256011;
export const REJECT_SHOPPING_CART_ITEM = 256012;

export const CART_PENDING = 1;
export const CART_PENDING_TITLE = "Pending";
export const CART_STOCK_PROCESS = 2;
export const CART_STOCK_PROCESS_TITLE = "Completed";


export const CART_APPROVAL_STATUS_PENDING = 1;
export const CART_APPROVAL_STATUS_PENDING_TITLE = "Waiting";
export const CART_APPROVAL_STATUS_REJECT = 2;
export const CART_APPROVAL_STATUS_REJECT_TITLE = "Rejected";
export const CART_APPROVAL_STATUS_APPROVED = 3;
export const CART_APPROVAL_STATUS_APPROVED_TITLE = "Approved";


export const CART_APPROVAL_STATUS_DATA_SOURCE = [
  {
    id: CART_APPROVAL_STATUS_PENDING,
    name: CART_APPROVAL_STATUS_PENDING_TITLE,
  },
  {
    id: CART_APPROVAL_STATUS_REJECT,
    name: CART_APPROVAL_STATUS_REJECT_TITLE,
  },
  {
    id: CART_APPROVAL_STATUS_APPROVED,
    name: CART_APPROVAL_STATUS_APPROVED_TITLE,
  },
];

export const CART_STATUS_DATASOURCE = [
  {
    id: CART_PENDING,
    name: CART_PENDING_TITLE,
  },
  {
    id: CART_STOCK_PROCESS,
    name: CART_STOCK_PROCESS_TITLE,
  },
];
