import React, { Component } from "react";
import PropTypes from "prop-types";
import { SaveOutlined } from "@ant-design/icons";

// Components 
import Input from "@cores/components/Input";
import TimePicker from "@cores/components/time-picker";

// Libs
import { Row, Col } from "reactstrap";
import { reduxForm } from "redux-form";
import { Button, Form } from "antd";
import { connect } from "react-redux";

// helper 
import { createSubmitAction } from "@/helpers";
import { editAttendanceSubmitRules } from "../../../helpers";
import { editAttendance } from "../../../actions";
import { ATTENDANCE_EDIT_REQUEST } from "../../../constants";

class EditAttendance extends Component {

  onSubmit = (values, dispatch) => {
    const { onDismiss } = this.props;
    return createSubmitAction(
      editAttendanceSubmitRules(values),
      editAttendance
    )(values, dispatch).then(() => {
      onDismiss();
    });
  }

  render() {
    const { 
      handleSubmit,
      loading 
    } = this.props;
    return (
      <Form onFinish={handleSubmit(this.onSubmit)} layout="vertical">
        <Row>
          <Col md="6" sm="12" xs="12">
            <TimePicker 
              label="Time In" 
              name="timeIn"
            />
          </Col>
          <Col md="6" sm="12" xs="12">
            <TimePicker 
              label="Time Out" 
              name="timeOut"
            />
          </Col>
          <Col md="12" sm="12" xs="12">
            <Input 
              required
              label="Description"
              name="description"
              type="TextArea"
              rows="4"
            />
          </Col>
          <Col md="12" sm="12" xs="12">
            <Button 
              ghost
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<SaveOutlined />}
              className="float-right" 
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

EditAttendance.propTypes = {
  label: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onDismiss: PropTypes.func,
  editAttendance: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { 
    values: { 
      id,
      timeIn, 
      timeOut, 
      description, 
    }, 
  } = ownProps;
  return {
    loading: state.request[ATTENDANCE_EDIT_REQUEST],
    initialValues: {
      timeIn: timeIn && timeIn.formattedValue,
      timeOut: timeOut && timeOut.formattedValue,
      description,
      id,
    }
  };
};

export default connect(mapStateToProps)(reduxForm({
  enableReinitialize: true,
})(EditAttendance));