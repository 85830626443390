export const USER_ACCOUNT_LIST_REQUEST = "USER_ACCOUNT_LIST_REQUEST"; 
export const USER_ACCOUNT_LIST_SUCCESS = "USER_ACCOUNT_LIST_REQUEST_SUCCESS";
export const USER_ACCOUNT_CREATE_REQUEST = "USER_ACCOUNT_CREATE_REQUEST"; 
export const USER_ACCOUNT_DETAIL_REQUEST = "USER_ACCOUNT_DETAIL_REQUEST"; 
export const USER_ACCOUNT_ARCHIVE_REQUEST = "USER_ACCOUNT_ARCHIVE_REQUEST"; 
export const USER_ACCOUNT_DELETE_REQUEST = "USER_ACCOUNT_DELETE_REQUEST"; 
export const USER_ACCOUNT_UNARCHIVE_REQUEST = "USER_ACCOUNT_UNARCHIVE_REQUEST"; 
export const USER_ACCOUNT_EDIT_REQUEST = "USER_ACCOUNT_EDIT_REQUEST"; 
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST"; 
export const USER_ACCOUNT_CREATE_FORM_NAME = "createUser"; 
export const USER_ACCOUNT_EDIT_FORM_NAME = "editUser"; 
export const USER_ACCOUNT_AUTOCOMPLETE_REQUEST = "USER_ACCOUNT_AUTOCOMPLETE_REQUEST"; 
export const USER_ACCOUNT_AUTOCOMPLETE_SUCCESS = "USER_ACCOUNT_AUTOCOMPLETE_SUCCESS";
export const UPDATE_USER_ACCOUNT_STATUS_REQUEST = "UPDATE_USER_ACCOUNT_STATUS_REQUEST";
export const USER_ACCOUNT_CHANGE_PASSWORD_REQUEST = "USER_ACCOUNT_CHANGE_PASSWORD_REQUEST";
export const USER_ACCOUNT_RESET_PASSWORD_REQUEST = "USER_ACCOUNT_RESET_PASSWORD_REQUEST";

export const READ_USER_ACCOUNT = 124000;
export const CREATE_USER_ACCOUNT = 124001;
export const UPDATE_USER_ACCOUNT = 124002;
export const ARCHIVE_USER_ACCOUNT = 124004;
export const UNARCHIVE_USER_ACCOUNT = 124005;
export const DELETE_USER_ACCOUNT = 124003;
export const ACTIVATE_USER_ACCOUNT = 40006;

export const READ_USER_SESSION_CODE = 121000;
export const DELETE_USER_SESSION_CODE = 121003;
export const BATCH_DELETE_USER_SESSION_CODE = 121011;

export const RESET_PASSWORD_USER_ACCOUNT = 124011;
export const USER_ACCOUNT_STATUS_ACTIVE = 1;
export const USER_ACCOUNT_STATUS_DEACTIVE = -1;
export const USER_ACCOUNT_STATUS_SUSPEND = 2;

export const USER_ACCOUNT_STATUS_ACTIVE_TITLE = "Active";
export const USER_ACCOUNT_STATUS_DEACTIVE_TITLE = "Deactive";
export const USER_ACCOUNT_STATUS_SUSPEND_TITLE = "Suspend";

export const UER_TYPE_PASSWORD_ID = 1;
export const UER_TYPE_PASSWORD_NAME = "User Password";
export const UER_TYPE_CREDENTAIL_ID = 2;
export const UER_TYPE_CREDENTAIL_NAME = "Credentail";
export const UER_TYPE_APP_ID = 3;
export const UER_TYPE_APP_NAME = "Integration App";
export const UER_TYPE_CUSTOMER_ID = 4;
export const UER_TYPE_CUSTOMER_NAME = "Customer";

export const USER_ACCOUNT_STATUS = [
  { 
    id: USER_ACCOUNT_STATUS_ACTIVE, 
    name: USER_ACCOUNT_STATUS_ACTIVE_TITLE 
  },
  { id: USER_ACCOUNT_STATUS_DEACTIVE, 
    name: USER_ACCOUNT_STATUS_DEACTIVE_TITLE 
  },
  { id: USER_ACCOUNT_STATUS_SUSPEND, 
    name: USER_ACCOUNT_STATUS_SUSPEND_TITLE 
  }
];

export const USER_TYPE = [
  { 
    id: UER_TYPE_PASSWORD_ID, 
    name: UER_TYPE_PASSWORD_NAME 
  },
  { id: UER_TYPE_CREDENTAIL_ID, 
    name: UER_TYPE_CREDENTAIL_NAME 
  },
  { id: UER_TYPE_APP_ID, 
    name: UER_TYPE_APP_NAME 
  },
  { id: UER_TYPE_CUSTOMER_ID, 
    name: UER_TYPE_CUSTOMER_NAME 
  }
];

export const USER_ACCOUNT_MODAL_TYPE_CHANGE_PASSWORD = 1;
export const USER_ACCOUNT_MODAL_TYPE_CHANGE_PASSWORD_TITLE = "Change Password";

export const USER_ACCOUNT_MODAL_TYPE_RESET_PASSWORD = 2;
export const USER_ACCOUNT_MODAL_TYPE_RESET_PASSWORD_TITLE = "Reset Password";

export const USER_SESSION = "USER_DETAIL_SESSION";
export const SESSION_SUCCESS = "SESSION_SUCCESS";
export const SESSION_DELETE = "SESSION_DELETE";
export const SESSION_DELETE_ALL = "SESSION_DELETE_ALL";
export const DELETE_SESSION_REQUEST = "DELETE_SESSION_REQUEST";
export const SESSION_SUCCESS_NEXT_PAGE = "SESSION_SUCCESS_NEXT_PAGE";
export const DELETE_ALL_SESSION_REQUEST = "DELETE_ALL_SESSION_REQUEST";