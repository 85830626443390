import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Title extends Component {
  
  render() {
    const { 
      title, 
      border,
      tag: Tag,
      className, 
    } = this.props;
    return (
      <Fragment>
        <Tag className={className}>
          {title}
        </Tag>
        {border && <hr />}
      </Fragment>
    );
  }
}

Title.propTypes = {
  tag: PropTypes.string,
  border: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

Title.defaultProps = {
  tag: "h6",
  border: true,
  className: "mt-2"
};

export default Title;