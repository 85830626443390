import React, { Fragment } from "react";
import PropTypes from "prop-types";
import download from "downloadjs";
import { Card, Empty } from "antd";
import { 
  EyeOutlined, 
  CloudDownloadOutlined,
  FileTextOutlined, 
} from "@ant-design/icons";  

class AttachmentBox extends React.Component {

  onDownload = (url) => {
    const splited = url.split("/");
    const lastIndex = splited.length - 1;
    const fileName = splited[lastIndex];
    download(url, fileName);
  }

  render() {
    const { url, title } = this.props;
    return (
      <Fragment>
        {url ? (
          <Card
            title={title}
            style={{width: 220, textAlign: "center", marginRight: "1.5em"}}
            actions={
              [
                <a
                  key="view"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                >
                  <EyeOutlined /> View
                </a>,
                <a
                  key="download"
                  rel="noopener noreferrer"
                  onClick={() => this.onDownload(url)}
                >
                  <CloudDownloadOutlined /> Download
                </a>
              ]
            }
          >
            <FileTextOutlined style={{fontSize: 50}} />
          </Card>
        ) : (
          <Empty
            className="mx-auto"
            description="No attachment"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Fragment>
    );
  }
}

AttachmentBox.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

export default AttachmentBox;