export const ITEM_CATALOG_LIST_REQUEST = "ITEM_CATALOG_LIST_REQUEST"; 
export const ITEM_CATALOG_LIST_SUCCESS = "ITEM_CATALOG_LIST_REQUEST_SUCCESS";
export const ITEM_CATALOG_CREATE_REQUEST = "ITEM_CATALOG_CREATE_REQUEST"; 
export const ITEM_CATALOG_DETAIL_REQUEST = "ITEM_CATALOG_DETAIL_REQUEST"; 
export const ITEM_CATALOG_ARCHIVE_REQUEST = "ITEM_CATALOG_ARCHIVE_REQUEST"; 
export const ITEM_CATALOG_DELETE_REQUEST = "ITEM_CATALOG_DELETE_REQUEST"; 
export const ITEM_CATALOG_UNARCHIVE_REQUEST = "ITEM_CATALOG_UNARCHIVE_REQUEST"; 
export const ITEM_CATALOG_EDIT_REQUEST = "ITEM_CATALOG_EDIT_REQUEST"; 
export const ITEM_CATALOG_CREATE_FORM_NAME = "createItemCatalog"; 
export const ITEM_CATALOG_EDIT_FORM_NAME = "editItemCatalog"; 
export const ITEM_CATALOG_AUTOCOMPLETE_REQUEST = "ITEM_CATALOG_AUTOCOMPLETE_REQUEST";
export const ITEM_CATALOG_AUTOCOMPLETE_SUCCESS = "ITEM_CATALOG_AUTOCOMPLETE_SUCCESS";

export const ITEM_CATALOG_SUBMIT_RULES = (values) => { 
  const { type } = values;
  return {
    name: {
      required: true,
    },
    status: {
      required: true,
    },
    type:{
      required: true,
    },
    itemIds: {
      required: type == ITEM_CATALOG_TYPE_ITEM
    },
    brandIds: {
      required: type == ITEM_CATALOG_TYPE_BRAND
    },
    itemDepartmentIds: {
      required: type == ITEM_CATALOG_TYPE_DEPARTMENT
    },
    categoryIds: {
      required: type == ITEM_CATALOG_TYPE_CATEGORY
    }
  };
};

export const ITEM_CATALOG_STATUS_ACTIVE = 1;
export const ITEM_CATALOG_STATUS_ACTIVE_TITLE = "Active";
export const ITEM_CATALOG_STATUS_DEACTIVE = 2;
export const ITEM_CATALOG_STATUS_DEACTIVE_TITLE = "Deactive";

export const ITEM_CATALOG_STATUS = [
  {
    id: ITEM_CATALOG_STATUS_ACTIVE,
    name: ITEM_CATALOG_STATUS_ACTIVE_TITLE,
  },
  {
    id: ITEM_CATALOG_STATUS_DEACTIVE,
    name: ITEM_CATALOG_STATUS_DEACTIVE_TITLE,
  },
];

export const ITEM_CATALOG_TYPE_ITEM = 1;
export const ITEM_CATALOG_TYPE_ITEM_TITLE = "Item";
export const ITEM_CATALOG_TYPE_BRAND = 2;
export const ITEM_CATALOG_TYPE_BRAND_TITLE = "Brand";
export const ITEM_CATALOG_TYPE_DEPARTMENT = 3;
export const ITEM_CATALOG_TYPE_DEPARTMENT_TITLE = "Department";
export const ITEM_CATALOG_TYPE_CATEGORY = 4;
export const ITEM_CATALOG_TYPE_CATEGORY_TITLE = "Category";

export const ITEM_CATALOG_REORDER_REQUEST = "ITEM_CATALOG_REORDER_REQUEST";
export const ITEM_CATALOG_REORDER_SUCCESS = "ITEM_CATALOG_REORDER_SUCCESS";

export const ITEM_CATALOG_TYPE_DATA_SOURCE = [
  {
    id: ITEM_CATALOG_TYPE_ITEM,
    name: ITEM_CATALOG_TYPE_ITEM_TITLE
  },
  {
    id: ITEM_CATALOG_TYPE_BRAND,
    name: ITEM_CATALOG_TYPE_BRAND_TITLE
  },
  {
    id: ITEM_CATALOG_TYPE_DEPARTMENT,
    name: ITEM_CATALOG_TYPE_DEPARTMENT_TITLE
  },
  {
    id: ITEM_CATALOG_TYPE_CATEGORY,
    name: ITEM_CATALOG_TYPE_CATEGORY_TITLE
  }
];


export const READ_ITEM_CATALOG = 265000;
export const CREATE_ITEM_CATALOG = 265001;
export const UPDATE_ITEM_CATALOG = 265002;
export const DELETE_ITEM_CATALOG = 265003;
export const ARCHIVE_ITEM_CATALOG= 265004;
export const UNARCHIVE_ITEM_CATALOG= 265005;