// Libs
import React, { Component } from "react";

// Constants
import { 
  SALE_COMMISSION_TYPE_DATA_SOURCE, 
  SALE_COMMISSION_TYPE_SALE
} from "@modules/reports/sale/sale-commission/constants";
import { OPTION_TYPE_THIS_MONTH } from "@cores/components/auto-complete/date-range-option/constants";

// Components
import FilterComponent from "@cores/components/filter-box";
import AsyncDateRangeOption from "@cores/components/async-date-range-options";
import AsyncStatusAutoComplete from "@cores/components/async-auto-complete/async-status-auto-complete";

class FilterBox extends Component {

  render() {
    return (
      <FilterComponent enableArchiveFilter={false} {...this.props}>
        <AsyncStatusAutoComplete 
          name="type"
          placeholder="Type"
          defaultValue={SALE_COMMISSION_TYPE_SALE}
          dataSource={SALE_COMMISSION_TYPE_DATA_SOURCE}
        />
        <AsyncDateRangeOption 
          defaultValue={OPTION_TYPE_THIS_MONTH}
        />
      </FilterComponent>
    );
  }
}

export default FilterBox;