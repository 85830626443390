import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "antd";

// Constants
import { ASSET_LIST_REQUEST } from "@modules/inventory/asset/asset/constants";

// Components
import ListLayout from "@/components/layout/list-layout";
import CurrencyBox from "@cores/components/currency-box";

// Schema
import { AssetArraySchema } from "@modules/inventory/asset/asset/schema";

// Action 
import { getShortNameWithTooltip } from "@modules/helpers";
import { getAll } from "@modules/inventory/asset/asset/actions";

class Asset extends React.Component {

  columns = () => {
    let columns = [
      {
        title: "Item",
        dataIndex: "item",
        width: "45%",
        render: (item, row) => {
          if (item) {
            return (
              <div className="d-inline">
                <Link to={`/inventory/items/item/${item.id}`}>
                  <div style={{display: "contents"}}>
                    {getShortNameWithTooltip(item.name, 120)}
                  </div>
                </Link>
                <div style={{ display: "grid" }}>
                  {row.itemSerial && (
                    <Typography.Text strong>
                        Serial: {row.itemSerial.serialNo}
                    </Typography.Text>
                  )}
                </div>
              </div>
            );
          }
        }
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "15%",
      },
      {
        title: "Cost",
        dataIndex: "cost",
        width: "20%",
        render: (cost) => <CurrencyBox value={cost} />
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: "20%",
        render: (_, row) => <CurrencyBox value={row.cost * row.quantity} />
      }
    ];
    return columns;
  }

  render() {
    const { permission, id } = this.props;
    return (
      <ListLayout
        title="Asset"
        className="mb-0"
        action={getAll}
        showHeader={false}
        reducerName="asset"
        tableSize="small"
        columns={this.columns}
        permission={permission}
        filterKeys={["employee"]}
        schema={AssetArraySchema}
        isPermissionAllowed={true}
        requestName={ASSET_LIST_REQUEST}
        extraFilter={(filter) => {
          filter.employee = [id];
          return filter;
        }}
      />
    );
  }
}

Asset.propTypes = {
  id: PropTypes.number,
  permission: PropTypes.object,
};

export default Asset;