export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST"; 
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_REQUEST_SUCCESS";
export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST"; 
export const COMPANY_DETAIL_REQUEST = "COMPANY_DETAIL_REQUEST"; 
export const COMPANY_ARCHIVE_REQUEST = "COMPANY_ARCHIVE_REQUEST"; 
export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST"; 
export const COMPANY_UNARCHIVE_REQUEST = "COMPANY_UNARCHIVE_REQUEST"; 
export const COMPANY_EDIT_REQUEST = "COMPANY_EDIT_REQUEST"; 
export const COMPANY_CREATE_FORM_NAME = "createCompany"; 
export const COMPANY_EDIT_FORM_NAME = "editCompany"; 
export const COMPANY_AUTOCOMPLETE_REQUEST = "COMPANY_AUTOCOMPLETE_REQUEST";
export const COMPANY_AUTOCOMPLETE_SUCCESS = "COMPANY_AUTOCOMPLETE_SUCCESS";
export const COUNTRY_AUTOCOMPLETE_REQUEST = "COUNTRY_AUTOCOMPLETE_REQUEST";
export const COMPANY_SUBMIT_RULES = (values) => {
  const { isAccountingInstalled } = values;
  const rules = {
    name: {
      required: true,
    },
    contactPhone: {
      required: true,
    },
    contactEmail: {
      required: true,
      type: "email",
    },
    address: {
      required: true,
    },
    noSeriesListId: {
      required: isAccountingInstalled,
    },
    defaultVATChartAccountId: {
      required: isAccountingInstalled,
    },
    vatNumber: {
      required: isAccountingInstalled,
    }
  };
  return rules;
};
export const READ_COMPANY = 102000;
export const CREATE_COMPANY = 102001;
export const UPDATE_COMPANY = 102002;
export const DELETE_COMPANY = 102003; 
export const ARCHIVE_COMPANY = 102004;
export const UNARCHIVE_COMPANY = 102005;

export const COMPANY_NAVIGATION_DEFAULT_BACKGROUND_COLOR = "#335E9C";
export const COMPANY_NAVIGATION_DEFAULT_LINK_COLOR = "#FFFFFF";
export const COMPANY_NAVIGATION_DEFAULT_ACTIVE_LINK_COLOR = "#FFFFFF";
export const COMPANY_NAVIGATION_DEFAULT_ACTIVE_BACKGROUND_COLOR = "#2e548b";