import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import CurrencyBox from "@cores/components/currency-box";

class SummaryBox extends React.Component {
  
  render() {
    const { label, value, isCurrency, tag: Tag, className, currency, isMarginLeftAuto } = this.props;
    return (
      <Row className={isMarginLeftAuto ? "ml-auto" : ""}>
        <Col xl="6" lg="6" md="8" xs="6">
          <Tag className={className}>{label} :</Tag>
        </Col>
        <Col xl="6" lg="6" md="4" xs="6">
          <Tag className={className}>
            {isCurrency ? <CurrencyBox value={value} currency={currency} /> : value}
          </Tag>
        </Col>
      </Row>
    );
  }
}

SummaryBox.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  tag: PropTypes.any,
  isCurrency: PropTypes.bool,
  className: PropTypes.string,
  currency: PropTypes.object,
  isMarginLeftAuto: PropTypes.bool,
};

SummaryBox.defaultProps = {
  tag: "h6",
  isCurrency: true,
  isMarginLeftAuto: true,
};

export default SummaryBox;
