export const PAYSLIP_LIST_REQUEST = "PAYSLIP_LIST_REQUEST"; 
export const PAYSLIP_LIST_SUCCESS = "PAYSLIP_LIST_REQUEST_SUCCESS";

export const PAYSLIP_DETAIL_REQUEST = "PAYSLIP_DETAIL_REQUEST";

export const PAYSLIP_ARCHIVE_REQUEST = "PAYSLIP_ARCHIVE_REQUEST";
export const PAYSLIP_EDIT_INPUT_REQUEST = "PAYSLIP_EDIT_INPUT_REQUEST";

export const PAYSLIP_DELETE_REQUEST = "PAYSLIP_DELETE_REQUEST";
export const PAYSLIP_EDIT_REQUEST = "PAYSLIP_EDIT_REQUEST";

export const PAYSLIP_UNARCHIVE_REQUEST = "PAYSLIP_UNARCHIVE_REQUEST";

export const PAYSLIP_AUTOCOMPLETE_REQUEST = "PAYSLIP_AUTOCOMPLETE_REQUEST";
export const PAYSLIP_AUTOCOMPLETE_SUCCESS = "PAYSLIP_AUTOCOMPLETE_SUCCESS";

export const PAYSLIP_CONFIRM_REQUEST = "PAYSLIP_CONFIRM_REQUEST";
export const PAYSLIP_CONFIRM_SUCCESS = "PAYSLIP_CONFIRM_SUCCESS";

export const PAYSLIP_CANCEL_REQUEST = "PAYSLIP_CANCEL_REQUEST";
export const PAYSLIP_CANCEL_SUCCESS = "PAYSLIP_CANCEL_SUCCESS";

export const PAYSLIP_DRAFT_REQUEST = "PAYSLIP_DRAFT_REQUEST";
export const PAYSLIP_DRAFT_SUCCESS = "PAYSLIP_DRAFT_SUCCESS";

export const PAYSLIP_COMPUTATION_CREATE_REQUEST = "PAYSLIP_COMPUTATION_CREATE_REQUEST";
export const PAYSLIP_COMPUTATION_CREATE_SUCCESS = "PAYSLIP_COMPUTATION_CREATE_SUCCESS";

export const PAYSLIP_EDIT_FORM_NAME = "editPayslip";

export const READ_PAYSLIP = 172000;
export const UPDATE_PAYSLIP = 172002;
export const PRINT_PAYSLIP_CODE = 172007;
export const COMPUTE_PAYSLIP_CODE = 172011;
export const CONFIRM_PAYSLIP_CODE = 172012;
export const CANCEL_PAYSLIP_CODE = 172013;
export const DRAFT_PAYSLIP_CODE = 172014;
export const VIEW_OTHER_PAYSLIP = 172015;

export const PAYSLIP_STATUS_DRAFT = 1;
export const PAYSLIP_STATUS_DRAFT_TITLE = "Draft";
export const PAYSLIP_STATUS_COMPUTED = 2;
export const PAYSLIP_STATUS_COMPUTED_TITLE = "Computed";
export const PAYSLIP_STATUS_CONFIRMED = 3;
export const PAYSLIP_STATUS_CONFIRMED_TITLE = "Confirmed";
export const PAYSLIP_STATUS_CANCELLED = 4;
export const PAYSLIP_STATUS_CANCELLED_TITLE = "Cancelled";
export const PAYSLIP_STATUS_COMPLETED = 5;
export const PAYSLIP_STATUS_COMPLETED_TITLE = "Completed";

export const PAYSLIP_STATUS_DATASOURCE = [
  {
    id: PAYSLIP_STATUS_DRAFT,
    name: PAYSLIP_STATUS_DRAFT_TITLE,
  },
  {
    id: PAYSLIP_STATUS_COMPUTED,
    name: PAYSLIP_STATUS_COMPUTED_TITLE,
  },
  {
    id: PAYSLIP_STATUS_CONFIRMED,
    name: PAYSLIP_STATUS_CONFIRMED_TITLE,
  },
  {
    id: PAYSLIP_STATUS_CANCELLED,
    name: PAYSLIP_STATUS_CANCELLED_TITLE,
  },
  {
    id: PAYSLIP_STATUS_COMPLETED,
    name: PAYSLIP_STATUS_COMPLETED_TITLE,
  },
];

export const PAYSLIP_COMPUTATION_FIX = 1;
export const PAYSLIP_COMPUTATION_FIX_TITLE = "Fix Amount";
export const PAYSLIP_COMPUTATION_PERCENTAGE = 2;
export const PAYSLIP_COMPUTATION_PERCENTAGE_TITLE = "Percentage";
export const PAYSLIP_COMPUTATION_TAX = 3;
export const PAYSLIP_COMPUTATION_TAX_TITLE = "Tax Calculation";
export const PAYSLIP_COMPUTATION_FORMULA = 4;
export const PAYSLIP_COMPUTATION_FORMULA_TITLE = "Formula";

export const PayslipComputationTypes = [
  {
    id: PAYSLIP_COMPUTATION_FIX,
    name: PAYSLIP_COMPUTATION_FIX_TITLE,
  },
  {
    id: PAYSLIP_COMPUTATION_PERCENTAGE,
    name: PAYSLIP_COMPUTATION_PERCENTAGE_TITLE,
  },
  {
    id: PAYSLIP_COMPUTATION_TAX,
    name: PAYSLIP_COMPUTATION_TAX_TITLE,
  },
  {
    id: PAYSLIP_COMPUTATION_FORMULA,
    name: PAYSLIP_COMPUTATION_FORMULA_TITLE,
  },
];

export const PAYSLIP_EDIT_SUBMIT_RULES = {
  inputs: {
    required: false,
    type: "arrayInline",
    subFields: [
      {
        name: "inputId"
      },
      {
        name: "parameter",
        type: "number",
      },
      {
        name: "value",
        type: "number",
      },
    ],
  }
};