/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Checkbox } from "antd";
import { 
  Label, 
  FormText, 
  FormGroup, 
  FormFeedback 
} from "reactstrap";

class RenderCheckBoxField extends React.Component {

  onChange = (event) => {
    const { target: { checked } } = event;
    const { input : { onChange }, handleChange }  = this.props;
    onChange(checked);
    if (handleChange) {
      handleChange(checked);
    }
  }

  render () {
    const {
      tip,
      input: {
        value,
      },
      label,
      meta: {
        error,
        touched,
      },
      required,
      disabled,
      className,
      isFormControl,
    } = this.props;
    const checkBoxValue = Boolean(value);
    return (
      <Fragment>
        {isFormControl ? (
          <FormGroup className={className}>
            <Label>
              {required && (
                <span className="text-danger"> * </span>
              )}
            </Label>
            <Checkbox
              disabled={disabled}
              onChange={this.onChange}
              checked={checkBoxValue}
            >
              {label}
            </Checkbox>
            {tip &&
              <FormText color="muted">
                {tip}
              </FormText>
            }
            {touched && error && (
              <FormFeedback>{error}</FormFeedback>
            )}
          </FormGroup>
        ) : (
          <Checkbox
            disabled={disabled}
            onChange={this.onChange}
            checked={checkBoxValue}
          >
            {label}
          </Checkbox>
        )}
      </Fragment>
    );
  }
}

RenderCheckBoxField.propTypes = {
  tip: PropTypes.string,
  meta: PropTypes.object,
  label: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  isFormControl: PropTypes.bool,
};

RenderCheckBoxField.defaultProps = {
  isFormControl: true,
};

class CheckBox extends React.Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field
        component={RenderCheckBoxField}
        {...props}
      />
    );
  }
}

export default CheckBox;