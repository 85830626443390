export const SALE_HISTORY_LIST_REQUEST = "SALE_HISTORY_LIST_REQUEST";
export const SALE_HISTORY_LIST_SUCCESS = "SALE_HISTORY_LIST_REQUEST_SUCCESS";
export const SALE_HISTORY_DETAIL_REQUEST = "SALE_HISTORY_DETAIL_REQUEST";

export const SALE_HISTORY_STATUS_PENDING = 1;
export const SALE_HISTORY_STATUS_PENDING_TITLE = "Pending";
export const SALE_HISTORY_STATUS_PAID = 2;
export const SALE_HISTORY_STATUS_PAID_TITLE = "Paid";
export const SALE_HISTORY_STATUS_CREDIT = 3;
export const SALE_HISTORY_STATUS_CREDIT_TITLE = "Credit";
export const SALE_HISTORY_STATUS_VOIDED = 4;
export const SALE_HISTORY_STATUS_VOIDED_TITLE = "Voided";
export const SALE_HISTORY_STATUS_RETURNED = 5;
export const SALE_HISTORY_STATUS_RETURNED_TITLE = "Returned";
export const SALE_HISTORY_STATUS_CANCELLED = 6;
export const SALE_HISTORY_STATUS_CANCELLED_TITLE = "Cancelled";
export const SALE_HISTORY_STATUS_CREDIT_NOTE = 7;
export const SALE_HISTORY_STATUS_CREDIT_NOTE_TITLE = "Credit Note";
export const SALE_HISTORY_STATUS_DELIVERY = 8;
export const SALE_HISTORY_STATUS_DELIVERY_TITLE = "Delivery";

export const SALE_HISTORY_STATUS_DATA_SOURCE = [
  {
    id: SALE_HISTORY_STATUS_PAID,
    name: SALE_HISTORY_STATUS_PAID_TITLE,
  },
  {
    id: SALE_HISTORY_STATUS_VOIDED,
    name: SALE_HISTORY_STATUS_VOIDED_TITLE,
  },
];

export const READ_SALE_HISTORY = 227005;
export const VOID_SALE_HISTORY = 198014;
export const VIEW_ALL_HISTORY = 198033;

export const SALE_HISTORY_MODAL_TYPE_DETAIL = 1;
export const SALE_HISTORY_MODAL_TYPE_DETAIL_TITLE = "Sale History Detail";
export const SALE_HISTORY_MODAL_TYPE_VOID = 2;
export const SALE_HISTORY_MODAL_TYPE_VOID_TITLE = "Void";