import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAutoComplete } from "@modules/utils/value-selector";

import TreeSelectAutoCompleteField from "./tree";

class TreeSelectAutoComplete extends React.Component {

  componentDidMount() {
    const { loadData, onDataLoaded } = this.props;
    const onLoadData = loadData();
    if (onLoadData != null) {
      onLoadData.then((data) => {
        if (onDataLoaded) {
          onDataLoaded(data);
        }
      }); 
    }
  }

  render() {
    let { dataSource, onNewDataSource } = this.props;
    if (onNewDataSource) {
      dataSource = onNewDataSource(dataSource);
    }
    return (
      <Field 
        name={name}
        treeData={dataSource}
        component={TreeSelectAutoCompleteField}
        {...this.props}
      />
    );
  }
}

TreeSelectAutoComplete.propTypes = {
  dataSource: PropTypes.array,
  loadData: PropTypes.func,
  onDataLoaded: PropTypes.func,
  onNewDataSource: PropTypes.func,
};

TreeSelectAutoComplete.defaultProps = {
  dataSource: [],
};

const mapStateToProps = (state, ownProps) => {
  const { requestName } = ownProps;
  return {
    entities: state.entities,
    loading: state.request[requestName],
    dataSource: getAutoComplete(state, requestName),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { action, requestName, extra } = ownProps;
  return {
    loadData: (query) => dispatch(action(query, { requestName, ...extra }))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TreeSelectAutoComplete));