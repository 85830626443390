import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Form, message, Button } from "antd";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { 
  FileExcelOutlined, 
  FileSearchOutlined, 
  DownloadOutlined, 
} from "@ant-design/icons";

// Components
import FileUploader from "@cores/components/uploader";
import CheckBox from "@cores/components/check-box";

class ImportForm extends Component {

  beforeUpload = (file) => {
    const { type } = file;
    const isExcel = type == "application/vnd.ms-excel";
    if (!isExcel) {
      message.error("You can only upload CSV file!");
    }
    return isExcel;
  }

  render() {
    const { 
      url, 
      loading, 
      isPreview,
      handleSubmit, 
      importFilePath, 
    } = this.props;
    return (
      <Form onFinish={() => handleSubmit()} layout="vertical">
        <Row>
          <Col md="12">
            <FileUploader 
              required
              name="url"
              label="File"
              multiple={false}
              path={importFilePath}
              showUploadList={false}
              icon={<FileExcelOutlined />}
              beforeUpload={this.beforeUpload}
            />
          </Col>
          <Col md="12">
            <CheckBox 
              name="previewBeforeImport"
              label="Preview before import"
            />
          </Col>
          <Col md="12">
            <Button
              ghost
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={url == null}
              className="float-right"
              icon={isPreview ? <FileSearchOutlined /> : <DownloadOutlined />}
            >
              {isPreview ? "Preview" : "Import"}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

ImportForm.propTypes = {
  url: PropTypes.string,
  loading: PropTypes.bool,
  isPreview: PropTypes.bool,
  handleSubmit: PropTypes.func,
  importFilePath: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const selector = formValueSelector(form);
  return {
    url: selector(state, "url"),
    isPreview: selector(state, "previewBeforeImport"),
  };
};

export default connect(mapStateToProps)(reduxForm()(ImportForm));