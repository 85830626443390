export const CHART_OF_ACCOUNT_REQUEST= "CHART_OF_ACCOUNT_REQUEST";
export const CHART_OF_ACCOUNT_SUCCESS= "CHART_OF_ACCOUNT_REQUEST_SUCCESS";
export const CHART_OF_ACCOUNT_AUTOCOMPLETE_REQUEST = "CHART_OF_ACCOUNT_AUTOCOMPLETE_REQUEST";
export const CHART_OF_ACCOUNT_TYPE_AUTOCOMPLETE_REQUEST = "CHART_OF_ACCOUNT_TYPE_AUTOCOMPLETE_REQUEST";
export const CHART_OF_ACCOUNT_CREATE_REQUEST = "CHART_OF_ACCOUNT_CREATE_REQUEST";
export const CHART_OF_ACCOUNT_DETAIL_REQUEST = "CHART_OF_ACCOUNT_DETAIL_REQUEST";
export const CHART_OF_ACCOUNT_EDIT_REQUEST = "CHART_OF_ACCOUNT_EDIT_REQUEST";
export const CHART_OF_ACCOUNT_ARCHIVE_REQUEST = "CHART_OF_ACCOUNT_ARCHIVE_REQUEST";
export const CHART_OF_ACCOUNT_UNARCHIVE_REQUEST = "CHART_OF_ACCOUNT_UNARCHIVE_REQUEST";
export const CHART_ACCOUNT_HISTORY_REQUEST = "CHART_ACCOUNT_HISTORY_REQUEST";
export const CHART_ACCOUNT_HISTORY_SUCCESS = "CHART_ACCOUNT_HISTORY_REQUEST_SUCCESS";

export const CHART_OF_ACCOUNT_SUBMIT_RULES = {
  name: {
    required: true
  },
  chartAccountNo: {
    required: true
  },
  chartAccountTypeId: {
    required: true
  }
};

export const DEFAULT_DEPOSIT_CHART_OF_ACCOUNT= "DEFAULT_DEPOSIT_CHART_OF_ACCOUNT";
export const RECEIVABLE_DEPOSIT_CHART_OF_ACCOUNT= "RECEIVABLE_DEPOSIT_CHART_OF_ACCOUNT";
export const PAYABLE_DEPOSIT_CHART_OF_ACCOUNT= "PAYABLE_DEPOSIT_CHART_OF_ACCOUNT";

export const CHART_OF_ACCOUNT_CREATE_FORM_NAME = "createChartOfAccount";
export const CHART_OF_ACCOUNT_EDIT_FORM_NAME = "editChartOfAccount";

export const READ_CHART_OF_ACCOUNT = 178000;
export const CREATE_CHART_OF_ACCOUNT = 178001;
export const UPDATE_CHART_OF_ACCOUNT = 178002;
export const DELETE_CHART_OF_ACCOUNT = 178003;
export const ARCHIVE_CHART_OF_ACCOUNT = 178004;
export const UNARCHIVE_CHART_OF_ACCOUNT = 178005;

export const CHART_OF_ACCOUNT_RECEIVABLE_TYPE = 1;
export const CHART_OF_ACCOUNT_BANK_CASH_TYPE = 3;
export const CHART_OF_ACCOUNT_COGS = 15;

export const CHART_ACCOUNT_TYPE_ASSET = 1;
export const CHART_ACCOUNT_TYPE_LIABILITY = 2;
export const CHART_ACCOUNT_TYPE_EQUITY = 3;
export const CHART_ACCOUNT_TYPE_INCOME = 4;
export const CHART_OF_ACCOUNT_TYPE_EXPENSE = 5;

export const DEFAULT_RECEIVABLE_CHART_OF_ACCOUNT= "defaultAccountReceivableChartAccount";
export const DEFAULT_CUSOMER_COMISSION_CHART_OF_ACCOUNT= "defaultCustomerComissionChartAccount";
export const DEFAULT_PAYABLE_CHART_OF_ACCOUNT= "defaultAccountPayableChartAccount";
export const DEFAULT_SALE_CHART_OF_ACCOUNT= "defaultSaleChartAccount";
export const DEFAULT_COST_CHART_OF_ACCOUNT= "defaultCostChartAccount";
export const DEFAULT_SUPPLIER_DEPOSIT_CHART_OF_ACCOUNT= "defaultSupplierDepositChartAccount";
export const DEFAULT_PAY_ABLE_CHART_OF_ACCOUNT= "defaultAccountPayableChartAccount";
