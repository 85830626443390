export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST"; 
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_REQUEST_SUCCESS";

export const CONTACT_CREATE_REQUEST = "CONTACT_CREATE_REQUEST"; 
export const CONTACT_DETAIL_REQUEST = "CONTACT_DETAIL_REQUEST"; 
export const CONTACT_ARCHIVE_REQUEST = "CONTACT_ARCHIVE_REQUEST"; 
export const CONTACT_DELETE_REQUEST = "CONTACT_DELETE_REQUEST"; 

export const CONTACT_UNARCHIVE_REQUEST = "CONTACT_UNARCHIVE_REQUEST"; 

export const READ_CONTACT = 253000;
export const CREATE_CONTACT = 253001;
export const UPDATE_CONTACT = 253002;
export const DELETE_CONTACT = 253003;
export const ARCHIVE_CONTACT = 253004;
export const UNARCHIVE_CONTACT = 253005;