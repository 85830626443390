import { 
  DETAIL_SALE_LIST_SUCCESS,
  SALE_INVOICE_LIST_SUCCESS, 
  SALE_INVOICE_HISTORY_LIST_SUCCESS, 
  SALE_INVOICE_PAY_BATCH_LIST_SUCCESS,
  SALE_INVOICE_ACCOUNT_STATEMENT_LIST_SUCCESS,
} from "../constants";

const initialState = {
  list: [],
  accountStatementList: [],
  invoiceHistoryList: [],
  batchList: [],
  detailSaleList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
  case SALE_INVOICE_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  case SALE_INVOICE_PAY_BATCH_LIST_SUCCESS:
    return {
      ...state,
      batchList: action.payload
    };
  case SALE_INVOICE_HISTORY_LIST_SUCCESS:
    return {
      ...state,
      invoiceHistoryList: action.payload
    };
  case SALE_INVOICE_ACCOUNT_STATEMENT_LIST_SUCCESS:
    return {
      ...state,
      accountStatementList: action.payload
    };
  case DETAIL_SALE_LIST_SUCCESS:
    return {
      ...state,
      detailSaleList: action.payload
    };
  default:
    return state;
  }
}