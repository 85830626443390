export const GENERAL_SETTING = "GeneralSetting";
export const SALE_SETTING = "SaleSetting";
export const ACCOUNTING_SETTING = "AccountingSetting";
export const EMPLOYEE_SETTING = "EmployeeSetting";
export const ITEM_SETTING = "ItemSetting";
export const BANK_SETTING = "BankSetting";
export const ATTENDANCE_SETTING = "AttendanceSetting";
export const CASH_ADVANCED_SETTING = "CashAdvancedSetting";
export const POS_SETTING = "POSSetting";
export const PAY_ROLL_SETTING = "PayrollSetting";
export const PURCHASE_ORDER_SETTING = "PurchaseOrderSetting";

// sub-configuration
export const CURRENCY = "currency";
export const COMPANY = "company";
export const SALE_TAX = "saleTax";

export const CONFIGURATION_REQUEST = "CONFIGURATION_REQUEST";
export const CONFIGURATION_SUCCESS = "CONFIGURATION_SUCCESS";
export const CREATE_CONFIGURATION_REQUEST = "CREATE_CONFIGURATION_REQUEST";

export const API_MAP_KEY = "AIzaSyA_1xNnB2CmqUk17a854tsrLfQ6TgXZDQQ";