import * as UserConstants from "@/constants/current-user";

export default function(state = {}, action) {
  switch(action.type) {
  case UserConstants.CURRENT_USER_SUCCESS: {
    return {
      ...state,
      ...action.payload
    };
  }
    
  default:
    return state;
  }
}