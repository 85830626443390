// Lib
import React, { Component } from "react";
import { Row, Col } from "reactstrap";

// Component
import Input from "@cores/components/Input";
import Select from "@cores/components/autocomplete-static";

import { JOB_TYPE_STATUS } from "../../constants";

class FormComponent extends Component {

  render() {
    return (
      <Row>
        <Col md="6" xs="12">
          <Input
            autoFocus
            required
            label="Name"
            name="name"
          />
        </Col>
        <Col md="6" xs="12">
          <Select 
            required
            label="Status"
            name="status"
            dataSource={JOB_TYPE_STATUS}
          />
        </Col>
      </Row>
    );
  }
}

export default FormComponent;  