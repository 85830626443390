import { CONTRACT_LIST_REQUEST_SUCCESS } from "../constants";

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
  case CONTRACT_LIST_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  default:
    return state;
  }
}