import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getPositionAutoComplete, createPosition } from "@modules/hr/employee-directory/position/actions";
import FormComponent from "@modules/hr/employee-directory/position/components/form-component";

// Constant 
import { 
  CREATE_POSITION,
  POSITION_SUBMIT_RULES, 
  POSITION_CREATE_REQUEST,
  POSITION_AUTOCOMPLETE_REQUEST, 
} from "@modules/hr/employee-directory/position/constants";

class PositionAutoComplete extends Component {

  render() {
    const { 
      ids,
      department,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        entity="Position"
        formComponent={FormComponent}
        createAction={createPosition}
        permissionCode={CREATE_POSITION}
        submitRules={POSITION_SUBMIT_RULES}
        createRequestName={POSITION_CREATE_REQUEST}
        extra={{
          ids,
          department,
        }}
        {...props}
      />
    );
  }
}

PositionAutoComplete.propTypes = {
  ids: PropTypes.array,
  department: PropTypes.array,
};

PositionAutoComplete.defaultProps = {
  label: "Position",
  name: "positionId",
  required: true,
  action: getPositionAutoComplete,
  requestName: POSITION_AUTOCOMPLETE_REQUEST,
};

export default PositionAutoComplete;