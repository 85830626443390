import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";
import { getCustomerCompanyAutoComplete } from "@sale/customer-center/customer-company/actions";
import { CUSTOMER_COMPANY_AUTOCOMPLETE_REQUEST } from "@sale/customer-center/customer-company/constants";

class AsyncCustomerCompanyAutoComplete extends React.Component {

  render() {
    const { name, requestName, action, ...props } = this.props;
    return (
      <AsyncSelect 
        name={name}
        action={action}
        requestName={requestName}
        placeholder="Customer Company" 
        {...props}
      />
    );
  }
}

AsyncCustomerCompanyAutoComplete.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  requestName: PropTypes.string,
};

AsyncCustomerCompanyAutoComplete.defaultProps = {
  name: "customerCompany",
  action: getCustomerCompanyAutoComplete,
  requestName: CUSTOMER_COMPANY_AUTOCOMPLETE_REQUEST,
};

export default withRouter(AsyncCustomerCompanyAutoComplete);