import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { Table, Input, Checkbox, Form, Tooltip, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// helpers
import { 
  getQueryParamUrl, 
  getQueryParamFromLocalStorage,
} from "@modules/helpers";

class AutoCompleteAdvanceField extends Component {

  constructor(props) {
    super(props);
    const { input: { value } , dataSource } = props;
    this.state = {
      inputNumber: null,
      indeterminate: value.length == 0 ? false : value.length != dataSource.length,
      checkedAll: value.length > 0 ? value.length == dataSource.length : false,
    };
  }

    onChange = (event, value) => {
      const { 
        target: { checked } 
      } = event;
      let { 
        mode,
        onHandleChange,
        queryStorage,
        input: { value: inputValue },
        input: { onChange: onReduxFormChange }, 
        onChecked,
      } = this.props;
      let newValue = null;
      if (checked) {
        if (mode == "single") {
          newValue = value;
        } else {
          if (inputValue == null) {
            newValue = [value];
          } else {
            newValue = [
              ...inputValue,
              value
            ];
          }
        }
      } else {
        if (mode == "single") {
          newValue = null;
        } else {
          newValue = _.remove(inputValue, (val) => val != value);
        }
      }
      onReduxFormChange(newValue);
      if (onHandleChange) {
        onHandleChange(newValue);
      }
      if (queryStorage != null) {
        const { fieldName } = this.props;
        const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
        const valueGetFormUrl = query[fieldName];
        if (checked) {
          if (mode == "single") {
            newValue = value;
          } else {
            if (valueGetFormUrl == null) {
              newValue = [value];
            } else {
              newValue = [
                ...valueGetFormUrl,
                value
              ];
            }
          }
        } else {
          if (mode == "single") {
            newValue = null;
          } else {
            newValue = _.remove(valueGetFormUrl, (val) => val != value);
          }
        }
        if (onChecked) {
          onChecked(newValue);
        }
      }
      this.forceUpdate();
    }

    renderChecked = (id) => {
      const { mode, queryStorage, fieldName } = this.props;
      if (queryStorage != null) {
        const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
        const valueGetFormUrl = query[fieldName];
        if (mode == "multiple") {
          if (valueGetFormUrl != undefined && _.isArray(valueGetFormUrl) ) {
            const currentValue = valueGetFormUrl.map(value => parseInt(value));
            return _.includes(currentValue, id);
          }
        } else {
          return valueGetFormUrl == id;
        }
      } else {
        const { input: { value } } = this.props;
        if (mode == "single") {
          return value == id;
        } else {
          return _.includes(value, id);
        }
      }
    }

    handleCheckAll = () => {
      const { 
        input: { 
          onChange: onReduxFormChange 
        }, 
        dataSource 
      } = this.props;
      const { 
        target: { checked } 
      } = event;
      if (checked) {
        const fieldIds = dataSource.map(item => item.id);
        onReduxFormChange(fieldIds || []);
        this.setState({
          indeterminate: false,
          checkedAll: true,
        });
      } else {
        onReduxFormChange([]);
        this.setState({
          indeterminate: false,
          checkedAll: false,
        });
      }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      const { mode } = this.props;
      const { dataSource: newDataSource, input: { value } } = newProps;
      if (mode == "multiple") {
        if (newDataSource) {
          this.setState({
            indeterminate: value.length == 0 ? false : value.length != newDataSource.length,
            checkedAll: value.length > 0 ? value.length == newDataSource.length : false,
          }); 
        }
      }
    }

    columns = () => {
      let { 
        disabled, 
        columns, 
        titleKey, 
        titleName, 
        textLength,
        columnsWidth,
        optionRender,
        valueKey,
        mode,
        sorter,
        isHasCheckAll,
        displayDefaultColumns,
      } = this.props;
      const { indeterminate, checkedAll } = this.state;
      let columnsWithCheckAll = [
        {
          title: mode == "multiple" &&
            <Checkbox
              onChange={this.handleCheckAll}
              indeterminate={indeterminate}
              checked={checkedAll}
            />
          ,
          dataIndex: "name",
          width: "10%",
          render: (text, row) => {
            const { id } = row;
            return (
              <Checkbox
                disabled={disabled}
                checked={this.renderChecked(id)}
                onChange={(checked) => this.onChange(checked, id)} 
              />
            );
          }
        },
      ];
      let generalColumns =  [
        {
          title: titleName,
          dataIndex: titleKey,
          width: columnsWidth,
          sorter: sorter,
          render: (text, row) => {
            const value = row[valueKey];
            text = typeof optionRender != "function" ? text : optionRender(row);
            const trimmedText = text.length > textLength ? text.substring(0, textLength - 3) + "..." : text;
            const disabledValue = typeof disabled == "function" ? disabled(row) : disabled;
            return (
              <Checkbox
                disabled={disabledValue}
                checked={this.renderChecked(value)}
                onChange={(checked) => this.onChange(checked, value)} 
              >
                <Tooltip placement="right" title={text}>
                  {trimmedText}
                </Tooltip>
              </Checkbox>
            );
          }
        },
      ];
      let newColumns = isHasCheckAll ? columnsWithCheckAll : generalColumns;
      if (columns) {
        const propColumns = columns(this.props);
        if (displayDefaultColumns) {
          newColumns = [
            ...newColumns,
            ...propColumns
          ];
        } else {
          newColumns = [
            ...propColumns
          ];
        }
      }
      return newColumns;
    }

    onInputChange = (event) => {
      const { onSearchClick } = this.props;
      const value = event.target.value;
      if (value) {
        this.setState({
          inputNumber: value,
        });
      } else {
        onSearchClick(null);
      }
    }

    onPressEnter = (event) => {
      event.preventDefault();
      const { onSearchClick } = this.props;
      const value = event.target.value;
      this.setState({
        inputNumber: value,
      });
      onSearchClick(value);
    }

    onTableChange = (pagination, filters, sorter) => {
      const { onTableChange } = this.props;
      if (onTableChange)  {
        onTableChange(pagination, filters, sorter);
      }
    }

    render() {
      const { 
        label, 
        required, 
        onSearchClick, 
        loading, 
        mode,
        scroll,
        displaySearch,
        extraFilter,
        dataSource,
        onTableChange,
        isFirstLoad,
        meta: { error, touched },
      } = this.props;
      const validateStatus = classnames(error && touched ? "error" : "success");
      return (
        <Form.Item 
          label={label} 
          required={required}
          validateStatus={validateStatus}
          help={mode == "single" ? error : (error && typeof error == "string") ? error : (error && error._error)}
        >
          {displaySearch && (
            <div className="w-100 d-flex">
              <Input 
                autoFocus
                allowClear
                placeholder="Search..."
                onChange={this.onInputChange} 
                onPressEnter={this.onPressEnter}
                className="mb-2"
                style={{
                  width: extraFilter ? "50%" : "85%"
                }}
              />
              <Button 
                type="primary"
                icon={isFirstLoad && loading ? null : <SearchOutlined />}
                className="ml-1" 
                style={{
                  width: "15%"
                }}
                onClick={() => onSearchClick(this.state.inputNumber)}
              >
                {isFirstLoad && loading ? "Searching..." : "Search"}
              </Button>
              {extraFilter && (
                <div className="ml-1 mt-1" 
                  style={{
                    width: "30%"
                  }}>
                  {extraFilter}
                </div>
              )}
            </div>
          )}
          <Table 
            rowKey="id"
            size="middle"
            pagination={false}
            scroll={scroll}
            columns={this.columns()}
            className={`${validateStatus} table-responsive`}
            dataSource={dataSource}
            onChange={onTableChange}
            loading={{
              tip: "Loading...",
              spinning: loading
            }}
          />
        </Form.Item>
      );
    }
}

AutoCompleteAdvanceField.propTypes = {
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  input: PropTypes.object,
  required: PropTypes.bool,
  label: PropTypes.string,
  onHandleChange: PropTypes.func,
  onSearch: PropTypes.func,
  meta: PropTypes.object,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  columns: PropTypes.any,
  displaySearch: PropTypes.bool,
  dataSource: PropTypes.array,
  scroll: PropTypes.any,
  extraFilter: PropTypes.node,
  titleName: PropTypes.string,
  titleKey: PropTypes.string,
  textLength: PropTypes.number,
  columnsWidth: PropTypes.string,
  optionRender: PropTypes.func,
  onTableChange: PropTypes.func,
  valueKey: PropTypes.string,
  isHasCheckAll: PropTypes.bool,
  onSearchClick: PropTypes.bool,
  displayDefaultColumns: PropTypes.bool,
  queryStorage: PropTypes.string,
  name: PropTypes.string,
  isStringValue: PropTypes.any, 
  valueType: PropTypes.any,
  onChecked: PropTypes.any,
  fieldName: PropTypes.string,
  isFirstLoad: PropTypes.bool,
  sorter: PropTypes.bool,
};

AutoCompleteAdvanceField.defaultProps = {
  loading: false,
  displaySearch: true,
  textLength: 250,
  titleKey: "name",
  titleName: "Name",
  columnsWidth: "40%",
  scroll: {
    y: 400
  },
  displayDefaultColumns: true,
};

export default AutoCompleteAdvanceField;