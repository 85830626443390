// Libs
import React, { Component, Fragment } from "react";
import Media from "react-media";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { denormalize } from "normalizr";
import { Form, Button, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FilterOutlined, SaveOutlined } from "@ant-design/icons";

// Components
import FormComponent from "./filter-form";
import CollapsePanel from "../collapse-panel";
import AsyncTextBox from "../async-text-box";
import AsyncCheckBox from "../async-check-box";
import AsyncClearFilter from "../async-clear-filter";

// utils
import { getLocalizedString } from "@modules/utils/value-selector";
import { 
  getQueryParamUrl, 
  updateQueryParamUrl, 
  getFilterObjectFromQuery,
  getQueryParamFromLocalStorage, 
  getFilterObjectFromFilterDataSource,
} from "@modules/helpers";
import { FilterArraySchema } from "@/schemas/filter";

const FormItem = Form.Item;

const WrapperForm = styled.div`
  .ant-form.ant-form-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: unset;
    padding: 10px;

    @media (max-width: 1024px) {
      display: ${props => props.hasChildren ? "block" : "flex"};
      justify-content: ${props => props.hasChildren ? "unset" : "space-between"};
    }
  }

  .ant-calendar-picker, .ant-input-affix-wrapper {
    width: 140px !important;
  }
  .ant-date-range-picker {
    width: 250px !important;
  }

  .ant-form-inline .ant-form-item {
    margin-right: 5px !important;
  }
`;

const WrapperChildren = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 550px) {
    width: ${props => props.hasChildren ? "auto" : "100%"};
  }

  .ant-dropdown-trigger {
    width: 100%;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-row.ant-form-item {
    width: 100%;
    padding-right: 0.5rem;
    margin-right: 0rem !important;

    @media (max-width: 550px) {
      padding-right: 0rem !important;
    }

    @media (min-width: 550px) and (max-width: 768px) {
      :nth-child(3n+3) {
        padding-right: 0rem !important;
      }
      width: ${props => props.hasChildren ? "33.3%" : "100%"};
    }

    @media (min-width: 770px) and (max-width: 1024px) {
      :nth-child(4n+4) {
        padding-right: 0rem !important;
      }
      width: ${props => props.hasChildren ? "25%" : "100%"};
    }
    
    .ant-select,
    .ant-calendar-picker,
    .ant-input-affix-wrapper,
    .ant-form-item-control-wrapper {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
`;

const WrapperExtraFilterAndClearFilter = styled.div`
  display: ${props => props.hasExtraFilter ? "block" : "flex"};
  align-items: center;
  justify-content: space-between;

  @media (max-width: 550px) {
    width: ${props => !props.hasChildren && "100%"};
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wrapper-extra-filter-and-archive {
    display: flex;

    @media (max-width: 550px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-row.ant-form-item {
        margin: 0rem !important;
      }
    }
  }

  .wrapper-clear-filter {
    @media (max-width: 550px) {
      .ant-row.ant-form-item {
        display: block;
        margin: 0rem !important;

        button {
          width: 100%;
        }
      }
    }
  }
`;

class FilterBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      edit: false,
      isSaveNew: false,
    };
    this.onEventLoad();
  }

  onVisibleChange = (edit, isSaveNew) => {
    this.setState({
      edit,
      isSaveNew,
      visible: !this.state.visible,
      local: null
    });
  }

  onSearch = () => {
    const { onNewClickSearch, afterSubmit } = this.props;
    let query = getQueryParamFromLocalStorage();
    if (onNewClickSearch) {
      onNewClickSearch(query);
    } else {
      const { beforeSubmit } = this.props;
      let query = getQueryParamFromLocalStorage();
      if (beforeSubmit) {
        query = beforeSubmit(query);
      }
      updateQueryParamUrl(query);
      if (afterSubmit) {
        afterSubmit(query);
      }
    }
  };

  onEventLoad = () => {
    window.addEventListener("keyup", this.handleEventLister);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleEventLister);
  }

  handleEventLister = (event) => {
    if (event.keyCode === 13) {
      if (localStorage.getItem("ON_FILTER_ENTER")) {
        this.onSearch();
      }
    }
  }

  render() {
    const {
      loading,
      children,
      onReset,
      entity,
      entities,
      filterKeys,
      saveAble,
      enableText,
      extraFilter,
      filterTitle,
      textBoxMinWidth,
      enableArchiveFilter,
      getLocalizedString,
      location: { search },
      filterListObjectIds,
      onNewClearClick,
      onlyLocal,
    } = this.props;
    const { visible, edit, isSaveNew } = this.state;
    const hasChildren = children && children.length > 0 || typeof(children) == "object" ? true : false;
    const query = getQueryParamUrl();
    const newQuery = getQueryParamUrl();
    const slug = query.view;
    delete newQuery.view;
    const isEmpty = _.isEmpty(newQuery);
    const filtersDataSource = denormalize(filterListObjectIds, FilterArraySchema, entities);
    const matched = filtersDataSource && filtersDataSource.find((value) => value.slug == slug);
    const filters = matched && matched.filters;
    const isEqual = _.isEqual(getFilterObjectFromQuery(newQuery), getFilterObjectFromFilterDataSource(filters));
    return (
      <Fragment>
        <Media query={{ maxWidth: 1024 }}>
          {matches => matches ? (
            <Fragment>
              <CollapsePanel
                title="Filter"
                className="rounded mb-2"
                activeKey={search ? "1" : "0"}
              >
                <WrapperForm
                  hasChildren={hasChildren}
                  className="wrapper-filter-box"
                >
                  <Form layout="inline">
                    <WrapperChildren hasChildren={hasChildren}>
                      {children}
                      <checkBox />
                      {enableText && (
                        <FormItem>
                          <AsyncTextBox 
                            name="query" 
                            placeholder={getLocalizedString("listLayout.containText")} 
                          />
                        </FormItem>
                      )}
                    </WrapperChildren>
                    <WrapperExtraFilterAndClearFilter
                      hasChildren={hasChildren}
                      hasExtraFilter={extraFilter}
                    >
                      <div className="wrapper-extra-filter-and-archive">
                        {extraFilter}
                        {enableArchiveFilter && (
                          <FormItem>
                            <AsyncCheckBox
                              onlyLocal={onlyLocal}
                              name="archive"
                              label={<FormattedMessage id="listLayout.archived" />}
                            />
                          </FormItem>
                        )}
                      </div>
                      <div className="wrapper-clear-filter">
                        <FormItem>
                          <Button 
                            type="primary"
                            loading={loading}
                            className="mb-2"
                            icon={<FilterOutlined />}
                            onClick={this.onSearch} 
                          >
                            {loading ? "loading..." : filterTitle}
                          </Button>
                        </FormItem>
                      </div>
                      <div className="wrapper-clear-filter">
                        <FormItem>
                          <AsyncClearFilter
                            onReset
                            loading={loading}
                            filterKeys={filterKeys}
                            matched={matched}
                            onNewClearClick={onNewClearClick}
                          />
                        </FormItem>
                      </div>
                      {saveAble && (
                        <Fragment>
                          {query.view == null && !_.isEmpty(newQuery) ? (
                            <div className="wrapper-clear-filter">
                              <FormItem>
                                <Button 
                                  type="primary"
                                  className="my-2"
                                  icon={<SaveOutlined />}
                                  onClick={() => this.onVisibleChange(false, true)}
                                >
                                  Save as New
                                </Button>
                              </FormItem>
                            </div>
                          ) : (
                            !isEmpty && !isEqual && (
                              <Fragment>
                                <div className="wrapper-clear-filter">
                                  <FormItem>
                                    <Button 
                                      type="primary"
                                      className="my-2"
                                      icon={<SaveOutlined />}
                                      onClick={() => this.onVisibleChange(true, false)}
                                    >
                                      Save
                                    </Button>
                                  </FormItem>
                                </div>
                                <div className="wrapper-clear-filter">
                                  <FormItem>
                                    <Button 
                                      type="primary"
                                      icon={<SaveOutlined />}
                                      onClick={() => this.onVisibleChange(false, true)}
                                    >
                                      Save as New
                                    </Button>
                                  </FormItem>
                                </div>
                              </Fragment>
                            ) 
                          )}
                        </Fragment>
                      )}
                    </WrapperExtraFilterAndClearFilter>
                  </Form>
                </WrapperForm>
              </CollapsePanel>
            </Fragment>
          ) : (
            <Fragment>
              <WrapperForm className="wrapper-filter-box">
                <Form layout="inline">
                  {children}
                  {enableText && (
                    <FormItem>
                      <AsyncTextBox 
                        minWidth={textBoxMinWidth}
                        name="query"
                        placeholder={getLocalizedString("listLayout.containText")} 
                      />
                    </FormItem>
                  )}
                  {extraFilter}
                  {enableArchiveFilter && (
                    <FormItem>
                      <AsyncCheckBox
                        name="archive"
                        onlyLocal={onlyLocal}
                        label={<FormattedMessage id="listLayout.archived" />}
                      />
                    </FormItem>
                  )}
                  <div className="wrapper-clear-filter">
                    <FormItem>
                      <Button 
                        type="primary"
                        loading={loading}
                        onClick={this.onSearch} 
                        icon={<FilterOutlined />}
                      >
                        {loading ? "loading..." : filterTitle}
                      </Button>
                    </FormItem>
                  </div>
                  <FormItem>
                    <AsyncClearFilter
                      loading={loading}
                      onReset={onReset}
                      filterKeys={filterKeys}
                      matched={matched}
                      onNewClearClick={onNewClearClick}
                    />
                  </FormItem>
                  {saveAble && (
                    <Fragment>
                      {query.view == null && !_.isEmpty(newQuery) ? (
                        <FormItem>
                          <Button 
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={() => this.onVisibleChange(false, true)}
                          >
                            Save as New
                          </Button>
                        </FormItem>
                      ) : (
                        !isEmpty && !isEqual && (
                          <Fragment>
                            <FormItem>
                              <Button 
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => this.onVisibleChange(true, false)}
                              >
                                Save
                              </Button>
                            </FormItem>
                            <FormItem>
                              <Button 
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => this.onVisibleChange(false, true)}
                              >
                                Save as New
                              </Button>
                            </FormItem>
                          </Fragment>
                        ) 
                      )}
                    </Fragment>
                  )}
                </Form>
              </WrapperForm>
            </Fragment>
          )}
        </Media>
        <Modal
          width="400px"
          footer={null}
          visible={visible}
          title="Save Filter"
          onCancel={this.onVisibleChange}
          destroyOnClose={true}
        >
          {visible && (
            <FormComponent 
              matched={matched}
              edit={edit}
              isSaveNew={isSaveNew}
              form={`FILTER_${edit}`}
              entity={entity}
              onDismiss={this.onVisibleChange}
              order={(filtersDataSource && filtersDataSource.length + 1)}
            />
          )}
        </Modal>
      </Fragment>
    );
  }
}

FilterBox.propTypes = {
  children: PropTypes.node,
  enableText: PropTypes.bool,
  extraFilter: PropTypes.any,
  filterKeys: PropTypes.array,
  onReset: PropTypes.func,
  loading: PropTypes.bool,
  entity: PropTypes.string,
  location: PropTypes.object,
  entities: PropTypes.object,
  saveAble: PropTypes.bool,
  filterTitle: PropTypes.bool,
  textBoxMinWidth: PropTypes.object,
  getLocalizedString: PropTypes.func,
  enableArchiveFilter: PropTypes.bool,
  filterListObjectIds: PropTypes.array,
  beforeSubmit: PropTypes.func,
  onNewClickSearch: PropTypes.func,
  onNewClearClick: PropTypes.func,
  requestName: PropTypes.func,
  onlyLocal: PropTypes.bool,
  afterSubmit: PropTypes.func,
};

FilterBox.defaultProps = {
  enableText: true,
  filterTitle: "Filter",
  enableArchiveFilter: true,
};

const mapStateToProps = (state, ownProps) => {
  const { entity } = ownProps;
  return {
    entities: state.entities,
    getLocalizedString: (key) => getLocalizedString(state, key),
    filterListObjectIds: state.filter && state.filter[entity],
  };
};

export default withRouter(connect(mapStateToProps)(FilterBox));