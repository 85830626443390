export const SALE_ORDER_LIST_REQUEST = "SALE_ORDER_LIST_REQUEST";
export const SALE_ORDER_LIST_SUCCESS = "SALE_ORDER_LIST_REQUEST_SUCCESS";
export const SALE_ORDER_CREATE_REQUEST = "SALE_ORDER_CREATE_REQUEST";
export const SALE_ORDER_DETAIL_REQUEST = "SALE_ORDER_DETAIL_REQUEST";
export const SALE_ORDER_ARCHIVE_REQUEST = "SALE_ORDER_ARCHIVE_REQUEST";
export const SALE_ORDER_DELETE_REQUEST = "SALE_ORDER_DELETE_REQUEST";
export const SALE_ORDER_UNARCHIVE_REQUEST = "SALE_ORDER_UNARCHIVE_REQUEST";
export const SALE_ORDER_EDIT_REQUEST = "SALE_ORDER_EDIT_REQUEST";
export const SALE_ORDER_SUBMIT_REQUEST = "SALE_ORDER_SUBMIT_REQUEST";
export const SALE_ORDER_PROCESS_REQUEST = "SALE_ORDER_PROCESS_REQUEST";
export const SALE_ORDER_CANCEL_REQUEST = "SALE_ORDER_CANCEL_REQUEST";
export const SALE_ORDER_VOID_REQUEST = "SALE_ORDER_VOID_REQUEST";
export const SALE_ORDER_ITEM_REQUEST_APPROVAL_REQUEST = "SALE_ORDER_ITEM_REQUEST_APPROVAL_REQUEST";
export const SALE_ORDER_APPROVE_REQUEST = "SALE_ORDER_APPROVE_REQUEST";
export const SALE_ORDER_REJECT_REQUEST = "SALE_ORDER_REJECT_REQUEST";
export const SALE_ORDER_QUOT_CREATE_REQUEST = "SALE_ORDER_QUOT_CREATE_REQUEST";

export const SALE_ORDER_CREATE_FORM_NAME = "createSaleOrder";
export const SALE_ORDER_EDIT_FORM_NAME = "editSaleOrder";
export const SALE_ORDER_ITEM_FILED_NAME = "saleOrderItems";
export const SALE_ORDER_PROCESS_ITEM_FORM_NAME = "processItemForm";

export const SALE_ORDER_PROCESS_ITEM_ARRAY_FIELD_NAME = "saleOrderItems";
export const SALE_ORDER_ITEM_CUSTOM_PRICE = "Custom";
export const ITEM_INSTALLATION_FORM_NAME = "itemInstallation";

export const SALE_ORDER_ITEM_APPROVAL_FORM_NAME = "approveSaleOrderItem";
export const SALE_ORDER_ITEM_ARRAY_FIELD_NAME = "saleOrderItems";

export const SALE_ORDER_SUBMIT_RULES = {
  employeeId: {
    required: true,
  },
  customerId: {
    required: true,
  },
  paymentTermId: {
    required: true,
  },
  orderDate: {
    required: true,
  },
  preferredPaymentMethodId: {
    required: true,
  },
  saleOrderItems: {
    required: true,
    type: "array",
    message: "Please select at least 1 item",
    validation: (values) => {
      let error = false;
      let parentName = [];
      values.map((value) => {
        const { hasChild, children, name } = value;
        if (hasChild == true && children.length == 0) {
          error = true;
          parentName = parentName.concat(name);
        }
      });
      if (error) {
        const message = parentName.map((val, index) => {
          return (
            <div key={index}>
              <b>{val}</b> must be have at least 1 sub item
            </div>
          );
        });
        return {
          message
        };
      }
    }
  },
};

export const READ_SALE_ORDER = 196000;
export const CREATE_SALE_ORDER = 196001;
export const UPDATE_SALE_ORDER = 196002;
export const ARCHIVE_SALE_ORDER = 196004;
export const UNARCHIVE_SALE_ORDER = 196005;
export const DELETE_SALE_ORDER = 196002;
export const CANCEL_SALE_ORDER = 196016;
export const PROCESS_SALE_ORDER = 26007;
export const SUBMIT_SALE_ORDER = 196022;
export const PRINT_SALE_ORDER = 196007;
export const REJECT_SALE_ORDER_ITEM_CODE = 196018;
export const SALE_ORDER_ITEM_APPROVAL = 196021;
export const REQUEST_APPROVAL_SALE_ORDER_CODE = 196019;
export const VIEW_CUSTOMER_COMMISSION_SALE_ORDER = 196023;
export const CUSTOMER_COMMISSION_SALE_ORDER = 196024;
export const VIEW_ALL_SALE_ORDER = 196026;
export const VIEW_SALE_TEAM_SALE_ORDER = 196027;

export const SALE_ORDER_PENDING = 1;
export const SALE_ORDER_PENDING_TITLE = "Pending";
export const SALE_ORDER_STOCK_PROCESS = 2;
export const SALE_ORDER_STOCK_PROCESS_TITLE = "Stock Process";
export const SALE_ORDER_COMPLETED = 3;
export const SALE_ORDER_COMPLETED_TITLE = "Completed";
export const SALE_ORDER_CANCELLED = 4;
export const SALE_ORDER_CANCELLED_TITLE= "Cancelled";
export const SALE_ORDER_WAITING_APPROVAL = 5;
export const SALE_ORDER_WAITING_APPROVAL_TITLE = "Waiting Approval";
export const SALE_ORDER_SUBMIT = 6;
export const SALE_ORDER_SUBMIT_TITLE = "Submitted";
export const SALE_ORDER_APPROVE = 7;
export const SALE_ORDER_APPROVE_TITLE = "Approved";
export const SALE_ORDER_REJECT = 8;
export const SALE_ORDER_REJECT_TITLE = "Rejected";
export const SALE_ORDER_STOCK_OUT_COMPLETE = 9;
export const SALE_ORDER_STOCK_OUT_COMPLETE_TITLE = "Stock Out Completed";

export const SALE_ORDER_STATUS_DATASOURCE = [
  {
    id: SALE_ORDER_PENDING,
    name: SALE_ORDER_PENDING_TITLE,
  },
  {
    id: SALE_ORDER_STOCK_PROCESS,
    name: SALE_ORDER_STOCK_PROCESS_TITLE,
  },
  {
    id: SALE_ORDER_COMPLETED,
    name: SALE_ORDER_COMPLETED_TITLE,
  },
  {
    id: SALE_ORDER_CANCELLED,
    name: SALE_ORDER_CANCELLED_TITLE,
  },
  {
    id: SALE_ORDER_WAITING_APPROVAL,
    name: SALE_ORDER_WAITING_APPROVAL_TITLE,
  },
  {
    id: SALE_ORDER_SUBMIT,
    name: SALE_ORDER_SUBMIT_TITLE,
  },
  {
    id: SALE_ORDER_APPROVE,
    name: SALE_ORDER_APPROVE_TITLE,
  },
  {
    id: SALE_ORDER_REJECT,
    name: SALE_ORDER_REJECT_TITLE,
  },
  {
    id: SALE_ORDER_STOCK_OUT_COMPLETE,
    name: SALE_ORDER_STOCK_OUT_COMPLETE_TITLE,
  },
];


export const SALE_ORDER_ITEM_STATUS_DRAFT = 1;
export const SALE_ORDER_ITEM_STATUS_DRAFT_TITLE = "Draft";
export const SALE_ORDER_ITEM_STATUS_REJECTED = 2;
export const SALE_ORDER_ITEM_STATUS_REJECTED_TITLE = "Rejected";
export const SALE_ORDER_ITEM_STATUS_APPROVED = 3;
export const SALE_ORDER_ITEM_STATUS_APPROVED_TITLE = "Approved"; 

export const QUOTATION_STOCK_STATUS_IN_STOCK = 1;
export const QUOTATION_STOCK_STATUS_IN_STOCK_TITLE = "In Stock";
export const QUOTATION_STOCK_STATUS_OUT_OF_STOCK = 2;
export const QUOTATION_STOCK_STATUS_OUT_OF_STOCK_TITLE = "Out of Stock";

export const QUOTATION_STOCK_STATUS_DATA_SOURCE = [
  {
    id: QUOTATION_STOCK_STATUS_IN_STOCK,
    name: QUOTATION_STOCK_STATUS_IN_STOCK_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_OUT_OF_STOCK,
    name: QUOTATION_STOCK_STATUS_OUT_OF_STOCK_TITLE,
  }
];

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1 = 1;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1_TITLE = "1 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2 = 2;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2_TITLE = "2 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3 = 3;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3_TITLE = "3 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4 = 4;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4_TITLE = "3-4 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5 = 5;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5_TITLE = "4-6 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6 = 6;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6_TITLE = "6-8 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7 = 7;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7_TITLE = "8-10 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8 = 8;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8_TITLE = "10-12 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9 = 9;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9_TITLE = "12-14 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_10 = 10;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_10_TITLE = "22-24 Week";

export const QUOTATION_STOCK_STATUS_DURATION = [
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_10,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_10_TITLE,
  },
];

export const INSTALLATION_SUBMIT_RULES = {
  installationId: {
    required: true,
    type: "array",
    message: "Please select at least 1",
  },
};

export const SALE_ORDER_APPROVE_SUBMIT_RULES = {
  saleOrderItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "price",
        type: "number",
        min: 0,
      },
    ]
  },
};