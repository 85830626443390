import { 
  JobTypeSchema,
  JobTypeArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as JobTypeConstants from "../constants";

const path = "/human-resource/employee-directory/job-type";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getJobType,
    schema: JobTypeArraySchema,
    requestName: JobTypeConstants.JOB_TYPE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createJobType = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createJobType,
    requestName: JobTypeConstants.JOB_TYPE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getJobTypeAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getJobTypeAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getJobTypeDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getJobTypeDetail,
    requestName: JobTypeConstants.JOB_TYPE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: JobTypeSchema,
  };
  return createDetailAction(options, id);
};

export const editJobType = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editJobType,
    requestName: JobTypeConstants.JOB_TYPE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "jobTypes",
    schema: JobTypeSchema,
    successMessage: "Job type is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveJobType = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveJobType,
    requestName: JobTypeConstants.JOB_TYPE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Job type is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteJobType = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteJobType,
    requestName: JobTypeConstants.JOB_TYPE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Job type is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveJobType = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveJobType,
    requestName: JobTypeConstants.JOB_TYPE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Job type is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};