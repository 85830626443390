/* eslint-disable react/no-unescaped-entities */
import React from "react";
import moment from "moment";
import { message } from "antd";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";

// components
import AuthenticationLayout from "@/components/layout/authentication-layout";
import { resetPasswordVerifyCode, resetPasswordSendCode } from "@/services/authentication";
import { USER_OTP_HAS_BEEN_EXPIRED } from "src/constants/authentication";

const Verification = (props) => {

  const { email, onSetOtp, setVerified } = props;

  const otpInputRef = React.useRef(null);
  const [code, setCode] = React.useState(null);
  const [timeLeft, setTimeLeft] = React.useState(600);
  const [error, setError] = React.useState(null);
  const [resendLoading, setResendLoading] = React.useState(false);
  
  const subDomain = location.host.split(".")[0];

  React.useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onBackToFirstInput = () => {
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
  };

  const onResendClick = async () => {
    setCode(null);
    setResendLoading(true);
    await resetPasswordSendCode({email, subDomain}).then(() => {
      setResendLoading(false);
      setTimeLeft(600);
    }).catch((error) => {
      message.error(error.message);
      setResendLoading(false);
    });
  };

  const onCodeChange = async (code) => {
    const subDomain = location.host.split(".")[0];
    const values = {
      code,
      subDomain,
    };
    setCode(code);
    setError(null);
    if (code.length == 6) {
      await resetPasswordVerifyCode(values).then(() => {
        if (setVerified) {
          setVerified(true);
        }
        if (onSetOtp) {
          onSetOtp(code);
          alert(code);
        }
        setError(null);
      }).catch(() => {
        setError(true);
        setTimeout(() => {
          setCode(null);
          setError(null);
          onBackToFirstInput();
        }, 2000);
      });
    }
  };
  return (
    <AuthenticationLayout>
      <h6 className="text-center">
          Please type the verification code sent to
        <div className="mt-1 mb-0 font-weight-bold">
          {email}
        </div>
      </h6>
      <div className="d-flex align-items-center justify-content-center otp">
        <OtpInput 
          value={code}
          numInputs={6}
          errorStyle="error"
          ref={otpInputRef}
          hasErrored={error != null}
          shouldAutoFocus={true}
          onChange={onCodeChange}
          inputStyle="input-style"
        />
      </div>
      {error && (
        <div className="text-center mt-1 text-danger">
          {error && error.errorCode == USER_OTP_HAS_BEEN_EXPIRED ? (
            "Code has been Expired"
          ) : (
            "Code is incorrect"
          )}
        </div>
      )}
      <br />
      <div className="d-flex justify-content-center mt-2">
        <div>
          {timeLeft == 0 ? (
            <h6>
              Didn't received code ?
            </h6>
          ) : (
            <h6>
              Request New Code in {moment.utc(timeLeft*1000).format("HH:mm:ss")}
            </h6>
          )}
          {timeLeft == 0 && (
            <h6 className="text-center">
              <a onClick={() => onResendClick()} >
                {resendLoading ? "Sending..." : "Resend code"}
              </a>
            </h6>
          )}
        </div>
      </div>
    </AuthenticationLayout>
  );
};

Verification.propTypes = {
  email: PropTypes.string,
  onSetOtp: PropTypes.func,
  setVerified: PropTypes.func,
};

export default Verification;