import React from "react";
import { 
  AuditOutlined,
  HistoryOutlined,
  SnippetsOutlined,
  IdcardOutlined,
  DesktopOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  DollarCircleOutlined,
  UserSwitchOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

// Components
import Asset from "../components/asset";
import Payslip from "../components/payslip";
// import Employee from "./components/employee";
import JobHistory from "../components/job-history";
import SaleHistory from "../components/sale-history";
import CustomerResponse from "../components/customer-response";
import Attendance from "../components/attendance";
// import Account from "./components/account";
import LeaveRequest from "../components/leave-request";
import CashAdvanced from "../components/cash-advance";
import SessionList from "../components/session";
import CustomerCommissionList from "../components/customer-commission";

// Constants
import * as ModulesSlugConstants from "@/constants/modules";
import { READ_CUSTOMER_COMMISSION_SELF_SERVICE } from "src/modules/accounting/list/customer-commission/constants";

export const operationTabList = (isInstalled, isAllowed) => {
  return [
    {
      key: "customer-response",
      slug: isInstalled(ModulesSlugConstants.CRM_SLUG),
      show: true,
      component: CustomerResponse,
      tab: (
        <span>
          <UsergroupAddOutlined />Customer Response 
        </span>
      ),
    },
    {
      key: "customer-commission",
      slug: isInstalled(ModulesSlugConstants.ACCOUNTING_SLUG),
      show: isAllowed?.(READ_CUSTOMER_COMMISSION_SELF_SERVICE),
      component: CustomerCommissionList,
      tab: (
        <span>
          <UserAddOutlined />Customer Commission
        </span>
      ),
    },
    {
      key: "payslip",
      component: Payslip,
      show: true,
      slug: isInstalled(ModulesSlugConstants.PAY_ROLL_SLUG),
      tab: (
        <span>
          <DollarCircleOutlined />Payslip
        </span>
      ),
    },
    {
      key: "attendance",
      component: Attendance,
      show: true,
      slug: isInstalled(ModulesSlugConstants.ATTENDANCE_SLUG),
      tab: (
        <span>
          <ClockCircleOutlined />Attendance
        </span>
      ),
    },
    {
      key: "leave-request",
      component: LeaveRequest,
      show: true,
      slug: isInstalled(ModulesSlugConstants.LEAVE_SLUG),
      tab: (
        <span>
          <AuditOutlined />Leave Request
        </span>
      ),
    },
    {
      key: "asset",
      component: Asset,
      slug: isInstalled(ModulesSlugConstants.STOCK_SLUG),
      tab: (
        <span>
          <DesktopOutlined />Asset
        </span>
      ),
    },
    {
      key: "sale-history",
      component: SaleHistory,
      show: true,
      slug: isInstalled(ModulesSlugConstants.INVOICE_SLUG),
      tab: (
        <span>
          <HistoryOutlined />Sale History
        </span>
      ),
    },
    {
      key: "cash-advanced",
      component: CashAdvanced,
      show: true,
      slug: isInstalled(ModulesSlugConstants.CASH_ADVANCE_SLUG),
      tab: (
        <span>
          <SnippetsOutlined />Cash Advanced
        </span>
      ),
    },
    {
      key: "job-history",
      component: JobHistory,
      show: true,
      slug: isInstalled(ModulesSlugConstants.PAY_ROLL_SLUG),
      tab: (
        <span>
          <IdcardOutlined />Job History
        </span>
      ),
    },
    {
      key: "session",
      show: true,
      component: SessionList,
      slug: true,
      tab: (
        <span>
          <UserSwitchOutlined />Sessions
        </span>
      ),
    },
  ];
};