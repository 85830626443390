import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout, Drawer, Button } from "antd";
import { LeftOutlined, RightOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getDefaultCompany } from "@modules/utils/value-selector";

// Component
import SideBarMenu from "./menu/";

// Constant
import { Ipad } from "@/constants/media-screen";

// Style
import "./index.less";
import packageJson from "../../../../package.json";

const { Sider } = Layout;

const DrawerWrapper = styled(Drawer)`
  top: 0px;
  
  .ant-drawer-mask {
    top: -25%;
    position: absolute;
    height: 125vh !important;
  }

  @media ${Ipad} {
    top: 0px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .ant-menu-item > a {
    color: ${props => props.isDark == true ? "#FFFFFF" : props.navBackground} !important;
  }

  .ant-menu-item-selected {
    background: ${props => props.isDark == true ? "inhertit" : props.navBackground} !important;
    a {
      color: ${props => props.isDark == true ? props.activeColor : props.navLinkColor} !important;
    }
  }

  .ant-menu-dark .ant-menu-item-selected > a {
    color: ${props => props.isDark == true ? props.navLinkColor : props.navLinkColor} !important;
  }

`;

const ToggleDrawerButton = styled(Button)`
  top: 50%;
  left: -5px;
  border: 0;
  z-index: 100;
  position: fixed;
  border-radius: 0px;
  box-shadow: 0px 4px 16px -8px rgba(38, 35, 66, 0.75);
`;

const SideBarWrapper = styled.div`
  .ant-menu-item > a {
    color: ${props => props.isDark == true ? "#FFFFFF" : props.navBackground} !important;
  }

  .ant-menu-item-selected {
    background: ${props => props.isDark == true ? "inhertit" : props.navBackground} !important;
    a {
      color: ${props => props.isDark == true ? props.activeColor : props.navLinkColor} !important;
    }
  }

  .ant-menu-dark .ant-menu-item-selected > a {
    color: ${props => props.isDark == true ? props.navLinkColor : props.navLinkColor} !important;
  }
`;

class LayoutSideBar extends Component {

  constructor(props) {
    super(props);
    const paths = this.getPath(props);
    paths.splice(0, 1);
    const currentPaths = [
      `${paths.splice(0, 1)}/${paths.splice(0, 1)}`,
      ...paths,
    ];
    this.state = {
      current: currentPaths,
      openKeys: currentPaths,
      visible: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    const { newLocation } = newProps;
    const { location } = this.props;
    if(newLocation !== location) {
      const paths = this.getPath(newProps);
      paths.splice(0, 1);
      const currentPaths = [
        `${paths.splice(0, 1)}/${paths.splice(0, 1)}`,
        ...paths,
      ];
      this.setState({
        openKeys: currentPaths,
      });
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  }

  closeDrawer = () => {
    this.setState({
      visible: false,
    });
  }

  getPath = (props) => {
    const { location: { pathname } } = props;
    const paths = pathname.split("/");
    return paths;
  }

  onOpenChange = (openKeys) => {
    const { menu } = this.props;
    const { menus } = menu;
    const paths = this.getPath(this.props);
    const firstPath = paths[1];
    const rootSubMenuKeys = [];
    menus && menus.map(rootItems => {
      rootSubMenuKeys.push(`${firstPath}/${rootItems.slug}`);
    });
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    );
    if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  }

  render() {
    const {
      menu,
      theme,
      onToggle,
      collapsed,
      company,  
    } = this.props;
    const {
      slug,
      menus,
      display,
      sideBarTitle,
    } = menu;
    const firstPath = menu.slug;
    const paths = this.getPath(this.props);
    const { current, openKeys, visible } = this.state;
    return (
      <Media query={{ maxWidth: 1024 }}>
        {matches =>
          matches ? (
            <SideBarWrapper
              isDark={theme == "dark"}
              activeColor={company && company.activeColor} 
              navLinkColor={company && company.navLinkColor}
              navBackground={company && company.navBackground} 
              activeBackground={company && company.activeBackground}
            >
              {display && (
                <Fragment>
                  <ToggleDrawerButton
                    type="default"
                    onClick={this.showDrawer}
                  >
                    <MenuUnfoldOutlined />
                  </ToggleDrawerButton>
                  <DrawerWrapper
                    placement="left"
                    closable={true}
                    visible={visible}
                    title={sideBarTitle}
                    onClose={this.closeDrawer} 
                    isDark={theme == "dark"}
                    activeColor={company && company.activeColor} 
                    navLinkColor={company && company.navLinkColor}
                    navBackground={company && company.navBackground} 
                    activeBackground={company && company.activeBackground}
                  >
                    <SideBarMenu
                      slug={slug}
                      theme={theme}
                      menus={menus}
                      openKeys={openKeys}
                      selectedKeys={paths}
                      firstPath={firstPath}
                      defaultOpenKeys={current}
                      onOpenChange={this.onOpenChange}
                    />
                  </DrawerWrapper>
                </Fragment>
              )}
            </SideBarWrapper>
          ) : (
            <SideBarWrapper
              isDark={theme == "dark"}
              activeColor={company && company.activeColor} 
              navLinkColor={company && company.navLinkColor}
              navBackground={company && company.navBackground} 
              activeBackground={company && company.activeBackground}
            >
              {display && (
                <Sider
                  width={230}
                  collapsible 
                  collapsedWidth={80}
                  theme={theme}
                  collapsed={collapsed}
                  className="sidebar"
                  onCollapse={onToggle}
                  trigger={
                    <p className="text-secondary">
                      {collapsed ? `V: ${packageJson.version}` : `Version: ${packageJson.version}` }
                    </p>
                  }
                >
                  <div className="d-flex justify-content-between align-items-center pl-3 border-wrapper">
                    {!collapsed && (
                      <h6>
                        {sideBarTitle}
                      </h6>
                    )}
                    <div className={`trigger text-secondary d-flex justify-content-${collapsed ? "center" : "end"}`} onClick={onToggle}>
                      {collapsed ? <RightOutlined /> : <LeftOutlined />}
                    </div>
                  </div>
                  <SideBarMenu
                    slug={slug}
                    theme={theme}
                    menus={menus}
                    openKeys={openKeys}
                    selectedKeys={paths}
                    firstPath={firstPath}
                    collapsed={collapsed}
                    defaultOpenKeys={current}
                    onOpenChange={this.onOpenChange}
                  />
                </Sider>
              )}
            </SideBarWrapper>
          )
        }
      </Media>
    );
  }
}

LayoutSideBar.propTypes = {
  menu: PropTypes.object,
  theme: PropTypes.state,
  collapsed: PropTypes.bool,
  location: PropTypes.object,
  company: PropTypes.object,
  onToggle: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    company: getDefaultCompany(state),
  };
};

export default withRouter(connect(mapStateToProps)(LayoutSideBar));