export const READ_SALE_SETTING_CODE = 196012;
export const UPDATE_SALE_SETTING_CODE = 196013;
export const SALE_SETTING_PATH = "/sale/setup/sale-setting";
export const SALE_SETTING_ALLOW_ITEM_PRICE_MODIFICATION = "allowItemPriceModification";
export const SALE_SETTING_ALLOW_CUSTOMER_COMMISSION = "allowCustomerCommission";
export const SALE_SETTING_ALLOW_TO_USE_CUSTOMER_COMMISSION = "allowToUseCustomerCommission";
export const SALE_SETTING_SALE_TAX = "saleTax";

export const SALE_SETTING_SUBMIT_RULES = (values) => {
  const { isAccountingInstalled } = values;
  return {
    defaultPaymentTerm: {
      type: "object",
      value: {
        required: true
      }
    },
    saleTax: {
      type: "object",
      value: {
        required: true
      }
    },
    defaultPriceList: {
      type: "object",
      value: {
        required: true
      }
    },
    defaultCostChartAccount: {
      type: "object",
      value: {
        required: isAccountingInstalled
      }
    },
    defaultSaleChartAccount: {
      type: "object",
      value: {
        required: isAccountingInstalled
      }
    },
    expenseBidChartAccountConfiguration: {
      type: "object",
      value: {
        required: isAccountingInstalled
      }
    }
  };
};