export const LOG_IN_APPROVAL_REQUEST= "LOG_IN_APPROVAL_REQUEST";
export const LOG_IN_APPROVAL_SUCCESS= "LOG_IN_APPROVAL_REQUEST_SUCCESS";

export const LOG_IN_APPROVAL_APPROVE_REQUEST= "LOG_IN_APPROVAL_APPROVE_REQUEST";
export const LOG_IN_APPROVAL_REJECT_REQUEST= "LOG_IN_APPROVAL_REJECT_REQUEST";

export const READ_LOG_IN_APPROVAL = 241000;
export const APPROVE_LOG_IN_APPROVAL = 241011;
export const REJECT_LOG_IN_APPROVAL = 241012;
export const READ_LOG_IN_APPROVAL_SETTING = 241013;
export const UPDATE_LOG_IN_APPROVAL_SETTING = 241014;

export const LOG_IN_APPROVAL_STATUS_WAITING_APPROVAL = 1;
export const LOG_IN_APPROVAL_STATUS_APPROVED = 2;
export const LOG_IN_APPROVAL_STATUS_REJECTED = 3;

export const LOG_IN_APPROVAL_STATUS_WAITING_APPROVAL_TITLE = "Waiting Approval";
export const LOG_IN_APPROVAL_STATUS_APPROVED_TITLE = "Approved";
export const LOG_IN_APPROVAL_STATUS_REJECTED_TITLE = "Rejected";

export const LOG_IN_APPROVAL_STATUS_DATA_SOURCE = [
  {
    id: LOG_IN_APPROVAL_STATUS_WAITING_APPROVAL,
    name: LOG_IN_APPROVAL_STATUS_WAITING_APPROVAL_TITLE
  },
  {
    id: LOG_IN_APPROVAL_STATUS_APPROVED,
    name: LOG_IN_APPROVAL_STATUS_APPROVED_TITLE,
  },
  {
    id: LOG_IN_APPROVAL_STATUS_REJECTED,
    name: LOG_IN_APPROVAL_STATUS_REJECTED_TITLE
  }
];