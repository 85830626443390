// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import FilterComponent from "@cores/components/filter-box";
import AsyncMonthPicker from "@cores/components/async-month-picker";
import AsyncEmployeeAutoComplete from "@cores/components/async-auto-complete/employee";
import AsyncSalaryStructureAutoComplete from "@cores/components/async-auto-complete/salary-structure";
import AsyncStatusAutoComplete from "@cores/components/async-auto-complete/async-status-auto-complete";

// Utils
import { getQueryParamUrl, updateQueryParamUrl } from "@modules/helpers";
import { PAYSLIP_STATUS_DATASOURCE } from "../../constants";

class FilterBox extends Component {

  onReset = () => {
    const { isViewOtherPayslip, currentUser, hideEmployee } = this.props;
    if (!hideEmployee) {
      let query = getQueryParamUrl();
      if (!isViewOtherPayslip) {
        query.employee = [currentUser && currentUser.employee.id];
        updateQueryParamUrl(query);
      }
    }
  }

  render() {
    const { hideEmployee, currentUser, isViewOtherPayslip, ...props } = this.props;
    return (
      <FilterComponent onReset={this.onReset} {...props}>
        {!hideEmployee && (
          <AsyncEmployeeAutoComplete 
            disabled={!isViewOtherPayslip}
            extraIds={[currentUser && currentUser.employee && currentUser.employee.id]}
          />
        )}
        <AsyncMonthPicker 
          name="month"
          placeholder="Month"
        />
        <AsyncSalaryStructureAutoComplete />
        <AsyncStatusAutoComplete 
          dataSource={PAYSLIP_STATUS_DATASOURCE}
        />
      </FilterComponent>
    );
  }
}

FilterBox.propTypes = {
  match: PropTypes.object,
  hideEmployee: PropTypes.object,
  permission: PropTypes.object,
  currentUser: PropTypes.object,
  isViewOtherPayslip: PropTypes.object,
};

export default FilterBox;