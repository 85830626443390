const SALE_PATH = "/sale";
const SETUP_PATH = "setup";
const SERVICES_PATH = "services";
const SALL_CENTER_PATH = "sale-center";
const CUSTOMER_CENTER_PATH = "customer-center";

export const sale = [
  {
    name: "Quotation List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/quotation`,
  },
  {
    name: "Quotation Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/quotation`,
  },
  {
    name: "Quotation Print",
    type: "print",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/quotation`,
  },
  {
    name: "Sale Order List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-order`,
  },
  {
    name: "Sale Order Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-order`,
  },
  {
    name: "Sale Order Print",
    type: "print",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-order`,
  },
  {
    name: "Create Sale Order",
    type: "create",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-order`,
  },
  {
    name: "Edit Sale Order",
    type: "edit",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-order`,
  },
  // ==================================
  {
    name: "Sale Invoice List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Sale Invoice Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Sale Invoice Print",
    type: "print",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Edit Invoice Order",
    type: "edit",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Sale Receipt List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-receipt`,
  },
  // =================================
  {
    name: "Sale Invoice List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Sale Invoice Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Sale Invoice Print",
    type: "print",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-invoice`,
  },
  {
    name: "Sale Receipt List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-receipt`,
  },
  {
    name: "Sale Receipt Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-receipt`,
  },
  {
    name: "Sale Receipt Print",
    type: "print",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/sale-receipt`,
  },
  {
    name: "Bid List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/bid`,
  },
  {
    name: "Bid Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/bid`,
  },
  {
    name: "Create Bid",
    type: "create",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/bid`,
  },
  {
    name: "Edit Bid",
    type: "edit",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/bid`,
  },
  {
    name: "Promotion List",
    type: "list",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/promotion`,
  },
  {
    name: "Promotion Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/promotion`,
  },
  {
    name: "Create Promotion",
    type: "create",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/promotion`,
  },
  {
    name: "Edit Promotion",
    type: "edit",
    slug: `${SALE_PATH}/${SALL_CENTER_PATH}/promotion`,
  },
  //customer center
  {
    name: "Customer List",
    type: "list",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer`,
  },
  {
    name: "Customer Detail",
    type: "detail",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer`,
  },
  {
    name: "Create Customer",
    type: "create",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer`,
  },
  {
    name: "Edit Customer",
    type: "edit",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer`,
  },
  {
    name: "Customer Company List",
    type: "list",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-company`,
  },
  {
    name: "Customer Company Detail",
    type: "detail",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-company`,
  },
  {
    name: "Create Customer Company",
    type: "create",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-company`,
  },
  {
    name: "Edit Customer Company",
    type: "edit",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-company`,
  },
  {
    name: "Customer Group List",
    type: "list",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-group`,
  },
  {
    name: "Customer Group Detail",
    type: "detail",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-group`,
  },
  {
    name: "Create Customer Group",
    type: "create",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-group`,
  },
  {
    name: "Edit Customer Group",
    type: "edit",
    slug: `${SALE_PATH}/${CUSTOMER_CENTER_PATH}/customer-group`,
  },
  //services
  {
    name: "Service Entry List",
    type: "list",
    slug: `${SALE_PATH}/${SERVICES_PATH}/service-entry`,
  },
  {
    name: "Service Entry Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SERVICES_PATH}/service-entry`,
  },
  {
    name: "Create Service Entry",
    type: "create",
    slug: `${SALE_PATH}/${SERVICES_PATH}/service-entry`,
  },
  {
    name: "Edit Service Entry",
    type: "edit",
    slug: `${SALE_PATH}/${SERVICES_PATH}/service-entry`,
  },
  {
    name: "Item Installation List",
    type: "list",
    slug: `${SALE_PATH}/${SERVICES_PATH}/item-installation`,
  },
  {
    name: "Item Installation Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SERVICES_PATH}/item-installation`,
  },
  {
    name: "Installation List",
    type: "list",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation`,
  },
  {
    name: "Installation Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation`,
  },
  {
    name: "Create Installation",
    type: "create",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation`,
  },
  {
    name: "Edit Installation",
    type: "edit",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation`,
  },
  {
    name: "Installation Template List",
    type: "list",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation-template`,
  },
  {
    name: "Installation Template Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation-template`,
  },
  {
    name: "Create Installation Template",
    type: "create",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation-template`,
  },
  {
    name: "Edit Installation Template",
    type: "edit",
    slug: `${SALE_PATH}/${SERVICES_PATH}/installation-template`,
  },
  //setup
  {
    name: "Payment Term List",
    type: "list",
    slug: `${SALE_PATH}/${SETUP_PATH}/payment-term`,
  },
  {
    name: "Payment Term Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SETUP_PATH}/payment-term`,
  },
  {
    name: "Create Payment Term",
    type: "create",
    slug: `${SALE_PATH}/${SETUP_PATH}/payment-term`,
  },
  {
    name: "Edit Payment Term",
    type: "edit",
    slug: `${SALE_PATH}/${SETUP_PATH}/payment-term`,
  },
  {
    name: "Sale Commission List",
    type: "list",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-commission`,
  },
  {
    name: "Sale Commission Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-commission`,
  },
  {
    name: "Create Sale Commission",
    type: "create",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-commission`,
  },
  {
    name: "Edit Sale Commission",
    type: "edit",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-commission`,
  },
  {
    name: "Team List",
    type: "list",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-team`,
  },
  {
    name: "Sale Team Detail",
    type: "detail",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-team`,
  },
  {
    name: "Create Team",
    type: "create",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-team`,
  },
  {
    name: "Edit Sale Team",
    type: "edit",
    slug: `${SALE_PATH}/${SETUP_PATH}/sale-team`,
  },
];