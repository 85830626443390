/* eslint-disable indent */
import {
  toDate,
  toShortDate,
  toTime,
} from "@modules/helpers";
import * as Constants from "../constants";

export const renderCustomFieldType = (id) => {
  if (id) {
    const matched = Constants.CUSTOMER_FIELD_INPUT_DATA_SOURCE?.find((value) => value.id == id);
    if (matched) {
      return matched?.name;
    }
    return "unknown";
  }
  return "unknown";
};

export const renderCustomFieldValue = (value, type) => {
  switch (type) {
    case Constants.CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX:
      return Boolean(value);
    case Constants.CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER:
      return toShortDate(value);
    case Constants.CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER:
      return toDate(value);
    case Constants.CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER:
      return toTime(value);
    default:
      return value;
  }
};