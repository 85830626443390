export const POSITION_LIST_REQUEST = "POSITION_LIST_REQUEST"; 
export const POSITION_LIST_SUCCESS = "POSITION_LIST_REQUEST_SUCCESS";
export const POSITION_CREATE_REQUEST = "POSITION_CREATE_REQUEST"; 
export const POSITION_DETAIL_REQUEST = "POSITION_DETAIL_REQUEST"; 
export const POSITION_ARCHIVE_REQUEST = "POSITION_ARCHIVE_REQUEST"; 
export const POSITION_DELETE_REQUEST = "POSITION_DELETE_REQUEST"; 
export const POSITION_UNARCHIVE_REQUEST = "POSITION_UNARCHIVE_REQUEST"; 
export const POSITION_EDIT_REQUEST = "POSITION_EDIT_REQUEST"; 
export const POSITION_CREATE_FORM_NAME = "createPosition"; 
export const POSITION_EDIT_FORM_NAME = "editPosition"; 
export const POSITION_AUTOCOMPLETE_REQUEST = "POSITION_AUTOCOMPLETE_REQUEST";
export const POSITION_AUTOCOMPLETE_SUCCESS = "POSITION_AUTOCOMPLETE_SUCCESS";
export const POSITION_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  code: {
    required: true
  }
};
export const READ_POSITION = 147000;
export const CREATE_POSITION = 147001;
export const UPDATE_POSITION = 147002;
export const DELETE_POSITION = 147003;
export const ARCHIVE_POSITION = 147004;
export const UNARCHIVE_POSITION = 147005;