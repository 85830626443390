// Utils
import { toShortDate } from "@modules/helpers";

import * as NotificationConstants from "@/constants/notification";

// Leave Requests
import { LEAVE_REQUEST_STATUS_SUBMITTED } from "@modules/hr/leave/leave-request/constants";
import { updateStatusToApprove, updateStatusToReject } from "@modules/hr/leave/leave-request/actions";

// Leave Allocations
import { LEAVE_ALLOCATION_STATUS_SUBMITTED } from "@modules/hr/leave/leave-allocation/constants";
import { updateStatusToApprove as approveLeaveAllocation,  updateStatusToReject as rejectLeaveAllocation } from "@modules/hr/leave/leave-allocation/actions";

// stock out
import { STOCK_OUT_STATUS_SUBMITTED } from "@modules/inventory/stock/stock-out/constants";
import { approveStockOut, rejectStockOut } from "@modules/inventory/stock/stock-out/actions";

// // stock Adjustment
// import { STOCK_ADJUSTMENT_STATUS_SUBMITTED } from "@modules/Inventory/stock/stock-adjustment/constant";
// import { approveStockAdjustment, rejectStockAdjustment } from "@modules/Inventory/stock/stock-adjustment/action";

// stock Transfer
import { STOCK_TRANSFER_STATUS_SUBMITTED } from "@modules/inventory/stock/stock-transfer/constants";
import { approveStockTransfer, rejectStockTransfer } from "@modules/inventory/stock/stock-transfer/actions";

// junk Out
import {  JUNK_OUT_STATUS_SUBMITTED } from "@modules/inventory/stock/junk-out/constants";
import { approveJunkOut, rejectJunkOut } from "@modules/inventory/stock/junk-out/actions";

// asset request
import { ASSET_REQUEST_STATUS_SUBMITTED } from "@modules/inventory/asset/asset-request/constants";
import { approveAssetRequest, rejectAssetRequest } from "@modules/inventory/asset/asset-request/actions";

// purchase order
import { PURCHASE_ORDER_STATUS_SUBMITTED } from "@modules/purchase/list/purchase-order/constants";
import { approvePurchaseOrder, rejectPurchaseOrder } from "@modules/purchase/list/purchase-order/actions";

// expense
import { EXPENSE_STATUS_SUBMITTED } from "@modules/accounting/list/expense/constants";
import { approveExpense, rejectExpense } from "@modules/accounting/list/expense/actions";

// LogIn Approval
import { LOG_IN_APPROVAL_STATUS_APPROVED, LOG_IN_APPROVAL_STATUS_WAITING_APPROVAL } from "@modules/settings/user-management/log-in-approval/constants";
import { approveLoginApproval, rejectLoginApproval } from "@modules/settings/user-management/log-in-approval/actions";

// notification message
import { 
  getSubmittedMessage,
  getApprovedMessage,
  getRejectedMessage,
  getCancelledMessage,
  getProcessedMessage,
  getForceClosedMessage,
  getAssignMessage,
  getRequestMessage,
} from "./notification-message";

export const notificationEntities = {
  Employee: {
    labelKey: [
      "firstName",
      "lastName"
    ],
    path: `${NotificationConstants.EMPLOYEE_DIRECTORY_PATH}/employee`,
  },
  Position: {
    labelKey: "name",
    path: `${NotificationConstants.EMPLOYEE_DIRECTORY_PATH}/position`,
  },
  Department: {
    labelKey: "name",
    path: `${NotificationConstants.EMPLOYEE_DIRECTORY_PATH}/department`,
  },
  JobType: {
    labelKey: "name",
    path: `${NotificationConstants.EMPLOYEE_DIRECTORY_PATH}/job-type`,
  },
  EmploymentCard: {
    labelKey: "name",
    path: `${NotificationConstants.EMPLOYEE_DIRECTORY_PATH}/employment-card`,
  },
  // Recruitment
  JobOpening: {
    labelKey: "title",
    path: `${NotificationConstants.RECRUITMENT_PATH}/job-opening`,
  },
  JobApplicant: {
    labelKey: "title",
    path: `${NotificationConstants.RECRUITMENT_PATH}/job-applicant`,
  },
  RecruitmentStage: {
    labelKey: "title",
    path: `${NotificationConstants.RECRUITMENT_PATH}/stage`,
  },
  OfferLetter: {
    labelKey: "id",
    path: `${NotificationConstants.RECRUITMENT_PATH}/offer-letter`,
  },
  OfferTerm: {
    labelKey: "name",
    path: `${NotificationConstants.RECRUITMENT_PATH}/offer-term`,
  },
  // Leave
  LeaveRequest: {
    label: "leave request",
    path: `${NotificationConstants.LEAVE_PATH}/request`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_REQUEST_SUBMIT]: {
        render: () => {
          return getSubmittedMessage("a leave request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == LEAVE_REQUEST_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => updateStatusToApprove(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => updateStatusToReject(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_REQUEST_APPROVE]: getApprovedMessage("leave request"),
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_REQUEST_REJECT]: getRejectedMessage("leave request"),
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_REQUEST_CANCEL]: getCancelledMessage("leave request"),
    },
  },
  EmployeeAttendance: {
    newEntityName: "attendance",
    path: (entityKey) => `${NotificationConstants.ATTENDANCE_PATH}/view?id[]=${entityKey}`,
  },
  WorkingDay: {
    labelKey: "name",
    path: `${NotificationConstants.ATTENDANCE_PATH}/working-day`,
  },
  LeaveAllocation: {
    labelKey: "title",
    path: `${NotificationConstants.LEAVE_PATH}/allocation`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_ALLOCATION_SUBMIT]: {
        render: () => {
          return getSubmittedMessage("a leave allocation");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == LEAVE_ALLOCATION_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveLeaveAllocation(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectLeaveAllocation(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_ALLOCATION_APPROVE]: getApprovedMessage("leave allocation"),
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_ALLOCATION_REJECT]: getRejectedMessage("leave allocation"),
      [NotificationConstants.NOTIFICATION_TYPE_LEAVE_ALLOCATION_CANCEL]: getCancelledMessage("leave allocation"),
    },
  },
  LeaveType: {
    labelKey: "title",
    path: `${NotificationConstants.LEAVE_PATH}/type`,
  },
  LeavePolicy: {
    labelKey: "name",
    path: `${NotificationConstants.LEAVE_PATH}/policy`,
  },
  HolidayGroup: {
    labelKey: "name",
    path: `${NotificationConstants.LEAVE_PATH}/holiday`,
  },
  // Appraisal
  AppraisalForm: {
    labelKey: "title",
    label: "appraisal form",
    path: `${NotificationConstants.APPRAISAL_PATH}/form`,
  },
  AppraisalPlan: {
    labelKey: "title",
    path: `${NotificationConstants.APPRAISAL_PATH}/plan`,
  },
  // Payroll
  Payslip: {
    labelKey: "name",
    path: `${NotificationConstants.PAYROLL_PATH}/payslip`,
  },
  PayslipBatch: {
    labelKey: "name",
    path: `${NotificationConstants.PAYROLL_PATH}/payslip-batch`,
  },
  SalaryStructure: {
    labelKey: "name",
    path: `${NotificationConstants.PAYROLL_PATH}/salary-structure`,
  },
  EarningType: {
    labelKey: "name",
    path: `${NotificationConstants.PAYROLL_PATH}/earning-type`,
  },
  DeductionType: {
    labelKey: "name",
    path: `${NotificationConstants.PAYROLL_PATH}/deduction-type`,
  },
  // ++ Inventory ++
  // Item Catalog
  Category: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/category`,
  },
  Brand: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/brand`,
  },
  Tag: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/tag`,
  },
  Item: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/item`,
  },
  Specification: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/item-specification`,
  },
  Template: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/item-specification-template`,
  },
  PriceList: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/price-list`,
  },
  Supplier: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/supplier-type`,
  },
  SupplierType: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/supplier-type`,
  },
  ItemAttribute: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/item-attribute`,
  },
  CustomizeItem: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/customize-item`,
  },
  Warranty: {
    labelKey: "name",
    path: `${NotificationConstants.ITEM_CATALOG_PATH}/warranty`,
  },
  // Stock
  Warehouse: {
    labelKey: "name",
    path: `${NotificationConstants.STOCK_PATH}/warehouse`,
  },
  StockOut: {
    labelKey: "stockOutNo",
    entityKey: ((values) => values.payload && values.payload.stockOutNo),
    path: `${NotificationConstants.STOCK_PATH}/stock-out`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_OUT_REQUEST]: {
        render: () => {
          return getSubmittedMessage("a stock out request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == STOCK_OUT_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveStockOut(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectStockOut(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_OUT_APPROVE]: getApprovedMessage("stock out request"),
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_OUT_REJECT]: getRejectedMessage("stock out request"),
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_OUT_CANCEL]: getCancelledMessage("stock out request"),
    },
  },
  // StockAdjustment: {
  //   labelKey: "stockAdjustmentNo",
  //   entityKey: ((values) => values.payload && values.payload.stockAdjustmentNo),
  //   path: `${NotificationConstants.STOCK_PATH}/stock-adjustment`,
  //   message: {
  //     [NotificationConstants.NOTIFICATION_TYPE_STOCK_ADJUSTMENT_REQUEST]: {
  //       render: () => {
  //         return getSubmittedMessage("a stock adjustment request");
  //       },
  //       action: {
  //         shouldRender: (notification) => {
  //           return notification.payload && notification.payload.status == STOCK_ADJUSTMENT_STATUS_SUBMITTED;
  //         },
  //         items: [
  //           {
  //             title: "Approve",
  //             type: "primary",
  //             handler: (notification) => approveStockAdjustment(notification.payload && notification.payload.id)
  //           },
  //           {
  //             title: "Reject",
  //             handler: (notification) => rejectStockAdjustment(notification.payload && notification.payload.id)
  //           }
  //         ]
  //       }
  //     },
  //     [NotificationConstants.NOTIFICATION_TYPE_STOCK_ADJUSTMENT_APPROVE]: getApprovedMessage("stock adjustment request"),
  //     [NotificationConstants.NOTIFICATION_TYPE_STOCK_ADJUSTMENT_REJECT]: getRejectedMessage("stock adjustment request"),
  //     [NotificationConstants.NOTIFICATION_TYPE_STOCK_ADJUSTMENT_CANCEL]: getCancelledMessage("stock adjustment request"),
  //   },
  // },
  StockTransfer: {
    labelKey: "stockTransferNo",
    entityKey: ((values) => values.payload && values.payload.stockTransferNo),
    path: `${NotificationConstants.STOCK_PATH}/stock-transfer`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_TRANSFER_REQUEST]: {
        render: () => {
          return getSubmittedMessage("a stock transfer request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == STOCK_TRANSFER_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveStockTransfer(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectStockTransfer(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_TRANSFER_APPROVE]: getApprovedMessage("stock transfer request"),
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_TRANSFER_REJECT]: getRejectedMessage("stock transfer request"),
      [NotificationConstants.NOTIFICATION_TYPE_STOCK_TRANSFER_CANCEL]: getCancelledMessage("stock transfer request"),
    },
  },
  JunkOut: {
    labelKey: "junkOutNo",
    entityKey: ((values) => values.payload && values.payload.junkOutNo),
    path: `${NotificationConstants.STOCK_PATH}/junk-out`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_JUNK_OUT_REQUEST]: {
        render: () => {
          return getSubmittedMessage("a junk out request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == JUNK_OUT_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveJunkOut(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectJunkOut(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_JUNK_OUT_APPROVE]: getApprovedMessage("junk out request"),
      [NotificationConstants.NOTIFICATION_TYPE_JUNK_OUT_REJECT]: getRejectedMessage("junk out request"),
      [NotificationConstants.NOTIFICATION_TYPE_JUNK_OUT_CANCEL]: getCancelledMessage("junk out request"),
    },
  },
  // Asset
  AssetRequest: {
    labelKey: "name",
    path: `${NotificationConstants.ASSET_PATH}/asset-request`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_ASSET_REQUEST_REQUEST]: {
        render: () => {
          return getSubmittedMessage("a asset request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == ASSET_REQUEST_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveAssetRequest(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectAssetRequest(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_ASSET_REQUEST_APPROVE]: getApprovedMessage("asset request"),
      [NotificationConstants.NOTIFICATION_TYPE_ASSET_REQUEST_REJECT]: getRejectedMessage("asset request"),
      [NotificationConstants.NOTIFICATION_TYPE_ASSET_REQUEST_CANCEL]: getCancelledMessage("asset request"),
    },
  },
  // Delivery
  DeliverOrder: {
    labelKey: "expectedDateTime",
    entityKey: ((values) => toShortDate(values.payload && values.payload.expectedDateTime)),
    path: `${NotificationConstants.DELIVERY_PATH}/delivery-order`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_DELIVERY_ORDER_ASSIGN]: getAssignMessage("delivery order"),
    },
  },
  // ++ Purchase ++
  PurchaseOrder: {
    labelKey: "purchaseOrderNo",
    entityKey: ((values) => values.payload && values.payload.purchaseOrderNo),
    path: `${NotificationConstants.PURCHASE_LIST_PATH}/purchase-order`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_PURCHASE_ORDER_REQUEST]: {
        render: () => {
          return getSubmittedMessage("a purchase order request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == PURCHASE_ORDER_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approvePurchaseOrder(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectPurchaseOrder(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_PURCHASE_ORDER_APPROVE]: getApprovedMessage("purchase order request"),
      [NotificationConstants.NOTIFICATION_TYPE_PURCHASE_ORDER_REJECT]: getRejectedMessage("purchase order request"),
      [NotificationConstants.NOTIFICATION_TYPE_PURCHASE_ORDER_CANCEL]: getCancelledMessage("purchase order request"),
      [NotificationConstants.NOTIFICATION_TYPE_PURCHASE_ORDER_PROCESS]: getProcessedMessage("purchase order request"),
      [NotificationConstants.NOTIFICATION_TYPE_PURCHASE_ORDER_FORCE_CLOSE]: getForceClosedMessage("purchase order request"),
    }
  },
  PurchaseOrderType: {
    labelKey: "name",
    path: `${NotificationConstants.PURCHASE_LIST_PATH}/purchase-order-type`,
  },
  Shipper: {
    labelKey: "name",
    path: `${NotificationConstants.PURCHASE_LIST_PATH}/shipper`,
  },
  // ++ Sale ++
  Customer: {
    labelKey: [
      "firstName",
      "lastName"
    ],
    path: `${NotificationConstants.CUSTOMER_CENTER_PATH}/customer`,
  },
  CustomerGroup: {
    labelKey: "name",
    path: `${NotificationConstants.CUSTOMER_CENTER_PATH}/customer-group`,
  },
  CustomerCompany: {
    labelKey: "name",
    path: `${NotificationConstants.CUSTOMER_CENTER_PATH}/customer-company`,
  },
  PaymentTerm: {
    labelKey: "name",
    path: `${NotificationConstants.SET_UP_PATH}/payment-term`,
  },
  Quotation: {
    labelKey: "quotationNo",
    entityKey: ((values) => values.payload && values.payload.quotationNo),
    path: `${NotificationConstants.SALE_LIST_PATH}/quotation`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_QUOTATION_REQUEST_APPROVAL]: getRequestMessage("quotation price approval"),
      [NotificationConstants.NOTIFICATION_TYPE_QUOTATION_APPROVE]: getApprovedMessage("quotation"),
      [NotificationConstants.NOTIFICATION_TYPE_QUOTATION_REJECT]: getRejectedMessage("quotation")
    }
  },
  SaleOrder: {
    labelKey: "saleOrderNo",
    entityKey: ((values) => values.payload && values.payload.saleOrderNo),
    path: `${NotificationConstants.SALE_LIST_PATH}/sale-order`,
  },
  SaleTeam: {
    labelKey: "name",
    path: `${NotificationConstants.SET_UP_PATH}/sale-team`,
  },
  Invoice: {
    labelKey: "invoiceNo",
    entityKey: ((values) => values.payload && values.payload.invoiceNo),
    path: `${NotificationConstants.SALE_LIST_PATH}/sale-invoice`,
  },
  Bid: {
    labelKey: "bidNo",
    entityKey: ((values) => values.payload && values.payload.bidNo),
    path: `${NotificationConstants.SALE_LIST_PATH}/bid`,
  },
  Promotion: {
    labelKey: "name",
    path: `${NotificationConstants.SALE_LIST_PATH}/promotion`,
  },
  SaleCommission: {
    labelKey: "name",
    path: `${NotificationConstants.SET_UP_PATH}/sale-commission`,
  },
  SaleReceipt: {
    labelKey: "saleReceiptNo",
    entityKey: ((values) => values.payload && values.payload.saleReceiptNo),
    path: `${NotificationConstants.SALE_LIST_PATH}/sale-receipt`,
  },
  Installation: {
    labelKey: "name",
    path: `${NotificationConstants.SALE_SERVICE_PATH}/installation`,
  },
  InstallationTemplate: {
    labelKey: "name",
    path: `${NotificationConstants.SALE_SERVICE_PATH}/installation-template`,
  },
  Service: {
    labelKey: "serviceNo",
    entityKey: ((values) => values.payload && values.payload.serviceNo),
    path: `${NotificationConstants.SALE_SERVICE_PATH}/service`,
  },
  // ++ Settings ++
  // User Management
  User: {
    labelKey: [
      "firstName",
      "lastName"
    ],
    path: `${NotificationConstants.SETTING_PATH_USER}/user-account`,
  },
  Role: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_USER}/role`,
  },
  LoginApproval: {
    labelKey: "name",
    path: (entityKey) => `${NotificationConstants.SETTING_PATH_USER}/log-in-approval?id=${entityKey}`,
    reloadWhenSuccess: (values) => {
      if (values.payload) {
        return values.payload.status == LOG_IN_APPROVAL_STATUS_APPROVED;
      }
      return null;
    },
    hideDetail: true,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_REQUEST]: {
        render: () => {
          return getRequestMessage("log in approval");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == LOG_IN_APPROVAL_STATUS_WAITING_APPROVAL;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveLoginApproval(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectLoginApproval(notification.payload && notification.payload.id)
            }
          ]
        },
      },
      [NotificationConstants.NOTIFICATION_TYPE_APPROVE]: getApprovedMessage("login request"),
    }
  },
  // Email
  EmailAccount: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_EMAIL}/account`,
  },
  EmailTemplate: {
    labelKey: "title",
    path: `${NotificationConstants.SETTING_PATH_EMAIL}/template`,
  },
  // Setup
  CompanyBranch: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/company`,
  },
  Currency: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/currency`,
  },
  CurrencyExchange: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/currency-exchange`,
  },
  Tax: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/Tax`,
  },
  PrintFormat: {
    labelKey: "title",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/print-format`,
  },
  ReportTemplate: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/report-template`,
  },
  NoSeriesList: {
    labelKey: "entity",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/no-series-list`,
  },
  CustomField: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_SET_UP}/custom-field`,
  },
  // Integration
  Integration: {
    labelKey: "name",
    path: `${NotificationConstants.SETTING_PATH_INTEGRATION}/integration`,
  },
  // ++ Accounting ++ 
  JournalEntry: {
    labelKey: "reference",
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/journal-entry`,
  },
  ChartAccount: {
    labelKey: "name",
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/chart-of-account`,
  },
  Expense: {
    labelKey: "expenseNo",
    entityKey: ((values) => values.payload && values.payload.expenseNo),
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/expense`,
    message: {
      [NotificationConstants.NOTIFICATION_TYPE_EXPENSE_SUBMIT]: {
        render: () => {
          return getSubmittedMessage("a expense request");
        },
        action: {
          shouldRender: (notification) => {
            return notification.payload && notification.payload.status == EXPENSE_STATUS_SUBMITTED;
          },
          items: [
            {
              title: "Approve",
              type: "primary",
              handler: (notification) => approveExpense(notification.payload && notification.payload.id)
            },
            {
              title: "Reject",
              handler: (notification) => rejectExpense(notification.payload && notification.payload.id)
            }
          ]
        }
      },
      [NotificationConstants.NOTIFICATION_TYPE_EXPENSE_APPROVE]: getApprovedMessage("expense request"),
      [NotificationConstants.NOTIFICATION_TYPE_EXPENSE_REJECT]: getRejectedMessage("expense request"),
      [NotificationConstants.NOTIFICATION_TYPE_EXPENSE_CANCEL]: getCancelledMessage("expense request"),
    },
  },
  SupplierDeposit: {
    labelKey: "supplierDepositNo",
    entityKey: ((values) => values.payload && values.payload.supplierDepositNo),
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/supplier-deposit`,
  },
  SupplierRefund: {
    labelKey: "supplierRefundNo",
    entityKey: ((values) => values.payload && values.payload.supplierRefundNo),
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/supplier-refund`,
  },
  CustomerDeposit: {
    labelKey: "customerDepositNo",
    entityKey: ((values) => values.payload && values.payload.customerDepositNo),
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/customer-deposit`,
  },
  CustomerRefund: {
    labelKey: "customerRefundNo",
    entityKey: ((values) => values.payload && values.payload.customerRefundNo),
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/customer-refund`,
  },
  PaymentMethod: {
    labelKey: "name",
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/payment-method`,
  },
  Contractor: {
    labelKey: "name",
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/contractor`,
  },
  ChequeEntry: {
    labelKey: "chequeNumber",
    path: `${NotificationConstants.ACCOUNTING_PATH_BANK}/cheque-entry`,
  },
  Bank: {
    labelKey: "bankName",
    path: `${NotificationConstants.ACCOUNTING_PATH_BANK}/bank`,
  },
  BankTransfer: {
    labelKey: "bankTransferNo",
    path: `${NotificationConstants.ACCOUNTING_PATH_BANK}/bank-transfer`,
  },
  BankEntry: {
    labelKey: "bankEntryNo",
    path: `${NotificationConstants.ACCOUNTING_PATH_BANK}/bank-entry`,
  },
  CustomerCommission: {
    labelKey: "customerCommissionNo",
    path: `${NotificationConstants.ACCOUNTING_PATH_LIST}/customer-commission`,
  },
  // Website
  Banner: {
    labelKey: "name",
    path: `${NotificationConstants.WEBSITE_PATH_BANNER}/banner-list`,
  },
  Order: {
    labelKey: "saleOrderNo",
    entityKey: ((values) => values.payload && values.payload.saleOrderNo),
    path: `${NotificationConstants.WEBSITE_PATH_ORDER}/order`,
  },
};