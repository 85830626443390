import { schema } from "normalizr";
import { PositionSchema } from "../../position/schema";
import { DepartmentSchema } from "../../department/schema";

const EmployeeJobHistorySchema = new schema.Entity("employeeJobHistories", {
  position: PositionSchema,
  department: DepartmentSchema,
});

const EmployeeJobHistoryArraySchema = new schema.Array(EmployeeJobHistorySchema);
export { 
  EmployeeJobHistorySchema,
  EmployeeJobHistoryArraySchema
};