import * as Constants from "@modules/reports/constants";
const subDomain = location.host.split(".")[0];
const isShow = subDomain.startsWith("eci");

export const HUMAN_RESOURCE_REPORT_MENU = [
  {
    id: 1,
    title: "Employee List",
    slug: "employee-list-report",
    code: Constants.EMPLOYEE_LIST_REPORT,
  },
  {
    id: 2,
    title: "Job Applicant",
    slug: "job-applicant-report",
    code: Constants.JOB_APPLICANT_REPORT,
  },
  {
    id: 3,
    title: "Job Opening",
    slug: "job-opening-report",
    code: Constants.JOB_OPENING_REPORT,
  },
  {
    id: 4,
    title: "Attendance",
    slug: "attendance-report",
    code: Constants.ATTENDANCE_REPORT,
  },
  {
    id: 8,
    title: "Appraisal Anniversary",
    slug: "appraisal-anniversary-report",
    code: Constants.APPRAISAL_ANNIVERSARY_REPORT,
  },
  {
    id: 5,
    title: "Attendance Log",
    slug: "attendance-log-report",
    code: Constants.ATTENDANCE_LOG_REPORT,
  },
  {
    id: 6,
    title: "Leave Request",
    slug: "leave-request-report",
    code: Constants.LEAVE_REQUEST_REPORT,
  },
  {
    id: 7,
    title: "Cash Advanced",
    slug: "cash-advanced-report",
    code: Constants.CASH_ADVANCED_REPORT,
  },
];

export const INVENTORY_REPORT_MENU = [
  {
    id: 1,
    title: "Stock",
    slug: "stock-report",
    code: Constants.STOCK_REPORT,
  },
  {
    id: 2,
    title: "Stock Values",
    slug: "stock-values-report",
    code: Constants.STOCK_VALUES_REPORT,
  },
  {
    id: 3,
    title: "Stock Values Detail",
    slug: "stock-values-detail-report",
    code: Constants.STOCK_VALUES_DETAIL_REPORT,
  },
  {
    id: 4,
    title: "Stock Reorder",
    slug: "stock-reorder-report",
    code: Constants.STOCK_REORDER_REPORT,
  },
  {
    id: 5,
    title: "Item Movement Log",
    slug: "item-movement-log-report",
    code: Constants.ITEM_MOVEMENT_LOG_REPORT,
  },
  {
    id: 6,
    title: "Delivery Order",
    slug: "delivery-order-report",
    code: Constants.DELIVERY_ORDER_REPORT,
  },
  {
    id: 7,
    title: "Item Sale Quantity",
    slug: "item-sale-report",
    code: Constants.ITEM_SALE_REPORT,
  },
  {
    id: 8,
    title: "Item Expiration",
    slug: "item-expiration-report",
    code: Constants.ITEM_EXPIRATION_REPORT,
  },
  {
    id: 9,
    title: "Item Aging",
    slug: "item-aging-report",
    code: Constants.ITEM_AGING_REPORT_CODE,
  },
  {
    id: 10,
    title: "Price List",
    slug: "price-list-report",
    code: Constants.PRICE_LIST_REPORT,
  },
  {
    id: 11,
    title: "Item",
    slug: "items-report",
    code: Constants.ITEM_REPORT,
  },
  {
    id: 12,
    title: "Item Forecasting",
    slug: "item-forecasting-report",
    code: Constants.ITEM_FORECASTING_REPORT,
  },
  {
    id: 13,
    title: "Item Change Log",
    slug: "item-change-log-report",
    code: Constants.ITEM_CHANGE_LOG_REPORT,
  },
  {
    id: 14,
    title: "Asset",
    slug: "asset-report",
    code: Constants.ASSET_REPORT,
  },
  {
    id: 15,
    title: "Lenovo Inventory",
    slug: "lenovo-inventory-report",
    code: Constants.LENOVO_INVENTORY_REPORT,
    isShow: isShow,
  },
  {
    id: 16,
    title: "Dell Inventory",
    slug: "dell-inventory-report",
    code: Constants.REPORT_DELL_INVENTORY,
    isShow: isShow,
  },
  {
    id: 17,
    title: "Supplier",
    slug: "supplier-report",
    code: Constants.SUPPLIER_REPORT,
  },
  {
    id: 18,
    title: "HP Inventory",
    slug: "hp-inventory-report",
    code: Constants.REPORT_HP_INVENTORY,
    isShow: isShow,
  },
  {
    id: 19,
    title: "Logitech Inventory",
    slug: "logitech-inventory-report",
    code: Constants.REPORT_LOGITECH_INVENTORY,
    isShow: isShow,
  },
  {
    id: 20,
    title: "APC Inventory",
    slug: "apc-inventory-report",
    code: Constants.REPORT_APC_INVENTORY,
    isShow: isShow,
  },
  {
    id: 21,
    title: "Sonicwall Inventory",
    slug: "sonicwall-inventory-report",
    code: Constants.REPORT_SONICWALL_INVENTORY,
    isShow: isShow,
  },
  {
    id: 22,
    title: "Junk Out",
    slug: "junk-out-report",
    code: Constants.JUNK_OUT_REPORT,
  },
];

export const ACCOUNTING_REPORT_MENU = [
  {
    id: 1,
    title: "Journal",
    slug: "journal-report",
    code: Constants.REPORT_JOURNAL,
  },
  {
    id: 2,
    title: "General Ledger",
    slug: "general-ledger-report",
    code: Constants.REPORT_GENERAL_LEDGER,
  },
  {
    id: 3,
    title: "Trail Balance",
    slug: "trail-balance-report",
    code: Constants.REPORT_TRAIL_BALANCE,
  },
  {
    id: 4,
    title: "Income Statement",
    slug: "income-statement-report",
    code: Constants.REPORT_INCOME_STATEMENT_CODE,
  },
  {
    id: 5,
    title: "Balance Sheet",
    slug: "balance-sheet-report",
    code: Constants.REPORT_BALANCE_SHEET_CODE,
  },
  {
    id: 6,
    title: "End Of Day",
    slug: "end-of-day-report",
    code: Constants.REPORT_END_OF_DAY_CODE,
  },
  {
    id: 7,
    title: "End Of Day Summary",
    slug: "end-of-day-summary-report",
    code: Constants.REPORT_END_OF_DAY_SUMMARY_CODE,
  },
  {
    id: 8,
    title: "Customer Refund",
    slug: "customer-refund-report",
    code: Constants.REPORT_CUSTOMER_REFUND_CODE,
  },
  {
    id: 9,
    title: "Customer Deposit",
    slug: "customer-deposit-report",
    code: Constants.REPORT_CUSTOMER_DEPOSIT_CODE,
  },
  {
    id: 10,
    title: "Customer Deposit Log",
    slug: "customer-deposit-log-report",
    code: Constants.REPORT_CUSTOMER_DEPOSIT_LOG,
  },
  {
    id: 11,
    title: "Customer Deposit Detail",
    slug: "customer-deposit-detail-report",
    code: Constants.REPORT_CUSTOMER_DEPOSIT_DETAIL,
  },
  {
    id: 12,
    title: "Customer Commission",
    slug: "customer-commission-report",
    code: Constants.REPORT_CUSTOMER_COMMISSION,
  },
  {
    id: 13,
    title: "Supplier Deposit",
    slug: "supplier-deposit-report",
    code: Constants.REPORT_SUPPLIER_DEPOSIT,
  },
  {
    id: 14,
    title: "Supplier Deposit Log",
    slug: "supplier-deposit-log-report",
    code: Constants.REPORT_SUPPLIER_DEPOSIT_LOG,
  },
  {
    id: 15,
    title: "Supplier Deposit Detail",
    slug: "supplier-deposit-detail-report",
    code: Constants.REPORT_SUPPLIER_DEPOSIT_DETAIL,
  },
  {
    id: 16,
    title: "Supplier Refund",
    slug: "supplier-refund-report",
    code: Constants.REPORT_SUPPLIER_REFUND,
  },
  {
    id: 17,
    title: "Expense",
    slug: "expense-report",
    code: Constants.REPORT_EXPENSE_CODE,
  },
  {
    id: 18,
    title: "Bank Detailed",
    slug: "bank-detailed-report",
    code: Constants.REPORT_BANK_LOG_CODE,
  },
  {
    id: 19,
    title: "Bank",
    slug: "bank-report",
    code: Constants.REPORT_BANK_CODE,
  },
  {
    id: 20,
    title: "A/R Aging Detail",
    slug: "accounting-receivable-aging-detail-report",
    code: Constants.REPORT_ACCOUNTING_RECEIVABLE_DETAIL_CODE,
  },
  {
    id: 21,
    title: "A/R Aging Summary",
    slug: "accounting-receivable-aging-summary-report",
    code: Constants.REPORT_ACCOUNTING_RECEIVABLE_AGEING_SUMMERY_CODE,
  },
  {
    id: 22,
    title: "Account Statement",
    slug: "account-statement-report",
    code: Constants.REPORT_ACCOUNT_STATEMENT,
  },
  {
    id: 23,
    title: "VAT Request",
    slug: "vat-request-report",
    code: Constants.REPORT_VAT_REQUEST,
  },
  {
    id: 24,
    title: "Cash Collection",
    slug: "cash-collection-report",
    code: Constants.REPORT_PAID,
  },
];

export const PURCHASE_REPORT_MENU = [
  {
    id: 1,
    title: "Purchase Order",
    slug: "purchase-order-report",
    code: Constants.READ_PURCHASE_ORDER_REPORT_CODE,
  },
  {
    id: 2,
    title: "Purchase Order Detail",
    slug: "purchase-order-detail-report",
    code: Constants.READ_PURCHASE_ORDER_DETAIL_REPORT_CODE,
  },
  {
    id: 3,
    title: "Purchase Order Summary",
    slug: "purchase-order-summary-report",
    code: Constants.READ_PURCHASE_ORDER_SUMMARY_REPORT_CODE,
  },
  {
    id: 4,
    title: "Dell Good In",
    slug: "dell-good-in-report",
    code: Constants.REPORT_GOOD_IN,
    isShow: isShow,
  },
  {
    id: 5,
    title: "Supplier",
    slug: "supplier-report",
    code: Constants.SUPPLIER_REPORT,
  },
  {
    id: 6,
    title: "Purchase Order Payment",
    slug: "purchase-order-payment-report",
    code: Constants.REPORT_PURCHASE_ORDER_PAYMENT,
  },
];

export const SALE_REPORT_MENU = [
  {
    id: 1,
    title: "Detailed Sale",
    slug: "detailed-sale-report",
    code: Constants.DETAILED_REPORT_SALE_ORDER_CODE,
  },
  {
    id: 2,
    title: "Detailed Sale With Income",
    slug: "detailed-sale-with-income-report",
    code: Constants.DETAIL_SALE_WITH_INCOME_REPORT,
  },
  {
    id: 3,
    title: "Detailed Sale (PM)",
    slug: "detailed-sale-pm",
    code: Constants.DETAILED_REPORT_SALE_REPORT_ORDER_CODE,
    isShow: subDomain.startsWith("eci") || subDomain.startsWith("ice"),
  },
  {
    id: 4,
    title: "Customer Refund",
    slug: "customer-refund-report",
    code: Constants.REPORT_CUSTOMER_REFUND_CODE,
  },
  {
    id: 5,
    title: "Customer Deposit",
    slug: "customer-deposit-report",
    code: Constants.REPORT_CUSTOMER_DEPOSIT_CODE,
  },
  {
    id: 7,
    title: "Sale Order",
    slug: "sale-order-report",
    code: Constants.REPORT_SALE_ORDER_CODE,
  },
  {
    id: 8,
    title: "Quotation",
    slug: "quotation-report",
    code: Constants.REPORT_QUOTATION_CODE,
  },
  {
    id: 9,
    title: "Item Sale Quantity",
    slug: "item-sale-report",
    code: Constants.ITEM_SALE_REPORT,
  },
  {
    id: 10,
    title: "Sale Commission",
    slug: "sale-commission-report",
    code: Constants.REPORT_SALE_COMMISSION,
  },
  {
    id: 11,
    title: "Customer",
    slug: "customer-report",
    code: Constants.REPORT_CUSTOMER_CODE,
  },
  {
    id: 12,
    title: "Customer Company",
    slug: "customer-company-report",
    code: Constants.REPORT_CUSTOMER_COMPANY_CODE,
  },
  {
    id: 13,
    title: "Company Response",
    slug: "company-response-report",
    code: Constants.REPORT_COMPANY_RESPONSE_CODE,
  },
  {
    id: 14,
    title: "Lenovo Sell Thru",
    slug: "lenovo-sell-thru-report",
    code: Constants.LENOVO_SELL_THRU_REPORT,
    isShow: isShow,
  },
  {
    id: 15,
    title: "Item Installation Summary",
    slug: "item-installation-summary-report",
    code: Constants.REPORT_ITEM_INSTALLATION_SUMMARY_CODE,
  },
  {
    id: 16,
    title: "Dell Sell Out",
    slug: "dell-sell-out-report",
    code: Constants.REPORT_SELL_OUT,
    isShow: isShow,
  },
  {
    id: 17,
    title: "Sale By Customer",
    slug: "sale-by-customer-report",
    code: Constants.REPORT_SALE_SUMMARY_BY_CUSTOMER,
  },
  {
    id: 18,
    title: "Sale By Company",
    slug: "sale-by-company-report",
    code: Constants.REPORT_SALE_SUMMARY_BY_COMPANY,
  },
  {
    id: 19,
    title: "Sale By Item",
    slug: "sale-by-item-report",
    code: Constants.REPORT_SALE_SUMMARY_BY_ITEM,
  },
  {
    id: 20,
    title: "Sale By Employee",
    slug: "sale-by-employee-report",
    code: Constants.REPORT_SALE_SUMMARY_BY_EMPLOYEE,
  },
  {
    id: 21,
    title: "Hp Sell Out",
    slug: "hp-sell-out-report",
    code: Constants.REPORT_HP_SELL_OUT,
    isShow: isShow,
  },
  {
    id: 22,
    title: "Invoice List Report",
    slug: "invoice-list-report",
    code: Constants.REPORT_INVOICE_LIST,
  },
  {
    id: 23,
    title: "Logitech Sell Out",
    slug: "logitech-sell-out-report",
    code: Constants.REPORT_LOGITECH_INVENTORY,
    isShow: isShow,
  },
  {
    id: 24,
    title: "APC Sell Out",
    slug: "apc-sell-out-report",
    code: Constants.REPORT_APC_INVENTORY,
    isShow: isShow,
  },
  {
    id: 25,
    title: "Sonicwall Sell Out",
    slug: "sonicwall-sell-out-report",
    code: Constants.REPORT_SONICWALL_INVENTORY,
    isShow: isShow,
  },
  {
    id: 25,
    title: "Service",
    slug: "service-report",
    code: Constants.SERVICE_SALE_REPORT,
    isShow: isShow,
  },
];

export default {
  sideBarTitle: "Reports",
  hasDashboard: true,
  isFixed: false,
  slug: "reports",
  display: true,
  menus: [
    {
      id: 1,
      title: "Human Resource",
      slug: "human-resource",
      icon: "UserOutlined",
      sub: HUMAN_RESOURCE_REPORT_MENU,
    },
    {
      id: 2,
      title: "Inventory",
      slug: "inventory",
      icon: "ProjectOutlined",
      sub: INVENTORY_REPORT_MENU,
    },
    {
      id: 3,
      title: "Accounting ",
      slug: "accounting",
      icon: "CalculatorOutlined",
      sub: ACCOUNTING_REPORT_MENU,
    },
    {
      id: 4,
      title: "Purchase",
      slug: "purchase",
      icon: "ScheduleOutlined",
      sub: PURCHASE_REPORT_MENU,
    },
    {
      id: 5,
      title: "Sale",
      slug: "sale",
      icon: "CarryOutOutlined",
      sub: SALE_REPORT_MENU,
    },
  ],
};
