import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const CHART_OF_ACCOUNT_URL = "/api/chart-account/v1";
const CHART_OF_ACCOUNT_TYPE_URL = "/api/chart-account-type/v1";
const API_URL = "/api/journal-entry-batch/v1";

export function getChartOfAccount(values) {
  const url = `${CHART_OF_ACCOUNT_URL}/tree`;
  return GETList(url, values);
}

export function getChartOfAccountAutoComplete(query, extra) {
  let url = `${CHART_OF_ACCOUNT_URL}/autocomplete`;
  return GETAutoComplete(url, query, extra);
}

export function getChartOfAccountTypeAutoComplete(query, extra) {
  let url = `${CHART_OF_ACCOUNT_TYPE_URL}/autocomplete`; 
  return GETAutoComplete(url, query, extra);
}

export function createChartOfAccount(values) {
  const url = `${CHART_OF_ACCOUNT_URL}/create`;
  return POSTWithToken(url, values);
}

export function getChartOfAccountDetail(id) {
  return GETDetail(CHART_OF_ACCOUNT_URL, id);
}

export function getChartAccountHistory(values) {
  const { id } = values;
  values = getDefaultCurrentDateRange(values); 
  const url = `${API_URL}/list?includeAll=true&chartAccount[]=${id}`;
  return GETList(url, values);
}

export function editChartOfAccount(values) {
  const { id } = values;
  const url = `${CHART_OF_ACCOUNT_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveChartOfAccount(id) {
  const url = `${CHART_OF_ACCOUNT_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveChartOfAccount(id) {
  const url = `${CHART_OF_ACCOUNT_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}