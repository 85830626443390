export const PRICE_LIST_LIST_REQUEST = "PRICE_LIST_LIST_REQUEST"; 
export const PRICE_LIST_LIST_SUCCESS = "PRICE_LIST_LIST_REQUEST_SUCCESS";
export const PRICE_LIST_CREATE_REQUEST = "PRICE_LIST_CREATE_REQUEST"; 
export const PRICE_LIST_DETAIL_REQUEST = "PRICE_LIST_DETAIL_REQUEST"; 
export const PRICE_LIST_ARCHIVE_REQUEST = "PRICE_LIST_ARCHIVE_REQUEST"; 
export const PRICE_LIST_DELETE_REQUEST = "PRICE_LIST_DELETE_REQUEST"; 
export const PRICE_LIST_UNARCHIVE_REQUEST = "PRICE_LIST_UNARCHIVE_REQUEST"; 
export const PRICE_LIST_EDIT_REQUEST = "PRICE_LIST_EDIT_REQUEST"; 
export const PRICE_LIST_AUTOCOMPLETE_REQUEST = "PRICE_LIST_AUTOCOMPLETE_REQUEST";
export const PRICE_LIST_AUTOCOMPLETE_SUCCESS = "PRICE_LIST_AUTOCOMPLETE_SUCCESS";
export const PRICE_LIST_CREATE_FORM_NAME = "createPriceList"; 
export const PRICE_LIST_EDIT_FORM_NAME = "editPriceList"; 

export const PRICE_LIST_SUBMIT_RULES = (values) => {
  const { type } = values;
  return {
    name: {
      required: true,
    },
    orderLevel: {
      required: true,
    },
    currencyId: {
      required: true,
    },
    type: {
      required: true,
    },
    formula: {
      required: type == PRICE_LIST_TYPE_CALCULATE
    }
  };
};

export const READ_PRICE_LIST = 207000;
export const CREATE_PRICE_LIST = 207001;
export const UPDATE_PRICE_LIST = 207002;
export const DELETE_PRICE_LIST = 207003;
export const ARCHIVE_PRICE_LIST = 207004;
export const UNARCHIVE_PRICE_LIST = 207005;

export const PRICE_LIST_TYPE_FIXED = 1;
export const PRICE_LIST_TYPE_CALCULATE = 2;
export const PRICE_LIST_TYPE_FIXED_TITLE = "Fixed";
export const PRICE_LIST_TYPE_CALCULATE_TITLE = "Calculate";

export const PRICE_LIST_TYPE_DATA_SOURCE = [
  {
    id: PRICE_LIST_TYPE_FIXED,
    name: PRICE_LIST_TYPE_FIXED_TITLE
  },
  {
    id: PRICE_LIST_TYPE_CALCULATE,
    name: PRICE_LIST_TYPE_CALCULATE_TITLE
  }
];