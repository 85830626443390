import * as NotificationConstants from "@/constants/notification";

export const notificationActionType = (type) => {
  let status = "";
  switch(type) {
  case NotificationConstants.NOTIFICATION_TYPE_UPDATE:
    status = "updated";
    break;
  case NotificationConstants.NOTIFICATION_TYPE_DELETE:
    status = "deleted";
    break;
  case NotificationConstants.NOTIFICATION_TYPE_ARCHIVE:
    status = "archived";
    break;
  case NotificationConstants.NOTIFICATION_TYPE_UNARCHIVE:
    status = "unarchived";
    break;
  case NotificationConstants.NOTIFICATION_TYPE_COMMENT:
    status = "commented on";
    break;
  case NotificationConstants.EMPLOYEE_ATTENDANCE_REQUEST_FOR_APPROVAL || NotificationConstants.EMPLOYEE_ATTENDANCE_HAS_BEEN_REJECT:
    status = "is waiting your approval";
    break;
  case NotificationConstants.EMPLOYEE_ATTENDANCE_HAS_BEEN_REJECT:
    status = "has been rejected your";
    break;
  case NotificationConstants.EMPLOYEE_ATTENDANCE_HAS_BEEN_APPROVE:
    status = "has been approved your";
    break;
  case NotificationConstants.NOTIFICATION_TYPE_APPROVE:
    status = "has been approved";
    break;
  case NotificationConstants.NOTIFICATION_TYPE_CREATE:
    status = "created new";
    break;
  default:
    status;
  }
  return status;
};