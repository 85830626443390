export const PAYMENT_TERM_LIST_REQUEST = "PAYMENT_TERM_LIST_REQUEST"; 
export const PAYMENT_TERM_LIST_SUCCESS = "PAYMENT_TERM_LIST_REQUEST_SUCCESS";
export const PAYMENT_TERM_CREATE_REQUEST = "PAYMENT_TERM_CREATE_REQUEST"; 
export const PAYMENT_TERM_DETAIL_REQUEST = "PAYMENT_TERM_DETAIL_REQUEST"; 
export const PAYMENT_TERM_ARCHIVE_REQUEST = "PAYMENT_TERM_ARCHIVE_REQUEST"; 
export const PAYMENT_TERM_DELETE_REQUEST = "PAYMENT_TERM_DELETE_REQUEST"; 
export const PAYMENT_TERM_UNARCHIVE_REQUEST = "PAYMENT_TERM_UNARCHIVE_REQUEST"; 
export const PAYMENT_TERM_EDIT_REQUEST = "PAYMENT_TERM_EDIT_REQUEST"; 
export const PAYMENT_TERM_CREATE_FORM_NAME = "createPaymentMethod"; 
export const PAYMENT_TERM_EDIT_FORM_NAME = "editPaymentMethod"; 
export const PAYMENT_TERM_AUTOCOMPLETE_REQUEST = "PAYMENT_TERM_AUTOCOMPLETE_REQUEST";
export const PAYMENT_TERM_AUTOCOMPLETE_SUCCESS = "PAYMENT_TERM_AUTOCOMPLETE_SUCCESS";
export const PAYMENT_TERM_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  creditDays: {
    required: true
  }
};
export const READ_PAYMENT_TERM = 194000;
export const CREATE_PAYMENT_TERM = 194001;
export const UPDATE_PAYMENT_TERM = 194002;
export const DELETE_PAYMENT_TERM = 194003;
export const ARCHIVE_PAYMENT_TERM = 194004;
export const UNARCHIVE_PAYMENT_TERM = 194005;