import { normalize } from "normalizr";
import * as service from "@/services/filter";
import * as Constants from "@/constants/filter";
import { FilterArraySchema, FilterSchema } from "@/schemas/filter";
import {
  createListAction, 
  createNewEntityAction, 
  createDeleteAction,
  createEditAction, 
  createEntityActionAction,
} from "@modules/utils/action-creator";
import { mergeEntities } from "@/actions/entity";
import { 
  updateQueryParamUrl,
  updateQueryParamToLocalStorage, 
  getFilterObjectFromFilterDataSource, 
} from "@modules/helpers";

export const getAll = (entity) => (dispatch, getState) => {

  const successAction = (response) => {
    const normalized = normalize(response, FilterArraySchema);
    dispatch(mergeEntities(normalized.entities));
    return {
      type: `${Constants.FILTER_LIST_REQUEST}_SUCCESS`,
      payload: normalized.result,
      entity,
    };
  };

  const options = {
    serviceMethod: service.getFilter,
    schema: FilterArraySchema,
    requestName: Constants.FILTER_LIST_REQUEST,
    dispatch,
    getState,
    successAction,
    isDefaultResponse: false,
  };
  return createListAction(options, entity);
};

export const createFilter = (values) => (dispatch, getState) => {

  const successAction = (json) => {

    const normalized = normalize(json, FilterSchema);
    dispatch(mergeEntities(normalized.entities));

    const query = getFilterObjectFromFilterDataSource(json && json.filters);
    query.view = json.slug;
    updateQueryParamUrl(query);
    updateQueryParamToLocalStorage(query);
    dispatch({
      type: Constants.FILTER_CREATE_SUCCESS,
      payload: normalized.result,
      entity: values.entity
    });
  };

  const options = {
    serviceMethod: service.createFilter,
    requestName: Constants.FILTER_CREATE_REQUEST,
    dispatch,
    getState,
    redirect: false,
    successAction,
    successMessage: "Filter is created successfully",
  };

  return createNewEntityAction(options, values);
};

export const editFilter = (values) => (dispatch, getState) => {

  const onSuccess = (json) => {
    
    const normalized = normalize(json, FilterSchema);
    dispatch(mergeEntities(normalized.entities));

    const query = getFilterObjectFromFilterDataSource(json && json.filters);
    query.view = json.slug;
    updateQueryParamUrl(query);
    updateQueryParamToLocalStorage(query);
  };

  const options = {
    serviceMethod: service.editFilter,
    requestName: Constants.FILTER_EDIT_REQUEST,
    dispatch,
    getState,
    entityName: "Filters",
    schema: FilterSchema,
    onSuccess: !values.noUpdateUrl ? onSuccess : null,
    successMessage: "Filter is updated successfully",
  };
  return createEditAction(options, values);
};

export const deleteFilter = (id, entity) => (dispatch, getState) => {

  const onSuccess = () => {
    dispatch({
      type: Constants.FILTER_DELETE_SUCCESS,
      id,
      entity
    });
  };

  const options = {
    serviceMethod: service.deleteFilter,
    requestName: Constants.FILTER_DELETE_REQUEST,
    dispatch,
    getState,
    onSuccess,
    successMessage: "Filter is deleted successfully!",
  };
  return createDeleteAction(options, id);
};


export const reorderFilter = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.reorderFilter,
    schema: FilterArraySchema,
    requestName: Constants.FILTER_REORDER_REQUEST,
    dispatch,
    getState,
  };
  return createEntityActionAction(options, values);
};
