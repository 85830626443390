


export const READ_CHEQUE_ENTRY = 180000;
export const WITHDRAW_CHEQUE_ENTRY = 180011;
export const DEPOSIT_CHEQUE_ENTRY = 180012;
export const CLEAR_CHEQUE_ENTRY = 180013;

export const CHEQUE_ENTRY_LIST_REQUEST = "CHEQUE_ENTRY_LIST_REQUEST";
export const CHEQUE_ENTRY_LIST_SUCCESS = "CHEQUE_ENTRY_LIST_REQUEST_SUCCESS";
export const CHEQUE_ENTRY_AUTOCOMPLETE_REQUEST = "CHEQUE_ENTRY_AUTOCOMPLETE_REQUEST";
export const CHEQUE_ENTRY_DETAIL_REQUEST = "CHEQUE_ENTRY_DETAIL_REQUEST";
export const CHEQUE_ENTRY_CLEAR_REQUEST = "CHEQUE_ENTRY_CLEAR_REQUEST";
export const CHEQUE_ENTRY_WITHDRAW_REQUEST = "CHEQUE_ENTRY_WITHDRAW_REQUEST";
export const CHEQUE_ENTRY_DEPOSIT_REQUEST = "CHEQUE_ENTRY_DEPOSIT_REQUEST";

export const CHEQUE_ENTRY_STATUS_DRAFT = 1;
export const CHEQUE_ENTRY_STATUS_DRAFT_TITLE = "Draft";
export const CHEQUE_ENTRY_STATUS_CLEAR = 2;
export const CHEQUE_ENTRY_STATUS_CLEAR_TITLE = "Cleared";
export const CHEQUE_ENTRY_STATUS_WITHDRAW = 3;
export const CHEQUE_ENTRY_STATUS_WITHDRAW_TITLE = "Withdraw";
export const CHEQUE_ENTRY_STATUS_DEPOSIT = 4;
export const CHEQUE_ENTRY_STATUS_DEPOSIT_TITLE = "Deposit";

export const CHEQUE_ENTRY_STATUS_DATASOURCE = [
  {
    id: CHEQUE_ENTRY_STATUS_DRAFT,
    name: CHEQUE_ENTRY_STATUS_DRAFT_TITLE,
  },
  {
    id: CHEQUE_ENTRY_STATUS_CLEAR,
    name: CHEQUE_ENTRY_STATUS_CLEAR_TITLE,
  },
  {
    id: CHEQUE_ENTRY_STATUS_WITHDRAW,
    name: CHEQUE_ENTRY_STATUS_WITHDRAW_TITLE,
  },
  {
    id: CHEQUE_ENTRY_STATUS_DEPOSIT,
    name: CHEQUE_ENTRY_STATUS_DEPOSIT_TITLE,
  },
];

export const CHEQUE_ENTRY_TYPE_IN = 1;
export const CHEQUE_ENTRY_TYPE_IN_TITLE = "In";
export const CHEQUE_ENTRY_TYPE_OUT = 2;
export const CHEQUE_ENTRY_TYPE_OUT_TITLE = "Out";

export const CHEQUE_ENTRY_REFERENCE_EXPENSE = "Expense";
export const CHEQUE_ENTRY_REFERENCE_INVOICE_PAYMENT = "InvoicePayment";

export const WITHDRAW_CHEQUE_ENTRY_FORM_NAME = "withdrawChequeEntry";
export const DEPOSIT_CHEQUE_ENTRY_FORM_NAME = "depositChequeEntry";