import React from "react";
import { Badge } from "antd";
import * as Constants from "../constants";
import moment from "moment";
import { DEFAULT_DISPLAY_DATE_FORMAT } from "@/constants/default-constants";

export const renderCashAdvancedStatus = (id) => {
  let status = null;
  switch(id) {
  case Constants.CASH_ADVANCED_STATUS_DRAFT:
    status = <Badge status="default" text={Constants.CASH_ADVANCED_STATUS_DRAFT_TITLE} />;
    break;
  case Constants.CASH_ADVANCED_STATUS_SUBMITTED:
    status = <Badge status="processing" text={Constants.CASH_ADVANCED_STATUS_SUBMITTED_TITLE} />;
    break;
  case Constants.CASH_ADVANCED_STATUS_APPROVED:
    status = <Badge status="success" text={Constants.CASH_ADVANCED_STATUS_APPROVED_TITLE} />;
    break;
  case Constants.CASH_ADVANCED_STATUS_REJECTED:
    status = <Badge status="error" text={Constants.CASH_ADVANCED_STATUS_REJECTED_TITLE} />;
    break;
  case Constants.CASH_ADVANCED_STATUS_POST:
    status = <Badge status="success" text={Constants.CASH_ADVANCED_STATUS_POST_TITLE} />;
    break;
  case Constants.CASH_ADVANCED_STATUS_PAID:
    status = <Badge status="success" text={Constants.CASH_ADVANCED_STATUS_PAID_TITLE} />;
    break;
  default:
    status;
  }
  return status;
};

export const renderCashAdvancedStatusTitle = (id) => {
  let status = null;
  switch(id) {
  case Constants.CASH_ADVANCED_STATUS_DRAFT:
    status = Constants.CASH_ADVANCED_STATUS_DRAFT_TITLE;
    break;
  case Constants.CASH_ADVANCED_STATUS_SUBMITTED:
    status = Constants.CASH_ADVANCED_STATUS_SUBMITTED_TITLE;
    break;
  case Constants.CASH_ADVANCED_STATUS_APPROVED:
    status = Constants.CASH_ADVANCED_STATUS_APPROVED_TITLE;
    break;
  case Constants.CASH_ADVANCED_STATUS_REJECTED:
    status = Constants.CASH_ADVANCED_STATUS_REJECTED_TITLE;
    break;
  case Constants.CASH_ADVANCED_STATUS_POST:
    status = Constants.CASH_ADVANCED_STATUS_POST_TITLE;
    break;
  case Constants.CASH_ADVANCED_STATUS_PAID:
    status = Constants.CASH_ADVANCED_STATUS_PAID_TITLE;
    break;
  default:
    status;
  }
  return status;
};

export const renderPaymentType = (paymentType) => {
  let type = null;
  switch (paymentType) {
  case Constants.PAYMENT_TYPE_FIXED_ID:
    type = Constants.PAYMENT_TYPE_FIXED_NAME;
    break;
  case Constants.PAYMENT_TYPE_FLEXIBLE_ID:
    type = Constants.PAYMENT_TYPE_FLEXIBLE_NAME;
    break;
  default:
    type;
  }
  return type;
};

export const cashAdvancedCalculator = (props) => {
  let {
    amount,
    duration,
    interestRate,
    paymentType,
    effectiveMonth,
    effectiveYear,
  } = props;
  const result = [];
  if (paymentType == Constants.PAYMENT_TYPE_FIXED_ID || paymentType == Constants.PAYMENT_TYPE_FLEXIBLE_ID) {
    if (amount && duration) {
      let i = 0;
      let customDate = "N/A";
      let amountEachMonth = "N/A";
      amount = Number(amount);
      duration = Number(duration);
      const monthlyPrincipal = amount / duration;
      let endingBalance = amount;
      let date = moment([effectiveYear, effectiveMonth - 1]);
      for (i; i < duration; i++) {
        if (effectiveMonth && effectiveYear) {
          const month = moment(date, DEFAULT_DISPLAY_DATE_FORMAT).format("MMM");
          const year = moment(date, DEFAULT_DISPLAY_DATE_FORMAT).format("YYYY");
          customDate = `${month}/${year}`;
          date = moment(date).add(1, "M");
        }
        if (paymentType == Constants.PAYMENT_TYPE_FIXED_ID) {
          const totalAmount = amount + (amount * duration * interestRate / 100);
          amountEachMonth = totalAmount / duration;
        }
        if (paymentType == Constants.PAYMENT_TYPE_FLEXIBLE_ID) {
          const interest = endingBalance * (interestRate / 100);
          amountEachMonth = monthlyPrincipal + interest;
          endingBalance -= amountEachMonth;
        }
        result.push({
          date: customDate,
          amount: amountEachMonth
        });
      }
    }
  }
  return result;
};


export const postCashAdvancedSubmitRules = (values) => {
  const { isCheque } = values;
  return {
    paymentMethodId: {
      required: true,
    },
    currencyId: {
      required: true,
    },
    cheque: {
      type: "object",
      chequeNumber: {
        required: isCheque
      },
      issueDate: {
        required: isCheque
      },
      bankId: {
        required: isCheque
      },
      payTo: {
        required: isCheque
      }
    }
  };
};

export const renderCashAdvancedListStatus = (id) => {
  let status = null;
  switch(id) {
  case Constants.CASH_ADVANCED_LIST_STATUS_UNPAID:
    status = <Badge status="processing" text={Constants.CASH_ADVANCED_LIST_STATUS_UNPAID_TITLE} />;
    break;
  case Constants.CASH_ADVANCED_LIST_STATUS_PAID:
    status = <Badge status="success" text={Constants.CASH_ADVANCED_LIST_STATUS_PAID_TITLE} />;
    break;
  default:
    status = <Badge status="default" text={Constants.CASH_ADVANCED_STATUS_DRAFT_TITLE} />;
  }
  return status;
};