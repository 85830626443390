export const READ_RECRUITMENT_SETTING_CODE = 165011;
export const UPDATE_RECRUITMENT_SETTING_CODE = 165012;
export const RECRUITMENT_SETTING_PATH = "/human-resource/recruitment/recruitment-setting";

export const RECRUITMENT_SETTING_SUBMIT_RULES = {
  offerLetterEmailTemplate: {
    type: "object",
    value: {
      required: true
    }
  },
  meetingEmailTemplate: {
    type: "object",
    value: {
      required: true
    }
  },
  recruitmentEmailAccount: {
    type: "object",
    value: {
      required: true
    }
  }
};