import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";

// Action 
import { getSalaryStructureAutoComplete } from "@modules/hr/payroll/salary-structure/actions";

// Constant 
import { SALARY_STRUCTURE_AUTOCOMPLETE_REQUEST } from "@modules/hr/payroll/salary-structure/constants";

class AsyncSalaryStructureAutoComplete extends React.Component {

  render() {
    const { name, requestName, action, ...props } = this.props;
    return (
      <AsyncSelect 
        name={name}
        action={action}
        requestName={requestName}
        {...props}
      />
    );
  }
}

AsyncSalaryStructureAutoComplete.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  requestName: PropTypes.string,
};

AsyncSalaryStructureAutoComplete.defaultProps = {
  name: "salaryStructure",
  placeholder: "Salary Structure",
  action: getSalaryStructureAutoComplete,
  requestName: SALARY_STRUCTURE_AUTOCOMPLETE_REQUEST,
};

export default withRouter(AsyncSalaryStructureAutoComplete);