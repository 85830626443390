export const CUSTOMER_FIELD_REQUEST = "CUSTOMER_FIELD_REQUEST";
export const CUSTOMER_FIELD_SUCCESS = "CUSTOMER_FIELD_REQUEST_SUCCESS";

export const CUSTOMER_FIELD_REQUEST_BY_ENTITY_REQUEST = "CUSTOMER_FIELD_REQUEST_BY_ENTITY_REQUEST";
export const CUSTOMER_FIELD_REQUEST_BY_ENTITY_SUCCESS = "CUSTOMER_FIELD_REQUEST_BY_ENTITY_REQUEST_SUCCESS";

export const CUSTOMER_FIELD_AUTOCOMPLETE_REQUEST = "CUSTOMER_FIELD_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_FIELD_AUTOCOMPLETE_SUCCESS = "CUSTOMER_FIELD_AUTOCOMPLETE_REQUEST_SUCCESS";

export const CREATE_CUSTOMER_FIELD_REQUEST = "CUSTOMER_FIELD_REQUEST";
export const CUSTOMER_FIELD_DETAIL_REQUEST = "CUSTOMER_FIELD_DETAIL_REQUEST";
export const CUSTOMER_FIELD_EDIT_REQUEST = "CUSTOMER_FIELD_EDIT_REQUEST";
export const CUSTOMER_FIELD_ARCHIVE_REQUEST = "CUSTOMER_FIELD_ARCHIVE_REQUEST";
export const CUSTOMER_FIELD_UNARCHIVE_REQUEST = "CUSTOMER_FIELD_UNARCHIVE_REQUEST";
export const CUSTOMER_FIELD_DELETE_REQUEST = "CUSTOMER_FIELD_DELETE_REQUEST";

export const CUSTOMER_FIELD_CREATE_FORM_NAME = "createCustomField";
export const CUSTOMER_FIELD_EDIT_FORM_NAME = "editCustomField";

export const CUSTOMER_FIELD_SUBMIT_RULES = {
  entityName: {
    required: true
  },
  name: {
    required: true
  },
  type: {
    required: true
  },
  label: {
    required: true
  },
  columLayout: {
    required: true
  }
};

export const READ_CUSTOMER_FIELD = 243000;
export const CREATE_CUSTOMER_FIELD = 243001;
export const UPDATE_CUSTOMER_FIELD = 243002;
export const DELETE_CUSTOMER_FIELD = 243003;
export const ARCHIVE_CUSTOMER_FIELD = 243004;
export const UNARCHIVE_CUSTOMER_FIELD = 243005;

export const CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX = 1;
export const CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX_NAME = "CheckBox";

export const CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER = 2;
export const CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER_NAME = "Date Picker";

export const CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER = 3;
export const CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER_NAME = "Date Time Picker";

export const CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER = 4;
export const CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER_NAME = "Time Picker";

export const CUSTOMER_FIELD_INPUT_TYPE_NUMBER = 5;
export const CUSTOMER_FIELD_INPUT_TYPE_NUMBER_NAME = "Number";

export const CUSTOMER_FIELD_INPUT_TYPE_TEXT_AREA = 6;
export const CUSTOMER_FIELD_INPUT_TYPE_TEXT_AREA_NAME = "Text Area";

export const CUSTOMER_FIELD_INPUT_TYPE_TEXT = 7;
export const CUSTOMER_FIELD_INPUT_TYPE_TEXT_NAME = "Text";

export const CUSTOMER_FIELD_INPUT_TYPE_EDITOR = 8;
export const CUSTOMER_FIELD_INPUT_TYPE_EDITOR_NAME = "Editor";

export const CUSTOMER_FIELD_INPUT_TYPE_PHONE_NUMBER = 9;
export const CUSTOMER_FIELD_INPUT_TYPE_PHONE_NUMBER_NAME = "Phone Number";

export const CUSTOMER_FIELD_INPUT_TYPE_CURRENCY = 10;
export const CUSTOMER_FIELD_INPUT_TYPE_CURRENCY_NAME = "Currency";

export const CUSTOMER_FIELD_INPUT_TYPE_IMAGE = 11;
export const CUSTOMER_FIELD_INPUT_TYPE_IMAGE_NAME = "Image";

export const CUSTOMER_FIELD_INPUT_TYPE_FILE = 12;
export const CUSTOMER_FIELD_INPUT_TYPE_FILE_NAME = "File";

export const CUSTOMER_FIELD_INPUT_TYPE_OBJECT = 13;
export const CUSTOMER_FIELD_INPUT_TYPE_OBJECT_NAME = "Object";

export const CUSTOMER_FIELD_INPUT_TYPE_ARRAY_OBJECT = 14;
export const CUSTOMER_FIELD_INPUT_TYPE_ARRAY_OBJECT_NAME = "Array Object";

export const CUSTOMER_FIELD_INPUT_DATA_SOURCE = [
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX,
    name: CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER,
    name: CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER,
    name: CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER,
    name: CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_NUMBER,
    name: CUSTOMER_FIELD_INPUT_TYPE_NUMBER_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_TEXT_AREA,
    name: CUSTOMER_FIELD_INPUT_TYPE_TEXT_AREA_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_TEXT,
    name: CUSTOMER_FIELD_INPUT_TYPE_TEXT_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_EDITOR,
    name: CUSTOMER_FIELD_INPUT_TYPE_EDITOR_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_PHONE_NUMBER,
    name: CUSTOMER_FIELD_INPUT_TYPE_PHONE_NUMBER_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_CURRENCY,
    name: CUSTOMER_FIELD_INPUT_TYPE_CURRENCY_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_IMAGE,
    name: CUSTOMER_FIELD_INPUT_TYPE_IMAGE_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_FILE,
    name: CUSTOMER_FIELD_INPUT_TYPE_FILE_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_OBJECT,
    name: CUSTOMER_FIELD_INPUT_TYPE_OBJECT_NAME,
  },
  {
    id: CUSTOMER_FIELD_INPUT_TYPE_ARRAY_OBJECT,
    name: CUSTOMER_FIELD_INPUT_TYPE_ARRAY_OBJECT_NAME,
  }
];

export const CUSTOMER_FIELD_COLUMN_LAYOUT_DATA_SOURCE = [
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 12,
    name: "12",
  },
];