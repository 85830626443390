export const PLAN_LIST_REQUEST = "PLAN_LIST_REQUEST"; 
export const PLAN_LIST_SUCCESS = "PLAN_LIST_REQUEST_SUCCESS";

export const PLAN_CREATE_REQUEST = "PLAN_CREATE_REQUEST"; 
export const PLAN_DETAIL_REQUEST = "PLAN_DETAIL_REQUEST"; 

export const PLAN_ARCHIVE_REQUEST = "PLAN_ARCHIVE_REQUEST"; 
export const PLAN_DELETE_REQUEST = "PLAN_DELETE_REQUEST"; 

export const PLAN_UNARCHIVE_REQUEST = "PLAN_UNARCHIVE_REQUEST"; 
export const PLAN_EDIT_REQUEST = "PLAN_EDIT_REQUEST"; 

export const PLAN_CREATE_FORM_NAME = "createPlan"; 
export const PLAN_EDIT_FORM_NAME = "editPlan"; 

export const PLAN_AUTOCOMPLETE_REQUEST = "PLAN_AUTOCOMPLETE_REQUEST";
export const PLAN_AUTOCOMPLETE_SUCCESS = "PLAN_AUTOCOMPLETE_SUCCESS";

export const PLAN_SUBMIT_RULES = { 
  title: {
    required: true,
  },
  probationDuration: {
    required: true,
  },
  periodicityDuration: {
    required: true,
  },
  probationFormId: {
    required: true,
  },
  appraisalFormId: {
    required: true,
  },
  evaluationConstraint: {
    required: true,
  },
  departmentIds: {
    required: true,
    type: "array",
  },
};

export const READ_PLAN = 129000;
export const CREATE_PLAN = 129001;
export const UPDATE_PLAN = 129002;
export const DELETE_PLAN = 129003;
export const ARCHIVE_PLAN = 129004;
export const UNARCHIVE_PLAN = 129005;