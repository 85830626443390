// Lib
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Constant
import * as Constants from "../../constants";

// Action
import { createLeaveRequest } from "../../actions";
import { leaveRequestSubmitRules } from "../../helpers";
import { getCurrentUser } from "@modules/utils/value-selector";

// Components
import FormComponent from "../../components/form-component";
import CreateLayout from "@/components/layout/create-layout";

class CreateLeaveRequest extends Component {

  beforeInitialValues = () => {
    const { currentUser: { employee } } = this.props;
    const employeeId = employee && employee.id;
    return {
      employeeId
    };
  }

  renderFormComponent = (props) => {
    const { currentUser: { employee } } = this.props;
    const employeeId = employee && employee.id;
    return (
      <FormComponent 
        userId={employeeId}
        {...props}
      />
    );
  }

  render() {
    const { 
      fullWrapper, 
      showHeader, 
      onCreateSuccess,
      children,
      className,
      shouldContinueCreate,
    } = this.props;
    return (
      <CreateLayout 
        showHeader={showHeader}
        className={className}
        title="LeaveRequest"
        fullWrapper={fullWrapper}
        rules={leaveRequestSubmitRules}
        onCreateSuccess={onCreateSuccess}
        createAction={createLeaveRequest}
        formComponent={this.renderFormComponent}
        shouldContinueCreate={shouldContinueCreate}
        beforeInitialValues={this.beforeInitialValues}
        form={Constants.LEAVE_REQUEST_CREATE_FORM_NAME}
        requestName={Constants.LEAVE_REQUEST_CREATE_REQUEST}
      >
        {children}
      </CreateLayout>
    );
  }
}

CreateLeaveRequest.propTypes = {
  children: PropTypes.any,
  userId: PropTypes.any,
  showHeader: PropTypes.bool,
  fullWrapper: PropTypes.bool,
  className: PropTypes.string,
  currentUser: PropTypes.object,
  onCreateSuccess: PropTypes.bool,
  shouldContinueCreate: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state).user,
  };
};

export default connect(mapStateToProps)(CreateLeaveRequest);