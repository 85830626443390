export const CURRENCY_EXCHANGE_LIST_REQUEST = "CURRENCY_EXCHANGE_LIST_REQUEST";
export const CURRENCY_EXCHANGE_LIST_SUCCESS = "CURRENCY_EXCHANGE_LIST_REQUEST_SUCCESS";
export const CURRENCY_EXCHANGE_AUTOCOMPLETE_REQUEST = "CURRENCY_EXCHANGE_AUTOCOMPLETE_REQUEST";
export const CURRENCY_EXCHANGE_AUTOCOMPLETE_SUCCESS = "CURRENCY_EXCHANGE_AUTOCOMPLETE_SUCCESS";
export const CURRENCY_EXCHANGE_CREATE_REQUEST = "CURRENCY_EXCHANGE_CREATE_REQUEST";
export const CURRENCY_EXCHANGE_DETAIL_REQUEST = "CURRENCY_EXCHANGE_DETAIL_REQUEST";
export const CURRENCY_EXCHANGE_ARCHIVE_REQUEST = "CURRENCY_EXCHANGE_ARCHIVE_REQUEST";
export const CURRENCY_EXCHANGE_DELETE_REQUEST = "CURRENCY_EXCHANGE_DELETE_REQUEST";
export const CURRENCY_EXCHANGE_UNARCHIVE_REQUEST = "CURRENCY_EXCHANGE_UNARCHIVE_REQUEST";
export const CURRENCY_EXCHANGE_EDIT_REQUEST = "CURRENCY_EXCHANGE_EDIT_REQUEST";
export const CURRENCY_EXCHANGE_CREATE_FORM_NAME = "createCurrencyExchange";
export const CURRENCY_EXCHANGE_EDIT_FORM_NAME = "editCurrencyExchange";
export const CURRENCY_EXCHANGE_SUBMIT_RULES = {
  fromId: {
    required: true
  },
  toId: {
    required: true
  },
  rate: {
    required: true
  },
  date: {
    required: true
  }
};
export const READ_CURRENCY_EXCHANGE = 106000;
export const CREATE_CURRENCY_EXCHANGE = 106001;
export const UPDATE_CURRENCY_EXCHANGE = 106002;
export const DELETE_CURRENCY_EXCHANGE = 106003;
export const ARCHIVE_CURRENCY_EXCHANGE = 106004;
export const UNARCHIVE_CURRENCY_EXCHANGE = 106005;

export const CURRENCY_EXCHANGE_FROM_KHR = "KHR"; 
export const CURRENCY_EXCHANGE_FROM_USD = "USD"; 