import { schema } from "normalizr";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";

const CashAdvancedSchema = new schema.Entity("cashAdvances", {
  employee: EmployeeSchema
}, {
  idAttribute: "cashAdvancedNo"
});
const CashAdvancedArraySchema = new schema.Array(CashAdvancedSchema);

export { CashAdvancedSchema, CashAdvancedArraySchema };