export const EMAIL_TEMPLATE_LIST_REQUEST = "EMAIL_TEMPLATE_LIST_REQUEST";
export const EMAIL_TEMPLATE_LIST_SUCCESS = "EMAIL_TEMPLATE_LIST_REQUEST_SUCCESS";

export const EMAIL_TEMPLATE_CREATE_REQUEST = "EMAIL_TEMPLATE_CREATE_REQUEST";
export const EMAIL_TEMPLATE_CREATE_SUCCESS = "EMAIL_TEMPLATE_CREATE_SUCCESS";

export const EMAIL_TEMPLATE_DETAIL_REQUEST = "EMAIL_TEMPLATE_DETAIL_REQUEST";
export const EMAIL_TEMPLATE_DELETE_REQUEST = "EMAIL_TEMPLATE_DELETE_REQUEST";
export const EMAIL_TEMPLATE_ARCHIVE_REQUEST = "EMAIL_TEMPLATE_ARCHIVE_REQUEST";
export const EMAIL_TEMPLATE_UNARCHIVE_REQUEST = "EMAIL_TEMPLATE_UNARCHIVE_REQUEST";
export const EMAIL_TEMPLATE_EDIT_REQUEST = "EMAIL_TEMPLATE_EDIT_REQUEST";

export const EMAIL_TEMPLATE_AUTOCOMPLETE_REQUEST = "EMAIL_TEMPLATE_AUTOCOMPLETE_REQUEST";
export const EMAIL_TEMPLATE_AUTOCOMPLETE_SUCCESS = "EMAIL_TEMPLATE_AUTOCOMPLETE_REQUEST_SUCCESS";

export const EMAIL_TEMPLATE_TYPE_AUTOCOMPLETE_REQUEST = "EMAIL_TEMPLATE_TYPE_AUTOCOMPLETE_REQUEST";
export const EMAIL_TEMPLATE_TYPE_AUTOCOMPLETE_SUCCESS = "EMAIL_TEMPLATE_TYPE_AUTOCOMPLETE_REQUEST_SUCCESS";
export const EMAIL_TEMPLATE_TYPE_VARIABLE_REQUEST = "EMAIL_TEMPLATE_TYPE_VARIABLE_REQUEST";
export const EMAIL_TEMPLATE_TYPE_VARIABLE_SUCCESS = "EMAIL_TEMPLATE_TYPE_VARIABLE_SUCCESS";

export const EMAIL_TEMPLATE_CREATE_FORM_NAME = "createEmailTemplate";
export const EMAIL_TEMPLATE_EDIT_FORM_NAME = "editEmailTemplate";

export const EMAIL_TEMPLATE_SUBMIT_RULES = {
  title: {
    required: true
  },
  subject: {
    required: true
  },
  emailTemplateTypeId: {
    required: true
  },
  content: {
    required: true
  }
};

export const READ_EMAIL_TEMPLATE_CODE = 110000;
export const CREATE_EMAIL_TEMPLATE_CODE = 110001;
export const UPDATE_EMAIL_TEMPLATE_CODE = 146002;
export const DELETE_EMAIL_TEMPLATE_CODE = 146003;
export const ARCHIVE_EMAIL_TEMPLATE_CODE = 146004;
export const UNARCHIVE_EMAIL_TEMPLATE_CODE = 146005;