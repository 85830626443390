/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getBreadcrumbPageLabel } from "@modules/helpers";
import { Card, Button, Dropdown, Menu, PageHeader, Space, message } from "antd";
import {
  PlusCircleOutlined,
  SettingOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  subscribeEntityList,
  unsubscribeEntityList,
  getSubscribersList,
} from "@/services/subscription";
import { useApi } from "../../../hooks/use-api";

// Component
import Breadcrumb from "@cores/components/breadcrumb";

const Content = ({ children, extra }) => (
  <div className="content">
    <div className="main">{children}</div>
    <div className="extra">{extra}</div>
  </div>
);

const Header = (props) => {
  const {
    title,
    path,
    createable,
    extraButtons,
    actionDropdown,
    breadcrumbs,
    importable,
    onImportClick,
    showTitle,
    extraContent,
    watchable
  } = props;

  const [loading, setLoading] = React.useState(false);
  const { response, loading: listLoading } = useApi(getSubscribersList, title, [title]);
  const [subscribe, setSubscribe] = React.useState(false);

  React.useEffect(() => {
    if (response) {
      setSubscribe(response.isSubscribed);
    }
  }, [response, title]);
  
  const subscribing = () => {
    setLoading(true);
    subscribeEntityList({ entity: title })
      .then(() => {
        setLoading(false);
        setSubscribe(true);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const unSubscribing = () => {
    setLoading(true);
    unsubscribeEntityList({ entity: title })
      .then(() => {
        setLoading(false);
        setSubscribe(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const menu = (
    <Menu>
      {actionDropdown}
      {importable && (
        <Menu.Item key="import" onClick={onImportClick}>
          Import
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Card style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
      <PageHeader
        className="site-page-header"
        subTitle={status ? status : null}
        title={
          showTitle
            ? breadcrumbs && breadcrumbs.length > 0
              ? breadcrumbs[breadcrumbs.length - 1]
              : getBreadcrumbPageLabel(title)
            : null
        }
        extra={[
          createable && (
            <Fragment>
              <Link to={`${path}/create`}>
                <Button
                  type="primary"
                  className={
                    (actionDropdown && actionDropdown.length > 0) ||
                    importable ||
                    (extraButtons && extraButtons.length > 0)
                      ? "mr-1"
                      : null
                  }
                  icon={<PlusCircleOutlined />}
                >
                  <FormattedMessage id="listLayout.create" />
                </Button>
              </Link>
            </Fragment>
          ),
          ((actionDropdown && actionDropdown.length > 0) || importable) && (
            <Dropdown overlay={menu} className="ml-1" trigger={["click"]}>
              <Button ghost type="primary" icon={<SettingOutlined />}>
                Tools <DownOutlined />
              </Button>
            </Dropdown>
          ),
          extraButtons,
          <Space key="1" >
            {watchable == false && (
              <>
                <Button
                  className="ml-1"
                  loading={listLoading || loading}
                  type={subscribe ? undefined : "primary"}
                  onClick={() => subscribe ? unSubscribing() : subscribing()}
                  icon={subscribe ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                >
                  {listLoading ? "Loading..." : subscribe ? "Unwatch" : "Watch"}
                </Button>
              </>
            )}
          </Space>,
        ]}
        breadcrumbRender={() => (
          <Breadcrumb
            breadcrumbs={
              breadcrumbs != null
                ? breadcrumbs
                : [getBreadcrumbPageLabel(title)]
            }
          />
        )}
      >
        <Content extra={extraContent}></Content>
      </PageHeader>
    </Card>
  );
};

Header.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  createable: PropTypes.bool,
  importable: PropTypes.bool,
  extraButtons: PropTypes.any,
  actionDropdown: PropTypes.any,
  breadcrumbs: PropTypes.any,
  onImportClick: PropTypes.func,
  showTitle: PropTypes.bool,
  watchable: PropTypes.bool,
};

Header.defaultProps = {
  showTitle: true,
  watchable: false,
};

export default Header;