import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";

class AsyncStatusAutoComplete extends Component {
  
  render() {
    const {
      name,
      dataSource,
      placeholder,
      mode,
      ...props
    } = this.props;
    return (
      <AsyncSelect 
        name={name}
        mode={mode}
        staticDataSource
        advance={false}
        dataSource={dataSource}
        placeholder={placeholder}
        {...props}
      />
    );
  }
}

AsyncStatusAutoComplete.propTypes = {
  name: PropTypes.string,
  mode: PropTypes.string,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
};

AsyncStatusAutoComplete.defaultProps = {
  name: "status",
  mode: "single",
  dataSource: [
    {
      id: 1,
      name: "Active"
    },
    {
      id: -1,
      name: "Deactive"
    }
  ]
};

export default withRouter(AsyncStatusAutoComplete);