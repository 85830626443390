import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Typography, Modal } from "antd";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import GaugeChart from "react-gauge-chart";
import { LoginOutlined, LogoutOutlined, PlusCircleOutlined } from "@ant-design/icons";

// Constants
import Form from "./form";
import TimeClock from "../time-clock";
import * as AttendanceConstant from "../../constants";
import { 
  attendanceCheckIn, 
  attendanceCheckOut,
} from "../../actions";
import { 
  getCurrentUser,
  getAttendanceSettings, 
} from "@modules/utils/value-selector";
import DetailBox from "@cores/components/detail-box";
import { 
  getDayLabel, 
  getHourLabel,
} from "@modules/helpers";

const { Title } = Typography;

class AttendanceCheckEntry extends Component {

  state = {
    visible: false,
    type: null,
    values: null,
  }

  onVisibleModal = (type) => {
    this.setState({
      visible: true,
      type: type,
    });
  }

  onDismissModal = () => {
    this.setState({
      visible: false,
      type: null,
    });
  }

  onCheckEntrySubmit = (values) => {
    const { type } = this.state;
    const { checkIn, checkOut } = this.props;
    const action = type == AttendanceConstant.ATTENDANCE_CHECK_IN_TYPE ? checkIn : checkOut;
    const checked = action(values);
    if (checked != null) {
      this.onDismissModal();
    }
  }

  render() {
    const { type, visible } = this.state;
    const { 
      allowWebCheckInOut, 
      loadingCheckIn, 
      loadingCheckOut,
      attendanceSummary,
      isCurrentUserPage,
      onVisibleAddClick,
    } = this.props;
    let totalHours = 0;
    let employeeWorkingHours = 0;
    let totalPaidLeaveHours = 0 ;
    let totalHolidayHours = 0;
    if (attendanceSummary != null) {
      let { hours, workingHours, paidLeaveHours, holidayHours } = attendanceSummary;
      totalHours = Math.floor(hours * 100) / 100;
      employeeWorkingHours = workingHours;
      totalPaidLeaveHours = paidLeaveHours;
      totalHolidayHours = holidayHours;
    }
    return (
      <Fragment>
        <Row className="mb-3">
          <Col md="12">
            <Title level={4} className="mb-0">
              Summary
            </Title>
            <GaugeChart id="gauge-chart6" 
              animate={true} 
              nrOfLevels={15} 
              percent={(parseInt(((totalHours + totalPaidLeaveHours + totalHolidayHours) / employeeWorkingHours) * 100) / 100) || 0}  
              needleColor="#345243" 
              textColor="#000000" 
              colors={["#64b5f6", "#42a5f5", "#2196f3", "#1e88e5", "#1976d2", "#1565c0", "#0d47a1"]} 
            />
          </Col>
        </Row>
        <hr />
        {employeeWorkingHours > 0 && (
          <Row className="mb-3">
            <Col md="12">
              <DetailBox 
                title="Working Hours" 
                data={`${getHourLabel(totalHours || 0)} / ${getDayLabel(attendanceSummary && attendanceSummary.days || 0)}`}
              />
              <DetailBox 
                title="Paid Leave Hours" 
                data={getHourLabel(totalPaidLeaveHours || 0)}
              />
              <DetailBox 
                title="Holiday Hours" 
                data={getHourLabel(totalHolidayHours || 0)}
              />
              <DetailBox 
                title="Total Hours" 
                data={getHourLabel((Math.round((totalHours + totalPaidLeaveHours + totalHolidayHours) * 1000) / 1000) || 0)}
              />
            </Col>
          </Row>
        )}
        <div className="d-flex">
          <Button 
            block
            ghost
            icon={<LoginOutlined />}
            type="primary"
            disabled={!allowWebCheckInOut}
            className="justify-content-start mr-1"
            onClick={() => this.onVisibleModal(AttendanceConstant.ATTENDANCE_CHECK_IN_TYPE)}
          >
            Check In
          </Button>
          <Button 
            block
            ghost
            icon={<LogoutOutlined />}
            type="primary"
            disabled={!allowWebCheckInOut}
            className="justify-content-end"
            onClick={() => this.onVisibleModal(AttendanceConstant.ATTENDANCE_CHECK_OUT_TYPE)}
          >
            Check Out
          </Button>
        </div>
        {isCurrentUserPage && (
          <Button 
            ghost 
            block
            type="primary"
            className="mt-2"
            icon={<PlusCircleOutlined />}
            onClick={onVisibleAddClick} 
          >
            Create
          </Button>
        )}
        <Modal
          title={
            type == AttendanceConstant.ATTENDANCE_CHECK_IN_TYPE ? 
              AttendanceConstant.ATTENDANCE_CHECK_IN_TITLE : 
              AttendanceConstant.ATTENDANCE_CHECK_OUT_TITLE
          }
          visible={visible}
          onCancel={this.onDismissModal}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
        >
          <TimeClock />
          {type == AttendanceConstant.ATTENDANCE_CHECK_IN_TYPE ? (
            <Form 
              icon={<LoginOutlined />}
              loading={loadingCheckIn}
              onSubmit={this.onCheckEntrySubmit}
              form={AttendanceConstant.ATTENDANCE_CHECK_IN_FORM}
              label={AttendanceConstant.ATTENDANCE_CHECK_IN_TITLE}
            />
          ) : (
            <Form
              icon={<LogoutOutlined />}
              loading={loadingCheckOut}
              onSubmit={this.onCheckEntrySubmit}
              form={AttendanceConstant.ATTENDANCE_CHECK_OUT_FORM}
              label={AttendanceConstant.ATTENDANCE_CHECK_OUT_TITLE}
            />
          )}
        </Modal>
      </Fragment> 
    );
  }
}

AttendanceCheckEntry.propTypes = {
  location: PropTypes.any,
  checkIn: PropTypes.func,
  checkOut: PropTypes.func,
  currentUser: PropTypes.object,
  loadingCheckIn: PropTypes.bool,
  loadingCheckOut: PropTypes.bool,
  onVisibleAddClick: PropTypes.func,
  allowWebCheckInOut: PropTypes.bool,
  attendanceSummary: PropTypes.object,
  getAttendanceSummary: PropTypes.func,
  isCurrentUserPage: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { request } = state;
  return {
    currentUser: getCurrentUser(state),
    attendanceSummary: state.attendanceSummary.list.totalAttendance,
    allowWebCheckInOut: getAttendanceSettings(state).allowWebCheckInOut,
    loadingCheckIn: request[AttendanceConstant.ATTENDANCE_CHECK_IN_REQUEST],
    loadingCheckOut: request[AttendanceConstant.ATTENDANCE_CHECK_OUT_REQUEST],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkIn: (values) => dispatch(attendanceCheckIn(values)),
    checkOut: (values) => dispatch(attendanceCheckOut(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceCheckEntry);