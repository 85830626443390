// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import FilterComponent from "@cores/components/filter-box";
import AsyncMonthPicker from "@cores/components/async-month-picker";
import AsyncEmployeeAutoComplete from "@cores/components/async-auto-complete/employee";
import AsyncStatusAutoComplete from "@cores/components/async-auto-complete/async-status-auto-complete";

// Constants
import { CASH_ADVANCED_STATUS_DATA_SOURCE, PAYMENT_TYPE_DATA_SOURCE } from "../../constants";

class FilterBox extends Component {

  render() {
    const { hasEmployee } = this.props;
    return (
      <FilterComponent {...this.props} >
        <AsyncMonthPicker 
          name="month"
          placeholder="Month"
        />
        {hasEmployee && (
          <AsyncEmployeeAutoComplete 
            extra={{
              hasPayroll: true
            }}
          />
        )}
        <AsyncStatusAutoComplete 
          dataSource={CASH_ADVANCED_STATUS_DATA_SOURCE}
        />
        <AsyncStatusAutoComplete 
          name="paymentType"
          placeholder="Payment Type"
          dataSource={PAYMENT_TYPE_DATA_SOURCE}
        />
      </FilterComponent>
    );
  }
}

FilterBox.propTypes = {
  hasEmployee: PropTypes.bool,
};

export default FilterBox;