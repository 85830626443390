export const HOLIDAY_GROUP_LIST_REQUEST = "HOLIDAY_GROUP_LIST_REQUEST";
export const HOLIDAY_GROUP_LIST_SUCCESS = "HOLIDAY_GROUP_LIST_REQUEST_SUCCESS";
  
export const HOLIDAY_GROUP_AUTOCOMPLETE_REQUEST = "HOLIDAY_GROUP_AUTOCOMPLETE_REQUEST";
export const HOLIDAY_GROUP_AUTOCOMPLETE_SUCCESS = "HOLIDAY_GROUP_AUTOCOMPLETE_SUCCESS";
export const HOLIDAY_GROUP_COPY_REQUEST = "HOLIDAY_GROUP_COPY_REQUEST";

export const HOLIDAY_GROUP_CREATE_REQUEST= "HOLIDAY_GROUP_CREATE_REQUEST";
export const HOLIDAY_GROUP_DETAIL_REQUEST= "HOLIDAY_GROUP_DETAIL_REQUEST";
export const HOLIDAY_GROUP_EDIT_REQUEST= "HOLIDAY_GROUP_EDIT_REQUEST";
export const HOLIDAY_GROUP_ARCHIVE_REQUEST= "HOLIDAY_GROUP_ARCHIVE_REQUEST";
export const HOLIDAY_GROUP_UNARCHIVE_REQUEST= "HOLIDAY_GROUP_UNARCHIVE_REQUEST";
export const HOLIDAY_GROUP_DELETE_REQUEST= "HOLIDAY_GROUP_DELETE_REQUEST";

export const HOLIDAY_LIST_CREATE_REQUEST= "HOLIDAY_LISTS_CREATE_REQUEST";
export const HOLIDAY_LIST_EDIT_REQUEST= "HOLIDAY_LISTS_EDIT_REQUEST";
export const HOLIDAY_LIST_DELETE_REQUEST= "HOLIDAY_LISTS_DELETE_REQUEST";

export const HOLIDAY_GROUP_SUBMIT_RULES = {
  name: {
    required: true
  },
};

export const READ_HOLIDAY_GROUP = 151000;
export const CREATE_HOLIDAY_GROUP = 151001;
export const UPDATE_HOLIDAY_GROUP = 151002;
export const DELETE_HOLIDAY_GROUP = 151003;
export const ARCHIVE_HOLIDAY_GROUP = 151004;
export const UNARCHIVE_HOLIDAY_GROUP = 151005;
export const PRINT_HOLIDAY_GROUP = 151007;

export const READ_HOLIDAY_LIST = 152000;
export const CREATE_HOLIDAY_LIST = 152001;
export const UPDATE_HOLIDAY_LIST = 152002;
export const DELETE_HOLIDAY_LIST = 152003;

export const HOLIDAY_LIST_TYPE_SINGLE = 1; 
export const HOLIDAY_LIST_TYPE_SINGLE_TITLE = "Single"; 
export const HOLIDAY_LIST_TYPE_MULTIPLE = 2; 
export const HOLIDAY_LIST_TYPE_MULTIPLE_TITLE = "Multiple"; 
export const HOLIDAY_LIST_TYPE = [ 
  { 
    id: HOLIDAY_LIST_TYPE_SINGLE, 
    name: HOLIDAY_LIST_TYPE_SINGLE_TITLE 
  }, 
  { 
    id: HOLIDAY_LIST_TYPE_MULTIPLE, 
    name: HOLIDAY_LIST_TYPE_MULTIPLE_TITLE 
  } 
];
export const HOLIDAY_LIST_DURATION_FULL_DAY = 1; 
export const HOLIDAY_LIST_DURATION_FULL_DAY_TITLE = "Full Day"; 
export const HOLIDAY_LIST_DURATION_FIRST_HALF = 2; 
export const HOLIDAY_LIST_DURATION_FIRST_HALF_TITLE = "First Half"; 
export const HOLIDAY_LIST_DURATION_SECOND_HALF = 3; 
export const HOLIDAY_LIST_DURATION_SECOND_HALF_TITLE = "Second Half"; 
export const HOLIDAY_LIST_DURATION = [ 
  { 
    id: HOLIDAY_LIST_DURATION_FULL_DAY, 
    name: HOLIDAY_LIST_DURATION_FULL_DAY_TITLE
  }, 
  { 
    id: HOLIDAY_LIST_DURATION_FIRST_HALF, 
    name: HOLIDAY_LIST_DURATION_FIRST_HALF_TITLE 
  },
  { 
    id: HOLIDAY_LIST_DURATION_SECOND_HALF, 
    name: HOLIDAY_LIST_DURATION_SECOND_HALF_TITLE 
  } 
];

export const HOLIDAY_GROUP_CREATE_FORM_NAME = "createHolidayGroup";
export const HOLIDAY_GROUP_EDIT_FORM_NAME = "editHolidayGroup";
export const HOLIDAY_GROUP_COPY_FORM_NAME = "copyHolidayGroup";