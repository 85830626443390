import {
  GETList, 
  GETDetail,
  GETWithToken,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/user/v1";
const USER_SESSION_URL = "/api/session/v1";

export const getUserAccount = (values) => {
  const url = `${API_URL}/lists`;
  return GETList(url, values);
};

export function createUserAccount(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getUserAccountAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getUserAccountDetail(id, extra) {
  return GETDetail(API_URL, id, extra);
}

export function getUserProfile() {
  const url = `${API_URL}/profile`;
  return GETWithToken(url);
}

export function editUserAccount(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function changePasswordUserAccount(values) {
  const url = `${API_URL}/change-password`;
  return PUTWithToken(url, values);
}

export function resetPasswordUserAccount(values) {
  const { id } = values;
  const url = `${API_URL}/reset-password/${id}`;
  return POSTWithToken(url, values);
}

export function archiveUserAccount(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveUserAccount(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteUserAccount(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function updateUserStatus(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/status`;
  return PUTWithToken(url, values);
}

export function getUserSession(values) {
  const { id, page } = values;
  const numberPerPage = 20;
  const offset = page * numberPerPage;
  const limit = numberPerPage;
  const userId = id;
  const params = {
    userId,
    offset,
    limit
  };
  const url = `${USER_SESSION_URL}/list`;
  return GETWithToken(url, params);
}

export function deleteSession(id) {
  const url = `${USER_SESSION_URL}/${id}`;
  return DELETEWithToken(url);
}

export function deleteAllSession(employeeId) {
  const url = `${USER_SESSION_URL}/${employeeId}/clear`;
  return DELETEWithToken(url);
}