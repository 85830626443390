import React from "react";
import PropTypes from "prop-types";

class FontAwesome extends React.Component {

  render() {
    const { name, className } = this.props;
    return (
      <i className={`fa fa-${name} ${className}`} />
    );
  }
}

FontAwesome.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default FontAwesome;