import { 
  CustomerCompanySchema,
  CustomerCompanyArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as CustomerCompanyConstants from "../constants";

const path = "/sale/customer-center/customer-company";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomerCompany,
    schema: CustomerCompanyArraySchema,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createCustomerCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createCustomerCompany,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: (json) => `${path}/${json.customerCompanyNo}`,
    successMessage: "Customer company is created successfully",
  };
  return createNewEntityAction(options, values);
};

export const getCustomerCompanyAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getCustomerCompanyAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getCustomerCompanyDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomerCompanyDetail,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: CustomerCompanySchema,
  };
  return createDetailAction(options, id);
};

export const editCustomerCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editCustomerCompany,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: (json) => `${path}/${json.customerCompanyNo}`,
    entityName: "customerCompanies",
    schema: CustomerCompanySchema,
    successMessage: "Customer company is updated successfully",
  };
  return createEditAction(options, values);
};

export const editRenew= (values) => (dispatch, getState) => {

  const onSuccess = (json) => {
    const entities = getState().entities;
    const customerCompanies = entities.customerCompanies;
    const currentCustomerCompanies = {
      ...customerCompanies?.[values.customerCompanyNo]
    };
    const customerCompanySaleTeams = currentCustomerCompanies?.customerCompanySaleTeams;
    const companyResponses = currentCustomerCompanies?.companyResponses;
    if(json.saleTeamResponseDate) {
      customerCompanySaleTeams?.map((values) => {
        if(values.id == json.id) {
          values.isExpired = json.isExpired;
          values.saleTeamResponseDate = json.saleTeamResponseDate;
        }
      });
    } else {
      companyResponses?.map((values) => {
        if(values.id == json.id) {
          values.isExpired = json.isExpired;
          values.responseDate = json.responseDate;
        }
      });
    }
    dispatch({
      type: "MERGE_ENTITY",
      entityName: "customerCompanies",
      key: json.customerCompanyNo,
      entity: currentCustomerCompanies
    });
      
  };

  const options = {
    serviceMethod: service.editRenew,
    requestName: CustomerCompanyConstants.RENEW_REQUEST,
    dispatch,
    getState,
    entityName: "customerCompanies",
    schema: CustomerCompanySchema,
    onSuccess,
    successMessage: "Renew successfully",
  };
  return createEditAction(options, values);
};
export const archiveCustomerCompany = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveCustomerCompany,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Customer company is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteCustomerCompany = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteCustomerCompany,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Customer company is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveCustomerCompany = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveCustomerCompany,
    requestName: CustomerCompanyConstants.CUSTOMER_COMPANY_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer company is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const suspendCustomerCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.suspendCustomerCompany,
    schema: CustomerCompanySchema,
    requestName: CustomerCompanyConstants.SUSPEND_CUSTOMER_COMPANY_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer company  is suspended successfully!",
  };
  return createEntityActionAction(options, values);
};

export const activeCustomerCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.activeCustomerCompany,
    schema: CustomerCompanySchema,
    requestName: CustomerCompanyConstants.ACTIVE_CUSTOMER_COMPANY_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer company is activated successfully!",
  };
  return createEntityActionAction(options, values);
};

export const approveCustomerCompany = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveCustomerCompany,
    schema: CustomerCompanySchema,
    requestName: CustomerCompanyConstants.APPROVE_CUSTOMER_COMPANY_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer company is approved successfully!",
  };
  return createEntityActionAction(options, values);
};