// Libs
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Form, DatePicker } from "antd";

// Utils
import { 
  toQueryParamDate,
  getQueryParamUrl, 
  updateQueryParamUrl,
  convertFieldToDisplay,
  updateQueryParamToLocalStorage,
  getQueryParamFromLocalStorage,
} from "@modules/helpers";
import * as Constants from "@/constants/default-constants";

const FormItem = Form.Item;

class AsyncRangePicker extends Component {

  onFromChange = (value) => {
    const { queryStorage } = this.props;
    let query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const page = query.page;
    if (page && page > 1) {
      delete query.page;
    }
    if (value == null) {
      delete query["from"];
      delete query["to"];
    } else {
      query["from"] = toQueryParamDate(value);
      query["to"] =  toQueryParamDate(value.endOf("month"));
    }
    if (queryStorage == "url") {
      updateQueryParamUrl(query);
    } else {
      updateQueryParamToLocalStorage(query);
    }
  }

  onToChange = (value) => {
    const { queryStorage } = this.props;
    let query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const page = query.page;
    if (page && page > 1) {
      delete query.page;
    }
    query["to"] = toQueryParamDate(value);
    if (value == null ) {
      delete query["from"];
      delete query["to"];
    }
    if (queryStorage == "url") {
      updateQueryParamUrl(query);
    } else {
      updateQueryParamToLocalStorage(query);
    }
  }

  render() {
    const {
      fromName,
      toName,
      required,
      showToday,
      typeOfRange,
      queryStorage,
    } = this.props;
    const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const from = query[fromName];
    const to = query[toName];
    let valueFrom = moment(from, Constants.DEFAULT_ASYNC_DATE_FORMAT);
    let valueTo = moment(to, Constants.DEFAULT_ASYNC_DATE_FORMAT);
    const startOfMonth = moment().startOf(typeOfRange);
    const endOfMonth = moment().endOf(typeOfRange);
    if (!showToday) {
      if (from == undefined && to == undefined) {
        valueFrom = null;
        valueTo = null;
      }
    } else {
      if (from == undefined) {
        valueFrom = startOfMonth;
      }
      if (to == undefined) {
        valueTo = endOfMonth;
      }
    }
    return (
      <Fragment>
        <FormItem> 
          <DatePicker 
            value={valueFrom}
            placeholder={convertFieldToDisplay(fromName)}
            className="mr-1 mb-1"
            allowClear={required}
            onChange={this.onFromChange}
            format={Constants.DEFAULT_DISPLAY_DATE_FORMAT}
          />
        </FormItem>
        <FormItem> 
          <DatePicker 
            value={valueTo}
            placeholder={convertFieldToDisplay(toName)}
            allowClear={required}
            onChange={this.onToChange}
            format={Constants.DEFAULT_DISPLAY_DATE_FORMAT}
          />
        </FormItem>
      </Fragment>
    );
  }
}
 
AsyncRangePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool,
  fromName: PropTypes.string,
  toName: PropTypes.string,
  showToday: PropTypes.bool,
  typeOfRange: PropTypes.string,
  placeholder: PropTypes.string,
  queryStorage: PropTypes.string,
};

AsyncRangePicker.defaultProps = {
  required: true,
  showToday: false,
  fromName: "from",    
  toName: "to",
  typeOfRange: "month",
};

export default AsyncRangePicker;