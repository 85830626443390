import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAutoComplete } from "@modules/utils/value-selector";

// Component
import AutoCompleteAdvanceField from "./field";
import AutoCompleteBoxAdvanceField from "./card-box-field";
class AutoCompleteAdvance extends Component {

  constructor(props) {
    super(props);
    this.onLoadData();
    this.state = {
      isSearching: undefined,
      data: [],
      oldData: [],
      isFirstLoad: false,
      query: null,
    };
  }

  onLoadData = () => {
    const { loadData, onDataLoaded } = this.props;
    if (loadData) {
      const onLoadData = loadData();
      if (onLoadData != null) {
        onLoadData.then((data) => {
          if (onDataLoaded) {
            onDataLoaded(data);
          }
        }); 
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { 
      extra,
      loadData,
      dataSource, 
    } = this.props;
    const { isFirstLoad, query } = this.state;
    const { dataSource: newDataSource, extra: newExtra } = newProps;
    if (dataSource != newDataSource) {
      if (isFirstLoad == false) {
        this.setState({
          data: newDataSource,
          oldData: newDataSource,
          isFirstLoad: true
        });
      } else {
        this.setState({
          data: newDataSource,
          isSearching: true
        });
      }
    }
    if (newExtra && newExtra.ids) {
      delete newExtra.ids;
    }
    if (extra && extra.ids) {
      delete extra.ids;
    }
    if (!_.isEqual(newExtra, extra)) {
      loadData(query, newExtra);
    }
  }

  onSearch = (event) => {
    const { oldData } = this.state;
    const value = event.target.value;
    const { loadData } = this.props;
    if (value != "") {
      this.setState({
        data: oldData,
        isSearching: true,
        query: value
      });
      loadData(value);
    } else {
      const { oldData } = this.state;
      this.setState({
        isSearching: false,
        data: oldData,
        query: null
      });
    }
  }

  onSearchClick = (value) => {
    const { oldData } = this.state;
    const { loadData } = this.props;
    if (value) {
      this.setState({
        data: oldData,
        isSearching: true,
        query: value
      });
      loadData(value);
    } else {
      const { oldData } = this.state;
      this.setState({
        isSearching: false,
        data: oldData,
        query: null
      });
    }
  }

  renderDataSource = () => {
    const { 
      existingData, 
      selectedValue,
    } = this.props;
    const { 
      isSearching, 
      data, 
      oldData,
    } = this.state;
    let items = isSearching ? data : oldData;
    items = items.filter((item) => {
      const matchedData  = existingData.find((data) => {
        const existed = item.id === (data.existedKey || data.id);
        return existed;
      });
      return !matchedData;
    });
    if (selectedValue && selectedValue.length > 0) {
      items = items.concat(selectedValue);
    }
    items = _.uniqBy(items, "id");
    return items;
  }

  getValue = (value, data) => {
    let foundObject = _.find(data, function (e) {
      return e.id === value;
    });
    return foundObject;
  }

  onHandleChange = (value) => {
    let newValues = value;

    const { onSelectChange } = this.props;
    if (_.isArray(value) && value.length > 0) {
      newValues = value[value.length - 1];
    }
    const { onChange } = this.props;
    let selectedObject = null;
    const { oldData } = this.state;
    const existingData = this.getValue(newValues, oldData);
    selectedObject = existingData;
    if (existingData == null) {
      const { dataSource } = this.props;
      const newData = this.getValue(newValues, dataSource);
      if (newData != null) {
        selectedObject = newData;
        oldData.unshift(newData);  
      }
    } 
    if (onChange) {
      onChange(value, selectedObject);
    }
    if (onSelectChange) {
      onSelectChange(selectedObject);
    }
  }

  render() {
    const { isFirstLoad } = this.state;
    const { staticDataSource, onTableChange, isBox, disabled } = this.props;
    return (
      <Field 
        {...this.props}
        isFirstLoad={isFirstLoad}
        disabled={disabled}
        onSearch={this.onSearch}
        onTableChange={onTableChange}
        onSearchClick={this.onSearchClick}
        onHandleChange={this.onHandleChange}
        component={isBox ? AutoCompleteBoxAdvanceField :  AutoCompleteAdvanceField}
        dataSource={staticDataSource || this.renderDataSource()}
      />
    );
  }
}

AutoCompleteAdvance.propTypes = {
  disabled: PropTypes.func,
  onChange: PropTypes.func,
  extra: PropTypes.object,
  loadData: PropTypes.func,
  dataSource: PropTypes.array,
  onDataLoaded: PropTypes.func,
  existingData: PropTypes.array,
  onSelectChange: PropTypes.func,
  selectedValue: PropTypes.array,
  onTableChange: PropTypes.func,
  staticDataSource: PropTypes.array,
  isBox: PropTypes.bool,
};

AutoCompleteAdvance.defaultProps = {
  dataSource: [],
  selectedValue: [],
  existingData: [],
  mode: "single",
  required: true,
};

const mapStateToProps = (state, ownProps) => {
  let { requestName, dataSource: staticDataSource} = ownProps;
  return {
    entities: state.entities,
    loading: state.request[requestName],
    staticDataSource: staticDataSource,
    dataSource: staticDataSource || getAutoComplete(state, requestName)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let { extra, requestName, action } = ownProps;
  if (action) {
    const defaultExtra = { requestName, ...extra };
    return {
      loadData: (query, newExtra) => dispatch(action(query, newExtra ? { requestName, ...newExtra } : defaultExtra)),
    };
  }
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoCompleteAdvance));