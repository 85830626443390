export const ATTRIBUTE_LIST_REQUEST = "ATTRIBUTE_LIST_REQUEST"; 
export const ATTRIBUTE_LIST_SUCCESS = "ATTRIBUTE_LIST_REQUEST_SUCCESS";
export const ATTRIBUTE_CREATE_REQUEST = "ATTRIBUTE_CREATE_REQUEST"; 
export const ATTRIBUTE_DETAIL_REQUEST = "ATTRIBUTE_DETAIL_REQUEST"; 
export const ATTRIBUTE_ARCHIVE_REQUEST = "ATTRIBUTE_ARCHIVE_REQUEST"; 
export const ATTRIBUTE_DELETE_REQUEST = "ATTRIBUTE_DELETE_REQUEST"; 
export const ATTRIBUTE_UNARCHIVE_REQUEST = "ATTRIBUTE_UNARCHIVE_REQUEST"; 
export const ATTRIBUTE_EDIT_REQUEST = "ATTRIBUTE_EDIT_REQUEST"; 
export const ATTRIBUTE_CREATE_FORM_NAME = "createAttribute"; 
export const ATTRIBUTE_EDIT_FORM_NAME = "editAttribute"; 
export const ATTRIBUTE_AUTOCOMPLETE_REQUEST = "ATTRIBUTE_AUTOCOMPLETE_REQUEST";
export const ATTRIBUTE_AUTOCOMPLETE_SUCCESS = "ATTRIBUTE_AUTOCOMPLETE_SUCCESS";

export const ITEM_ATTRIBUTE_FIELD_NAME = "itemAttributeValues";

export const ATTRIBUTE_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  itemAttributeValues: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "value"
      },
      {
        name: "abbreviation",
      },
    ],
  }
};
export const READ_ATTRIBUTE = 202000;
export const CREATE_ATTRIBUTE = 202001;
export const UPDATE_ATTRIBUTE = 202002;
export const DELETE_ATTRIBUTE = 202003;
export const ARCHIVE_ATTRIBUTE = 202004;
export const UNARCHIVE_ATTRIBUTE = 202005;