export const JUNK_OUT_TYPE_LIST_REQUEST = "JUNK_OUT_TYPE_LIST_REQUEST"; 
export const JUNK_OUT_TYPE_LIST_SUCCESS = "JUNK_OUT_TYPE_LIST_REQUEST_SUCCESS";

export const JUNK_OUT_TYPE_CREATE_REQUEST = "JUNK_OUT_TYPE_CREATE_REQUEST"; 
export const JUNK_OUT_TYPE_DETAIL_REQUEST = "JUNK_OUT_TYPE_DETAIL_REQUEST"; 
export const JUNK_OUT_TYPE_ARCHIVE_REQUEST = "JUNK_OUT_TYPE_ARCHIVE_REQUEST"; 
export const JUNK_OUT_TYPE_DELETE_REQUEST = "JUNK_OUT_TYPE_DELETE_REQUEST"; 

export const JUNK_OUT_TYPE_UNARCHIVE_REQUEST = "JUNK_OUT_TYPE_UNARCHIVE_REQUEST"; 
export const JUNK_OUT_TYPE_EDIT_REQUEST = "JUNK_OUT_TYPE_EDIT_REQUEST"; 

export const JUNK_OUT_TYPE_CREATE_FORM_NAME = "createJunkOutType"; 
export const JUNK_OUT_TYPE_EDIT_FORM_NAME = "editJunkOutType";

export const JUNK_OUT_TYPE_AUTOCOMPLETE_REQUEST = "JUNK_OUT_TYPE_AUTOCOMPLETE_REQUEST";
export const JUNK_OUT_TYPE_AUTOCOMPLETE_SUCCESS = "JUNK_OUT_TYPE_AUTOCOMPLETE_SUCCESS";

export const JUNK_OUT_TYPE_SUBMIT_RULES = (values) => {
  const { isRequiredChartAccount } = values;
  return {
    name: {
      required: true,
    },
    chartAccountId: {
      required: isRequiredChartAccount,
    },
  };
};

export const READ_JUNK_OUT_TYPE = 260000;
export const CREATE_JUNK_OUT_TYPE = 260001;
export const UPDATE_JUNK_OUT_TYPE = 260002;
export const DELETE_JUNK_OUT_TYPE = 260003;
export const ARCHIVE_JUNK_OUT_TYPE = 260004;
export const UNARCHIVE_JUNK_OUT_TYPE = 260005;