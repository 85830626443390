
// Delivery
import { READ_DELIVERY_ORDER } from "@modules/delivery/delivery-management/delivery-order/constants";
import { READ_DELIVERY_TRACKING } from "@modules/delivery/delivery-management/delivery-tracking/constants";

export default {
  sideBarTitle: "Delivery",
  hasDashboard: true,
  isFixed: false,
  slug: "delivery",
  display: true,
  menus: [
    {
      id: 4,
      title: "Delivery Management",
      slug: "delivery-management",
      icon: "ShoppingCartOutlined",
      sub: [
        {
          id: 1,
          title: "Delivery Order",
          slug: "delivery-order",
          code: READ_DELIVERY_ORDER,
        },
        {
          id: 2,
          title: "Delivery Tracking",
          slug: "delivery-tracking",
          code: READ_DELIVERY_TRACKING,
        },
      ]
    },
  ]
};