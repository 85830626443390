import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/cash-advanced/v1";

export const getCashAdvanced = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createCashAdvanced(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getCashAdvancedAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getCashAdvancedDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editCashAdvanced(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveCashAdvanced(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveCashAdvanced(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteCashAdvanced(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function submitCashAdvanced(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function approveCashAdvanced(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectCashAdvanced(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function postCashAdvanced(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/post`;
  return PUTWithToken(url, values);
}

export function payCashAdvanced(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/pay`;
  return PUTWithToken(url, values);
}

export function setToDraftCashAdvanced(id) {
  const url = `${API_URL}/${id}/draft`;
  return PUTWithToken(url);
}