import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Form, Button } from "antd";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { VerticalAlignTopOutlined } from "@ant-design/icons";

// Component
import Input from "@modules/cores/components/Input";

class ShortCutForm extends Component {

  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onFinish={() => handleSubmit()} layout="vertical">
        <Row>
          <Col md="12">
            <Input 
              label="Edit ID"
              name="editId"
              autoFocus
            />
          </Col>
          <Col md="12">
            <Button 
              type="primary"
              ghost
              htmlType="submit"
              className="float-right"
              icon={<VerticalAlignTopOutlined />}
            >
              Go
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

ShortCutForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: "searchBoxForm"
})(ShortCutForm);