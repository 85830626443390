import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getRoleAutoComplete } from "@modules/settings/user-management/role/actions";

// Constant 
import { ROLE_AUTOCOMPLETE_REQUEST } from "@modules/settings/user-management/role/constants";

class RoleAutoComplete extends Component {

  render() {
    const { 
      ids,
      type,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        extra={{
          ids,
          type,
        }}
        {...props}
      />
    );
  }
}

RoleAutoComplete.propTypes = {
  ids: PropTypes.array,
  type: PropTypes.array,
};

RoleAutoComplete.defaultProps = {
  label: "Roles",
  name: "roles",
  required: true,
  action: getRoleAutoComplete,
  requestName: ROLE_AUTOCOMPLETE_REQUEST,
  mode: "multiple"
};

export default RoleAutoComplete;