import { schema } from "normalizr";
import { UserSchema } from "./user";
import { UserRoleArraySchema } from "./user-role";
import { CompanySchema } from "./company";
import { AppsArraySchema } from "./app";

const CurrentUserSchema = new schema.Object({
  user: UserSchema,
  roles: UserRoleArraySchema,
  company: CompanySchema,
  modules: AppsArraySchema,
});

export {
  CurrentUserSchema
};