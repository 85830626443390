import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getDepartmentAutoComplete, createDepartment } from "@modules/hr/employee-directory/department/actions";
import FormComponent from "@modules/hr/employee-directory/department/components/form-component";

// Constant 
import {
  DEPARTMENT_AUTOCOMPLETE_REQUEST,
  CREATE_DEPARTMENT,
  DEPARTMENT_SUBMIT_RULES,
  DEPARTMENT_CREATE_REQUEST
} from "@modules/hr/employee-directory/department/constants";

class DepartmentAutoComplete extends Component {
  
  render() {
    const { 
      ids,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        entity="Department"
        formComponent={FormComponent}
        createAction={createDepartment}
        permissionCode={CREATE_DEPARTMENT}
        submitRules={DEPARTMENT_SUBMIT_RULES}
        createRequestName={DEPARTMENT_CREATE_REQUEST}
        extra={{
          ids
        }}
        {...props}
      />
    );
  }
}

DepartmentAutoComplete.propTypes = {
  ids: PropTypes.array,
};

DepartmentAutoComplete.defaultProps = {
  label: "Department",
  name: "departmentId",
  required: true,
  action: getDepartmentAutoComplete,
  requestName: DEPARTMENT_AUTOCOMPLETE_REQUEST,
};

export default DepartmentAutoComplete;