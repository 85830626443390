import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

// Components
import Input from "@cores/components/Input"; 
import Editor from "@cores/components/Editor"; 
import CheckBox from "@cores/components/check-box";
import DatePicker from "@cores/components/date-picker";
import TimePicker from "@cores/components/time-picker";
import InputNumber from "@cores/components/input-number"; 
import InputCurrency from "@cores/components/input-currency"; 
import InputPhoneNumber from "@cores/components/input-phone-number"; 

import { 
  CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX,
  CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER,
  CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER,
  CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER,
  CUSTOMER_FIELD_INPUT_TYPE_NUMBER,
  CUSTOMER_FIELD_INPUT_TYPE_TEXT_AREA,
  CUSTOMER_FIELD_INPUT_TYPE_TEXT,
  CUSTOMER_FIELD_INPUT_TYPE_EDITOR,
  CUSTOMER_FIELD_INPUT_TYPE_PHONE_NUMBER,
  CUSTOMER_FIELD_INPUT_TYPE_CURRENCY,
  CUSTOMER_FIELD_INPUT_TYPE_IMAGE,
  CUSTOMER_FIELD_INPUT_TYPE_FILE,
} from "../../constants";
import UploadImageBox from "src/modules/cores/components/upload-image/upload-image-box";
import FileUpload from "src/modules/cores/components/file-uploader/index";

class CustomFieldElementMapper extends React.Component {

  render() {
    const { type, ...props } = this.props;
    switch (type) {
    case CUSTOMER_FIELD_INPUT_TYPE_CHECK_BOX:
      return <CheckBox {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_DATE_PICKER:
      return <DatePicker {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_DATE_TIME_PICKER:
      return <DatePicker showTime {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_TIME_PICKER:
      return <TimePicker {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_NUMBER:
      return <InputNumber {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_TEXT_AREA:
      return <Input {...props} type="TextArea" rows={4} />;
    case CUSTOMER_FIELD_INPUT_TYPE_TEXT:
      return <Input {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_EDITOR:
      return <Editor {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_PHONE_NUMBER:
      return <InputPhoneNumber {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_CURRENCY:
      return <InputCurrency {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_IMAGE:
      return <UploadImageBox {...props} />;
    case CUSTOMER_FIELD_INPUT_TYPE_FILE:
      return <FileUpload {...props} />;
    default:
      return <div>Unknown</div>;
    }
  }
}

CustomFieldElementMapper.propTypes = {
  type: PropTypes.number,
};

class CustomFieldField extends React.Component {

  render() {
    const { fields, customFieldDataSource } = this.props;
    const fieldName = fields.name;
    return (
      <Row>
        {customFieldDataSource.map((values, index) => {
          const { id, name, isRequired, columLayout, ...rest } = values;
          const inputName = `${fieldName}.${index}.${id}_${name}`;
          return (
            <Col md={columLayout} xs="12" key={index}>
              <CustomFieldElementMapper 
                required={isRequired}
                name={inputName}
                {...rest}
              />
            </Col>
          );
        })}
      </Row>
    );
  }
}

CustomFieldField.propTypes = {
  fields: PropTypes.object,
  customFieldDataSource: PropTypes.array,
};

export default CustomFieldField;