import React from "react";
import PropTypes from "prop-types";

// libs
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { Table, Button } from "antd";
import { withRouter } from "react-router-dom";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

// utils
import { confirmDeleteModal } from "@modules/helpers";
import { renderStatusLanguage } from "../../../helpers";
import { deleteLanguage, getLanguage } from "../../../actions";
import { LanguageArraySchema } from "../../../schema/employee-language";
import { LANGUAGE_REQUEST, EMPLOYEE_LANGUAGE_MODAL_ID } from "../../../constants";

class EmployeeLanguage extends React.Component {

  constructor(props) {
    super(props);
    this.getEmployeeLanguage();
  }

  getEmployeeLanguage() {
    const { 
      getEmployeeLanguage,
      match: { params } 
    } = this.props;
    getEmployeeLanguage(params.id);
  }

  confirmDelete = (id) => {
    const { 
      deleteLanguage, 
      match: { params } 
    } = this.props;
    const values = {
      id,
      employeeId: params.id,
    };
    confirmDeleteModal(
      values,
      "language",
      deleteLanguage
    );
  }

  columns = () => {
    const { openModalEdit } = this.props;
    return [
      {
        title: "Language",
        dataIndex: "language",
      },
      {
        title: "Reading",
        dataIndex: "readingLevel",
        render: (readingLevel) => renderStatusLanguage(readingLevel)
      },
      {
        title: "Writing",
        dataIndex: "writingLevel",
        render: (writingLevel) => renderStatusLanguage(writingLevel)
      },
      {
        title: "Speaking",
        dataIndex: "speakingLevel",
        render: (speakingLevel) => renderStatusLanguage(speakingLevel)
      },
      {
        title: "Listening",
        dataIndex: "listeningLevel",
        render: (listeningLevel) => renderStatusLanguage(listeningLevel)
      },
      {
        title: "Actions",
        render: (_, values) => {
          return (
            <div className="align-item-center justify-content-center text-align-center">
              <EditOutlined 
                onClick={() => openModalEdit(values, EMPLOYEE_LANGUAGE_MODAL_ID)} 
              />
              <DeleteOutlined 
                className="ml-2"
                onClick={() => this.confirmDelete(values.id)} 
              />
            </div>
          );
        }
      },
    ];
  };

  render() {
    const { 
      loading,
      entities,
      match: { params },
      employeeLanguage, 
      openModalCreate,
    } = this.props;
    const languageEntities = denormalize(
      employeeLanguage[params.id], 
      LanguageArraySchema, 
      entities
    );
    return (
      <Table 
        size="small"
        loading={loading}
        pagination={false}
        columns={this.columns()} 
        dataSource={languageEntities} 
        footer={() => {
          return (
            <Button 
              ghost
              size="small"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => openModalCreate(EMPLOYEE_LANGUAGE_MODAL_ID)}
            >
              Add New
            </Button>
          );
        }}
      />
    );
  }
}

EmployeeLanguage.propTypes = {
  handleSubmit: PropTypes.func,
  employeeLanguage: PropTypes.object,
  deleteLanguage: PropTypes.func,
  getEmployeeLanguage: PropTypes.func,
  match: PropTypes.any,
  openModalEdit: PropTypes.func,
  openModalCreate: PropTypes.func,
  entities: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { employeeLanguage, entities, request } = state;
  return { 
    entities,
    employeeLanguage,
    loading: request[LANGUAGE_REQUEST],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeLanguage: (id) => dispatch(getLanguage(id)),
    deleteLanguage: (id, employeeId) => dispatch(deleteLanguage(id, employeeId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeLanguage));