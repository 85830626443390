export const INSTALLATION_LIST_REQUEST = "INSTALLATION_LIST_REQUEST"; 
export const INSTALLATION_LIST_SUCCESS = "INSTALLATION_LIST_REQUEST_SUCCESS";
export const INSTALLATION_CREATE_REQUEST = "INSTALLATION_CREATE_REQUEST"; 
export const INSTALLATION_DETAIL_REQUEST = "INSTALLATION_DETAIL_REQUEST"; 
export const INSTALLATION_ARCHIVE_REQUEST = "INSTALLATION_ARCHIVE_REQUEST"; 
export const INSTALLATION_DELETE_REQUEST = "INSTALLATION_DELETE_REQUEST"; 
export const INSTALLATION_UNARCHIVE_REQUEST = "INSTALLATION_UNARCHIVE_REQUEST"; 
export const INSTALLATION_EDIT_REQUEST = "INSTALLATION_EDIT_REQUEST"; 

export const INSTALLATION_CREATE_FORM_NAME = "createInstallation"; 
export const INSTALLATION_EDIT_FORM_NAME = "editInstallation"; 

export const INSTALLATION_AUTOCOMPLETE_REQUEST = "INSTALLATION_AUTOCOMPLETE_REQUEST";
export const INSTALLATION_AUTOCOMPLETE_TREE_REQUEST = "INSTALLATION_AUTOCOMPLETE_TREE_REQUEST";
export const INSTALLATION_AUTOCOMPLETE_SUCCESS = "INSTALLATION_AUTOCOMPLETE_SUCCESS";
export const INSTALLATION_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  code: {
    required: true
  }
};
export const READ_INSTALLATION = 236000;
export const CREATE_INSTALLATION = 236001;
export const UPDATE_INSTALLATION = 236002;
export const DELETE_INSTALLATION = 236003;
export const ARCHIVE_INSTALLATION = 236004;
export const UNARCHIVE_INSTALLATION = 236005;