export const LEAVE_TYPE_LIST_REQUEST = "LEAVE_TYPE_LIST_REQUEST"; 
export const LEAVE_TYPE_LIST_SUCCESS = "LEAVE_TYPE_LIST_REQUEST_SUCCESS";
export const LEAVE_TYPE_CREATE_REQUEST = "LEAVE_TYPE_CREATE_REQUEST"; 
export const LEAVE_TYPE_DETAIL_REQUEST = "LEAVE_TYPE_DETAIL_REQUEST"; 
export const LEAVE_TYPE_ARCHIVE_REQUEST = "LEAVE_TYPE_ARCHIVE_REQUEST"; 
export const LEAVE_TYPE_DELETE_REQUEST = "LEAVE_TYPE_DELETE_REQUEST"; 
export const LEAVE_TYPE_UNARCHIVE_REQUEST = "LEAVE_TYPE_UNARCHIVE_REQUEST"; 
export const LEAVE_TYPE_EDIT_REQUEST = "LEAVE_TYPE_EDIT_REQUEST"; 
export const LEAVE_TYPE_CREATE_FORM_NAME = "createLeaveType"; 
export const LEAVE_TYPE_EDIT_FORM_NAME = "editLeaveType"; 
export const LEAVE_TYPE_AUTOCOMPLETE_REQUEST = "LEAVE_TYPE_AUTOCOMPLETE_REQUEST";
export const LEAVE_TYPE_AUTOCOMPLETE_SUCCESS = "LEAVE_TYPE_AUTOCOMPLETE_SUCCESS";

export const LEAVE_TYPE_DEFAULT_CALENDAR_COLOR = "#335e9c";
export const LEAVE_TYPE_DEFAULT_TEXT_COLOR = "#000000";

export const LEAVE_TYPE_SUBMIT_RULES = {
  name: {
    required: true,
  },
  leaveCode: {
    required: true,
  },
  status: {
    required: true,
  }
};
export const READ_LEAVE_TYPE = 159000;
export const CREATE_LEAVE_TYPE = 159001;
export const UPDATE_LEAVE_TYPE = 159002;
export const DELETE_LEAVE_TYPE = 159003;
export const ARCHIVE_LEAVE_TYPE = 159004;
export const UNARCHIVE_LEAVE_TYPE = 159005;