import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import km from "react-intl/locale-data/km";

// Component
import App from "@views";
import { ClientAuthContextProvider } from "./context/ClientAuthContext";

// Styles
import "@/styles/index.less";

// Store
import configureStore from "@/stores/configureStore";
import { generateMachineIdIfNeeded } from "@/shared/utils";
import { setLocale } from "@/actions/locale";

const store = configureStore();

addLocaleData(en);
addLocaleData(km);

if (localStorage.lang) {
  store.dispatch((setLocale(localStorage.lang)));
}

// Generate Machine ID 
generateMachineIdIfNeeded();

ReactDOM.render(
  <Provider store={store}>
    <ClientAuthContextProvider>
      <App />
    </ClientAuthContextProvider>
  </Provider>, 
  document.getElementById("root")
);