import React, { Fragment, useState } from "react";
import { Button, Spin, Form } from "antd";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, change } from "redux-form";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { SaveOutlined, CaretUpOutlined, CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";

// Utils
import { createEmployeePayrollIncentive } from "@modules/hr/employee-directory/employee/actions";
import { getEmployeePayrollIncentive } from "@modules/hr/employee-directory/employee/services";
import { 
  PAYROLL_INCENTIVE_CREATE_REQUEST,
  EMPLOYEE_PAYROLL_INCENTIVES_FIELD_NAME
} from "@modules/hr/employee-directory/employee/constants";

// Components
import CurrencyBox from "@cores/components/currency-box";
import InputCurrency from "@cores/components/input-currency";
import ArrayFieldInline from "@cores/components/array-field-inline";
import IncentiveCalculator from "../form-component/incentive-calculator";
import { useApi } from "@modules/hooks/useApi";

function PayrollIncentiveFormNew({ employeeId, payslipId, month, year, computations, form, handleSubmit, payslipComputationId, onDismiss }) {
  const [editingIndex, setEditingIndex] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const createLoading = useSelector(state => state.request[PAYROLL_INCENTIVE_CREATE_REQUEST] || false);
  const updateField = (fieldName, value) => dispatch(change(form, fieldName, value));
  
  const { response: incentives, loading, loaded } = useApi(getEmployeePayrollIncentive, { employeeId, payslipId });
  const employeePayrollIncentives = [];
  incentives && _.sortBy(incentives, "id").map(values => {
    if (values.payslipSaleIncentives && values.payslipSaleIncentives.length > 0) {
      values.payslipSaleIncentives.map(incentive => {
        const newIncentive = { ...incentive };
        const employee = values.employees.find(employee => newIncentive.employeeId == employee.id);
        newIncentive.employeePayrollIncentiveId = values.id;
        newIncentive.id = `${values.id}-${incentive.id}-${employee ? employee.id : -1}`;
        newIncentive.name = values.name;
        newIncentive.notation = values.notation;
        newIncentive.creator = values.creator;
        newIncentive.employee = employee;
        newIncentive.value = incentive.value;
        newIncentive.payslipSaleIncentiveId = incentive.id;
        employeePayrollIncentives.push(newIncentive);
      });
    } else {
      if (values.employees == null || values.employees.length == 0) {
        const newValues = { ...values };
        delete newValues.payslipSaleIncentives;
        delete newValues.employees;
        newValues.employeePayrollIncentiveId = values.id;
        newValues.id = `${values.id}`;
        newValues.value = 0;
        employeePayrollIncentives.push(newValues);
      } else {
        values.employees.map(employee => {
          const newValues = { ...values };
          delete newValues.payslipSaleIncentives;
          delete newValues.employees;
          newValues.employeePayrollIncentiveId = values.id;
          newValues.id = `${values.id}-${employee.id}`;
          newValues.employee = employee;
          newValues.value = 0;
          employeePayrollIncentives.push(newValues);
        });
      }
    }
  });
  if (initialized == false && loaded == true) {
    updateField(EMPLOYEE_PAYROLL_INCENTIVES_FIELD_NAME, employeePayrollIncentives);
    setInitialized(true);
  }
  const onShowCalculate = (index) => {
    if (editingIndex == index) {
      setEditingIndex(null);
    } else {
      setEditingIndex(index);
    }
  };

  const onSubmit = (values, dispatch) => {
    const newValues = {};
    newValues.payslipSaleIncentives = values.employeePayrollIncentives.map((incentive) => {
      const newIncentive = {};
      newIncentive.employeePayrollIncentiveId = incentive.employeePayrollIncentiveId;
      newIncentive.employeeId = incentive.employee && incentive.employee.id;
      newIncentive.payslipId = payslipId;
      newIncentive.percentage = incentive.percentage;
      newIncentive.value = incentive.value;
      newIncentive.id = incentive.payslipSaleIncentiveId;
      return newIncentive;
    });
    newValues.payslipComputationId = payslipComputationId;
    return dispatch(createEmployeePayrollIncentive(newValues)).then(() => {
      onDismiss();
    });
  };

  const columns = (fields, editingIndex) => {
    return [
      {
        title: "No",
        dataIndex: "no",
        render: (name, row, index) => index + 1
      },
      {
        title: "Amount",
        dataIndex: "value",
        width: "25%",
        render: (name, row, index) => {
          const filedName = `${fields.name}[${index}].value`;
          return (
            <InputCurrency
              name={filedName}
            />
          );
        }
      },
      {
        title: "Incentive",
        dataIndex: "percentage",
        width: "20%",
        render: (percentage, row, index) => {
          const saleAmount = fields.get(index).value;
          const incentive = (saleAmount * percentage) / 100;
          return (
            <Fragment>
              <CurrencyBox value={incentive} /> of {percentage} %
            </Fragment>
          );
        }
      },
      {
        title: "Employee",
        dataIndex: "employee",
        width: "20%",
        render: (employee) => {
          if (employee) {
            return (
              <Link to={`/human-resource/employee-directory/employee/${employee.id}`}>
                {employee.firstName} {employee.lastName}
              </Link>
            );
          }
        }
      },
      {
        title: "Notation",
        dataIndex: "notation",
        width: "25%",
      },
      {
        title: "Action",
        width: "10%",
        render: (_, __, index) => {
          return (
            <Button 
              ghost
              type="primary"
              onClick={() => onShowCalculate(index)}
              icon={editingIndex == index ? <CaretUpOutlined /> : <CaretDownOutlined />}
            >
              Calculate
            </Button>
          );
        }
      },
    ];
  };

  return (
    <Fragment>
      {loading == true ? (
        <Spin 
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} 
          className="d-block"
          tip="Loading..." 
        />
      ) : (
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Row>
            <Col xs="12">
              <ArrayFieldInline 
                footer={false}
                label={null}
                rowKey="id"
                onFirstAdd={false}
                className="overflow-hidden"
                columns={(props) => columns(props, editingIndex)}
                name={EMPLOYEE_PAYROLL_INCENTIVES_FIELD_NAME}
                expandedRowKeys={editingIndex != null ?[employeePayrollIncentives[editingIndex].id] : []}
                expandedRowRender={() => {
                  if (editingIndex != null) {
                    return (
                      <IncentiveCalculator
                        employeeId={employeePayrollIncentives[editingIndex].employee && employeePayrollIncentives[editingIndex].employee.id}
                        month={month}
                        year={year}
                        updateField={updateField}
                        index={editingIndex}
                        fieldName={EMPLOYEE_PAYROLL_INCENTIVES_FIELD_NAME}
                        computations={computations}
                      />
                    );
                  }
                }}
              />
            </Col>
            <Col xs="12">
              <Button 
                ghost
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={createLoading}
                className="float-right" 
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Fragment>
  );
}

PayrollIncentiveFormNew.propTypes = {
  form: PropTypes.string,
  handleSubmit: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
  computations: PropTypes.array,
  payslipId: PropTypes.number,
  employeeId: PropTypes.number,
  onDismiss: PropTypes.func,
  payslipComputationId: PropTypes.number,
};

export default reduxForm({
  enableReinitialize: true
})(PayrollIncentiveFormNew);