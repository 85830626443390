import { SET_FULL_SCREEN } from "../constants";

const initialState = {
  isFullScreen: false
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_FULL_SCREEN:
    return {
      isFullScreen: action.isFullScreen
    };
  }
  return state;
}