import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getJobTypeAutoComplete, createJobType } from "@modules/hr/employee-directory/job-type/actions";
import FormComponent from "@modules/hr/employee-directory/job-type/components/form-component";

// Constant 
import { 
  CREATE_JOB_TYPE,
  JOB_TYPE_SUBMIT_RULES,
  JOB_TYPE_CREATE_REQUEST,
  JOB_TYPE_AUTOCOMPLETE_REQUEST
} from "@modules/hr/employee-directory/job-type/constants";

class JobTypeAutoComplete extends Component {

  render() {
    const { 
      ids,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        entity="JobType"
        formComponent={FormComponent}
        createAction={createJobType}
        permissionCode={CREATE_JOB_TYPE}
        submitRules={JOB_TYPE_SUBMIT_RULES}
        createRequestName={JOB_TYPE_CREATE_REQUEST}
        extra={{
          ids,
        }}
        {...props}
      />
    );
  }
}

JobTypeAutoComplete.propTypes = {
  ids: PropTypes.array,
};

JobTypeAutoComplete.defaultProps = {
  label: "Job Type",
  name: "jobTypeId",
  required: true,
  action: getJobTypeAutoComplete,
  requestName: JOB_TYPE_AUTOCOMPLETE_REQUEST,
};

export default JobTypeAutoComplete;