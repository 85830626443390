import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import BraftEditor from "braft-editor";
import table from "braft-extensions/dist/table";
import ColorPicker from "braft-extensions/dist/color-picker";
import Markdown from "braft-extensions/dist/markdown";
import CodeHighlighter from "braft-extensions/dist/code-highlighter";
import "prismjs/components/prism-java";
import "prismjs/components/prism-php";
import { 
  API_URL,
  tableOption,
  markdownOption,
  buildPreviewHtml, 
  colorPickerOption,
  codeHightLightOptions, 
} from "./configuration";
import * as axios from "axios";

const FormItem = Form.Item;

import { getAccessToken } from "@/shared/utils";
const token = getAccessToken();

BraftEditor.use(table(tableOption));
BraftEditor.use(Markdown(markdownOption));
BraftEditor.use(ColorPicker(colorPickerOption));
BraftEditor.use(CodeHighlighter(codeHightLightOptions));

class RenderEditorField extends Component {

  constructor(props) {
    super(props);
    const { input: { value }  } = props;
    this.state = {
      editorState: BraftEditor.createEditorState(value),
      viewSourceCode: false,
      onChanging: false,
    };
  }

  componentDidMount() {
    this.braftFinder = this.editorInstance.getFinderInstance();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { input: { value } } = newProps;
    const { onChanging } = this.state;
    if (value == "") {
      this.setState({
        editorState: null,
      });
    } else { 
      if (!onChanging) {
        this.setState({
          editorState: BraftEditor.createEditorState(value),
        });
      }
    }
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
      onChanging: true,
      outputHTML: editorState.toHTML()    
    }, () => {
      const { 
        input: { onChange }, 
        handleChange
      } = this.props;
      const { editorState } = this.state;
      const value = editorState && editorState.toHTML();
      if (value == "<p></p>") {
        onChange(null);
      } else {
        onChange(value);
      }
      if (handleChange) {
        handleChange(value);
      }
    });
  }

  onViewSourceCode = () => {
    const { viewSourceCode } = this.state;
    this.setState({
      viewSourceCode: !viewSourceCode,
    });
  }

  preview = () => {
    const { editorState } = this.state;
    if (window.previewWindow) {
      window.previewWindow.close();
    }
    window.previewWindow = window.open();
    window.previewWindow.document.write(buildPreviewHtml(editorState));
    window.previewWindow.document.close();
  }

  onUpload = (params) => {
    const {
      file,
      success,
      progress
    } = params;
    const formData = new FormData();
    formData.append("file", file);
    axios.post(API_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token && token.accessToken}`
      },
      onUploadProgress: (progressEvent) => {
        const progressPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        progress(progressPercentage);
      }
    }).then((res) => {
      success({
        url: res.data.url
      });
    });
  }

  render() {
    const { 
      label,
      required,
      viewSourceCode: isViewSourceCode,
      meta: { touched, error },
    } = this.props;
    const { editorState, outputHTML, viewSourceCode } = this.state;
    const extendControls = [
      {
        key: "view-source",
        type: "button",
        text: "< />",
        onClick: this.onViewSourceCode
      },
      {
        key: "custom-button",
        type: "button",
        text: "Preview",
        onClick: this.preview
      }
    ];
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <FormItem 
        label={label} 
        validateStatus={validateStatus}
        help={error}
        className="editor"
        required={required}
      >
        <BraftEditor
          id="editor"
          language="en"
          imageResizable
          ref={instance => this.editorInstance = instance}
          value={editorState}
          onChange={this.onChange}
          media={{uploadFn: this.onUpload}}
          extendControls={isViewSourceCode ? extendControls : []}
          contentClassName={viewSourceCode && "d-none"}
          componentBelowControlBar={
            viewSourceCode && (
              <div className="code-block">
                {outputHTML}
              </div>
            ) 
          }
        />
      </FormItem>
    );
  }
}

RenderEditorField.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  handleChange: PropTypes.func,
  viewSourceCode: PropTypes.bool,
};

RenderEditorField.defaultProps = {
  required: false,
  viewSourceCode: true,
};

class Editor extends Component {

  render() {
    const { 
      handleChange,
      ...props
    } = this.props;
    return (
      <Field 
        component={RenderEditorField}
        handleChange={handleChange}
        {...props}
      />
    );
  }
}

Editor.propTypes = {
  handleChange: PropTypes.func,
};

export default Editor;