import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { withRouter } from "react-router-dom";
import { submit as ReduxFormSubmit } from "redux-form"; 
import Loading from "react-loading-bar";

// Components
import EditForm from "./form";
import NotFound from "@/components/notfound";
import DisableBox from "@modules/cores/components/disabled-box";

// Helpers
import { 
  getMainPath,
  getBreadcrumbPageLabel,
  getShortNameWithTooltip,
} from "@/modules/helpers";
import { getPageTitle } from "@modules/utils/value-selector";

class EditLayout extends Component {

  constructor(props) {
    super(props);
    this.getDetailData();
    this.onEventLoad();
  }

  onEventLoad = () => {
    const { onSaveClick } = this.props;
    if (!onSaveClick) {
      window.addEventListener("keydown", (event) => {
        if (event.ctrlKey && event.which === 83) {
          event.preventDefault();
          this.onSubmit();
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", null);
  }

  getDetailData = () => {
    const { match: { params }, id, loadData }= this.props;
    loadData(id || params.id);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { match: { params }, loadData } = this.props;
    const { match } = newProps;
    const oldPropId = params.id;
    const newPropId = match.params.id;
    if (oldPropId != newPropId) {
      loadData(newPropId);
    }
  }

  onSubmit = () => {
    const { beforeSubmitConfirmation }  = this.props;
    if (beforeSubmitConfirmation != null) {
      const beforeSubmitConfirmationValue = beforeSubmitConfirmation();
      if (typeof beforeSubmitConfirmationValue === "object" && typeof beforeSubmitConfirmationValue.then === "function") {
        beforeSubmitConfirmationValue.then(() => {
          const { submit, onSubmit } = this.props;
          if (onSubmit) {
            onSubmit();
          } else {
            submit();
          }
        });
      }
    } else {
      const { submit, onSubmit } = this.props;
      if (onSubmit) {
        onSubmit();
      } else {
        submit();
      }
    }
  }

  renderTitleName = (val) => {
    const { titleKey, renderTitle } = this.props;
    const titleName = typeof renderTitle != "function"
      ? val[titleKey]
      : renderTitle(val);
    return titleName;
  }

  beforeInitialValues = (object) => {
    const { beforeInitialValues } = this.props;
    return beforeInitialValues(object);
  }

  render() {
    const { 
      title,
      menu,
      rules,
      schema,
      error,
      entities,
      loading,
      status,
      children,
      newSubmit,
      pageTitle,
      id: newId,
      loadingEdit,
      actionTitle,
      fullWrapper,
      breadcrumbs,
      beforeSubmit,
      extraButtons,
      formComponent,
      shouldDisabled,
      denormalizeId,
      disabledMessage,
      beforeInitialValues,
      beforeSubmissionError,
      noCustomField,
      match: { url, params: { id } },
      ...props
    } = this.props;
    const objectEntities = denormalize((denormalizeId || newId || id), schema, entities);
    let titleName = this.renderTitleName(
      objectEntities != null ? objectEntities : []
    );
    const newShouldDisabled = typeof(shouldDisabled) == "function" ? shouldDisabled(objectEntities || []) : shouldDisabled;
    const pageName = getBreadcrumbPageLabel(title);
    const location = url.split("/");
    const path = getMainPath(location);
    pageTitle(`${actionTitle} ${pageName}`);
    if (titleName && titleName.length > 15) {
      titleName = getShortNameWithTooltip(titleName, 25);
    }
    return (
      <Fragment>
        <Loading
          show={loading}
          color="#FFFFFF"
          showSpinner={false}
        />
        {error ? (
          <NotFound 
            status={error.statusCode}
            name={title} 
            backTo={path}
          />
        ) : (
          <Fragment>
            { loading == false && objectEntities != null && (
              <EditForm
                menu={menu}
                rules={rules}
                status={status}
                titleName={titleName}
                loading={loadingEdit}
                newSubmit={newSubmit}
                onSave={this.onSubmit}
                fullWrapper={fullWrapper}
                extraButtons={extraButtons}
                updatable={!newShouldDisabled}
                formComponent={formComponent}
                initialValues={beforeInitialValues ? 
                  this.beforeInitialValues(objectEntities) : 
                  objectEntities
                }
                objectEntities={objectEntities}
                beforeSubmissionError={beforeSubmissionError}
                beforeSubmit={beforeSubmit ? beforeSubmit : null}
                customFieldDataSource={noCustomField ? [] : (objectEntities.customFields || [])}
                breadcrumbs={[pageName, titleName, actionTitle, ...breadcrumbs]}
                {...props}
              >
                {children}
                {newShouldDisabled && (
                  disabledMessage ? (
                    <DisableBox
                      className="text-center"
                      message={disabledMessage}
                    />
                  ) : (
                    <DisableBox
                      className="text-center"
                      message={`This ${getBreadcrumbPageLabel(title)} cannot be ${actionTitle.toLowerCase()}ed.`}
                    />
                  )
                )}
              </EditForm>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

EditLayout.propTypes = {
  match: PropTypes.any,
  loadData: PropTypes.func,
  renderTitle: PropTypes.func,
  title: PropTypes.string,
  titleKey: PropTypes.string,
  schema: PropTypes.any,
  entities: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  children: PropTypes.node,
  actionTitle: PropTypes.string,
  loadingEdit: PropTypes.bool,
  menu: PropTypes.any,
  submit: PropTypes.func,
  formComponent: PropTypes.any,
  rules: PropTypes.any,
  beforeSubmit: PropTypes.func,
  beforeInitialValues: PropTypes.func,
  beforeSubmissionError: PropTypes.func,
  newSubmit: PropTypes.func,
  fullWrapper: PropTypes.bool,
  shouldDisabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  pageTitle: PropTypes.func,
  id: PropTypes.number,
  denormalizeId: PropTypes.number,
  breadcrumbs: PropTypes.array,
  onSubmit: PropTypes.func,
  extraButtons: PropTypes.any,
  onSaveClick: PropTypes.func,
  noCustomField: PropTypes.bool ,
  status: PropTypes.any,
  beforeSubmitConfirmation: PropTypes.func,
};

EditLayout.defaultProps = {
  titleKey: "name",
  actionTitle: "Edit",
  shouldDisabled: false,
  menu: [],
  breadcrumbs: [],
};

const mapStateToProps = (state, ownProps) => {
  const { 
    requestName, 
    editSubmitRequestName,
    requestNames 
  } = ownProps;
  let loading = state.request[requestName];
  if (requestNames) {
    loading = requestNames.some(value => state.request[value]); 
  }
  return {
    entities: state.entities,
    error: state.error[requestName],
    loading: loading,
    loadingEdit: state.request[editSubmitRequestName],
    pageTitle: (page) => getPageTitle(state, page),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    loadData,
    form
  } = ownProps;
  return {
    loadData: (id) => dispatch(loadData(id)),
    submit: () => dispatch(ReduxFormSubmit(form)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLayout));