import { message } from "antd";
import moment from "moment";

// Utils
import { setError } from "./error";
import { setRequestInProcess } from "./request";
import * as service from "@/services/authentication";
import * as CompanyService from "@/services/company";
import * as Constants from "@/constants/current-user";
import { createEditAction } from "@modules/utils/action-creator";
import * as AuthenticationConstants from "@/constants/authentication";
import { FIND_COMPANY_REQUEST, FIND_COMPANY_SUCCESS } from "@/constants/company";
import { DEFAULT_DISPLAY_DATE_TIME_FORMAT } from "@/constants/default-constants";

export const authenticateClientCredentialIfNeeded = (values) => (dispatch, getState) => {

  const onSuccess = (clientCredential) => {
    const localClientCredential = localStorage.getItem(
      AuthenticationConstants.CLIENT_CREDENTIAL_TYPE
    );
    if (localClientCredential == null) {
      localStorage.setItem(
        AuthenticationConstants.CLIENT_CREDENTIAL_TYPE,
        JSON.stringify(clientCredential)
      );
    } else {
      const { expiresIn } = localClientCredential;
      const momentExpireDate = moment(expiresIn).format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      const expireDate = moment(momentExpireDate);
      const now = moment().format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      const currentDate = moment(now);
      const isExpired = expireDate.isBefore(currentDate);
      if (isExpired) {
        localStorage.removeItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
        localStorage.setItem(
          AuthenticationConstants.CLIENT_CREDENTIAL_TYPE,
          JSON.stringify(clientCredential)
        );
      }
    }
  };

  const option = {
    dispatch,
    getState,
    requestName: AuthenticationConstants.CLIENT_CREDENTIAL_REQUEST,
    serviceMethod: service.authenticateClientCredential,
    onSuccess: onSuccess
  }; 
  return createEditAction(option, values);
};

export const loginUserPasswordIfNeeded = (values) => (dispatch, getState) => {

  const onSuccess = (token) => {
    if (token) {
      localStorage.setItem(
        AuthenticationConstants.LOGIN_TYPE,
        JSON.stringify(token)
      );
    }
  };

  const option = {
    dispatch,
    getState,
    requestName: AuthenticationConstants.LOGIN_REQUEST,
    serviceMethod: service.loginUserPassword,
    onSuccess: onSuccess,
    showMessage: false,
    redirectUrl: () => "/"
  }; 

  return createEditAction(option, values);
};

// eslint-disable-next-line no-unused-vars
export const logOut = (machineId) => (dispatch, getState) => {

  const requestInProcess = getState().request[
    AuthenticationConstants.LOGOUT_REQUEST
  ];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, AuthenticationConstants.LOGOUT_REQUEST));
  return service.logOut().then(() => {
    const process = () => {
      localStorage.removeItem(AuthenticationConstants.LOGOUT_REQUEST);
      localStorage.removeItem(AuthenticationConstants.USER_LAST_ACCESS);
      localStorage.removeItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
      localStorage.removeItem(AuthenticationConstants.LOGIN_TYPE);
      // if (machineId != null) {
      //   localStorage.removeItem(AuthenticationConstants.MACHINE_ID);
      // }
      dispatch(setRequestInProcess(false, AuthenticationConstants.LOGOUT_REQUEST));
      window.location.href = "/login";
    };
    const OneSignal = window.OneSignal;
    if (OneSignal) {
      OneSignal.isPushNotificationsEnabled().then(function(isEnabled) {
        if (isEnabled) {
          OneSignal.setSubscription(false);
        }
        process();
      });
    } else {
      process();
    }
    
  }).catch((error) => {
    dispatch(setRequestInProcess(false, AuthenticationConstants.LOGOUT_REQUEST));
    dispatch(setError(error, AuthenticationConstants.LOGOUT_REQUEST));
    message.error(error.message);
  });
};

export const loginUserPasswordIfLockScreen = (values) => (dispatch, getState) => {

  const requestInProcess = getState().request[
    AuthenticationConstants.LOGIN_REQUEST
  ];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, AuthenticationConstants.LOGIN_REQUEST));
  return service.
    loginUserPasswordIfLockScreen(values).then(() => {
      dispatch(setRequestInProcess(false, AuthenticationConstants.LOGIN_REQUEST));
    }).catch((error) => {
      dispatch(setRequestInProcess(false, AuthenticationConstants.LOGIN_REQUEST));
      dispatch(setError(error, AuthenticationConstants.LOGIN_REQUEST));
      message.error(error.message);
      return error;
    });
};


export const getCompanyInfo = (subDomain) => (dispatch, getState) => {
  
  const success = (company) => {
    return {
      type: FIND_COMPANY_SUCCESS,
      subDomain,
      company
    };
  };

  const requestName = FIND_COMPANY_REQUEST;
  const requestInProcess = getState().request[requestName];
  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return CompanyService.findCompany(subDomain)
    .then(company => {
      dispatch(setRequestInProcess(false, requestName));
      dispatch(success(company));
    }).catch(error => {
      if (error && error.statusCode == Constants.INVALID_ACCESS_TOKEN_STATUS_CODE) {
        localStorage.removeItem(AuthenticationConstants.USER_LAST_ACCESS);
        localStorage.removeItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
        localStorage.removeItem(AuthenticationConstants.LOGIN_TYPE);
        window.location.href = "/login";
      }
      dispatch(setError(error, requestName));
      dispatch(setRequestInProcess(false, requestName));
      return error;
    });
};