import { ITEM_RESERVE_LOG_SUCCESS } from "../constants";

export default function (state = {}, action) {
  switch (action.type) {
  case ITEM_RESERVE_LOG_SUCCESS:
    return {
      ...state,
      [action.id]: action.payload
    };
  default:
    return state;
  }
}