import * as Constants from "@/constants/subscription";

const initialState = {
  EmailAccount: {},
  OfferTerm: {},
  OfferLetter: {},
  RecruitmentStage: {},
  CustomerGroup: {},
  JobOpening: {},
  JobApplicant: {},
  Department:{},
  Employee:{},
  JobType:{},
  Position:{},
  AppraisalPlan:{},
  AppraisalForm:{},
  Currency: {},
  LeaveType: {},
  CurrencyExchange: {},
  LeaveAllocation: {},
  LeaveRequest: {},
  Tax: {},
  Contract: {},
  EarningType: {},
  User: {},
  Role:{},
  DeductionType: {},
  SalaryStructure: {},
  EmployeePayroll: {},
  Payslip: {},
  AppraisalAnniversary: {},
  PayslipBatch: {},
  LeavePolicy: {},
  Company: {},
  EmployeeLeaveSetting: {},
  EmailTemplate: {},
  PrintFormat: {},
  HolidayGroup: {},
  EmploymentCard: {},
  Category: {},
  Brand: {},
  Tag: {},
  SupplierType: {},
  Supplier: {},
  UnitOfMeasure: {},
  UnitOfMeasureSet: {},
  Item: {},
  ItemAttribute: {},
  Warehouse: {},
  StockIn: {},
  StockOut: {},
  StockAdjustment: {},
  StockTransfer: {},
  ChartOfAccount: {},
  PurchaseOrderType: {},
  PurchaseOrder: {},
  JournalEntry: {},
  PaymentTerm: {},
  CustomerCompany: {},
  PriceList: {},
  Expense: {},
  ExpenseType: {},
  Customer: {},
  CustomerDeposit: {},
  CustomerRefund: {},
  ReportTemplate: {},
  Quotation: {},
  SaleOrder: {},
  SaleTeam: {},
  Invoice: {},
  PaymentMethod: {},
  NoSeriesList: {},
  ChequeEntry: {},
  Bank: {},
  BankTransfer: {},
  DeliveryOrder: {},
  SupplierDeposit: {},
  Shipper: {},
  ItemDepartment: {},
  JunkOut: {},
  Promotion: {},
  BankEntry: {},
  SaleCommission: {},
  WorkingDay: {},
  CustomizeItem: {},
  CustomerCommission: {},
  CompanyGroup: {},
  Installation: {},
  Service: {},
  CashAdvanced: {},
  ItemInstallation: {},
  SupplierRefund: {},
  InstallationTemplate: {},
  CustomField: {},
  Contractor: {},
  IncentiveTemplate: {},
  Bid: {},
  SaleReceipt: {},
  Warranty: {},
  Asset: {},
  AssetRequest: {},
  Integration: {},
  Banner: {},
  Contact: {},
  PurchaseOrderShipperPayment: {},
  JunkOutType: {},
  Loan: {},
  ItemCatalog: {},
  Order: {},
  Cart: {},
  Goal: {},
  Specification: {},
  Template: {},
  Meeting: {},
  Room: {},
};

export default function(state = initialState, action) {
  switch(action.type) {
  case Constants.SUBSCRIPTION_GET_SUBSCRIBERS_SUCCESS:
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...action.subscribers
        ]
      }
    };
  case Constants.SUBSCRIPTION_SUBSCRIBE_SUCCESS: {
    const subscriptions = [
      ...state[action.entity][action.entityId]
    ];
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...subscriptions,
          action.subscription
        ]
      }
    };
  }
  case Constants.SUBSCRIPTION_UNSUBSCRIBE_SUCCESS: {
    const subscriptions = [
      ...state[action.entity][action.entityId]
    ];
    const deleteIndex = subscriptions.indexOf(action.subscription);
    subscriptions.splice(deleteIndex, 1);
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...subscriptions,
        ]
      }
    };
  }
  default:
    return state;
  }
}