export const MODULE_HR_SLUG = "hr";
export const MODULE_INVENTORY_SLUG = "inventory";
export const MODULE_ACCOUNTING_SLUG = "accounting";
export const MODULE_PURCHASE_SLUG = "purchase";
export const MODULE_SALE_SLUG = "sale";

export const PAY_ROLL_SLUG = "hr/payroll";
export const LEAVE_SLUG = "hr/leave";
export const APPRAISAL_SLUG = "hr/appraisal";
export const ACCOUNTING_SLUG = "accounting/accounting";
export const EMPLOYEE_SLUG = "hr/employee";
export const RECRUITMENT_SLUG = "hr/recruitment";
export const ITEM_SLUG = "inventory/item";
export const STOCK_SLUG = "inventory/stock";
export const SALE_ORDER_SLUG = "sale/sale-order";
export const CRM_SLUG = "sale/crm-sale";
export const PURCHASE_ORDER_SLUG = "purchase/purchase-order";
export const ATTENDANCE_SLUG = "hr/attendance";
export const SERVICE_SLUG = "sale/service";
export const INVOICE_SLUG = "sale/invoice";
export const CASH_ADVANCE_SLUG = "hr/cash-advanced";