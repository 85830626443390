import * as PaginationConstants from "../../constants/pagination";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
  case PaginationConstants.SET_PAGINATION:
    return setPagination(state, action);
  }
  return state;
}

function setPagination(state, action) {
  const { pagination, requestType } = action;
  const requestObject = {};
  requestObject[requestType] = pagination;
  return Object.assign({}, state, requestObject);
}