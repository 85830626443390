import React from "react";
import { Badge } from "antd";
import * as Constants from "../constants";

export const clearCustomerCommissionSubmitRules = (values) => {
  const { isCheque } = values;
  const rules = {
    paymentMethodId: {
      required: true,
    },
    amount: {
      required: true,
    },
    cheque: {
      type: "object",
      bankId: {
        required: isCheque,
      },
      chequeNumber: {
        required: isCheque,
      },
      issueDate: {
        required: isCheque,
      },
      payTo: {
        required: isCheque,
      },
    }
  };
  return rules;
};

export const renderCustomerCommissionStatus = (value) => {
  let status = null;
  switch(value) {
  case Constants.CUSTOMER_COMMISSION_STATUS_DRAFT_ID:
    status = <Badge status="default" text={Constants.CUSTOMER_COMMISSION_STATUS_DRAFT_NAME} />;
    break;
  case Constants.CUSTOMER_COMMISSION_STATUS_CLEAR_ID:
    status = <Badge status="success" text={Constants.CUSTOMER_COMMISSION_STATUS_CLEAR_NAME} />;
    break;
  case Constants.CUSTOMER_COMMISSION_STATUS_CANCEL_ID:
    status = <Badge status="error" text={Constants.CUSTOMER_COMMISSION_STATUS_CANCEL_NAME} />;
    break;
  default:
    status;
  }
  return status;
};

export const renderCustomerCommissionStatusTitle = (value) => {
  let status = null;
  switch(value) {
  case Constants.CUSTOMER_COMMISSION_STATUS_DRAFT_ID:
    status = Constants.CUSTOMER_COMMISSION_STATUS_DRAFT_NAME;
    break;
  case Constants.CUSTOMER_COMMISSION_STATUS_CLEAR_ID:
    status = Constants.CUSTOMER_COMMISSION_STATUS_CLEAR_NAME;
    break;
  case Constants.CUSTOMER_COMMISSION_STATUS_CANCEL_ID:
    status = Constants.CUSTOMER_COMMISSION_STATUS_CANCEL_NAME;
    break;
  default:
    status;
  }
  return status;
};