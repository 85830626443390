import * as PermissionCodeConstants from "@modules/point-of-sale/pos/constants";
import { READ_SALE_HISTORY } from "@modules/point-of-sale/sale-history/constants";

export default {
  isFixed: true,
  hasDashboard: true,
  slug: "point-of-sale",
  sideBarTitle: "Point of Sale",
  display: true,
  menus: [
    {
      id: 1,
      title: "POS",
      slug: "pos",
      code: PermissionCodeConstants.READ_POS,
      icon: "QrcodeOutlined",
    },
    {
      id: 2,
      title: "Sale History",
      slug: "sale-history",
      code: READ_SALE_HISTORY,
      icon: "HistoryOutlined"
    },
    {
      id: 3,
      title: "Setting",
      slug: "setting",
      code: PermissionCodeConstants.READ_POS_SETTING,
      icon: "SettingOutlined",
    },
  ]
};