import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Dropdown, Menu, Button, Modal, Tooltip } from "antd";
import { 
  EllipsisOutlined, 
  UnorderedListOutlined, 
  ScheduleOutlined,
  PlusCircleOutlined, 
  CheckCircleOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

// Constants
import { ATTENDANCE_REQUEST } from "../constants";

// Components
import FilterBox from "../components/filter-box";
import AsyncCalendar from "../components/calendar";
import ListLayout from "@/components/layout/list-layout";
import AttendanceCheckEntry from "../components/attendance-check-entry";
import EditAttendanceForm from "../components/form-components/edit-attendance";
import CreateAttendanceForm from "../components/form-components/create-attendance";
import MergeAttendanceForm from "../components/merge-attendance";
import AttendanceHistory from "../components/attendance-history";

// Schema
import { AttendanceArraySchema } from "../schema";

// Action 
import { 
  toDate,
  toShortDate, 
  getHourLabel, 
  getQueryParamUrl,
  modalConfirmDialog,
  updateQueryParamUrl,
  toQueryParamDate,
  getQueryParamUrlWithProps,
} from "@modules/helpers";
import { 
  getAll, 
  approveAttendance,
  rejectAttendance,
  deleteAttendance,
  getInvalidAttendance,
  getAttendanceSummary,
} from "../actions";
import { renderAttendanceStatus } from "../helpers";
import * as AttendanceConstants from "../constants";
import { 
  getCurrentUser,
  getEmployeeSettings, 
  getPermissionAllowed,
} from "@modules/utils/value-selector";

class AttendanceList extends Component {

  state = {
    visible: false,
    type: null,
    values: null,
    title: null,
    id: null,
  }

  onVisible = (values, type, title, id) => {
    this.setState({
      visible: true,
      type,
      values,
      title,
      id,
    }, () => {
      localStorage.removeItem("ON_FILTER_ENTER");
    });
  }

  onDismiss = () => {
    this.setState({
      visible: false,
      type: null,
      values: null,
      title: null,
      id: null
    }, () => {
      localStorage.setItem("ON_FILTER_ENTER", "ACTIVE");
    });
  }

  onDelete = (values) => {
    const { id } = values;
    const { deleteAttendance } = this.props;
    modalConfirmDialog(
      "delete this record" , 
      "Once you delete it, it cannot be undone.",
      deleteAttendance,
      id,
    );
  }

  onApprove = (values) => {
    const { id } = values;
    const { approveAttendance } = this.props;
    modalConfirmDialog(
      "approve this record" , 
      "",
      approveAttendance,
      id,
      null,
      "primary",
    );
  } 

  onReject = (values) => {
    const { id } = values;
    const { rejectAttendance } = this.props;
    modalConfirmDialog(
      "reject this record" , 
      "",
      rejectAttendance,
      id,
      null,
      "danger",
    );
  }

  getAttendanceSummary = (props) => {
    const { getAttendanceSummary, currentUser } = this.props;
    let query = getQueryParamUrlWithProps(props);
    let employeeId = query && query.employee && Number(query.employee[0]);
    let values = {};
    if (!employeeId) {
      employeeId = currentUser.employee && currentUser.employee.id;
    }
    values.employeeId = employeeId;
    if (query.from == null && query.to == null) {
      values.from = toQueryParamDate(moment().startOf("month"));
      values.to = toQueryParamDate(moment().endOf("month"));
    } else {
      values.from = query.from;
      values.to = query.to;
    }
    getAttendanceSummary(values);
  }

  componentDidMount = () => {
    this.getAttendanceSummary(this.props);
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (this.props.location != newProps.location) {
      this.getAttendanceSummary(newProps);
    }
  }

  renderMenu = (values) => {
    const menu = [];
    const { hrManager, currentUser, isPermissionAllowed } = this.props;
    const hrManagerId = hrManager && hrManager.id;
    const currentUserId = currentUser.id;
    const { 
      id: attendanceId,
      attendanceStatus, 
      employee: { attendanceApproverUser, id } 
    } = values;
    const employeeId = currentUser.employee && currentUser.employee.id;
    const attendanceApproverUserId = attendanceApproverUser && attendanceApproverUser.id;
    if ((attendanceApproverUserId == currentUserId || hrManagerId == currentUserId || isPermissionAllowed(AttendanceConstants.APPROVE_EMPLOYEE_ATTENDANCE)) && attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_WAITING_APPROVAL) {
      menu.push(
        <Menu.Item key="approve">
          <a onClick={() => this.onApprove(values)}>
            Approve
          </a>
        </Menu.Item>
      );
    }
    if ((attendanceApproverUserId == currentUserId || hrManagerId == currentUserId || isPermissionAllowed(AttendanceConstants.REJECT_EMPLOYEE_ATTENDANCE)) && attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_WAITING_APPROVAL) {
      menu.push(
        <Menu.Item key="reject">
          <a onClick={() => this.onReject(values)}>
            Reject
          </a>
        </Menu.Item>
      );
    }
    if ((employeeId == id || isPermissionAllowed(AttendanceConstants.JOIN_EMPLOYEE_ATTENDANCE)) && attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_INVALID) {
      menu.push(
        <Menu.Item key="merge">
          <a onClick={() => this.onVisible(values, AttendanceConstants.ATTENDANCE_MODAL_MERGE_TYPE, AttendanceConstants.ATTENDANCE_MODAL_MERGE_TITLE)}>
            Merge
          </a>
        </Menu.Item>
      );
    }
    if ((employeeId == id || isPermissionAllowed(AttendanceConstants.UPDATE_EMPLOYEE_ATTENDANCE)) && (attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_INVALID || 
      attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_VALID || 
      attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_WAITING_APPROVAL ||
      attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_REJECT
    )) {
      menu.push(
        <Menu.Item key="edit">
          <a onClick={() => this.onVisible(values, AttendanceConstants.ATTENDANCE_MODAL_EDIT_TYPE, AttendanceConstants.ATTENDANCE_MODAL_EDIT_TITLE)}>
            Edit
          </a>
        </Menu.Item>
      );   
    }
    if ((employeeId == id || isPermissionAllowed(AttendanceConstants.DELETE_EMPLOYEE_ATTENDANCE)) && attendanceStatus != AttendanceConstants.ATTENDANCE_STATUS_PAID) {
      menu.push(
        <Menu.Item key="delete">
          <a onClick={() => this.onDelete(values)}>
            Delete
          </a>
        </Menu.Item>
      );
    }
    {(attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_WAITING_APPROVAL || attendanceStatus == AttendanceConstants.ATTENDANCE_STATUS_APPROVED) &&
      menu.push(
        <Menu.Item key="history">
          <a onClick={() => this.onVisible(values, AttendanceConstants.ATTENDANCE_MODAL_HISTORY_TYPE, AttendanceConstants.ATTENDANCE_MODAL_HISTORY_TITLE, attendanceId)}>
            History
          </a>
        </Menu.Item>
      );
    }
    return menu;
  }

  column = () => {
    const { isCurrentUserPage } = this.props;
    let columns = [
      {
        title: "Date",
        dataIndex: "attandanceDate",
        sortable: true,
        width: isCurrentUserPage ? "15%" : "10%",
        render: (attandanceDate) => toShortDate(attandanceDate)
      },
      {
        title: "Time In",
        dataIndex: "timeIn",
        sortable: true,
        width: "10%",
        render: (timeIn) => {
          if (timeIn) {
            return timeIn.formattedValue;
          }
        }
      },
      {
        title: "Time Out",
        dataIndex: "timeOut",
        sortable: true,
        width: "10%",
        render: (timeOut) => {
          if (timeOut) {
            return timeOut.formattedValue;
          }
        }
      },
      {
        title: "Duration",
        dataIndex: "hours",
        sortable: true,
        width: "10%",
        render: (text, row) => {
          const { timeIn, timeOut, hours } = row;
          const duration = timeIn && timeOut ? hours : "0.00";
          return getHourLabel(duration);
        }
      },
      {
        title: "Status",
        width: "15%",
        render: (text, row) => renderAttendanceStatus(row)
      },
      {
        title: "Notation",
        dataIndex: "notation",
        width: "15%",
        render: (notation) => {
          return (
            <div className="text-success">
              {notation}
            </div>
          );
        }
      },
      {
        title: "Action",
        sortable: false,
        width: "5%",
        render: (text, row) => {
          let menu = this.renderMenu(row);
          menu = _.uniqBy(menu, "key");
          return (
            <>
              <Dropdown 
                trigger={["click"]}
                disabled={_.isEmpty(menu)}
                overlay={<Menu>{menu}</Menu>}
              >
                <Button 
                  ghost
                  size="small" 
                  type="primary" 
                  icon={<EllipsisOutlined />}
                />
              </Dropdown>
              <Tooltip 
                placement="rightTop" 
                title={
                  <>
                    <div>
                      <small className="text-white">
                        Created At : {toDate(row.createdAt)}
                      </small>
                    </div>
                    <div>
                      <small className="text-white">
                        Updated At : {toDate(row.updatedAt)}
                      </small>
                    </div>
                  </>
                }
              >
                <Button 
                  ghost
                  size="small" 
                  type="primary"
                  className="ml-1" 
                  icon={<HistoryOutlined />}
                />
              </Tooltip>
            </>
          );
        }
      }
    ];
    if (!isCurrentUserPage) {
      let employee = [
        {
          title: "Employee",
          dataIndex: "employee",
          sortable: true,
          width: "25%",
          render: (text, row) => {
            const employee = row.employee;
            if(employee) {
              return (
                <Link to={`../employee-directory/employee/${employee.id}`}>
                  {employee.firstName} {employee.lastName}
                </Link>
              ); 
            }
          }
        },

      ];
      columns = [
        ...employee,
        ...columns,
      ];
    }
    return columns;
  }

  renderFilterComponent = (props) => {
    const { viewOtherEmployee, currentUser, isCurrentUserPage } = this.props;
    return (
      <FilterBox
        currentUser={currentUser} 
        isCurrentUserPage={isCurrentUserPage}
        viewOtherEmployee={viewOtherEmployee}
        {...props}
      />
    );
  }

  onSwitchClick = () => {
    let query = getQueryParamUrl();
    if (query.layout == "calendar") {
      delete query.layout;
    } else {
      query.layout = "calendar";
    }
    updateQueryParamUrl(query);
  }

  renderExtraFilterComponent = () => {
    let query = getQueryParamUrl();
    return (
      <Button 
        ghost
        size="small"
        type="primary"
        className="float-right"
        onClick={this.onSwitchClick} 
        icon={query.layout == "calendar" ? <UnorderedListOutlined /> : <ScheduleOutlined />} 
      />
    );
  }

  renderCheckEntryComponent = (props) => {
    const { isCurrentUserPage } = this.props;
    return (
      <AttendanceCheckEntry 
        isCurrentUserPage={isCurrentUserPage} 
        onVisibleAddClick={() => this.onVisible(null, AttendanceConstants.ATTENDANCE_MODAL_CREATE_TYPE, AttendanceConstants.ATTENDANCE_MODAL_CREATE_TITLE)}
        {...props}
      />
    );
  }

  render() {
    let query = getQueryParamUrl();
    const { visible, type, values, title, id } = this.state;
    const { 
      permission, 
      currentUser,
      viewOtherEmployee, 
      getInvalidAttendance, 
      isPermissionAllowed,
      isCurrentUserPage,
      extraFilter,
    } = this.props;
    let filterKeys = [
      "from", 
      "to", 
      "status",
      "id",
    ];
    if (viewOtherEmployee) {
      filterKeys = filterKeys.concat(["includeAll", "terminated"]);
    }
    if (!isCurrentUserPage) {
      filterKeys = filterKeys.concat(["employee"]);
    }
    const extraButtons = [];
    extraButtons.push(
      <Button 
        ghost
        type="primary" 
        icon={<PlusCircleOutlined />}
        onClick={() => this.onVisible(null, AttendanceConstants.ATTENDANCE_MODAL_CREATE_TYPE, AttendanceConstants.ATTENDANCE_MODAL_CREATE_TITLE)}
      >
        Create
      </Button>
    );
    if (isPermissionAllowed(AttendanceConstants.APPROVE_EMPLOYEE_ATTENDANCE)) {
      extraButtons.push(
        <Link to="/human-resource/attendance/view/approve-all">
          <Button 
            ghost
            type="primary" 
            className="ml-1"
            icon={<CheckCircleOutlined />}
          >
            Approve All
          </Button>
        </Link>
      );
    }
    const hasSwitch = query && query.layout == "calendar";
    return (
      <Fragment>
        <ListLayout 
          action={getAll}
          creatable={false}
          showHeader={!isCurrentUserPage}
          hasSwitch={hasSwitch}
          tableSize="small"
          title="Attendance View"
          reducerName="attendance"
          filterKeys={filterKeys}
          permission={permission}
          extraButtons={extraButtons}
          showPagination={!hasSwitch}
          schema={AttendanceArraySchema}
          columns={this.column()}
          switchComponent={AsyncCalendar}
          requestName={ATTENDANCE_REQUEST}
          isPermissionAllowed={isCurrentUserPage}
          filterComponent={this.renderFilterComponent}
          extraFilterComponent={this.renderExtraFilterComponent}
          extraEntitySummaryComponent={this.renderCheckEntryComponent}
          extraFilter={extraFilter}
          extraEntitySummaryClassName={isCurrentUserPage ? "border rounded mb-2" : undefined}
        />
        <Modal
          title={title}
          visible={visible}
          footer={null}
          width={type == AttendanceConstants.ATTENDANCE_MODAL_HISTORY_TYPE ? "1200px": "700px"}
          maskClosable={false}
          keyboard={false}
          onCancel={this.onDismiss}
          destroyOnClose={true}
        >
          {visible && type == AttendanceConstants.ATTENDANCE_MODAL_EDIT_TYPE && (
            <EditAttendanceForm 
              values={values} 
              onDismiss={this.onDismiss}
              form={AttendanceConstants.EDIT_ATTENDANCE_FORM_NAME}
            />
          )}
          {visible && type == AttendanceConstants.ATTENDANCE_MODAL_MERGE_TYPE && (
            <MergeAttendanceForm 
              data={values} 
              onDismiss={this.onDismiss}
              getInvalidAttendance={getInvalidAttendance}
              form={AttendanceConstants.MERGE_ATTENDANCE_FORM_NAME}
            />
          )}
          {visible && type == AttendanceConstants.ATTENDANCE_MODAL_CREATE_TYPE && (
            <CreateAttendanceForm 
              onDismiss={this.onDismiss}
              userId={currentUser && currentUser.id}
              isCurrentUserPage={isCurrentUserPage}
              form={AttendanceConstants.CREATE_ATTENDANCE_FORM_NAME}
              isAllowToCrateAttendanceForOther={isPermissionAllowed(AttendanceConstants.CREATE_EMPLOYEE_ATTENDANCE_FOR_OTHER_USER)}
            />
          )} 
          {visible && type == AttendanceConstants.ATTENDANCE_MODAL_HISTORY_TYPE && (
            <AttendanceHistory 
              id={id}
            />
          )}
        </Modal>
      </Fragment>
    );
  }
}

AttendanceList.propTypes = {
  match: PropTypes.object,
  location: PropTypes.any,
  permission: PropTypes.object,
  currentUser: PropTypes.object,
  hrManager: PropTypes.object,
  approveAttendance: PropTypes.func,
  rejectAttendance: PropTypes.func,
  deleteAttendance: PropTypes.func,
  viewOtherEmployee: PropTypes.bool,
  permissionAllowed: PropTypes.func,
  getInvalidAttendance: PropTypes.func,
  getAttendanceSummary: PropTypes.func,
  isPermissionAllowed: PropTypes.func,
  extraFilter: PropTypes.func,
  isCurrentUserPage: PropTypes.bool,
  
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state).user,
    hrManager: getEmployeeSettings(state).hrManagerId,
    viewOtherEmployee: getPermissionAllowed(state, AttendanceConstants.VIEW_ATTENDANCE_SUMMARIZE_OTHER_USER),
    isPermissionAllowed: (code) => getPermissionAllowed(state, code)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAttendance: (id) => dispatch(deleteAttendance(id)),
    approveAttendance: (id) => dispatch(approveAttendance(id)),
    rejectAttendance: (id) => dispatch(rejectAttendance(id)),
    getAttendanceSummary: (values) => dispatch(getAttendanceSummary(values)),
    getInvalidAttendance: (values) => dispatch(getInvalidAttendance(values)), 
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AttendanceList));