import React from "react";
import socket from "socket.io-client";
import PropTypes from "prop-types";
import { getAccessToken } from "@/shared/utils";

export const SocketIoContext = React.createContext();

export const SocketIOProvider = (props) => {
  const [io, setIO] = React.useState();
  React.useEffect(() => {
    const subDomain = window.location.host.split(".")[0];
    const accessToken = getAccessToken();
    const io = socket("wss://socket.iserp.cloud", {
      query: {
        token: accessToken.accessToken,
        userId: accessToken.userId,
        subDomain
      }
    });
    setIO(io);
  }, []);

  return (
    <SocketIoContext.Provider value={{ socket: io }}>
      {io && props.children}
    </SocketIoContext.Provider>
  );
};

export const useSocketIO = () => React.useContext(SocketIoContext);

SocketIOProvider.propTypes = {
  children: PropTypes.any
};