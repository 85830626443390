import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getChartOfAccountTypeAutoComplete } from "@modules/accounting/list/chart-of-account/actions";

// Constant 
import { CHART_OF_ACCOUNT_TYPE_AUTOCOMPLETE_REQUEST } from "@modules/accounting/list/chart-of-account/constants";

class ChartOfAccountTypeAutoComplete extends Component {

  onChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }
  
  render() {
    const { 
      label, 
      name, 
      editing,
      required,
      existingData,
      action,
      requestName,
      disabled,
      onSelectChange,
      selectedValue,
      mode,
      ids,
    } = this.props; 
    return (
      <AutoComplete
        name={name}
        label={label}
        required={required}
        editing={editing}
        action={action}
        requestName={requestName}
        onChange={this.onChange}
        onSelectChange={onSelectChange}
        existingData={existingData}
        disabled={disabled}
        selectedValue={selectedValue}
        mode={mode}
        extra={{
          ids
        }}
      />
    );
  }
}

ChartOfAccountTypeAutoComplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  editing: PropTypes.bool,
  required: PropTypes.bool,
  existingData: PropTypes.array,
  onChange: PropTypes.func,
  action: PropTypes.func,
  requestName: PropTypes.any,
  disabled: PropTypes.bool,
  onSelectChange: PropTypes.func,
  selectedValue: PropTypes.array,
  mode: PropTypes.string,
  ids: PropTypes.array,
};

ChartOfAccountTypeAutoComplete.defaultProps = {
  label: "Chart of Account Type",
  name: "chartAccountTypeId",
  required: true,
  editing: false,
  existingData: [],
  action: getChartOfAccountTypeAutoComplete,
  requestName: CHART_OF_ACCOUNT_TYPE_AUTOCOMPLETE_REQUEST,
  disabled: false,
  selectedValue: [],
};

export default ChartOfAccountTypeAutoComplete;