import React from "react";
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { Row, Col } from "reactstrap";
import { reduxForm, change } from "redux-form";
import { Button, Checkbox, Select, Form as AntForm, Table, Form } from "antd";
import { RetweetOutlined, CheckSquareOutlined } from "@ant-design/icons";

// Utils
import { 
  toQueryParamDate, 
  toShortDate,
} from "@modules/helpers";
import { AttendanceArraySchema } from "../../schema";
import { 
  ATTENDANCE_INVALID_REQUEST,
  ATTENDANCE_TYPE_DATA_SOURCE,
  ATTENDANCE_CHECK_IN_TYPE,
  ATTENDANCE_MERGE_REQUEST,
} from "../../constants";
import { mergeAttendance } from "../../actions";
import { DEFAULT_DISPLAY_TIME_FORMAT } from "@/constants/default-constants";

const Option = Select.Option;

class MergeAttendanceForm extends React.Component {

  state = {
    selectedValues: [],
    firstRecord: null,
    secondRecord: null,
    error: {
      status: null,
      message: null,
    }
  }

  onSubmit = (values) => {
    const { 
      mergeAttendance, 
      onDismiss,
    } = this.props;
    const { 
      checkInAttendance: { timeIn }, 
      checkOutAttendance: { timeOut },
    } = values;
    const momentIn = moment(timeIn, DEFAULT_DISPLAY_TIME_FORMAT);
    const momentOut = moment(timeOut, DEFAULT_DISPLAY_TIME_FORMAT);
    const isValid = momentIn.isAfter(momentOut);
    const isSame = momentIn.isSame(momentOut);
    if (isValid || isSame) {
      this.onError("invalid time");
    } else {
      this.onCancelError();
      if (mergeAttendance) {
        mergeAttendance(values).then((response) => {
          if (response?.error == null) {
            onDismiss();
          }
        });
      }
    }
  }

  componentDidMount() {
    const { 
      getInvalidAttendance, 
      data: { 
        employee: { id }, 
        attandanceDate
      } 
    } = this.props;
    const query = {
      filter: {
        employee: id,
        attandanceDate: toQueryParamDate(attandanceDate)
      },
    };
    if (getInvalidAttendance) {
      getInvalidAttendance(query);
    }
  }

  onCheck = (e, index) => {
    const { target: { checked } } = e;
    if (checked) {
      this.setState({
        selectedValues: [
          ...this.state.selectedValues, 
          index
        ]
      });
    } else {
      this.setState({
        selectedValues: this.state.selectedValues.filter((val) => val != index)
      }, () => {
        const { selectedValues } = this.state;
        if (selectedValues.length == 0) {
          this.onCancelError();
        }
      });
    }
  }

  renderDisabledCheckBox = (id) => {
    const { selectedValues } = this.state;
    let disabled = false;
    if (selectedValues.length == 2 && !_.includes(selectedValues, id)) {
      disabled = true;
    }
    return disabled;
  }

  handleCheckValidateType = () => {
    const { firstRecord, secondRecord } = this.state;
    if (firstRecord == secondRecord) {
      this.onError("type can not be the same");
    } else {
      this.onCancelError();
    }
  };

  onError = (message) => {
    this.setState({
      error: {
        message: message,
        status: "error"
      }
    });
  }

  onCancelError = () => {
    this.setState({
      error: {
        status: null,
        message: null,
      }
    });
  }

  handleChangeType = (type, row, index, time) => {
    const { updateField } = this.props;
    const { selectedValues } = this.state;
    const findIndex = _.findIndex(selectedValues, (val) => val == index);
    if (findIndex == 0) {
      this.setState({
        firstRecord: type,
      }, () => {
        this.handleCheckValidateType();
      });
    } else {
      this.setState({
        secondRecord: type,
      }, () => {
        this.handleCheckValidateType();
      });
    }
    if (type == ATTENDANCE_CHECK_IN_TYPE) {
      const checkInObject = {
        id: row.id,
        timeIn: row[time].formattedValue,
      };
      updateField("checkInAttendance", checkInObject);
    } else {
      const checkOutObject = {
        id: row.id,
        timeOut: row[time].formattedValue,
      };
      updateField("checkOutAttendance", checkOutObject);
    }
  }

  renderDisableButtonMerge = () => {
    const { firstRecord, secondRecord } = this.state;
    let disabled = true;
    if (firstRecord != null && secondRecord != null) {
      if (firstRecord != secondRecord) {
        disabled = false;
      }
    }
    return disabled;
  }

  renderColumns = () => {
    const { selectedValues } = this.state;
    return [
      {
        title: <CheckSquareOutlined />,
        dataIndex: "name",
        width: "10%",
        render: (text, row, index) => {
          return (
            <Checkbox 
              onChange={(e) => this.onCheck(e, index)}
              disabled={this.renderDisabledCheckBox(index)}
            />
          );
        }
      },
      {
        title: "Date",
        width: "20%",
        dataIndex: "attandanceDate",
        render: (attandanceDate) => toShortDate(attandanceDate)
      },
      {
        title: "Time In",
        width: "20%",
        dataIndex: "timeIn",
        render: (timeIn) => {
          if (timeIn) {
            return timeIn.formattedValue;
          }
        }
      },
      {
        title: "Time Out",
        width: "20%",
        dataIndex: "timeOut",
        render: (timeOut) => {
          if (timeOut) {
            return timeOut.formattedValue;
          }
        }
      },
      {
        title: "Type",
        width: "30%",
        render: (text, row, index) => {
          const { error } = this.state;
          let time = null;
          if (!_.isEmpty(row.timeIn)) {
            time = "timeIn";
          } else 
          if (!_.isEmpty(row.timeOut)) {
            time = "timeOut";
          }
          const className = classnames(!error.status && "mb-0");
          if(_.includes(selectedValues, index)) {
            return (
              <AntForm.Item
                help={error.message}
                className={className}
                validateStatus={error.status}
              >
                <Select
                  style={{ width: 110 }}
                  onChange={(type) => this.handleChangeType(type, row, index, time)}
                >
                  {ATTENDANCE_TYPE_DATA_SOURCE.map((val, index) => {
                    return (
                      <Option key={index} value={val.id}>
                        {val.name}
                      </Option>
                    );
                  })}
                </Select>
              </AntForm.Item>
            );
          }
        }
      }
    ];
  }

  render() {
    const { 
      handleSubmit,
      loading,
      list,
      entities, 
      mergeLoading,
    } = this.props;
    const dataSource = denormalize(list, AttendanceArraySchema, entities);
    return (
      <Form onFinish={handleSubmit(this.onSubmit)} layout="vertical">
        <Row>
          <Col xs="12">
            <Table 
              size="small"
              className="mb-2"
              loading={loading}
              pagination={false}
              dataSource={dataSource}
              columns={this.renderColumns()}
            />
          </Col>
          <Col xs="12">
            <Button 
              ghost
              type="primary"
              htmlType="submit"
              loading={mergeLoading}
              className="float-right" 
              icon={<RetweetOutlined />}
              disabled={this.renderDisableButtonMerge()}
            >
              Merge
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

MergeAttendanceForm.propTypes = {
  list: PropTypes.array,
  data: PropTypes.object,
  loading: PropTypes.bool,
  onDismiss: PropTypes.func,
  entities: PropTypes.object,
  updateField: PropTypes.func,
  handleSubmit: PropTypes.func,
  mergeLoading: PropTypes.bool,
  mergeAttendance: PropTypes.func,
  getInvalidAttendance: PropTypes.func,
};

const mapStateToProp = (state) => {
  const { request, attendance, entities } = state;
  return {
    entities,
    list: attendance.invalidList,
    loading: request[ATTENDANCE_INVALID_REQUEST],
    mergeLoading: request[ATTENDANCE_MERGE_REQUEST],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { form } = ownProps;
  return {
    mergeAttendance: (values) => dispatch(mergeAttendance(values)),
    updateField: (field, values) => dispatch(change(form, field, values)),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(reduxForm()(MergeAttendanceForm));