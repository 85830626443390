// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import FilterComponent from "@cores/components/filter-box";
import AsyncStatusAutoComplete from "@cores/components/async-auto-complete/async-status-auto-complete";
import AsyncDateRangeOption from "@cores/components/async-date-range-options";
import AsyncCustomerCompany from "@cores/components/async-auto-complete/customer-company";
import AsyncEmployee from "@cores/components/async-auto-complete/employee";
import AsyncCustomerAutoComplete from "@cores/components/async-auto-complete/customer";

// Constant
import { CUSTOMER_COMMISSION_STATUS_DATASOURCE } from "../../constants";

class FilterBox extends Component {

  render() {
    const { withEmployee, ...props } = this.props;
    return (
      <FilterComponent {...props}>
        <AsyncDateRangeOption />
        <AsyncCustomerAutoComplete />
        <AsyncCustomerCompany />
        {withEmployee && (
          <AsyncEmployee />
        )}
        <AsyncStatusAutoComplete
          name="status"
          placeholder="Status"
          dataSource={CUSTOMER_COMMISSION_STATUS_DATASOURCE}
        />
      </FilterComponent>
    );
  }
}

FilterBox.propTypes = {
  withEmployee: PropTypes.bool,
};

export default FilterBox;