export const PAYMENT_METHOD_LIST_REQUEST = "PAYMENT_METHOD_LIST_REQUEST";
export const PAYMENT_METHOD_LIST_SUCCESS = "PAYMENT_METHOD_LIST_REQUEST_SUCCESS";
export const PAYMENT_METHOD_AUTOCOMPLETE_REQUEST = "PAYMENT_METHOD_AUTOCOMPLETE_REQUEST";
export const PAYMENT_METHOD_AUTOCOMPLETE_SUCCESS = "PAYMENT_METHOD_AUTOCOMPLETE_SUCCESS";
export const PAYMENT_METHOD_CREATE_REQUEST = "PAYMENT_METHOD_CREATE_REQUEST";
export const PAYMENT_METHOD_DETAIL_REQUEST = "PAYMENT_METHOD_DETAIL_REQUEST";
export const PAYMENT_METHOD_ARCHIVE_REQUEST = "PAYMENT_METHOD_ARCHIVE_REQUEST";
export const PAYMENT_METHOD_DELETE_REQUEST = "PAYMENT_METHOD_DELETE_REQUEST";
export const PAYMENT_METHOD_UNARCHIVE_REQUEST = "PAYMENT_METHOD_UNARCHIVE_REQUEST";
export const PAYMENT_METHOD_EDIT_REQUEST = "PAYMENT_METHOD_EDIT_REQUEST";
export const PAYMENT_METHOD_CREATE_FORM_NAME = "createPaymentMethod";
export const PAYMENT_METHOD_EDIT_FORM_NAME = "editPaymentMethod";
export const READ_PAYMENT_METHOD = 187000;
export const CREATE_PAYMENT_METHOD = 187001;
export const UPDATE_PAYMENT_METHOD = 187002;
export const DELETE_PAYMENT_METHOD = 187003;
export const ARCHIVE_PAYMENT_METHOD = 187004;
export const UNARCHIVE_PAYMENT_METHOD = 187005;

export const PAYMENT_GATEWAY_TYPE = [
  {
    id:1,
    name:"ABA",
  },
  {
    id:2,
    name:"Wing",
  },
];

export const PAYMENT_OPTION = [
  {
    id: "cards",
    name: "cards",
  },
  {
    id: "abapay",
    name: "abapay",
  },
  {
    id: "abapay_deeplink",
    name: "abapay_deeplink",
  },
  {
    id: "wechat",
    name: "wechat",
  },
  {
    id: "alipay",
    name: "alipay",
  },
  {
    id: "abapay_khqr",
    name: "abapay_khqr",
  },
  {
    id: "abapay_khqr_deeplink",
    name: "abapay_khqr_deeplink",
  }
];