import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";

// Action 
import { getLeaveTypeAutoComplete } from "@modules/hr/leave/leave-type/actions";

// Constant 
import { LEAVE_TYPE_AUTOCOMPLETE_REQUEST } from "@modules/hr/leave/leave-type/constants";

class AsyncLeaveTypeAutoComplete extends React.Component {

  render() {
    const { name, requestName, action, placeholder, ...props } = this.props;
    return (
      <AsyncSelect 
        name={name}
        action={action}
        requestName={requestName}
        placeholder={placeholder}
        {...props}
      />
    );
  }
}

AsyncLeaveTypeAutoComplete.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  requestName: PropTypes.string,
  placeholder: PropTypes.string,
};

AsyncLeaveTypeAutoComplete.defaultProps = {
  name: "leaveType",
  placeholder: "Leave Type",
  action: getLeaveTypeAutoComplete,
  requestName: LEAVE_TYPE_AUTOCOMPLETE_REQUEST,
};

export default withRouter(AsyncLeaveTypeAutoComplete);