import { GETList, PUTWithToken } from "@/services/base-service";

const API_URL = "/api/login-approval/v1";

export function getLoginApproval(values) {
  const url = `${API_URL}/list`;
  return GETList(url, values);
}

export function approveLoginApproval(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectLoginApproval(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}