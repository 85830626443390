import { END_OF_DAY_SUMMARY_SUCCESS } from "../constants";

export default function (state = {}, action) {
  switch (action.type) {
  case END_OF_DAY_SUMMARY_SUCCESS:
    return {
      ...state,
      ...action.payload
    };
  default:
    return state;
  }
}