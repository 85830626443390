
// Item
import { READ_ITEM } from "@modules/inventory/item/item/constants";
import { READ_CONTACT } from "@modules/website/support/contact/constants";
import { READ_ORDER } from "@modules/website/e-commerce/order/constants";
import { READ_SYNCHRONIZE } from "src/modules/website/store-front/synchronize/constants";

export default {
  sideBarTitle: "Website",
  hasDashboard: true,
  isFixed: false,
  slug: "website",
  display: true,
  menus: [
    {
      id: 1,
      title: "E-Commerce",
      slug: "e-commerce",
      icon: "ShoppingCartOutlined",
      sub: [
        {
          id: 1,
          title: "Cart",
          slug: "cart",
          code: READ_ORDER,
        },
        {
          id: 2,
          title: "Order",
          slug: "order",
          code: READ_ORDER,
        },
      ]
    },
    {
      id: 2,
      title: "Store Font",
      slug: "store-front",
      icon: "PictureOutlined",
      sub: [
        {
          id: 1,
          title: "Item Catalog",
          slug: "item-catalog",
          code: READ_ITEM,
        },
        {
          id: 2,
          title: "Banner List",
          slug: "banner-list",
          code: READ_ITEM,
        },
        {
          id: 3,
          title: "Synchronization",
          slug: "synchronization",
          code: READ_SYNCHRONIZE,
        },
      ]
    },
    {
      id: 3,
      title: "Support",
      slug: "support",
      icon: "ContactsOutlined",
      sub: [
        {
          id: 1,
          title: "Contact",
          slug: "contact",
          code: READ_CONTACT,
        },
      ]
    },
  ]
};