import React from "react";
import * as PropTypes from "prop-types";
import { Select, Form, Row, Col, DatePicker, Button } from "antd";
import * as moment from "moment";

// Constants
import * as Constants from "../../constants";
import { DEFAULT_ASYNC_DATE_FORMAT } from "@/constants/default-constants";
import { getSaleIncentiveWithFilter } from "@sale/sale-center/sale-invoice/services";

class IncentiveCalculator extends React.Component {

  constructor(props) {
    super(props);
    const { month, year, computations, employeeId } = props;
    const date = moment(`01-${month}-${year}`, DEFAULT_ASYNC_DATE_FORMAT);
    const from = moment(date).startOf("month");
    const to = moment(date).endOf("month");
    this.state = {
      valueSource: employeeId ? Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE : null,
      filterBy: Constants.PAYSLIP_SALE_INCENTIVE_FILTER_BY_SOLD_DATE,
      from,
      to,
      calculateLoading: false,
      computationId: computations && computations.length > 0 && computations[0].id,
    };
  }

  onComputationChanged = (computationId) => {
    this.setState({
      computationId
    });
  }

  onValueSourceChanged = (valueSource) => {
    this.setState({
      valueSource
    });
  }

  onFilterByChanged = (filterBy) => {
    this.setState({
      filterBy
    });
  }

  onDateRangeChange = (values) => {
    this.setState({
      from: values[0],
      to: values[1]
    });
  }

  onCalculateClick = () => {
    const { from, to, filterBy, valueSource, computationId } = this.state;
    const { employeeId, updateField, fieldName, index, computations } = this.props;
    const matchedComputation = computations.find(computation => computation.id == computationId);
    if (valueSource == Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE) {
      this.setState({
        calculateLoading: true
      }, () => {
        getSaleIncentiveWithFilter({
          from: from.format(DEFAULT_ASYNC_DATE_FORMAT),
          to: to.format(DEFAULT_ASYNC_DATE_FORMAT),
          type: filterBy,
          employee: [employeeId]
        }).then(incentives => {
          this.setState({
            calculateLoading: false
          });
          if (incentives.length > 0) {
            updateField(`${fieldName}[${index}].value`, incentives[0].value);
          }
        });
      });
    } else {
      updateField(`${fieldName}[${index}].value`, matchedComputation.total);
    }
  }

  render() {
    const {
      from,
      to,
      filterBy,
      valueSource,
      computationId,
      calculateLoading,
    } = this.state;
    const { computations, employeeId } = this.props;
    const matchedFilterBy = Constants.PAYSLIP_SALE_INCENTIVE_FILTER_BY.find(filter => filter.id == filterBy);
    return (
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Value Source">
              <Select
                defaultValue={null}
                className="w-100"
                value={valueSource}
                placeholder="Value Source"
                onChange={this.onValueSourceChanged}
              >
                <Select.Option value={Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_EARNING_TYPE}>
                  {Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_EARNING_TYPE_TITLE}
                </Select.Option>
                {employeeId && (
                  <Select.Option value={Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE}>
                    {Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE_TITLE}
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          {valueSource == Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_EARNING_TYPE && (
            <Col span={12}>
              <Form.Item label="Earning Type">
                <Select
                  className="w-100"
                  value={computationId}
                  placeholder="Earning Type"
                  onChange={this.onComputationChanged}
                >
                  {computations && computations.map((computation) => {
                    return (
                      <Select.Option key={computation.id} value={computation.id}>
                        {computation.earningType.name}
                      </Select.Option>
                    );
                  }
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}
          {valueSource == Constants.PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE && (
            <React.Fragment>
              <Col span={8}>
                <Form.Item label="Filter By">
                  <Select
                    className="w-100"
                    placeholder="Filter By"
                    value={filterBy}
                    onChange={this.onFilterByChanged}
                  >
                    {Constants.PAYSLIP_SALE_INCENTIVE_FILTER_BY.map((filterBy) => {
                      return (
                        <Select.Option key={filterBy.id} value={filterBy.id}>
                          {filterBy.name}
                        </Select.Option>
                      );
                    }
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {matchedFilterBy && (
                <Col span={24}>
                  <Form.Item label={matchedFilterBy.name}>
                    <DatePicker.RangePicker
                      format={DEFAULT_ASYNC_DATE_FORMAT}
                      value={[from, to]}
                      onChange={this.onDateRangeChange}
                    />
                  </Form.Item>
                </Col>
              )}
            </React.Fragment>
          )}
          <Col span={24}>
            <Form.Item>
              <Button
                ghost
                type="primary"
                onClick={this.onCalculateClick}
                loading={calculateLoading}
              >
                Calculate
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

}

IncentiveCalculator.propTypes = {
  month: PropTypes.number,
  year: PropTypes.number,
  employeeId: PropTypes.number,
  getSaleIncentive: PropTypes.func,
  fieldName: PropTypes.string,
  updateField: PropTypes.func,
  index: PropTypes.number,
  computations: PropTypes.array,
};

export default IncentiveCalculator;