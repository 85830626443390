export const LEAVE_ALLOCATION_LIST_REQUEST = "LEAVE_ALLOCATION_LIST_REQUEST"; 
export const LEAVE_ALLOCATION_LIST_SUCCESS = "LEAVE_ALLOCATION_LIST_REQUEST_SUCCESS";
export const LEAVE_ALLOCATION_CREATE_REQUEST = "LEAVE_ALLOCATION_CREATE_REQUEST"; 
export const LEAVE_ALLOCATION_DETAIL_REQUEST = "LEAVE_ALLOCATION_DETAIL_REQUEST"; 
export const LEAVE_ALLOCATION_ARCHIVE_REQUEST = "LEAVE_ALLOCATION_ARCHIVE_REQUEST"; 
export const LEAVE_ALLOCATION_DELETE_REQUEST = "LEAVE_ALLOCATION_DELETE_REQUEST"; 
export const LEAVE_ALLOCATION_UNARCHIVE_REQUEST = "LEAVE_ALLOCATION_UNARCHIVE_REQUEST"; 
export const LEAVE_ALLOCATION_EDIT_REQUEST = "LEAVE_ALLOCATION_EDIT_REQUEST"; 
export const LEAVE_ALLOCATION_CREATE_FORM_NAME = "createLeaveAllocation"; 
export const LEAVE_ALLOCATION_EDIT_FORM_NAME = "editLeaveAllocation"; 
export const LEAVE_ALLOCATION_AUTOCOMPLETE_REQUEST = "LEAVE_ALLOCATION_AUTOCOMPLETE_REQUEST";
export const LEAVE_ALLOCATION_AUTOCOMPLETE_SUCCESS = "LEAVE_ALLOCATION_AUTOCOMPLETE_SUCCESS";
export const LEAVE_ALLOCATION_STATUS_TO_OPEN_REQUEST = "LEAVE_ALLOCATION_STATUS_TO_OPEN_REQUEST";
export const LEAVE_ALLOCATION_STATUS_TO_CLOSE_REQUEST = "LEAVE_ALLOCATION_STATUS_TO_CLOSE_REQUEST";

export const LEAVE_ALLOCATION_STATUS_DRAFT = 1;
export const LEAVE_ALLOCATION_STATUS_DRAFT_TITLE = "Draft";
export const LEAVE_ALLOCATION_STATUS_SUBMITTED = 2;
export const LEAVE_ALLOCATION_STATUS_SUBMITTED_TITLE = "Submitted";
export const LEAVE_ALLOCATION_STATUS_CANCELLED = 3;
export const LEAVE_ALLOCATION_STATUS_CANCELLED_TITLE = "Cancelled";
export const LEAVE_ALLOCATION_STATUS_APPROVED = 4;
export const LEAVE_ALLOCATION_STATUS_APPROVED_TITLE = "Approved";
export const LEAVE_ALLOCATION_STATUS_REJECT = 5;
export const LEAVE_ALLOCATION_STATUS_REJECT_TITLE = "Rejected";

export const LEAVE_ALLOCATION_APPROVE_REQUEST = "LEAVE_ALLOCATION_APPROVE_REQUEST";
export const LEAVE_ALLOCATION_REJECT_REQUEST = "LEAVE_ALLOCATION_REJECT_REQUEST";
export const LEAVE_ALLOCATION_SUBMIT_REQUEST = "LEAVE_ALLOCATION_SUBMIT_REQUEST";
export const LEAVE_ALLOCATION_CANCEL_REQUEST = "LEAVE_ALLOCATION_CANCEL_REQUEST";

export const LEAVE_ALLOCATION_SUBMIT_RULES = {
  duration: {
    required: true,
  },
  employeeId: {
    required: true,
  },
  leaveTypeId: {
    required: true,
  },
};

export const LEAVE_ALLOCATION_STATUS = [
  {
    id: LEAVE_ALLOCATION_STATUS_DRAFT,
    name: LEAVE_ALLOCATION_STATUS_DRAFT_TITLE
  },
  {
    id: LEAVE_ALLOCATION_STATUS_SUBMITTED,
    name: LEAVE_ALLOCATION_STATUS_SUBMITTED_TITLE,
  },
  {
    id: LEAVE_ALLOCATION_STATUS_CANCELLED,
    name: LEAVE_ALLOCATION_STATUS_CANCELLED_TITLE,
  },
  {
    id: LEAVE_ALLOCATION_STATUS_APPROVED,
    name: LEAVE_ALLOCATION_STATUS_APPROVED_TITLE,
  },
  {
    id: LEAVE_ALLOCATION_STATUS_REJECT,
    name: LEAVE_ALLOCATION_STATUS_REJECT_TITLE,
  }
];

export const READ_LEAVE_ALLOCATION = 153000;
export const CREATE_LEAVE_ALLOCATION = 153001;
export const UPDATE_LEAVE_ALLOCATION = 153002;
export const DELETE_LEAVE_ALLOCATION = 153003;
export const ARCHIVE_LEAVE_ALLOCATION = 153004;
export const UNARCHIVE_LEAVE_ALLOCATION = 153005;
export const PRINT_LEAVE_ALLOCATION = 153007;
export const SUBMIT_LEAVE_ALLOCATION = 153011;
export const APPROVE_LEAVE_ALLOCATION = 153012;
export const REJECT_LEAVE_ALLOCATION = 153013;
export const CANCEL_LEAVE_ALLOCATION = 153014;