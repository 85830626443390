export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_REQUEST_SUCCESS";
export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST";
export const ORDER_ARCHIVE_REQUEST = "ORDER_ARCHIVE_REQUEST";
export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_UNARCHIVE_REQUEST = "ORDER_UNARCHIVE_REQUEST";
export const ORDER_EDIT_REQUEST = "ORDER_EDIT_REQUEST";
export const ORDER_SUBMIT_REQUEST = "ORDER_SUBMIT_REQUEST";
export const ORDER_PROCESS_REQUEST = "ORDER_PROCESS_REQUEST";
export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_VOID_REQUEST = "ORDER_VOID_REQUEST";
export const ORDER_ITEM_REQUEST_APPROVAL_REQUEST = "ORDER_ITEM_REQUEST_APPROVAL_REQUEST";
export const ORDER_APPROVE_REQUEST = "ORDER_APPROVE_REQUEST";
export const ORDER_REJECT_REQUEST = "ORDER_REJECT_REQUEST";
export const ORDER_QUOT_CREATE_REQUEST = "ORDER_QUOT_CREATE_REQUEST";

export const ORDER_CREATE_FORM_NAME = "createSaleOrder";
export const ORDER_EDIT_FORM_NAME = "editSaleOrder";
export const ORDER_ITEM_FILED_NAME = "saleOrderItems";
export const ORDER_PROCESS_ITEM_FORM_NAME = "processItemForm";

export const ORDER_PROCESS_ITEM_ARRAY_FIELD_NAME = "saleOrderItems";
export const ORDER_ITEM_CUSTOM_PRICE = "Custom";
export const ITEM_INSTALLATION_FORM_NAME = "itemInstallation";

export const ORDER_ITEM_APPROVAL_FORM_NAME = "approveSaleOrderItem";
export const ORDER_ITEM_ARRAY_FIELD_NAME = "saleOrderItems";

export const SETTING_ALLOW_CUSTOMER_COMMISSION = "allowCustomerCommission";
export const SETTING_ALLOW_TO_USE_CUSTOMER_COMMISSION = "allowToUseCustomerCommission";

export const PAYMENT_TERM_AUTOCOMPLETE_REQUEST = "PAYMENT_TERM_AUTOCOMPLETE_REQUEST";
export const ORDER_SUBMIT_RULES = {
  employeeId: {
    required: true,
  },
  customerId: {
    required: true,
  },
  paymentTermId: {
    required: true,
  },
  orderDate: {
    required: true,
  },
  preferredPaymentMethodId: {
    required: true,
  },
  saleOrderItems: {
    required: true,
    type: "array",
    message: "Please select at least 1 item",
    validation: (values) => {
      let error = false;
      let parentName = [];
      values.map((value) => {
        const { hasChild, children, name } = value;
        if (hasChild == true && children.length == 0) {
          error = true;
          parentName = parentName.concat(name);
        }
      });
      if (error) {
        const message = parentName.map((val, index) => {
          return (
            <div key={index}>
              <b>{val}</b> must be have at least 1 sub item
            </div>
          );
        });
        return {
          message
        };
      }
    }
  },
};

export const CREATE_SALE_INVOICE = 198001;
export const READ_ORDER = 196000;
export const CREATE_ORDER = 196001;
export const UPDATE_ORDER = 196002;
export const ARCHIVE_ORDER = 196004;
export const UNARCHIVE_ORDER = 196005;
export const DELETE_ORDER = 196002;
export const CANCEL_ORDER = 196016;
export const PROCESS_ORDER = 26007;
export const SUBMIT_ORDER = 196022;
export const PRINT_ORDER = 196007;
export const REJECT_ORDER_ITEM_CODE = 196018;
export const ORDER_ITEM_APPROVAL = 196021;
export const REQUEST_APPROVAL_ORDER_CODE = 196019;
export const VIEW_CUSTOMER_COMMISSION_ORDER = 196023;
export const CUSTOMER_COMMISSION_ORDER = 196024;
export const VIEW_ALL_ORDER = 196026;
export const VIEW_TEAM_ORDER = 196027;

export const ORDER_PENDING = 1;
export const ORDER_PENDING_TITLE = "Pending";
export const ORDER_STOCK_PROCESS = 2;
export const ORDER_STOCK_PROCESS_TITLE = "Stock Process";
export const ORDER_COMPLETED = 3;
export const ORDER_COMPLETED_TITLE = "Completed";
export const ORDER_CANCELLED = 4;
export const ORDER_CANCELLED_TITLE= "Cancelled";
export const ORDER_WAITING_APPROVAL = 5;
export const ORDER_WAITING_APPROVAL_TITLE = "Waiting Approval";
export const ORDER_SUBMIT = 6;
export const ORDER_SUBMIT_TITLE = "Confirmed";
export const ORDER_APPROVE = 7;
export const ORDER_APPROVE_TITLE = "Approved";
export const ORDER_REJECT = 8;
export const ORDER_REJECT_TITLE = "Rejected";
export const ORDER_STOCK_OUT_COMPLETE = 9;
export const ORDER_STOCK_OUT_COMPLETE_TITLE = "Stock Out Completed";
export const ORDER_RETURN = 10;
export const ORDER_RETURN_TITLE = "Returned";

export const ORDER_STATUS_DATASOURCE = [
  {
    id: ORDER_PENDING,
    name: ORDER_PENDING_TITLE,
  },
  {
    id: ORDER_STOCK_PROCESS,
    name: ORDER_STOCK_PROCESS_TITLE,
  },
  {
    id: ORDER_COMPLETED,
    name: ORDER_COMPLETED_TITLE,
  },
  {
    id: ORDER_CANCELLED,
    name: ORDER_CANCELLED_TITLE,
  },
  {
    id: ORDER_WAITING_APPROVAL,
    name: ORDER_WAITING_APPROVAL_TITLE,
  },
  {
    id: ORDER_SUBMIT,
    name: ORDER_SUBMIT_TITLE,
  },
  {
    id: ORDER_APPROVE,
    name: ORDER_APPROVE_TITLE,
  },
  {
    id: ORDER_REJECT,
    name: ORDER_REJECT_TITLE,
  },
  {
    id: ORDER_STOCK_OUT_COMPLETE,
    name: ORDER_STOCK_OUT_COMPLETE_TITLE,
  },
  {
    id: ORDER_RETURN,
    name: ORDER_RETURN_TITLE,
  },
];


export const ORDER_ITEM_STATUS_DRAFT = 1;
export const ORDER_ITEM_STATUS_DRAFT_TITLE = "Draft";
export const ORDER_ITEM_STATUS_REJECTED = 2;
export const ORDER_ITEM_STATUS_REJECTED_TITLE = "Rejected";
export const ORDER_ITEM_STATUS_APPROVED = 3;
export const ORDER_ITEM_STATUS_APPROVED_TITLE = "Approved"; 

export const QUOTATION_STOCK_STATUS_IN_STOCK = 1;
export const QUOTATION_STOCK_STATUS_IN_STOCK_TITLE = "In Stock";
export const QUOTATION_STOCK_STATUS_OUT_OF_STOCK = 2;
export const QUOTATION_STOCK_STATUS_OUT_OF_STOCK_TITLE = "Out of Stock";

export const QUOTATION_STOCK_STATUS_DATA_SOURCE = [
  {
    id: QUOTATION_STOCK_STATUS_IN_STOCK,
    name: QUOTATION_STOCK_STATUS_IN_STOCK_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_OUT_OF_STOCK,
    name: QUOTATION_STOCK_STATUS_OUT_OF_STOCK_TITLE,
  }
];

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1 = 1;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1_TITLE = "1 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2 = 2;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2_TITLE = "2 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3 = 3;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3_TITLE = "3 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4 = 4;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4_TITLE = "3-4 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5 = 5;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5_TITLE = "4-6 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6 = 6;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6_TITLE = "6-8 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7 = 7;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7_TITLE = "8-10 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8 = 8;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8_TITLE = "10-12 Week";

export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9 = 9;
export const QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9_TITLE = "12-14 Week";

export const QUOTATION_STOCK_STATUS_DURATION = [
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_1_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_2_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_3_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_4_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_5_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_6_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_7_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_8_TITLE,
  },
  {
    id: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9,
    name: QUOTATION_STOCK_STATUS_DURATION_WEEK_OPTION_9_TITLE,
  },
];

export const INSTALLATION_SUBMIT_RULES = {
  installationId: {
    required: true,
    type: "array",
    message: "Please select at least 1",
  },
};

export const ORDER_APPROVE_SUBMIT_RULES = {
  saleOrderItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "price",
        type: "number",
        min: 0,
      },
    ]
  },
};

export const ORDER_STATUS_PENDING = 1;
export const ORDER_STATUS_PENDING_TITLE = "Pending";
export const ORDER_STATUS_ON_THE_WAY = 3;
export const ORDER_STATUS_ON_THE_WAY_TITLE = "On the way";
export const ORDER_STATUS_CONFIRM = 2;
export const ORDER_STATUS_CONFIRM_TITLE = "Confirmed";
export const ORDER_STATUS_COMPLETE = 4;
export const ORDER_STATUS_COMPLETE_TITLE = "Completed";
export const ORDER_STATUS_CANCELLED = 5;
export const ORDER_STATUS_CANCELLED_TITLE = "Cancelled";
export const ORDER_STATUS_RETURN = 10;
export const ORDER_STATUS_RETURN_TITLE = "Returned";

export const ORDER_STATUS_DATA_SOURCE = [
  {
    id: ORDER_STATUS_PENDING,
    name: ORDER_STATUS_PENDING_TITLE,
  },
  {
    id: ORDER_STATUS_CONFIRM,
    name: ORDER_STATUS_CONFIRM_TITLE,
  },
  {
    id: ORDER_STATUS_ON_THE_WAY,
    name: ORDER_STATUS_ON_THE_WAY_TITLE,
  },
  {
    id: ORDER_STATUS_COMPLETE,
    name: ORDER_STATUS_COMPLETE_TITLE,
  },
  {
    id: ORDER_STATUS_RETURN,
    name: ORDER_STATUS_RETURN_TITLE,
  }
];