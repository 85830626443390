import { GETWithRawResponse } from "./base-service";
import { getAccessToken } from "@/shared/utils";
  
const API_URL = "/api/print-format/v1";
  
export function getPrintFormatTemplate(query) {
  const url = `${API_URL}/print?format=pdf`;
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`
  };
  return GETWithRawResponse(url, query, headers, {
    responseType: "arraybuffer"
  });
}