export const SYNC_ITEM_DIFF_ITEM_REQUEST = "SYNC_ITEM_DIFF_ITEM_REQUEST";
export const SYNC_ITEM_DIFF_ITEM_SUCCESS = "SYNC_ITEM_DIFF_ITEM_SUCCESS";

export const SYNC_ITEM_SYNC_REQUEST = "SYNC_ITEM_SYNC_REQUEST";
export const SYNC_ITEM_SYNC_SUCCESS = "SYNC_ITEM_SYNC_SUCCESS";
export const SYNC_ITEM_SYNC_RESET_SUCCESS = "SYNC_ITEM_SYNC_RESET_SUCCESS";

export const SYNC_ITEM_LOGIN_REQUEST = "SYNC_ITEM_LOGIN_REQUEST";
export const SYNC_ITEM_LOGIN_SUCCESS = "SYNC_ITEM_LOGIN_SUCCESS";

export const SYNC_ITEM_LOGIN_FORM_NAME = "synItemLogInForm";
export const SYNC_ITEM_SYNC_FORM_NAME = "synItemSyncForm";

export const SYNC_ITEM_TO_UPDATE_FIELD_NAME = "toUpdateItemIds";
export const SYNC_ITEM_TO_CREATE_FIELD_NAME = "toCreateItemIds";
export const SYNC_ITEM_LOCAL_ITEM_FIELD_NAME = "localItemIds";

export const SYNC_ITEM_READ_DIFF_ITEM = 204023;
export const SYNC_ITEM_SYNC = 204024;

export const LOGIN_REQUEST_RULES = {
  username: {
    required: true,
  },
  password: {
    required: true,
  },
  subDomain: {
    required: true,
  }
};