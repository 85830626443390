import { hr } from "./hr";
import { sale }from "./sale";
import { website } from "./website";
import { purchase }from "./purchase";
import { inventory } from "./inventory";
import { accounting } from "./accounting";
import { settings } from "./settings";
import { delivery } from "./delivery";

export default [
  ...hr,
  ...sale,
  ...website,
  ...purchase,
  ...inventory,
  ...accounting,
  ...settings,
  ...delivery
];
