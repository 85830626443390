import { 
  LeaveAllocationSchema,
  LeaveAllocationArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as LeaveAllocationConstants from "../constants";

const path = "/human-resource/leave/leave-allocation";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLeaveAllocation,
    schema: LeaveAllocationArraySchema,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createLeaveAllocation = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createLeaveAllocation,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getLeaveAllocationAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getLeaveAllocationAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getLeaveAllocationDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLeaveAllocationDetail,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: LeaveAllocationSchema,
  };
  return createDetailAction(options, id);
};

export const editLeaveAllocation = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editLeaveAllocation,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "leaveAllocations",
    schema: LeaveAllocationSchema,
    successMessage: "Leave allocation updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveLeaveAllocation = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveLeaveAllocation,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Leave allocation archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteLeaveAllocation = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteLeaveAllocation,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Leave allocation deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveLeaveAllocation = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveLeaveAllocation,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave allocation unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const updateStatusToSubmit = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToSubmit,
    schema: LeaveAllocationSchema,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave allocation is submitted successfully!",
  };

  return createEntityActionAction(options, id);
};

export const updateStatusToApprove = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToApprove,
    schema: LeaveAllocationSchema,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave allocation is approved successfully!",
  };

  return createEntityActionAction(options, id);
};

export const updateStatusToCancel = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToCancel,
    schema: LeaveAllocationSchema,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave allocation is cancelled successfully!",
  };

  return createEntityActionAction(options, id);
};

export const updateStatusToReject = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToReject,
    schema: LeaveAllocationSchema,
    requestName: LeaveAllocationConstants.LEAVE_ALLOCATION_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave allocation is rejected successfully!",
  };

  return createEntityActionAction(options, id);
};