
export const ACCOUNTING_SETTING_PATH = "/accounting/list/accounting-setting";

export const READ_ACCOUNTING_SETTING = 178015;
export const UPDATE_ACCOUNTING_SETTING = 178016;

export const ALLOW_REVERSE_PURCHASE_JOURNAL = "allowReversePurchaseJournal";
export const ALLOW_REVERSE_SALE_JOURNAL = "allowReverseSaleJournal";
export const ALLOW_REVERSE_STOCK_IN_JOURNAL = "allowStockInJournal";
export const ALLOW_REVERSE_STOCK_OUT_JOURNAL = "allowStockOutJournal";
export const ALLOW_REVERSE_STOCK_TRANSFER_JOURNAL = "allowStockTransferJournal";
export const ALLOW_REVERSE_STOCK_ADJUSTMENT_JOURNAL = "allowStockAdjustmentJournal";
export const ALLOW_REVERSE_EXPENSE_JOURNAL = "allowReverseExpenseJournal";
export const ALLOW_REVERSE_CUSTOMER_DEPOSIT_JOURNAL = "allowReverseCustomerDepositJournal";
export const ALLOW_REVERSE_CUSTOMER_REFUND_JOURNAL = "allowReverseCustomerRefundJournal";
export const ACCOUNTING_SETTING_ALLOW_MANUAL_DATE = "allowManualDate";

export const ACCOUNTING_SETTING_SUBMIT_RULES = {
  fiscalYear: {
    required: true,
  },
  defaultDepositChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  receivableDepositChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  payableDepositChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  defaultSupplierDepositChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  defaultBidChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  defaultVATExpense: {
    type: "object",
    value: {
      required: true
    }
  },
};

export const DEFAULT_DEPOSIT_CHART_OF_ACCOUNT = "DEFAULT_DEPOSIT_CHART_OF_ACCOUNT";
export const DEFAULT_CUSTOMER_COMMISSION_CHART_OF_ACCOUNT = "DEFAULT_CUSTOMER_COMMISSION_CHART_OF_ACCOUNT";
export const RECEIVABLE_DEPOSIT_CHART_OF_ACCOUNT = "RECEIVABLE_DEPOSIT_CHART_OF_ACCOUNT";
export const PAYABLE_DEPOSIT_CHART_OF_ACCOUNT = "PAYABLE_DEPOSIT_CHART_OF_ACCOUNT";
