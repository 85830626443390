const getSubmittedMessage = (additionalText) => {
  return `submitted ${additionalText}`;
};

const getApprovedMessage = (additionalText) => {
  return `approved your ${additionalText}`;
};

const getRejectedMessage = (additionalText) => {
  return `rejected your ${additionalText}`;
};

const getCancelledMessage = (additionalText) => {
  return `cancelled ${additionalText}`;
};

const getProcessedMessage = (additionalText) => {
  return `processed ${additionalText}`;
};

const getForceClosedMessage = (additionalText) => {
  return `force closed ${additionalText}`;
};

const getAssignMessage = (additionalText) => {
  return `assign you to ${additionalText}`;
};

const getRequestMessage = (additionalText) => {
  return `request ${additionalText}`;
};

export { 
  getSubmittedMessage,
  getApprovedMessage,
  getRejectedMessage,
  getCancelledMessage,
  getProcessedMessage,
  getForceClosedMessage,
  getAssignMessage,
  getRequestMessage,
};