import { CONFIGURATION_SUCCESS } from "@/constants/configuration";

export default function (state = {}, action) {
  switch(action.type){
  case CONFIGURATION_SUCCESS:
    return {
      ...state,
      [action.entity] : action.data
    };
  default: 
    return state;
  }
}