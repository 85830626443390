import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";

// Action 
import { getEmployeeAutoComplete } from "@modules/hr/employee-directory/employee/actions";

// Constant 
import { EMPLOYEE_AUTOCOMPLETE_REQUEST } from "@modules/hr/employee-directory/employee/constants";

class AsyncEmployeeAutoComplete extends Component {

  render() {
    const { name, requestName, action, ...props } = this.props;
    return (
      <AsyncSelect 
        name={name}
        action={action}
        requestName={requestName}
        optionRender={(value) => `${value.firstName} ${value.lastName}`}
        {...props}
      />
    );
  }
}

AsyncEmployeeAutoComplete.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  requestName: PropTypes.string,
};

AsyncEmployeeAutoComplete.defaultProps = {
  name: "employee",
  action: getEmployeeAutoComplete,
  requestName: EMPLOYEE_AUTOCOMPLETE_REQUEST,
};

export default withRouter(AsyncEmployeeAutoComplete);