export const RECRUITMENT_STAGE_LIST_REQUEST = "RECRUITMENT_STAGE_LIST_REQUEST"; 
export const RECRUITMENT_STAGE_LIST_SUCCESS = "RECRUITMENT_STAGE_LIST_REQUEST_SUCCESS";
export const RECRUITMENT_STAGE_CREATE_REQUEST = "RECRUITMENT_STAGE_CREATE_REQUEST"; 
export const RECRUITMENT_STAGE_DETAIL_REQUEST = "RECRUITMENT_STAGE_DETAIL_REQUEST"; 
export const RECRUITMENT_STAGE_ARCHIVE_REQUEST = "RECRUITMENT_STAGE_ARCHIVE_REQUEST"; 
export const RECRUITMENT_STAGE_DELETE_REQUEST = "RECRUITMENT_STAGE_DELETE_REQUEST"; 
export const RECRUITMENT_STAGE_UNARCHIVE_REQUEST = "RECRUITMENT_STAGE_UNARCHIVE_REQUEST"; 
export const RECRUITMENT_STAGE_EDIT_REQUEST = "RECRUITMENT_STAGE_EDIT_REQUEST"; 
export const RECRUITMENT_STAGE_CREATE_FORM_NAME = "createRecruitmentStage"; 
export const RECRUITMENT_STAGE_EDIT_FORM_NAME = "editRecruitmentStage"; 
export const RECRUITMENT_STAGE_AUTOCOMPLETE_REQUEST = "RECRUITMENT_STAGE_AUTOCOMPLETE_REQUEST";
export const RECRUITMENT_STAGE_AUTOCOMPLETE_SUCCESS = "RECRUITMENT_STAGE_AUTOCOMPLETE_SUCCESS";
export const RECRUITMENT_STAGE_SUBMIT_RULES = {
  name: {
    required: true,
  },
  level: {
    required: true,
  }
};
export const READ_RECRUITMENT_STAGE = 165000;
export const CREATE_RECRUITMENT_STAGE = 165001;
export const UPDATE_RECRUITMENT_STAGE = 165002;
export const DELETE_RECRUITMENT_STAGE = 165003;
export const ARCHIVE_RECRUITMENT_STAGE = 165004;
export const UNARCHIVE_RECRUITMENT_STAGE = 165005;