import { normalize } from "normalizr";
import { 
  PayslipSchema,
  PayslipArraySchema,
  PayslipInputArraySchema,
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as PayslipConstants from "../constants";
import { mergeEntity, mergeEntities } from "@/actions/entity";

const path = "/human-resource/payroll/payslip";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPayslip,
    schema: PayslipArraySchema,
    requestName: PayslipConstants.PAYSLIP_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getPayslipAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getPayslipAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getPayslipDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPayslipDetail,
    requestName: PayslipConstants.PAYSLIP_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: PayslipSchema,
  };
  return createDetailAction(options, id);
};

export const computePayslip = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.computePayslip,
    schema: PayslipSchema,
    requestName: PayslipConstants.PAYSLIP_COMPUTATION_CREATE_REQUEST,
    dispatch,
    getState,
    entityName: "payslips",
    shouldSyncEntities: true,
    successMessage: "Payslip is computed successfully!",
  };
  return createEntityActionAction(options, id);
};

export const confirmPayslip = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.confirmPayslip,
    schema: PayslipSchema,
    requestName: PayslipConstants.PAYSLIP_CONFIRM_REQUEST,
    dispatch,
    getState,
    successMessage: "Payslip is confirmed successfully!",
  };
  return createEntityActionAction(options, id);
};

export const cancelPayslip = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.cancelPayslip,
    schema: PayslipSchema,
    requestName: PayslipConstants.PAYSLIP_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Payslip is cancelled successfully!",
  };
  return createEntityActionAction(options, id);
};

export const draftPayslip = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.draftPayslip,
    schema: PayslipSchema,
    requestName: PayslipConstants.PAYSLIP_DRAFT_REQUEST,
    dispatch,
    getState,
    successMessage: "Payslip is drafted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const editPayslipInput = (values) => (dispatch, getState) => {

  const onSuccess = (json) => {
    const { id } = values;
    const state = getState();
    const normalized = normalize(json.inputs, PayslipInputArraySchema);
    const payslips = state.entities.payslips && state.entities.payslips[id];
    payslips.inputs = normalized.result;
    dispatch(mergeEntity("payslips", id, payslips));
    dispatch(mergeEntities(normalized.entities));
  };

  const options = {
    serviceMethod: service.editPayslipInput,
    schema: PayslipSchema,
    entityName: "payslips",
    requestName: PayslipConstants.PAYSLIP_EDIT_INPUT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    onSuccess,
    successMessage: "Payslip inputs is updated successfully!",
  };
  return createEditAction(options, values);
};