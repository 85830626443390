import * as service from "../services";
import { 
  ChartOfAccountSchema,
} from "../schema";
import { ChartOfAccountTypeArraySchema } from "../schema/chart-of-account-type";
import { ChartAccountHistoryArraySchema } from "../schema/chart-account-histrory";
import * as Constants from "../constants";
import {
  createEditAction,
  createDetailAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createTreeAction,
  createAutoCompleteAction,
  createListAction,
} from "@modules/utils/action-creator";

const path = "/accounting/list/chart-of-account";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getChartOfAccount,
    schema: ChartOfAccountTypeArraySchema,
    requestName: Constants.CHART_OF_ACCOUNT_REQUEST,
    dispatch,
    getState,
    entityName: "chartOfAccountTypes"
  };
  return createTreeAction(options, values);
};

export const getChartOfAccountAutoComplete = (query, extra) => (dispatch, getState) => {

  const options = {
    serviceMethod: service.getChartOfAccountAutoComplete,
    requestName: extra.requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getChartOfAccountTypeAutoComplete = (query) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getChartOfAccountTypeAutoComplete,
    requestName: Constants.CHART_OF_ACCOUNT_TYPE_AUTOCOMPLETE_REQUEST,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query);
};

export const createChartOfAccount = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createChartOfAccount,
    requestName: Constants.CHART_OF_ACCOUNT_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "Chart of account",
    formName: "createChartOfAccount"
  };
  return createNewEntityAction(options, values);
};

export const getChartOfAccountDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getChartOfAccountDetail,
    requestName: Constants.CHART_OF_ACCOUNT_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: ChartOfAccountSchema,
  };
  return createDetailAction(options, id);
};

export const editChartOfAccount = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editChartOfAccount,
    requestName: Constants.CHART_OF_ACCOUNT_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    schema: ChartOfAccountSchema,
    successMessage: "Chart of account is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveChartOfAccount = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveChartOfAccount,
    requestName: Constants.CHART_OF_ACCOUNT_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Chart of account is archived successfully!",
  };
  return createArchiveAction(options, id);  
};

export const unarchiveChartOfAccount = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveChartOfAccount,
    requestName: Constants.CHART_OF_ACCOUNT_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Chart of account is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const getChartAccountHistory = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getChartAccountHistory,
    schema: ChartAccountHistoryArraySchema,
    requestName: Constants.CHART_ACCOUNT_HISTORY_REQUEST,
    dispatch,
    getState,
  };
  return createListAction(options, values);
};