import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/leave/allocation/v1";

export const getLeaveAllocation = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createLeaveAllocation(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getLeaveAllocationAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getLeaveAllocationDetail(id) {
  return GETDetail(API_URL, id);
}

export function editLeaveAllocation(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveLeaveAllocation(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveLeaveAllocation(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteLeaveAllocation(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function updateStatusToSubmit(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url, null);
}

export function updateStatusToApprove(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url, null);
}

export function updateStatusToCancel(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url, null);
}

export function updateStatusToReject(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url, null);
}