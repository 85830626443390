// Libs
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Components
import PanelBox from "@cores/components/panel-box";
import DetailBox from "@cores/components/detail-box";
import CurrencyBox from "@cores/components/currency-box";

// Helper
import { renderCustomerCommissionStatus } from "../../helper";
import { toShortDate } from "@modules/helpers";

class CustomerCommissionDetail extends Component {

  render() {
    const {
      entity: {
        date,
        amount,
        status,
        invoice,
        customer,
        reference,
        chequeEntry,
        paymentMethod,
        customerCommissionNo,
      }
    } = this.props;
    return (
      <Fragment>
        <PanelBox title="General Info">
          <Row>
            <Col md="6" xs="12">
              <DetailBox
                title="Customer Commission No"
                data={customerCommissionNo}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Reference"
                data={reference}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Date"
                data={toShortDate(date)}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Amount"
                data={<CurrencyBox value={amount} />}
              />
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Payment Method">
                {paymentMethod ? (
                  <Link to={`../payment-method/${paymentMethod.id}`}>
                    {paymentMethod.name}
                  </Link>
                ) : (
                  "N/A"
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Customer">
                {customer && (
                  <Link to={`../../../sale/customer-center/customer/${customer.customerNo}`}>
                    {customer.firstName} {customer.lastName}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Company">
                {customer.customerCompany && (
                  <Link to={`../../../sale/customer-center/customer-company/${customer.customerCompany.customerCompanyNo}`}>
                    {customer.customerCompany.name}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox title="Invoice">
                {invoice && (
                  <Link to={`../../../sale/sale-center/sale-invoice/${invoice.invoiceNo}`}>
                    {invoice.invoiceNo}
                  </Link>
                )}
              </DetailBox>
            </Col>
            <Col md="6" xs="12">
              <DetailBox
                title="Status"
                data={renderCustomerCommissionStatus(status)}
              />
            </Col>
          </Row>
        </PanelBox>
        {paymentMethod && paymentMethod.isCheque && chequeEntry && (
          <PanelBox title="Cheque Info">
            <Row>
              <Col md="6" xs="12">
                <DetailBox
                  title="Cheque Number"
                  data={chequeEntry.chequeNumber}
                />
              </Col>
              <Col md="6" xs="12">
                <DetailBox
                  title="Issue Date"
                  data={toShortDate(chequeEntry.issueDate)}
                />
              </Col>
              <Col md="6" xs="12">
                <DetailBox
                  title="Bank Name"
                  data={chequeEntry.bankName}
                />
              </Col>
              <Col md="6" xs="12">
                <DetailBox
                  title="Pay To"
                  data={chequeEntry.payTo}
                />
              </Col>
            </Row>
          </PanelBox>
        )}
      </Fragment>
    );
  }
}

CustomerCommissionDetail.propTypes = {
  entity: PropTypes.object,
};

export default CustomerCommissionDetail;