import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Component
import AsyncSelect from "../async-select-check-box";
import { getPaymentTermAutoComplete } from "@sale/setup/payment-term/actions";
import { PAYMENT_TERM_AUTOCOMPLETE_REQUEST } from "@sale/setup/payment-term/constants";

class AsyncPaymentTermAutoComplete extends React.Component {

  render() {
    const { name, requestName, action, ...props } = this.props;
    return (
      <AsyncSelect 
        name={name}
        action={action}
        requestName={requestName}
        placeholder="Payment Term" 
        {...props}
      />
    );
  }
}

AsyncPaymentTermAutoComplete.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  requestName: PropTypes.string,
};

AsyncPaymentTermAutoComplete.defaultProps = {
  name: "paymentTerm",
  action: getPaymentTermAutoComplete,
  requestName: PAYMENT_TERM_AUTOCOMPLETE_REQUEST,
};

export default withRouter(AsyncPaymentTermAutoComplete);