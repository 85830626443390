export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST"; 
export const ROLE_LIST_SUCCESS = "ROLE_LIST_REQUEST_SUCCESS";
export const ROLE_CREATE_REQUEST = "ROLE_CREATE_REQUEST"; 
export const ROLE_DETAIL_REQUEST = "ROLE_DETAIL_REQUEST"; 
export const ROLE_ARCHIVE_REQUEST = "ROLE_ARCHIVE_REQUEST"; 
export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST"; 
export const ROLE_UNARCHIVE_REQUEST = "ROLE_UNARCHIVE_REQUEST"; 
export const ROLE_EDIT_REQUEST = "ROLE_EDIT_REQUEST"; 
export const ROLE_AUTOCOMPLETE_REQUEST = "ROLE_AUTOCOMPLETE_REQUEST";
export const ROLE_AUTOCOMPLETE_SUCCESS = "ROLE_AUTOCOMPLETE_SUCCESS";
export const ROLE_CREATE_FORM_NAME = "createRole"; 
export const ROLE_EDIT_FORM_NAME = "editRole"; 

export const PERMISSION_GROUP_PREFIX = "group_";
export const PERMISSION_IDS_POSTFIX = "permissionIds";
export const PERMISSION_CHILDREN_GROUP_PREFIX = "group_children_";

export const PERMISSION_GROUP_LIST_REQUEST = "PERMISSION_GROUP_LIST_REQUEST";
export const PERMISSION_GROUP_LIST_SUCCESS = "PERMISSION_GROUP_LIST_REQUEST_SUCCESS";

export const PERMISSION_LIST_REQUEST = "PERMISSION_LIST_REQUEST"; 
export const PERMISSION_LIST_SUCCESS = "PERMISSION_LIST_REQUEST_SUCCESS";

export const ROLE_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  type: {
    required: true,
  }
};

export const READ_ROLE = 120000;
export const CREATE_ROLE = 120001;
export const UPDATE_ROLE = 120002;
export const DELETE_ROLE = 120003; 
export const ARCHIVE_ROLE = 120004;
export const UNARCHIVE_ROLE = 120005;

export const ROLE_TYPE_USER = 1;
export const ROLE_TYPE_USER_TITLE = "User";
export const ROLE_TYPE_APP = 2;
export const ROLE_TYPE_APP_TITLE = "App";
export const ROLE_TYPE_CUSTOMER = 3;
export const ROLE_TYPE_CUSTOMER_TITLE = "Customer";

export const ROLE_TYPE_DATA_SOURCE = [
  {
    id: ROLE_TYPE_USER,
    name: ROLE_TYPE_USER_TITLE,
  },
  {
    id: ROLE_TYPE_APP,
    name: ROLE_TYPE_APP_TITLE,
  },
  {
    id: ROLE_TYPE_CUSTOMER,
    name: ROLE_TYPE_CUSTOMER_TITLE,
  }
];