export const WARRANTY_LIST_REQUEST = "WARRANTY_LIST_REQUEST"; 
export const WARRANTY_LIST_SUCCESS = "WARRANTY_LIST_REQUEST_SUCCESS";
export const WARRANTY_CREATE_REQUEST = "WARRANTY_CREATE_REQUEST"; 
export const WARRANTY_DETAIL_REQUEST = "WARRANTY_DETAIL_REQUEST"; 
export const WARRANTY_ARCHIVE_REQUEST = "WARRANTY_ARCHIVE_REQUEST"; 
export const WARRANTY_DELETE_REQUEST = "WARRANTY_DELETE_REQUEST"; 
export const WARRANTY_UNARCHIVE_REQUEST = "WARRANTY_UNARCHIVE_REQUEST"; 
export const WARRANTY_EDIT_REQUEST = "WARRANTY_EDIT_REQUEST"; 
export const WARRANTY_CREATE_FORM_NAME = "createWarranty"; 
export const WARRANTY_EDIT_FORM_NAME = "editWarranty"; 
export const WARRANTY_AUTOCOMPLETE_REQUEST = "WARRANTY_AUTOCOMPLETE_REQUEST";
export const WARRANTY_AUTOCOMPLETE_SUCCESS = "WARRANTY_AUTOCOMPLETE_SUCCESS";
export const WARRANTY_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  duration: {
    required: true,
  }
};
export const READ_WARRANTY = 248000;
export const CREATE_WARRANTY = 248001;
export const UPDATE_WARRANTY = 248002;
export const DELETE_WARRANTY = 248003;
export const ARCHIVE_WARRANTY = 248004;
export const UNARCHIVE_WARRANTY = 248005;
export const BATCH_DELETE_WARRANTY = 248006;
export const PRINT_WARRANTY = 248007;
export const IMPORT_WARRANTY = 248008;
export const EXPORT_WARRANTY = 248009;
export const REPORT_WARRANTY = 248010;