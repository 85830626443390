import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Form, DatePicker } from "antd";
import classnames from "classnames";
import { FormText } from "reactstrap";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

import { 
  DEFAULT_DISPLAY_DATE_FORMAT, 
  DEFAULT_ASYNC_DATE_FORMAT
} from "@/constants/default-constants"; 

class DateRangPickerField extends React.Component {

  onChange = (value) => {
    const { 
      toName, 
      fromName, 
      handleChange, 
      updateField, 
      input: { 
        onChange: reduxFormChange 
      } 
    } = this.props;
    const from = value[0].format(DEFAULT_DISPLAY_DATE_FORMAT);
    const to = value[1].format(DEFAULT_DISPLAY_DATE_FORMAT);
    reduxFormChange(true);
    updateField(fromName, from);
    updateField(toName, to);
    if (handleChange) {
      handleChange({
        from, 
        to
      });
    }
  }

  render() {
    const { 
      tip,
      label,
      toName,
      required,
      getValue,
      fromName,
      disabled,
      withFilter,
      placeholder,
      meta: { touched, error },
    } = this.props;
    const from = getValue(fromName);
    const to = getValue(toName);
    let { style } = this.props;
    if (label == null && withFilter) {
      const defaultStyle = {
        width: "auto",
        minWidth: "150px"
      };
      style = Object.assign(defaultStyle, style);
    }
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <React.Fragment>
        <FormItem
          label={label} 
          help={error}
          required={required}
          validateStatus={validateStatus}
        >
          <RangePicker 
            style={style}
            className="w-100"
            disabled={disabled}
            onChange={this.onChange}
            placeholder={placeholder}
            format={DEFAULT_DISPLAY_DATE_FORMAT}
            value={[
              from && moment(from, DEFAULT_ASYNC_DATE_FORMAT),
              to && moment(to, DEFAULT_ASYNC_DATE_FORMAT)
            ]}
          />
        </FormItem>
        {tip && (
          <FormText
            color="muted"
            className="mt-0 mb-2"
          >
            {tip}
          </FormText>
        )}
      </React.Fragment>
    );
  }
}

DateRangPickerField.propTypes = {
  meta: PropTypes.any,
  input: PropTypes.any,
  tip: PropTypes.string,
  toName: PropTypes.any,
  label: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fromName: PropTypes.any,
  getValue: PropTypes.func,
  withFilter: PropTypes.bool,
  updateField: PropTypes.func,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
};

DateRangPickerField.defaultProps = {
  fromName: "from",
  toName: "to",
  placeholder: [
    "Start Date",
    "End Date"
  ],
};

const mapStateToProps = (state, ownProps) => {
  const { meta: { form } } = ownProps;
  const selector = formValueSelector(form);
  return {
    getValue: (name) => selector(state, name)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { meta: { form } } = ownProps;
  return {
    updateField: (field, value) => dispatch(change(form, field, value)),
  };
};

const ConnectAutoCompleteField = connect(mapStateToProps, mapDispatchToProps)(DateRangPickerField);

class DateRangePicker extends React.Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field 
        component={ConnectAutoCompleteField}
        name="dateRange"
        {...props}
      />
    );
  }
}

export default DateRangePicker;