export const READ_ATTACHMENT = "263000";
export const WRITE_ATTACHMENT = "263001";
export const UPDATE_ATTACHMENT = "263002";
export const DELETE_ATTACHMENT = "263003";
export const ARCHIVE_ATTACHMENT = "263004";
export const UNARCHIVE_ATTACHMENT = "263005";
export const BATCHDELETE_ATTACHMENT = "263006";
export const PRINT_ATTACHMENT = "263007";
export const IMPORT_ATTACHMENT = "263008";
export const EXPORT_ATTACHMENT = "263009";
export const REPORT_ATTACHMENT = "263010";

export const ATTACHMENT_REQUEST = "ATTACHMENT_REQUEST";
export const ATTACHMENT_SUCCESS = "ATTACHMENT_SUCCESS";
export const ATTACHMENT_SUCCESS_NEXT_PAGE = "ATTACHMENT_SUCCESS_NEXT_PAGE";

export const ATTACHMENT_CREATE_REQUEST = "ATTACHMENT_CREATE_REQUEST";
export const ATTACHMENT_CREATE_SUCCESS = "ATTACHMENT_CREATE_SUCCESS";

export const ATTACHMENT_DELETE_REQUEST = "ATTACHMENT_DELETE_REQUEST";
export const ATTACHMENT_DELETE_SUCCESS = "ATTACHMENT_DELETE_SUCCESS";