import React, { Component } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";

class PageNotFound extends Component {

  render() {
    return (
      <Result
        status="404"
        title="Sorry, the page you visited does not exist."
        className="m-4"
        extra={
          <Link to="/dashboard">
            <br />
            <Button 
              ghost 
              type="primary" 
              icon={<HomeOutlined />}
            >
              Return Home 
            </Button>
          </Link>
        }
      />
    );
  }
}

export default PageNotFound;