import { BANK_HISTORY_SUCCESS, BANK_HISTORY_NEXT_PAGE } from "../constants";

export default function(state = {}, action) {
  switch (action.type) {
  case BANK_HISTORY_SUCCESS:
    return {
      ...state,
      [action.id]: action.payload
    };
  case BANK_HISTORY_NEXT_PAGE: {
    return {
      ...state,
      [action.id]: [
        ...state[action.id],
        ...action.payload
      ]
    };
  }
  default:
    return state;
  }
}