export const PURCHASE_ORDER_TYPE_LIST_REQUEST = "PURCHASE_ORDER_TYPE_LIST_REQUEST"; 
export const PURCHASE_ORDER_TYPE_LIST_SUCCESS = "PURCHASE_ORDER_TYPE_LIST_REQUEST_SUCCESS";
export const PURCHASE_ORDER_TYPE_CREATE_REQUEST = "PURCHASE_ORDER_TYPE_CREATE_REQUEST"; 
export const PURCHASE_ORDER_TYPE_DETAIL_REQUEST = "PURCHASE_ORDER_TYPE_DETAIL_REQUEST"; 
export const PURCHASE_ORDER_TYPE_ARCHIVE_REQUEST = "PURCHASE_ORDER_TYPE_ARCHIVE_REQUEST"; 
export const PURCHASE_ORDER_TYPE_DELETE_REQUEST = "PURCHASE_ORDER_TYPE_DELETE_REQUEST"; 
export const PURCHASE_ORDER_TYPE_UNARCHIVE_REQUEST = "PURCHASE_ORDER_TYPE_UNARCHIVE_REQUEST"; 
export const PURCHASE_ORDER_TYPE_EDIT_REQUEST = "PURCHASE_ORDER_TYPE_EDIT_REQUEST"; 
export const PURCHASE_ORDER_TYPE_CREATE_FORM_NAME = "createPurchaseOrderType"; 
export const PURCHASE_ORDER_TYPE_EDIT_FORM_NAME = "editPurchaseOrderType"; 
export const PURCHASE_ORDER_TYPE_AUTOCOMPLETE_REQUEST = "PURCHASE_ORDER_TYPE_AUTOCOMPLETE_REQUEST";
export const PURCHASE_ORDER_TYPE_AUTOCOMPLETE_SUCCESS = "PURCHASE_ORDER_TYPE_AUTOCOMPLETE_SUCCESS";
export const PURCHASE_ORDER_TYPE_SUBMIT_RULES = {
  name: {
    required: true,
  },
  managerId: {
    required: true,
  }
};

export const READ_PURCHASE_ORDER_TYPE = 190000;
export const CREATE_PURCHASE_ORDER_TYPE = 190001;
export const UPDATE_PURCHASE_ORDER_TYPE = 190002;
export const DELETE_PURCHASE_ORDER_TYPE = 190003;
export const ARCHIVE_PURCHASE_ORDER_TYPE = 190004;
export const UNARCHIVE_PURCHASE_ORDER_TYPE = 190005;