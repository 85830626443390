import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getCompanyAutoComplete, createCompany } from "@modules/settings/setup/company/actions";
import FormComponent from "@modules/settings/setup/company/components/form-component"; 

// Constant 
import { 
  COMPANY_AUTOCOMPLETE_REQUEST,
  CREATE_COMPANY,
  COMPANY_SUBMIT_RULES,
  COMPANY_CREATE_REQUEST
} from "@modules/settings/setup/company/constants";

class CompanyAutoComplete extends Component {

  render() {
    const { ids, ...props } = this.props; 
    return (
      <AutoComplete
        entity="Branch"
        formComponent={FormComponent}
        createAction={createCompany}
        permissionCode={CREATE_COMPANY}
        submitRules={COMPANY_SUBMIT_RULES}
        createRequestName={COMPANY_CREATE_REQUEST}
        extra={{
          ids,
        }}
        {...props}
      />
    );
  }
}

CompanyAutoComplete.propTypes = {
  ids: PropTypes.array,
};

CompanyAutoComplete.defaultProps = {
  label: "Branch",
  name: "companyBranchId",
  required: true,
  action: getCompanyAutoComplete,
  requestName: COMPANY_AUTOCOMPLETE_REQUEST,
};

export default CompanyAutoComplete;