import {
  GETList,
  GETDetail,
  PUTWithToken,
  GETWithToken,
  POSTWithToken,
  GETAutoComplete,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";
import { DELETEWithToken } from "src/services/base-service";

const API_URL = "/api/invoice/v1";

const API_SALE_SERVICE = "/api/service/v1";

export const getSaleInvoice = (values) => {
  const url = `${API_URL}/list`;
  if (!values.filter.noDateRange) {
    values = getDefaultCurrentDateRange(values);
  }
  values.filter.includes = ["customer", "employee", "companyBranch"];
  return GETList(url, values);
};

export const getDetailSaleList = (values) => {
  const url = `${API_URL}/detail-sale/list`;
  values = getDefaultCurrentDateRange(values);
  return GETList(url, values);
};

export function createSaleInvoice(values) {
  const { saleOrderId } = values;
  const url = `${API_URL}/create/sale-order/${saleOrderId}`;
  return POSTWithToken(url, values);
}

export function getSaleInvoiceDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function getSaleInvoiceAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function paySaleInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/pay`;
  return PUTWithToken(url, values);
}

export function creditSaleInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/credit`;
  return PUTWithToken(url, values);
}

export function editSaleInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function payBatchSaleInvoice(values) {
  const url = `${API_URL}/pay-batch`;
  return POSTWithToken(url, values);
}

export function createInvoice(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function cancelInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url, values);
}

export function assignVAT(id) {
  const url = `${API_URL}/${id}/assign-vat`;
  return PUTWithToken(url);
}


export function voidInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/void`;
  return PUTWithToken(url, values);
}

export function getInvoiceWithSerial(serial) {
  const url = `${API_URL}/serial/${serial}`;
  return GETWithToken(url);
}

export function convertToInvoice(id) {
  const url = `${API_URL}/create/sale-order/${id}`;
  return POSTWithToken(url);
}

export function updateInvoiceDescription(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function updateInvoiceExchangeRate(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/exchange-rate`;
  return PUTWithToken(url, values);
}

export function returnSaleInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/return`;
  return PUTWithToken(url, values);
}

export function exchangeSaleInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/exchange`;
  return PUTWithToken(url, values);
}

export function saleService(values) {
  const { id } = values;
  const url = `${API_SALE_SERVICE}/${id}/sale-service`;
  return PUTWithToken(url, values);
}

export function delayInvoice(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/delay`;
  return PUTWithToken(url, values);
}

export function delayBatchInvoice(values) {
  const url = `${API_URL}/delay-batch`;
  return PUTWithToken(url, values);
}

export function getSaleIncentiveWithFilter(values) {
  const url = `${API_URL}/employee-sale-commission`;
  return GETWithToken(url, values);
}

export function deleteInvoice(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}