import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
  GETWithToken,
} from "@/services/base-service";

const API_URL = "/api/custom-field/v1";

export function getCustomField(values) {
  const url = `${API_URL}/list`;
  return GETList(url, values);
}

export function getCustomFieldByEntity(entityName) {
  const url = `${API_URL}/list?sortBy=createdAt&order=ASC`;
  return GETWithToken(url, {entityName});
}

export function getCustomFieldAutoComplete(query, extra) {
  const url = `${API_URL}/autocomplete`;
  return GETAutoComplete(url, query, extra);
}

export function createCustomField(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getCustomFieldDetail(id) {
  return GETDetail(API_URL, id);
}

export function editCustomField(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveCustomField(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveCustomField(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteCustomField(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}