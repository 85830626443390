// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { DatePicker, Form } from "antd";
import moment from "moment";

// Utils
import {
  toQueryParamMonth,
  toQueryParamYear,
  getQueryParamUrl,
  updateQueryParamUrl,
  updateQueryParamToLocalStorage,
  getQueryParamFromLocalStorage,
} from "../../../helpers";
import * as Constants from "@/constants/default-constants";

const { MonthPicker } = DatePicker;

class AsyncMonthPicker extends Component {

  onChange = (value) => {
    const { name, onChange, queryStorage } = this.props;
    let query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const page = query.page;
    if (page && page > 1) {
      delete query.page;
    }
    query[name] = toQueryParamMonth(value);
    query["year"] = toQueryParamYear(value);
    if (!value) {
      delete query[name];
      delete query["year"];
    }
    if (queryStorage == "url") {
      updateQueryParamUrl(query);
    } else {
      updateQueryParamToLocalStorage(query);
      this.forceUpdate();
    }
    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const {
      name,
      placeholder,
      queryStorage,
    } = this.props;
    const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const monthValue = query[name];
    const yearValue = query["year"];
    const monthYearValue = `${monthValue}-${yearValue}`;
    let value = moment(monthYearValue, Constants.DEFAULT_ASYNC_MONTH_YEAR_FORMAT);
    if (monthValue == undefined && yearValue == undefined) {
      value = null;
    }
    return (
      <Form.Item>
        <MonthPicker
          allowClear
          value={value}
          onChange={this.onChange}
          placeholder={placeholder}
          format={Constants.DEFAULT_DISPLAY_MONTH_YEAR_FORMAT}
        />
      </Form.Item>
    );
  }
}

AsyncMonthPicker.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  queryStorage: PropTypes.string,
};

export default AsyncMonthPicker;