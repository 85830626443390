import * as AuthenticationConstants from "@/constants/authentication";

export function getBasicToken() {
  const clientCredential = localStorage.getItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
  if (clientCredential == null) {
    return null;
  }
  const serializedClientCredential = JSON.parse(clientCredential);
  return serializedClientCredential;
}

export function getAccessToken() {
  const clientCredential = localStorage.getItem(AuthenticationConstants.LOGIN_TYPE);
  if (clientCredential == null) {
    return null;
  }
  const serializedClientCredential = JSON.parse(clientCredential);
  return serializedClientCredential;
}

export function generateMachineIdIfNeeded() {
  const ID = function () {
    return Math.random().toString(36).substr(2, 9);
  };
  let machineId = localStorage.getItem(AuthenticationConstants.MACHINE_ID);
  if (machineId == null) {
    machineId = `iserp_${ID()}_${ID()}`;
    localStorage.setItem(AuthenticationConstants.MACHINE_ID, machineId);
  }
  return machineId;
}