import {
  GETAutoComplete,
  GETWithRawResponse,
} from "@/services/base-service";
import { getAccessToken } from "@/shared/utils";

const apiUrl = "/api/report-template/v1";

export function getReportTemplateAutoComplete(query, extra) {
  const url = `${apiUrl}/autocomplete`;
  return GETAutoComplete(url, query, extra);
}

export function getReportTemplate(type, query) {
  const url = `${apiUrl}/print/${type}`;
  const token = getAccessToken();
  const headers = {
    Authorization: `Bearer ${token.accessToken}`,
  };
  return GETWithRawResponse(url, query, headers, {
    responseType: "arraybuffer"
  });
}