const SETTING_PATH = "/settings";
const GOAL_PATH = "/goal";
const USER_MANAGEMENT_PATH = "user-management";
const EMAIL_PATH = "email";
const SETUP_PATH = "setup";
const INTEGRATIONS_PATH = "integrations";


export const settings = [
  {
    name: "User Account List",
    type: "list",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/user-account`,
  },
  {
    name: "User Account Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/user-account`,
  },
  {
    name: "Create User Account",
    type: "create",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/user-account`,
  },
  {
    name: "Edit User Account",
    type: "edit",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/user-account`,
  },
  {
    name: "Role List",
    type: "list",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/role`,
  },
  {
    name: "Role Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/role`,
  },
  {
    name: "Create Role",
    type: "create",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/role`,
  },
  {
    name: "Edit Role",
    type: "edit",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/role`,
  },
  {
    name: "Log In Approval List",
    type: "list",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/log-in-approval`,
  },
  {
    name: "Log In Approval Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${USER_MANAGEMENT_PATH}/log-in-approval`,
  },
  //email
  {
    name: "Email Account List",
    type: "create",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/account`,
  },
  {
    name: "Email Account Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/account`,
  },
  {
    name: "Create Email Account",
    type: "create",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/account`,
  },
  {
    name: "Edit Email Account",
    type: "edit",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/account`,
  },
  {
    name: "Email Template List",
    type: "list",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/template`,
  },
  {
    name: "Email Template Detail",
    type: "edit",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/template`,
  },
  {
    name: "Create Email Template",
    type: "create",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/template`,
  },
  {
    name: "Edit Email Template",
    type: "edit",
    slug: `${SETTING_PATH}/${EMAIL_PATH}/template`,
  },
  //setup
  {
    name: "Company List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/company`,
  },
  {
    name: "Company Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/company`,
  },
  {
    name: "Create Company",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/company`,
  },
  {
    name: "Edit Company",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/company`,
  },
  {
    name: "Currency List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency`,
  },
  {
    name: "Currency Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency`,
  },
  {
    name: "Create Currency",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency`,
  },
  {
    name: "Edit Currency",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency`,
  },
  {
    name: "Currency Exchange List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency-exchange`,
  },
  {
    name: "Currency Exchange Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency-exchange`,
  },
  {
    name: "Create Currency Exchange",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency-exchange`,
  },
  {
    name: "Edit Currency Exchange",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/currency-exchange`,
  },
  {
    name: "Tax List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/tax`,
  },
  {
    name: "Tax Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/tax`,
  },
  {
    name: "Create Tax",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/tax`,
  },
  {
    name: "Edit Tax",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/tax`,
  },
  {
    name: "Print Format List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/print-format`,
  },
  {
    name: "Print Format Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/print-format`,
  },
  {
    name: "Create Print Format",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/print-format`,
  },
  {
    name: "Edit Print Format",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/print-format`,
  },
  {
    name: "Report Template List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/report-template`,
  },
  {
    name: "Report Template Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/report-template`,
  },
  {
    name: "Create Report Template",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/report-template`,
  },
  {
    name: "Edit Report Template",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/report-template`,
  },
  {
    name: "No Series List List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/no-series-list`,
  },
  {
    name: "No Series List Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/no-series-list`,
  },
  {
    name: "Create No Series List",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/no-series-list`,
  },
  {
    name: "Edit No Series List",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/no-series-list`,
  },
  {
    name: "Custom Field List",
    type: "list",
    slug: `${SETTING_PATH}/${SETUP_PATH}/custom-field`,
  },
  {
    name: "Custom Field Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${SETUP_PATH}/custom-field`,
  },
  {
    name: "Create Custom Field",
    type: "create",
    slug: `${SETTING_PATH}/${SETUP_PATH}/custom-field`,
  },
  {
    name: "Edit Custom Field",
    type: "edit",
    slug: `${SETTING_PATH}/${SETUP_PATH}/custom-field`,
  },
  //integrations
  {
    name: "Integration List",
    type: "list",
    slug: `${SETTING_PATH}/${INTEGRATIONS_PATH}/integration`,
  },
  {
    name: "Integration Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${INTEGRATIONS_PATH}/integration`,
  },
  {
    name: "Create Integration",
    type: "create",
    slug: `${SETTING_PATH}/${INTEGRATIONS_PATH}/integration`,
  },
  {
    name: "Edit Integration",
    type: "edit",
    slug: `${SETTING_PATH}/${INTEGRATIONS_PATH}/integration`,
  },
  // Goal
  {
    name: "Goal List",
    type: "list",
    slug: `${SETTING_PATH}/${GOAL_PATH}/goal`,
  },
  {
    name: "Goal Detail",
    type: "detail",
    slug: `${SETTING_PATH}/${GOAL_PATH}/goal`,
  },
  {
    name: "Create Goal",
    type: "create",
    slug: `${SETTING_PATH}/${GOAL_PATH}/goal`,
  },
  {
    name: "Edit Goal",
    type: "edit",
    slug: `${SETTING_PATH}/${GOAL_PATH}/goal`,
  },
];