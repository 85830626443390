import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

// Components
import Input from "@cores/components/Input";
import Title from "@cores/components/title";
import DatePicker from "@cores/components/date-picker";
import GenderAutoComplete from "@cores/components/auto-complete/gender";
import DepartmentAutoComplete from "@cores/components/auto-complete/department";
import MaritalStatusAutoComplete from "@cores/components/auto-complete/marital-status";
import PositionAutoComplete from "@cores/components/auto-complete/position";
import EmployeeAutoComplete from "@cores/components/auto-complete/employee";
import InputPhoneNumber from "@cores/components/input-phone-number";
import WarehouseAutoComplete from "@cores/components/auto-complete/warehouse";
import UserAccountAutoComplete from "@cores/components/auto-complete/user-account";
import BranchAutoComplete from "@cores/components/auto-complete/company";
import JobTypeAutoComplete from "@cores/components/auto-complete/job-type";

// utils
import * as ModuleSlugConstants from "@/constants/modules";
import { isModulesInstalled } from "@modules/utils/value-selector";
import { EMPLOYEE_ATTENDANCE_APPROVER_SETTING_REQUEST } from "../../constants";

class GeneralInfo extends Component {

  render() {
    const { 
      editable, 
      isInventoryInstalled,
      isAttendanceInstalled,
    } = this.props;
    const branchIds = [];
    const jobTypeIds = [];
    const managerIds = [];
    const positionIds = [];
    const warehouseIds = [];
    const departmentIds = [];
    const userAccountIds = [];
    const attendanceApproverUserIds = [];
    if (editable) {
      const { 
        entity: { 
          user,
          jobType,
          manager,
          position, 
          warehouse,
          department, 
          companyBranch,
          attendanceApproverUser,
        } 
      } = this.props;
      userAccountIds.push(user && user.id);
      jobTypeIds.push(jobType && jobType.id);
      managerIds.push(manager && manager.id);
      positionIds.push(position && position.id);
      warehouseIds.push(warehouse && warehouse.id);
      departmentIds.push(department && department.id);
      branchIds.push(companyBranch && companyBranch.id);
      attendanceApproverUserIds.push(attendanceApproverUser && attendanceApproverUser.id);
    }
    return (
      <Row>
        <Col xs="12">
          <Title title="Personal Profile" />
        </Col>
        <Col md="6" xs="12">
          <DatePicker 
            required
            label="Date Of Birth"
            name="dateOfBirth"
            showFutureDates={false}  
          />
        </Col>
        <Col md="6" xs="12">
          <GenderAutoComplete />
        </Col>
        <Col md="6" xs="12">
          <MaritalStatusAutoComplete required={false} />
        </Col>
        <Col xs="12">
          <Title title="Job Profile" />
        </Col>
        <Col md="6" xs="12">
          <BranchAutoComplete 
            required={false}
            ids={branchIds}
          />
        </Col>
        <Col md="6" xs="12">
          <DepartmentAutoComplete 
            required={false}
            ids={departmentIds}
          />
        </Col>
        <Col md="6" xs="12">
          <EmployeeAutoComplete 
            required={false} 
            label="Manager" 
            name="managerId" 
            ids={managerIds}
          />
        </Col>
        <Col md="6" xs="12">
          <PositionAutoComplete 
            required={false}
            ids={positionIds}
          />
        </Col>
        <Col md="6" xs="12">
          <JobTypeAutoComplete 
            required={false}
            ids={jobTypeIds}
          />
        </Col>
        <Col md="6" xs="12">
          <DatePicker
            required
            label="Joining Date"
            name="joiningDate"
          />
        </Col>
        {isInventoryInstalled && (
          <Col sm="6" xs="12">
            <WarehouseAutoComplete 
              required={false}
              ids={warehouseIds}
            />
          </Col>
        )}
        {editable && (
          <Col md="6" xs="12">
            <InputPhoneNumber 
              required
              name="phoneNumber" 
              label="Work Phone"
            />
          </Col>
        )}
        <Col xs="12">
          <Title title="System Profile" />
        </Col>
        <Col md="6" xs="12">
          <UserAccountAutoComplete 
            required={false}
            ids={userAccountIds}
            hasEmployee={0}
          />
        </Col>
        <Col md="6" xs="12">
          <Input 
            name="fingerPrintNumber" 
            label="Finger Print Number"
          />
        </Col>
        <Col md="6" xs="12">
          <Input 
            name="telegramUsername" 
            label="Telegram Username"
          />
        </Col>
        {isAttendanceInstalled && (
          <Fragment>
            <Col xs="12">
              <Title title="Attendance Approver" />
            </Col>
            <Col sm="6" xs="12">
              <UserAccountAutoComplete 
                label="Approver"
                required={false}
                name="attendanceApproverId"
                ids={attendanceApproverUserIds}
                requestName={EMPLOYEE_ATTENDANCE_APPROVER_SETTING_REQUEST}
              />
            </Col>
          </Fragment>
        )}
      </Row>
    );
  }
}

GeneralInfo.propTypes ={
  editable: PropTypes.bool,
  entity: PropTypes.object,
  isInventoryInstalled: PropTypes.bool,
  isAttendanceInstalled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isAttendanceInstalled: isModulesInstalled(state, ModuleSlugConstants.ATTENDANCE_SLUG),
    isInventoryInstalled: isModulesInstalled(state, ModuleSlugConstants.STOCK_SLUG),
  };
};

export default connect(mapStateToProps)(GeneralInfo);