import { SET_DASHBOARD } from "../../constants/dashboard";
import { 
  DASHBOARD_SUCCESS, 
  GET_MODULES_SUCCESS, 
  WIDGET_FILTER_SUCCESS
} from "../../modules/dashboard/constants";

const initialState = {
  dashboard: [],
  modules: [],
  list: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_DASHBOARD:
    return setDashboard(state, action);
  case DASHBOARD_SUCCESS:
    return {
      ...state,
      dashboard: action.payload,
    };
  case GET_MODULES_SUCCESS:
    return {
      ...state,
      modules: action.payload,
    };
  case WIDGET_FILTER_SUCCESS:
    return {
      ...state,
      list: action.payload,
    };
  }
  return state;
}

function setDashboard(state, action) {
  const { data, requestName } = action;
  const requestObject = {
    [requestName]: data
  };
  requestObject[requestName] = data;
  return Object.assign({}, state, requestObject);
}