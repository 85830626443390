// User Management
import { READ_ROLE } from "@modules/settings/user-management/role/constants";
import { READ_USER_ACCOUNT } from "@modules/settings/user-management/user-account/constants";
import { READ_LOG_IN_APPROVAL } from "@modules/settings/user-management/log-in-approval/constants";

// Email
import { READ_EMAIL_ACCOUNT } from "@modules/settings/email/email-account/constants";
import { READ_EMAIL_TEMPLATE_CODE } from "@modules/settings/email/email-template/constants";

// Setup
import { READ_TAX } from "@modules/settings/setup/tax/constants";
import { READ_COMPANY } from "@modules/settings/setup/company/constants";
import { READ_CURRENCY } from "@modules/settings/setup/currency/constants";
import { READ_PRINT_FORMAT } from "@modules/settings/setup/print-format/constants";
import { CONFIGURATION_READ } from "@modules/settings/setup/configuration/constants";
import { READ_CUSTOMER_FIELD } from "@modules/settings/setup/custom-field/constants";
import { READ_NO_SERIES_LIST } from "@modules/settings/setup/no-series-list/constants";
import { READ_GENERAL_SETTING } from "@modules/settings/setup/general-setting/constants";
import { READ_REPORT_TEMPLATE } from "@modules/settings/setup/report-template/constants";
import { READ_CURRENCY_EXCHANGE } from "@modules/settings/setup/currency-exchange/constants";

// Integrations
import { READ_INTEGRATION } from "@modules/settings/integrations/integration/constants";

// Goal
import { READ_GOAL } from "@modules/settings/goal/goal/constants";

export default {
  sideBarTitle: "Settings",
  hasDashboard: true,
  isFixed: false,
  slug: "settings",
  display: true,
  menus: [
    {
      id: 1,
      title: "User Management",
      slug: "user-management",
      icon: "UserSwitchOutlined",
      sub: [
        {
          id: 1,
          title: "User Account",
          slug: "user-account",
          code: READ_USER_ACCOUNT,
        },
        {
          id: 2,
          title: "Role",
          slug: "role",
          code: READ_ROLE
        },
        {
          id: 3,
          title: "Log In Approval",
          slug: "log-in-approval",
          code: READ_LOG_IN_APPROVAL
        },
      ]
    },
    {
      id: 2,
      title: "Email",
      slug: "email",
      icon: "MailOutlined",
      sub: [
        {
          id: 1,
          title: "Email Account",
          slug: "account",
          code: READ_EMAIL_ACCOUNT,
        },
        {
          id: 2,
          title: "Email Template",
          slug: "template",
          code: READ_EMAIL_TEMPLATE_CODE,
        },
      ]
    },
    {
      id: 3,
      title: "Setup",
      slug: "setup",
      icon: "SettingOutlined",
      sub: [
        {
          id: 1,
          title: "General Setting",
          slug: "general-setting",
          code: READ_GENERAL_SETTING,
        },
        {
          id: 2,
          title: "Company",
          slug: "company",
          code: READ_COMPANY,
        },
        {
          id: 3,
          title: "Currency",
          slug: "currency",
          code: READ_CURRENCY,
        },
        {
          id: 4,
          title: "Currency Exchange",
          slug: "currency-exchange",
          code: READ_CURRENCY_EXCHANGE,
        },
        {
          id: 5,
          title: "Tax",
          slug: "tax",
          code: READ_TAX,
        },
        {
          id: 6,
          title: "Print Format",
          slug: "print-format",
          code: READ_PRINT_FORMAT,
        },
        {
          id: 7,
          title: "Report Template",
          slug: "report-template",
          code: READ_REPORT_TEMPLATE,
        },
        {
          id: 8,
          title: "No Series List",
          slug: "no-series-list",
          code: READ_NO_SERIES_LIST,
        },
        {
          id: 9,
          title: "Custom Field",
          slug: "custom-field",
          code: READ_CUSTOMER_FIELD,
        },
        {
          id: 10,
          title: "Configuration",
          slug: "configuration",
          code: CONFIGURATION_READ,
        },
      ]
    },
    {
      id: 4,
      title: "Integrations",
      slug: "integrations",
      icon: "InteractionOutlined",
      sub: [
        {
          id: 1,
          title: "Integration",
          slug: "integration",
          code: READ_INTEGRATION,
        },
      ]
    },
    {
      id: 5,
      title: "Goal",
      slug: "goal",
      icon: "FundOutlined",
      sub: [
        {
          id: 1,
          title: "Goal",
          slug: "goal",
          code: READ_GOAL,
        },
      ]
    }
  ]
};