import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2"; 
import styled from "styled-components";
import { Layout, Avatar, Card } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

// Utils
import "@/components/layout/header/index.less";
import { MACHINE_ID } from "@/constants/authentication";
import BackgroundImage from "@/images/background/background.svg";

const { Header } = Layout;

const HeaderWrapper = styled(Header)`
  width: 100% !important;
  background: ${props => props.isDark == true ? "#1f1f1f" : props.navBackground} !important;

  .ant-menu-item > a {
    color: ${props => props.isDark == true ? "#FFFFFF" : props.navLinkColor} !important;
  }

  .ant-menu-item-selected {
    background: ${props => props.isDark == true ? "inhertit" : tinycolor(props.navBackground).lighten().toString()} !important;
    a {
      color: ${props => props.isDark == true ? props.activeColor : props.activeColor} !important;
    }
  }

  .icon, .anticon, span {
    color: ${props => props.isDark == true ? "inhertit" :props.navLinkColor} !important;
  }

  a {
    color: ${props => props.isDark == true ? props.activeColor : props.activeColor} !important;
  }
`;

const Wrapper = styled.div`
  background-image: url(${BackgroundImage});
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center 110px;
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  margin-bottom: 0.5rem;
  color: ${props => props.isDark == true ? "#FFFFFF" : "#000000"} !important;
`;

const CardWrapper = styled(Card)`
  top: 30%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%) !important;
`;

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 500;
`;

class LogInApproval extends Component {

  render() {
    const { 
      onLogOut,
      currentUser: {
        company: {
          logoImageUrl,
          activeColor,
          navLinkColor,
          navBackground,
          activeBackground,
          name: companyName, 
        }
      }
    } = this.props;
    const machineId = localStorage.getItem(MACHINE_ID);
    const theme = localStorage.getItem("theme-name") || "light";
    return (
      <Fragment>
        <HeaderWrapper
          theme={theme}
          isDark={theme == "dark"}
          activeColor={activeColor} 
          navLinkColor={navLinkColor} 
          navBackground={navBackground} 
          activeBackground={activeBackground} 
          className="fixed-header header-padding"
        >
          <Fragment>
            <div className="d-flex align-items-center header px-2">
              <span className="text-white company-name" style={{ minWidth: "220px" }}>
                <Avatar shape="square" src={logoImageUrl} /> {companyName}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <a onClick={() => onLogOut(machineId)}> 
                <LogoutOutlined /> Log Out
              </a>
            </div>
          </Fragment>
        </HeaderWrapper>
        <Wrapper>
          <CardWrapper 
            className="w-50" 
            title="Log In Approval Required" 
          >
            <Text>
            Please ask your administrator or manager to approve your login request. 
            Once you login request is approved, you will be redirected to home page directly.
            </Text>
          </CardWrapper>
          <Footer 
            theme={theme}
            navLinkColor={navLinkColor}
            className="text-center p-3"  
          >
            <span>Copyright &copy; 2019 Developed by</span>
            <a
              target="_blank"
              className="pl-1"
              rel="noopener noreferrer"
              href=""
            >
              ISERP
            </a>
          </Footer>
        </Wrapper>
      </Fragment>
    );
  }
}

LogInApproval.propTypes = {
  onLogOut: PropTypes.func,
  currentUser: PropTypes.object,
};

export default LogInApproval;