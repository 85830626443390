import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import TreeAutoComplete from "../../tree-auto-complete";
import FormComponent from "@modules/accounting/list/chart-of-account/components/form-component";
import { getChartOfAccountAutoComplete, createChartOfAccount } from "@modules/accounting/list/chart-of-account/actions";
import { 
  CREATE_CHART_OF_ACCOUNT,
  CHART_OF_ACCOUNT_SUBMIT_RULES, 
  CHART_OF_ACCOUNT_CREATE_REQUEST,
  CHART_OF_ACCOUNT_AUTOCOMPLETE_REQUEST,
} from "@modules/accounting/list/chart-of-account/constants";
import { getChartOfAccountAutoComplete as getChartOfAccountAutoCompleteService } from "@modules/accounting/list/chart-of-account/services";

class ChartOfAccountAutoComplete extends Component {

  onNewDataSource = (dataSource, key) => {
    const { disabledParent } = this.props;
    dataSource && dataSource.map((value, index) => {
      let treeKey = `${index}`;
      if (key == null) {
        value.key = treeKey;
      } else {
        treeKey = `${key}-${index}`;
        value.key = treeKey;
      }
      value.title = `${value.chartAccountNo} - ${value.name}`;
      value.value = value.id;
      value.disabled = (Boolean(value.isSystem) == true && value.typeName == "Retained Earnings");  
      if (value.chartAccounts) {
        value.disabled = disabledParent;
        value.children = this.onNewDataSource(value.chartAccounts, treeKey);
      }
    });
    return dataSource;
  }

  render() {
    const { 
      ids,
      type,
      includes,
      extra,
      ...props
    } = this.props; 
    return (
      <TreeAutoComplete
        advanceSearch
        entity="ChartOfAccount"
        formComponent={FormComponent}
        createAction={createChartOfAccount}
        onNewDataSource={this.onNewDataSource}
        permissionCode={CREATE_CHART_OF_ACCOUNT}
        submitRules={CHART_OF_ACCOUNT_SUBMIT_RULES}
        service={getChartOfAccountAutoCompleteService}
        createRequestName={CHART_OF_ACCOUNT_CREATE_REQUEST}
        optionRender={values => `${values.chartAccountNo} - ${values.name}`}
        extra={{
          ids,
          type,
          includes,
          ...extra
        }}
        {...props}
      />
    );
  }
}

ChartOfAccountAutoComplete.propTypes = {
  ids: PropTypes.any,
  type: PropTypes.array,
  includes: PropTypes.array,
  extra: PropTypes.object,
  disabledParent: PropTypes.bool,
};

ChartOfAccountAutoComplete.defaultProps = {
  label: "Chart of Account",
  name: "chartAccountId",
  required: true,
  disabledParent: true,
  action: getChartOfAccountAutoComplete,
  requestName: CHART_OF_ACCOUNT_AUTOCOMPLETE_REQUEST,
};

export default ChartOfAccountAutoComplete;