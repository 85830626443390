export const SALE_INVOICE_LIST_REQUEST = "SALE_INVOICE_LIST_REQUEST";
export const SALE_INVOICE_LIST_SUCCESS = "SALE_INVOICE_LIST_REQUEST_SUCCESS";
export const SALE_INVOICE_AUTOCOMPLETE_REQUEST = "SALE_INVOICE_AUTOCOMPLETE_REQUEST";
export const SALE_INVOICE_AUTOCOMPLETE_SUCCESS = "SALE_INVOICE_AUTOCOMPLETE_SUCCESS";
export const SALE_INVOICE_DETAIL_REQUEST = "SALE_INVOICE_DETAIL_REQUEST";
export const SALE_INVOICE_CREATE_REQUEST = "SALE_INVOICE_CREATE_REQUEST";
export const SALE_INVOICE_PAY_BATCH_LIST_REQUEST = "SALE_INVOICE_PAY_BATCH_LIST_REQUEST";
export const SALE_INVOICE_PAY_BATCH_LIST_SUCCESS = "SALE_INVOICE_PAY_BATCH_LIST_REQUEST_SUCCESS";

export const SALE_INVOICE_HISTORY_LIST_REQUEST = "SALE_INVOICE_HISTORY_LIST_REQUEST";
export const SALE_INVOICE_HISTORY_LIST_SUCCESS = "SALE_INVOICE_HISTORY_LIST_REQUEST_SUCCESS";

export const SALE_INVOICE_ACCOUNT_STATEMENT_LIST_REQUEST = "SALE_INVOICE_ACCOUNT_STATEMENT_LIST_REQUEST";
export const SALE_INVOICE_ACCOUNT_STATEMENT_LIST_SUCCESS = "SALE_INVOICE_ACCOUNT_STATEMENT_LIST_REQUEST_SUCCESS";

export const DETAIL_SALE_LIST_REQUEST = "DETAIL_SALE_LIST_REQUEST";
export const DETAIL_SALE_LIST_SUCCESS = "DETAIL_SALE_LIST_REQUEST_SUCCESS";

export const SALE_INVOICE_EDIT_REQUEST = "SALE_INVOICE_EDIT_REQUEST";
export const SALE_INVOICE_GET_SALE_INCENTIVE_REQUEST = "SALE_INVOICE_GET_SALE_INCENTIVE_REQUEST";

export const SALE_INVOICE_STATUS_PENDING = 1;
export const SALE_INVOICE_STATUS_PENDING_TITLE = "Pending";
export const SALE_INVOICE_STATUS_PAID = 2;
export const SALE_INVOICE_STATUS_PAID_TITLE = "Paid";
export const SALE_INVOICE_STATUS_CREDIT = 3;
export const SALE_INVOICE_STATUS_CREDIT_TITLE = "Credit";
export const SALE_INVOICE_STATUS_VOIDED = 4;
export const SALE_INVOICE_STATUS_VOIDED_TITLE = "Voided";
export const SALE_INVOICE_STATUS_RETURNED = 5;
export const SALE_INVOICE_STATUS_RETURNED_TITLE = "Returned";
export const SALE_INVOICE_STATUS_CANCELLED = 6;
export const SALE_INVOICE_STATUS_CANCELLED_TITLE = "Cancelled";
export const SALE_INVOICE_STATUS_CREDIT_NOTE = 7;
export const SALE_INVOICE_STATUS_CREDIT_NOTE_TITLE = "Credit Note";
export const SALE_INVOICE_STATUS_DELIVERY = 8;
export const SALE_INVOICE_STATUS_DELIVERY_TITLE = "Delivery";
export const SALE_INVOICE_STATUS_EXCHANGED = 9;
export const SALE_INVOICE_STATUS_EXCHANGED_TITLE = "Exchanged";

export const SALE_INVOICE_STATUS_DATA_SOURCE = [
  {
    id: SALE_INVOICE_STATUS_PENDING,
    name: SALE_INVOICE_STATUS_PENDING_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_PAID,
    name: SALE_INVOICE_STATUS_PAID_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_CREDIT,
    name: SALE_INVOICE_STATUS_CREDIT_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_VOIDED,
    name: SALE_INVOICE_STATUS_VOIDED_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_RETURNED,
    name: SALE_INVOICE_STATUS_RETURNED_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_CANCELLED,
    name: SALE_INVOICE_STATUS_CANCELLED_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_CREDIT_NOTE,
    name: SALE_INVOICE_STATUS_CREDIT_NOTE_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_DELIVERY,
    name: SALE_INVOICE_STATUS_DELIVERY_TITLE,
  },
  {
    id: SALE_INVOICE_STATUS_EXCHANGED,
    name: SALE_INVOICE_STATUS_EXCHANGED_TITLE,
  }
];

export const READ_SALE_INVOICE = 198000;
export const CREATE_SALE_INVOICE = 198001;
export const UPDATE_SALE_INVOICE = 198002;
export const ARCHIVE_SALE_INVOICE = 198004;
export const UNARCHIVE_SALE_INVOICE = 198005;
export const DELETE_SALE_INVOICE = 198003;
export const PAY_SALE_INVOICE = 198011;
export const PAY_BATCH_INVOICE_CODE = 198012;
export const CREDIT_SALE_INVOICE = 198013;
export const VOID_SALE_INVOICE = 198014;
export const RETURN_INVOICE = 198015;
export const CANCEL_SALE_INVOICE = 198016;
export const PRINT_SALE_INVOICE = 198007;
export const VIEW_CUSTOMER_COMMISSION_INVOICE = 198023;
export const CUSTOMER_COMMISSION_INVOICE = 195028;
export const ACKNOWLEDGE_INVOICE = 198024;
export const UNACKNOWLEDGE_INVOICE = 198025;
export const COMPLETE_INVOICE = 198026;
export const EXCHANGE_INVOICE = 198028;
export const VIEW_ALL_INVOICE = 198033;
export const UPDATE_EXCHANGE_RATE = 198034;
export const DELAY_INVOICE = 198039;
export const ASSIGN_VAT_INVOICE = 198043;
export const VIEW_SALE_TEAM_INVOICE = 198044;

export const SALE_INVOICE_PAY_REQUEST = "SALE_INVOICE_PAY_REQUEST";
export const SALE_INVOICE_CANCEL_REQUEST = "SALE_INVOICE_CANCEL_REQUEST";
export const SALE_INVOICE_VOID_REQUEST = "SALE_INVOICE_VOID_REQUEST";
export const SALE_INVOICE_PAY_BATCH_REQUEST = "SALE_INVOICE_PAY_BATCH_REQUEST";
export const SALE_INVOICE_CREDIT_REQUEST = "SALE_INVOICE_CREDIT_REQUEST";
export const SALE_INVOICE_RETURN_REQUEST = "SALE_INVOICE_RETURN_REQUEST";
export const SALE_INVOICE_EXCHANGE_REQUEST = "SALE_INVOICE_EXCHANGE_REQUEST";
export const SALE_INVOICE_DELAY_REQUEST = "SALE_INVOICE_DELAY_REQUEST";
export const SALE_INVOICE_DELAY_BATCH_REQUEST = "SALE_INVOICE_DELAY_BATCH_REQUEST";
export const SALE_INVOICE_ASSIGN_VAT_REQUEST = "SALE_INVOICE_ASSIGN_VAT_REQUEST";
export const SALE_INVOICE_DELETE_REQUEST = "SALE_INVOICE_DELETE_REQUEST";

export const SALE_INVOICE_PAY_FORM_NAME = "paySaleInvoice";
export const SALE_INVOICE_PAY_BATCH_FORM_NAME = "payBatchSaleInvoice";
export const SALE_INVOICE_CREDIT_FORM_NAME = "creditSaleInvoice";
export const SALE_INVOICE_VOID_FORM_NAME = "voidSaleInvoice";
export const CREATE_DELIVERY_ORDER_FORM_NAME = "createDeliveryOrderForm";
export const SALE_INVOICE_ITEM_FILED_NAME = "invoiceItems";
export const SALE_INVOICE_CREATE_FORM_NAME = "createSaleInvoice";
export const SALE_INVOICE_EDIT_FORM_NAME = "editSaleInvoice";
export const SALE_INVOICE_UPDATE_DESCRIPTION_FORM_NAME = "updateDescriptionForm";
export const SALE_INVOICE_RETURN_FORM_NAME = "returnSaleInvoice";
export const SALE_INVOICE_EXCHANGE_FORM_NAME = "exchangeSaleInvoice";
export const SALE_INVOICE_RETURN_ITEMS_FIELD_NAME = "invoiceItems";
export const INVOICE_RETURN_ITEM_SERIAL_FORM_NAME = "invoiceItemsSerials";
export const INVOICE_CANCEL_FORM_NAME = "invoiceCancel";
export const INVOICE_DELAY_FORM_NAME = "delayInvoice";
export const INVOICE_DELAY_BATCH_FORM_NAME = "delayBatchInvoice";

export const SALE_INVOICE_ITEM_SCAN_SERIAL_FORM_NAME = "scanSerial";
export const SALE_INVOICE_UPDATE_EXCHANGE_RATE_FORM_NAME = "updateExchangeRate";

export const SALE_INVOICE_SERIAL_STATUS_RETURNED = -1;
export const SALE_INVOICE_SERIAL_STATUS_RETURNED_TITLE = "Returned";
export const SALE_INVOICE_SERIAL_STATUS_PENDING = 1;
export const SALE_INVOICE_SERIAL_STATUS_PENDING_TITLE = "Pending";

export const SALE_INVOICE_ITEM_SCAN_SERIAL_SUBMIT_RULES = {
  serialNumber: {
    required: true,
  },
};

export const VOID_SALE_INVOICE_TYPE_CUSTOMER_DEPOSIT = 1;
export const VOID_SALE_INVOICE_TYPE_CUSTOMER_DEPOSIT_TITLE = "Customer Deposit";
export const VOID_SALE_INVOICE_TYPE_PAYMENT = 2;
export const VOID_SALE_INVOICE_TYPE_PAYMENT_TITLE = "Payment";
export const VOID_SALE_INVOICE_TYPE_CREDIT = 3;
export const VOID_SALE_INVOICE_TYPE_CREDIT_TITLE = "Credit";

export const VOID_SALE_INVOICE_DATA_SOURCE = [
  {
    id: VOID_SALE_INVOICE_TYPE_CUSTOMER_DEPOSIT,
    name: VOID_SALE_INVOICE_TYPE_CUSTOMER_DEPOSIT_TITLE,
  },
  {
    id: VOID_SALE_INVOICE_TYPE_PAYMENT,
    name: VOID_SALE_INVOICE_TYPE_PAYMENT_TITLE,
  },
  {
    id: VOID_SALE_INVOICE_TYPE_CREDIT,
    name: VOID_SALE_INVOICE_TYPE_CREDIT_TITLE 
  }
];

export const SALE_INVOICE_TYPE_PAY = 1;
export const SALE_INVOICE_TYPE_PAY_TITLE = "Pay Invoice";
export const SALE_INVOICE_TYPE_CREDIT = 2;
export const SALE_INVOICE_TYPE_CREDIT_TITLE = "Credit Invoice";
export const SALE_INVOICE_TYPE_VOID = 3;
export const SALE_INVOICE_TYPE_VOID_TITLE = "Void Invoice";
export const SALE_INVOICE_TYPE_CREATE_DELIVERY_ORDER = 4;
export const SALE_INVOICE_TYPE_CREATE_DELIVERY_ORDER_TITLE = "Create Delivery Order";
export const SALE_INVOICE_TYPE_EXCHANGE_RATE = 5;
export const SALE_INVOICE_TYPE_EXCHANGE_RATE_TITLE = "Update Exchange Rate";
export const SALE_INVOICE_TYPE_CANCEL = 6;
export const SALE_INVOICE_TYPE_CANCEL_TITLE = "Cancel";
export const SALE_INVOICE_TYPE_DELAY = 7;
export const SALE_INVOICE_TYPE_DELAY_TITLE = "Delay";

export const PAY_SALE_INVOICE_BY_PASS_ERROR_CODE = -618; 
export const PAY_SALE_INVOICE_BY_PASS_ERROR_MESSAGE = "Customer does not have enough credit balance";
export const PAY_SALE_INVOICE_BY_PASS_ERROR_DESCRIPTION = "You can continue this operation by bypassing but you need to upload attachment to confirm this operation.";

export const PAY_BATCH_SUBMIT_RULES_INVOICE = {
  customerId: {
    required: true,
  },
  invoiceIds: {
    required: true,
    type: "array",
    message: "Please select at least 1 invoice"
  },
};

export const EXCHANGE_RATE_SUBMIT_RULE = {
  exchangeRate: {
    required: true,
  },
};

export const SALE_INVOICE_SUBMIT_RULES = {
  invoiceDate: {
    required: true,
  },
  dueDate: {
    required: true,
  },
  currencyId: {
    required: true,
  },
  customerId: {
    required: true,
  },
  paymentTermId: {
    required: true,
  },
  invoiceItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "itemId"
      },
      {
        name: "price",
        type: "number",
      },
      {
        name: "quantity",
        type: "number",
      },
    ],
  },
};

export const SALE_INVOICE_EXCHANGE_REMOVE_ITEMS_FIELD_NAME = "removeInvoiceItems";
export const SALE_INVOICE_EXCHANGE_ADD_ITEMS_FIELD_NAME = "addInvoiceItems";

export const SALE_INVOICE_EXCHANGE_TYPE_REMOVE_ITEM_SERIAL = 1;
export const SALE_INVOICE_EXCHANGE_TYPE_ADD_ITEM_SERIAL = 2;

