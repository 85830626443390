import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/role/v1";
const PERMISSION_GROUP_URl = "/api/permission-group/v1";

export function getRole(values) {
  const url = `${API_URL}/list`;
  return GETList(url, values);
}

export function getRoleAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function createRole(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getRoleDetail(id) {
  return GETDetail(API_URL, id);
}

export function editRole(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveRole(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveRole(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteRole(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function getPermissionGroup() {
  const url = `${PERMISSION_GROUP_URl}/list`;
  return GETWithToken(url);
}

export function getPermissionGroupByRoleId(id) {
  const url = `${PERMISSION_GROUP_URl}/${id}/role`;
  return GETWithToken(url);
}

export function getRoleDetailWithPermissions(id) {
  const url = `${API_URL}/${id}?includePermission=true`;
  return GETWithToken(url);
}