import { 
  NoSeriesListSchema,
  NoSeriesListArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as NoSeriesListConstants from "../constants";

const path = "/settings/setup/no-series-list";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getNoSeriesList,
    schema: NoSeriesListArraySchema,
    requestName: NoSeriesListConstants.NO_SERIES_LIST_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createNoSeriesList = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createNoSeriesList,
    requestName: NoSeriesListConstants.NO_SERIES_LIST_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getNoSeriesListAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getNoSeriesListAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getNoSeriesListDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getNoSeriesListDetail,
    requestName: NoSeriesListConstants.NO_SERIES_LIST_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: NoSeriesListSchema,
  };
  return createDetailAction(options, id);
};

export const editNoSeriesList = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editNoSeriesList,
    requestName: NoSeriesListConstants.NO_SERIES_LIST_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "noSeriesLists",
    schema: NoSeriesListSchema,
    successMessage: "No series list is updated successfully",
  };
  return createEditAction(options, values);
};