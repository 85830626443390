import { 
  PositionSchema,
  PositionArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as PositionConstants from "../constants";

const path = "/human-resource/employee-directory/position";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPosition,
    schema: PositionArraySchema,
    requestName: PositionConstants.POSITION_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createPosition = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createPosition,
    requestName: PositionConstants.POSITION_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getPositionAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getPositionAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getPositionDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPositionDetail,
    requestName: PositionConstants.POSITION_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: PositionSchema,
  };
  return createDetailAction(options, id);
};

export const editPosition = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editPosition,
    requestName: PositionConstants.POSITION_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "positions",
    schema: PositionSchema,
    successMessage: "Position is updated successfully",
  };
  return createEditAction(options, values);
};

export const archivePosition = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archivePosition,
    requestName: PositionConstants.POSITION_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Position is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deletePosition = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deletePosition,
    requestName: PositionConstants.POSITION_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Position is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchivePosition = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchivePosition,
    requestName: PositionConstants.POSITION_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Position is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};