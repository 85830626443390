import { 
  SalaryStructureSchema,
  SalaryStructureArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as SalaryStructureConstants from "../constants";

const path = "/human-resource/payroll/salary-structure";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getSalaryStructure,
    schema: SalaryStructureArraySchema,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createSalaryStructure = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createSalaryStructure,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getSalaryStructureAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getSalaryStructureAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getSalaryStructureDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getSalaryStructureDetail,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: SalaryStructureSchema,
  };
  return createDetailAction(options, id);
};

export const editSalaryStructure = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editSalaryStructure,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "salaryStructures",
    schema: SalaryStructureSchema,
    successMessage: "Salary structure is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveSalaryStructure = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveSalaryStructure,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Salary structure is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteSalaryStructure = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteSalaryStructure,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Salary structure is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveSalaryStructure = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveSalaryStructure,
    requestName: SalaryStructureConstants.SALARY_STRUCTURE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Salary structure is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};