export const BANK_LIST_REQUEST = "BANK_LIST_REQUEST"; 
export const BANK_LIST_SUCCESS = "BANK_LIST_REQUEST_SUCCESS";
export const BANK_CREATE_REQUEST = "BANK_CREATE_REQUEST"; 
export const BANK_DETAIL_REQUEST = "BANK_DETAIL_REQUEST"; 
export const BANK_ARCHIVE_REQUEST = "BANK_ARCHIVE_REQUEST"; 
export const BANK_DELETE_REQUEST = "BANK_DELETE_REQUEST"; 
export const BANK_UNARCHIVE_REQUEST = "BANK_UNARCHIVE_REQUEST"; 
export const BANK_EDIT_REQUEST = "BANK_EDIT_REQUEST"; 
export const BANK_CREATE_FORM_NAME = "createBank"; 
export const BANK_EDIT_FORM_NAME = "editBank"; 
export const BANK_AUTOCOMPLETE_REQUEST = "BANK_AUTOCOMPLETE_REQUEST";
export const BANK_AUTOCOMPLETE_SUCCESS = "BANK_AUTOCOMPLETE_SUCCESS";
export const BANK_SUMMARY_REQUEST = "BANK_SUMMARY_REQUEST";
export const BANK_SUMMARY_SUCCESS = "BANK_SUMMARY_SUCCESS";
export const BANK_HISTORY_NEXT_PAGE = "BANK_HISTORY_NEXT_PAGE";
export const BANK_HISTORY_SUCCESS = "BANK_HISTORY_SUCCESS";
export const BANK_HISTORY_REQUEST = "BANK_HISTORY_REQUEST";

export const CUSTOMER_COMMISSION_REQUEST = "CUSTOMER_COMMISSION_REQUEST";
export const CUSTOMER_COMMISSION_SUCCESS = "CUSTOMER_COMMISSION_SUCCESS";

export const CASH_ON_HAND_DEPOSIT_FORM_NAME = "cashOnHandForm";
export const CASH_ON_HAND_DEPOSIT_FIELD_NAME = "cashes";

export const CASH_ON_HAND_DEPOSIT_REQUEST = "CASH_ON_HAND_DEPOSIT_REQUEST";
export const CASH_ON_HAND_DEPOSIT_SUCCESS = "CASH_ON_HAND_DEPOSIT_SUCCESS";
export const CASH_ON_HAND_DEPOSIT_UPDATE_REQUEST = "CASH_ON_HAND_DEPOSIT_UPDATE_REQUEST";

export const BANK_DEPOSIT_OR_WITHDRAW_REQUEST = "BANK_DEPOSIT_OR_WITHDRAW_REQUEST";

export const BANK_SUBMIT_RULES = (values) => {
  const { balance, editable } = values;
  return {
    bankName: {
      required: true,
    },
    bankAccountNumber: {
      required: true,
    },
    registerDate: {
      required: true,
    },
    checkChartAccountId: {
      required: true,
    },
    cashChartAccountId: {
      required: true,
    },
    creditChartAccountId: {
      required: editable != true && balance > 0
    }
  };
};


export const bankWithdrawSubmitRules = (values) => {
  const { paymentType, amount } = values;
  const isCheque = paymentType == 2;
  const rules =  {
    paymentType: {
      required: true,
    },
    amount: {
      required: true,
      min: 0,
    },
    debitChartAccountId: {
      required: true,
    },
    description: {
      required: true,
    },
    cheque: {
      type: "object",
      chequeNumber: {
        required: isCheque
      },
      issueDate: {
        required: isCheque
      },
      bankId: {
        required: isCheque
      },
      payTo: {
        required: isCheque
      }
    },
  };
  rules.amount["compare"] = {
    name: "amount",
    message: "Amount must be greater than zero or 0.",
    condition: amount <= 0,
  };
  return rules;
};

export const BANK_DEPOSIT_OR_WITHDRAW_SUBMIT_RULES = (values) => {
  const {
    amount,
    paymentType,
  } = values;
  const isRequired = paymentType == 2;
  const rules = {
    paymentType: {
      required: true,
    },
    creditChartAccountId: {
      required: true
    },
    amount: {
      required: true,
    },
    cheque: {
      type: "object",
      chequeNumber: {
        required: isRequired,
      },
      issueDate: {
        required: isRequired,
      },
      bankName: {
        required: isRequired,
      },
      payTo: {
        required: isRequired,
      },
    },
    description: {
      required: true
    }
  };
  rules.amount["compare"] = {
    name: "amount",
    message: "Amount must be greater than zero or 0.",
    condition: amount <= 0,
  };
  return rules;
};

export const READ_BANK = 175000;
export const CREATE_BANK = 175001;
export const UPDATE_BANK = 175002;
export const ARCHIVE_BANK = 175004;
export const UNARCHIVE_BANK = 175005;
export const DELETE_BANK = 175003;
export const DEPOSIT_BANK = 175011;
export const WITHDRAW_BANK = 175012;
export const JOURNAL_DEPOSIT_BANK = 175015;
export const READ_BANK_LOG = 176000;

export const BANK_TRANSFER_MODAL_TYPE = 1;
export const BANK_TRANSFER_MODAL_TITLE = "Transfer";
export const BANK_DEPOSIT_MODAL_TYPE = 2;
export const BANK_DEPOSIT_MODAL_TITLE = "Deposit";
export const CASH_ON_HAND_DEPOSIT_MODAL_TYPE = 3;
export const CASH_ON_HAND_DEPOSIT_MODAL_TITLE = "Cash On Hand Deposit";
export const BANK_WITHDRAW_MODAL_TYPE = 4;
export const BANK_WITHDRAW_MODAL_TITLE = "Withdraw";

export const BANK_HISTORY_TYPE_DEPOSIT = 1;
export const BANK_HISTORY_TYPE_WITHDRAW = 2;
export const BANK_HISTORY_TYPE_TRANSFER = 3;