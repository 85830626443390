export const CUSTOMER_REFUND_LIST_REQUEST = "CUSTOMER_REFUND_LIST_REQUEST";
export const CUSTOMER_REFUND_LIST_SUCCESS = "CUSTOMER_REFUND_LIST_REQUEST_SUCCESS";
export const CUSTOMER_REFUND_AUTOCOMPLETE_REQUEST = "CUSTOMER_REFUND_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_REFUND_AUTOCOMPLETE_SUCCESS = "CUSTOMER_REFUND_AUTOCOMPLETE_SUCCESS";
export const CUSTOMER_REFUND_CREATE_REQUEST = "CUSTOMER_REFUND_CREATE_REQUEST";
export const CUSTOMER_REFUND_DETAIL_REQUEST = "CUSTOMER_REFUND_DETAIL_REQUEST";
export const CUSTOMER_REFUND_ARCHIVE_REQUEST = "CUSTOMER_REFUND_ARCHIVE_REQUEST";
export const CUSTOMER_REFUND_DELETE_REQUEST = "CUSTOMER_REFUND_DELETE_REQUEST";
export const CUSTOMER_REFUND_UNARCHIVE_REQUEST = "CUSTOMER_REFUND_UNARCHIVE_REQUEST";
export const CUSTOMER_REFUND_EDIT_REQUEST = "CUSTOMER_REFUND_EDIT_REQUEST";
export const CUSTOMER_REFUND_CREATE_FORM_NAME = "createCustomerRefund";
export const CUSTOMER_REFUND_EDIT_FORM_NAME = "editCustomerRefund";
export const CUSTOMER_REFUND_POST_FORM_NAME = "postCustomerRefund";

export const CUSTOMER_REFUND_HISTORY_LIST_REQUEST = "CUSTOMER_REFUND_LIST_REQUEST";
export const CUSTOMER_REFUND_HISTORY_LIST_SUCCESS = "CUSTOMER_REFUND_LIST_SUCCESS";

export const READ_CUSTOMER_REFUND = 182000;
export const CREATE_CUSTOMER_REFUND = 182001;
export const UPDATE_CUSTOMER_REFUND = 182002;
export const DELETE_CUSTOMER_REFUND = 182003;
export const ARCHIVE_CUSTOMER_REFUND = 182004;
export const UNARCHIVE_CUSTOMER_REFUND = 182005;
export const POST_CUSTOMER_REFUND = 182011;
export const PRINT_CUSTOMER_REFUND = 182007;
export const ACKNOWLEDGE_CUSTOMER_REFUND = 182012;
export const UNACKNOWLEDGE_CUSTOMER_REFUND = 182013;

export const CUSTOMER_REFUND_POST_REQUEST = "CUSTOMER_REFUND_POST_REQUEST";

export const CUSTOMER_REFUND_STATUS_DRAFT = 1;
export const CUSTOMER_REFUND_STATUS_DRAFT_TITLE = "Draft";
export const CUSTOMER_REFUND_STATUS_POSTED = 2;
export const CUSTOMER_REFUND_STATUS_POSTED_TITLE = "Posted";

export const CUSTOMER_REFUND_STATUS = [
  {
    id: CUSTOMER_REFUND_STATUS_DRAFT,
    name: CUSTOMER_REFUND_STATUS_DRAFT_TITLE
  },
  {
    id: CUSTOMER_REFUND_STATUS_POSTED,
    name: CUSTOMER_REFUND_STATUS_POSTED_TITLE,
  },
];