export default {
  "dashboard": "ទំព័រដើម",
  "hr": "ធនធានមនុស្ស",
  "inventory": "សារពើភ័ណ្ឌ",
  "delivery": "ដឹកជញ្ជូន",
  "accounting": "គណនេយ្យ",
  "purchase": "បញ្ជាទិញ",
  "sale": "លក់",
  "apps": "កម្មវិធី",
  "website": "គេហទំព័រ",
  "pos": "ភីអូអេស",
  "reports": "របាយការណ៍",
  "settings": "ការកំណត់",
};
