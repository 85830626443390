import { normalize } from "normalizr";
import { mergeEntities } from "./entity";
import * as service from "@/services/notification";
import * as Constants from "@/constants/notification";
import { RecipientArraySchema } from "@/schemas/recipients";
import { NotificationArraySchema, NotificationSchema } from "@/schemas/notification";
import { createDetailAction, createListAction, createListWithSeeMoreAction } from "@/modules/utils/action-creator";

export const getNotificationUnreadCount = () => (dispatch, getState) => {

  const onSuccess = (count) => {
    return dispatch(
      { 
        count,
        type: Constants.NOTIFICATION_UNREAD_COUNT_SUCCESS,
      }
    );
  };

  const options = {
    serviceMethod: service.getNotificationUnreadCount,
    requestName: Constants.NOTIFICATION_UNREAD_COUNT_REQUEST,
    dispatch,
    getState,
    onSuccess: onSuccess,
  };

  return createDetailAction(options);
};

export const getNotification = (page) => (dispatch, getState) => {

  const options = {
    serviceMethod: service.getNotification,
    schema: NotificationArraySchema,
    requestName: Constants.NOTIFICATION_LIST_REQUEST,
    dispatch,
    getState,
  };
  
  return createListAction(options, page);
};

export const markAllRead = () => (dispatch, getState) => {

  const successAction = () => {
    return { 
      type: Constants.NOTIFICATION_MARK_ALL_READ_SUCCESS
    };
  };

  const options = {
    serviceMethod: service.markAllRead,
    requestName: Constants.NOTIFICATION_MARK_ALL_READ_REQUEST,
    dispatch,
    getState,
    schema: RecipientArraySchema,
    successAction,
  };

  return createDetailAction(options);
};

export const notificationRead = (notificationIds) => (dispatch, getState) => {
  
  const successAction = () => {
    return { 
      type: Constants.NOTIFICATION_READ_SUCCESS,
    };
  };

  const options = {
    serviceMethod: service.notificationRead,
    requestName: Constants.NOTIFICATION_READ_REQUEST,
    dispatch,
    getState,
    entityName: "recipients",
    schema: RecipientArraySchema,
    successAction,
  };

  return createDetailAction(options, notificationIds);
};

export const didReceiveNotification = (notification) => (dispatch) => {
  const successAction = (normalized) => {
    return {
      type: Constants.NOTIFICATION_DID_RECEIVE,
      notification: normalized.result
    };
  };
  const normalized = normalize(notification, NotificationSchema);
  dispatch(mergeEntities(normalized.entities));
  dispatch(successAction(normalized));
};

export const getNotificationWithSeeMore = (page) => (dispatch, getState) => {
  const onSuccess = (payload) => {
    return {
      type: Constants.NOTIFICATION_LIST_SUCCESS,
      payload,
    };
  };

  const onNextPage = (payload) => {
    return {
      type: Constants.NOTIFICATION_LIST_NEXT_PAGE,
      payload,
    };
  };

  const options = {
    serviceMethod: service.getNotificationWithSeeMore,
    schema: NotificationArraySchema,
    requestName: Constants.NOTIFICATION_LIST_REQUEST,
    dispatch,
    getState,
    getDataSuccess: onSuccess,
    getDataSuccessAddArray: onNextPage,
    multiplePayloads: true,
  };
  return createListWithSeeMoreAction(options, {page});
};