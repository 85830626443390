import React from "react";
import { Row, Col } from "reactstrap";

class TimeClock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleTimeString(),
    };
  }

  tick = () => {
    this.setState({
      time: new Date().toLocaleTimeString()
    });
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { time } = this.state;
    return (
      <Row>
        <Col md="12" sm="12" xs="12">
          <h3 className="text-center">
            {time}
          </h3>
        </Col>
      </Row>
    );
  }
}
export default TimeClock;