import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getWarehouseAutoComplete , createWarehouse } from "@modules/inventory/stock/warehouse/actions";
import FormComponent from "@modules/inventory/stock/warehouse/components/form-component";
import { isAccountingInstalled, getItemSetting } from "@modules/utils/value-selector";

// Constant 
import {
  WAREHOUSE_AUTOCOMPLETE_REQUEST,
  CREATE_WAREHOUSE,
  WAREHOUSE_CREATE_REQUEST
} from "@modules/inventory/stock/warehouse/constants";
import { warehouseSubmitRules } from "@modules/inventory/stock/warehouse/helper";

class WarehouseAutoComplete extends Component {

  beforeInitialValues = () => {
    const { isAccountingInstalled, defaultWarehouseChartAccount } = this.props;
    const values = {};
    values.stockInApproval = false;
    values.stockOutApproval = false;
    values.stockTransferApproval = false;
    values.stockAdjustmentApproval = false;
    values.isRequiredChartAccount = isAccountingInstalled;
    if (isAccountingInstalled) {
      values.chartAccountId = defaultWarehouseChartAccount && defaultWarehouseChartAccount.id;
    }
    return values;
  }

  renderFormComponent = (props) => {
    const { isAccountingInstalled, defaultWarehouseChartAccount } = this.props;
    return (
      <FormComponent 
        isAccountingInstalled={isAccountingInstalled}
        chartAccountId={defaultWarehouseChartAccount && defaultWarehouseChartAccount.id}
        {...props}
      />
    );
  }

  render() {
    const { 
      ids,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        entity="Warehouse"
        createAction={createWarehouse}
        permissionCode={CREATE_WAREHOUSE}
        submitRules={warehouseSubmitRules}
        formComponent={this.renderFormComponent}
        initialValues={this.beforeInitialValues()}
        createRequestName={WAREHOUSE_CREATE_REQUEST}
        extra={{
          ids,
        }}
        {...props}
      />
    );
  }
}

WarehouseAutoComplete.propTypes = {
  ids: PropTypes.array,
  isAccountingInstalled: PropTypes.bool,
  defaultWarehouseChartAccount: PropTypes.object,
};

WarehouseAutoComplete.defaultProps = {
  label: "Warehouse",
  name: "warehouseId",
  required: true,
  action: getWarehouseAutoComplete,
  requestName: WAREHOUSE_AUTOCOMPLETE_REQUEST,
};

const mapStateTopProps = (state) => {
  return {
    isAccountingInstalled: isAccountingInstalled(state),
    defaultWarehouseChartAccount: getItemSetting(state, "warehouseChartAccount"),
  };
};

export default connect(mapStateTopProps)(WarehouseAutoComplete);