/* eslint-disable no-unused-vars */
// Libs
import React, { Component, Fragment, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

// import IdleTimer from "react-idle-timer";
import Favicon from "react-favicon";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { notification as Notification, Button, BackTop } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";

// Components
import Async from "@/components/async";
import Layout from "@/components/layout";
import Loading from "@/components/loading";
import NoPermission from "@/components/no-permission";
import LogInApproval from "@/components/log-in-approval";
import PageNotFound from "@cores/components/page-not-found";
import LockScreen from "@/components/authentication/lock-screen";

// Action
import { createSubmitAction } from "@/helpers";
import { logOut } from "@/actions/authentication";
import { getCurrentUser } from "@/actions/current-user";
import { didReceiveNotification } from "@/actions/notification";
import { loginUserPasswordIfLockScreen } from "@/actions/authentication";

// Value Selector 
import { history } from "@/shared/utils/history";
import * as valueSelectors from "@/modules/utils/value-selector";
import { getNotificationMetaData } from "@/helpers/notification";

// Constants
import { modalConfirmDialog } from "@modules/helpers";
import { CURRENT_USER_REQUEST } from "@/constants/current-user";
import { modalWarningLoginBeforeIsOverAndLogOut } from "@modules/helpers";
import { DEFAULT_DISPLAY_TIME_FORMAT } from "@/constants/default-constants";
import { LOGIN_TYPE, USER_LAST_ACCESS, LOGIN_REQUEST_RULES, MACHINE_ID } from "@/constants/authentication";
import { SocketIOProvider, useSocketIO } from "../context/SocketIOContext";
import { LayoutProvider } from "src/context/layout-visible";

const AsyncDashboard = Async({
  loader: () => import("../modules/dashboard")
});

const AsyncHumanResource = Async({
  loader: () => import("../modules/hr")
});

const AsyncInventory = Async({
  loader: () => import("../modules/inventory")
});

const AsyncDelivery = Async({
  loader: () => import("../modules/delivery")
});

const AsyncPurchase = Async({
  loader: () => import("../modules/purchase")
});

const AsyncAccounting = Async({
  loader: () => import("../modules/accounting")
});

const AsyncSale = Async({
  loader: () => import("../modules/sale")
});

const AsyncSettings = Async({
  loader: () => import("../modules/settings")
});

const AsyncReports = Async({
  loader: () => import("../modules/reports")
});

const AsyncSyncItem = Async({
  loader: () => import("../modules/sync-item")
});

const AsyncProfile = Async({
  loader: () => import("../modules/user-profile")
});

const AsyncPointOfSale = Async({
  loader: () => import("../modules/point-of-sale")
});

const AsyncApp = Async({
  loader: () => import("../modules/apps")
});

const AsyncNotificationList = Async({
  loader: () => import("../modules/notification-list")
});

const AsyncWebsite = Async({
  loader: () => import("../modules/website")
});


function NewContainer(props) {
  const [tracker, setTracker] = useState(null);
  const machineId = localStorage.getItem(MACHINE_ID);
  // Selectors
  const company = useSelector(valueSelectors.getDefaultCompany);
  const currentUser = useSelector(valueSelectors.getCurrentUser);
  const pageTitle = (page) => {
    const title = `${page} | ${company.name}`;
    document.title = title;
  };
  const loading = useSelector(state => valueSelectors.getLoading(state, CURRENT_USER_REQUEST));
  // Actions
  const dispatch = useDispatch();
  const logOutAction = () => dispatch(logOut(machineId));
  const getCurrentUserAction = () => dispatch(getCurrentUser(machineId));
  const didReceiveNotificationAction = (notification) => dispatch(didReceiveNotification(notification));

  const renderNotificationDetailButton = (path, notificationKey) => {
    return (
      <a onClick={() => {
        history.push(path);
        Notification.close(notificationKey);
      }}>
        See detail
      </a>
    );
  };

  const { socket: io } = useSocketIO();
  const setupSocket = () => {
    io.on("connect", function () {
      console.log("SOCKET: Connected to server");
    });
    Notification.config({
      placement: "bottomLeft",
      duration: 3
    });
    io.on(`login:approved:${machineId}`, () => {
      location.reload();
    });
    io.on("notification", (notification) => {
      const key = `open${Date.now()}`;
      const displayName = `${notification.creator.firstName} ${notification.creator.lastName}`;
      const meta = getNotificationMetaData(notification);
      const description = `${displayName} ${meta.text}`;
      let closeButton = null;
      if (!meta.hideDetail) {
        closeButton = renderNotificationDetailButton(meta.url, key);
      }
      if (meta.actions.length > 0) {
        closeButton = renderNotificationDetailButton(meta.actions, notification, key);
      }
      Notification.open({
        message: "Notification",
        description,
        btn: closeButton,
        key
      });
      didReceiveNotificationAction(notification);
      if (meta.reloadWhenSuccess) {
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    });
    io.on("disconnect", () => {
      if (process.env.ENV !== "production") {
        console.log("SOCKET: Disconnected from server");
      }
    });
  };
  const setupPushNotification = () => {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    const subDomain = window.location.host.split(".")[0];
    const appIds = {
      "ice": "34c5c2a9-42c4-4c96-99b6-29d3d4cae965",
      "eci": "967b2a3c-b2b0-4586-91a7-dd9ce716881d",
      "smartsecure": "b931421f-8ac0-4dd0-b8bd-7aa725eae9f9",
      "ugamer": "1046eea6-d6a0-4cee-9b20-a0413477d1e8",
      "dem": "921ab717-864d-496c-b758-0303918f3bc8",
    };
    const appId = appIds[subDomain];
    if (appId != null) {
      OneSignal.push(() => {
        OneSignal.init(
          {
            appId,
            subdomainName: subDomain,
          }
        );
        OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
          if (!isEnabled) {
            OneSignal.isOptedOut((isOptedOut) => {
              if (isOptedOut) {
                OneSignal.setSubscription(true);
              } else {
                OneSignal.showNativePrompt();
              }
            });
          }
        });
      });
    }
  };

  const onLogOut = () => {
    logOutAction();
  };

  const authStorageEventListener = () => {
    const localClientCredential = localStorage.getItem(LOGIN_TYPE);
    if (!localClientCredential) {
      window.location.href = "/login";
    }
    const machineId = localStorage.getItem(MACHINE_ID);
    if (machineId == null) {
      onLogOut();
    }
  };

  useEffect(() => {
    getCurrentUserAction();
  }, []);

  useEffect(() => {
    if ((currentUser && currentUser.user) && tracker == null) {
      const user = currentUser.user;
      const tracker = new Tracker({
        projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_KEY,
        ingestPoint: "https://openreplay.iserp.cloud/ingest",
        obscureTextNumbers: false,
        obscureTextEmails: false,
        autoResetOnWindowOpen: true,
        onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
      });
      tracker.use(trackerAssist({
        controlConfirm: {
          text: "ISERP Support Team would like to request remote control to this session. Do you want to allow?",
        },
        confirmText: "Allow",
        callConfirm: {
          text: "ISERP Support Team would like to call you. Do you want to allow?",
        }
      }));
      tracker.start({
        userID: `${currentUser.company.subDomain}:${user.clientId}`,
        metadata: {
          name: `${user.firstName} ${user.lastName}`,
        }
      });
      setTracker(tracker);
    }
  }), [currentUser];

  useEffect(() => {
    setupSocket();
    setupPushNotification();
    window.addEventListener("storage", authStorageEventListener);
    return () => {
      window.removeEventListener("storage", authStorageEventListener);
    };
  }, []);

  const lastAccessTime = localStorage.getItem(USER_LAST_ACCESS);
  const permissionNotAllowed = _.isEmpty(currentUser && currentUser.permissions);
  if (!_.isEmpty(currentUser)) {
    pageTitle(lastAccessTime ? "Lock Screen" : "Loading...");
  }
  const isApproved = currentUser && currentUser.user && currentUser.user.isApproved;
  // const style = {
  //   height: 40,
  //   width: 40,
  //   lineHeight: "40px",
  //   borderRadius: 4,
  //   textAlign: "center",
  //   fontSize: 14,
  // };
  return (
    <Fragment>
      <Favicon url={company && company.logoImageUrl} />
      {/* {process.env.NODE_ENV == "production" && (
        <IdleTimer
          debounce={250}
          timeout={900000}
          element={document}
          onIdle={this.onIdle}
          ref={ref => this.idleTimer = ref}
        />
      )} */}
      {loading == true || _.isEmpty(currentUser) ? (
        <Loading
          afterDelay={500}
          header="We're fetching your account info."
          text="It will be done in a moment."
        />
      ) : (
        lastAccessTime ? (
          <LockScreen
            form="lockScreenForm"
            currentUser={currentUser}
            onSubmit={this.onSubmit}
            handleLogOut={this.handleLogOut}
          />
        ) : (
          isApproved ? (
            <LayoutProvider>
              {!permissionNotAllowed ? (
                <Switch>
                  <Redirect
                    exact
                    from="/"
                    to="/dashboard"
                  />
                  <Route
                    path="/dashboard"
                    component={AsyncDashboard}
                  />
                  <Route
                    path="/human-resource"
                    component={AsyncHumanResource}
                  />
                  <Route
                    path="/delivery"
                    component={AsyncDelivery}
                  />
                  <Route
                    path="/inventory"
                    component={AsyncInventory}
                  />
                  <Route
                    path="/purchase"
                    component={AsyncPurchase}
                  />
                  <Route
                    path="/accounting"
                    component={AsyncAccounting}
                  />
                  <Route
                    path="/sale"
                    component={AsyncSale}
                  />
                  <Route
                    path="/settings"
                    component={AsyncSettings}
                  />
                  <Route
                    path="/reports"
                    component={AsyncReports}
                  />
                  <Route
                    path="/sync-item"
                    component={AsyncSyncItem}
                  />
                  <Route
                    path="/profile"
                    component={AsyncProfile}
                  />
                  <Route
                    path="/point-of-sale"
                    component={AsyncPointOfSale}
                  />
                  <Route
                    path="/website"
                    component={AsyncWebsite}
                  />
                  <Route
                    path="/apps"
                    component={AsyncApp}
                  />
                  <Route
                    path="/notification"
                    component={AsyncNotificationList}
                  />
                  <Route
                    component={PageNotFound}
                  />
                </Switch>
              ) : (
                <NoPermission />
              )}
            </LayoutProvider>
          ) : (
            <LogInApproval
              currentUser={currentUser}
              onLogOut={onLogOut}
            />
          )
        )
      )}
      <BackTop className="fixed-bottom-back-to-top">
        <Button type="primary">
          <DoubleLeftOutlined rotate={90} />
          Back to Top
        </Button>
      </BackTop>
    </Fragment>
  );
}

export default function MainContainer() {
  return (
    <SocketIOProvider>
      <NewContainer />
    </SocketIOProvider>
  );
}

class Container extends Component {

  constructor(props) {
    super(props);
    // if (process.env.NODE_ENV == "production") {
    this.setupSocket();
    // }
    this.handleListenerLogout();
    this.handleListenerMachineId();
    this.state = {
      locationKey: null,
    };

  }

  componentDidMount() {
    const { getCurrentUser } = this.props;
    if (getCurrentUser) {
      getCurrentUser();
    }
  }

  onHandleAddEventListener = () => {
    const localClientCredential = localStorage.getItem(LOGIN_TYPE);
    if (!localClientCredential) {
      window.location.href = "/login";
    }
  }

  handleListenerLogout = () => {
    window.addEventListener("storage", this.onHandleAddEventListener);
  }

  componentWillUnmount = () => {
    window.removeEventListener("storage", this.onHandleAddEventListener);
  }

  onIdle = () => {
    const {
      location: { pathname },
    } = this.props;
    const getLastActiveTime = this.idleTimer.getLastActiveTime();
    localStorage.setItem(USER_LAST_ACCESS, getLastActiveTime);
    history.push(pathname);
  }

  handleLogOut = () => {
    const { logOut } = this.props;
    modalConfirmDialog(
      "logout",
      "You can login back anytime.",
      logOut,
      null,
      "350px"
    );
  }

  onSubmit = (values, dispatch) => {
    return createSubmitAction(
      LOGIN_REQUEST_RULES,
      loginUserPasswordIfLockScreen
    )(values, dispatch).then((response) => {
      if (response?.error == null) {
        localStorage.removeItem(USER_LAST_ACCESS);
      }
    });
  }

  disconnectSocket = () => {
    const io = this.io;
    io.disconnect();
  }

  renderNotificationActionButtons = (actions, notification, notificationKey) => {
    const { dispatchAction } = this.props;
    return (
      actions.map((item, index) => {
        const onClick = () => {
          dispatchAction(item.handler(notification));
          Notification.close(notificationKey);
        };
        return (
          <Button
            onClick={onClick}
            key={index}
            type={item.type}
            size="small"
            style={{ marginRight: "8px" }}
          >
            {item.title}
          </Button>
        );
      })
    );
  };


  renderNotificationDetailButton = (path, notificationKey) => {
    return (
      <a onClick={() => {
        history.push(path);
        Notification.close(notificationKey);
      }}>
        See detail
      </a>
    );
  };

  setupSocket = () => {
    const { didReceiveNotification } = this.props;
    const { socket: io } = useSocketIO();
    io.on("connect", function () {
      // if (process.env.ENV !== "production") {
      console.log("SOCKET: Connected to server");
      // }
    });
    Notification.config({
      placement: "bottomLeft",
      duration: 3
    });
    io.on("notification", (notification) => {
      const key = `open${Date.now()}`;
      const displayName = `${notification.creator.firstName} ${notification.creator.lastName}`;
      const meta = getNotificationMetaData(notification);
      const description = `${displayName} ${meta.text}`;
      let closeButton = null;
      if (!meta.hideDetail) {
        closeButton = this.renderNotificationDetailButton(meta.url, key);
      }
      if (meta.actions.length > 0) {
        closeButton = this.renderNotificationActionButtons(meta.actions, notification, key);
      }
      Notification.open({
        message: "Notification",
        description,
        btn: closeButton,
        key
      });
      didReceiveNotification(notification);
      if (meta.reloadWhenSuccess) {
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    });
    io.on("disconnect", () => {
      if (process.env.ENV !== "production") {
        console.log("SOCKET: Disconnected from server");
      }
    });
  }

  onLogOut = (machineId) => {
    const { logOut } = this.props;
    logOut(machineId);
  }

  handleListenerMachineId = () => {
    window.addEventListener("storage", () => {
      const machineId = localStorage.getItem(MACHINE_ID);
      if (machineId == null) {
        this.onLogOut();
      }
    });
  }

  UNSAFE_componentWillUpdate(newProps) {
    const {
      currentUser,
    } = this.props;
    const { locationKey } = this.state;
    const { user } = currentUser;
    const { location: { key } } = newProps;
    if (!_.isEmpty(currentUser) && !_.isEmpty(user) && !_.isEmpty(user.loginBefore) && key != locationKey) {
      const {
        loginAfter: { formattedValue: loginAfterFormattedValue },
        loginBefore: { formattedValue: loginBeforeFormattedValue },
      } = user;
      const currentTime = moment().format(DEFAULT_DISPLAY_TIME_FORMAT);
      const momentTime = moment(currentTime, DEFAULT_DISPLAY_TIME_FORMAT);
      const loginAfter = moment(loginAfterFormattedValue, DEFAULT_DISPLAY_TIME_FORMAT);
      const loginBefore = moment(loginBeforeFormattedValue, DEFAULT_DISPLAY_TIME_FORMAT);
      if (!momentTime.isAfter(loginAfter) || !momentTime.isBefore(loginBefore)) {
        this.warningLoginBeforeIsOver(key);
      }
    }
  }

  warningLoginBeforeIsOver = (key) => {
    this.setState({
      locationKey: key,
    }, () => {
      const { logOut } = this.props;
      modalWarningLoginBeforeIsOverAndLogOut(logOut);
    });
  }

  render() {
    const {
      loading,
      pageTitle,
      currentUser,
      company,
    } = this.props;
    const lastAccessTime = localStorage.getItem(USER_LAST_ACCESS);
    const permissionNotAllowed = _.isEmpty(currentUser && currentUser.permissions);
    if (!_.isEmpty(currentUser)) {
      pageTitle(lastAccessTime ? "Lock Screen" : "Loading...");
    }
    const isApproved = currentUser && currentUser.user && currentUser.user.isApproved;
    return (
      <Fragment>
        <Favicon url={company && company.logoImageUrl} />
        {/* {process.env.NODE_ENV == "production" && (
          <IdleTimer
            debounce={250}
            timeout={900000}
            element={document}
            onIdle={this.onIdle}
            ref={ref => this.idleTimer = ref}
          />
        )} */}
        {loading == true || _.isEmpty(currentUser) ? (
          <Loading
            afterDelay={500}
            header="We're fetching your account info."
            text="It will be done in a moment."
          />
        ) : (
          lastAccessTime ? (
            <LockScreen
              form="lockScreenForm"
              currentUser={currentUser}
              onSubmit={this.onSubmit}
              handleLogOut={this.handleLogOut}
            />
          ) : (
            isApproved ? (
              <Layout>
                {!permissionNotAllowed ? (
                  <Switch>
                    <Redirect
                      exact
                      from="/"
                      to="/dashboard"
                    />
                    <Route
                      path="/dashboard"
                      component={AsyncDashboard}
                    />
                    <Route
                      path="/human-resource"
                      component={AsyncHumanResource}
                    />
                    <Route
                      path="/delivery"
                      component={AsyncDelivery}
                    />
                    <Route
                      path="/inventory"
                      component={AsyncInventory}
                    />
                    <Route
                      path="/purchase"
                      component={AsyncPurchase}
                    />
                    <Route
                      path="/accounting"
                      component={AsyncAccounting}
                    />
                    <Route
                      path="/sale"
                      component={AsyncSale}
                    />
                    <Route
                      path="/settings"
                      component={AsyncSettings}
                    />
                    <Route
                      path="/reports"
                      component={AsyncReports}
                    />
                    <Route
                      path="/sync-item"
                      component={AsyncSyncItem}
                    />
                    <Route
                      path="/profile"
                      component={AsyncProfile}
                    />
                    <Route
                      path="/point-of-sale"
                      component={AsyncPointOfSale}
                    />
                    <Route
                      path="/website"
                      component={AsyncWebsite}
                    />
                    <Route
                      path="/apps"
                      component={AsyncApp}
                    />
                    <Route
                      path="/notification"
                      component={AsyncNotificationList}
                    />
                    <Route
                      component={PageNotFound}
                    />
                  </Switch>
                ) : (
                  <NoPermission />
                )}
              </Layout>
            ) : (
              <LogInApproval
                currentUser={currentUser}
                onLogOut={this.onLogOut}
              />
            )
          )
        )}
      </Fragment>
    );
  }
}

Container.propTypes = {
  logOut: PropTypes.func,
  history: PropTypes.any,
  loading: PropTypes.bool,
  match: PropTypes.object,
  location: PropTypes.object,
  pageTitle: PropTypes.func,
  company: PropTypes.object,
  currentUser: PropTypes.object,
  getCurrentUser: PropTypes.func,
  dispatchAction: PropTypes.func,
  didReceiveNotification: PropTypes.func,
};

// const mapStateToProps = (state) => {
//   return {
//     company: valueSelectors.getDefaultCompany(state),
//     currentUser: valueSelectors.getCurrentUser(state),
//     pageTitle: (page) => valueSelectors.getPageTitle(state, page),
//     loading: valueSelectors.getLoading(state, CURRENT_USER_REQUEST),
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   const machineId = localStorage.getItem(MACHINE_ID);
//   return {
//     dispatchAction: (action) => dispatch(action),
//     logOut: (machineId) => dispatch(logOut(machineId)),
//     getCurrentUser: () => dispatch(getCurrentUser(machineId)),
//     didReceiveNotification: (notification) => dispatch(didReceiveNotification(notification)),
//   };
// };

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));