// Libs
import React from "react";
import PropTypes from "prop-types";
import { Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

class PopConfirm extends React.Component {

  render() {
    const { children, ...props } = this.props;
    return (
      <Popconfirm {...props}>
        {children ? 
          children
          :
          <DeleteOutlined className="d-flex align-item-center justify-content-center" />
        }
      </Popconfirm>
    );
  }
}

PopConfirm.propTypes = {
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.any,
};

PopConfirm.defaultProps = {
  title:"Are you sure delete ?",
  okText:"Yes", 
  cancelText:"No",
};

export default PopConfirm;