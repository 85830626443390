import { getPermissionAllowed } from "@/modules/utils/value-selector";

import * as SidebarConstants from "@/constants/sidebar";
import humanResourceMenus from "@/resources/menus/human-resource";
import settingsMenus from "@/resources/menus/settings";
import dashboardMenus from "@/resources/menus/dashboard";
import inventoryMenus from "@/resources/menus/inventory";
import purchaseMenus from "@/resources/menus/purchase";
import saleMenus from "@/resources/menus/sale";
import accountingMenus from "@/resources/menus/accounting";
import reportsMenus from "@/resources/menus/reports";
import profileMenus from "@/resources/menus/profile";
import syncItemMenus from "@/resources/menus/sync-item";
import pointOfSaleMenus from "@/resources/menus/point-of-sale";
import appSaleMenus from "@/resources/menus/app";
import notificationMenus from "@/resources/menus/notification";
import deliveryMenus from "@/resources/menus/delivery";
import websiteMenus from "@/resources/menus/website";

const initialState = {
  menus: [],
  hasDashboard: false,
  isFixed: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SidebarConstants.SIDEBAR_LOAD_DATA:
    return loadData(action.path, action.page, action.state);
  default:
    return state;
  }
}

const loadData = (path, page, state) => {
  const rootMenu = loadDataByPage(page, state);
  return {
    ...rootMenu,
  };
};

const loadDataByPage = (page, state) => {
  let menu = [];
  switch (page) {
  case SidebarConstants.SIDEBAR_PAGE_DASHBOARD:
    menu = {
      ...dashboardMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_HUMAN_RESOURCE:
    menu = {
      ...humanResourceMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_INVENTORY:
    menu = {
      ...inventoryMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_PURCHASE:
    menu = {
      ...purchaseMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_ACCOUNTING:
    menu = {
      ...accountingMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_SALE:
    menu = { 
      ...saleMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_SETTING:
    menu = { 
      ...settingsMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_REPORT:
    menu = { 
      ...reportsMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_PROFILE:
    menu = { 
      ...profileMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_SYNC_ITEM:
    menu = {
      ...syncItemMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_POINT_OF_SALE:
    menu = {
      ...pointOfSaleMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_APP:
    menu = {
      ...appSaleMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_NOTIFICATION:
    menu = {
      ...notificationMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_DELIVERY:
    menu = {
      ...deliveryMenus
    };
    break;
  case SidebarConstants.SIDEBAR_PAGE_WEBSITE:
    menu = {
      ...websiteMenus
    };
    break;
  }
  const { menus } = menu;
  const allowedMenus = [];
  menus.map((menuItem) => {
    const { sub: subMenus } = menuItem;
    if (subMenus) {
      const allowedSubMenus = subMenus.filter(subMenu => {
        const { code, isShow=true } = subMenu;
        const isAllowed = getPermissionAllowed(state, code);
        return (isAllowed == true && isShow) ? subMenu : null;
      });
      if (allowedSubMenus.length > 0) {
        allowedMenus.push({
          ...menuItem,
          sub: allowedSubMenus
        });
      }
    } else {
      const { code } = menuItem;
      const isAllowed = getPermissionAllowed(state, code);
      if (isAllowed) {
        allowedMenus.push({
          ...menuItem
        });
      }
    }
  });
  return {
    ...menu,
    menus: allowedMenus
  };
};