//action type 
export const MEETING_LIST_REQUEST = "MEETING_LIST_REQUEST";
export const MEETING_LIST_REQUEST_SUCCESS = "MEETING_LIST_REQUEST_SUCCESS";
export const MEETING_CREATE_REQUEST = "MEETING_CREATE_REQUEST";
export const CONSTRACT_DETAIL_REQUEST = "CONSTRACT_DETAIL_REQUEST";
export const MEETING_EDIT_REQUEST = "MEETING_EDIT_REQUEST";
export const MEETING_APPROVE_REQUEST = "MEETING_APPROVE_REQUEST";
export const MEETING_REJECT_REQUEST = "MEETING_REJECT_REQUEST";
export const MEETING_CANCEL_REQUEST = "MEETING_CANCEL_REQUEST";

export const MEETING_FORM_NAME ="meeting";

export const MEETING_STATUS_PENDING = 1;
export const MEETING_STATUS_APPROVED = 2;
export const MEETING_STATUS_REJECTED = 3;
export const MEETING_STATUS_CANCELLED = 4;

//type
export const MEETING_STATUSES = [
  {
    id: MEETING_STATUS_PENDING,
    name: "Pending",
  },
  {
    id: MEETING_STATUS_APPROVED,
    name: "Approved",
  },
  {
    id: MEETING_STATUS_REJECTED,
    name: "Rejected",
  },
  {
    id: MEETING_STATUS_CANCELLED,
    name: "Cancelled",
  },
];


export const MEETING_SUBMIT_RULES = () => { 
  return {
    title: {
      required: true,
    },
    start:{
      required: true,
    },
    end:{
      required: true,
    },
    roomId:{
      required: true,
    },
  };
};

// permission code
export const EDIT_MEETING = 701002;
export const READ_MEETING = 701000;
export const CREAT_MEETING = 701001;
export const PRINT_MEETING = 701007;
export const IMPORT_MEETING = 701008;
export const EXPORT_MEETING = 701009;
export const REPORT_MEETING = 701010;
export const DELETE_MEETING = 701003;
export const ARCHIVE_MEETING = 701004;
export const UNARCHIVE_MEETING = 701005;
export const BATCH_DELETE_MEETING = 701006;
