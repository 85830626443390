import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Form, Button, Avatar } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// Components
import Input from "@modules/cores/components/Input";
import AuthenticationLayout from "@/components/layout/authentication-layout";

// Constants
import { LOGIN_REQUEST, LOGOUT_REQUEST, MACHINE_ID } from "@/constants/authentication";

class LockScreen extends Component {

  render() {
    const { 
      loading, 
      currentUser, 
      handleSubmit, 
      handleLogOut,
      logoutLoading 
    } = this.props;
    return (
      <AuthenticationLayout displayImage={false}>
        <div className="mb-3 text-center">
          <Avatar 
            size={100} 
            icon={<UserOutlined />}
            src={currentUser && currentUser.user && currentUser.user.profileImageUrl}
          />
        </div>
        <h4 className="text-center">
          {currentUser && currentUser.user && currentUser.user.firstName} {currentUser && currentUser.user && currentUser.user.lastName}
        </h4>
        <p className="text-center mb-0">You have been inactive for a while.</p>
        <p className="text-center">Please retype your password to continue</p>
        <Form onFinish={() => handleSubmit()} layout="vertical">
          <Input
            autoFocus
            type="Password"
            name="password"
            icon={<LockOutlined />}
            placeholder="Password"
            autoComplete="new-password"
          />
          <div className="d-flex">
            <Button
              block
              ghost
              type="primary"
              htmlType="submit"
              className="mr-1"
              loading={loading}
            >
              Continue
            </Button>
            <Button
              block
              ghost
              type="danger"
              className="ml-1"
              onClick={handleLogOut}
              loading={logoutLoading}
            >
              Log Out
            </Button>
          </div>
        </Form>
      </AuthenticationLayout>
    );
  }
}

LockScreen.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  logoutLoading: PropTypes.bool,
  currentUser: PropTypes.object,
  handleLogOut: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { currentUser } = ownProps;
  const subDomain = location.host.split(".")[0];
  const machineId = localStorage.getItem(MACHINE_ID);
  return {
    loading: state.request[LOGIN_REQUEST],
    logoutLoading: state.request[LOGOUT_REQUEST],
    initialValues: {
      username: currentUser && currentUser.user.clientId,
      subDomain,
      machineId,
    }
  };
};


export default connect(mapStateToProps)(reduxForm()(LockScreen));