import { 
  PaymentTermSchema,
  PaymentTermArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as PaymentTermConstants from "../constants";

const path = "/sale/setup/payment-term";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPaymentTerm,
    schema: PaymentTermArraySchema,
    requestName: PaymentTermConstants.PAYMENT_TERM_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createPaymentTerm = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createPaymentTerm,
    requestName: PaymentTermConstants.PAYMENT_TERM_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Payment term is created successfully",
  };
  return createNewEntityAction(options, values);
};

export const getPaymentTermAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getPaymentTermAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getPaymentTermDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getPaymentTermDetail,
    requestName: PaymentTermConstants.PAYMENT_TERM_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: PaymentTermSchema,
  };
  return createDetailAction(options, id);
};

export const editPaymentTerm = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editPaymentTerm,
    requestName: PaymentTermConstants.PAYMENT_TERM_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "paymentTerms",
    schema: PaymentTermSchema,
    successMessage: "Payment term is updated successfully",
  };
  return createEditAction(options, values);
};

export const archivePaymentTerm = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archivePaymentTerm,
    requestName: PaymentTermConstants.PAYMENT_TERM_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Payment term is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deletePaymentTerm = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deletePaymentTerm,
    requestName: PaymentTermConstants.PAYMENT_TERM_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Payment term is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchivePaymentTerm = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchivePaymentTerm,
    requestName: PaymentTermConstants.PAYMENT_TERM_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Payment term is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};