import { schema } from "normalizr";

const EarningTypeInputSchema = new schema.Entity("earningTypesInput");
const EarningTypeInputArraySchema = new schema.Array(EarningTypeInputSchema);

const EarningTypeSchema = new schema.Entity("earningTypes", {
  inputs: EarningTypeInputArraySchema
});

const EarningTypeArraySchema = new schema.Array(EarningTypeSchema);

export { EarningTypeSchema, EarningTypeArraySchema, EarningTypeInputSchema };