export const SALE_TEAM_LIST_REQUEST = "SALE_TEAM_LIST_REQUEST"; 
export const SALE_TEAM_LIST_SUCCESS = "SALE_TEAM_LIST_REQUEST_SUCCESS";
export const SALE_TEAM_CREATE_REQUEST = "SALE_TEAM_CREATE_REQUEST"; 
export const SALE_TEAM_DETAIL_REQUEST = "SALE_TEAM_DETAIL_REQUEST"; 
export const SALE_TEAM_ARCHIVE_REQUEST = "SALE_TEAM_ARCHIVE_REQUEST"; 
export const SALE_TEAM_DELETE_REQUEST = "SALE_TEAM_DELETE_REQUEST"; 
export const SALE_TEAM_UNARCHIVE_REQUEST = "SALE_TEAM_UNARCHIVE_REQUEST"; 
export const SALE_TEAM_EDIT_REQUEST = "SALE_TEAM_EDIT_REQUEST"; 
export const SALE_TEAM_CREATE_FORM_NAME = "createSaleTeam"; 
export const SALE_TEAM_EDIT_FORM_NAME = "editSaleTeam"; 
export const SALE_TEAM_AUTOCOMPLETE_REQUEST = "SALE_TEAM_AUTOCOMPLETE_REQUEST";
export const SALE_TEAM_AUTOCOMPLETE_SUCCESS = "SALE_TEAM_AUTOCOMPLETE_SUCCESS";
export const SALE_TEAM_MEMBER_ARRAY_FIELD_NAME = "saleTeamMembers";
export const SALE_TEAM_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  leadUserId: {
    required: true
  }
};
export const READ_SALE_TEAM = 197000;
export const CREATE_SALE_TEAM = 197001;
export const UPDATE_SALE_TEAM = 197002;
export const DELETE_SALE_TEAM = 197003;
export const ARCHIVE_SALE_TEAM = 197004;
export const UNARCHIVE_SALE_TEAM = 197005;