// Libs
import React from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import tinycolor from "tinycolor2"; 

// Components
import SideBar from "./side-bar";
import Header from "./header";
import Content from "./content";

// themes
import darkVars from "@/themes/dark.json";
import lightVars from "@/themes/light.json";

const { Footer } = Layout;

function AdminLayout(props) {

  const [
    collapsed, 
    setCollapsed
  ] = React.useState(false);

  const [
    theme,
    setTheme
  ] = React.useState(localStorage.getItem("theme-name") || "light");

  const onToggle = () => {
    setCollapsed(!collapsed);
  };

  const onThemeChange = (value) => {
    const { company } = props;
    let vars = value === "light" ? lightVars : darkVars;
    vars = { 
      ...vars, 
      "@primary-color": company && company.navBackground,
      "@link-color" : value == "dark" ? tinycolor(company && company.navBackground).isDark() ? "#FFFFFF" : (company && company.navBackground) : (company && company.navBackground),
      "@white": "#fff", 
      "@black": "#000" 
    };
    setTheme(value);
    localStorage.setItem("app-theme", JSON.stringify(vars));
    localStorage.setItem("theme-name", value);
    window.less.modifyVars(vars).catch((error) => {
      console.log(error);
    });
  };

  const {
    children,
    isFullScreen,
    menu: { display },
  } = props;
  return (
    <Layout>
      <SideBar 
        theme={theme}
        collapsed={collapsed} 
        onToggle={onToggle}
      />
      <Layout style={{ minHeight: "100vh" }}>
        {isFullScreen == false && (
          <Header
            theme={theme}
            onToggle={onToggle}
            collapsed={collapsed}
            shouldToggle={display}
            onThemeChange={onThemeChange}
          />
        )}
        <Content
          theme={theme}
          collapsed={collapsed}
          shouldToggle={display}
          isFullScreen={isFullScreen}
        >
          {children}
        </Content>
        <Footer 
          theme={theme}
          className="text-center p-3"  
        >
            Copyright &copy; 2019 Developed by
          <a
            target="_blank"
            className="pl-1"
            rel="noopener noreferrer"
            href=""
          >
            ISERP
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
}

AdminLayout.propTypes = {
  menu: PropTypes.object,
  children: PropTypes.node,
  company: PropTypes.object,
  isFullScreen: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const subDomain = location.host.split(".")[0];
  return {
    menu: state.menu,
    company: state.company[subDomain],
    isFullScreen: state.posFullScreen.isFullScreen,
  };
};

export default withRouter(connect(mapStateToProps)(AdminLayout));