import React from "react";
import moment from "moment";
import { Modal } from "antd";
import PropTypes from "prop-types";

// Constants
import { CASH_ADVANCED_LIST_REQUEST } from "@modules/hr/cash-advanced/cash-advanced/constants";

// Components
import { getMonthLabel } from "@modules/helpers";
import ListLayout from "@/components/layout/list-layout";
import CurrencyBox from "@cores/components/currency-box";
import CashAdvanceDetail from "./cash-advance-detail";
import FilterBox from "@modules/hr/cash-advanced/cash-advanced/components/filter-box";
import { renderCashAdvancedStatus, renderPaymentType } from "@modules/hr/cash-advanced/cash-advanced/helpers";

// Schema
import { CashAdvancedArraySchema } from "@modules/hr/cash-advanced/cash-advanced/schema";

// Action 
import { getAll } from "@modules/hr/cash-advanced/cash-advanced/actions";

function CashAdvance(props) {

  const [visible, setVisible] = React.useState({
    visible: false,
    cashAdvancedNo: null,
  });

  const columns = () => {
    return [
      {
        title: "Cash Advanced No",
        dataIndex: "cashAdvancedNo",
        width: "15%",
        sortable: true,
        render: (cashAdvancedNo) => {
          return (
            <a onClick={() => setVisible({
              visible: true,
              cashAdvancedNo
            })}>
              <>
                {cashAdvancedNo}
              </>
            </a>
          );
        }
      },
      {
        title: "Amount",
        width: "10%",
        dataIndex: "amount",
        render: (amount) => <CurrencyBox value={amount} />
      },
      {
        title: "Duration",
        width: "10%",
        dataIndex: "duration",
        sortable: true,
        sortKey: "duration",
        render: (duration) => getMonthLabel(duration)
      },
      {
        title: "Interest Rate",
        dataIndex: "interestRate",
        width: "10%",
        sortable: true,
        sortKey: "interestRate",
        render: (interestRate) => (interestRate != null ? `${interestRate} %` : "")
      },
      {
        title: "Effective Date",
        dataIndex: "effectiveDate",
        width: "10%",
        render: (effectiveDate, row) => {
          const { effectiveMonth, effectiveYear } = row;
          return `${moment(effectiveMonth, "MM").format("MMMM")} / ${effectiveYear}`;
        }
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        width: "10%",
        sortable: true,
        sortKey: "paymentType",
        render: (paymentType) => renderPaymentType(paymentType)
      },
      {
        title: "Status",
        width: "10%",
        dataIndex: "status",
        sortKey: "Status",
        render: (status) => renderCashAdvancedStatus(status)
      },
    ];
  };

  const { permission, id } = props;
  const filterKeys = [
    "employee",
    "paymentType",
    "status",
    "month",
    "year",
  ];
  return (
    <>
      <ListLayout
        className="mb-0"
        action={getAll}
        columns={columns}
        showHeader={false}
        tableSize="small"
        title="Cash Advanced"
        permission={permission}
        filterKeys={filterKeys}
        filterComponent={FilterBox}
        reducerName="cashAdvanced"
        isPermissionAllowed={true}
        schema={CashAdvancedArraySchema}
        requestName={CASH_ADVANCED_LIST_REQUEST}
        extraFilter={(filter) => {
          filter.employee = [id];
          return filter;
        }}
      />
      <Modal
        width="1200px"
        footer={null}
        visible={visible.visible}
        title="Cash Advanced Detail"
        onCancel={() => setVisible({
          visible: false,
          cashAdvancedNo: null
        })}
      >
        <CashAdvanceDetail 
          id={visible.cashAdvancedNo}
        />
      </Modal>
    </>
  );
}

CashAdvance.propTypes = {
  id: PropTypes.number,
  permission: PropTypes.object,
};

export default CashAdvance;