const PURCHASE_PATH = "/purchase";
const LIST_PATH = "list";

export const purchase = [
  {
    name: "Purchase Order List",
    type: "list",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order`,
  },
  {
    name: "Purchase Order Detail",
    type: "detail",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order`,
  },
  {
    name: "Purchase Order Print",
    type: "print",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order`,
  },
  {
    name: "Create Purchase Order",
    type: "create",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order`,
  },
  {
    name: "Edit Purchase Order",
    type: "edit",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order`,
  },
  {
    name: "Purchase Order Type List",
    type: "list",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order-type`,
  },
  {
    name: "Purchase Order Type Detail",
    type: "detail",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order-type`,
  },
  {
    name: "Create Purchase Order Type",
    type: "create",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order-type`,
  },
  {
    name: "Edit Purchase Order Type",
    type: "edit",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/purchase-order-type`,
  },
  {
    name: "Supplier List",
    type: "list",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier`,
  },
  {
    name: "Supplier Detail",
    type: "detail",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier`,
  },
  {
    name: "Create Supplier",
    type: "create",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier`,
  },
  {
    name: "Edit Supplier",
    type: "edit",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier`,
  },
  {
    name: "Supplier Type List",
    type: "list",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier-type`,
  },
  {
    name: "Supplier Type Detail",
    type: "detail",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier-type`,
  },
  {
    name: "Create Supplier Type",
    type: "create",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier-type`,
  },
  {
    name: "Edit Supplier Type",
    type: "edit",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/supplier-type`,
  },
  {
    name: "Shipper List",
    type: "list",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/shipper`,
  },
  {
    name: "Shipper Detail",
    type: "detail",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/shipper`,
  },
  {
    name: "Create Shipper",
    type: "create",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/shipper`,
  },
  {
    name: "Edit Shipper",
    type: "edit",
    slug: `${PURCHASE_PATH}/${LIST_PATH}/shipper`,
  },
];