import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, DatePicker as AntdDatePicker } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import moment from "moment";
import { 
  DEFAULT_DISPLAY_TIME_FORMAT,
  DEFAULT_DISPLAY_DATE_FORMAT,
  // DEFAULT_YEAR_AND_DATE_FORMAT,
  DEFAULT_DISPLAY_DATE_TIME_FORMAT,
} from "@/constants/default-constants";

const FormItem = Form.Item;

class DatePickerField extends Component {

    onChange = (value, dateSting) => {
      let { 
        input: { onChange }, 
        handleChange,
        showTime,
        // type,
      } = this.props;
      if (value != null) {
        if (showTime) {
          // const result = value.format(DEFAULT_YEAR_AND_DATE_FORMAT);
          // const now = moment().format(DEFAULT_YEAR_AND_DATE_FORMAT);
          // if (!moment(result).isSame(now)) {
          //   dateSting = value[type]("day").format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
          // }
        }
        onChange(dateSting);
        if (handleChange) {
          handleChange(dateSting, value);
        }
      } else {
        onChange(null);
        if (handleChange) {
          handleChange();
        }
      }
    }
    
      disabledDate = (currentDate) => {
        const { 
          minimumDate, 
          disabledDate,
          showFutureDates,
          defaultDisplayDateFormat,
          numberDayOfDisabledFuture,
        } = this.props;
        if (disabledDate) {
          return disabledDate(currentDate);
        }
        if (minimumDate) {
          const minimumDateMoment = moment(minimumDate, defaultDisplayDateFormat).add(1, "day");
          if (currentDate) {
            return currentDate.isBefore(minimumDateMoment);
          }
        }
        if (!showFutureDates) {
          return moment() < moment(currentDate).add((numberDayOfDisabledFuture ||0), "day");
        }
      }

      // onOk = (value) =>  {
      //   let { 
      //     input: { onChange }, 
      //     handleChange,
      //     showTime,
      //     type,
      //   } = this.props;

      //   if (value != null) {
      //     if (showTime) {
      //       const result = value.format(DEFAULT_YEAR_AND_DATE_FORMAT);
      //       const now = moment().format(DEFAULT_YEAR_AND_DATE_FORMAT);
      //       if (!moment(result).isSame(now)) {
      //         dateSting = value[type]("day").format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      //       }
      //     }
      //     onChange(dateSting);
      //     if (handleChange) {
      //       handleChange(dateSting, value);
      //     }
      //   } else {
      //     onChange(null);
      //     if (handleChange) {
      //       handleChange();
      //     }
      //   }
      // }
    
      renderValue = () => {
        let { 
          input: { value }, 
          defaultDisplayDateFormat, 
          displayCurrentDate,
          showTime,
        } = this.props;
        if (showTime) {
          defaultDisplayDateFormat = DEFAULT_DISPLAY_DATE_TIME_FORMAT;
        }
        if (value) {
          return moment(value, defaultDisplayDateFormat);
        } else {
          if (displayCurrentDate) {
            const currentDate = moment().format(defaultDisplayDateFormat);
            return moment(currentDate, defaultDisplayDateFormat);
          }
        }
      }

      render() {
        const { 
          label, 
          placeholder,
          required,
          disabled,
          minimumDate,
          showTime,
          use12Hours,
          allowClear,
          defaultTimeValue,
          minuteStep,
          meta: { touched, error },
          ...props
        } = this.props;
        let { defaultDisplayDateFormat } = this.props;
        const validateStatus = classnames(touched && error ? "error" : "success");
        if (showTime) {
          defaultDisplayDateFormat = DEFAULT_DISPLAY_DATE_TIME_FORMAT;
        }
        return (
          <FormItem 
            label={label} 
            validateStatus={validateStatus}
            help={error}
            required={required}
          >
            <AntdDatePicker 
              className="w-100"
              format={defaultDisplayDateFormat}
              placeholder={placeholder}
              allowClear={allowClear}
              onChange={this.onChange}
              // onOk={this.onOk}
              defaultPickerValue={minimumDate && moment(minimumDate, defaultDisplayDateFormat).add(1, "day")}
              disabledDate={this.disabledDate}
              value={this.renderValue()}
              defaultValue={this.renderValue()}
              disabled={disabled}
              minuteStep={minuteStep}
              showTime={
                showTime && {
                  defaultValue: defaultTimeValue && defaultTimeValue(moment),
                  showTime,
                  use12Hours,
                  format: DEFAULT_DISPLAY_TIME_FORMAT
                }
              }
              {...props}
            />
        
          </FormItem>
        );
      }
}

DatePickerField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  defaultDisplayDateFormat: PropTypes.string,
  displayCurrentDate: PropTypes.bool,
  minimumDate: PropTypes.any,
  showFutureDates: PropTypes.bool,
  showTime: PropTypes.bool,
  defaultTimeValue: PropTypes.any,
  use12Hours: PropTypes.bool,
  disabledDate: PropTypes.any,
  allowClear: PropTypes.any,
  numberDayOfDisabledFuture: PropTypes.number,
  minuteStep: PropTypes.number,
};

DatePickerField.defaultProps = {
  required: false,
  defaultDisplayDateFormat: DEFAULT_DISPLAY_DATE_FORMAT,
  showFutureDates: true,
  use12Hours: true,
  allowClear: false,
  type: "startOf",
};

class DatePicker extends Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field 
        component={DatePickerField}
        {...props} 
      />
    );
  }
}

export default DatePicker;