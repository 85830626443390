import * as FilterConstant from "@/constants/filter";

export default function (state = {}, action) {
  switch (action.type) {
  case FilterConstant.FILTER_LIST_SUCCESS: {
    return {
      ...state,
      [action.entity]: [
        ...action.payload
      ]
    };
  }
  case FilterConstant.FILTER_CREATE_SUCCESS: {
    const oldFilter = [
      ...state[action.entity]
    ];
    return {
      ...state,
      [action.entity]: [
        ...oldFilter,
        action.payload,
      ]
    };
  }
  case FilterConstant.FILTER_DELETE_SUCCESS: {
    const filers = [
      ...state[action.entity]
    ];
    const deleteIndex = filers.indexOf(action.id);
    filers.splice(deleteIndex, 1);
    return {
      ...state,
      [action.entity]: [
        ...filers,
      ]
    };
  }
  default:
    return state;
  }
}