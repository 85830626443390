import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Input, AutoComplete } from "antd";
import classNames from "classnames";
import { SearchOutlined } from "@ant-design/icons";
import "./index.less";

class HeaderSearch extends PureComponent {

  static getDerivedStateFromProps(props) {
    if ("open" in props) {
      return {
        searchMode: props.open,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      searchMode: props.defaultOpen,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  enterSearchMode = () => {
    const { onVisibleChange } = this.props;
    onVisibleChange(true);
    this.setState({ searchMode: true }, () => {
      const { searchMode } = this.state;
      if (searchMode) {
        this.input.focus();
      }
    });
  };

  leaveSearchMode = () => {
    const { onLeaveMode } = this.props;
    this.setState({
      searchMode: false,
    });
    if (onLeaveMode) {
      onLeaveMode();
    }
  };

  render() {
    const { 
      value,
      className, 
      placeholder, 
      dataSource, 
      onChange, 
      ...restProps
    } = this.props;
    const { searchMode } = this.state;
    delete restProps.defaultOpen;
    const inputClass = classNames("input", {
      "show": searchMode,
    });
    return (
      <span
        className={classNames(className, "headerSearch")}
        onClick={this.enterSearchMode}
        onTransitionEnd={({ propertyName }) => {
          if (propertyName === "width" && !searchMode) {
            const { onVisibleChange } = this.props;
            onVisibleChange(searchMode);
          }
        }}
      >
        <SearchOutlined style={{color: "white"}}  />
        <AutoComplete
          className={inputClass}
          value={value}
          onChange={onChange}
          dataSource={dataSource}
          {...restProps}
        >
          <Input
            ref={node => {
              this.input = node;
            }}
            aria-label={placeholder}
            placeholder={placeholder}
            onKeyDown={this.onKeyDown}
            onBlur={this.leaveSearchMode}
          />
        </AutoComplete>
      </span>
    );
  }
}

HeaderSearch.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  dataSource: PropTypes.array,
  defaultOpen: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  open: PropTypes.any,
  value: PropTypes.any,
  onLeaveMode: PropTypes.func,
};

HeaderSearch.defaultProps = {
  defaultActiveFirstOption: false,
  dataSource: [],
  defaultOpen: false,
  onVisibleChange: () => {},
};

export default HeaderSearch;