
// Item
import { READ_TAG } from "@/modules/inventory/item/tag/constants";
import { READ_ITEM } from "@modules/inventory/item/item/constants";
import { READ_BRAND } from "@/modules/inventory/item/brand/constants";
import { READ_SUPPLIER } from "@modules/purchase/list/supplier/constants";
import { READ_CATEGORY } from "@modules/inventory/item/category/constants";
import { READ_ATTRIBUTE } from "@modules/inventory/item/attribute/constants";
import { READ_ITEM_SPECIFICATION } from "@modules/inventory/item/item-specification/constants";
import { READ_PRICE_LIST } from "@/modules/inventory/item/price-list/constants";
import { READ_DEPARTMENT } from "@/modules/inventory/item/department/constants";
import { READ_SUPPLIER_TYPE } from "@modules/purchase/list/supplier-type/constants";
import { READ_CUSTOMIZE_ITEM } from "@/modules/inventory/item/customize-item/constants";
import { READ_WARRANTY } from "@/modules/inventory/item/warranty/constants";

// Stock
import { READ_JUNK_OUT } from "@modules/inventory/stock/junk-out/constants";
import { READ_JUNK_OUT_TYPE } from "@modules/inventory/stock/junk-out-type/constants";
import { READ_WAREHOUSE } from "@modules/inventory/stock/warehouse/constants";
import { READ_STOCK_OUT } from "@modules/inventory/stock/stock-out/constants";
import { READ_STOCK_SETTING } from "@modules/inventory/stock/setting/constants";
import { READ_STOCK_TRANSFER } from "@modules/inventory/stock/stock-transfer/constants";
import { READ_ITEM_MOVEMENT_LOG } from "@modules/inventory/stock/item-movement-log/constants";
import { READ_ITEM_SPECIFICATION_TEMPLATE } from "@modules/inventory/item/item-specification-template/constants";

// Asset
import { READ_ASSET } from "@modules/inventory/asset/asset/constants";
import { READ_ASSET_REQUEST } from "@modules/inventory/asset/asset-request/constants";
import { READ_ASSET_LOG } from "@modules/inventory/asset/asset-log/constants";

// Report
import { INVENTORY_REPORT_MENU } from "./reports";

export default {
  sideBarTitle: "Inventory",
  hasDashboard: true,
  isFixed: false,
  slug: "inventory",
  display: true,
  menus: [
    {
      id: 1,
      title: "Item Catalog",
      slug: "items",
      icon: "ShoppingCartOutlined",
      sub: [
        {
          id: 1,
          title: "Item",
          slug: "item",
          code: READ_ITEM,
        },
        {
          id: 2,
          title: "Customize Item",
          slug: "customize-item",
          code: READ_CUSTOMIZE_ITEM,
        },
        {
          id: 3,
          title: "Attribute",
          slug: "attribute",
          code: READ_ATTRIBUTE,
        },
        {
          id: 4,
          title: "Specification",
          slug: "specification",
          code: READ_ITEM_SPECIFICATION,
        },
        {
          id: 5,
          title: "Specification Template",
          slug: "specification-template",
          code: READ_ITEM_SPECIFICATION_TEMPLATE,
        },
        {
          id: 6,
          title: "Price List",
          slug: "price-list",
          code: READ_PRICE_LIST,
        },
        {
          id: 7,
          title: "Department",
          slug: "department",
          code: READ_DEPARTMENT,
        },
        {
          id: 8,
          title: "Category",
          slug: "category",
          code: READ_CATEGORY,
        },
        {
          id: 9,
          title: "Brand",
          slug: "brand",
          code: READ_BRAND,
        },
        {
          id: 10,
          title: "Tag",
          slug: "tag",
          code: READ_TAG,
        },
        {
          id: 11,
          title: "Supplier",
          slug: "supplier",
          code: READ_SUPPLIER,
        },
        {
          id: 12,
          title: "Supplier Type",
          slug: "supplier-type",
          code: READ_SUPPLIER_TYPE,
        },
        {
          id: 13,
          title: "Warranty",
          slug: "warranty",
          code: READ_WARRANTY,
        },
      ]
    },
    {
      id: 2,
      title: "Stock",
      slug: "stock",
      icon: "AppstoreAddOutlined",
      sub: [
        {
          id: 1,
          title: "Warehouse",
          slug: "warehouse",
          code: READ_WAREHOUSE,
        },
        {
          id: 2,
          title: "Stock Transfer",
          slug: "stock-transfer",
          code: READ_STOCK_TRANSFER,
        },
        {
          id: 3,
          title: "Stock Out",
          slug: "stock-out",
          code: READ_STOCK_OUT,
        },
        {
          id: 4,
          title: "Item Movement Log",
          slug: "item-movement-log",
          code: READ_ITEM_MOVEMENT_LOG,
        },
        {
          id: 5,
          title: "Junk Out",
          slug: "junk-out",
          code: READ_JUNK_OUT,
        },
        {
          id: 5,
          title: "Junk Out Type",
          slug: "junk-out-type",
          code: READ_JUNK_OUT_TYPE,
        },
        {
          id: 6,
          title: "Stock Setting",
          slug: "stock-setting",
          code: READ_STOCK_SETTING,
        },
      ]
    },
    {
      id: 3,
      title: "Asset",
      slug: "asset",
      icon: "DesktopOutlined",
      sub: [
        {
          id: 1,
          title: "Asset Request",
          slug: "asset-request",
          code: READ_ASSET_REQUEST,
        },
        {
          id: 2,
          title: "Asset List",
          slug: "asset-list",
          code: READ_ASSET,
        },
        {
          id: 3,
          title: "Asset Log",
          slug: "asset-log",
          code: READ_ASSET_LOG,
        },
      ]
    },
    {
      id: 5,
      title: "Report",
      slug: "report",
      icon: "FileTextOutlined",
      sub: INVENTORY_REPORT_MENU,
    },
  ]
};