import {
  FontColorsOutlined,
  IdcardOutlined,
  MailOutlined,
  CalendarOutlined,
  ScheduleOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  SolutionOutlined,
  CameraOutlined,
  FormOutlined,
  PictureOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

export const EMPLOYMENT_CARD_LIST_REQUEST = "EMPLOYMENT_CARD_LIST_REQUEST";
export const EMPLOYMENT_CARD_LIST_SUCCESS = "EMPLOYMENT_CARD_LIST_REQUEST_SUCCESS";
export const EMPLOYMENT_CARD_CREATE_REQUEST = "EMPLOYMENT_CARD_CREATE_REQUEST";
export const EMPLOYMENT_CARD_DETAIL_REQUEST = "EMPLOYMENT_CARD_DETAIL_REQUEST";
export const EMPLOYMENT_CARD_ARCHIVE_REQUEST = "EMPLOYMENT_CARD_ARCHIVE_REQUEST";
export const EMPLOYMENT_CARD_DELETE_REQUEST = "EMPLOYMENT_CARD_DELETE_REQUEST";
export const EMPLOYMENT_CARD_UNARCHIVE_REQUEST = "EMPLOYMENT_CARD_UNARCHIVE_REQUEST";
export const EMPLOYMENT_CARD_EDIT_REQUEST = "EMPLOYMENT_CARD_EDIT_REQUEST";
export const EMPLOYMENT_CARD_CREATE_FORM_NAME = "createEmploymentCard";
export const EMPLOYMENT_CARD_EDIT_FORM_NAME = "editEmploymentCard";
export const EMPLOYMENT_CARD_AUTOCOMPLETE_REQUEST = "EMPLOYMENT_CARD_AUTOCOMPLETE_REQUEST";
export const EMPLOYMENT_CARD_AUTOCOMPLETE_SUCCESS = "EMPLOYMENT_CARD_AUTOCOMPLETE_SUCCESS";
export const EMPLOYMENT_CARD_SUBMIT_RULES = {
  name: {
    required: true
  },
  status: {
    required: true
  },
  type: {
    required: true
  },
  duration: {
    required: true
  }
};
export const EMPLOYMENT_CARD_STATUS = [
  {
    id: 1,
    name: "Active"
  },
  {
    id: -1,
    name: "Deactive"
  },
];

export const EMPLOYMENT_CARD_TYPES = [
  {
    id: 1,
    name: "Day"
  },
  {
    id: 2,
    name: "Month"
  },
  {
    id: 3,
    name: "Year"
  }
];

export const READ_EMPLOYMENT_CARD = 144000;
export const CREATE_EMPLOYMENT_CARD = 144001;
export const UPDATE_EMPLOYMENT_CARD = 144002;
export const DELETE_EMPLOYMENT_CARD = 144003;
export const ARCHIVE_EMPLOYMENT_CARD = 144004;
export const UNARCHIVE_EMPLOYMENT_CARD = 144005;

export const DEFAULT_EMPLOYMENT_CARD_PROPERTIES = {
  backgroundColor: "#ffffff",
  width: "250",
  height: "300",
  orientation: 1,
  backgroundImageUrl: "",
  elements: [],
  isUseBackgroundImage: false,
};

export const CardToolboxItem = "CardToolboxItem";

export const TOOLBOX_DATA_SOURCE = [
  {
    title: "Label",
    sub: [
      {
        type: 1,
        text: "Label",
        icon: <FontColorsOutlined />,
        props: {
          text: "Label",
          color: "#666",
          fontSize: 16,
          textAlign: "center",
          width: null,
          height: null,
        }
      },
    ]
  },
  {
    title: "Text",
    sub: [
      {
        type: 2,
        text: "ID",
        icon: <IdcardOutlined />,
        props: {
          text: "{{id}}",
          color: "#666",
          textAlign: "center",
          fontSize: 16,
          width: null,
          height: null,
        }
      },
      {
        type: 3,
        text: "Email Address",
        icon: <MailOutlined />,
        props: {
          text: "{{emailAddress}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
      {
        type: 4,
        text: "Join Date",
        icon: <CalendarOutlined />,
        props: {
          dateFormat: "DD/MM/YYYY",
          text: "{{joiningDate}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
      {
        type: 5,
        text: "Valid Date",
        icon: <ScheduleOutlined />,
        props: {
          dateFormat: "DD/MM/YYYY",
          text: "{{expireDate}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
      {
        type: 6,
        text: "Current Date",
        icon: <CalendarOutlined />,
        props: {
          dateFormat: "DD/MM/YYYY",
          text: "{{currentDate}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
      {
        type: 7,
        text: "Full Name",
        icon: <UserOutlined />,
        props: {
          text: "{{fullName}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
      {
        type: 8,
        text: "Department",
        icon: <DeploymentUnitOutlined />,
        props: {
          text: "{{departmentName}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
      {
        type: 9,
        text: "Position",
        icon: <SolutionOutlined />,
        props: {
          text: "{{positionName}}",
          color: "#666",
          fontSize: 16,
          width: null,
          height: null,
          textAlign: "center",
        }
      },
    ]
  },
  {
    title: "Picture",
    sub: [
      {
        type: 10,
        text: "Profile Image",
        icon: <CameraOutlined />,
        props: {
          borderRadius: 0,
          text: "{{profileImage}}",
          color: "#666",
          fontSize: 16,
          width: 50,
          height: 50,
        }
      },
      {
        type: 11,
        text: "Signature",
        icon: <FormOutlined />,
        props: {
          text: "{{signatureImageUrl}}",
          color: "#666",
          fontSize: null,
          width: 100,
          height: 50,
        }
      },
      {
        type: 12,
        text: "Company Logo",
        icon: <PictureOutlined />,
        props: {
          text: "{{companyLogo}}",
          color: "#666",
          fontSize: null,
          width: 50,
          height: 50,
        }
      },
      {
        type: 13,
        text: "Qr Code",
        icon: <QrcodeOutlined />,
        props: {
          text: "id",
          fontSize: null,
          size: 24,
          qrValue: "",
        }
      }
    ]
  },
];