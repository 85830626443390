import { schema } from "normalizr";
import { PermissionByRoleArraySchema } from "./permission-by-role";
import { PermissionChildrenByRoleArraySchema } from "./permission-by-role-children";

const PermissionGroupByRoleSchema = new schema.Entity("permissionGroupByRoles", {
  permissions: PermissionByRoleArraySchema,
  children: PermissionChildrenByRoleArraySchema,
});
const PermissionGroupByRoleArraySchema = new schema.Array(PermissionGroupByRoleSchema);

export { PermissionGroupByRoleSchema, PermissionGroupByRoleArraySchema };
