export const BID_LIST_REQUEST = "BID_LIST_REQUEST"; 
export const BID_LIST_SUCCESS = "BID_LIST_REQUEST_SUCCESS";
export const BID_CREATE_REQUEST = "BID_CREATE_REQUEST"; 
export const BID_DETAIL_REQUEST = "BID_DETAIL_REQUEST"; 
export const BID_ARCHIVE_REQUEST = "BID_ARCHIVE_REQUEST"; 
export const BID_DELETE_REQUEST = "BID_DELETE_REQUEST"; 
export const BID_UNARCHIVE_REQUEST = "BID_UNARCHIVE_REQUEST"; 
export const BID_EDIT_REQUEST = "BID_EDIT_REQUEST"; 
export const BID_AUTOCOMPLETE_REQUEST = "BID_AUTOCOMPLETE_REQUEST";
export const BID_AUTOCOMPLETE_SUCCESS = "BID_AUTOCOMPLETE_SUCCESS";

export const BID_SUBMIT_REQUEST = "BID_SUBMIT_REQUEST";
export const BID_APPROVE_REQUEST = "BID_APPROVE_REQUEST";
export const BID_REJECT_REQUEST = "BID_REJECT_REQUEST";
export const BID_POST_REQUEST = "BID_POST_REQUEST";
export const BID_CLOSE_REQUEST = "BID_CLOSE_REQUEST";
export const BID_BACK_TO_DRAFT_REQUEST = "BID_BACK_TO_DRAFT_REQUEST";

export const BID_CREATE_FORM_NAME = "createBid"; 
export const BID_EDIT_FORM_NAME = "editBid"; 

export const BID_SUBMIT_RULES= {
  bidExpiredDate: {
    required: true
  },  
  securityAmount: {
    required: true
  },
  employeeId: {
    required: true
  },
  bidAmount: {
    required: true
  },
  title: {
    required: true
  },
};

export const BID_STATUS_DRAFT = 1;
export const BID_STATUS_DRAFT_TITLE = "Draft";
export const BID_STATUS_SUBMIT = 2;
export const BID_STATUS_SUBMIT_TITLE = "Submitted";
export const BID_STATUS_APPROVE = 3;
export const BID_STATUS_APPROVE_TITLE = "Approved";
export const BID_STATUS_REJECT = 4;
export const BID_STATUS_REJECT_TITLE = "Rejected";
export const BID_STATUS_POST = 5;
export const BID_STATUS_POST_TITLE = "Posted";
export const BID_STATUS_CLOSE = 6;
export const BID_STATUS_CLOSE_TITLE = "Closed";

export const BID_DATA_SOURCE = [
  {
    id: BID_STATUS_DRAFT,
    name: BID_STATUS_DRAFT_TITLE,
  },
  {
    id: BID_STATUS_SUBMIT,
    name: BID_STATUS_SUBMIT_TITLE,
  },
  {
    id: BID_STATUS_APPROVE,
    name: BID_STATUS_APPROVE_TITLE,
  },
  {
    id: BID_STATUS_REJECT,
    name: BID_STATUS_REJECT_TITLE,
  },
  {
    id: BID_STATUS_POST,
    name: BID_STATUS_POST_TITLE,
  },
  {
    id: BID_STATUS_CLOSE,
    name: BID_STATUS_CLOSE_TITLE,
  },
];

export const READ_BID = 246000;
export const CREATE_BID = 246001;
export const UPDATE_BID = 246002;
export const DELETE_BID = 246003;
export const ARCHIVE_BID = 246004;
export const UNARCHIVE_BID = 246005;
export const SUBMIT_BID = 246009;
export const APPROVE_BID = 246010;
export const REJECT_BID = 246011;
export const BACK_TO_DRAFT_BID = 246012;
export const POST_BID = 246013;
export const CLOSE_BID = 246014;