// HR
export const EMPLOYEE_LIST_REPORT = 139010;
export const ATTENDANCE_LOG_REPORT = 135019;
export const JOB_APPLICANT_REPORT = 160010;
export const JOB_OPENING_REPORT = 161010;
export const ATTENDANCE_REPORT = 135010;
export const LEAVE_REQUEST_REPORT = 157010;
export const CASH_ADVANCED_REPORT = 235010;
export const APPRAISAL_ANNIVERSARY_REPORT = 126010;

// INVENTORY
export const STOCK_REPORT = 216010;
export const STOCK_REORDER_REPORT = 216012;
export const DELIVERY_ORDER_REPORT = 199010;
export const ITEM_MOVEMENT_LOG_REPORT = 216011;
export const ITEM_SALE_REPORT = 198021;
export const STOCK_VALUES_REPORT = 216015;
export const STOCK_VALUES_DETAIL_REPORT = 216016;
export const REPORT_SALE_COMMISSION = 198029;
export const PRICE_LIST_REPORT = 207010;
export const ITEM_REPORT = 204010;
export const ASSET_REPORT = 213010;
export const ITEM_EXPIRATION_REPORT = 204026;
export const LENOVO_INVENTORY_REPORT = 216017;
export const ITEM_CHANGE_LOG_REPORT = 204027;
export const SUPPLIER_REPORT = 208010;
export const JUNK_OUT_REPORT = 216019;
export const REPORT_LOGITECH_INVENTORY = 198051;
export const REPORT_APC_INVENTORY = 198052;
export const REPORT_SONICWALL_INVENTORY = 198053;

// ACCOUNTING
export const REPORT_BANK_LOG_CODE = 176010;
export const REPORT_CUSTOMER_REFUND_CODE = 182010;
export const REPORT_CUSTOMER_DEPOSIT_CODE = 181010;
export const REPORT_BALANCE_SHEET_CODE = 178011;
export const REPORT_INCOME_STATEMENT_CODE = 178012;
export const REPORT_ACCOUNTING_RECEIVABLE_DETAIL_CODE = 178013;
export const REPORT_ACCOUNTING_RECEIVABLE_AGEING_SUMMERY_CODE = 178014;
export const REPORT_BANK_CODE = 175010;
export const REPORT_END_OF_DAY_CODE = 198022;
export const REPORT_END_OF_DAY_SUMMARY_CODE = 189011;
export const REPORT_EXPENSE_CODE = 183010;
export const REPORT_VAT_REQUEST = 198032;
export const REPORT_GENERAL_LEDGER = 178019;
export const REPORT_TRAIL_BALANCE = 178020;
export const REPORT_JOURNAL = 178021;
export const REPORT_SUPPLIER_DEPOSIT_LOG = 188012;
export const REPORT_CUSTOMER_DEPOSIT_LOG = 181014;
export const REPORT_CUSTOMER_DEPOSIT_DETAIL = 181015;
export const REPORT_SUPPLIER_DEPOSIT = 188010;
export const REPORT_SUPPLIER_DEPOSIT_DETAIL = 188013;
export const REPORT_SUPPLIER_REFUND = 240010;
export const REPORT_ACCOUNT_STATEMENT = 198037;
export const REPORT_CUSTOMER_COMMISSION = 232011;
export const REPORT_PAID = 198041;
export const REPORT_PURCHASE_ORDER_PAYMENT = 189030;
export const REPORT_SALE_SUMMARY_BY_ITEM = 198056;
export const REPORT_SALE_SUMMARY_BY_CUSTOMER = 198054;
export const REPORT_SALE_SUMMARY_BY_COMPANY = 198055;
export const REPORT_SALE_SUMMARY_BY_EMPLOYEE = 198057;

// Purchase
export const READ_PURCHASE_ORDER_REPORT_CODE = 189010;
export const READ_PURCHASE_ORDER_DETAIL_REPORT_CODE = 189033;
export const READ_PURCHASE_ORDER_SUMMARY_REPORT_CODE = 189020;

// SALE
export const REPORT_CUSTOMER_COMPANY_CODE = 191010;
export const REPORT_COMPANY_RESPONSE_CODE = 191011;
export const REPORT_CUSTOMER_GROUP_CODE = 193010;
export const REPORT_CUSTOMER_CODE = 192010;
export const REPORT_INVOICE_CODE = 198010;
export const REPORT_INVOICE_LIST = 198050;
export const REPORT_PAYMENT_TERM_CODE = 194010;
export const REPORT_QUOTATION_CODE = 195010;
export const REPORT_SALE_ORDER_CODE = 196010;
export const REPORT_SALE_TERM_CODE = 197010;
export const DETAILED_REPORT_SALE_ORDER_CODE = 196011;
export const DETAILED_REPORT_SALE_PM_ORDER_CODE = 198047;
export const REPORT_ITEM_INSTALLATION_SUMMARY_CODE = 237014;
export const VIEW_SALE_TEAM_DETAIL_SALE_REPORT = 198045;
export const VIEW_ALL_SALE_TEAM_DETAIL_SALE_REPORT = 198046;
export const DETAILED_REPORT_SALE_REPORT_ORDER_CODE = 198047;
export const SERVICE_SALE_REPORT = 238010;
export const DETAIL_SALE_WITH_INCOME_REPORT = 198058;

export const BALANCE_SHEET_REPORT_CODE = 49001;
export const INCOME_STATEMENT_REPORT_CODE = 49002;
export const ITEM_MOVEMENT_REPORT_CODE = 49003;
export const EXPENSE_REPORT_CODE = 42008;
export const A_R_AGING_DETAIL_REPORT_CODE = 36011;
export const JOURNAL_ENTRY_REPORT_CODE = 31008;
export const CUSTOMER_LIST_REPORT = 24007;
export const CUSTOMER_BALANCE_REPORT = 24008;
export const ITEM_LIST_REPORT_CODE = 60011;
export const ITEM_AGING_REPORT_CODE = 189025;
export const ITEM_FORECASTING_REPORT = 204025;
export const LENOVO_SELL_THRU_REPORT = 198036;

export const REPORT_SELL_OUT = 198040;
export const REPORT_GOOD_IN = 189029;
export const REPORT_DELL_INVENTORY = 216018;
export const REPORT_HP_INVENTORY = 198048;
export const REPORT_HP_SELL_OUT = 198048;
export const STOCK_REPORT_QUANTITY_ALL_KEY = 1;
export const STOCK_REPORT_QUANTITY_ALL_TITLE = "All";
export const STOCK_REPORT_QUANTITY_IN_STOCK_KEY = 2;
export const STOCK_REPORT_QUANTITY_IN_STOCK_TITLE = "In stock";
export const STOCK_REPORT_QUANTITY_OUT_OF_STOCK_KEY = 3;
export const STOCK_REPORT_QUANTITY_OUT_OF_STOCK_TITLE = "Out of Stock";
export const STOCK_REPORT_QUANTITY_DATA_SOURCE = [
  {
    id: STOCK_REPORT_QUANTITY_ALL_KEY,
    name: STOCK_REPORT_QUANTITY_ALL_TITLE,
  },
  {
    id: STOCK_REPORT_QUANTITY_IN_STOCK_KEY,
    name: STOCK_REPORT_QUANTITY_IN_STOCK_TITLE,
  },
  {
    id: STOCK_REPORT_QUANTITY_OUT_OF_STOCK_KEY,
    name: STOCK_REPORT_QUANTITY_OUT_OF_STOCK_TITLE,
  },
];

export const BANK_DETAILED_TYPE_DEPOSIT_KEY = 1;
export const BANK_DETAILED_TYPE_DEPOSIT_TITLE = "Deposit";
export const BANK_DETAILED_TYPE_WITHDRAW_KEY = 2;
export const BANK_DETAILED_TYPE_WITHDRAW_TITLE = "Withdraw";
export const BANK_DETAILED_TYPE_TRANSFER_KEY = 3;
export const BANK_DETAILED_TYPE_TRANSFER_TITLE = "Transfer";
export const BANK_DETAILED_TYPE_DATA_SOURCE = [
  {
    id: BANK_DETAILED_TYPE_DEPOSIT_KEY,
    name: BANK_DETAILED_TYPE_DEPOSIT_TITLE,
  },
  {
    id: BANK_DETAILED_TYPE_WITHDRAW_KEY,
    name: BANK_DETAILED_TYPE_WITHDRAW_TITLE,
  },
  {
    id: BANK_DETAILED_TYPE_TRANSFER_KEY,
    name: BANK_DETAILED_TYPE_TRANSFER_TITLE,
  },
];

export const FAVORITE_REPORT_LIST_REQUEST = "FAVORITE_REPORT_LIST_REQUEST";
export const FAVORITE_REPORT_LIST_SUCCESS =
  "FAVORITE_REPORT_LIST_REQUEST_SUCCESS";

export const FAVORITE_REPORT_CREATE_REQUEST = "FAVORITE_REPORT_CREATE_REQUEST";
export const FAVORITE_REPORT_CREATE_SUCCESS = "FAVORITE_REPORT_CREATE_SUCCESS";

export const FAVORITE_REPORT_DELETE_REQUEST = "FAVORITE_REPORT_DELETE_REQUEST";
export const FAVORITE_REPORT_DELETE_SUCCESS = "FAVORITE_REPORT_DELETE_SUCCESS";

export const BALANCE_TYPE_ALL = 1;
export const BALANCE_TYPE_ALL_TITLE = "All";
export const BALANCE_TYPE_BIGGEST_THAN_ZERO = 2;
export const BALANCE_TYPE_BIGGEST_THAN_ZERO_TITLE = "> 0";
export const BALANCE_TYPE_EQUAL_ZERO = 3;
export const BALANCE_TYPE_EQUAL_ZERO_TITLE = "= 0";
export const BALANCE_TYPE_DATA_SOURCE = [
  {
    id: BALANCE_TYPE_ALL,
    name: BALANCE_TYPE_ALL_TITLE,
  },
  {
    id: BALANCE_TYPE_BIGGEST_THAN_ZERO,
    name: BALANCE_TYPE_BIGGEST_THAN_ZERO_TITLE,
  },
  { id: BALANCE_TYPE_EQUAL_ZERO, name: BALANCE_TYPE_EQUAL_ZERO_TITLE },
];

export const ACCOUNTING_STATEMENT_REPORT_SALE_DATE = 1;
export const ACCOUNTING_STATEMENT_REPORT_SALE_DATE_TITLE = "Sale Date";

export const ACCOUNTING_STATEMENT_REPORT_PAID_DATE = 2;
export const ACCOUNTING_STATEMENT_REPORT_PAID_DATE_TITLE = "Paid Date";

export const ACCOUNTING_STATEMENT_REPORT_DUE_DATE = 3;
export const ACCOUNTING_STATEMENT_REPORT_DUE_DATE_TITLE = "Due Date";

export const ACCOUNTING_STATEMENT_REPORT_INVOICE_DATE = 4;
export const ACCOUNTING_STATEMENT_REPORT_INVOICE_DATE_TITLE = "Invoice Date";

export const ACCOUNTING_STATEMENT_REPORT_DATA_SOURCE = [
  {
    id: ACCOUNTING_STATEMENT_REPORT_SALE_DATE,
    name: ACCOUNTING_STATEMENT_REPORT_SALE_DATE_TITLE,
  },
  {
    id: ACCOUNTING_STATEMENT_REPORT_PAID_DATE,
    name: ACCOUNTING_STATEMENT_REPORT_PAID_DATE_TITLE,
  },
  {
    id: ACCOUNTING_STATEMENT_REPORT_DUE_DATE,
    name: ACCOUNTING_STATEMENT_REPORT_DUE_DATE_TITLE,
  },
  {
    id: ACCOUNTING_STATEMENT_REPORT_INVOICE_DATE,
    name: ACCOUNTING_STATEMENT_REPORT_INVOICE_DATE_TITLE,
  },
];
