export const COMPANY_GROUP_LIST_REQUEST = "COMPANY_GROUP_LIST_REQUEST"; 
export const COMPANY_GROUP_LIST_SUCCESS = "COMPANY_GROUP_LIST_REQUEST_SUCCESS";
export const COMPANY_GROUP_CREATE_REQUEST = "COMPANY_GROUP_CREATE_REQUEST"; 
export const COMPANY_GROUP_DETAIL_REQUEST = "COMPANY_GROUP_DETAIL_REQUEST"; 
export const COMPANY_GROUP_ARCHIVE_REQUEST = "COMPANY_GROUP_ARCHIVE_REQUEST"; 
export const COMPANY_GROUP_DELETE_REQUEST = "COMPANY_GROUP_DELETE_REQUEST"; 
export const COMPANY_GROUP_UNARCHIVE_REQUEST = "COMPANY_GROUP_UNARCHIVE_REQUEST"; 
export const COMPANY_GROUP_EDIT_REQUEST = "COMPANY_GROUP_EDIT_REQUEST"; 
export const COMPANY_GROUP_CREATE_FORM_NAME = "createCompanyGroup"; 
export const COMPANY_GROUP_EDIT_FORM_NAME = "editCompanyGroup"; 
export const COMPANY_GROUP_AUTOCOMPLETE_REQUEST = "COMPANY_GROUP_AUTOCOMPLETE_REQUEST";
export const COMPANY_GROUP_AUTOCOMPLETE_SUCCESS = "COMPANY_GROUP_AUTOCOMPLETE_SUCCESS";
export const COMPANY_GROUP_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  code: {
    required: true
  }
};
export const READ_COMPANY_GROUP = 193000;
export const CREATE_COMPANY_GROUP = 193001;
export const UPDATE_COMPANY_GROUP = 193002;
export const DELETE_COMPANY_GROUP = 193003;
export const ARCHIVE_COMPANY_GROUP = 193004;
export const UNARCHIVE_COMPANY_GROUP = 193005;