// Libs
import React from "react";
import PropTypes from "prop-types";
import { Form, Button } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { reduxForm, SubmissionError } from "redux-form";

// Component
import Input from "@modules/cores/components/Input";

// Helper
import { validate } from "src/helpers";
import { resetPasswordSendCode } from "src/services/authentication";

const RESET_PASSWORD_REQUEST_RULES = {
  email: {
    required: true,
    type: "email"
  }, 
};

function ResetPasswordForm(props) {

  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values) => {
    let error = null;
    error = validate(RESET_PASSWORD_REQUEST_RULES, values);
    if (error != null) {
      throw new SubmissionError(error);
    } else {
      setLoading(true);
      const newValues = {...values};
      await resetPasswordSendCode(newValues).then(() => {
        setLoading(false);
        if (props.onSetEmail) {
          props.onSetEmail(newValues.email);
        }
      }).catch((error) => {
        setLoading(false);
        if (error) {
          throw new SubmissionError({email: "Email does not existed"});
        }
      });
    }
  };

  const { handleSubmit } = props;
  return (
    <>
      <Form onFinish={handleSubmit(onFinish)} layout="vertical">
        <Input
          name="email"
          placeholder="Email"
          icon={<MailOutlined />}
        />
        <Button
          block
          ghost
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Continue
        </Button>
        <div className="d-flex align-items-center justify-content-center mt-1">
          Remember Password ? go to <Link to="login" className="ml-2">Login</Link>
        </div>
      </Form>
    </>
  );
}

ResetPasswordForm.propTypes = {
  onSetEmail: PropTypes.func,
  handleSubmit: PropTypes.func,
};

const mapStateToProps = () => {
  const subDomain = location.host.split(".")[0];
  return {
    initialValues: {
      subDomain,
    }
  };
};

export default connect(mapStateToProps)(reduxForm()(ResetPasswordForm));