import React, { Component } from "react";
import PropTypes from "prop-types";

import AttendanceList from "@modules/hr/attendance/attendance-entry/pages/attendance-list";

class Attendance extends Component {

  static propTypes = {
    id: PropTypes.number
  }

  render() {
    const { id }= this.props;
    return (
      <AttendanceList 
        isCurrentUserPage={true}
        extraFilter={(filters) => {
          filters.employee = [id];
          return filters;
        }}
      />
    );
  }
}

export default Attendance;