import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";

class NoPermission extends React.Component {
  
  render() {
    return (
      <Result
        status="403"
        title="Sorry, you do not have permission to perform this action"
        className="m-4"
        extra={
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/">
              <Button 
                ghost 
                type="primary" 
                className="mr-1" 
                icon={<HomeOutlined />}
              >
                Return Home 
              </Button>
            </Link>
              or contact your administrator for more info. 
          </div>
        }
      />
    );
  }
}

export default NoPermission;