// Lib
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Typography } from "antd";

// Component
import Input from "@cores/components/Input";
import TimePicker from "@cores/components/time-picker";
import InputNumber from "@cores/components/input-number";
import Select from "@cores/components/autocomplete-static";
import DepartmentAutoComplete from "@cores/components/auto-complete/department";
import EmployeeAutoComplete from "@cores/components/auto-complete/employee";

// Constants
import { DEPARTMENT_STATUS } from "../../constants";
const { Title } = Typography;

class FormComponent extends Component {

  render() {
    const { editable } = this.props;
    const managerId = [];
    const departmentId = [];
    if (editable) {
      const { entity: { manager, parent } } = this.props;
      managerId.push(manager && manager.id);
      departmentId.push(parent && parent.id);
    }
    return (
      <Fragment>
        <Row>
          <Col md="6" xs="12">
            <Input
              autoFocus
              required
              name="name"
              label="Name"
            />
          </Col>
          <Col md="6" xs="12">
            <Input
              required
              name="code"
              label="Code"
            />
          </Col>
          <Col md="6" xs="12">
            <EmployeeAutoComplete 
              name="managerId"
              label="Manager" 
              required={false}
              ids={managerId}
            />
          </Col>
          <Col md="6" xs="12">
            <DepartmentAutoComplete 
              name="parentId"
              label="Parent Department" 
              required={false}
              ids={departmentId}
            />
          </Col>
          <Col md="6" xs="12">
            <Select 
              required
              label="Status"
              name="status"
              dataSource={DEPARTMENT_STATUS}
            />
          </Col>
        </Row>
        <Title level={4} className="text-primary">
          Working Hours
        </Title>
        <hr />
        <Row>
          <Col md="6" xs="12">
            <TimePicker 
              required
              label="Morning In" 
              name="morningIn" 
            />
          </Col>
          <Col md="6" xs="12">
            <TimePicker 
              required
              label="Morning Out" 
              name="morningOut"
            />
          </Col>
          <Col md="6" xs="12">
            <TimePicker 
              required
              label="Afternoon In" 
              name="afternoonIn" 
            />
          </Col>
          <Col md="6" xs="12">
            <TimePicker 
              required
              label="Afternoon Out" 
              name="afternoonOut" 
            />
          </Col>
          <Col md="6" xs="12">
            <InputNumber 
              required
              name="numberOfBreakingTimeLimit"
              label="Number of Breaking Time Limit (Hour)"
            />
          </Col>
          <Col md="6" xs="12">
            <InputNumber 
              required
              name="breakTime"
              label="Break Time (Minute)"
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

FormComponent.propTypes = {
  editable: PropTypes.bool,
  entity: PropTypes.object,
};


export default FormComponent;  