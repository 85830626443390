import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Form, Button, Spin } from "antd";
import { FieldArray, reduxForm } from "redux-form";
import { SaveOutlined, LoadingOutlined } from "@ant-design/icons";

// Components
import Title from "@cores/components/title";
import { renderCustomFieldValue } from "@modules/settings/setup/custom-field/helpers";
import CustomFieldField from "@modules/settings/setup/custom-field/components/render-input";

function submitForm (values, dispatch, props) {
  const { handleSubmitHandler } = props;
  if (handleSubmitHandler) {
    return handleSubmitHandler(values, dispatch);
  }
}

class FormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.getCustomField();
  }

  getCustomField = () => {
    const { getCustomFieldByEntity, entity } = this.props;
    if (entity != null) {
      const entityName = entity.replace(/\s/g, "");
      getCustomFieldByEntity(entityName); 
    }
  }

  render() {
    const { 
      onSave,
      handleSubmit,
      createLoading,
      loadingCustomField,
      customFieldDataSource,
      formComponent: FormComponent, 
    } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <Form onFinish={() => handleSubmit()} layout="vertical">
        {loadingCustomField == true ? (
          <Spin indicator={antIcon} className="d-block" tip="loading..." />
        ) : (
          <Fragment>
            <FormComponent {...this.props} />
            {customFieldDataSource && customFieldDataSource.length > 0 && (
              <Fragment>
                <Title title="Additional info" />
                <FieldArray 
                  name="customFieldsArrayField" 
                  component={CustomFieldField} 
                  customFieldDataSource={customFieldDataSource}
                />
              </Fragment>
            )}
            <br />
            <Row>
              <Col xs="12">
                <Button 
                  ghost
                  type="primary" 
                  onClick={onSave}
                  icon={<SaveOutlined />}
                  className="float-right"
                  loading={createLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </Form>
    );
  }
}

FormComponent.propTypes = {
  onSave: PropTypes.node,
  onClick: PropTypes.node,
  entity: PropTypes.string,
  handleSubmit: PropTypes.func,
  formComponent: PropTypes.node,
  createLoading: PropTypes.func,
  loadingCustomField: PropTypes.bool,
  handleSubmitHandler: PropTypes.func,
  customFieldDataSource: PropTypes.array,
  getCustomFieldByEntity: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  let { initialValues={}, customFieldDataSource } = ownProps;
  if (customFieldDataSource && customFieldDataSource.length > 0) {
    const customFieldsArrayField = [];
    customFieldDataSource.map((value) => {
      const { name, id, type, customFieldValue } = value;
      customFieldsArrayField.push({
        [`${id}_${name}`]: customFieldValue != null ? renderCustomFieldValue(customFieldValue.value, type) : null
      });
    });
    initialValues = Object.assign(initialValues, {customFieldsArrayField});
  }
  return {
    initialValues: {
      ...initialValues
    }
  };
};


const RenderFormComponentConnect = reduxForm({
  onSubmit: submitForm,
  enableReinitialize: true, 
  keepDirtyOnReinitialize : true 
})(FormComponent);

export default connect(mapStateToProps)(RenderFormComponentConnect);