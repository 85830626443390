import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Button, message, Form } from "antd";
import { reduxForm, SubmissionError } from "redux-form";
import { LockOutlined, CheckCircleOutlined } from "@ant-design/icons";

// Components
import Input from "@cores/components/Input";

// Services
import { loginUserPassword } from "@/services/authentication";
import { MACHINE_ID } from "@/constants/authentication";

// utils
import { validate } from "@/helpers";

class Login extends React.Component {

  state = {
    loading: false
  }

  onCancel = () => {
    this.setState({
      loading: false
    });
  }

  onSubmit = (values) => {
    this.setState({
      loading: true
    });
    const { onSuccess, onDismiss, viewDetail } = this.props;
    const rules = {
      username: {
        required: true,
      },
      password: {
        required: true
      }
    };
    let error = null;
    error = validate(rules, values);
    if (error != null) {
      this.onCancel();
      throw new SubmissionError(error);
    } else {
      loginUserPassword(values).then((data) => {
        if (data != null && onSuccess) {
          if (onDismiss) {
            onDismiss();
          }
          onSuccess(viewDetail);
          this.onCancel();
        }
      }).catch((error) => {
        message.error(error.message);
        this.onCancel();
      });
    }
  }

  render() {
    const { loading } = this.state;
    const { handleSubmit } = this.props;
    const submit = handleSubmit(this.onSubmit);
    return (
      <Form onFinish={submit} layout="vertical">
        <Input
          autoFocus
          type="Password"
          name="password"
          icon={<LockOutlined />}
          placeholder="Password"
          autoComplete="new-password"
        />
        <Row>
          <Col xs="12">
            <Button
              block
              ghost
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<CheckCircleOutlined />}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

Login.propTypes = {
  onSuccess: PropTypes.func,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  updateField: PropTypes.func,
  viewDetail: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { username } = ownProps;
  const subDomain = location.host.split(".")[0];
  const machineId = localStorage.getItem(MACHINE_ID);
  return {
    initialValues: {
      username,
      subDomain,
      machineId
    }
  };
};

const connectReduxForm = reduxForm()(Login);

export default connect(mapStateToProps)(connectReduxForm);