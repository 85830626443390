import { 
  LeaveRequestSchema,
  LeaveRequestArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as LeaveRequestConstants from "../constants";

const path = "/human-resource/leave/leave-request";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLeaveRequest,
    schema: LeaveRequestArraySchema,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createLeaveRequest = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createLeaveRequest,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getLeaveRequestAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getLeaveRequestAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getLeaveRequestDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getLeaveRequestDetail,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: LeaveRequestSchema,
  };
  return createDetailAction(options, id);
};

export const editLeaveRequest = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editLeaveRequest,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "leaveRequests",
    schema: LeaveRequestSchema,
    successMessage: "Leave request updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveLeaveRequest = (id, noRedirect) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveLeaveRequest,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: noRedirect ? false : path,
    successMessage: "Leave request archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteLeaveRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteLeaveRequest,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Leave request deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveLeaveRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveLeaveRequest,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave request unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const updateStatusToSubmit = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToSubmit,
    schema: LeaveRequestSchema,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave request is submitted successfully!",
  };

  return createEntityActionAction(options, id);
};

export const updateStatusToApprove = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToApprove,
    schema: LeaveRequestSchema,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave request is approved successfully!",
  };

  return createEntityActionAction(options, id);
};

export const updateStatusToCancel = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToCancel,
    schema: LeaveRequestSchema,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave request is cancelled successfully!",
  };

  return createEntityActionAction(options, id);
};

export const updateStatusToReject = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateStatusToReject,
    schema: LeaveRequestSchema,
    requestName: LeaveRequestConstants.LEAVE_REQUEST_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Leave request is rejected successfully!",
  };

  return createEntityActionAction(options, id);
};