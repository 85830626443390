import { POST, POSTWithToken } from "./base-service";
import { getBasicToken } from "@/shared/utils";

const AUTH_URL = "/api/oauth2/v1";

export const authenticateClientCredential = (values) => {
  const { username, password } = values;
  const base64 = new Buffer(`${username}:${password}`).toString("base64");
  const token = `Basic ${base64}`;
  const headers = {
    Authorization: token
  };
  const url = `${AUTH_URL}/credential`;
  return POST(url, null, headers);
};

export const loginUserPassword = (values) => {
  const token = `Bearer ${getBasicToken().accessToken}`;
  const headers = {
    Authorization: token
  };
  const url = `${AUTH_URL}/login`;
  return POST(url, values, headers);
};

export const logOut = () => {
  const url = "/api/user/v1/logout";
  return POSTWithToken(url);
};

export const loginUserPasswordIfLockScreen = (values) => {
  const token = `Bearer ${getBasicToken().accessToken}`;
  const headers = {
    Authorization: token
  };
  const url = `${AUTH_URL}/login`;
  return POST(url, values, headers);
};

export const resetPassword = (values) => {
  const token = `Bearer ${getBasicToken().accessToken}`;
  const headers = {
    Authorization: token
  };
  const url = `${AUTH_URL}/reset-password`;
  return POST(url, values, headers);
};

export const resetPasswordSendCode = (values) => {
  const token = `Bearer ${getBasicToken().accessToken}`;
  const headers = {
    Authorization: token
  };
  const url = `${AUTH_URL}/send-code`;
  return POST(url, values, headers);
};

export const resetPasswordVerifyCode = (values) => {
  const token = `Bearer ${getBasicToken().accessToken}`;
  const headers = {
    Authorization: token
  };
  const url = `${AUTH_URL}/verify-code`;
  return POST(url, values, headers);
};

export const resetVerifyCode = (values) => {
  const token = `Bearer ${getBasicToken().accessToken}`;
  const headers = {
    Authorization: token
  };
  const url = "/api/authenticator/v1/verify";
  return POST(url, values, headers);
};