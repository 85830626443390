import { schema } from "normalizr";
import { WarehouseSchema } from "../../warehouse/schema";
import { StockOutItemArraySchema } from "./stock-out-item";
import { CustomerSchema } from "@sale/customer-center/customer/schema";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";

const StockOutSchema = new schema.Entity("stockOuts", {
  warehouse: WarehouseSchema,
  customer: CustomerSchema,
  employee: EmployeeSchema,
  stockOutItems: StockOutItemArraySchema,
}, {
  idAttribute: "stockOutNo"
});

const StockOutArraySchema = new schema.Array(StockOutSchema);

export { StockOutSchema, StockOutArraySchema };