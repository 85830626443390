export const READ_EMPLOYEE_SETTING_CODE = 139016;
export const UPDATE_EMPLOYEE_SETTING_CODE = 139017;

export const EMPLOYEE_CODE_TYPE_MANUALLY_ID = 1;
export const EMPLOYEE_CODE_TYPE_MANUALLY_NAME = "Manually";
export const EMPLOYEE_CODE_TYPE_AUTO_ID = 2;
export const EMPLOYEE_CODE_TYPE_AUTO_NAME = "Auto";

export const EMPLOYEE_SETTING_PATH = "/human-resource/employee-directory/employee-setting";

export const renderEmployeeCodeNoSeriesList = (id) => {
  let status = null;
  switch(id) {
  case EMPLOYEE_CODE_TYPE_MANUALLY_ID:
    status = EMPLOYEE_CODE_TYPE_MANUALLY_NAME;
    break;
  case EMPLOYEE_CODE_TYPE_AUTO_ID:
    status = EMPLOYEE_CODE_TYPE_AUTO_NAME;
    break;
  default:
    status;
  }
  return status;
};

export const EMPLOYEE_CODE_TYPE_DATA_SOURCE = [
  {
    id: EMPLOYEE_CODE_TYPE_MANUALLY_ID,
    name: EMPLOYEE_CODE_TYPE_MANUALLY_NAME,
  },
  {
    id: EMPLOYEE_CODE_TYPE_AUTO_ID,
    name: EMPLOYEE_CODE_TYPE_AUTO_NAME,
  }
];

export const EMPLOYEE_SETTING_SUBMIT_RULES = {
  morningIn: {
    required: true,
  },
  morningOut: {
    required: true,
  },
  afternoonIn: {
    required: true,
  },
  afternoonOut: {
    required: true,
  },
  hrManagerId: {
    type: "object",
    value: {
      required: true
    }
  }
};