export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_REQUEST_SUCCESS";
export const SUPPLIER_CREATE_REQUEST = "SUPPLIER_CREATE_REQUEST";
export const SUPPLIER_DETAIL_REQUEST = "SUPPLIER_DETAIL_REQUEST";
export const SUPPLIER_ARCHIVE_REQUEST = "SUPPLIER_ARCHIVE_REQUEST";
export const SUPPLIER_DELETE_REQUEST = "SUPPLIER_DELETE_REQUEST";
export const SUPPLIER_UNARCHIVE_REQUEST = "SUPPLIER_UNARCHIVE_REQUEST";
export const SUPPLIER_EDIT_REQUEST = "SUPPLIER_EDIT_REQUEST";
export const SUPPLIER_AUTO_COMPLETE_REQUEST = "SUPPLIER_AUTO_COMPLETE_REQUEST";
export const SUPPLIER_AUTO_COMPLETE_SUCCESS = "SUPPLIER_AUTO_COMPLETE_REQUEST_SUCCESS";
export const SUPPLIER_CREATE_FORM_NAME = "createSupplier";
export const SUPPLIER_EDIT_FORM_NAME = "editSupplier";

export const READ_SUPPLIER = 208000;
export const CREATE_SUPPLIER = 208001;
export const UPDATE_SUPPLIER = 208002;
export const DELETE_SUPPLIER = 208003;
export const ARCHIVE_SUPPLIER = 208004;
export const UNARCHIVE_SUPPLIER = 208005;
export const ASSIGN_CHART_OF_ACCOUNT_SUPPLIER = 208012;
export const VIEW_CHART_OF_ACCOUNT_SUPPLIER = 208011;

export const EDIT_SUPPLIER_SUBMIT_RULE = {
  name: {
    required: true,
  },
  payTo: {
    required: true
  },
  supplierTypeId: {
    required: true,
  },
  title: {
    required: true,
  },
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  emailAddress: {
    type: "email",
  },
  phone1: {
    required: true,
  },
};