import React from "react";
import { 
  Input,
  Modal,
  AutoComplete,
} from "antd";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

// components
import ShortCutForm from "./form";
import HeaderSearchCollapse from "../header-search";

// util
import resource from "@/resources/page-search";
import { createSubmitAction } from "@/helpers";
import { history } from "@/shared/utils/history";

const Option = AutoComplete.Option;

class SearchBox extends React.Component {

  state = {
    result: [],
    slug: null,
    value: null,
    visible: false,
  }

  handleSearch = (value) => {
    const newValues = value.toLowerCase();
    if (value) {
      const result = resource.filter((str) => {
        const name = str.name.toLowerCase();
        return name.includes(newValues);
      }); 
      this.setState({
        value: value,
        result: result,
      });
    } else {
      this.setState({
        value: null,
        result: [],
      });
    }
  }

  onModalClose = () => {
    this.setState({
      visible: false,
      slug: null,
      value: null,
      result: [],
      type: [],
    });
  }

  onModalOpen = (slug, type) => {
    this.setState({
      visible: true,
      slug: slug,
      type,
    });
  }

  onClick = (slug, type) => {
    if (type == "edit") {
      this.onModalOpen(slug, type);
    } else if (type == "list") {
      history.push(slug);
      this.onModalClose();
    } else if (type == "detail") {
      this.onModalOpen(slug, type);
    } else if (type == "print") {
      this.onModalOpen(slug, type);
    } else {
      history.push(`${slug}/${type}`);
      this.onModalClose();
    }
  }

  onSelect = (data) => {
    const { slug, type } = data;
    this.onClick(slug, type);
  }

  onLeaveMode = () => {
    this.setState({
      value: null,
      result: [],
    });
  }

  onSubmit = (values, dispatch) => {
    const { slug, type } = this.state;
    const { editId } = values;
    const rule = {
      editId: {
        required: true
      }
    };
    return createSubmitAction(rule, () => {
      this.onModalClose();
      if (type == "edit") {
        return history.push(`${slug}/${editId}/edit`);
      } else if (type == "print") {
        return history.push(`${slug}/${editId}/print`);
      } else {
        return history.push(`${slug}/${editId}`);
      }
    },
    null,
    false,
    )(values, dispatch);
  }

  render() { 
    const { result, value, visible } = this.state;
    const { width, isCollapse } = this.props;
    const children = result.map((value, index) => {
      const { name, slug, type } = value;
      return (
        <Option 
          key={index} 
          onClick={() => this.onClick(slug, type)}
        >
          {name}
        </Option>
      );
    });
    return (
      <React.Fragment>
        {isCollapse ? (
          <HeaderSearchCollapse 
            value={value}
            dataSource={children}
            placeholder="Type page name..."
            onSearch={this.handleSearch}
            onLeaveMode={this.onLeaveMode}
            onSelect={(value, option) => this.onSelect(result[option.key])}
          />
        ) : (
          <React.Fragment>
            <AutoComplete
              value={value}
              className="mr-3"
              dataSource={children}
              style={{ width: width }}
              onSearch={this.handleSearch}
              placeholder="Type page name..."
            >
              <Input suffix={<SearchOutlined />} />
            </AutoComplete>
          </React.Fragment>
        )}
        <Modal
          title={null}
          width="400px"
          footer={null}
          visible={visible}
          destroyOnClose={true}
          onCancel={this.onModalClose}
        >
          {visible && (
            <ShortCutForm 
              onSubmit={this.onSubmit} 
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

SearchBox.propTypes = {
  width: PropTypes.any,
  isCollapse: PropTypes.bool,
};

SearchBox.defaultProps = {
  width: 200,
  isCollapse: true,
};

export default withRouter(SearchBox);