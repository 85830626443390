// Libs
import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

// Components
import Input from "@cores/components/Input";
import CheckBox from "@cores/components/check-box";
import InputNumber from "@cores/components/input-number";
import ChartOfAccountAutoComplete from "@cores/components/auto-complete/chart-of-account";
import ChartOfAccountTypeAutoComplete from "@cores/components/auto-complete/chart-of-account-type";

class FormComponent extends React.Component {

  render() {
    const {
      editable,
      isCheque,
      isBankingAccount
    } = this.props;
    const chartOfAccountTypeIds = [];
    const parentIds = [];
    let disabled = false;
    if (editable) {
      const { 
        entity: {
          chartAccountType,
          parent,
          isSystem,
        } 
      } = this.props;
      disabled = Boolean(isSystem);
      chartOfAccountTypeIds.push(chartAccountType && chartAccountType.id);
      parentIds.push(parent && parent.id);
    }
    return (
      <Row>
        <Col md="6" xs="12">
          <Input
            required
            autoFocus
            name="name"
            label="Name"
          />
        </Col>
        <Col md="6" xs="12">
          <InputNumber
            required
            name="chartAccountNo"
            label="Account No"
          />
        </Col>
        <Col md="6" xs="12">
          <ChartOfAccountTypeAutoComplete 
            ids={chartOfAccountTypeIds} 
            disabled={disabled}
          />
        </Col>
        <Col md="6" xs="12"> 
          <ChartOfAccountAutoComplete 
            ids={parent}
            required={false}
            name="parentId"
            label="Sub Account Of"
            disabledParent={false}
          />
        </Col>
        <Col xs="12" className="d-flex">
          <CheckBox
            className="mb-0"
            name="isBankingAccount"
            label="Is Banking"
            disabled={disabled || isCheque}
          />
          <CheckBox
            className="mb-0"
            name="isCheque"
            label="Is Cheque"
            disabled={disabled || isBankingAccount}
          />
        </Col>
      </Row>
    );
  }
}

FormComponent.propTypes ={
  entity: PropTypes.object,
  editable: PropTypes.bool,
  isCheque: PropTypes.bool,
  isBankingAccount: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const selector = formValueSelector(formName);
  const isCheque = selector(state, "isCheque");
  const isBankingAccount = selector(state, "isBankingAccount");
  return {
    isCheque,
    isBankingAccount,
  };
};

export default connect(mapStateToProps, null)(FormComponent);