export const JOB_APPLICANT_LIST_REQUEST = "JOB_APPLICANT_LIST_REQUEST"; 
export const JOB_APPLICANT_LIST_SUCCESS = "JOB_APPLICANT_LIST_REQUEST_SUCCESS";
export const JOB_APPLICANT_CREATE_REQUEST = "JOB_APPLICANT_CREATE_REQUEST"; 
export const JOB_APPLICANT_DETAIL_REQUEST = "JOB_APPLICANT_DETAIL_REQUEST"; 
export const JOB_APPLICANT_ARCHIVE_REQUEST = "JOB_APPLICANT_ARCHIVE_REQUEST"; 
export const JOB_APPLICANT_DELETE_REQUEST = "JOB_APPLICANT_DELETE_REQUEST"; 
export const JOB_APPLICANT_UNARCHIVE_REQUEST = "JOB_APPLICANT_UNARCHIVE_REQUEST"; 
export const JOB_APPLICANT_EDIT_REQUEST = "JOB_APPLICANT_EDIT_REQUEST"; 
export const JOB_APPLICANT_CREATE_FORM_NAME = "createJobApplicant"; 
export const JOB_APPLICANT_EDIT_FORM_NAME = "editJobApplicant"; 
export const JOB_APPLICANT_AUTOCOMPLETE_REQUEST = "JOB_APPLICANT_AUTOCOMPLETE_REQUEST";
export const JOB_APPLICANT_AUTOCOMPLETE_SUCCESS = "JOB_APPLICANT_AUTOCOMPLETE_SUCCESS";
export const JOB_APPLICANT_UPDATE_STAGE_REQUEST = "JOB_APPLICANT_UPDATE_STAGE_REQUEST";
export const JOB_APPLICANT_UPDATE_STAGE_FORM_NAME = "moveRecruitmentStageForm";

export const JOB_APPLICANT_KANBAN_REQUEST = "JOB_APPLICANT_KANBAN_REQUEST";
export const JOB_APPLICANT_KANBAN_SUCCESS = "JOB_APPLICANT_KANBAN_SUCCESS";
export const JOB_APPLICANT_REORDER_RECRUITMENT_STAGE_REQUEST = "JOB_APPLICANT_REORDER_RECRUITMENT_STAGE_REQUEST";
export const JOB_APPLICANT_REORDER_RECRUITMENT_STAGE_SUCCESS = "JOB_APPLICANT_REORDER_RECRUITMENT_STAGE_SUCCESS";
export const JOB_APPLICANT_REORDER_REQUEST = "JOB_APPLICANT_REORDER_REQUEST";
export const JOB_APPLICANT_REORDER_SUCCESS = "JOB_APPLICANT_REORDER_SUCCESS";

export const grid = 8;
export const borderRadius = 5;
export const colors = {
  black: "#4d4d4d",
  grey: {
    darker: "#C1C7D0",
    dark: "#E2E4E6",
    medium: "#f0f2f5",
    N30: "#EBECF0",
    light: "#F4F5F7",
  },
  white: "white",
  purple: "rebeccapurple",
};

export const JOB_APPLICANT_SUBMIT_RULES = {
  name: {
    required: true,
  },
  emailAddress: {
    required: true,
    type: "email"
  },
  contactNumber: {
    required: true
  },
  jobOpeningId: {
    required: true
  },
  recruitmentStageId: {
    required: true
  },
  curriculumVitaeUrl: {
    required: true
  }
};

export const UPDATE_STAGE_SUBMIT_RULES = {
  stageId: {
    required: true
  }
};

export const READ_JOB_APPLICANT = 160000;
export const CREATE_JOB_APPLICANT = 160001;
export const UPDATE_JOB_APPLICANT = 160002;
export const DELETE_JOB_APPLICANT = 160003;
export const ARCHIVE_JOB_APPLICANT = 160004;
export const UNARCHIVE_JOB_APPLICANT = 160005;