import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/company/branch/v1";

export const getCompany = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createCompany(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getCompanyAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getCompanyDetail(id) {
  return GETDetail(API_URL, id);
}

export function editCompany(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveCompany(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveCompany(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteCompany(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}