import React from "react";
import { 
  UserOutlined,
  LogoutOutlined, 
  // SettingOutlined, 
} from "@ant-design/icons";
import styled from "styled-components";
import { Avatar, Menu, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

// Components
import HeaderDropdown from "../header-dropdown";
import { getCurrentUser, isModulesInstalled } from "@modules/utils/value-selector";

// Styles
import "./index.less";

// Action
import { logOut } from "@/actions/authentication";

// Helper
import { modalConfirmDialog } from "@modules/helpers";
import { useHistory } from "react-router";
import { operationTabList } from "@/modules/user-profile/resource";

const UserNameWrapper = styled.div`
  color: #000;
  font-weight: 600 !important;
`;

function AvatarDropdown(props) {

  const history = useHistory();
  const dispatch = useDispatch();
  const onLogOut = () => dispatch(logOut());
  const [visible, onVisibleChange] = React.useState(false);
  const currentUser = useSelector((state) => getCurrentUser(state).user);
  const isInstalled = (slug) => useSelector((state) => isModulesInstalled(state, slug));

  const onMenuClick = (event) => {
    const { key } = event;
    onVisibleChange(false);
    if (key === "logout") {
      modalConfirmDialog(
        "logout",
        "You can login back anytime.",
        onLogOut,
        null,
        "350px"
      );
    } else {
      history.push(key);
    }
  };

  const tabDataSource = operationTabList(isInstalled);
  const firtTab = tabDataSource?.find((value) => value.slug == true);

  const menuHeaderDropdown = (
    <Menu className="menu" selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key={`/profile/${firtTab?.key}`}>
        <UserOutlined />
          Profile
      </Menu.Item>
      {/* <Menu.Item key="settings">
        <SettingOutlined />
            Settings
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
          Logout
      </Menu.Item>
    </Menu>
  );

  const subStringName = `${currentUser.firstName.substring(0,1)}${currentUser.lastName.substring(0,1)}`;

  return currentUser ? (
    <HeaderDropdown 
      visible={visible} 
      overlay={menuHeaderDropdown} 
      overlayStyle={{zIndex: 0}}
      onVisibleChange={onVisibleChange}
      {...props}
    >
      <span className="action account">
        <Avatar size="small" className="avatar" src={currentUser.profileImageUrl} alt="avatar">
          {!currentUser.profileImageUrl && (
            <UserNameWrapper>
              {subStringName}
            </UserNameWrapper>
          )}
        </Avatar>
        <span className="name anticon">
          {currentUser.firstName} {currentUser.lastName} {visible ? <UpOutlined style={{fontSize: 10}}/> : <DownOutlined style={{fontSize: 10}}/>}
        </span>
      </span>
    </HeaderDropdown>
  ) : (
    <span className="action account">
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );
}

export default AvatarDropdown;