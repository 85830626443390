//action type 
export const ROOM_AUTOCOMPLETE_REQUEST = "ROOM_LIST_REQUEST";
export const ROOM_LIST_REQUEST = "ROOM_LIST_REQUEST";
export const ROOM_LIST_REQUEST_SUCCESS = "ROOM_LIST_REQUEST_SUCCESS";
export const ROOM_CREATE_REQUEST = "ROOM_CREATE_REQUEST";
export const CONSTRACT_DETAIL_REQUEST = "CONSTRACT_DETAIL_REQUEST";
export const ROOM_EDIT_REQUEST = "ROOM_EDIT_REQUEST";

export const ROOM_FORM_NAME ="room";


export const ROOM_TYPE_CUSTOMER = 1;
export const ROOM_TYPE_CUSTOMER_TITLE = "Customer";
export const ROOM_TYPE_EMPLOYEE = 2;
export const ROOM_TYPE_EMPLOYEE_TITLE = "Employee";
export const ROOM_TYPE_SUPPLER = 3;
export const ROOM_TYPE_SUPPLER_TITLE = "Suppler";
//type
export const ROOM_TYPE = [
  {
    id: ROOM_TYPE_CUSTOMER,
    name: ROOM_TYPE_CUSTOMER_TITLE,
  },
  {
    id: ROOM_TYPE_EMPLOYEE,
    name: ROOM_TYPE_EMPLOYEE_TITLE,
  },
  {
    id: ROOM_TYPE_SUPPLER,
    name: ROOM_TYPE_SUPPLER_TITLE,
  }
];


export const ROOM_SUBMIT_RULES = () => { 
  return {
    name: {
      required: true,
    },
    approverId: {
      required: true,
    },
  };
};

// permission code
export const EDIT_ROOM = 700002;
export const READ_ROOM = 700000;
export const CREAT_ROOM = 700001;
export const PRINT_ROOM = 700007;
export const IMPORT_ROOM = 700008;
export const EXPORT_ROOM = 700009;
export const REPORT_ROOM = 700010;
export const DELETE_ROOM = 700003;
export const ARCHIVE_ROOM = 700004;
export const UNARCHIVE_ROOM = 700005;
export const BATCH_DELETE_ROOM = 700006;
