// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";

// Components
import BackgroundImage from "@/images/background/background.svg";

const Wrapper = styled.div`
  height: 100vh;
  background-size: 100%;
  background-position: center;
  background-image: url(${BackgroundImage});
`;

const Image = styled.img`
  width: 150px;
  margin-bottom: 1rem;
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  margin-bottom: 0.5rem;
`;

class AuthenticationLayout extends Component {
  
  render() {
    const { children, displayImage, company } = this.props;
    return (
      <Wrapper>
        <Col
          xl="3"
          lg="4"
          md="6"
          xs="12"
          className="content-center-screen"
        >
          {displayImage && (
            <div className="text-center">
              <Image src={company && company.logoImageUrl} />
              <h4>{company && company.name}</h4>
            </div>
          )}
          { children }
        </Col>
        <Footer>
            Copyright &copy; 2019 Developed by
          <a
            target="_blank"
            rel="noopener noreferrer"
            href=""
            className="pl-1"
          >
            ISERP
          </a>
        </Footer>
      </Wrapper>
    );
  }
}

AuthenticationLayout.propTypes = {
  children: PropTypes.any,
  company: PropTypes.object,
  displayImage: PropTypes.bool,
};

AuthenticationLayout.defaultProps = {
  displayImage: true,
};

const mapStateToProps = (state) => {
  const subDomain = location.host.split(".")[0];
  return {
    company: state.company[subDomain],
    initialValues: {
      subDomain
    }
  };
};

export default connect(mapStateToProps)(AuthenticationLayout);