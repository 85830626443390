import {
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETWithToken,
} from "@/services/base-service";

const API_URL = "/api/filter/v1";

export const getFilter = (entity) => {
  const url = `${API_URL}/list?sortBy=order&order=ASC`;
  return GETWithToken(url, {entity});
};

export function createFilter(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getFilterDetail(id) {
  return GETDetail(API_URL, id);
}

export function editFilter(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function deleteFilter(id) {
  const url = `${API_URL}/${id}/delete`;
  return DELETEWithToken(url);
}

export function reorderFilter(values) {
  const url = `${API_URL}/reorder`;
  const newValue = {
    filters: values
  };
  return PUTWithToken(url, newValue);
}