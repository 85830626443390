export const ATTENDANCE_SUCCESS = "ATTENDANCE_REQUEST_SUCCESS";
export const ATTENDANCE_REQUEST = "ATTENDANCE_REQUEST";

export const ATTENDANCE_INVALID_SUCCESS = "ATTENDANCE_INVALID_REQUEST_SUCCESS";
export const ATTENDANCE_INVALID_REQUEST = "ATTENDANCE_INVALID_REQUEST";

export const ATTENDANCE_CHECK_IN_REQUEST = "ATTENDANCE_CHECK_IN_REQUEST";
export const ATTENDANCE_CHECK_IN_SUCCESS = "ATTENDANCE_CHECK_IN_SUCCESS"; 
export const ATTENDANCE_CHECK_OUT_REQUEST = "ATTENDANCE_CHECK_OUT_REQUEST"; 

export const ATTENDANCE_DELETE_REQUEST = "ATTENDANCE_DELETE_REQUEST"; 
export const ATTENDANCE_DELETE_SUCCESS = "ATTENDANCE_DELETE_SUCCESS"; 
export const ATTENDANCE_EDIT_REQUEST = "ATTENDANCE_EDIT_REQUEST";
export const ATTENDANCE_CREATE_REQUEST = "ATTENDANCE_CREATE_REQUEST";
export const ATTENDANCE_APPROVE_ALL_REQUEST = "ATTENDANCE_APPROVE_ALL_REQUEST";

export const ATTENDANCE_STATUS_VALID = 1;
export const ATTENDANCE_STATUS_INVALID = -1;
export const ATTENDANCE_STATUS_WAITING_APPROVAL = 2;
export const ATTENDANCE_STATUS_APPROVED = 3;
export const ATTENDANCE_STATUS_REJECT = 4;
export const ATTENDANCE_STATUS_PAID = 5;

export const ATTENDANCE_STATUS_VALID_TITLE = "Valid";
export const ATTENDANCE_STATUS_INVALID_TITLE = "Invalid";
export const ATTENDANCE_STATUS_WAITING_APPROVAL_TITLE = "Waiting Approval";
export const ATTENDANCE_STATUS_APPROVED_TITLE = "Approved";
export const ATTENDANCE_STATUS_REJECT_TITLE = "Rejected";
export const ATTENDANCE_STATUS_PAID_TITLE = "Paid";

export const ATTENDANCE_APPROVE_REQUEST = "ATTENDANCE_APPROVE_REQUEST";
export const ATTENDANCE_REJECT_REQUEST = "ATTENDANCE_REJECT_REQUEST";
export const ATTENDANCE_MERGE_REQUEST = "ATTENDANCE_MERGE_REQUEST";
export const ATTENDANCE_MERGE_SUCCESS = "ATTENDANCE_MERGE_SUCCESS";

export const ATTENDANCE_SUMMARY_REQUEST = "ATTENDANCE_SUMMARY_REQUEST";
export const ATTENDANCE_SUMMARY_SUCCESS = "ATTENDANCE_SUMMARY_SUCCESS";

export const ATTENDANCE_STATUS_DATA_SOURCE = [
  {
    id: ATTENDANCE_STATUS_VALID,
    name: ATTENDANCE_STATUS_VALID_TITLE
  },
  {
    id: ATTENDANCE_STATUS_INVALID,
    name: ATTENDANCE_STATUS_INVALID_TITLE
  },
  {
    id: ATTENDANCE_STATUS_WAITING_APPROVAL,
    name: ATTENDANCE_STATUS_WAITING_APPROVAL_TITLE
  },
  {
    id: ATTENDANCE_STATUS_APPROVED,
    name: ATTENDANCE_STATUS_APPROVED_TITLE
  },
  {
    id: ATTENDANCE_STATUS_REJECT,
    name: ATTENDANCE_STATUS_REJECT_TITLE
  },
  {
    id: ATTENDANCE_STATUS_PAID,
    name: ATTENDANCE_STATUS_PAID_TITLE
  },
];

export const ATTENDANCE_CHECK_IN_TYPE = 1;
export const ATTENDANCE_CHECK_OUT_TYPE = 2;

export const ATTENDANCE_CHECK_IN_TITLE ="Check In";
export const ATTENDANCE_CHECK_OUT_TITLE = "Check Out";

export const ATTENDANCE_MODAL_EDIT_TYPE = 1;
export const ATTENDANCE_MODAL_MERGE_TYPE = 2;
export const ATTENDANCE_MODAL_CREATE_TYPE = 3;
export const ATTENDANCE_MODAL_HISTORY_TYPE = 4;

export const ATTENDANCE_MODAL_EDIT_TITLE = "Edit Attendance Time";
export const ATTENDANCE_MODAL_MERGE_TITLE = "Merge Attendance Time";
export const ATTENDANCE_MODAL_CREATE_TITLE = "Create Attendance Time";
export const ATTENDANCE_MODAL_HISTORY_TITLE = "History";

export const ATTENDANCE_CHECK_IN_FORM = "checkInForm";
export const ATTENDANCE_CHECK_OUT_FORM = "checkOutForm";

export const EDIT_ATTENDANCE_FORM_NAME = "editAttendance";
export const MERGE_ATTENDANCE_FORM_NAME = "mergeAttendance";
export const CREATE_ATTENDANCE_FORM_NAME = "createAttendance";
export const APPROVE_ATTENDANCE_FORM_NAME = "approveAttendance";

export const ATTENDANCE_TYPE_DATA_SOURCE = [
  {
    id: ATTENDANCE_CHECK_IN_TYPE,
    name: ATTENDANCE_CHECK_IN_TITLE,
  },
  {
    id: ATTENDANCE_CHECK_OUT_TYPE,
    name: ATTENDANCE_CHECK_OUT_TITLE,
  },
];

export const VIEW_ATTENDANCE_SUMMARY = 135011;
export const UPDATE_EMPLOYEE_ATTENDANCE = 135002;
export const DELETE_EMPLOYEE_ATTENDANCE  = 135003;
export const APPROVE_EMPLOYEE_ATTENDANCE = 135014;
export const REJECT_EMPLOYEE_ATTENDANCE = 135015;
export const JOIN_EMPLOYEE_ATTENDANCE = 135016;
export const VIEW_ATTENDANCE_SUMMARIZE_OTHER_USER = 135012;
export const CREATE_EMPLOYEE_ATTENDANCE_FOR_OTHER_USER = 135013;