import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/department/v1";

export const getDepartment = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createDepartment(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getDepartmentAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getDepartmentDetail(id) {
  return GETDetail(API_URL, id);
}

export function editDepartment(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveDepartment(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveDepartment(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteDepartment(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}