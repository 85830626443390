import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/payslip/v1";

export const getPayslip = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function getPayslipAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getPayslipDetail(id) {
  return GETDetail(API_URL, id);
}

export function computePayslip(payslipId) {
  const url = "/api/payslip/computation/v1/create";
  const id = { payslipId };
  return POSTWithToken(url, id);
}

export function confirmPayslip(id) {
  const url = `${API_URL}/${id}/confirm`;
  return PUTWithToken(url);
}

export function cancelPayslip(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function draftPayslip(id) {
  const url = `${API_URL}/${id}/draft`;
  return PUTWithToken(url);
}

export function editPayslipInput(values) {
  const { id, ...rest } = values;
  const url = `${API_URL}/${id}/input`;
  return PUTWithToken(url, rest);
}