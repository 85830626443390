import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Button, message } from "antd";
import { withRouter, Link } from "react-router-dom";
import { PrinterOutlined, FilePdfOutlined } from "@ant-design/icons";

// Components
import SummaryBox from "@cores/components/summary-box";
import ListLayout from "@/components/layout/list-layout";
import CurrencyBox from "@cores/components/currency-box";
import FilterComponent from "@modules/hr/employee-directory/employee/components/filter-box/employee-sale-commission";

// Schemas
import { EmployeeSaleCommissionArraySchema } from "@modules/hr/employee-directory/employee//schema/employee-sale-commission";

// Utils
import { 
  toShortDate, 
  getQueryParamUrl,
  getShortNameWithTooltip, 
} from "@modules/helpers";
import { useApi } from "@modules/hooks";
import * as EmployeeConstants from "@modules/hr/employee-directory/employee/constants";
import { getEmployeeSaleCommission } from "@modules/hr/employee-directory/employee/actions";
import { getTotalSaleHistory } from "@modules/hr/employee-directory/employee/services";
import { DEFAULT_ASYNC_DATE_FORMAT } from "@/constants/default-constants"; 
import * as Constants from "@cores/components/auto-complete/date-range-option/constants";
import { 
  SALE_COMMISSION_TYPE_SALE
} from "@modules/reports/sale/sale-commission/constants";
import { REPORT_TYPE_SALE_COMMISSION } from "@/constants/report-template";

// Utils
import * as Service from "@/services/report-template";

const ButtonGroup = Button.Group;

function SaleHistory(props) {
  let query = getQueryParamUrl();
  const { currentUser } = props;
  const employeeId = currentUser?.employee?.id;
  const dateType = query.dateType;
  let values = {
    employee: [employeeId],
    from: query.from || moment().startOf("month").format(DEFAULT_ASYNC_DATE_FORMAT),
    to: query.to || moment().endOf("month").format(DEFAULT_ASYNC_DATE_FORMAT),
    dateType: dateType,
    type: query.type ? [query.type] : [SALE_COMMISSION_TYPE_SALE],
    query: query.query

  };
  if (dateType == Constants.OPTION_TYPE_ALL) {
    values = {
      query: query.query,
      employee: [employeeId],
      dateType: Constants.OPTION_TYPE_ALL,
      type: query.type ? [query.type] : [SALE_COMMISSION_TYPE_SALE],
    };
    
  }

  const { response } = useApi(getTotalSaleHistory, values, [props.location]);

  const columns = () => {
    let columns = [
      {
        title: "Sold Date",
        dataIndex: "invoiceDate",
        width: "10%",
        render: (_, row) => toShortDate(row.invoice && row.invoice.invoiceDate)
      },
      {
        title: "Paid Date",
        dataIndex: "paidDate",
        width: "10%",
        render: (_, row) => {
          if (row.invoice && row.invoice.paidDate) {
            return toShortDate(row.invoice.paidDate);
          }
          return "N/A";
        }
      },
      {
        title: "Item",
        dataIndex: "item",
        width: "20%",
        render: (item) => {
          if (item) {
            return (
              <Link to={`/inventory/items/item/${item.id}`}>
                {getShortNameWithTooltip(item.name, 30)}
              </Link>
            );
          }
        }
      },
      {
        title: "Invoice No",
        width: "10%",
        dataIndex: "invoiceNo",
        render: (_, row) => {
          if (row.invoice) {
            return (
              <Link to={`/sale/sale-center/sale-invoice/${row.invoice.invoiceNo}`}>
                {row.invoice.invoiceNo}
              </Link>
            );
          }
        }
      },
      {
        title: "Item Value",
        width: "10%",
        dataIndex: "saleCommissionValue",
        render: (saleCommissionValue) => `${saleCommissionValue} %`
      },
      {
        title: "Employee Value",
        width: "10%",
        dataIndex: "employeeCommissionValue",
      },
      {
        title: "Qty Sold",
        width: "10%",
        dataIndex: "quantity",
      },
      {
        title: "Price Sold",
        dataIndex: "price",
        width: "10%",
        render: (price, row) => <CurrencyBox value={price * row.quantity} />
      },
      {
        title: "Commission",
        dataIndex: "commissionValue",
        width: "10%",
        render: (commissionValue, row) => <CurrencyBox value={commissionValue * row.quantity} />
      },
    ];
    return columns;
  };

  const newDataSource = (dataSource) => {
    return dataSource && dataSource.map((value) => {
      const newValue = {...value};
      delete newValue.children;
      return newValue;
    });
  };


  const onPDFClick = () => {
    if (employeeId) {
      Service.getReportTemplate(REPORT_TYPE_SALE_COMMISSION, values).then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType == "application/pdf") {
          var file = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(file);
          window.open(url, name);
        }
      }).catch((error) => {
        message.error(error.message);
      });
    } else {
      message.error("Current user not link with employee");
    }
    
  };

  const onPrintClick = () => {
    if (employeeId) {
      Service.getReportTemplate(REPORT_TYPE_SALE_COMMISSION, values).then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType == "application/pdf") {
          var file = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(file);
          const iframe = document.createElement("iframe");
          iframe.id = "iframe-print";
          iframe.src = url;
          iframe.style = "display: none";
          document.body.append(iframe);
          iframe.focus();
          iframe.contentWindow.print();
        }
      }).catch((error) => {
        message.error(error.message);
      });
    } else {
      message.error("Current user not link with employee");
    }
  };

  const { id, permission } = props;
  const filterKeys = [
    "type",
    "from",
    "to",
    "dateType",
  ];
  const extraButtons = [];
  extraButtons.push(
    <ButtonGroup>
      <Button 
        ghost
        type="primary"
        icon={<PrinterOutlined />}
        onClick={onPrintClick}
      >
        Print
      </Button>
      <Button
        ghost
        icon={<FilePdfOutlined />}
        type="primary"
        onClick={onPDFClick}
      >
        PDF
      </Button>
    </ButtonGroup>
  );

  return (
    <ListLayout 
      className="m-0"
      extraValues={{id}}
      creatable={false}
      tableSize="small"
      columns={columns()}
      permission={permission}
      filterKeys={filterKeys}
      extraButtons={extraButtons}
      isPermissionAllowed={true}
      newDataSource={newDataSource}
      filterComponent={FilterComponent}
      title="Sale History"
      reducerName="saleCommissionHistory"
      action={getEmployeeSaleCommission}
      schema={EmployeeSaleCommissionArraySchema}
      extraFilter={(filter) => {
        filter.employee = [employeeId];
        return filter;
      }}
      tableChildrenComponent={() => {
        return (
          <Fragment>
            <hr />
            <Row>
              <Col xs="12" md={{size: 6, offset: 6}} className="text-right font-weight-bold mt-1">
                <SummaryBox 
                  label="Total Commission"
                  value={response && response.totalCommission}
                />
              </Col>
              <Col xs="12" md={{size: 6, offset: 6}} className="text-right font-weight-bold mt-1">
                <SummaryBox 
                  label="Total Sale"
                  value={response && response.totalSale}
                />
              </Col>
            </Row>
          </Fragment>
        );
      }}
      requestName={EmployeeConstants.EMPLOYEE_SALE_COMMISSION_REQUEST}
    />
  );
}

SaleHistory.propTypes = {
  id: PropTypes.number,
  location: PropTypes.object,
  permission: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withRouter(SaleHistory);