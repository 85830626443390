export const STOCK_OUT_LIST_REQUEST = "STOCK_OUT_LIST_REQUEST";
export const STOCK_OUT_LIST_SUCCESS = "STOCK_OUT_LIST_REQUEST_SUCCESS";
export const STOCK_OUT_DETAIL_REQUEST = "STOCK_OUT_DETAIL_REQUEST";
export const STOCK_OUT_AUTOCOMPLETE_REQUEST = "STOCK_OUT_AUTOCOMPLETE_REQUEST";
export const STOCK_OUT_ARCHIVE_REQUEST = "STOCK_OUT_ARCHIVE_REQUEST";
export const STOCK_OUT_UNARCHIVE_REQUEST = "STOCK_OUT_UNARCHIVE_REQUEST";
export const STOCK_OUT_DELETE_REQUEST = "STOCK_OUT_DELETE_REQUEST";
export const STOCK_OUT_CREATE_REQUEST = "STOCK_OUT_CREATE_REQUEST";

export const STOCK_OUT_SUBMIT_REQUEST = "STOCK_OUT_SUBMIT_REQUEST";
export const STOCK_OUT_APPROVE_REQUEST = "STOCK_OUT_APPROVE_REQUEST";
export const STOCK_OUT_REJECT_REQUEST = "STOCK_OUT_REJECT_REQUEST";
export const STOCK_OUT_CANCEL_REQUEST = "STOCK_OUT_CANCEL_REQUEST";
export const STOCK_OUT_POST_REQUEST = "STOCK_OUT_POST_REQUEST";
export const STOCK_OUT_PUSH_BACK_REQUEST = "STOCK_OUT_PUSH_BACK_REQUEST";

export const STOCK_OUT_PROCESS_FORM_NAME = "stockOutProcess";
export const STOCK_OUT_PROCESS_ITEMS_FIELD_NAME = "stockOutItems";
export const STOCK_OUT_PROCESS_SCAN_SERIAL_FORM_NAME = "scanSerial";

export const STOCK_OUT_PROCESS_SCAN_SERIAL_SUBMIT_RULES = {
  serialNumber: {
    required: true,
  },
};

export const STOCK_OUT_PROCESS_REQUEST = "STOCK_OUT_PROCESS_REQUEST";

export const STOCK_OUT_TYPE_SALE_ORDER = 3;
export const ITEM_INSTALLATION_STATUS_COMPLETED = 4;

export const READ_STOCK_OUT = 219000;
export const CREATE_STOCK_OUT = 219001;
export const UPDATE_STOCK_OUT = 219002;
export const DELETE_STOCK_OUT = 219003;
export const ARCHIVE_STOCK_OUT = 219004;
export const UNARCHIVE_STOCK_OUT = 219005;
export const PRINT_STOCK_OUT = 219007;
export const SUBMIT_STOCK_OUT = 219011;
export const APPROVE_STOCK_OUT = 219012;
export const REJECT_STOCK_OUT = 219013;
export const PROCESS_STOCK_OUT = 219014;
export const CANCEL_STOCK_OUT = 219015;
export const STOCK_OUT_PUSH_BACK_TO_STOCK = 219017;

export const STOCK_OUT_STATUS_DRAFT = 1;
export const STOCK_OUT_STATUS_DRAFT_TITLE = "Draft";
export const STOCK_OUT_STATUS_SUBMITTED = 2;
export const STOCK_OUT_STATUS_SUBMITTED_TITLE = "Submitted";
export const STOCK_OUT_STATUS_APPROVED = 3;
export const STOCK_OUT_STATUS_APPROVED_TITLE = "Approved";
export const STOCK_OUT_STATUS_REJECTED = 4;
export const STOCK_OUT_STATUS_REJECTED_TITLE = "Rejected";
export const STOCK_OUT_STATUS_PROCESSING = 5;
export const STOCK_OUT_STATUS_PROCESSING_TITLE = "Processing";
export const STOCK_OUT_STATUS_COMPLETED = 6;
export const STOCK_OUT_STATUS_COMPLETED_TITLE = "Completed";
export const STOCK_OUT_STATUS_CANCELLED = 7;
export const STOCK_OUT_STATUS_CANCELLED_TITLE = "Cancelled";
export const STOCK_OUT_STATUS_DELIVERY_ORDER = 8;
export const STOCK_OUT_STATUS_DELIVERY_ORDER_TITLE = "Delivered";

export const STOCK_OUT_STATUS = [
  {
    id: STOCK_OUT_STATUS_DRAFT,
    name: STOCK_OUT_STATUS_DRAFT_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_SUBMITTED,
    name: STOCK_OUT_STATUS_SUBMITTED_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_APPROVED,
    name: STOCK_OUT_STATUS_APPROVED_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_REJECTED,
    name: STOCK_OUT_STATUS_REJECTED_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_PROCESSING,
    name: STOCK_OUT_STATUS_PROCESSING_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_COMPLETED,
    name: STOCK_OUT_STATUS_COMPLETED_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_CANCELLED,
    name: STOCK_OUT_STATUS_CANCELLED_TITLE,
  },
  {
    id: STOCK_OUT_STATUS_DELIVERY_ORDER,
    name: STOCK_OUT_STATUS_DELIVERY_ORDER_TITLE,
  },
];