export const TAX_LIST_REQUEST = "TAX_LIST_REQUEST";
export const TAX_LIST_SUCCESS = "TAX_LIST_REQUEST_SUCCESS";

export const TAX_CREATE_REQUEST = "TAX_CREATE_REQUEST";
export const TAX_CREATE_SUCCESS = "TAX_CREATE_SUCCESS";

export const TAX_DETAIL_REQUEST = "TAX_DETAIL_REQUEST";

export const TAX_DELETE_REQUEST = "TAX_DELETE_REQUEST";

export const TAX_ARCHIVE_REQUEST = "TAX_ARCHIVE_REQUEST";

export const TAX_UNARCHIVE_REQUEST = "TAX_UNARCHIVE_REQUEST";

export const TAX_EDIT_REQUEST = "TAX_EDIT_REQUEST";

export const TAX_AUTOCOMPLETE_REQUEST = "TAX_AUTOCOMPLETE_REQUEST";
export const TAX_AUTOCOMPLETE_SUCCESS = "TAX_AUTOCOMPLETE_SUCCESS";

export const TAX_CALCULATION_TYPE_FIX = 1;
export const TAX_CALCULATION_TYPE_RANGE = 2;
export const TAX_CALCULATION_TYPE_PERCENTAGE = 3;
export const TAX_TYPE_PAYROLL = 1;
export const TAX_TYPE_SALE = 2;
export const TAX_TYPE_PURCHASE = 3;
export const TaxTypes = [
  {
    name: "Payroll",
    id: TAX_TYPE_PAYROLL,
  },
  {
    name: "Sale",
    id: TAX_TYPE_SALE,
  },
  {
    name: "Purchase",
    id: TAX_TYPE_PURCHASE,
  },
];

export const TaxPayrollCalculationType = [
  {
    name: "Fix",
    id: TAX_CALCULATION_TYPE_FIX,
  },
  {
    name: "Range",
    id: TAX_CALCULATION_TYPE_RANGE,
  },
];

export const TaxSalePurchaseCalculationType = [
  {
    name: "Fix",
    id: TAX_CALCULATION_TYPE_FIX,
  },
  {
    name: "Percentage",
    id: TAX_CALCULATION_TYPE_PERCENTAGE,
  },
];

export const TAX_RANGE_TYPE_FIX = 1;
export const TAX_RANGE_TYPE_PERCENTAGE = 2;
export const TaxRangeTypes = [
  {
    name: "Fix",
    id: TAX_RANGE_TYPE_FIX,
  },
  {
    name: "Percentage (%)",
    id: TAX_RANGE_TYPE_PERCENTAGE,
  },
];

export const TAX_CREATE_FORM_NAME = "createTax";
export const TAX_EDIT_FORM_NAME = "editTax";
export const TAX_RANGE_FIELD_NAME = "ranges";
export const READ_TAX = 123000;
export const CREATE_TAX = 123001;
export const UPDATE_TAX = 123002;
export const DELETE_TAX = 123003;
export const ARCHIVE_TAX = 123004;
export const UNARCHIVE_TAX = 123005;