import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
  GETWithToken,
} from "@/services/base-service";

const API_URL = "/api/customer-company/v1";
const SALE_TEAM_API_URL = "/api/sale-team/v1";

export const getCustomerCompany = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createCustomerCompany(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getCustomerCompanyAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getCustomerCompanyDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editCustomerCompany(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function editRenew(values) {
  const { id } = values;
  const url = `/api/customer-company/v1/${id}/renew`;
  return PUTWithToken(url, values);
}

export function archiveCustomerCompany(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveCustomerCompany(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteCustomerCompany(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function suspendCustomerCompany(id) {
  const url = `${API_URL}/${id}/suspend`;
  return PUTWithToken(url);
}

export function activeCustomerCompany(id) {
  const url = `${API_URL}/${id}/activate`;
  return PUTWithToken(url);
}

export function approveCustomerCompany(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function getSaleTeamResponse(id) {
  const url = `${SALE_TEAM_API_URL}/customer-company/${id}`;
  return GETWithToken(url);
}