import React from "react";
import PropTypes from "prop-types";
import { useApi } from "@modules/hooks";
import { getCashAdvancedDetail } from "@modules/hr/cash-advanced/cash-advanced/services";
import DetailComponent from "@modules/hr/cash-advanced/cash-advanced/components/cash-advanced-detail";

function CashAdvanceDetail(props) {

  const { response } = useApi(getCashAdvancedDetail, props.id, [props.id]);

  if (response == null) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <DetailComponent 
        entity={response}
        profilePage={true}
      />
    </div>
  );
}

CashAdvanceDetail.propTypes = {
  id: PropTypes.number,
};

export default CashAdvanceDetail;