export const CUSTOMER_COMPANY_LIST_REQUEST = "CUSTOMER_COMPANY_LIST_REQUEST"; 
export const CUSTOMER_COMPANY_LIST_SUCCESS = "CUSTOMER_COMPANY_LIST_REQUEST_SUCCESS";
export const CUSTOMER_COMPANY_CREATE_REQUEST = "CUSTOMER_COMPANY_CREATE_REQUEST"; 
export const CUSTOMER_COMPANY_DETAIL_REQUEST = "CUSTOMER_COMPANY_DETAIL_REQUEST"; 
export const CUSTOMER_COMPANY_ARCHIVE_REQUEST = "CUSTOMER_COMPANY_ARCHIVE_REQUEST"; 
export const CUSTOMER_COMPANY_DELETE_REQUEST = "CUSTOMER_COMPANY_DELETE_REQUEST"; 
export const CUSTOMER_COMPANY_UNARCHIVE_REQUEST = "CUSTOMER_COMPANY_UNARCHIVE_REQUEST"; 
export const CUSTOMER_COMPANY_EDIT_REQUEST = "CUSTOMER_COMPANY_EDIT_REQUEST"; 
export const SUSPEND_CUSTOMER_COMPANY_REQUEST = "SUSPEND_CUSTOMER_COMPANY_REQUEST";
export const ACTIVE_CUSTOMER_COMPANY_REQUEST = "ACTIVE_CUSTOMER_COMPANY_REQUEST";
export const APPROVE_CUSTOMER_COMPANY_REQUEST = "APPROVE_CUSTOMER_COMPANY_REQUEST";
export const RENEW_REQUEST = "RENEW_REQUEST";

export const CUSTOMER_COMPANY_CREATE_FORM_NAME = "createCustomerCompany";
export const CUSTOMER_COMPANY_EDIT_FORM_NAME = "editCustomerCompany"; 

export const CUSTOMER_COMPANY_AUTOCOMPLETE_REQUEST = "CUSTOMER_COMPANY_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_COMPANY_AUTOCOMPLETE_SUCCESS = "CUSTOMER_COMPANY_AUTOCOMPLETE_SUCCESS";

export const COMPANY_RESPONSE_FIELD_ARRAY_NAME = "companyResponses";
export const CUSTOMER_COMPANY_SALE_TEAM_ARRAY_NAME = "customerCompanySaleTeams"; 

export const COMPANY_RESPONSE_TYPE_BY_EMPLOYEE = 1;
export const COMPANY_RESPONSE_TYPE_BY_EMPLOYEE_NAME = "By Employee";
export const COMPANY_RESPONSE_TYPE_BY_SALE_TEAM = 2;
export const COMPANY_RESPONSE_TYPE_BY_SALE_TEAM_NAME = "By Sale Team";

export const COMPANY_RESPONSE_TYPE_DATA_SOURCE = [
  {
    id: COMPANY_RESPONSE_TYPE_BY_EMPLOYEE,
    name: COMPANY_RESPONSE_TYPE_BY_EMPLOYEE_NAME
  },
  {
    id: COMPANY_RESPONSE_TYPE_BY_SALE_TEAM,
    name: COMPANY_RESPONSE_TYPE_BY_SALE_TEAM_NAME
  },
];

export const READ_CUSTOMER_COMPANY = 191000;
export const CREATE_CUSTOMER_COMPANY = 191001;
export const UPDATE_CUSTOMER_COMPANY = 191002;
export const DELETE_CUSTOMER_COMPANY = 191003;
export const ARCHIVE_CUSTOMER_COMPANY = 191004;
export const UNARCHIVE_CUSTOMER_COMPANY = 191005;
export const SUSPEND_CUSTOMER_COMPANY = 191012;
export const ACTIVE_CUSTOMER_COMPANY = 191013;
export const APPROVE_CUSTOMER_REGISTER_FROM_WEBSITE  = 191014;

export const CUSTOMER_COMPANY_STATUS_ACTIVE = 1;
export const CUSTOMER_COMPANY_STATUS_ACTIVE_TITLE = "Active";
export const CUSTOMER_COMPANY_STATUS_INACTIVE = 2;
export const CUSTOMER_COMPANY_STATUS_INACTIVE_TITLE = "Inactive";
export const CUSTOMER_COMPANY_STATUS_SUSPEND = 3;
export const CUSTOMER_COMPANY_STATUS_SUSPEND_TITLE = "Suspend";

export const CUSTOMER_COMPANY_STATUS_DATA_SOURCE = [
  {
    id: CUSTOMER_COMPANY_STATUS_ACTIVE,
    name: CUSTOMER_COMPANY_STATUS_ACTIVE_TITLE
  },
  {
    id: CUSTOMER_COMPANY_STATUS_INACTIVE,
    name: CUSTOMER_COMPANY_STATUS_INACTIVE_TITLE
  },
  {
    id: CUSTOMER_COMPANY_STATUS_SUSPEND,
    name: CUSTOMER_COMPANY_STATUS_SUSPEND_TITLE
  }
];