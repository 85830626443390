import React from "react";
import { Badge } from "antd";
import * as moment from "moment";
import * as Constants from "../constants";
import { DEFAULT_DISPLAY_DATE_FORMAT } from "@/constants/default-constants";

export const leaveRequestStatus = (id) => {
  let status = null;  
  switch(id) {
  case Constants.LEAVE_REQUEST_STATUS_DRAFT:
    status = <Badge status="default" text={Constants.LEAVE_REQUEST_STATUS_DRAFT_TITLE} />;
    break;
  case Constants.LEAVE_REQUEST_STATUS_SUBMITTED:
    status = <Badge status="processing" text={Constants.LEAVE_REQUEST_STATUS_SUBMITTED_TITLE} />;
    break;
  case Constants.LEAVE_REQUEST_STATUS_CANCELLED:
    status = <Badge status="warning" text={Constants.LEAVE_REQUEST_STATUS_CANCELLED_TITLE} />;
    break;
  case Constants.LEAVE_REQUEST_STATUS_APPROVED:
    status = <Badge status="success" text={Constants.LEAVE_REQUEST_STATUS_APPROVED_TITLE} />; 
    break;
  case Constants.LEAVE_REQUEST_STATUS_REJECT:
    status = <Badge status="error" text={Constants.LEAVE_REQUEST_STATUS_REJECT_TITLE} />;
    break;
  }
  return status;
};

export const leaveRequestStatusTitle = (id) => {
  let status = null;  
  switch(id) {
  case Constants.LEAVE_REQUEST_STATUS_DRAFT:
    status = Constants.LEAVE_REQUEST_STATUS_DRAFT_TITLE;
    break;
  case Constants.LEAVE_REQUEST_STATUS_SUBMITTED:
    status = Constants.LEAVE_REQUEST_STATUS_SUBMITTED_TITLE;
    break;
  case Constants.LEAVE_REQUEST_STATUS_CANCELLED:
    status = Constants.LEAVE_REQUEST_STATUS_CANCELLED_TITLE;
    break;
  case Constants.LEAVE_REQUEST_STATUS_APPROVED:
    status = Constants.LEAVE_REQUEST_STATUS_APPROVED_TITLE; 
    break;
  case Constants.LEAVE_REQUEST_STATUS_REJECT:
    status = Constants.LEAVE_REQUEST_STATUS_REJECT_TITLE;
    break;
  }
  return status;
};

export const leaveRequestSubmitRules = (values) => {
  const { startDate, endDate } = values;
  let rules = {
    employeeId: {
      required: true,
    },
    leaveTypeId: {
      required: true,
    },
    reason: {
      required: true,
    },
    dateRange: {
      required: true,
    },
    startDate: {
      required: true,
    },
    endDate: {
      required: true,
    },
    fromDurationType: {
      required: values.isPartialLeave,
    },
    toDurationType: {
      required: values.isPartialLeave,
    },
    documentUrl: {
      required: values.isDocumentRequired,
    },
    duration: {
      required: true,
    }
  };

  rules.dateRange["compare"] = {
    condition: !moment(startDate, DEFAULT_DISPLAY_DATE_FORMAT).isSame(moment(endDate, DEFAULT_DISPLAY_DATE_FORMAT), "month"),
    message: "Leave Request should not be able to request cross-month",
  };
  return rules;
};

export const leaveRequestDurationType = (value) => {
  let type = null;
  switch(value) {
  case Constants.LEAVE_REQUEST_DURATION_FULL_DAY:
    type = Constants.LEAVE_REQUEST_DURATION_FULL_DAY_TITLE;
    break;
  case Constants.LEAVE_REQUEST_DURATION_FIRST_HALF:
    type = Constants.LEAVE_REQUEST_DURATION_FIRST_HALF_TITLE;
    break;
  case Constants.LEAVE_REQUEST_DURATION_SECOND_HALF:
    type = Constants.LEAVE_REQUEST_DURATION_SECOND_HALF_TITLE;
    break;
  }
  return type;
};

export const calculateLeaveRequestDuration = (oldProps, newProps) => {
  const {
    updateDuration,
    updateToDurationType,
    isPartialLeave: oldIsPartialLeave,
    startDate: oldStartDate,
    endDate: oldEndDate,
    fromDurationType: oldFromDurationType,
    toDurationType: oldToDurationType
  } = oldProps;
  let {
    startDate,
    endDate,
    fromDurationType,
    toDurationType,
    isPartialLeave
  } = newProps;
  if (isPartialLeave != oldIsPartialLeave ||
    startDate != oldStartDate ||
    endDate != oldEndDate ||
    fromDurationType != oldFromDurationType ||
    toDurationType != oldToDurationType
  ) {
    startDate = moment(startDate, DEFAULT_DISPLAY_DATE_FORMAT);
    endDate = moment(endDate, DEFAULT_DISPLAY_DATE_FORMAT);
    if (startDate.isValid() && endDate.isValid()) {
      let value = endDate.diff(startDate, "days", true) + 1;
      // If partial leave is checked
      if (isPartialLeave) {
        // existing value `from date` and `to date` is the same day
        if (value == Constants.LEAVE_REQUEST_FULL_DAY_VALUE) {
          // If value `from duration type` and `to duration type` is equal `First Half` or
          // to duration type is equal `Second Half`, it means we need to minus duration a half day
          if ((fromDurationType == Constants.LEAVE_REQUEST_DURATION_FIRST_HALF && 
            toDurationType == Constants.LEAVE_REQUEST_DURATION_FIRST_HALF) ||
            fromDurationType == Constants.LEAVE_REQUEST_DURATION_SECOND_HALF
          ) {
            value = value - Constants.LEAVE_REQUEST_HALF_DAY_VALUE;
          }
          updateDuration(value);
          // If from duration type equal `Full Half` or `Second Half`, 
          // it's mean `to duration type` will be disable
          if (fromDurationType == Constants.LEAVE_REQUEST_DURATION_FULL_DAY ||
            fromDurationType == Constants.LEAVE_REQUEST_DURATION_SECOND_HALF
          ) {
            updateToDurationType(fromDurationType);
            return {
              disabled: true,
            };
          }
          // If `from duration type` is equal `First Half`, it will be remove disable
          // on `to duration type` and otherwise it will disable `Full Day` in select option
          else {
            // If `Full Day` in select option is disabled, it's mean `to duration type`
            // will update to `First Day`
            if (toDurationType == Constants.LEAVE_REQUEST_DURATION_FULL_DAY) {
              updateToDurationType(Constants.LEAVE_REQUEST_DURATION_FIRST_HALF);
            }
            return {
              disabled: false,
              toDurationTypeId: Constants.LEAVE_REQUEST_DURATION_FULL_DAY
            };
          }
        }
        // If partial leave and value `from date` and `to date` are not the same day
        else {
          // If If `from duration type` is equal `Full Day` or `First Half`, it's mean `Full Day`
          // If `from duration type` is not equal `Full Day` or `First Half`
          // the duration will be minus a half day
          if (fromDurationType != Constants.LEAVE_REQUEST_DURATION_FULL_DAY &&
            fromDurationType != Constants.LEAVE_REQUEST_DURATION_FIRST_HALF
          ) {
            value = value - Constants.LEAVE_REQUEST_HALF_DAY_VALUE;
          }
          // If If `to duration type` is equal `Full Day` or `Second Half`, it's mean `Full Day`
          // If `to duration type` is not equal `Full Day` or `Second Half`
          // the duration will be minus a half day
          if (toDurationType != Constants.LEAVE_REQUEST_DURATION_FULL_DAY && 
            toDurationType != Constants.LEAVE_REQUEST_DURATION_SECOND_HALF
          ) {
            value = value - Constants.LEAVE_REQUEST_HALF_DAY_VALUE;
          }
          updateDuration(value);
          return {
            disabled: false,
            toDurationTypeId: null
          };
        }
      }
      updateDuration(value);
    }
  }
};