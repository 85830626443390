import React from "react";
import { Badge, Button } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import { denormalize } from "normalizr";
import { useHistory } from "react-router";
import { BellOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// Components
import NoticeList from "./notice-list";
import HeaderDropdown from "../header-dropdown";

// Actions
import { NotificationArraySchema } from "@/schemas/notification";
import { NOTIFICATION_LIST_REQUEST } from "@/constants/notification";
import { getNotification, notificationRead, markAllRead } from "@/actions/notification";

// Style
import "./index.less";

const NoticeIcon = (props) => {

  const [visible, setVisible] = React.useState(false);

  const { className, count } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const onGetNotification = (page) => dispatch(getNotification(page));
  const onNotificationRead = (notificationIds) => dispatch(notificationRead(notificationIds));
  const onMarkAllRead = () => dispatch(markAllRead());
  const dispatchAction = (action) => dispatch(action);
  const entities = useSelector((state) => state.entities);
  const notifications = useSelector((state) => state.notification.list);
  const loading =  useSelector((state) => state.request[NOTIFICATION_LIST_REQUEST]);
  const dataSource = denormalize(notifications, NotificationArraySchema, entities);

  const onSeeAll = () => {
    history.push("/notification");
  };

  const onMarkAllReadClick = () => {
    onMarkAllRead();
  };

  const buildNotificationActions = (actions, notification, shouldRenderActionButton) => {
    if (shouldRenderActionButton) {
      return actions.map((item, index) => {
        let { handler, type, title } = item;
        const onClick = () => {
          dispatchAction(handler(notification));
        };
        type = type != "primary" ? "danger" : type;
        return (
          <Button
            ghost
            onClick={onClick}
            key={index}
            type={type}
            size="small"
            className={index == 0 && "mr-1"}
          >
            {title}
          </Button>
        );
      });
    }
  };

  React.useEffect(() => {
    if (visible) {
      onGetNotification();
    }
  }, [visible]);

  const notificationReadCheck = (notificationIds, isSeen) => {
    if (!isSeen) {
      onNotificationRead(notificationIds);
    }
  };

  const isAlreadySeen = (recipients) => {
    return recipients.map((value) => value.isSeen == 1);
  };

  const onNotificationClick = (notification, url) => {
    if (notification) {
      const { notificationRecipients } = notification;
      const isSeen = isAlreadySeen(notificationRecipients);
      notificationReadCheck([notification.id], isSeen[0]);
    }
    setVisible(false);
    history.push(url);
  };

  const getNotificationBox = () => {
    return (
      <NoticeList
        count={count}
        loading={loading}
        onSeeAll={onSeeAll}
        dataSource={dataSource}
        onItemClick={onNotificationClick}
        onMarkAllRead={onMarkAllReadClick}
        buildNotificationActions={buildNotificationActions}
      />
    );
  };

  const noticeButtonClass = classNames(className, "noticeButton");
  const notificationBox = getNotificationBox();
  const trigger = (
    <span className={classNames(noticeButtonClass, { opened: visible })}>
      <Badge count={count > 0 ? count : 0} style={{ boxShadow: "none" }} className="badge">
        <BellOutlined className="icon" />
      </Badge>
    </span>
  );
  if (!notificationBox) {
    return trigger;
  }

  return (
    <HeaderDropdown
      visible={visible}
      trigger={["click"]}
      placement="bottomRight"
      overlay={notificationBox}
      overlayStyle={{zIndex: 3}}
      onVisibleChange={setVisible}
    >
      {trigger}
    </HeaderDropdown>
  );
};

NoticeIcon.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
};

export default NoticeIcon;
