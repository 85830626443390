import * as Constants from "@/constants/attachment";

export default function (state = {}, action) {
  switch (action.type) {
  case Constants.ATTACHMENT_SUCCESS:
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...action.data
        ]
      }
    };
  case Constants.ATTACHMENT_SUCCESS_NEXT_PAGE: {
    const oldAttachment = state[action.entity][action.entityId] || [];
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...oldAttachment, 
          ...action.data
        ]
      }
    };
  }
  case Constants.ATTACHMENT_CREATE_SUCCESS: {
    const oldAttachment = [
      ...state[action.entity][action.entityId]
    ];
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          action.id,
          ...oldAttachment,
        ]
      }
    };
  }
  case Constants.ATTACHMENT_DELETE_SUCCESS: {
    const data = [
      ...state[action.entity][action.entityId]
    ];
    const deleteIndex = data.indexOf(action.id);
    data.splice(deleteIndex, 1);
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...data,
        ]
      }
    };
  }
  default:
    return state;
  }
}