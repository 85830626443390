// Libs
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Action 
import { getAll } from "@sale/customer-center/customer/actions";

// Schema
import { CustomerArraySchema } from "@sale/customer-center/customer/schema";

// Constant
import { CUSTOMER_LIST_REQUEST, CUSTOMER_TYPE_COMPANY_ID } from "@sale/customer-center/customer/constants";

// Helper
import { renderCustomerType } from "@sale/customer-center/customer/helpers";

// Components
import FilterBox from "@sale/customer-center/customer/components/filter-box";
import ListLayout from "@/components/layout/list-layout";

class CustomerResponse extends Component {

  renderColumn = () => {
    return [
      {
        title: "Name",
        sortable: true,
        dataIndex: "displayName",
        sortKey: "displayName",
        width: "20%",
        render: (text, row) => {
          const { title, firstName, lastName } = row;
          return (
            <Fragment>
              {title} {firstName} {lastName}
            </Fragment>
          );
        }
      },
      {
        title: "Payment Term",
        width: "20%",
        sortable: true,
        dataIndex: "paymentTerm",
        render: (text, row) => {
          const { paymentTerm } = row;
          if (paymentTerm) {
            return (
              <Fragment>
                {paymentTerm.name}
              </Fragment>
            );
          }
          return null;
        }
      },
      {
        title: "Customer Company",
        width: "20%",
        sortable: true,
        dataIndex: "customerCompany",
        render: (text, row) => {
          const { customerCompany, type } = row;
          if (customerCompany && type == CUSTOMER_TYPE_COMPANY_ID) {
            return (
              <Fragment>
                {customerCompany.name}
              </Fragment>
            );
          }
          return "N/A";
        }
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        width: "20%",
      },
      {
        title: "Type",
        sortable: true,
        dataIndex: "type",
        width: "20%",
        render: (text) => renderCustomerType(text)
      }
    ];
  }

  render() {
    const { permission, userId } = this.props;
    const filterKeys = [
      "type", 
      "status",
      "paymentTerm", 
      "customerCompany"
    ];
    return (
      <ListLayout 
        className="m-0"
        action={getAll}
        creatable={false}
        tableSize="small"
        showHeader={false}
        reducerName="customer"
        permission={permission}
        filterKeys={filterKeys}
        title="Customer Response"
        filterComponent={FilterBox}
        schema={CustomerArraySchema}
        columns={this.renderColumn()}
        requestName={CUSTOMER_LIST_REQUEST}
        isPermissionAllowed={true}
        extraFilter={(filter) => {
          filter.user = userId;
          return filter;
        }}
      />
    );
  }
}

CustomerResponse.propTypes = {
  userId: PropTypes.number,
  match: PropTypes.object,
  permission: PropTypes.object,
};

export default CustomerResponse;