export const JUNK_OUT_LIST_REQUEST = "JUNK_OUT_REQUEST";
export const JUNK_OUT_LIST_SUCCESS = "JUNK_OUT_REQUEST_SUCCESS";
export const JUNK_OUT_AUTOCOMPLETE_REQUEST = "JUNK_OUT_AUTOCOMPLETE_REQUEST";
export const JUNK_OUT_AUTOCOMPLETE_SUCCESS = "JUNK_OUT_AUTOCOMPLETE_SUCCESS";
export const JUNK_OUT_CREATE_REQUEST = "JUNK_OUT_CREATE_REQUEST";
export const JUNK_OUT_DETAIL_REQUEST = "JUNK_OUT_DETAIL_REQUEST";
export const JUNK_OUT_EDIT_REQUEST = "JUNK_OUT_EDIT_REQUEST";
export const JUNK_OUT_ARCHIVE_REQUEST = "JUNK_OUT_ARCHIVE_REQUEST";
export const JUNK_OUT_UNARCHIVE_REQUEST = "JUNK_OUT_UNARCHIVE_REQUEST";
export const JUNK_OUT_DELETE_REQUEST = "JUNK_OUT_DELETE_REQUEST";
export const JUNK_OUT_BATCH_DELETE_REQUEST = "JUNK_OUT_BATCH_DELETE_REQUEST";

export const JUNK_OUT_SUBMIT_REQUEST = "JUNK_OUT_SUBMIT_REQUEST";
export const JUNK_OUT_APPROVE_REQUEST = "JUNK_OUT_APPROVE_REQUEST";
export const JUNK_OUT_REJECT_REQUEST = "JUNK_OUT_REJECT_REQUEST";
export const JUNK_OUT_CANCEL_REQUEST = "JUNK_OUT_CANCEL_REQUEST";
export const JUNK_OUT_PROCESS_ITEM_REQUEST = "JUNK_OUT_PROCESS_ITEM_REQUEST";

export const JUNK_OUT_CREATE_FORM_NAME = "createJunkOut";
export const JUNK_OUT_EDIT_FORM_NAME = "editJunkOut";
export const JUNK_OUT_ITEM_ARRAY_FIELD_NAME = "junkOutItems";
export const JUNK_OUT_PROCESS_ITEM_FORM_NAME = "processItem";
export const JUNK_OUT_SCAN_SERIAL_FORM_NAME = "scanSerial";

export const JUNK_OUT_SUBMIT_RULES = {
  warehouseId: {
    required: true
  },
  name: {
    required: true
  },
  junkOutTypeId: {
    required: true
  },
  junkOutItems: {
    type: "arrayInline",
    message: "Please add at lease 1 item.",
    required: true,
    subFields: [
      {
        name: "itemId"
      },
      {
        name: "quantity",
        type: "number",
      },
    ],
  }
};

export const JUNK_OUT_STATUS_DRAFT = 1;
export const JUNK_OUT_STATUS_DRAFT_TITLE = "Draft";
export const JUNK_OUT_STATUS_SUBMITTED = 2;
export const JUNK_OUT_STATUS_SUBMITTED_TITLE = "Submitted";
export const JUNK_OUT_STATUS_APPROVED = 3;
export const JUNK_OUT_STATUS_APPROVED_TITLE = "Approved";
export const JUNK_OUT_STATUS_REJECTED = 4;
export const JUNK_OUT_STATUS_REJECTED_TITLE = "Rejected";
export const JUNK_OUT_STATUS_CANCELLED = 5;
export const JUNK_OUT_STATUS_CANCELLED_TITLE= "Cancelled";
export const JUNK_OUT_STATUS_PROCESSED = 6;
export const JUNK_OUT_STATUS_PROCESSED_TITLE = "Processed";

export const JUNK_OUT_STATUS = [
  {
    id: JUNK_OUT_STATUS_DRAFT,
    name: JUNK_OUT_STATUS_DRAFT_TITLE,
  },
  {
    id: JUNK_OUT_STATUS_SUBMITTED,
    name: JUNK_OUT_STATUS_SUBMITTED_TITLE,
  },
  {
    id: JUNK_OUT_STATUS_APPROVED,
    name: JUNK_OUT_STATUS_APPROVED_TITLE,
  },
  {
    id: JUNK_OUT_STATUS_REJECTED,
    name: JUNK_OUT_STATUS_REJECTED_TITLE,
  },
  {
    id: JUNK_OUT_STATUS_CANCELLED,
    name: JUNK_OUT_STATUS_CANCELLED_TITLE,
  },
  {
    id: JUNK_OUT_STATUS_PROCESSED,
    name: JUNK_OUT_STATUS_PROCESSED_TITLE,
  },
];

export const JUNK_OUT_PROCESS_ITEM_SUBMIT_RULES = {
  warehouseId: {
    required: true,
  },
  junkOutItems: {
    type: "array",
    message: "junk out item must have at lease 1 item.",
    required: true
  }
};

export const READ_JUNK_OUT = 224000;
export const CREATE_JUNK_OUT = 224001;
export const UPDATE_JUNK_OUT = 224002;
export const DELETE_JUNK_OUT = 224003;
export const ARCHIVE_JUNK_OUT = 224004;
export const UNARCHIVE_JUNK_OUT = 224005;
export const SUBMIT_JUNK_OUT = 224015;
export const APPROVE_JUNK_OUT = 224011;
export const REJECT_JUNK_OUT = 224012;
export const PROCESS_JUNK_OUT = 224013;
export const CANCEL_JUNK_OUT = 224014;
export const PRINT_JUNK_OUT = 224007;