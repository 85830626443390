const size = {
  MobileS: "320px",
  MobileM: "414px",
  MobileL: "425px",
  Tablet: "768px",
  Laptop: "1024px",
  LaptopM: "1366px",
  LaptopL: "1440px",
  Desktop: "2560px"
};

export const MobileS = `(max-width: ${size.MobileS})`;
export const MobileM = `(max-width: ${size.MobileM})`;
export const MobileL = `(min-width: ${size.MobileL})`;
export const Tablet =`(min-width: ${size.Tablet})`;
export const Ipad =`(min-width: ${size.Tablet}) and (max-width: ${size.Laptop})`;
export const IpadPro =`(min-width: ${size.Laptop}) and (max-width: ${size.LaptopM})`;
export const Laptop = `(min-width: ${size.Laptop})`;
export const LaptopL = `(min-width: ${size.LaptopL})`;
export const Desktop = `(min-width: ${size.Desktop})`;
export const DesktopL = `(min-width: ${size.Desktop})`;