export const JOB_TYPE_LIST_REQUEST = "JOB_TYPE_LIST_REQUEST"; 
export const JOB_TYPE_LIST_SUCCESS = "JOB_TYPE_LIST_REQUEST_SUCCESS";
export const JOB_TYPE_CREATE_REQUEST = "JOB_TYPE_CREATE_REQUEST"; 
export const JOB_TYPE_DETAIL_REQUEST = "JOB_TYPE_DETAIL_REQUEST"; 
export const JOB_TYPE_ARCHIVE_REQUEST = "JOB_TYPE_ARCHIVE_REQUEST"; 
export const JOB_TYPE_DELETE_REQUEST = "JOB_TYPE_DELETE_REQUEST"; 
export const JOB_TYPE_UNARCHIVE_REQUEST = "JOB_TYPE_UNARCHIVE_REQUEST"; 
export const JOB_TYPE_EDIT_REQUEST = "JOB_TYPE_EDIT_REQUEST"; 
export const JOB_TYPE_CREATE_FORM_NAME = "createJobType"; 
export const JOB_TYPE_EDIT_FORM_NAME = "editJobType"; 
export const JOB_TYPE_AUTOCOMPLETE_REQUEST = "JOB_TYPE_AUTOCOMPLETE_REQUEST";
export const JOB_TYPE_AUTOCOMPLETE_SUCCESS = "JOB_TYPE_AUTOCOMPLETE_SUCCESS";
export const JOB_TYPE_SUBMIT_RULES = {
  name: {
    required: true,
  },
  status: {
    required: true,
  }
};
export const JOB_TYPE_STATUS = [
  {
    id: 1,
    name: "Active"
  },
  {
    id:  -1,
    name: "Deactive"
  },
];

export const READ_JOB_TYPE = 146000;
export const CREATE_JOB_TYPE = 146001;
export const UPDATE_JOB_TYPE = 146002;
export const DELETE_JOB_TYPE = 146003;
export const ARCHIVE_JOB_TYPE = 146004;
export const UNARCHIVE_JOB_TYPE = 146005;