import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import _ from "lodash";
import { Modal } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Component 
import SelectAutoCompleteField from "./select";
import AutoCompleteAdvance from "../auto-complete-advance";

// utils
import { getAutoComplete } from "@modules/utils/value-selector";

class SelectAutoComplete extends React.Component {

  constructor(props) {
    super(props);
    this.loadData();
    this.state = {
      isSearching: undefined,
      data: [],
      oldData: [],
      isFirstLoad: false,
      visible: false,
      query: null,
    };
  }

  onShowModal = () => {
    this.setState({
      visible: true,
    });
  }

  onCancel = () => {
    this.setState({
      visible: false,
    });
  }

  loadData = (params) => {
    const { loadData, onDataLoaded } = this.props;
    const onLoadData = loadData(params);
    if (onLoadData != null) {
      onLoadData.then((data) => {
        if (onDataLoaded) {
          onDataLoaded(data);
        }
      }); 
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    const { dataSource, extra, loadData, rerenderWhenIdsChanged } = this.props;
    const { dataSource: newDataSource, extra: newExtra } = newProps;
    const { isFirstLoad, query } = this.state;
    if (dataSource != newDataSource) {
      if (isFirstLoad == false) {
        this.setState({
          data: newDataSource,
          oldData: newDataSource,
          isFirstLoad: true
        });
      } else {
        this.setState({
          data: newDataSource,
          isSearching: true
        });
      }
    }
    if (!rerenderWhenIdsChanged) {
      if (newExtra && newExtra.ids) {
        delete newExtra.ids;
      }
      if (extra && extra.ids) {
        delete extra.ids;
      }
    }
    if (!_.isEqual(newExtra, extra)) {
      loadData(query, newExtra);
    }
  }

  search = _.debounce((value) => {
    this.filterOption(value);
  }, 250)

  filterOption = (value) => {
    const { oldData } = this.state;
    if (value) {
      this.setState({
        data: oldData,
        isSearching: true,
        query: value
      });
      if (this.loadData(value)) {
        this.setState({
          isSearching: true
        });
      }
    } else {
      const { oldData } = this.state;
      this.setState({
        isSearching: false,
        data: oldData,
        query: null
      });
    }
  }

  getValue = (value, data) => {
    let foundObject = _.find(data, function(e) {
      return e.id === value;
    });
    return foundObject;
  }

  handleChange = async (value, onChange) => {
    let newValues = value;
    if (_.isArray(value) && value.length > 0) {
      newValues = value[value.length - 1];
    }
    let selectedObject = null;
    const { oldData } = this.state;
    const existingData = this.getValue(newValues, oldData);
    selectedObject = existingData;
    if (existingData == null) {
      const { dataSource } = this.props;
      const newData = this.getValue(newValues, dataSource);
      if (newData != null) {
        selectedObject = newData;
        oldData.unshift(newData);  
      }
    }
    const { 
      onSelectChange, 
      beforeChange, 
      onChange: changeHandler,
    } = this.props;
    if (beforeChange) {
      await beforeChange(value, selectedObject);
    }
    onChange(value || null);
    if (changeHandler) {
      changeHandler(value || null);
    }
    if (onSelectChange) {
      onSelectChange(selectedObject);
    }
    this.setState({
      isSearching: false,
    });
  }

  renderDataSource = () => {
    const { 
      existingData, 
      selectedValue,
    } = this.props;
    const { 
      isSearching, 
      data, 
      oldData,
    } = this.state;
    let items = isSearching ? data : oldData || [];
    items = items && items.filter(function(item) {
      const matchedData  = existingData.find(function(data) {
        const existed = item.id === (data.existedKey || data.id);
        return existed;
      });
      return !matchedData;
    });
    if (selectedValue && selectedValue.length > 0) {
      items = items.concat(selectedValue);
    }
    items = _.uniqBy(items, "id");
    return items;
  }

  handleBlur = () => {
    this.setState({
      isSearching: false,
    });
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
  }

  render() {
    const { 
      optionRender,
      optionDisabled,
      titleKey, 
      mode,
      valueKey, 
      label,
      disabled,
      required,
      loading,
      name,
      sorter,
      action,
      requestName,
      extra,
      columns,
      extraFilter,
      advanceNoModal,
      onChange,
      existingData, 
      selectedValue,
      onSelectChange,
      initialValues,
      modalWidth,
      onTableChange,
      displayDefaultColumns,
      isBox,
      ...props
    } = this.props;
    const { visible } = this.state;
    return (
      <Fragment>
        {advanceNoModal ? (
          <AutoCompleteAdvance 
            isBox={isBox}
            mode={mode}
            name={name}
            sorter={sorter}
            label={label}
            titleKey={titleKey}
            valueKey={valueKey}
            action={action}
            columns={columns}
            requestName={requestName}
            extraFilter={extraFilter}
            extra={extra}
            onChange={onChange}
            existingData={existingData}
            selectedValue={selectedValue}
            optionRender={optionRender}
            disabled={optionDisabled}
            onTableChange={onTableChange}
            initialValues={initialValues}
            handleChange={this.handleChange}
            displayDefaultColumns={displayDefaultColumns}
          />
        ) : (
          <Fragment>
            <Field 
              name={name}
              onSearch={this.search}
              handleChange={this.handleChange}
              component={SelectAutoCompleteField}
              autoCompleteDataSource={this.renderDataSource()}
              optionRender={optionRender}
              optionDisabled={optionDisabled}
              titleKey={titleKey}
              valueKey={valueKey}
              mode={mode}
              label={label}
              disabled={disabled}
              required={required}
              loading={loading}
              handleBlur={this.handleBlur}
              onSelectChange={onSelectChange}
              onShowModal={this.onShowModal}
              initialValues={initialValues}
              advance={true}
              {...props}
            />
            <Modal
              width={modalWidth || "50%"}
              visible={visible}
              title="Advance Search"
              onCancel={this.onCancel}
              footer={null}
              maskClosable={false}
            >
              {visible && (
                <AutoCompleteAdvance 
                  mode={mode}
                  name={name}
                  sorter={sorter}
                  label={label}
                  titleKey={titleKey}
                  valueKey={valueKey}
                  action={action}
                  columns={columns}
                  extraFilter={extraFilter}
                  extra={extra}
                  optionRender={optionRender}
                  disabled={optionDisabled}
                  initialValues={initialValues}
                  onSelectChange={onSelectChange}
                  requestName={requestName}
                  onTableChange={onTableChange}
                  displayDefaultColumns={displayDefaultColumns}
                />
              )}
            </Modal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

SelectAutoComplete.propTypes = {
  loadData: PropTypes.func,
  onDataLoaded: PropTypes.func,
  titleKey: PropTypes.string,
  optionRender: PropTypes.func,
  optionDisabled: PropTypes.func,
  dataSource: PropTypes.array,
  onSelectChange: PropTypes.func,
  beforeChange: PropTypes.func,
  onChange: PropTypes.func,
  existingData: PropTypes.array,
  selectedValue: PropTypes.array,
  required: PropTypes.bool,
  valueKey: PropTypes.string,
  mode: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  action: PropTypes.func,
  requestName: PropTypes.string,
  extra: PropTypes.object,
  initialValues: PropTypes.object,
  columns: PropTypes.any,
  extraFilter: PropTypes.any,
  advanceNoModal: PropTypes.bool,
  modalWidth: PropTypes.string,
  displayDefaultColumns: PropTypes.bool,
  onTableChange: PropTypes.func,
  sorter: PropTypes.bool,
  isBox: PropTypes.bool,
  rerenderWhenIdsChanged: PropTypes.bool,
};

SelectAutoComplete.defaultProps = {
  required: false,
  dataSource: [],
  titleKey: "name",
  valueKey: "id",
  existingData: [],
  selectedValue: [],
  mode: "single",
};

const mapStateToProps = (state, ownProps) => {
  const { requestName } = ownProps;
  return {
    entities: state.entities,
    loading: state.request[requestName],
    dataSource: getAutoComplete(state, requestName)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let { extra, requestName, action } = ownProps;
  if (action) {
    const defaultExtra = { requestName, ...extra };
    return {
      loadData: (query, newExtra) => dispatch(action(query, newExtra ? { requestName, ...newExtra } : defaultExtra)),
    };
  }
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectAutoComplete));