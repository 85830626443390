import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { submit } from "redux-form";
import { denormalize } from "normalizr";
import { Row, Col } from "reactstrap";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { TreeSelect, Form, Modal, Table, Button, Input } from "antd";

// Components
import ModalCreate from "../select-autocomplete/create-modal";

// utils
import { createSubmitAction } from "@/helpers";
import { getPermissionAllowed } from "@modules/utils/value-selector";
import { CustomFieldArraySchema } from "@modules/settings/setup/custom-field/schema";
import * as CreateLayoutConstants from "@/components/layout/create-layout/constant";

class TreeSelectAutoCompleteField extends React.Component {

  onChange = (value) => {
    const { handleChange, input: { onChange } } = this.props;
    onChange(value);
    if (handleChange) {
      handleChange(value);
    }
  }

  state = {
    visible: false,
    newData: [],
    visibleSearch: false,
    selectedId: null,
    searchData: [],
    isSearching: false,
  }

  onShowModalSearch = (selectedId) => {
    if (selectedId) {
      this.setState({
        visibleSearch: true,
        selectedId,
      });
    } else {
      this.setState({
        visibleSearch: true,
      });
    }
  }

  onShowModal = () => {
    this.setState({
      visible: true,
    });
  }

  onCancel = () => {
    this.setState({
      visible: false,
      searchData: [],
      visibleSearch: false,
      isSearching: false,
    });
  }

  handleSubmitHandler = (values, dispatch) => {
    const {
      label, 
      createAction, 
      beforeSubmit,
      entities,
      customFields,
      input: { onChange, name },
    } = this.props;
    const customFieldDataSource = denormalize(customFields, CustomFieldArraySchema, entities);
    let { submitRules } = this.props;
    values[CreateLayoutConstants.SAVE_AND_CONTINUE_CREATE] = true;
    values["formName"] = `create${name}`;
    values["entityTitle"] = label;
    if (typeof submitRules === "function") {
      submitRules = submitRules(values);
    }
    if (customFieldDataSource && customFieldDataSource.length > 0) {
      const subFields = [];
      customFieldDataSource.map((value, index) => {
        const { name, id, isRequired } = value;
        const customFieldSubmitRulesSubFields = {
          name: `${id}_${name}`,
          required: isRequired,
          inputKey: index
        };
        subFields.push(customFieldSubmitRulesSubFields);
      });
      const customFieldSubmitRules = {
        customFieldsArrayField: {
          type: "arrayInlineCustomField",
          subFields
        }
      };
      submitRules = Object.assign(submitRules, customFieldSubmitRules);
    }
    if (values.customFieldsArrayField && values.customFieldsArrayField.length > 0) {
      const newCustomFieldValues = [];
      values.customFieldsArrayField.map((value) => {
        let subValue = {};
        if (value) {
          const fieldKey = Object.keys(value).toString();
          const customFieldKey = fieldKey.split("_");
          const id = Number(customFieldKey[0]);
          subValue.id = id;
          subValue.customFieldValue = {
            value: value[fieldKey]
          };
        }
        newCustomFieldValues.push(subValue);
      });
      values.customFields = newCustomFieldValues;
    }
    return createSubmitAction(submitRules, createAction, () => {
      if (beforeSubmit) {
        return beforeSubmit(values);
      } else {
        return values;
      }
    })(values, dispatch).then((value) => {
      if (value != null) {
        const { newData } = this.state;
        if (newData.length > 0) {
          const newValue = [value];
          this.setState({
            newData: [
              ...newValue,
              ...this.state.newData,
            ]
          });
        } else {
          this.setState({
            newData: [value]
          });
        }
        onChange(value.id);
        this.onCancel();
      }
    });
  }

  onSelect = (selectedId) => {
    this.setState({
      selectedId,
    });
  }

  onApply = (selectedId) => {
    this.onChange(selectedId);
    this.onCancel();
  }

  onSearch = (event) => {
    const { target: { value} } = event;
    if (value) {
      const { service, onNewDataSource, extra } = this.props;
      let newValue = {};
      if(extra) {
        newValue = {
          value,
          limit: 1000,
          ...extra,
        };
      }
      service(extra ? newValue : value).then((response) => {
        this.setState({
          searchData: onNewDataSource ? onNewDataSource(response.data) : response.data,
          isSearching: true,
        });
      }).catch(() => {
        this.setState({
          searchData: []
        });
      });
    } else {
      this.setState({
        searchData: [],
        isSearching: false,
      });
    }
  }

  columns = [
    {
      title: "Name",
      dataIndex: "title",
    },
  ];

  render() {
    const { 
      label,
      entity,
      loading,
      onSubmit,
      required,
      disabled,
      withFilter,
      requestName,
      formComponent,
      createLoading,
      treeNodeFilterProp,
      isAllowCreate,
      creatable,
      mode,
      advanceSearch,
      meta: { touched, error },
      input: { onFocus, value, name },
    } = this.props;
    const { visible, selectedId, isSearching, searchData, visibleSearch } = this.state;
    let { placeholder, treeData, style } = this.props;
    placeholder = loading ? "Loading..." : placeholder;
    const validateStatus = classnames(touched && error ? "error" : "success");
    const addOnClassName = classnames((isAllowCreate && creatable) || advanceSearch && "ant-input-group");
    const disabledClassName = classnames(disabled == true && "disabled");
    if (label == null && withFilter) {
      const defaultStyle = {
        width: "auto",
        minWidth: "150px"
      };
      style = Object.assign(defaultStyle, style);
    }
    const multiple = mode == "multiple";
    const newTreeDataSource = isSearching ? searchData : treeData;
    const rowSelection = {
      type: multiple ? "checkbox " : "radio",
      onChange: (selectedRowKeys, row) => {
        const { id } = row[0];
        this.onSelect(id);
      },
      selectedRowKeys: [selectedId],
      getCheckboxProps: (row) => {
        return {
          disabled: row.chartAccounts != null,
        };
      }
    };
    return (
      <Fragment>
        <Form.Item
          help={error}
          label={label} 
          required={required}
          validateStatus={validateStatus}
        >
          <span className="ant-input-group-wrapper">
            <span className={`ant-input-wrapper ${addOnClassName}`}>
              <TreeSelect
                showSearch
                style={style}
                maxTagCount={3}
                onFocus={onFocus}
                disabled={disabled}
                treeData={treeData}
                treeDefaultExpandAll
                multiple={multiple}
                placeholder={placeholder}
                onChange={this.onChange}
                treeNodeFilterProp={treeNodeFilterProp}
                dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
                value={(treeData && treeData.length) > 0 ? (value || undefined) : undefined}
              />
              {(isAllowCreate && creatable) && (
                <span className={`ant-input-group-addon ${disabledClassName}`} onClick={disabled == true ? null : this.onShowModal}>
                  <PlusCircleOutlined />
                </span>
              )}
              {advanceSearch && (
                <span className={`ant-input-group-addon ${disabledClassName}`} onClick={() => disabled == true ? null : this.onShowModalSearch(value)}>
                  <SearchOutlined />
                </span>
              )}
            </span>
          </span>
        </Form.Item>
        <ModalCreate 
          label={label}
          entity={entity}
          visible={visible}
          onSave={onSubmit}
          form={`create${name}`}
          onCancel={this.onCancel}      
          formName={`create${name}`}
          formComponent={formComponent}
          createLoading={createLoading}
          requestName={requestName}
          handleSubmitHandler={this.handleSubmitHandler}
        />
        <Modal
          title="Advance Search"
          visible={visibleSearch}
          footer={null}
          width="1000px"
          onCancel={this.onCancel}
          destroyOnClose={true}
        >
          {(treeData && visibleSearch) && (
            <Fragment>
              <Row>
                <Col xs="12">
                  <Input 
                    autoFocus
                    className="mb-2" 
                    placeholder="Search..."
                    onChange={this.onSearch}
                    prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />} 
                  />
                </Col>
                <Col xs="12">
                  {newTreeDataSource.length > 0 && (
                    <Table 
                      size="small"
                      columns={this.columns} 
                      rowSelection={rowSelection} 
                      dataSource={newTreeDataSource} 
                      defaultExpandAllRows
                      pagination={false}
                      rowKey={(row) => row.id}
                      scroll={{
                        y: 600
                      }}
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Button 
                    ghost 
                    type="primary" 
                    onClick={() => this.onApply(selectedId)}
                    disabled={selectedId == null}
                    className="float-right mt-2"
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

TreeSelectAutoCompleteField.propTypes = {
  meta: PropTypes.any,
  input: PropTypes.any,
  required: PropTypes.any,
  label: PropTypes.string,
  style: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  entity: PropTypes.string,
  withFilter: PropTypes.bool,
  handleBlur: PropTypes.func,
  treeData: PropTypes.array,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  requestName: PropTypes.string,
  formComponent: PropTypes.any,
  createLoading: PropTypes.bool,
  treeNodeFilterProp: PropTypes.string,
  submitRules: PropTypes.any,
  createAction: PropTypes.func,
  customFields: PropTypes.array,
  beforeSubmit: PropTypes.any,
  entities: PropTypes.object,
  isAllowCreate: PropTypes.bool,
  creatable: PropTypes.bool,
  mode: PropTypes.string,
  advanceSearch: PropTypes.bool,
  service: PropTypes.func,
  onNewDataSource: PropTypes.func,
  extra: PropTypes.any,
};

TreeSelectAutoCompleteField.defaultProps = {
  treeNodeFilterProp: "title",
  disabled: false,
  creatable: false,
  treeData: [],
  advanceSearch: false,
};

const mapStateToProps = (state, ownProps) => {
  const { 
    entity ,
    permissionCode, 
    createRequestName, 
  } = ownProps;
  return {
    entities: state.entities,
    customFields: state.customField[entity] || [],
    createLoading: state.request[createRequestName],
    isAllowCreate: getPermissionAllowed(state, permissionCode),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { input: { name } } = ownProps; 
  return {
    onSubmit: () => dispatch(submit(`create${name}`))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TreeSelectAutoCompleteField));