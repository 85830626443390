import {
  CustomFieldSchema,
  CustomFieldArraySchema,
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

const path = "/settings/setup/custom-field";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomField,
    schema: CustomFieldArraySchema,
    requestName: Constants.CUSTOMER_FIELD_REQUEST,
    dispatch,
    getState,
  };
  return createListAction(options, values);
};

export const getCustomFieldByEntity = (entityName, requestName) => (dispatch, getState) => {
  if (requestName == null) {
    requestName = Constants.CUSTOMER_FIELD_REQUEST;
  }
  const options = {
    serviceMethod: service.getCustomFieldByEntity,
    schema: CustomFieldArraySchema,
    requestName,
    dispatch,
    getState,
  };
  return createListAction(options, entityName);
};

export const getCustomFieldByEntityToModalCreate = (entityName) => (dispatch, getState) => {

  const successAction = (objectIds) => {
    return {
      type: Constants.CUSTOMER_FIELD_REQUEST_BY_ENTITY_SUCCESS,
      payload: objectIds,
      entityName
    };
  };

  const options = {
    serviceMethod: service.getCustomFieldByEntity,
    schema: CustomFieldArraySchema,
    requestName: `${Constants.CUSTOMER_FIELD_REQUEST_BY_ENTITY_REQUEST}_${entityName}`,
    dispatch,
    getState,
    successAction
  };
  return createListAction(options, entityName);
};

export const getCustomFieldAutoComplete = (query, extra) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomFieldAutoComplete,
    requestName: Constants.CUSTOMER_FIELD_AUTOCOMPLETE_REQUEST,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const createCustomField = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createCustomField,
    requestName: Constants.CREATE_CUSTOMER_FIELD_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "CustomField",
  };
  return createNewEntityAction(options, values);
};

export const getCustomFieldDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomFieldDetail,
    requestName: Constants.CUSTOMER_FIELD_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: CustomFieldSchema,
  };
  return createDetailAction(options, id);
};

export const editCustomField = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editCustomField,
    requestName: Constants.CUSTOMER_FIELD_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "customFields",
    schema: CustomFieldSchema,
    successMessage: "Custom field is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveCustomField = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveCustomField,
    requestName: Constants.CUSTOMER_FIELD_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Custom field is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteCustomField = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteCustomField,
    requestName: Constants.CUSTOMER_FIELD_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Custom field is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveCustomField = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveCustomField,
    requestName: Constants.CUSTOMER_FIELD_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Custom field is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};