import _ from "lodash";
import {   
  DASHBOARD_REQUEST, 
  DASHBOARD_SUCCESS,
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  WIDGET_FILTER_SUCCESS,
  WIDGET_FILTER_REQUEST,
  ADD_DASHBOARD_WIDGET_REQUEST,
  UPDATE_DASHBOARD_WIDGET_OPTIONS,
  DELETE_DASHBOARD_WIDGET_REQUEST,
} from "@modules/dashboard/constants";
import { 
  createDeleteAction,
  createDashboardAction, 
  createDetailAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import { mergeEntity } from "@/actions/entity";
import { SET_DASHBOARD } from "@/constants/dashboard";
import { DashboardSchema } from "@modules/dashboard/schemas";
import { DashboardWidgetSchema } from "@modules/dashboard/schemas/dashboard-widget";
// services
import * as services from "@/services/dashboard";

export const setDashboard = (data, requestName) => {
  return {
    type: SET_DASHBOARD,
    requestName,
    data
  };
};

export const getDashboardList = () => (dispatch, getState) => {

  const successAction = (payload) => {
    return {
      type: DASHBOARD_SUCCESS,
      payload,
    };
  };

  const options = {
    serviceMethod: services.getDashboardList,
    requestName: DASHBOARD_REQUEST,
    schema: DashboardSchema,
    dispatch,
    getState,
    successAction,
  };
  return createDetailAction(options);
};

export const getDashBoardWidget = (query, requestName, endPoint) => (dispatch, getState) => {
  const options = {
    serviceMethod: services.getDashBoardWidget,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createDashboardAction(options, query, endPoint);
};


export const getAllModules = () => (dispatch, getState) => {

  const successAction = (payload) => {
    return {
      type: GET_MODULES_SUCCESS,
      payload,
    };
  };

  const options = {
    serviceMethod: services.getAllModules,
    requestName: GET_MODULES_REQUEST,
    dispatch,
    getState,
    successAction,
  };
  return createDetailAction(options);
};

export const filterWidget = (values) => (dispatch, getState) => {

  const successAction = (payload) => {
    return {
      type: WIDGET_FILTER_SUCCESS,
      payload: payload.data,
    };
  };

  const options = {
    serviceMethod: services.filterWidget,
    requestName: WIDGET_FILTER_REQUEST,
    dispatch,
    getState,
    successAction,
  };
  return createDetailAction(options, values);
};

export const addDashboardDashboard = (values) => (dispatch, getState) => {
  
  const options = {
    serviceMethod: services.addDashboardDashboard,
    schema: DashboardWidgetSchema,
    requestName: ADD_DASHBOARD_WIDGET_REQUEST,
    dispatch,
    getState,
    entityName: "dashboardWidgets",
    shouldSyncEntities: true,
    successMessage: "Widget is added successfully!",
    successAction: (response, normalized) => {
      const state = getState();
      const dashboardId = response.dashboard && response.dashboard.id;
      const dashboardEntity = state.entities.dashboards && state.entities.dashboards[dashboardId];
      const oldData = state.entities.dashboards && state.entities.dashboards[dashboardId] && state.entities.dashboards[dashboardId].dashboardWidgets;
      const newData = [
        ...oldData,
        normalized.result,
      ];
      dashboardEntity.dashboardWidgets = newData;
      return dispatch(mergeEntity("dashboards", dashboardId, dashboardEntity));
    }
  };
  
  return createEntityActionAction(options, values);
};

export const updateDashboardOptions = (values) => (dispatch, getState) => {

  const options = {
    serviceMethod: services.updateDashboardOptions,
    schema: DashboardWidgetSchema,
    requestName: UPDATE_DASHBOARD_WIDGET_OPTIONS,
    dispatch,
    getState,
    successMessage: "Widget options is updated successfully!",
  };
  
  return createEntityActionAction(options, values);
};

export const deleteDashBoardWidget = (id, dashboardId) => (dispatch, getState) => {
  
  const onSuccess = () => {
    const state = getState();
    const dashboardEntity = state.entities.dashboards && state.entities.dashboards[dashboardId];
    const data = state.entities.dashboards && state.entities.dashboards[dashboardId] && state.entities.dashboards[dashboardId].dashboardWidgets;
    _.remove(data, (value) => value == id);
    dashboardEntity.dashboardWidgets = data;
    dispatch(mergeEntity("dashboards", dashboardId, dashboardEntity));
  };

  const options = {
    serviceMethod: services.deleteDashBoardWidget,
    requestName: DELETE_DASHBOARD_WIDGET_REQUEST,
    dispatch,
    getState,
    onSuccess,
    successMessage: "Widget is removed successfully!",
  };
  return createDeleteAction(options, id);
};