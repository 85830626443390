import React from "react";
import PropTypes from "prop-types";

// Libs
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { Button, Table } from "antd";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

// Utils
import { confirmDeleteModal } from "@modules/helpers";
import { deleteWorkExperience, getWorkExperience } from "../../../actions";
import { workExperienceArraySchema } from "../../../schema/employee-work-experience";
import { EMPLOYEE_WORK_EXPERIENCE_MODAL_ID, WORK_EXPERIENCE_REQUEST } from "../../../constants";

class EmployeeWorkExperience extends React.Component {

  constructor(props) {
    super(props);
    this.getEmployeeWorkExperience();
  }

  getEmployeeWorkExperience = () => {
    const { 
      getEmployeeWorkExperience,
      match: { params } 
    } = this.props;
    getEmployeeWorkExperience(params.id);
  }

  confirmDelete = (id) => {
    const { 
      deleteWorkExperience, 
      match: { params } 
    } = this.props;
    const values = {
      id,
      employeeId: params.id,
    };
    confirmDeleteModal(
      values,
      "experience",
      deleteWorkExperience
    );
  }

  columns = () => {
    const { openModalEdit } = this.props;
    return [
      {
        title: "Company",
        dataIndex: "company",
      },
      {
        title: "Period",
        dataIndex: "period",
      },
      {
        title: "From Year",
        dataIndex: "from",
      },
      {
        title: "To Year",
        dataIndex: "to",
      },
      {
        title: "Position",
        dataIndex: "position",
      },
      {
        title: "Actions",
        render: (_, values) => {
          return (
            <div className="align-item-center justify-content-center">
              <EditOutlined 
                type="edit" 
                onClick={() => openModalEdit(values, EMPLOYEE_WORK_EXPERIENCE_MODAL_ID)} 
              />
              <DeleteOutlined 
                type="delete" 
                className="ml-2"
                onClick={() => this.confirmDelete(values.id)} 
              />
            </div>
          );
        }
      },
    ];
  };

  render() {
    const { 
      loading,
      entities,
      match: { params },
      openModalCreate,
      employeeWorkExperience, 
    } = this.props;
    const workExperienceEntities = denormalize(
      employeeWorkExperience[params.id],
      workExperienceArraySchema,
      entities,
    );
    return (
      <Table 
        size="small"
        loading={loading}
        pagination={false}
        columns={this.columns()} 
        dataSource={workExperienceEntities} 
        footer={() => {
          return (
            <Button 
              ghost
              size="small"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => openModalCreate(EMPLOYEE_WORK_EXPERIENCE_MODAL_ID)}
            >
              Add New
            </Button>
          );
        }}
      />
    );
  }
}

EmployeeWorkExperience.propTypes = {
  match: PropTypes.any,
  loading: PropTypes.bool,
  entities: PropTypes.object,
  employeeId: PropTypes.string,
  openModalEdit: PropTypes.func,
  openModalCreate: PropTypes.func,
  deleteWorkExperience: PropTypes.func,
  employeeWorkExperience: PropTypes.object,
  getEmployeeWorkExperience: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { entities, request, employeeWorkExperience } = state;
  return { 
    entities,
    employeeWorkExperience,
    loading: request[WORK_EXPERIENCE_REQUEST],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteWorkExperience: (id, employeeId) => dispatch(deleteWorkExperience(id, employeeId)),
    getEmployeeWorkExperience: (id) => dispatch(getWorkExperience(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeWorkExperience));