import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import _ from "lodash";
import { withRouter } from "react-router";
import {
  toQueryParamDate,
  getQueryParamUrl,
  updateQueryParamUrl,
  getQueryParamFromLocalStorage,
  updateQueryParamToLocalStorage,
} from "@modules/helpers";

import { 
  Col,
  Form,
  Menu, 
  Row, 
  Button, 
  Checkbox, 
  Dropdown, 
} from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import AsyncDateRangePicker from "../async-date-range-picker";
import * as Constants from "@cores/components/auto-complete/date-range-option/constants";

function AsyncDateRangeOption(props) {

  const { defaultValue, location, queryStorage, onlyLocal, onChange: onHandleChange } = props;
  
  let query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
  const view = getQueryParamUrl()["view"];
  const [visible, setVisible] = React.useState(false);
  const defaultQuery = Number(query.dateType);
  const [checkedValues, setCheckedValues] = React.useState(defaultQuery ? [defaultQuery] : []);
  
  React.useEffect(() => {
    if (_.isEmpty(getQueryParamUrl()["dateType"])) {
      setCheckedValues([defaultQuery || defaultValue]);
    }
  }, [location]);

  React.useEffect(() => {
    setCheckedValues([Number(getQueryParamUrl().dateType) || defaultValue]);
  }, [view]);

  React.useEffect(() => {
    if (onlyLocal) {
      if (getQueryParamFromLocalStorage()["dateType"] == null) {
        setCheckedValues(defaultValue != null ? [defaultValue] : []);
      }
    }
  }, [getQueryParamFromLocalStorage()["dateType"]]);

  const onChange = (values) => {
    if (values && values.length > 0) {
      const newValues = values[values.length - 1];
      const { rangeOptions: { fromName, toName } } = props;

      if (newValues) {
        let dateObject = {
          from: null,
          to: null,
          dateType: null
        };

        const startYesterday = moment().subtract(1, "day").startOf("days");
        const endYesterday = moment().subtract(1, "day").endOf("days");

        const startToday = moment().startOf("days");
        const endToday = moment().endOf("days");

        const endLast30Day = moment().subtract(30, "days").endOf("days");

        const startThisWeek = moment().startOf("week");
        const endThisWeek = moment().endOf("week");

        const startThisMonth = moment().startOf("month");
        const endThisMonth = moment().endOf("month");

        const startOfLastWeek = moment().subtract(1, "weeks").startOf("week");
        const endOfLastWeek = moment().subtract(1, "weeks").endOf("week");

        const startOfLastMonth = moment().subtract(1, "month").startOf("month");
        const endOfLastMonth = moment().subtract(1, "month").endOf("month");

        const startThisYear = moment().startOf("year");
        const endThisYear = moment().endOf("year");

        const startLastYear = moment().subtract(1, "year").startOf("year");
        const endLastYear = moment().subtract(1, "year").endOf("year");

        switch(newValues) {
        case Constants.OPTION_TYPE_30_DAY:
          dateObject = {
            dateType: Constants.OPTION_TYPE_30_DAY,
            from: endLast30Day,
            to: endToday,
          };
          break;
        case Constants.OPTION_TYPE_TO_DAY:
          dateObject = {
            dateType: Constants.OPTION_TYPE_TO_DAY,
            from: startToday,
            to: endToday,
          };
          break;
        case Constants.OPTION_TYPE_THIS_WEEK:
          dateObject = {
            dateType: Constants.OPTION_TYPE_THIS_WEEK,
            from: startThisWeek,
            to: endThisWeek,
          };
          break;
        case Constants.OPTION_TYPE_LAST_WEEK:
          dateObject = {
            dateType: Constants.OPTION_TYPE_LAST_WEEK,
            from: startOfLastWeek,
            to: endOfLastWeek,
          };
          break;
        case Constants.OPTION_TYPE_THIS_MONTH:
          dateObject = {
            dateType: Constants.OPTION_TYPE_THIS_MONTH,
            from: startThisMonth,
            to: endThisMonth,
          };
          break;
        case Constants.OPTION_TYPE_LAST_MONTH:
          dateObject = {
            dateType: Constants.OPTION_TYPE_LAST_MONTH,
            from: startOfLastMonth,
            to: endOfLastMonth,
          };
          break;
        case Constants.OPTION_TYPE_THIS_YEAR:
          dateObject = {
            dateType: Constants.OPTION_TYPE_THIS_YEAR,
            from: startThisYear,
            to: endThisYear,
          };
          break;
        case Constants.OPTION_TYPE_LAST_YEAR:
          dateObject = {
            dateType: Constants.OPTION_TYPE_LAST_YEAR,
            from: startLastYear,
            to: endLastYear,
          };
          break;
        case Constants.OPTION_TYPE_YESTERDAY:
          dateObject = {
            dateType: Constants.OPTION_TYPE_YESTERDAY,
            from: startYesterday,
            to: endYesterday,
          };
          break;
        default:
          dateObject;
        }
        let { from, to, dateType } = dateObject;
        const page = query.page;
        if (page && page > 1) {
          delete query.page;
        }
        if (newValues == Constants.OPTION_TYPE_CUSTOM) {
          query["dateType"] = Constants.OPTION_TYPE_CUSTOM;
        } else if (newValues == Constants.OPTION_TYPE_ALL) {
          query["dateType"] = Constants.OPTION_TYPE_ALL;
          delete query[fromName];
          delete query[toName];
        } 
        else {
          query[fromName] = toQueryParamDate(from);
          query[toName] = toQueryParamDate(to);
          query["dateType"] = dateType;
        }
      } else {
        delete query[fromName];
        delete query[toName];
        delete query.dateType;
      }
      if (queryStorage == "url"){
        updateQueryParamUrl(query);
        setCheckedValues([newValues]);
      } else {
        updateQueryParamToLocalStorage(query);
        setCheckedValues([newValues]);
      }
      if (onHandleChange) {
        onHandleChange(newValues);
      }
    }
  };


  const getValueToDisplay = () => {
    let toDisplay = Constants.DATASOURCE.find((value) => value.id == defaultValue);
    const newCheckedValues = checkedValues && checkedValues.map((value) => Number(value));
    if (newCheckedValues && newCheckedValues.length > 0) {
      toDisplay = Constants.DATASOURCE.find((value) => value.id == checkedValues[0]);
    }
    return toDisplay;
  };

  const toDisplay = getValueToDisplay();

  const menu = () => {
    return (
      <Menu>
        <Checkbox.Group 
          className="px-3 pt-1 w-100" 
          onChange={onChange} 
          value={checkedValues.length > 0 ? checkedValues : [defaultValue]}
        >
          <Row>
            <Col span={24}>
              {Constants.DATASOURCE.map((value, index) => {
                return (
                  <div className="mb-2" key={index}>
                    <Checkbox value={value.id}>
                      {value.name}
                    </Checkbox>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Checkbox.Group>
      </Menu>
    );
  };

  const onVisibleChange = (visible) => {
    setVisible(visible);
  };

  const { rangeOptions, placeholder } = props;
  return (
    <Fragment>
      <Form.Item>
        <Dropdown 
          overlay={menu} 
          trigger={["click"]} 
          visible={visible}
          placement="bottomLeft" 
          onVisibleChange={onVisibleChange} 
        >
          <Button>
            <CalendarOutlined /> {placeholder || "Date"}
            {toDisplay && (
              <small className="mx-1 font-weight-bold">
              : {toDisplay.name} 
              </small>
            )}
          </Button>
        </Dropdown>
      </Form.Item>
      {checkedValues && checkedValues.length > 0 && checkedValues[0] == Constants.OPTION_TYPE_CUSTOM && (
        <AsyncDateRangePicker
          {...rangeOptions}
        />
      )}
    </Fragment>
  );
}

AsyncDateRangeOption.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  rangeOptions: PropTypes.object,
  queryStorage: PropTypes.string,
  location: PropTypes.object,
  onlyLocal: PropTypes.bool,
  onHandleChange: PropTypes.func,
};

AsyncDateRangeOption.defaultProps = {
  rangeOptions: {
    toName: "to",
    fromName: "from",
    typeOfRange: "date",
    showToday: false,
  },
  defaultValue: Constants.OPTION_TYPE_TO_DAY,
};

export default withRouter(AsyncDateRangeOption);