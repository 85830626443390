import { schema } from "normalizr";
import { DepartmentSchema } from "@modules/hr/employee-directory/department/schema";
import { PositionSchema } from "@modules/hr/employee-directory/position/schema";
import { JobTypeSchema } from "@modules/hr/employee-directory/job-type/schema";
import { UserAccountSchema } from "@modules/settings/user-management/user-account/schema";
import { WarehouseSchema } from "@modules/inventory/stock/warehouse/schema";
import { CompanySchema } from "@modules/settings/setup/company/schema";

const EmployeeSchema = new schema.Entity("employees", {
  department: DepartmentSchema,
  position: PositionSchema,
  jobType: JobTypeSchema,
  creator: UserAccountSchema,
  companyBranch: CompanySchema,
  attendanceApproverUser: UserAccountSchema,
  user: UserAccountSchema,
  warehouse: WarehouseSchema,
});
const EmployeeArraySchema = new schema.Array(EmployeeSchema);

export { EmployeeSchema, EmployeeArraySchema };