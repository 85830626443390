export const DEDUCTION_TYPE_LIST_REQUEST = "DEDUCTION_TYPE_LIST_REQUEST"; 
export const DEDUCTION_TYPE_LIST_SUCCESS = "DEDUCTION_TYPE_LIST_REQUEST_SUCCESS";
export const DEDUCTION_TYPE_CREATE_REQUEST = "DEDUCTION_TYPE_CREATE_REQUEST"; 
export const DEDUCTION_TYPE_DETAIL_REQUEST = "DEDUCTION_TYPE_DETAIL_REQUEST"; 
export const DEDUCTION_TYPE_ARCHIVE_REQUEST = "DEDUCTION_TYPE_ARCHIVE_REQUEST"; 
export const DEDUCTION_TYPE_DELETE_REQUEST = "DEDUCTION_TYPE_DELETE_REQUEST"; 
export const DEDUCTION_TYPE_UNARCHIVE_REQUEST = "DEDUCTION_TYPE_UNARCHIVE_REQUEST"; 
export const DEDUCTION_TYPE_EDIT_REQUEST = "DEDUCTION_TYPE_EDIT_REQUEST"; 
export const DEDUCTION_TYPE_CREATE_FORM_NAME = "createDeductionType"; 
export const DEDUCTION_TYPE_EDIT_FORM_NAME = "editDeductionType"; 
export const DEDUCTION_TYPE_AUTOCOMPLETE_REQUEST = "DEDUCTION_TYPE_AUTOCOMPLETE_REQUEST";
export const DEDUCTION_TYPE_AUTOCOMPLETE_SUCCESS = "DEDUCTION_TYPE_AUTOCOMPLETE_SUCCESS";
export const EARNING_TYPE_INPUT_FIELD_NAME = "inputs"; 

export const READ_DEDUCTION_TYPE = 166000;
export const CREATE_DEDUCTION_TYPE = 166001;
export const UPDATE_DEDUCTION_TYPE = 166002;
export const DELETE_DEDUCTION_TYPE = 166003;
export const ARCHIVE_DEDUCTION_TYPE = 166004;
export const UNARCHIVE_DEDUCTION_TYPE = 166005;

export const DEDUCTION_TYPE_COMPUTATION_FIX = 1;
export const DEDUCTION_TYPE_COMPUTATION_PERCENTAGE = 2;
export const DEDUCTION_TYPE_COMPUTATION_TAX = 3;
export const DEDUCTION_TYPE_COMPUTATION_FORMULA = 4;

export const DeductionTypeComputationTypes = [
  {
    name: "Fix Amount",
    id: DEDUCTION_TYPE_COMPUTATION_FIX,
  },
  {
    name: "Percentage",
    id: DEDUCTION_TYPE_COMPUTATION_PERCENTAGE,
  },
  {
    name: "Tax Calculation",
    id: DEDUCTION_TYPE_COMPUTATION_TAX,
  },
  {
    name: "Formula",
    id: DEDUCTION_TYPE_COMPUTATION_FORMULA,
  },
];

export const DEDUCTION_TYPE_EMPLOYEE_VARIABLE = [
  {
    name: "employee",
    type: "object",
    children: [
      {
        name: "salary",
      },
      {
        name: "salaryCurrency",
      },
      {
        name: "minorChildren",
      },
      {
        name: "totalWorkingHours",
      },
      {
        name: "workingHours",
      }
    ]
  }
];

export const DEDUCTION_TYPE_CATEGORIES_VARIABLE = [
  {
    name: "categories",
    type: "object",
    children: [
      {
        name: "Basic",
      },
      {
        name: "Allowance",
      },
      {
        name: "Gross",
      },
      {
        name: "Net",
      }
    ]
  },
];

export const DEDUCTION_TYPE_CURRENCY_EXCHANGE_FUNCTION_VARIABLE = [
  {
    name: "currentExchange(amount: number, from: Currency, to: Currency)",
    type: "function"
  }
];