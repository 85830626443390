import { schema } from "normalizr";
import { BrandSchema } from "../../brand/schema";
import { TagArraySchema } from "../../tag/schema";
import { CategoryArraySchema } from "../../category/schema";
import { PriceListArraySchema } from "../../price-list/schema";
import { SupplierSchema } from "@modules/purchase/list/supplier/schema";

const ItemSchema = new schema.Entity("items", {
  brand : BrandSchema,
  tags : TagArraySchema,
  supplier : SupplierSchema,
  categories: CategoryArraySchema,
  itemPrices : PriceListArraySchema,
});

const ItemArraySchema = new schema.Array(ItemSchema);
export { ItemSchema, ItemArraySchema };