export const LEAVE_POLICY_LIST_REQUEST = "LEAVE_POLICY_LIST_REQUEST";
export const LEAVE_POLICY_LIST_SUCCESS = "LEAVE_POLICY_LIST_REQUEST_SUCCESS";
  
export const LEAVE_POLICY_AUTOCOMPLETE_REQUEST = "LEAVE_POLICY_AUTOCOMPLETE_REQUEST";
export const LEAVE_POLICY_AUTOCOMPLETE_SUCCESS = "LEAVE_POLICY_AUTOCOMPLETE_SUCCESS";

export const LEAVE_POLICY_CREATE_REQUEST= "LEAVE_POLICY_CREATE_REQUEST";
export const LEAVE_POLICY_DETAIL_REQUEST= "LEAVE_POLICY_DETAIL_REQUEST";
export const LEAVE_POLICY_EDIT_REQUEST= "LEAVE_POLICY_EDIT_REQUEST";
export const LEAVE_POLICY_ARCHIVE_REQUEST= "LEAVE_POLICY_ARCHIVE_REQUEST";
export const LEAVE_POLICY_UNARCHIVE_REQUEST= "LEAVE_POLICY_UNARCHIVE_REQUEST";
export const LEAVE_POLICY_DELETE_REQUEST= "LEAVE_POLICY_DELETE_REQUEST";
export const LEAVE_POLICY_ASSIGN_REQUEST= "LEAVE_POLICY_ASSIGN_REQUEST";

export const LEAVE_POLICY_RULES_CREATE_REQUEST= "LEAVE_POLICY_RULES_CREATE_REQUEST";
export const LEAVE_POLICY_RULES_EDIT_REQUEST= "LEAVE_POLICY_RULES_EDIT_REQUEST";
export const LEAVE_POLICY_RULES_DELETE_REQUEST= "LEAVE_POLICY_RULES_DELETE_REQUEST";

export const LEAVE_POLICY_RULE_EFFECT_FROM_JOINING_DATE = 1; 
export const LEAVE_POLICY_RULE_EFFECT_AFTER_JOINING_DATE = 2; 

export const LeavePolicyRuleEffectiveFrom = [ 
  { 
    id: LEAVE_POLICY_RULE_EFFECT_FROM_JOINING_DATE, 
    name: "Employee Joining Date" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_EFFECT_AFTER_JOINING_DATE, 
    name: "After Employee Joining Date" 
  } 
]; 

export const LEAVE_POLICY_RULE_RESET_FREQUENCY_WEEKLY = 1; 
export const LEAVE_POLICY_RULE_RESET_FREQUENCY_MONTHLY = 2; 
export const LEAVE_POLICY_RULE_RESET_FREQUENCY_YEARLY = 3; 
export const LEAVE_POLICY_RULE_RESET_FREQUENCY_ANNIVERSARY = 4; 

export const LeavePolicyRuleResetFrequencies = [ 
  { 
    id: LEAVE_POLICY_RULE_RESET_FREQUENCY_WEEKLY, 
    name: "Weekly" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_RESET_FREQUENCY_MONTHLY, 
    name: "Monthly" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_RESET_FREQUENCY_YEARLY, 
    name: "Yearly" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_RESET_FREQUENCY_ANNIVERSARY, 
    name: "Anniversary Day" 
  } 
]; 

export const DAY_MONDAY = 1; 
export const DAY_TUESDAY = 2; 
export const DAY_WEDNESDAY = 3; 
export const DAY_THURSDAY = 4; 
export const DAY_FRIDAY = 5; 
export const DAY_SATURDAY = 6; 
export const DAY_SUNDAY = 7; 

export const WeeklyDayList = [ 
  { 
    id: DAY_MONDAY, 
    name: "Monday", 
  }, 
  { 
    id: DAY_TUESDAY, 
    name: "Tuesday", 
  }, 
  { 
    id: DAY_WEDNESDAY, 
    name: "Wednesday", 
  }, 
  { 
    id: DAY_THURSDAY, 
    name: "Thursday", 
  }, 
  { 
    id: DAY_FRIDAY, 
    name: "Friday", 
  }, 
  { 
    id: DAY_SATURDAY, 
    name: "Saturday", 
  }, 
  { 
    id: DAY_SUNDAY, 
    name: "Sunday", 
  }, 
]; 

export const LEAVE_POLICY_RULE_PRORATE_DAILY = 1; 
export const LEAVE_POLICY_RULE_PRORATE_WEEKLY = 2; 
export const LEAVE_POLICY_RULE_PRORATE_MONTHLY = 3; 
export const LEAVE_POLICY_RULE_PRORATE_CUSTOM = 4; 

export const LeavePolicyRuleProrateTypes = [ 
  { 
    id: LEAVE_POLICY_RULE_PRORATE_DAILY, 
    name: "Day", 
    formula: "(leaveBalance / totalDays) * employeeTotalDays" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_PRORATE_WEEKLY, 
    name: "Week", 
    formula: "(leaveBalance / totalWeeks) * employeeTotalWeeks" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_PRORATE_MONTHLY, 
    name: "Month", 
    formula: "(leaveBalance / totalMonths) * employeeTotalMonths" 
  }, 
  { 
    id: LEAVE_POLICY_RULE_PRORATE_CUSTOM, 
    name: "Custom", 
  },
];

export const LEAVE_POLICY_SUBMIT_RULES = {
  name: {
    required: true,
  },
  leaveTypeId: {
    required: true,
  },
  status: {
    required: true,
  }
};

export const READ_LEAVE_POLICY = 154000;
export const CREATE_LEAVE_POLICY = 154001;
export const UPDATE_LEAVE_POLICY = 154002;
export const DELETE_LEAVE_POLICY = 154003;
export const ARCHIVE_LEAVE_POLICY = 154004;
export const UNARCHIVE_LEAVE_POLICY = 154005;

export const READ_LEAVE_POLICY_RULE = 155000;
export const CREATE_LEAVE_POLICY_RULE = 155001;
export const UPDATE_LEAVE_POLICY_RULE = 155002;
export const DELETE_LEAVE_POLICY_RULE = 155003;

export const LEAVE_POLICY_CREATE_FORM_NAME = "createLeavePolicy";
export const LEAVE_POLICY_EDIT_FORM_NAME = "editLeavePolicy";