import React from "react";
import _ from "lodash";
import { Breadcrumb } from "antd";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

function Breadcrumbs(props) {

  const convert = (input) => {
    return input.map((data) => data.split(/-| /).map(capitalize).join(" "));
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  let { breadcrumbs, match: { url } } = props;
  let paths = url.split("/");
  let redirectPaths = url.split("/");
  paths = _.remove(paths, function(n) {
    return n != "";
  });
  paths = convert(paths);
  if (paths.length > 2) {
    breadcrumbs = [
      paths[0],
      paths[1],
      ...breadcrumbs
    ];
  }
  const lastBreadcrumb = breadcrumbs.splice(breadcrumbs.length - 1, 1);
  return(
    <Breadcrumb>
      {breadcrumbs && breadcrumbs.map((breadcrumb, index) => {
        const fullPaths = [
          ...redirectPaths
        ];
        const spliceIndex = redirectPaths.length - breadcrumbs.length + index;
        const spliceLength = breadcrumbs.length - index;
        fullPaths.splice(spliceIndex, spliceLength);
        return (
          <>
            <Breadcrumb.Item key={index}>
              <Link to={fullPaths.join("/")}>
                {breadcrumb}
              </Link>
            </Breadcrumb.Item>
          </>
        );
      })
      }
      <Breadcrumb.Item>
        {lastBreadcrumb}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

Breadcrumbs.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  breadcrumbs: PropTypes.array,
};

export default withRouter(Breadcrumbs);