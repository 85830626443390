import * as CommentConstant from "@/constants/comment";

export default function (state = {}, action) {
  switch (action.type) {
  case CommentConstant.COMMENT_SUCCESS:
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...action.data
        ]
      }
    };
  case CommentConstant.COMMENT_SUCCESS_NEXT_PAGE: {
    const oldComment = state[action.entity][action.entityId] || [];
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...oldComment, 
          ...action.data
        ]
      }
    };
  }
  case CommentConstant.COMMENT_CREATE_SUCCESS: {
    const oldComment = [
      ...state[action.entity][action.entityId]
    ];
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          action.id,
          ...oldComment,
        ]
      }
    };
  }
  case CommentConstant.COMMENT_DELETE_SUCCESS: {
    const data = [
      ...state[action.entity][action.entityId]
    ];
    const deleteIndex = data.indexOf(action.id);
    data.splice(deleteIndex, 1);
    return {
      ...state,
      [action.entity]: {
        [action.entityId]: [
          ...data,
        ]
      }
    };
  }
  default:
    return state;
  }
}