// Utils
import { 
  CustomerSchema,
  CustomerArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as CustomerConstants from "../constants";

const path = "/sale/customer-center/customer";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomer,
    schema: CustomerArraySchema,
    requestName: CustomerConstants.CUSTOMER_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getCustomerAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getCustomerAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getCustomerAppAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getCustomerAppAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};


export const createCustomer = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createCustomer,
    redirectUrl: (json) => `${path}/${json.customerNo}` ,
    requestName: CustomerConstants.CUSTOMER_CREATE_REQUEST,
    dispatch,
    getState,
  };
  return createNewEntityAction(options, values);
};

export const getCustomerDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomerDetail,
    requestName: CustomerConstants.CUSTOMER_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: CustomerSchema,
  };
  return createDetailAction(options, id);
};

export const editCustomer = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editCustomer,
    requestName: CustomerConstants.CUSTOMER_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: (json) => `${path}/${json.customerNo}` ,
    entityName: "customers",
    schema: CustomerSchema,
    successMessage: "Customer is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveCustomer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveCustomer,
    requestName: CustomerConstants.CUSTOMER_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Customer is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteCustomer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteCustomer,
    requestName: CustomerConstants.CUSTOMER_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Customer is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveCustomer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveCustomer,
    requestName: CustomerConstants.CUSTOMER_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const suspendCustomer = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.suspendCustomer,
    schema: CustomerSchema,
    requestName: CustomerConstants.SUSPEND_CUSTOMER_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer is suspended successfully!",
  };
  return createEntityActionAction(options, values);
};

export const activeCustomer = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.activeCustomer,
    schema: CustomerSchema,
    requestName: CustomerConstants.ACTIVE_CUSTOMER_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer is activated successfully!",
  };
  return createEntityActionAction(options, values);
};

export const createUserForCustomer = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createUserForCustomer,
    schema: CustomerSchema,
    requestName: CustomerConstants.CREATE_USER_FOR_CUSTOMER_REQUEST,
    dispatch,
    getState,
    successMessage: "User is created successfully!",
  };
  return createEntityActionAction(options, values);
};