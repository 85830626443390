export const ITEM_MOVEMENT_REFERENCE_TYPE_PURCHASE_ORDER = "PurchaseOrder";
export const ITEM_MOVEMENT_REFERENCE_TYPE_STOCK_TRANSFER = "StockTransfer";

export const ITEM_MOVEMENT_REFERENCE_TYPE_STOCK_OUT = "StockOut";
export const ITEM_MOVEMENT_REFERENCE_TYPE_SALE_ORDER = "SaleOrder";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";

export const WIDGET_FILTER_REQUEST = "WIDGET_FILTER_REQUEST";
export const WIDGET_FILTER_SUCCESS = "WIDGET_FILTER_SUCCESS";

export const GET_MODULES_REQUEST = "GET_MODULES_REQUEST";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_WIDGET_REQUEST = "GET_WIDGET_REQUEST";

export const UPDATE_DASHBOARD_WIDGET_OPTIONS = "UPDATE_DASHBOARD_WIDGET_OPTIONS";
export const ADD_DASHBOARD_WIDGET_REQUEST = "ADD_DASHBOARD_WIDGET_REQUEST";
export const DELETE_DASHBOARD_WIDGET_REQUEST = "DELETE_DASHBOARD_WIDGET_REQUEST";

export const DASHBOARD_WIDGET_TYPE_PIE = 1;
export const DASHBOARD_WIDGET_TYPE_BAR = 2;
export const DASHBOARD_WIDGET_TYPE_LINE = 3;
export const DASHBOARD_WIDGET_TYPE_TEXT = 4;
export const DASHBOARD_WIDGET_TYPE_TABLE = 5;
export const DASHBOARD_WIDGET_TYPE_CALENDAR = 6;
export const DASHBOARD_WIDGET_TYPE_NUMBER = 7;
export const DASHBOARD_WIDGET_TYPE_TARGET = 8;