export const SHIPPER_PAYMENT_LIST_REQUEST = "SHIPPER_PAYMENT_LIST_REQUEST"; 
export const SHIPPER_PAYMENT_LIST_SUCCESS = "SHIPPER_PAYMENT_LIST_REQUEST_SUCCESS";

export const SHIPPER_PAYMENT_CREATE_REQUEST = "SHIPPER_PAYMENT_CREATE_REQUEST"; 
export const SHIPPER_PAYMENT_DETAIL_REQUEST = "SHIPPER_PAYMENT_DETAIL_REQUEST"; 
export const SHIPPER_PAYMENT_ARCHIVE_REQUEST = "SHIPPER_PAYMENT_ARCHIVE_REQUEST"; 
export const SHIPPER_PAYMENT_DELETE_REQUEST = "SHIPPER_PAYMENT_DELETE_REQUEST"; 

export const SHIPPER_PAYMENT_UNARCHIVE_REQUEST = "SHIPPER_PAYMENT_UNARCHIVE_REQUEST"; 
export const SHIPPER_PAYMENT_EDIT_REQUEST = "SHIPPER_PAYMENT_EDIT_REQUEST"; 
export const SHIPPER_PAYMENT_PAY_BATCH_REQUEST = "SHIPPER_PAYMENT_PAY_BATCH_REQUEST"; 

export const SHIPPER_PAYMENT_CREATE_FORM_NAME = "createShipperPayment"; 
export const SHIPPER_PAYMENT_EDIT_FORM_NAME = "editShipperPayment";
export const SHIPPER_PAYMENT_PAY_BATCH_FORM_NAME = "payBatchShipperPayment";

export const SHIPPER_PAYMENT_AUTOCOMPLETE_REQUEST = "SHIPPER_PAYMENT_AUTOCOMPLETE_REQUEST";
export const SHIPPER_PAYMENT_AUTOCOMPLETE_SUCCESS = "SHIPPER_PAYMENT_AUTOCOMPLETE_SUCCESS";

export const SHIPPER_PAYMENT_PAY_BATCH_LIST_REQUEST = "SHIPPER_PAYMENT_PAY_BATCH_LIST_REQUEST";
export const SHIPPER_PAYMENT_PAY_BATCH_LIST_SUCCESS = "SHIPPER_PAYMENT_PAY_BATCH_LIST_REQUEST_SUCCESS";

export const SHIPPER_PAYMENT_SUBMIT_RULES = {
  name: {
    required: true,
  },
};

export const SHIPPER_PAYMENT_PAY_REQUEST = "SHIPPER_PAYMENT_PAY_REQUEST";
export const SHIPPER_PAYMENT_PAY_FORM_NAME = "payShipperForm";

export const READ_SHIPPER_PAYMENT = 259000;
export const CREATE_SHIPPER_PAYMENT = 259001;
export const UPDATE_SHIPPER_PAYMENT = 259002;
export const DELETE_SHIPPER_PAYMENT = 259003;
export const ARCHIVE_SHIPPER_PAYMENT = 259004;
export const UNARCHIVE_SHIPPER_PAYMENT = 259005;
export const PAY_SHIPPER_PAYMENT = 259011;

export const SHIPPER_PAYMENT_DRAFT = 1;
export const SHIPPER_PAYMENT_DRAFT_TITLE = "Draft";
export const SHIPPER_PAYMENT_PARTIAL = 2;
export const SHIPPER_PAYMENT_PARTIAL_TITLE = "Partial Paid";
export const SHIPPER_PAYMENT_PAID = 3;
export const SHIPPER_PAYMENT_PAID_TITLE = "Paid";
export const SHIPPER_PAYMENT_CANCELLED = 4;
export const SHIPPER_PAYMENT_CANCELLED_TITLE = "Cancelled";

export const SHIPPER_PAYMENT_STATUS_DATA_SOURCE = [
  {
    id: SHIPPER_PAYMENT_DRAFT,
    name: SHIPPER_PAYMENT_DRAFT_TITLE,
  },
  {
    id: SHIPPER_PAYMENT_PARTIAL,
    name: SHIPPER_PAYMENT_PARTIAL_TITLE,
  },
  {
    id: SHIPPER_PAYMENT_PAID,
    name: SHIPPER_PAYMENT_PAID_TITLE, 
  },
  {
    id: SHIPPER_PAYMENT_CANCELLED,
    name: SHIPPER_PAYMENT_CANCELLED_TITLE, 
  }
];

export const PAY_BATCH_SUBMIT_RULES = {
  shipperId: {
    required: true,
  },
  purchaseShipperPaymentIds: {
    required: true,
    type: "array",
    message: "Please select at least 1 shipper payment"
  },
};