import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";

// Helper
import { 
  getQueryParamUrl, 
  updateQueryParamUrl, 
  getQueryParamFromLocalStorage, 
  updateQueryParamToLocalStorage, 
} from "@modules/helpers";

function AsyncClearFilter(props) {

  const renderValue = (values) => {
    let newValues = {};
    for (const property in values) {
      if (values[property] && values[property].type == "Array") {
        newValues[property] = values[property].value;
      }
      else if (values[property] && values[property].type == "Boolean") {
        newValues[property] = values[property].value;
      } else {
        newValues[property] = values[property].value;
      }     
    }                      
    return newValues;
  };

  const onClear = () => {
    let query = getQueryParamUrl();
    const { filterKeys, onReset, queryStorage, history, matched } = props;
    if (query.view != null && matched && matched.filters) {
      const newQuery = renderValue(matched.filters);
      newQuery.view = query.view;
      updateQueryParamUrl(newQuery);
      if (queryStorage != "url") {
        updateQueryParamToLocalStorage(newQuery);
      }
    } else {
      const currentActiveFilter = localStorage.getItem("FILTER_ACTIVE");
      const filterValues = localStorage.getItem(currentActiveFilter);
      const localStorageValueToJson = JSON.parse(filterValues);
      filterKeys && filterKeys.map(key => {
        delete query[key];
        if (queryStorage != "url") {
          delete localStorageValueToJson[key];
        }
      });
      updateQueryParamUrl(query, history);
      if (queryStorage != "url") {
        updateQueryParamToLocalStorage(localStorageValueToJson);
      }
      if (onReset) {
        onReset();
      }
    }
  };

  const { filterKeys, loading, queryStorage, onNewClearClick } = props;
  const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
  let clearable = false;
  filterKeys && filterKeys.map((key) => {
    const value = query[key];
    if (value) {
      clearable = true;
    }
  });
  const type = classnames(clearable ? "danger" : "default");
  return (
    <Button 
      ghost
      type={type}
      onClick={onNewClearClick ? onNewClearClick : onClear}
      icon={<CloseCircleOutlined />}
      disabled={loading || !clearable}
    >
      {loading ? "Loading..." : "Clear Filter"}
    </Button>
  );
}

AsyncClearFilter.propTypes = {
  loading: PropTypes.bool,
  onReset: PropTypes.func,
  history: PropTypes.object,
  matched: PropTypes.object,
  filterKeys: PropTypes.array,
  queryStorage: PropTypes.string,
  onNewClearClick: PropTypes.func,
};

AsyncClearFilter.defaultProps = {
  queryStorage: "local",
};


export default withRouter(AsyncClearFilter);