// Libs
import { 
  StockTransferSchema,
  StockTransferArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

const path = "/inventory/stock/stock-transfer";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getStockTransfer,
    schema: StockTransferArraySchema,
    requestName: Constants.STOCK_TRANSFER_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createStockTransfer = (values) => (dispatch, getState) => {
  const redirectUrl = (json) => {
    return `${path}/${json.stockTransferNo}`;
  };
  const options = {
    serviceMethod: service.createStockTransfer,
    requestName: Constants.STOCK_TRANSFER_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
    successMessage: "Stock transfer is created successfully"
  };
  return createNewEntityAction(options, values);
};

export const getStockTransferDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getStockTransferDetail,
    requestName: Constants.STOCK_TRANSFER_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: StockTransferSchema,
  };
  return createDetailAction(options, id);
};

export const editStockTransfer = (values) => (dispatch, getState) => {
  const redirectUrl = (json) => {
    return `${path}/${json.stockTransferNo}`;
  };
  const options = {
    serviceMethod: service.editStockTransfer,
    requestName: Constants.STOCK_TRANSFER_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
    entityName: "stockTransfers",
    schema: StockTransferSchema,
    successMessage: "Stock transfer is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveStockTransfer,
    requestName: Constants.STOCK_TRANSFER_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Stock transfer is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteStockTransfer,
    requestName: Constants.STOCK_TRANSFER_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Stock transfer is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveStockTransfer,
    requestName: Constants.STOCK_TRANSFER_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const postStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.postStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_POST_REQUEST,
    dispatch,
    getState,
    successMessage: "Purchase order is posted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const submitStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approveStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const cancelStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.cancelStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is canceled successfully!",
  };
  return createEntityActionAction(options, id);
};

export const closeStockTransfer = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.closeStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_CLOSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is closed successfully!",
  };
  return createEntityActionAction(options, id);
};

export const receiveStockTransfer = (id) => (dispatch, getState) => {
  
  const options = {
    serviceMethod: service.receiveStockTransfer,
    schema: StockTransferSchema,
    requestName: Constants.STOCK_TRANSFER_PROCESS_REQUEST,
    dispatch,
    getState,
    successMessage: "Stock transfer is received successfully!",
  };
  return createEntityActionAction(options, id);
};