import React from "react";
import AutoComplete from "../../autocomplete-static";

class ProvinceAutoComplete extends React.Component {

  render() {
    let dataSource = [
      "Phnom Penh", 
      "Banteay Meanchey", 
      "Battambang", 
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Speu",
      "Kampong Thom",
      "Kampot",
      "Kandal",
      "Koh Kong",
      "Kep",
      "Kratié",
      "Mondulkiri",
      "Oddar Meanchey",
      "Pailin",
      "Sihanoukville",
      "Preah Vihear",
      "Pursat",
      "Prey Veng",
      "Ratanakiri",
      "Siem Reap",
      "Stung Treng",
      "Svay Rieng",
      "Takéo",
      "Tbong Khmum",
    ];
    const newDataSource = [];
    dataSource.map((value) => {
      newDataSource.push(
        {
          id: value,
          name: value
        }
      );
    });
    return (
      <AutoComplete 
        stringValue
        required={false}
        name="province"
        label="Province"
        dataSource={newDataSource}
      />
    );
  }
}

export default ProvinceAutoComplete;
