import {
  GETList,
  GETDetail,
  PUTWithToken,
  GETWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/customer/v1";

export const getCustomer = values => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createCustomer(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getCustomerAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getCustomerAppAutoComplete(query, extra) {
  const url = `${API_URL}/app-autoComplete?type=4`;
  return GETAutoComplete(url, query, extra);
}

export function getCustomerDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editCustomer(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveCustomer(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveCustomer(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteCustomer(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function getCustomerTotalDeposit(values) {
  const url = `${API_URL}/total-deposit`;
  return GETWithToken(url, values);
}

export function suspendCustomer(id) {
  const url = `${API_URL}/${id}/suspend`;
  return PUTWithToken(url);
}

export function activeCustomer(id) {
  const url = `${API_URL}/${id}/activate`;
  return PUTWithToken(url);
}

export function createUserForCustomer(id) {
  const url = `${API_URL}/${id}/create-user`;
  return POSTWithToken(url);
}