// Utils
import { normalize } from "normalizr";
import { 
  ExpenseSchema,
  ExpenseArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as ExpenseConstants from "../constants";
import { mergeEntities, syncArrayEntities, mergeEntity } from "@/actions/entity";

const path = "/accounting/list/expense";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getExpense,
    schema: ExpenseArraySchema,
    requestName: ExpenseConstants.EXPENSE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createExpense = (values) => (dispatch, getState) => {

  const redirectUrl = (json) => {
    return `${path}/${json.expenseNo}`;
  };

  const options = {
    serviceMethod: service.createExpense,
    requestName: ExpenseConstants.EXPENSE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl,
    successMessage: "Expense is created successfully",
  };
  return createNewEntityAction(options, values);
};

export const getExpenseAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getExpenseAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getExpenseDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getExpenseDetail,
    requestName: ExpenseConstants.EXPENSE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: ExpenseSchema,
  };
  return createDetailAction(options, id);
};

export const editExpense = (values) => (dispatch, getState) => {

  const redirectUrl = (json) => {
    return `${path}/${json.expenseNo}`;
  };

  const onSuccess = (json) => {
    const normalized = normalize(json, ExpenseSchema);
    dispatch(mergeEntities(normalized.entities));
    dispatch(syncArrayEntities(normalized.entities["expenses"], "expenses"));
  };

  const options = {
    serviceMethod: service.editExpense,
    requestName: ExpenseConstants.EXPENSE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl,
    schema: ExpenseSchema,
    onSuccess,
    successMessage: "Expense is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveExpense,
    requestName: ExpenseConstants.EXPENSE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Expense is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteExpense,
    requestName: ExpenseConstants.EXPENSE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Expense is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveExpense,
    requestName: ExpenseConstants.EXPENSE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const submitExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitExpense,
    schema: ExpenseSchema,
    requestName: ExpenseConstants.SUBMIT_EXPENSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approveExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveExpense,
    schema: ExpenseSchema,
    requestName: ExpenseConstants.APPROVE_EXPENSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectExpense,
    schema: ExpenseSchema,
    requestName: ExpenseConstants.REJECT_EXPENSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const postExpense = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.postExpense,
    schema: ExpenseSchema,
    requestName: ExpenseConstants.POST_EXPENSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is posted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const processExpense = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.processExpense,
    schema: ExpenseSchema,
    requestName: ExpenseConstants.POST_EXPENSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is paid successfully!",
  };
  return createEntityActionAction(options, values);
};

export const draftExpense = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.draftExpense,
    schema: ExpenseSchema,
    requestName: ExpenseConstants.DRAFT_EXPENSE_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense is drafted successfully!",
  };
  return createEntityActionAction(options, values);
};

export const payBatchExpense = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.payBatchExpense,
    requestName: ExpenseConstants.EXPENSE_PAY_BATCH_REQUEST,
    dispatch,
    getState,
    successMessage: "Expense batch is paid successfully!",
  };
  return createEntityActionAction(options, values);
};

export const getPayBatchExpense = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getExpense,
    schema: ExpenseArraySchema,
    requestName: ExpenseConstants.EXPENSE_PAY_BATCH_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const approveExpenses = (values) => (dispatch, getState) => {

  const { ids, expenseNos } = values;
  
  const state = getState();
  let message = "Expense";
  if (expenseNos && expenseNos.length > 1) {
    message = "Expenses";
  }

  const successAction = () => {
    let expensesEntities = state.entities.expenses;
    if (expenseNos && expenseNos.length > 0) {
      expenseNos.map((value) => {
        if (expensesEntities[value]) {
          const matchedEntity = expensesEntities[value];
          matchedEntity.status = ExpenseConstants.EXPENSE_STATUS_APPROVED;
          dispatch(mergeEntity("expenses", value, matchedEntity));
        }
      });
    }
  };

  const options = {
    serviceMethod: service.approveExpenses,
    requestName: ExpenseConstants.APPROVE_EXPENSE_REQUEST,
    dispatch,
    getState,
    successAction,
    successMessage: `${message} is approved successfully!`,
  };
  return createEntityActionAction(options, ids);
};

export const postExpenses = (values) => (dispatch, getState) => {

  const { ids, expenseNos } = values;
  
  const state = getState();
  let message = "Expense";
  if (expenseNos && expenseNos.length > 1) {
    message = "Expenses";
  }

  const successAction = () => {
    let expensesEntities = state.entities.expenses;
    if (expenseNos && expenseNos.length > 0) {
      expenseNos.map((value) => {
        if (expensesEntities[value]) {
          const matchedEntity = expensesEntities[value];
          matchedEntity.status = ExpenseConstants.EXPENSE_STATUS_POSTED;
          dispatch(mergeEntity("expenses", value, matchedEntity));
        }
      });
    }
  };

  const options = {
    serviceMethod: service.postBatchExpense,
    requestName: ExpenseConstants.POST_EXPENSE_REQUEST,
    dispatch,
    getState,
    successAction,
    successMessage: `${message} is posted successfully!`,
  };
  return createEntityActionAction(options, ids);
};