import { schema } from "normalizr";
import { WarehouseSchema } from "../../warehouse/schema";
import { ItemSchema } from "@modules/inventory/item/item/schema";

const JunkOutItemSchema = new schema.Entity("junkOutItems", {
  item: ItemSchema
});
const JunkOutItemArraySchema = new schema.Array(JunkOutItemSchema);

const JunkOutSchema = new schema.Entity("junkOuts",
  {
    warehouse: WarehouseSchema,
    junkOutItems: JunkOutItemArraySchema,
  }, {
    idAttribute: "junkOutNo"
  }
);

const JunkOutArraySchema = new schema.Array(JunkOutSchema);

export { JunkOutSchema, JunkOutArraySchema };