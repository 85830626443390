// Lib
import React, { Component } from "react";

// Components
import RegisterForm from "./form";
import AuthenticationLayout from "@/components/layout/authentication-layout";

class Register extends Component {
  
  render() {
    return (
      <AuthenticationLayout>
        <RegisterForm form="registerForm" />
      </AuthenticationLayout>
    );
  }
}

export default Register;