import React from "react";
import { Badge } from "antd";

import * as Constants from "../constants";

export const renderCustomerType = (value) => {
  let status = null;
  switch (value) {
  case Constants.CUSTOMER_TYPE_INDIVIDUAL_ID:
    status = Constants.CUSTOMER_TYPE_INDIVIDUAL_NAME;
    break;
  case Constants.CUSTOMER_TYPE_COMPANY_ID:
    status = Constants.CUSTOMER_TYPE_COMPANY_NAME;
    break;
  case Constants.CUSTOMER_TYPE_GENERAL_ID:
    status = Constants.CUSTOMER_TYPE_GENERAL_NAME;
    break;
  case Constants.CUSTOMER_TYPE_APP_ID:
    status = Constants.CUSTOMER_TYPE_APP_NAME;
    break;
  default:
    status;
  }
  return status;
};

export const renderCustomerStatus = (id) => {
  let status = null;
  switch(id) {
  case Constants.CUSTOMER_STATUS_ACTIVE:
    status = <Badge status="success" text={Constants.CUSTOMER_STATUS_ACTIVE_TITLE} />;
    break;
  case Constants.CUSTOMER_STATUS_INACTIVE:
    status = <Badge status="error" text={Constants.CUSTOMER_STATUS_INACTIVE_TITLE} />;
    break;
  case Constants.CUSTOMER_STATUS_SUSPEND:
    status = <Badge status="error" text={Constants.CUSTOMER_STATUS_SUSPEND_TITLE} />;
    break;
  default:
    status;
  }
  return status;
};

export const onCustomerPriceListValidation = (customerPriceLists) => {
  let customerPriceListRequired = false;
  if (customerPriceLists && customerPriceLists.length > 0) {
    const isMatched = customerPriceLists.find((value) => value.isDefault == true);
    customerPriceListRequired = isMatched == undefined;
  }
  if (customerPriceListRequired) {
    return {
      message: "Please at least select one default price list"
    }; 
  }
};