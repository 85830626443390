import * as Constants from "../constants";

export default function (state = {}, action) {
  switch (action.type) {
  case Constants.ITEM_IN_STOCK_LIST_SUCCESS:
    return {
      ...state,
      [action.id]: action.payload
    };
  default:
    return state;
  }
}