export const SERVICE_LIST_REQUEST = "SERVICE_LIST_REQUEST"; 
export const SERVICE_LIST_SUCCESS = "SERVICE_LIST_REQUEST_SUCCESS";
export const SERVICE_CREATE_REQUEST = "SERVICE_CREATE_REQUEST"; 
export const SERVICE_DETAIL_REQUEST = "SERVICE_DETAIL_REQUEST"; 
export const SERVICE_ARCHIVE_REQUEST = "SERVICE_ARCHIVE_REQUEST"; 
export const SERVICE_DELETE_REQUEST = "SERVICE_DELETE_REQUEST"; 
export const SERVICE_UNARCHIVE_REQUEST = "SERVICE_UNARCHIVE_REQUEST"; 
export const SERVICE_EDIT_REQUEST = "SERVICE_EDIT_REQUEST"; 

export const SERVICE_HISTORY_LIST_REQUEST = "SERVICE_HISTORY_LIST_REQUEST"; 
export const SERVICE_HISTORY_LIST_SUCCESS = "SERVICE_HISTORY_LIST_REQUEST_SUCCESS";

export const SERVICE_CREATE_FORM_NAME = "createService"; 
export const SERVICE_EDIT_FORM_NAME = "editService"; 
export const SERVICE_ACCESSORIES_FIELD_NAME = "serviceAccessories";
export const SERVICE_ASSIGN_TECHNICIAN_FORM_NAME = "assignTechnician";
export const SERVICE_START_FORM_NAME = "startService";
export const SERVICE_COMPLETE_FORM_NAME = "completeService";
export const SERVICE_DELAY_FORM_NAME = "delayService";
export const SERVICE_CANCEL_FORM_NAME = "cancel";
export const SERVICE_ADD_LOG_FORM_NAME = "addLog";
export const SERVICE_TECHNICIAN_ARRAY_FIELD_NAME = "serviceTechnicians";
export const SERVICE_TYPE_BACK_TO_DRAFT_FORM_NAME = "backToDraft"; 

export const SERVICE_AUTOCOMPLETE_REQUEST = "SERVICE_AUTOCOMPLETE_REQUEST";
export const SERVICE_AUTOCOMPLETE_SUCCESS = "SERVICE_AUTOCOMPLETE_SUCCESS";

export const SERVICE_ASSIGN_TECHNICIAN_REQUEST = "SERVICE_ASSIGN_TECHNICIAN_REQUEST";
export const SERVICE_START_REQUEST = "SERVICE_START_REQUEST";
export const SERVICE_COMPLETE_REQUEST = "SERVICE_COMPLETE_REQUEST";
export const SERVICE_TECHNICIAN_START_REQUEST = "SERVICE_TECHNICIAN_START_REQUEST";
export const SERVICE_TECHNICIAN_COMPLETE_REQUEST = "SERVICE_TECHNICIAN_COMPLETE_REQUEST";
export const SERVICE_DELAY_REQUEST = "SERVICE_DELAY_REQUEST";
export const SERVICE_PAY_REQUEST = "SERVICE_PAY_REQUEST";
export const SERVICE_LOG_CREATE_REQUEST = "SERVICE_LOG_CREATE_REQUEST";
export const SERVICE_BACK_TO_DRAFT_REQUEST = "SERVICE_BACK_TO_DRAFT_REQUEST";

export const SERVICE_SUBMIT_RULES = { 
  employeeId: {
    required: true
  },
  customerId: {
    required: true
  },
  serialNumber: {
    required: true
  },
  itemName: {
    required: true
  }
};

export const SERVICE_ASSIGN_SUBMIT_RULES = {
  serviceTechnicians: {
    required: true,
  }
};

export const BACK_TO_DRAFT_SUBMIT_RULES = {
  notation: {
    required: true,
  }
};

export const SERVICE_ASSIGN_TECHNICIAN_SUBMIT_RULES = {
  serviceTechnicians: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "userId"
      },
    ]
  }
};

export const SERVICE_DELAY_SUBMIT_RULES = {
  expectedDate: {
    required: true,
  }
};

export const SERVICE_CREATE_LOG_SUBMIT_RULES = {
  notation: {
    required: true,
  }
};

export const READ_SERVICE = 238000;
export const CREATE_SERVICE = 238001;
export const UPDATE_SERVICE = 238002;
export const DELETE_SERVICE = 238003;
export const ARCHIVE_SERVICE = 238004;
export const UNARCHIVE_SERVICE = 238005;
export const ASSIGN_SERVICE = 238011;
export const START_SERVICE = 238012;
export const COMPLETE_SERVICE = 238013;
export const DELAY_SERVICE = 238014;
export const PRINT_SERVICE = 238007;
export const PAY_SERVICE = 238015;
export const CREATE_LOG_SERVICE = 238016;
export const DRAFT_SERVICE = 238017;
export const VIEW_ALL_SERVICE = 238019;
export const VIEW_SALE_TEAM_SERVICE = 238020;

export const SERVICE_LOG_TYPE_STARTED = 1;
export const SERVICE_LOG_TYPE_STARTED_TITLE = "Started";
export const SERVICE_LOG_TYPE_COMPLETED = 2;
export const SERVICE_LOG_TYPE_COMPLETED_TITLE = "Completed";
export const SERVICE_LOG_TYPE_DELAYED = 3;
export const SERVICE_LOG_TYPE_DELAYED_TITLE = "Delayed";

export const SERVICE_STATUS_DRAFT = 1;
export const SERVICE_STATUS_DRAFT_TITLE = "Draft";
export const SERVICE_STATUS_ASSIGNED = 2;
export const SERVICE_STATUS_ASSIGNED_TITLE = "Assigned";
export const SERVICE_STATUS_STARTED = 3;
export const SERVICE_STATUS_STARTED_TITLE = "Started";
export const SERVICE_STATUS_COMPLETED = 4;
export const SERVICE_STATUS_COMPLETED_TITLE = "Completed";
export const SERVICE_STATUS_PAID = 5;
export const SERVICE_STATUS_PAID_TITLE = "Paid";

export const SERVICE_TYPE_START = 1;
export const SERVICE_TYPE_COMPLETE = 2;
export const SERVICE_TYPE_TECHNICIAN_START = 3;
export const SERVICE_TYPE_TECHNICIAN_COMPLETE = 4;
export const SERVICE_TYPE_DELAY = 5;
export const SERVICE_TYPE_PAY = 6;
export const SERVICE_TYPE_EXCHANGE = 7;
export const SERVICE_TYPE_CREATE_LOG = 8;
export const SERVICE_TYPE_BACK_TO_DRAFT = 9;

export const SERVICE_STATUS_DATA_SOURCE = [
  {
    id: SERVICE_STATUS_DRAFT,
    name: SERVICE_STATUS_DRAFT_TITLE,
  },
  {
    id: SERVICE_STATUS_ASSIGNED,
    name: SERVICE_STATUS_ASSIGNED_TITLE,
  },
  {
    id: SERVICE_STATUS_STARTED,
    name: SERVICE_STATUS_STARTED_TITLE,
  },
  {
    id: SERVICE_STATUS_COMPLETED,
    name: SERVICE_STATUS_COMPLETED_TITLE,
  },
  {
    id: SERVICE_STATUS_PAID,
    name: SERVICE_STATUS_PAID_TITLE,
  },
];