// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import * as Icon from "@ant-design/icons";

const { Item, SubMenu } = Menu;

class SideBarMenu extends Component {
  renderSubMenu = (subMenu, menu) => {
    const { slug } = this.props;
    if (subMenu.sub != null) {
      return (
        <SubMenu key={subMenu.slug} title={subMenu.title}>
          {subMenu &&
            subMenu.sub.map((subSubMenu) => (
              <Item key={subSubMenu.slug}>
                <Link
                  to={`/${slug}/${menu.slug}/${subMenu.slug}/${subSubMenu.slug}`}
                >
                  {subSubMenu.title}
                </Link>
              </Item>
            ))}
        </SubMenu>
      );
    } else {
      return (
        <Item key={subMenu.slug}>
          <Link to={`/${slug}/${menu.slug}/${subMenu.slug}`}>
            {subMenu.title}
          </Link>
        </Item>
      );
    }
  };

  render() {
    const {
      slug,
      menus,
      theme,
      openKeys,
      onOpenChange,
      selectedKeys,
      defaultOpenKeys,
    } = this.props;
    return (
      <Menu
        theme={theme}
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={defaultOpenKeys}
      >
        {menus &&
          menus.map((menu) => {
            const NewIcon = Icon[menu.icon];
            if (menu.sub == null) {
              return (
                <Item key={menu.slug}>
                  <Link to={`/${slug}/${menu.slug}`}>
                    <NewIcon />
                    <span>{menu.title}</span>
                  </Link>
                </Item>
              );
            } else {
              const NewIcon = Icon[menu.icon];
              return (
                <SubMenu
                  key={`${slug}/${menu.slug}`}
                  title={
                    <span>
                      {NewIcon && <NewIcon style={{ fontSize: 16 }} />}
                      <span>{menu.title}</span>
                    </span>
                  }
                >
                  {menu &&
                    menu.sub &&
                    menu.sub.map((subMenu) => {
                      return this.renderSubMenu(subMenu, menu);
                    })}
                </SubMenu>
              );
            }
          })}
      </Menu>
    );
  }
}

SideBarMenu.propTypes = {
  slug: PropTypes.string,
  theme: PropTypes.string,
  menus: PropTypes.array,
  openKeys: PropTypes.any,
  collapsed: PropTypes.bool,
  selectedKeys: PropTypes.any,
  onOpenChange: PropTypes.func,
  defaultOpenKeys: PropTypes.any,
};

export default SideBarMenu;
