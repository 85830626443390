export const ASSET_LIST_REQUEST = "ASSET_LIST_REQUEST"; 
export const ASSET_LIST_SUCCESS = "ASSET_LIST_REQUEST_SUCCESS";
export const ASSET_TRANSFER_REQUEST = "ASSET_TRANSFER_REQUEST";
export const ASSET_BACK_TO_STOCK_REQUEST = "ASSET_BACK_TO_STOCK_REQUEST";
export const ASSET_TRANSFER_FORM_NAME = "transferAsset";
export const ASSET_BACK_TO_STOCK_FORM_NAME = "backToStock";

export const ASSET_SUBMIT_RULES = {
  id: {
    required: true,
  },
  toEmployeeId: {
    required: true,
  },
  quantity: {
    required: true,
  },
};

export const ASSET_BACK_TO_STOCK_RULES = {
  id: {
    required: true,
  },
  quantity: {
    required: true,
  },
};

export const READ_ASSET = 213000;
export const TRANSFER_ASSET = 213011;
export const ASSET_BACK_TO_STOCK  = 213013;