// Libs
import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

// Component
import Uploader from "./uploader";

const renderFileUpload = ({
  input: {
    value,
    onChange,
  },
  ...props
}) => {
  return (
    <Uploader
      value={value}
      onChange={onChange}
      {...props} 
    />
  );
};

renderFileUpload.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  })
};

class ReduxFileUpload extends React.Component {

  handleChange = (value, onChange) => {
    onChange(value || null);
    const { onChange: handleChange } = this.props;
    if (handleChange) {
      handleChange(value || null);
    }
  }

  render() {
    const {
      name,
      path,
      label,
      required,
      multiple,
      beforeUpload,
    } = this.props;
    return (
      <Field
        name={name}
        path={path}
        label={label}
        fileName={label}
        required={required}
        beforeUpload={beforeUpload}
        component={renderFileUpload}
        handleChange={this.handleChange}
        multiple={multiple}
      />
    );
  }
}

ReduxFileUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  beforeUpload: PropTypes.func,
  multiple: PropTypes.bool,
};

ReduxFileUpload.defaultProps = {
  required: false,
};

export default ReduxFileUpload;