import { 
  JunkOutSchema,
  JunkOutArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

const path = "/inventory/stock/junk-out";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getJunkOut,
    schema: JunkOutArraySchema,
    requestName: Constants.JUNK_OUT_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createJunkOut = (values) => (dispatch, getState) => {
  const redirectUrl = (json) => {
    return `${path}/${json.junkOutNo}`;
  };
  const options = {
    serviceMethod: service.createJunkOut,
    requestName: Constants.JUNK_OUT_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
  };
  return createNewEntityAction(options, values);
};

export const getJunkOutAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getJunkOutAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getJunkOutDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getJunkOutDetail,
    requestName: Constants.JUNK_OUT_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: JunkOutSchema,
  };
  return createDetailAction(options, id);
};

export const editJunkOut = (values) => (dispatch, getState) => {
  const redirectUrl = (json) => {
    return `${path}/${json.junkOutNo}`;
  };
  const options = {
    serviceMethod: service.editJunkOut,
    requestName: Constants.JUNK_OUT_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
    entityName: "junkOuts",
    schema: JunkOutSchema,
    successMessage: "Junk out is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveJunkOut,
    requestName: Constants.JUNK_OUT_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Junk out is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteJunkOut,
    requestName: Constants.JUNK_OUT_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Junk out is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveJunkOut,
    requestName: Constants.JUNK_OUT_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Junk out is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const submitJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitJunkOut,
    schema: JunkOutSchema,
    requestName: Constants.JUNK_OUT_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Junk out is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approveJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveJunkOut,
    schema: JunkOutSchema,
    requestName: Constants.JUNK_OUT_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Junk out is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectJunkOut,
    schema: JunkOutSchema,
    requestName: Constants.JUNK_OUT_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Junk out is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const cancelJunkOut = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.cancelJunkOut,
    schema: JunkOutSchema,
    requestName: Constants.JUNK_OUT_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Junk out is cancelled successfully!",
  };
  return createEntityActionAction(options, id);
};

export const processJunkOut = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.processJunkOut,
    schema: JunkOutSchema,
    requestName: Constants.JUNK_OUT_PROCESS_ITEM_REQUEST,
    dispatch,
    getState,
    successMessage: "Junk out is processed successfully!",
  };
  return createEntityActionAction(options, values);
};