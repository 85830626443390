import { Dropdown } from "antd";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import tinycolor from "tinycolor2";
import styled from "styled-components";
import { useSelector } from "react-redux";

// Style
import styles from "./index.less";
import { getDefaultCompany } from "@/modules/utils/value-selector";

const HeaderDropdownWrapper = styled(Dropdown)`
    &:hover {
      background: ${props => tinycolor(props.navBackground).lighten().toString()} !important;
    }
    &:global(.opened) {
      background: ${props => tinycolor(props.navBackground).lighten().toString()} !important;
    }
  }
`;

const HeaderDropdown = ({ overlayClassName: cls, ...restProps }) => {
  const company = useSelector((state) => getDefaultCompany(state));
  return (
    <HeaderDropdownWrapper 
      navBackground={company.navBackground}
      trigger={["click"]} 
      overlayClassName={classNames(styles.container, cls)} 
      {...restProps} 
    />
  );
}  
;

HeaderDropdown.propTypes = {
  overlayClassName: PropTypes.string,
};

export default HeaderDropdown;