import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import { FormText } from "reactstrap";
import RCColorPicker from "rc-color-picker";
import styled from "styled-components";

const ColorWrapper = styled(RCColorPicker)`
  .rc-color-picker-trigger {
    width: 80px;
    height: 20px;
    border-radius: 2px;
  }
`;

const FormItem = Form.Item;

class ColorPickerField extends Component {

  onChange = (colors) => {
    const { input: { onChange }, handleChange } = this.props;
    const { color } = colors;
    onChange(color);
    if (handleChange) {
      handleChange(color);
    }
  }

  render() {
    const { 
      tip,
      label, 
      required,
      disabled,
      meta: { touched, error },
      input: { value },
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <Fragment>
        <FormItem 
          label={label} 
          validateStatus={validateStatus}
          help={error}
          required={required}
        >
    
          <ColorWrapper
            animation="slide-up"
            color={value}
            disabled={disabled}
            onChange={this.onChange}
          />
        </FormItem>
        {tip && (
          <FormText
            color="muted"
            className="mt-0 mb-2"
          >
            {tip}
          </FormText>
        )}
      </Fragment>
    );
  }
}

ColorPickerField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  hide: PropTypes.bool,
  tip: PropTypes.string,
};

ColorPickerField.defaultProps = {
  required: false,
};

class ColorPicker extends Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field 
        component={ColorPickerField}
        {...props} 
      />
    );
  }
}

export default ColorPicker;