// Libs
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, Button, Dropdown, Menu, PageHeader } from "antd";
import { 
  SaveOutlined, 
  LoadingOutlined, 
  CloseCircleOutlined 
} from "@ant-design/icons";

// Component
import Breadcrumb from "@cores/components/breadcrumb";

// Helper
import { getPathName } from "@modules/helpers";

class Header extends Component {

  constructor(props) {
    super(props);
    this.onEventLoad();
  }

  onEventLoad = () => {
    window.addEventListener("keydown", this.handleEventLister);
  }

  handleEventLister = (event) => {
    const { history, match: { url }, backLevel } = this.props;
    const path = url.split("/");
    const previousPath = getPathName(path, backLevel);
    if (event.which === 27) {
      event.preventDefault();
      history.push(previousPath);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEventLister);
  }

  render() {
    const {  
      menu,
      loading,
      onSave,
      backLevel,
      updatable,
      breadcrumbs,
      onSaveClick,
      titleName,
      objectEntities,
      match: { url },
      extraButtons: ExtraButtons,
    } = this.props;
    const path = url.split("/");
    const previousPath = getPathName(path, backLevel);
    const newUpdatable = typeof(updatable) == "function" ? updatable(objectEntities || []) : updatable;
    return (
      <>
        <Card style={{padding:0}} bodyStyle={{padding:0}}>
          <PageHeader
            title={titleName}
            className="site-page-header"
            subTitle={status ? status : null}
            extra={[
              newUpdatable && menu.length > 0 ? (
                <Dropdown.Button
                  onClick={onSave}
                  htmlType="submit"
                  overlay={
                    <Menu>
                      {menu}
                    </Menu>
                  }
                  type="primary"
                  trigger={["click"]}
                >
                  {loading ? 
                    <LoadingOutlined /> : 
                    <SaveOutlined /> 
                  } Save
                </Dropdown.Button>
              ) : (
                <>
                  {newUpdatable && (
                    <Button 
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      className="mx-1"
                      icon={<SaveOutlined />}
                      onClick={onSaveClick ? onSaveClick : onSave}
                    >
                      Save
                    </Button>
                  )}
                </>
              ),
              ExtraButtons && (
                <ExtraButtons {...this.props} />
              ),
              <Link to={`/${previousPath}`} key="discard">
                <Button 
                  ghost 
                  type="primary"
                  icon={<CloseCircleOutlined />}
                >
                  Discard
                </Button>
              </Link>
            ]}
            breadcrumbRender={() => (
              <Breadcrumb 
                breadcrumbs={breadcrumbs} 
              />
            )}
          >
          </PageHeader>
        </Card>
      </>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  menu: PropTypes.any,
  match: PropTypes.any,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  backLevel: PropTypes.number,
  updatable: PropTypes.bool,
  history: PropTypes.func,
  extraButtons: PropTypes.any,
  onSaveClick: PropTypes.func,
  titleName: PropTypes.string,
  objectEntities: PropTypes.object,
};

Header.defaultProps = {
  loading: false,
  backLevel: 2,
  updatable: true,
  menu: [],
};

export default withRouter(Header);