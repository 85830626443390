import { combineLocale } from "@/helpers";

import { Km as HeaderMenuKm, En as HeaderMenuEn } from "@/components/layout/header/locale";
import { Km as ListLayoutKm, En as ListLayoutEn } from "@/components/layout/list-layout/locale";
import { Km as CreateLayoutKm, En as CreateLayoutEn } from "@/components/layout/create-layout/locale";
import { Km as DetailLayoutKm, En as DetailLayoutEn } from "@/components/layout/detail-layout/locale";
import { Km as DuplicateLayoutKm, En as DuplicateLayoutEn } from "@/components/layout/duplicate-layout/locale";

const locale = {
  en: combineLocale({
    headerMenu: HeaderMenuEn,
    listLayout: ListLayoutEn,
    createLayout: CreateLayoutEn,
    detailLayout: DetailLayoutEn,
    duplicateLayout: DuplicateLayoutEn,
  }),
  km: combineLocale({
    headerMenu: HeaderMenuKm,
    listLayout: ListLayoutKm,
    createLayout: CreateLayoutKm,
    detailLayout: DetailLayoutKm,
    duplicateLayout: DuplicateLayoutKm,
  })
};

export default locale;