import { 
  AssetRequestSchema,
  AssetRequestArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";

const path = "/inventory/asset/asset-request";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getAssetRequest,
    schema: AssetRequestArraySchema,
    requestName: Constants.ASSET_REQUEST_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createAssetRequest = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createAssetRequest,
    requestName: Constants.ASSET_REQUEST_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getAssetRequestAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getAssetRequestAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getAssetRequestDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getAssetRequestDetail,
    requestName: Constants.ASSET_REQUEST_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: AssetRequestSchema,
  };
  return createDetailAction(options, id);
};

export const editAssetRequest = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editAssetRequest,
    requestName: Constants.ASSET_REQUEST_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "assetRequests",
    schema: AssetRequestSchema,
    successMessage: "Asset request is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveAssetRequest,
    requestName: Constants.ASSET_REQUEST_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Asset request is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteAssetRequest,
    requestName: Constants.ASSET_REQUEST_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Asset request is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveAssetRequest,
    requestName: Constants.ASSET_REQUEST_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Asset request is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const submitAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitAssetRequest,
    schema: AssetRequestSchema,
    requestName: Constants.ASSET_REQUEST_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Asset request is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approveAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveAssetRequest,
    schema: AssetRequestSchema,
    requestName: Constants.ASSET_REQUEST_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Asset request is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectAssetRequest,
    schema: AssetRequestSchema,
    requestName: Constants.ASSET_REQUEST_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Asset request is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const cancelAssetRequest = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.cancelAssetRequest,
    schema: AssetRequestSchema,
    requestName: Constants.ASSET_REQUEST_CANCEL_REQUEST,
    dispatch,
    getState,
    successMessage: "Asset request is cancelled successfully!",
  };
  return createEntityActionAction(options, id);
};

export const processAssetRequest = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.processAssetRequest,
    schema: AssetRequestSchema,
    requestName: Constants.ASSET_REQUEST_PROCESS_ITEM_REQUEST,
    dispatch,
    getState,
    successMessage: "Asset request is processed successfully!",
  };
  return createEntityActionAction(options, values);
};