// Lib
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import EditLayout from "@/components/layout/edit-layout";
import LeaveRequestForm from "../../components/form-component";

// Constant
import * as LeaveRequestConstants from "../../constants";
import { leaveRequestSubmitRules } from "../../helpers";

// Action
import { 
  editLeaveRequest,
  getLeaveRequestDetail, 
} from "../../actions";

// Schema
import { LeaveRequestSchema } from "../../schema";
import { getDayLabel, toShortDate } from "@modules/helpers";
import { getCurrentUser } from "@modules/utils/value-selector";

class EditLeaveRequest extends Component {

  state = {
    disabledMessage: null
  }

  beforeInitialValues = (values) => {
    const newValues = {...values};
    const { leaveType, totalDays, startDate, endDate, employee } = values;
    const { allowPartial, documentRequired } = leaveType;
    newValues.leaveTypeId = leaveType && leaveType.id;
    newValues.employeeId = employee && employee.id;
    newValues.duration = getDayLabel(totalDays);
    newValues.startDate = toShortDate(startDate);
    newValues.endDate = toShortDate(endDate);
    newValues.dateRange = startDate != null && endDate != null;    
    newValues.isAllowPartial = allowPartial;
    newValues.isDocumentRequired = documentRequired;
    return newValues;
  }

  renderTitle = (values) => {
    const { employee } = values;
    if (employee) {
      return `${employee.firstName} ${employee.lastName}`;
    }
  };

  beforeSubmit = (values) => {
    const newValues = {...values};
    const { isDocumentRequired } = values;
    if (!isDocumentRequired) {
      newValues.documentUrl = null;
    }
    return newValues;
  }

  onDisable = (values) => {
    const { currentUser } = this.props;
    if (values.requestStatus != LeaveRequestConstants.LEAVE_REQUEST_STATUS_DRAFT) {
      return true;
    } else {
      const employee = currentUser && currentUser.employee;
      if (employee && values.employee) {
        if ((employee && employee.id) != (values.employee && values.employee.id)) {
          this.setState({
            disabledMessage: "You can not edit this leave request."
          });
          return true;
        }
      }
    }
  }

  renderFormComponent = (props) => {
    const { currentUser: { employee } } = this.props;
    const employeeId = employee && employee.id;
    return (
      <LeaveRequestForm 
        userId={employeeId}
        {...props}
      />
    );
  }

  render() {
    const { 
      id, 
      fullWrapper, 
      showHeader, 
      className, 
      children,
      onEditSuccess,
      notRedirectPage,
    } = this.props;
    const { disabledMessage } = this.state;
    return (
      <EditLayout 
        id={id}
        title="LeaveRequest"
        showHeader={showHeader}
        className={className}
        fullWrapper={fullWrapper}
        notRedirectPage={notRedirectPage}
        schema={LeaveRequestSchema}
        onEditSuccess={onEditSuccess}
        renderTitle={this.renderTitle}
        editAction={editLeaveRequest}
        loadData={getLeaveRequestDetail}
        formComponent={this.renderFormComponent}
        rules={leaveRequestSubmitRules}
        beforeSubmit={this.beforeSubmit}
        shouldDisabled={this.onDisable}
        disabledMessage={disabledMessage}
        beforeInitialValues={this.beforeInitialValues}
        form={LeaveRequestConstants.LEAVE_REQUEST_EDIT_FORM_NAME}
        requestName={LeaveRequestConstants.LEAVE_REQUEST_DETAIL_REQUEST}
        editSubmitRequestName={LeaveRequestConstants.LEAVE_REQUEST_EDIT_REQUEST}
      >
        {children}
      </EditLayout>
    );
  }
}

EditLeaveRequest.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,
  showHeader: PropTypes.bool,
  fullWrapper: PropTypes.true,
  className: PropTypes.string,
  currentUser: PropTypes.object,
  onEditSuccess: PropTypes.func,
  notRedirectPage: PropTypes.bool,
  userId: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state).user,
  };
};

export default connect(mapStateToProps)(EditLeaveRequest);