import * as CompanyConstants from "@/constants/company";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
  case CompanyConstants.FIND_COMPANY_SUCCESS:
    return {
      ...state,
      [action.subDomain]: action.company,
    };
  default:
    return state;
  }
}