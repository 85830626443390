export const STOCK_SETTING_SUBMIT_RULES = {
  defaultValuationMethod: {
    required: true
  },
  warehouseChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
};

export const READ_STOCK_SETTING = 204017;
export const UPDATE_STOCK_SETTING = 204018;

export const STOCK_SETTING_PATH = "/inventory/stock/stock-setting";
