import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";
import { denormalize } from "normalizr";
import { useDispatch, useSelector } from "react-redux";

// Components
import PopConfirm from "@cores/components/pop-confirm";
import TableSeeMore from "@cores/components/see-more-table";
import { getPermissionAllowed } from "@modules/utils/value-selector";

// Constants
import { toDate, modalConfirmDeleteAllSession } from "@modules/helpers";
import * as Constants from "@modules/settings/user-management/user-account/constants";
import { SessionArraySchema } from "@modules/settings/user-management/user-account/schema/session";
import { getUserSession, deleteSession, deleteAllSession } from "@modules/settings/user-management/user-account/actions";

function SessionList(props) {

  const { userId } = props;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    dispatch(getUserSession(userId, page));
  }, [page]);

  const entities = useSelector((state) => state.entities);
  const sessions = useSelector((state) => state?.session?.[userId]);
  const loading = useSelector((state) => state.request[Constants.USER_SESSION]);
  const pagination = useSelector((state) => state.pagination[Constants.USER_SESSION]);

  const dataSource = denormalize(sessions, SessionArraySchema, entities);
  const onDeleteSession = (id) => dispatch(deleteSession(id, userId));
  const onDeleteAllSession = () => dispatch(deleteAllSession(userId));
  const permissionAllowed = (code) => useSelector((state) => getPermissionAllowed(state, code));

  const confirmDeleteAllSession = () => {
    const employeeId = userId;
    modalConfirmDeleteAllSession(
      employeeId,
      onDeleteAllSession,
    );
  };

  const sessionColumns = () => {
    const allowedDelete = permissionAllowed(Constants.DELETE_USER_SESSION_CODE);
    const allowedBatchDelete = permissionAllowed(Constants.BATCH_DELETE_USER_SESSION_CODE);
    let columns = [
      {
        title: "No",
        width: "5%",
        render: (text, row, index) => index + 1
      },
      {
        title: "Device",
        width: "20%",
        render: (text, row) => {
          const { userDevice } = row;
          if (userDevice) {
            return userDevice.deviceName;
          }
        }
      },
      {
        title: "Browser",
        width: "20%",
        render: (text, row) => {
          const { userDevice } = row;
          if (userDevice) {
            return userDevice.browser;
          }
        }
      },
      {
        title: "IP Address",
        dataIndex: "userDevice",
        width: "20%",
        render: (userDevice) => {
          if (userDevice) {
            return userDevice.ipAddress;
          }
        }
      },
      {
        title: "Last Access",
        dataIndex: "lastAccessTime",
        width: "20%",
        render: (lastAccessTime) => toDate(lastAccessTime)
      },
    ];
    if (allowedDelete &&  allowedBatchDelete) {
      const deleteColumn = [
        {
          title: allowedBatchDelete ? (
            <a className="text-danger" onClick={() => confirmDeleteAllSession()}>
              Delete all
            </a>
          ) : null,
          width: "10%",
          dataIndex: "id",
          className: "text-center",
          render: (id) => {
            if (allowedDelete) {
              return (
                <div className="text-center text-danger">
                  <PopConfirm onConfirm={() => onDeleteSession(id)} />
                </div>
              );
            }
          }
        }
      ];
      columns = [
        ...columns,
        ...deleteColumn
      ];
    }
    return columns;
  };

  const hasNext = pagination?.hasNext;
  return (
    <Card headStyle={{padding: 0}}>
      <TableSeeMore 
        hasNext={hasNext}
        loading={loading}
        scroll={{ y: 750 }}
        dataSource={dataSource}
        columns={sessionColumns()}
        onClick={() => setPage(page + 1)}
      />
    </Card>
  );
}

SessionList.propTypes = {
  userId: PropTypes.number,
};

export default SessionList;