//action type 
export const CUSTOMER_GROUP_LIST_REQUEST = "CUSTOMER_GROUP_LIST_REQUEST";
export const CUSTOMER_GROUP_LIST_REQUEST_SUCCESS = "CUSTOMER_GROUP_LIST_REQUEST_SUCCESS";
export const CUSTOMER_GROUP_CREATE_REQUEST = "CUSTOMER_GROUP_CREATE_REQUEST";
export const CUSTOMER_GROUP_DETAIL_REQUEST = "CUSTOMER_GROUP_DETAIL_REQUEST";
export const CUSTOMER_GROUP_EDIT_REQUEST = "CUSTOMER_GROUP_EDIT_REQUEST";

export const CUSTOMER_GROUP_FORM_NAME ="customerGroup";

export const CUSTOMERGROUP_SUBMIT_RULES = () => { 
  return {
    name: {
      required: true,
    },
    code:{
      required: true,
    }
  };
};

// permission code
export const EDIT_CUSTOMER_GROUP = 272002;
export const READ_CUSTOMER_GROUP = 272000;
export const CREATE_CUSTOMER_GROUP = 272001;
export const DELETE_CUSTOMER_GROUP = 272003;
export const ARCHIVE_CUSTOMER_GROUP = 272004;
export const UNARCHIVE_CUSTOMER_GROUP = 272005;
export const CLEAR_CUSTOMER_GROUP = 272006;
export const CANCEL_CUSTOMER_GROUP = 272007;
export const PRINT_CUSTOMER_GROUP = 272008;
export const SUBMIT_CUSTOMER_GROUP = 272009;
export const APPROVE_CUSTOMER_GROUP = 272010;
export const REJECT_CUSTOMER_GROUP = 272011;
export const BACK_TO_DRAFT_CUSTOMER_GROUP = 272012;
export const POST_CUSTOMER_GROUP = 272013;
export const CLOSE_CUSTOMER_GROUP = 272014;
