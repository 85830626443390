const HR_PATH = "/human-resource";
const LEAVE_PATH = "leave";
const PAYROLL_PATH = "payroll";
const APPRAISAL_PATH = "appraisal";
const ATTENDANCE_PATH = "attendance";
const RECRUITENT_PATH = "recruitment";
const CASH_ADVANCE_PATH = "cash-advance";
const EMPLOYEE_DIRECTORY_PATH = "employee-directory";

export const hr = [
  //employee directory
  {
    name: "Employee List",
    type: "list",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/employee`,
  },
  {
    name: "Employee Detail",
    type: "detail",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/employee`,
  },
  {
    name: "Create Employee",
    type: "create",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/employee`,
  },
  {
    name: "Edit Employee",
    type: "edit",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/employee`,
  },
  {
    name: "Position List",
    type: "list",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/position`,
  },
  {
    name: "Position Detail",
    type: "detail",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/position`,
  },
  {
    name: "Create Position",
    type: "create",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/position`,
  },
  {
    name: "Edit Position",
    type: "edit",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/position`,
  },
  {
    name: "Department List",
    type: "list",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/department`,
  },
  {
    name: "Department Detail",
    type: "detail",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/department`,
  },
  {
    name: "Create Department",
    type: "create",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/department`,
  },
  {
    name: "Edit Department",
    type: "edit",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/department`,
  },
  {
    name: "Job Type List",
    type: "list",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/job-type`,
  },
  {
    name: "Job Type Detail",
    type: "detail",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/job-type`,
  },
  {
    name: "Create Job Type",
    type: "create",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/job-type`,
  },
  {
    name: "Edit Job Type",
    type: "edit",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/job-type`,
  },
  {
    name: "Employee Card List",
    type: "list",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/employment-card`,
  },
  {
    name: "Employee Card Detail",
    type: "detail",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/Employee Carde`,
  },
  {
    name: "Create Employee Card",
    type: "create",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/employment-card`,
  },
  {
    name: "Edit Employee Card",
    type: "edit",
    slug: `${HR_PATH}/${EMPLOYEE_DIRECTORY_PATH}/Employee Carde`,
  },
  // recruitment 
  {
    name: "Job Opening List",
    type: "list",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-opening`,
  },
  {
    name: "Job Opening Detail",
    type: "detail",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-opening`,
  },
  {
    name: "Create Job Opening",
    type: "create",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-opening`,
  },
  {
    name: "Edit Job Opening",
    type: "edit",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-opening`,
  },
  {
    name: "Job Applicant List",
    type: "list",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-applicant`,
  },
  {
    name: "Job Applicant Detail",
    type: "detail",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-applicant`,
  },
  {
    name: "Create Job Applicant",
    type: "create",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-applicant`,
  },
  {
    name: "Edit Job Applicant",
    type: "edit",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/job-applicant`,
  },
  {
    name: "Recruitment Stage List",
    type: "list",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/stage`,
  },
  {
    name: "Recruitment Stage Detail",
    type: "detail",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/stage`,
  },
  {
    name: "Create Recruitment Stage",
    type: "create",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/stage`,
  },
  {
    name: "Edit Recruitment Stage",
    type: "edit",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/stage`,
  },
  {
    name: "Offer Letter List",
    type: "list",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-letter`,
  },
  {
    name: "Offer Letter Detail",
    type: "detail",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-letter`,
  },
  {
    name: "Create Offer Letter",
    type: "create",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-letter`,
  },
  {
    name: "Edit Offer Letter",
    type: "edit",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-letter`,
  },
  {
    name: "Offer Term List",
    type: "list",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-term`,
  },
  {
    name: "Offer Term Detail",
    type: "detail",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-term`,
  },
  {
    name: "Create Offer Term",
    type: "create",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-term`,
  },
  {
    name: "Edit Offer Term",
    type: "edit",
    slug: `${HR_PATH}/${RECRUITENT_PATH}/offer-term`,
  },
  //appraisal
  {
    name: "Plan List",
    type: "list",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/plan`,
  },
  {
    name: "Plan Detail",
    type: "detail",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/plan`,
  },
  {
    name: "Create Plan",
    type: "create",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/plan`,
  },
  {
    name: "Edit Plan",
    type: "edit",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/plan`,
  },
  {
    name: "Form List",
    type: "list",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/form`,
  },
  {
    name: "Form Detail",
    type: "detail",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/form`,
  },
  {
    name: "Create Form",
    type: "create",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/form`,
  },
  {
    name: "Edit Form",
    type: "edit",
    slug: `${HR_PATH}/${APPRAISAL_PATH}/form`,
  },
  //leave
  {
    name: "Leave Request List",
    type: "list",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-request`,
  },
  {
    name: "Leave Request Detail",
    type: "detail",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-request`,
  },
  {
    name: "Create Leave Request",
    type: "create",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-request`,
  },
  {
    name: "Edit Leave Request",
    type: "edit",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-request`,
  },
  {
    name: "Leave Type List",
    type: "list",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-type`,
  },
  {
    name: "Leave Type Detail",
    type: "detail",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-type`,
  },
  {
    name: "Create Leave Type",
    type: "create",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-type`,
  },
  {
    name: "Edit Leave Type",
    type: "edit",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-type`,
  },
  {
    name: "Leave Allocation List",
    type: "list",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-allocation`,
  },
  {
    name: "Leave Allocation Detail",
    type: "detail",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-allocation`,
  },
  {
    name: "Create Leave Allocation",
    type: "create",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-allocation`,
  },
  {
    name: "Edit Leave Allocation",
    type: "edit",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-allocation`,
  },
  {
    name: "Leave Policy List",
    type: "list",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-policy`,
  },
  {
    name: "Leave Policy Detail",
    type: "detail",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-policy`,
  },
  {
    name: "Create Leave Policy",
    type: "create",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-policy`,
  },
  {
    name: "Edit Leave Policy",
    type: "edit",
    slug: `${HR_PATH}/${LEAVE_PATH}/leave-policy`,
  },
  {
    name: "Holiday Group List",
    type: "list",
    slug: `${HR_PATH}/${LEAVE_PATH}/holiday-group`,
  },
  {
    name: "Holiday Group Detail",
    type: "detail",
    slug: `${HR_PATH}/${LEAVE_PATH}/holiday-group`,
  },
  {
    name: "Create Holiday Group",
    type: "create",
    slug: `${HR_PATH}/${LEAVE_PATH}/holiday-group`,
  },
  {
    name: "Edit Holiday Group",
    type: "edit",
    slug: `${HR_PATH}/${LEAVE_PATH}/holiday-group`,
  },
  //attendance
  {
    name: "Attendance List",
    type: "list",
    slug: `${HR_PATH}/${ATTENDANCE_PATH}/view`,
  },
  {
    name: "Working Day List",
    type: "list",
    slug: `${HR_PATH}/${ATTENDANCE_PATH}/working-day`,
  },
  {
    name: "Working Day Detail",
    type: "detail",
    slug: `${HR_PATH}/${ATTENDANCE_PATH}/working-day`,
  },
  {
    name: "Create Working Day",
    type: "create",
    slug: `${HR_PATH}/${ATTENDANCE_PATH}/working-day`,
  },
  {
    name: "Edit Working Day",
    type: "edit",
    slug: `${HR_PATH}/${ATTENDANCE_PATH}/working-day`,
  },
  //payroll 
  {
    name: "Payslip",
    type: "list",
    slug: `${HR_PATH}/${PAYROLL_PATH}/payslip`,
  },
  {
    name: "Payslip Detail",
    type: "detail",
    slug: `${HR_PATH}/${PAYROLL_PATH}/payslip`,
  },
  {
    name: "Payslip Batch List",
    type: "list",
    slug: `${HR_PATH}/${PAYROLL_PATH}/payslip-batch`,
  },
  {
    name: "Payslip Batch Detail",
    type: "detail",
    slug: `${HR_PATH}/${PAYROLL_PATH}/payslip-batch`,
  },
  {
    name: "Create Payslip Batch",
    type: "create",
    slug: `${HR_PATH}/${PAYROLL_PATH}/payslip-batch`,
  },
  {
    name: "Edit Payslip Batch",
    type: "edit",
    slug: `${HR_PATH}/${PAYROLL_PATH}/payslip-batch`,
  },
  {
    name: "Deduction Type List",
    type: "list",
    slug: `${HR_PATH}/${PAYROLL_PATH}/deduction-type`,
  },
  {
    name: "Deduction Type Detail",
    type: "detail",
    slug: `${HR_PATH}/${PAYROLL_PATH}/deduction-type`,
  },
  {
    name: "Create Deduction Type",
    type: "create",
    slug: `${HR_PATH}/${PAYROLL_PATH}/deduction-type`,
  },
  {
    name: "Edit Deduction Type",
    type: "edit",
    slug: `${HR_PATH}/${PAYROLL_PATH}/deduction-type`,
  },
  {
    name: "Earning Type List",
    type: "list",
    slug: `${HR_PATH}/${PAYROLL_PATH}/earning-type`,
  },
  {
    name: "Earning Type Detail",
    type: "detail",
    slug: `${HR_PATH}/${PAYROLL_PATH}/earning-type`,
  },
  {
    name: "Create Earning Type",
    type: "create",
    slug: `${HR_PATH}/${PAYROLL_PATH}/earning-type`,
  },
  {
    name: "Edit Earning Type",
    type: "edit",
    slug: `${HR_PATH}/${PAYROLL_PATH}/earning-type`,
  },
  {
    name: "Salary Structure List",
    type: "list",
    slug: `${HR_PATH}/${PAYROLL_PATH}/salary-structure`,
  },
  {
    name: "Salary Structure Detail",
    type: "detail",
    slug: `${HR_PATH}/${PAYROLL_PATH}/salary-structure`,
  },
  {
    name: "Create Salary Structure",
    type: "create",
    slug: `${HR_PATH}/${PAYROLL_PATH}/salary-structure`,
  },
  {
    name: "Edit Salary Structure",
    type: "edit",
    slug: `${HR_PATH}/${PAYROLL_PATH}/salary-structure`,
  },
  {
    name: "Incentive Template List",
    type: "list",
    slug: `${HR_PATH}/${PAYROLL_PATH}/incentive-template`,
  },
  {
    name: "Incentive Template Detail",
    type: "detail",
    slug: `${HR_PATH}/${PAYROLL_PATH}/incentive-template`,
  },
  {
    name: "Create Incentive Template",
    type: "create",
    slug: `${HR_PATH}/${PAYROLL_PATH}/incentive-template`,
  },
  {
    name: "Edit Incentive Template",
    type: "edit",
    slug: `${HR_PATH}/${PAYROLL_PATH}/incentive-template`,
  },
  //cash advance
  {
    name: "Cash Advanced List",
    type: "list",
    slug: `${HR_PATH}/${CASH_ADVANCE_PATH}/cash-advanced`,
  },
  {
    name: "Cash Advanced Detail",
    type: "detail",
    slug: `${HR_PATH}/${CASH_ADVANCE_PATH}/icash-advanced`,
  },
  {
    name: "Create Cash Advanced",
    type: "create",
    slug: `${HR_PATH}/${CASH_ADVANCE_PATH}/cash-advanced`,
  },
  {
    name: "Edit Cash Advanced",
    type: "edit",
    slug: `${HR_PATH}/${CASH_ADVANCE_PATH}/icash-advanced`,
  },
];