export const ANNIVERSARY_LIST_REQUEST = "ANNIVERSARY_LIST_REQUEST";
export const ANNIVERSARY_LIST_SUCCESS = "ANNIVERSARY_LIST_REQUEST_SUCCESS";

export const ANNIVERSARY_DETAIL_REQUEST = "ANNIVERSARY_DETAIL_REQUEST";
export const CREATE_APPRAISAL_EMPLOYEE_REQUEST = "CREATE_APPRAISAL_EMPLOYEE_REQUEST";
export const START_EVALUATE_EMPLOYEE_FORM_NAME = "startEvaluate";

export const READ_ANNIVERSARY = 126000;
export const CREATE_ANNIVERSARY = 126001;
export const UPDATE_ANNIVERSARY = 126002;
export const DELETE_ANNIVERSARY = 126003;
export const ARCHIVE_ANNIVERSARY = 126004;
export const UNARCHIVE_ANNIVERSARY = 126005;
export const START_APPRAISAL_EMPLOYEE = 130011;
export const CONFIRM_APPRAISAL_EMPLOYEE = 130012;

export const ANNIVERSARY_STATUS_WAITING_EVALUATED = false;
export const ANNIVERSARY_STATUS_WAITING_EVALUATED_FILTER_VALUE = 1;
export const ANNIVERSARY_STATUS_WAITING_EVALUATED_TITLE = "Waiting Evaluate";
export const ANNIVERSARY_STATUS_EVALUATED = true;
export const ANNIVERSARY_STATUS_EVALUATED_FILTER_VALUE = 2;
export const ANNIVERSARY_STATUS_EVALUATED_TITLE = "Evaluated";
export const ANNIVERSARY_STATUS_COMPLETED_FILTER_VALUE = 3;
export const ANNIVERSARY_STATUS_COMPLETED_TITLE = "Completed";

export const ANNIVERSARY_TYPE_PROBATION = 1;
export const ANNIVERSARY_TYPE_PROBATION_TITLE = "Probation";
export const ANNIVERSARY_TYPE_PERIODICITY = 2;
export const ANNIVERSARY_TYPE_PERIODICITY_TITLE = "Periodicity";

export const ANNIVERSARY_TYPE_PROBATION_DATA_SOURCE = [
  {
    id: ANNIVERSARY_TYPE_PROBATION,
    name: ANNIVERSARY_TYPE_PROBATION_TITLE,
  },
  {
    id: ANNIVERSARY_TYPE_PERIODICITY,
    name: ANNIVERSARY_TYPE_PERIODICITY_TITLE,
  }
];

export const ANNIVERSARY_STATUS_DATA_SOURCE = [
  {
    id: ANNIVERSARY_STATUS_WAITING_EVALUATED_FILTER_VALUE,
    name: ANNIVERSARY_STATUS_WAITING_EVALUATED_TITLE,
  },
  {
    id: ANNIVERSARY_STATUS_EVALUATED_FILTER_VALUE,
    name: ANNIVERSARY_STATUS_EVALUATED_TITLE,
  },
  {
    id: ANNIVERSARY_STATUS_COMPLETED_FILTER_VALUE,
    name: ANNIVERSARY_STATUS_COMPLETED_TITLE,
  },
];