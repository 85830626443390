import React, { Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Menu,
  Row,
  Input,
  Spin,
  Empty,
  Button,
  Checkbox,
  Dropdown,
  Col,
  Tooltip,
} from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

// style
import { getAutoComplete } from "@modules/utils/value-selector";
import {
  getQueryParamUrl,
  capitalizeFirstLetter,
  getQueryParamFromLocalStorage,
  updateQueryParamUrl,
  updateQueryParamToLocalStorage,
} from "@modules/helpers";

function AsyncSelectCheckBox(props) {
  const {
    extra,
    name,
    mode,
    action,
    valueKey,
    titleKey,
    location,
    onChange,
    dataSource,
    requestName,
    placeholder,
    queryStorage,
    beforeChange,
    optionRender,
    stringValue,
    beforeUpdateQuery,
    defaultValue,
    autoFocus,
    onlyLocal,
  } = props;

  const [visible, setVisible] = React.useState(false);
  const inputRef = React.useRef(null);

  const dispatch = useDispatch();
  const query = getQueryParamUrl();

  const defaultQuery = query[name];
  const view = query["view"];

  const [checkedValues, setCheckedValues] = React.useState(defaultQuery || []);
  const [isSearching, setIsSearching] = React.useState(false);

  const loadData = (values) =>
    dispatch(action(values, { requestName, ...extra }));
  const loadDataSearch = (values) =>
    dispatch(
      action(values, { requestName: `${requestName}_SEARCH`, ...extra })
    );

  const loading = useSelector((state) => state.request[requestName]);
  const loadingSearch = useSelector(
    (state) => state.request[`${requestName}_SEARCH`]
  );

  let selectDataSource = useSelector((state) =>
    getAutoComplete(state, requestName)
  );
  let searchDataSource = useSelector((state) =>
    getAutoComplete(state, `${requestName}_SEARCH`)
  );

  if (dataSource != null) {
    selectDataSource = dataSource;
  }

  let items = isSearching ? searchDataSource : selectDataSource;

  const getValue = (value, data) => {
    let foundObject = _.find(data, function(e) {
      return e.id === value;
    });
    return foundObject;
  };

  React.useEffect(() => {
    const toSetValue =
      queryStorage == "url"
        ? getQueryParamUrl()
        : getQueryParamFromLocalStorage();
    setCheckedValues(toSetValue[name]);
  }, [location]);

  React.useEffect(() => {
    if (_.isEmpty(getQueryParamUrl()[name])) {
      setCheckedValues(defaultValue != null ? [defaultValue] : []);
    }
  }, [location]);

  React.useEffect(() => {
    if (onlyLocal) {
      if (_.isEmpty(getQueryParamFromLocalStorage()[name])) {
        setCheckedValues(defaultValue != null ? [defaultValue] : []);
      }
    }
  }, [getQueryParamFromLocalStorage()[name]]);

  React.useEffect(() => {
    if (!dataSource && !_.isEmpty(defaultQuery)) {
      onLoadData();
    }
  }, [view]);

  const onCheckBoxChange = async (values) => {
    let query =
      queryStorage == "url"
        ? getQueryParamUrl()
        : getQueryParamFromLocalStorage();
    if (values && values.length > 0) {
      let newValues = values;
      if (isSearching) {
        if (query[name]) {
          const oldValue = query[name];
          values = oldValue.concat(values);
        }
      }
      if (_.isArray(values) && values.length > 0) {
        newValues =
          mode == "single" && stringValue
            ? newValues[0]
            : values[values.length - 1];
        const page = getQueryParamUrl().page;
        if (page && page > 1) {
          delete query.page;
        }
        const queryValues =
          mode == "multiple" ? values : stringValue ? newValues : [newValues];
        const toUpdateValues = stringValue ? queryValues : _.uniq(queryValues);
        query[name] = toUpdateValues;
        if (beforeChange) {
          await beforeChange(values);
        }
        if (beforeUpdateQuery) {
          query = await beforeUpdateQuery(query);
        }
        if (queryStorage == "url") {
          updateQueryParamUrl(query);
        } else {
          updateQueryParamToLocalStorage(query);
          setCheckedValues(toUpdateValues);
        }
        const existingData = getValue(newValues, selectDataSource);
        if (existingData == null) {
          const newData = getValue(newValues, searchDataSource);
          if (newData != null) {
            selectDataSource.unshift(newData);
          }
        }
        if (onChange) {
          onChange(queryValues, values);
        }
      }
    } else {
      delete query[name];
      if (beforeUpdateQuery) {
        query = await beforeUpdateQuery(query);
      }
      if (queryStorage == "url") {
        updateQueryParamUrl(query);
      } else {
        updateQueryParamToLocalStorage(query);
        setCheckedValues(defaultValue ? [defaultValue] : []);
      }
      if (onChange) {
        onChange(null);
      }
    }
  };

  const onLoadData = (value) => {
    const { name, mode, extraIds } = props;
    let query = getQueryParamUrl();
    let queryValue = query[name];
    if (extraIds != null) {
      queryValue = [...extraIds, queryValue];
    }
    if (loadData) {
      if (mode == "multiple") {
        loadData({
          value,
          ids: queryValue,
        });
      } else {
        loadData({
          value,
          ids: _.isArray(queryValue) ? queryValue : [queryValue],
        });
      }
    }
  };

  const search = _.debounce((value) => {
    if (value) {
      setIsSearching(true);
      loadDataSearch(value);
    } else {
      setIsSearching(false);
    }
  }, 250);

  const onPressEnter = async (e) => {
    e.preventDefault();
    const firstRow = items && items[0];
    if (firstRow != null) {
      let query =
        queryStorage == "url"
          ? getQueryParamUrl()
          : getQueryParamFromLocalStorage();
      const existingData = getValue(firstRow && firstRow.id, selectDataSource);
      if (existingData == null) {
        const newData = getValue(firstRow && firstRow.id, searchDataSource);
        if (newData != null) {
          selectDataSource.unshift(newData);
        }
      }
      let toUpdateValues = [];
      if (mode == "multiple") {
        if (checkedValues && checkedValues.length > 0) {
          toUpdateValues = [...checkedValues, firstRow.id];
        } else {
          toUpdateValues = [firstRow.id];
        }
      } else {
        toUpdateValues = [firstRow.id];
      }
      if (stringValue == null) {
        toUpdateValues = toUpdateValues.map((value) => Number(value));
      }
      toUpdateValues = _.uniq(toUpdateValues);
      query[name] = toUpdateValues;
      if (beforeChange) {
        await beforeChange(toUpdateValues);
      }
      if (beforeUpdateQuery) {
        query = await beforeUpdateQuery(query);
      }
      if (queryStorage == "url") {
        updateQueryParamUrl(query);
      } else {
        updateQueryParamToLocalStorage(query);
        setCheckedValues(toUpdateValues);
      }
      if (onChange) {
        onChange(toUpdateValues);
      }
    }
  };

  const renderValues = (values) => {
    if (mode == "multiple") {
      if (values != undefined && _.isArray(values)) {
        values = values.map((value) => Number(value));
      }
    } else {
      values =
        values != null
          ? stringValue
            ? values
            : values.map((value) => Number(value))
          : [];
    }
    return values;
  };

  const getValueToDisplay = () => {
    if (_.isArray(checkedValues)) {
      let newCheckedValue = checkedValues;
      if (stringValue == null) {
        newCheckedValue =
          checkedValues && checkedValues.map((value) => Number(value));
      }
      if (newCheckedValue && newCheckedValue.length > 0) {
        const selected =
          items &&
          items.filter((value) => newCheckedValue.indexOf(value.id) != -1);
        return selected;
      }
      return null;
    }
    return stringValue ? checkedValues : null;
  };

  const toDisplay = getValueToDisplay();

  const menu = (
    <Menu>
      <Checkbox.Group
        className="px-3 pt-1 w-100"
        onChange={onCheckBoxChange}
        value={
          renderValues(checkedValues)?.length > 0
            ? renderValues(checkedValues)
            : [defaultValue]
        }
      >
        {!dataSource && (
          <Col span={24} className="mb-2 mt-1">
            <Input
              allowClear
              ref={inputRef}
              autoFocus={autoFocus}
              placeholder="Search..."
              onPressEnter={onPressEnter}
              onChange={(event) => search(event.target.value)}
            />
          </Col>
        )}
        <Row>
          {loading == true || loadingSearch == true || items == null ? (
            <Col span={24} className="mb-2 mt-1">
              <Spin className="d-block mb-3 mt-4 w-100" tip="Searching..." />
            </Col>
          ) : (
            <div
              style={{
                maxHeight: "400px",
                overflow: "auto",
                width: "100%",
              }}
            >
              {items && items.length > 0 ? (
                <Fragment>
                  {items.map((value, index) => {
                    let id = value.id;
                    if (typeof id != "string") {
                      id = Number(value[valueKey]);
                      if (_.isNaN(value)) {
                        id = value[valueKey];
                      }
                    }
                    return (
                      <div className="mb-2" key={index}>
                        <Checkbox value={id}>
                          {typeof optionRender != "function"
                            ? value[titleKey]
                            : optionRender(value)}
                        </Checkbox>
                      </div>
                    );
                  })}
                </Fragment>
              ) : (
                <Empty className="my-2" />
              )}
            </div>
          )}
        </Row>
      </Checkbox.Group>
    </Menu>
  );

  const onVisibleChange = (visible) => {
    if (visible && !dataSource && !selectDataSource) {
      onLoadData();
    }
    if (visible) {
      localStorage.removeItem("ON_FILTER_ENTER");
    } else {
      localStorage.setItem("ON_FILTER_ENTER", "ACTIVE");
    }
    setVisible(visible);
  };

  React.useEffect(() => {
    if (
      visible == true &&
      items &&
      items.length > 0 &&
      !dataSource &&
      inputRef?.current
    ) {
      inputRef?.current?.focus?.();
    }
  }, [visible]);

  const renderTrimText = (text) => {
    const trimmedText =
      text.length > 45 ? text.substring(0, 45 - 3) + "..." : text;
    if (text.length > 45) {
      return (
        <Tooltip placement="right" title={text}>
          {trimmedText}
        </Tooltip>
      );
    }
    return trimmedText;
  };

  return (
    <Form.Item name={name}>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        visible={visible}
        placement="bottomLeft"
        onVisibleChange={onVisibleChange}
      >
        <Button
          block
          className="d-flex align-items-center justify-content-center"
        >
          {placeholder || capitalizeFirstLetter(name)}
          {toDisplay && toDisplay.length > 1 ? (
            <small className="mx-1 font-weight-bold">
              :{" "}
              <b>{stringValue ? toDisplay : `${toDisplay.length} Selected`}</b>
            </small>
          ) : (
            toDisplay &&
            toDisplay.length == 1 && (
              <small className="mx-1 font-weight-bold">
                {toDisplay && (
                  <Fragment>
                    :{" "}
                    {typeof optionRender != "function"
                      ? renderTrimText(toDisplay[0][titleKey])
                      : renderTrimText(optionRender(toDisplay[0]))}
                  </Fragment>
                )}
              </small>
            )
          )}
          {visible ? (
            <CaretUpOutlined style={{ fontSize: 10 }} />
          ) : (
            <CaretDownOutlined style={{ fontSize: 10 }} />
          )}
        </Button>
      </Dropdown>
    </Form.Item>
  );
}

AsyncSelectCheckBox.propTypes = {
  extra: PropTypes.any,
  name: PropTypes.string,
  mode: PropTypes.string,
  action: PropTypes.func,
  onChange: PropTypes.any,
  extraIds: PropTypes.any,
  dataSource: PropTypes.any,
  requestName: PropTypes.string,
  placeholder: PropTypes.string,
  queryStorage: PropTypes.string,
  beforeChange: PropTypes.func,
  beforeUpdateQuery: PropTypes.func,
  location: PropTypes.object,
  stringValue: PropTypes.bool,
  optionRender: PropTypes.func,
  valueKey: PropTypes.string,
  titleKey: PropTypes.string,
  defaultValue: PropTypes.any,
  autoFocus: PropTypes.bool,
  onlyLocal: PropTypes.bool,
};

AsyncSelectCheckBox.defaultProps = {
  mode: "multiple",
  valueKey: "id",
  titleKey: "name",
  autoFocus: true,
};

export default withRouter(AsyncSelectCheckBox);
