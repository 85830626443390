import { 
  CashAdvancedSchema,
  CashAdvancedArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createNewEntityAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as CashAdvancedConstants from "../constants";

const path = "/human-resource/cash-advance/cash-advanced";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCashAdvanced,
    schema: CashAdvancedArraySchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createCashAdvanced = (values) => (dispatch, getState) => {
  
  const redirectUrl = (json) => {
    return `${path}/${json.cashAdvancedNo}`;
  }; 

  const options = {
    serviceMethod: service.createCashAdvanced,
    requestName: CashAdvancedConstants.CASH_ADVANCED_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl,
  };
  return createNewEntityAction(options, values);
};

export const getCashAdvancedAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getCashAdvancedAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getCashAdvancedDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCashAdvancedDetail,
    requestName: CashAdvancedConstants.CASH_ADVANCED_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: CashAdvancedSchema,
  };
  return createDetailAction(options, id);
};

export const editCashAdvanced = (values) => (dispatch, getState) => {

  const redirectUrl = (json) => {
    return `${path}/${json.cashAdvancedNo}`;
  }; 

  const options = {
    serviceMethod: service.editCashAdvanced,
    requestName: CashAdvancedConstants.CASH_ADVANCED_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl,
    entityName: "cashAdvances",
    schema: CashAdvancedSchema,
    successMessage: "Cash advanced is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveCashAdvanced = (id) => (dispatch, getState) => {

  const options = {
    serviceMethod: service.archiveCashAdvanced,
    requestName: CashAdvancedConstants.CASH_ADVANCED_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Cash advanced is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteCashAdvanced = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteCashAdvanced,
    requestName: CashAdvancedConstants.CASH_ADVANCED_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Cash advanced is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveCashAdvanced = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveCashAdvanced,
    requestName: CashAdvancedConstants.CASH_ADVANCED_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const submitCashAdvanced = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.submitCashAdvanced,
    schema: CashAdvancedSchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_SUBMIT_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is submitted successfully!",
  };
  return createEntityActionAction(options, id);
};

export const approveCashAdvanced = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveCashAdvanced,
    schema: CashAdvancedSchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_APPROVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is approved successfully!",
  };
  return createEntityActionAction(options, id);
};

export const rejectCashAdvanced = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectCashAdvanced,
    schema: CashAdvancedSchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_REJECT_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is rejected successfully!",
  };
  return createEntityActionAction(options, id);
};

export const postCashAdvanced = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.postCashAdvanced,
    schema: CashAdvancedSchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_POST_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is posted successfully!",
  };
  return createEntityActionAction(options, values);
};

export const payCashAdvanced = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.payCashAdvanced,
    schema: CashAdvancedSchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_PAY_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is paid successfully!",
  };
  return createEntityActionAction(options, values);
};

export const setToDraftCashAdvanced = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.setToDraftCashAdvanced,
    schema: CashAdvancedSchema,
    requestName: CashAdvancedConstants.CASH_ADVANCED_SET_TO_DRAFT_REQUEST,
    dispatch,
    getState,
    successMessage: "Cash advanced is set to draft successfully!",
  };
  return createEntityActionAction(options, id);
};