export const REPORT_TEMPLATE_AUTO_COMPLETE_REQUEST =
  "REPORT_TEMPLATE_AUTO_COMPLETE_REQUEST";

// Report Template Type
export const REPORT_TYPE_ITEM_LIST_REPORT = 2;
export const REPORT_TYPE_CHART_OF_ACCOUNT = 103;
export const REPORT_TYPE_JOURNAL_ENTRY = 105;
export const REPORT_TYPE_A_R_AGING_REPORT = 151;
export const REPORT_TYPE_CUSTOMER_LIST_REPORT = 152;
export const REPORT_TYPE_CUSTOMER_BALANCE_REPORT = 153;

export const REPORT_TYPE_EMPLOYEE_LIST = 1;
export const REPORT_TYPE_CASH_ADVANCED = 2;
export const REPORT_TYPE_JOB_APPLICANT = 600;
export const REPORT_TYPE_JOB_OPENING = 651;
export const REPORT_TYPE_ITEM_MOVEMENT_LOG = 100;
export const REPORT_TYPE_STOCK_REPORT = 150;
export const REPORT_TYPE_ITEM_EXPIRATION_REPORT = 106;
export const REPORT_TYPE_STOCK_REORDER_REPORT = 151;
export const REPORT_TYPE_STOCK_VALUES_REPORT = 152;
export const REPORT_TYPE_ASSET_REPORT = 154;
export const REPORT_TYPE_STOCK_VALUES_DETAIL_REPORT = 153;
export const REPORT_TYPE_CUSTOMER_REFUND = 450;
export const REPORT_TYPE_SALE_COMMISSION = 351;
export const REPORT_TYPE_DETAILED_SALE = 101;
export const REPORT_TYPE_DETAILED_SALE_PM = 108;
export const REPORT_TYPE_DETAILED_SALE_WITH_INCOME = 109;
export const REPORT_TYPE_CUSTOMER_DEPOSIT = 451;
export const REPORT_TYPE_INCOME_STATEMENT = 200;
export const REPORT_TYPE_BALANCE_SHEET = 201;
export const REPORT_TYPE_ACCOUNTING_RECEIVABLE_AGEING_DETAIL = 202;
export const REPORT_TYPE_ACCOUNTING_RECEIVABLE_AGEING_SUMMERY = 203;
export const REPORT_TYPE_EXPENSE = 204;
export const REPORT_TYPE_SALE_ORDER = 350;
export const REPORT_INVOICE_LIST = 359;
export const REPORT_TYPE_QUOTATION_REPORT = 300;
export const REPORT_TYPE_PURCHASE_ORDER = 250;
export const REPORT_TYPE_PURCHASE_ORDER_DETAIL = 255;
export const REPORT_TYPE_PURCHASE_ORDER_SUMMARY = 251;
export const REPORT_TYPE_DELIVERY_ORDER = 50;
export const REPORT_TYPE_BANK = 400;
export const REPORT_TYPE_BANK_DETAILED = 401;
export const REPORT_TYPE_ATTENDANCE = 550;
export const REPORT_TYPE_ATTENDANCE_LOG = 551;
export const REPORT_TYPE_LEAVE_REQUEST = 500;
export const REPORT_TYPE_ITEM_SALE = 102;
export const REPORT_TYPE_END_OF_DAY = 205;
export const REPORT_TYPE_END_OF_DAY_SUMMARY = 206;
export const REPORT_TYPE_PRICE_LIST = 103;
export const REPORT_TYPE_AGING_REPORT = 252;
export const REPORT_TYPE_VAT_REQUEST = 352;
export const REPORT_TYPE_ITEM = 104;
export const REPORT_TYPE_ITEM_FORECASTING = 105;
export const REPORT_TYPE_GENERAL_LEDGER = 207;
export const REPORT_TYPE_TRAIL_BALANCE = 208;
export const REPORT_TYPE_JOURNAL = 209;
export const REPORT_TYPE_SUPPLIER_DEPOSIT_LOG = 750;
export const REPORT_TYPE_CUSTOMER_DEPOSIT_LOG = 452;
export const REPORT_TYPE_CUSTOMER_DEPOSIT_DETAIL = 453;
export const REPORT_TYPE_SUPPLIER_DEPOSIT = 751;
export const REPORT_TYPE_SUPPLIER_DEPOSIT_DETAIL = 752;
export const REPORT_TYPE_SUPPLIER_REFUND_REPORT = 753;
export const REPORT_TYPE_CUSTOMER_REPORT = 454;
export const REPORT_TYPE_LENOVO_INVENTORY_REPORT = 155;
export const REPORT_TYPE_LENOVO_SELL_THRU_REPORT = 353;
export const REPORT_TYPE_ACCOUNT_STATEMENT = 354;
export const REPORT_TYPE_ITEM_INSTALLATION_SUMMARY = 700;
export const REPORT_TYPE_SELL_OUT = 355;
export const REPORT_TYPE_PH_SELL_OUT = 358;
export const REPORT_TYPE_GOOD_IN = 253;
export const REPORT_TYPE_DELL_INVENTORY = 156;
export const REPORT_TYPE_PH_INVENTORY = 157;
export const REPORT_TYPE_ITEM_CHANGE_LOG = 107;
export const REPORT_TYPE_SUPPLIER = 754;
export const REPORT_TYPE_COMPANY_RESPONSE = 455;
export const REPORT_TYPE_CUSTOMER_COMPANY = 456;
export const REPORT_TYPE_CUSTOMER_COMMISSION = 457;
export const REPORT_TYPE_PAID_REPORT = 356;
export const REPORT_TYPE_PURCHASE_ORDER_PAYMENT = 254;
export const REPORT_TYPE_JUNK_OUT = 158;
export const REPORT_TYPE_LOGITECH_INVENTORY = 159;
export const REPORT_TYPE_APC_INVENTORY = 160;
export const REPORT_TYPE_SONICWALL_INVENTORY = 161;
export const REPORT_TYPE_LOGITECH_SELL_OUT = 360;
export const REPORT_TYPE_APC_SELL_OUT = 361;
export const REPORT_TYPE_SONICWALL_SELL_OUT = 362;
export const REPORT_TYPE_SALE_SUMMARY_BY_ITEM = 362;
export const REPORT_TYPE_SALE_SUMMARY_BY_CUSTOMER = 363;
export const REPORT_TYPE_SALE_SUMMARY_BY_EMPLOYEE = 364;
export const REPORT_TYPE_SALE_SUMMARY_BY_COMPANY = 365;
export const REPORT_TYPE_SERVICE = 702;
export const REPORT_TYPE_APPRAISAL_ANNIVERSARY = 1100;
