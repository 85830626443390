export const WAREHOUSE_LIST_REQUEST = "WAREHOUSE_LIST_REQUEST"; 
export const WAREHOUSE_LIST_SUCCESS = "WAREHOUSE_LIST_REQUEST_SUCCESS";
export const WAREHOUSE_CREATE_REQUEST = "WAREHOUSE_CREATE_REQUEST"; 
export const WAREHOUSE_DETAIL_REQUEST = "WAREHOUSE_DETAIL_REQUEST"; 
export const WAREHOUSE_ARCHIVE_REQUEST = "WAREHOUSE_ARCHIVE_REQUEST"; 
export const WAREHOUSE_DELETE_REQUEST = "WAREHOUSE_DELETE_REQUEST"; 
export const WAREHOUSE_UNARCHIVE_REQUEST = "WAREHOUSE_UNARCHIVE_REQUEST"; 
export const WAREHOUSE_EDIT_REQUEST = "WAREHOUSE_EDIT_REQUEST"; 
export const WAREHOUSE_AUTOCOMPLETE_REQUEST = "WAREHOUSE_AUTOCOMPLETE_REQUEST";
export const WAREHOUSE_AUTOCOMPLETE_SUCCESS = "WAREHOUSE_AUTOCOMPLETE_SUCCESS";
export const WAREHOUSE_CREATE_FORM_NAME = "createWarehouse"; 
export const WAREHOUSE_EDIT_FORM_NAME = "editWarehouse"; 

export const READ_WAREHOUSE = 221000;
export const CREATE_WAREHOUSE = 221001;
export const UPDATE_WAREHOUSE = 221002;
export const ARCHIVE_WAREHOUSE = 221004;
export const DELETE_WAREHOUSE = 221003;
export const UNARCHIVE_WAREHOUSE = 221005;