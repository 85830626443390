import { schema } from "normalizr";
import { EarningTypeArraySchema } from "../../earning-type/schema";
import { DeductionTypeArraySchema } from "../../deduction-type/schema";

const SalaryStructureSchema = new schema.Entity("salaryStructures", {
  earningTypes: EarningTypeArraySchema,
  deductionTypes: DeductionTypeArraySchema,
});

const SalaryStructureArraySchema = new schema.Array(SalaryStructureSchema);

export { SalaryStructureSchema, SalaryStructureArraySchema };