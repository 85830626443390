import { POSTWithToken } from "./base-service";

export function importList(module, values) {
  const url = `/api/${module}/v1/import`;
  return POSTWithToken(url, values);
}

export function createBatch(module, values) {
  const url = `/api/${module}/v1/create-batch`;
  return POSTWithToken(url, values);
}