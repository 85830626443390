import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import classnames from "classnames";
import { connect } from "react-redux";
import CurrencyInput from "react-currency-input";
import { Form, message as AntMessage } from "antd";
import CurrencyFormat from "react-currency-format";

// Constants
import {
  CURRENCY_SYMBOL_POSITION_FRONT_ID,
  CURRENCY_SYMBOL_POSITION_BACK_ID,
} from "@modules/settings/setup/currency/constants";
import { getDefaultCurrency } from "@modules/utils/value-selector";

const FormItem = Form.Item;

class InputCurrencyField extends Component {

  onChange = (symbolValue, value) => {
    const {
      input: { onChange },
      handleChange,
      min,
      max,
      maxMessage,
      minMessage,
      showErrorMessage,
    } = this.props;
    if ((max == 0 || max) && max < value) {
      let message = `value can not greater than ${max}`;
      if (maxMessage) {
        if (typeof (maxMessage) == "function") {
          message = maxMessage(max, value);
        } else {
          message = maxMessage;
        }
      }
      if (showErrorMessage) {
        AntMessage.error(message);
      }
      value = max;
      this.forceUpdate();
    }
    if ((min == 0 || min) && min > value) {
      let message = `value can not smaller than ${min}`;
      if (minMessage) {
        message = maxMessage(min, value);
      }
      if (showErrorMessage) {
        AntMessage.error(message);
      }
      value = min;
      this.forceUpdate();
    }
    onChange(value);
    if (handleChange) {
      handleChange(value);
    }
  }

  onBlur = () => {
    const { input: { onBlur } } = this.props;
    if (this.props.onBlur) {
      this.props.onBlur();
      onBlur();
    }
  }

  onFocus = () => {
    const { input: { onFocus } } = this.props;
    if (this.props.onFocus) {
      this.props.onFocus();
      onFocus();
    }
  }

  render() {
    const {
      label,
      required,
      autoFocus,
      style,
      size,
      className,
      precision,
      allowNegative,
      shouldDisplaySymbol,
      input: { value },
      meta: { touched, error },
      currencyConfiguration: {
        precision: defaultPrecision,
        symbol,
        symbolPosition,
      },
      ...props
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    const inputClassName = classnames(size == "large" ? "ant-input ant-input-lg" : "ant-input");
    const newValue = value != "" ? value : (value == "0" ? 0 : null);
    return (
      <FormItem
        help={error}
        label={label}
        required={required}
        validateStatus={validateStatus}
      >
        <CurrencyInput
          decimal="."
          separator=","
          value={newValue}
          autoFocus={autoFocus}
          style={style}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          allowNegative={allowNegative}
          showSymbol={shouldDisplaySymbol}
          className={`${className} ${inputClassName}`}
          precision={precision || defaultPrecision}
          prefix={symbolPosition == CURRENCY_SYMBOL_POSITION_FRONT_ID ? `${symbol} ` : ""}
          suffix={symbolPosition == CURRENCY_SYMBOL_POSITION_BACK_ID ? ` ${symbol}` : ""}
          {...props}
        />
      </FormItem>
    );
  }
}

InputCurrencyField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  size: PropTypes.any,
  minMessage: PropTypes.any,
  maxMessage: PropTypes.any,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  precision: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  allowNegative: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  shouldDisplaySymbol: PropTypes.bool,
  currencyConfiguration: PropTypes.object,
  autoFocus: PropTypes.bool,
};

InputCurrencyField.defaultProps = {
  required: false,
  shouldDisplaySymbol: true,
  allowNegative: false,
};

class InputCurrencyNoPrecisionField extends Component {

  onValueChange = ({ formattedValue, value, floatValue }) => {
    const {
      input: { onChange },
      handleChange,
      min,
      max,
      maxMessage,
      minMessage,
      showErrorMessage,
    } = this.props;
    floatValue = !value || !value.length || value[value.length - 1] === "." ? value : parseFloat(value);
    if (max && max < floatValue) {
      let message = `value can not greater than ${max}`;
      if (maxMessage) {
        if (typeof (maxMessage) == "function") {
          message = maxMessage(max, floatValue);
        } else {
          message = maxMessage;
        }
      }
      if (showErrorMessage) {
        AntMessage.error(message);
      }
      floatValue = max;
    }
    if (min && min > floatValue) {
      let message = `value can not smaller than ${min}`;
      if (minMessage) {
        message = maxMessage(min, floatValue);
      }
      if (showErrorMessage) {
        AntMessage.error(message);
      }
      floatValue = min;
    }
    onChange(floatValue);
    if (handleChange) {
      handleChange(floatValue, formattedValue, value);
    }
  }

  onBlur = () => {
    const { input: { onChange, value, onBlur } } = this.props;
    onChange(value);
    onBlur();
  }

  onFocus = () => {
    const { input: { onFocus } } = this.props;
    onFocus();
  }

  render() {
    const {
      label,
      required,
      style,
      size,
      disabled,
      className,
      allowNegative,
      autoFocus,
      input: { value },
      meta: { touched, error },
      currencyConfiguration: {
        symbol,
        symbolPosition,
      },
    } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    const inputClassName = classnames(size == "large" ? "ant-input ant-input-lg" : "ant-input");
    return (
      <FormItem
        label={label}
        validateStatus={validateStatus}
        help={error}
        required={required}
      >
        <CurrencyFormat
          disabled={disabled}
          thousandSeparator
          value={value}
          style={style}
          className={`${className} ${inputClassName}`}
          prefix={symbolPosition == CURRENCY_SYMBOL_POSITION_FRONT_ID ? `${symbol} ` : ""}
          suffix={symbolPosition == CURRENCY_SYMBOL_POSITION_BACK_ID ? ` ${symbol}` : ""}
          onValueChange={this.onValueChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          decimalSeparator="."
          allowNegative={allowNegative}
          autoFocus={autoFocus}
        />
      </FormItem>
    );
  }
}

InputCurrencyNoPrecisionField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  minMessage: PropTypes.any,
  maxMessage: PropTypes.any,
  onBlur: PropTypes.func,
  precision: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  allowNegative: PropTypes.bool,
  disabled: PropTypes.bool,
  currencyConfiguration: PropTypes.object,
  showErrorMessage: PropTypes.bool,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
};

InputCurrencyNoPrecisionField.defaultProps = {
  required: false,
  allowNegative: false,
  showErrorMessage: false,
};

class InputCurrency extends Component {

  render() {
    const { currencyConfiguration, hasPrecision, ...props } = this.props;
    return (
      <Field
        currencyConfiguration={currencyConfiguration}
        component={hasPrecision ? InputCurrencyField : InputCurrencyNoPrecisionField}
        {...props}
      />
    );
  }
}

InputCurrency.propTypes = {
  hasPrecision: PropTypes.bool,
  currencyConfiguration: PropTypes.object,
};

InputCurrency.defaultProps = {
  hasPrecision: true
};

const mapStateToProps = (state, ownProps) => {
  const { currency } = ownProps;
  return {
    currencyConfiguration: currency || getDefaultCurrency(state),
  };
};

export default connect(mapStateToProps)(InputCurrency);