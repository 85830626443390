import React, { Fragment } from "react";
import {
  BrowserRouter,
  Router as MainRouter
} from "react-router-dom";
import PropTypes from "prop-types";
import { Spin, message } from "antd";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import tinycolor from "tinycolor2";

// Components
import Router from "../routes";
import CompanyNotFound from "@/components/company-not-found";

// utils
import localization from "@/localization";
import { FIND_COMPANY_REQUEST } from "@/constants/company";
import { getLoading, getError } from "@modules/utils/value-selector";
import { getCompanyInfo } from "../actions/authentication";
import { history } from "../shared/utils/history";

// Theme
import lightVars from "@/themes/light.json";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      vars: {},
      themeApplied: false,
    };
    this.getCompanyInfo();
  }

  onSetupTheme = (primary) => {
    if (primary) {
      let { vars } = this.state;
      let initialValue = lightVars;
      const theme = localStorage.getItem("theme-name");
      try {
        vars = localStorage.getItem("app-theme");
        if (!vars) {
          vars = initialValue;
        } else {
          vars = Object.assign(
            {},
            JSON.parse(vars)
          );
        }
      } catch (e) {
        vars = initialValue;
      } finally {
        vars["@primary-color"] = primary;
        vars["@link-color"] = theme == "dark" ? tinycolor(primary).isDark() ? "#FFFFFF" : primary : primary,
        window.less
          .modifyVars(vars)
          .then(() => {
            this.setState({
              themeApplied: true,
              vars,
            });
          })
          .catch((error) => {
            message.error(error.message);
          });
      }
    }
  };

  getCompanyInfo = () => {
    const { getCompanyInfoBySubDomain, subDomain } = this.props;
    getCompanyInfoBySubDomain(subDomain);
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.company) {
      if ((newProps.company != null && this.props.company != newProps.company) || (this.props.company.navBackground) != (newProps.company.navBackground)) {
        this.onSetupTheme(newProps.company.navBackground);
      }
    }
  }

  render() {
    const { loadingCompanyInfo, companyError, subDomain, locale, company } = this.props;
    return (
      <BrowserRouter>
        <Fragment>
          <MainRouter history={history}>
            <IntlProvider
              locale={locale}
              messages={localization[locale]}
            >
              <>
                {loadingCompanyInfo == true && companyError == null && company == null &&
                  <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                    <Spin size="large" tip="We're waking everyone up to start working" />
                  </div>
                }
                {companyError && 
                  <CompanyNotFound
                    status={companyError.statusCode}
                    name={subDomain}
                    backTo={"/"}
                  />
                }
                {company != null &&
                  <Router />
                }
              </>
            </IntlProvider>
          </MainRouter>
        </Fragment>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  locale: PropTypes.string,
  company: PropTypes.object,
  companyError: PropTypes.any,
  subDomain: PropTypes.string,
  isAuthenticating: PropTypes.bool,
  loadingCompanyInfo: PropTypes.bool,
  getCompanyInfoBySubDomain: PropTypes.func,
  authenticateClientCredentialIfNeeded: PropTypes.func,
};

const mapStateToProps = (state) => {
  const subDomain = location.host.split(".")[0];
  return {
    subDomain,
    locale: state.locale.locale,
    company: state.company[subDomain],
    companyError: getError(state, FIND_COMPANY_REQUEST),
    loadingCompanyInfo: getLoading(state, FIND_COMPANY_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyInfoBySubDomain: (subDomain) => dispatch(getCompanyInfo(subDomain)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);