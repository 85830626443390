export const CURRENCY_LIST_REQUEST = "CURRENCY_LIST_REQUEST";
export const CURRENCY_LIST_SUCCESS = "CURRENCY_LIST_REQUEST_SUCCESS";
export const CURRENCY_AUTOCOMPLETE_REQUEST = "CURRENCY_AUTOCOMPLETE_REQUEST";
export const CURRENCY_AUTOCOMPLETE_SUCCESS = "CURRENCY_AUTOCOMPLETE_SUCCESS";
export const CURRENCY_AUTOCOMPLETE_FROM_CURRENCY_REQUEST = "CURRENCY_AUTOCOMPLETE_FROM_CURRENCY_REQUEST";
export const CURRENCY_AUTOCOMPLETE_FORM_CURRENCY_SUCCESS = "CURRENCY_AUTOCOMPLETE_FROM_CURRENCY_SUCCESS";
export const CURRENCY_AUTOCOMPLETE_TO_CURRENCY_REQUEST = "CURRENCY_AUTOCOMPLETE_TO_CURRENCY_REQUEST";
export const CURRENCY_AUTOCOMPLETE_TO_CURRENCY_SUCCESS = "CURRENCY_AUTOCOMPLETE_TO_CURRENCY_SUCCESS";
export const CURRENCY_CREATE_REQUEST = "CURRENCY_CREATE_REQUEST";
export const CURRENCY_DETAIL_REQUEST = "CURRENCY_DETAIL_REQUEST";
export const CURRENCY_ARCHIVE_REQUEST = "CURRENCY_ARCHIVE_REQUEST";
export const CURRENCY_DELETE_REQUEST = "CURRENCY_DELETE_REQUEST";
export const CURRENCY_UNARCHIVE_REQUEST = "CURRENCY_UNARCHIVE_REQUEST";
export const CURRENCY_EDIT_REQUEST = "CURRENCY_EDIT_REQUEST";
export const CURRENCY_CREATE_FORM_NAME = "createCurrency";
export const CURRENCY_EDIT_FORM_NAME = "editCurrency";
export const CURRENCY_SUBMIT_RULES = {
  name: {
    required: true,
  },
  symbol: {
    required: true,
  },
  symbolPosition: {
    required: true,
  },
  code: {
    required: true,
  },
  precision: {
    required: true,
  },
  roundingMethod: {
    required: true,
  },
};
export const READ_CURRENCY = 105000;
export const CREATE_CURRENCY = 105001;
export const UPDATE_CURRENCY = 105002;
export const DELETE_CURRENCY = 105003;
export const ARCHIVE_CURRENCY = 105004;
export const UNARCHIVE_CURRENCY = 105005;

export const CURRENCY_SYMBOL_POSITION_FRONT_ID = 1;
export const CURRENCY_SYMBOL_POSITION_BACK_ID = 2;
export const CURRENCY_SYMBOL_POSITION_FRONT_NAME = "Front";
export const CURRENCY_SYMBOL_POSITION_BACK_NAME = "Back";
export const CURRENCY_SYMBOL_POSITION_DATASOURCE = [
  {
    id: CURRENCY_SYMBOL_POSITION_FRONT_ID,
    name: CURRENCY_SYMBOL_POSITION_FRONT_NAME
  },
  {
    id: CURRENCY_SYMBOL_POSITION_BACK_ID,
    name: CURRENCY_SYMBOL_POSITION_BACK_NAME,
  }
];

export const CURRENCY_ROUNDING_METHOD_UP_ID = 1;
export const CURRENCY_ROUNDING_METHOD_DOWN_ID = 2;
export const CURRENCY_ROUNDING_METHOD_UP_NAME = "Rounding Up";
export const CURRENCY_ROUNDING_METHOD_DOWN_NAME = "Rounding Down";
export const CURRENCY_ROUNDING_METHOD_DATASOURCE = [
  {
    id: CURRENCY_ROUNDING_METHOD_UP_ID,
    name: CURRENCY_ROUNDING_METHOD_UP_NAME
  },
  {
    id: CURRENCY_ROUNDING_METHOD_DOWN_ID,
    name: CURRENCY_ROUNDING_METHOD_DOWN_NAME,
  }
];

export const CURRENCY_SYMBOL_POSITION_TIP = "A position of symbol to display. E.g";