import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { reduxForm } from "redux-form";
import { Form, Button, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

class PreviewForm extends React.Component {

  state = {
    selectedValues: [],
  }

  renderTableComponent = (dataSource, defaultExpandAllRows, rowSelection) => {
    const { childrenColumnName, columns } = this.props;
    return (
      <Table 
        rowKey="key"
        size="middle"
        pagination={false}
        scroll={{ y: 650 }}
        dataSource={dataSource} 
        rowSelection={rowSelection}
        className="table-responsive"
        columns={columns(dataSource)} 
        childrenColumnName={childrenColumnName}
        defaultExpandAllRows={defaultExpandAllRows}
      />
    );
  }

  onChange = (_, selectedValues) => {
    this.setState({
      selectedValues,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { selectedValues } = this.state;
    if (selectedValues.length > 0) {
      if (onSubmit) {
        onSubmit(selectedValues);
      }
    }
  }

  render() {
    let { 
      loading,
      treeTable, 
      dataSource,
      newDataSource,
      childrenColumnName, 
      defaultExpandAllRows,
    } = this.props;
    if (treeTable) {
      if (newDataSource) {
        dataSource = newDataSource(dataSource);
      } else {
        dataSource.map((objectEntity, index) => {
          objectEntity.key = index;
          if (childrenColumnName) {
            const newObjectEntity = objectEntity[childrenColumnName];
            newObjectEntity && newObjectEntity.map((childrenObjectEntity, childrenIndex) => {
              childrenObjectEntity.key = `${index}-${childrenIndex}`;
            });
          }
        });
      }
    } else {
      if (newDataSource) {
        dataSource = newDataSource(dataSource);
      }
      dataSource.map((objectEntity, index) => {
        objectEntity.key = index;
      });
    }
    const rowSelection = {
      onChange: this.onChange,
    };
    const { selectedValues } = this.state;
    return (
      <Form onFinish={this.onSubmit} layout="vertical">
        <Row>
          <Col md="12">
            {defaultExpandAllRows && treeTable ? (
              dataSource && dataSource.length > 0 && (
                this.renderTableComponent(dataSource, true, rowSelection)
              )
            ) : (
              this.renderTableComponent(dataSource, false, rowSelection)
            )}
          </Col>
          <Col md="12">
            <Button
              ghost
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<DownloadOutlined />}
              disabled={selectedValues.length == 0}
              className="float-right mt-1"
            >
              Import
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

PreviewForm.propTypes = {
  columns: PropTypes.any,
  loading: PropTypes.bool,
  treeTable: PropTypes.bool,
  onSubmit: PropTypes.func,
  newDataSource: PropTypes.func,
  dataSource: PropTypes.array,
  childrenColumnName: PropTypes.string,
  defaultExpandAllRows: PropTypes.string,
};

export default reduxForm()(PreviewForm);