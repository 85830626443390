export const ITEM_SPECIFICATION_LIST_REQUEST = "ITEM_SPECIFICATION_LIST_REQUEST"; 
export const ITEM_SPECIFICATION_LIST_SUCCESS = "ITEM_SPECIFICATION_LIST_REQUEST_SUCCESS";

export const ITEM_SPECIFICATION_CREATE_REQUEST = "ITEM_SPECIFICATION_CREATE_REQUEST"; 
export const ITEM_SPECIFICATION_DETAIL_REQUEST = "ITEM_SPECIFICATION_DETAIL_REQUEST"; 
export const ITEM_SPECIFICATION_ARCHIVE_REQUEST = "ITEM_SPECIFICATION_ARCHIVE_REQUEST"; 
export const ITEM_SPECIFICATION_DELETE_REQUEST = "ITEM_SPECIFICATION_DELETE_REQUEST"; 

export const ITEM_SPECIFICATION_UNARCHIVE_REQUEST = "ITEM_SPECIFICATION_UNARCHIVE_REQUEST"; 
export const ITEM_SPECIFICATION_EDIT_REQUEST = "ITEM_SPECIFICATION_EDIT_REQUEST"; 
export const ITEM_SPECIFICATION_AUTO_COMPLETE_REQUEST = "ITEM_SPECIFICATION_AUTO_COMPLETE_REQUEST"; 

export const ITEM_SPECIFICATION_CREATE_FORM_NAME = "createItemSpecification"; 
export const ITEM_SPECIFICATION_EDIT_FORM_NAME = "editItemSpecification";

export const ITEM_SPECIFICATION_AUTOCOMPLETE_REQUEST = "ITEM_SPECIFICATION_AUTOCOMPLETE_REQUEST";
export const ITEM_SPECIFICATION_AUTOCOMPLETE_SUCCESS = "ITEM_SPECIFICATION_AUTOCOMPLETE_SUCCESS";

export const ITEM_SPECIFICATION_SUBMIT_RULES = {
  name: {
    required: true,
  },
};

export const READ_ITEM_SPECIFICATION = 269000;
export const CREATE_ITEM_SPECIFICATION = 269001;
export const UPDATE_ITEM_SPECIFICATION = 269002;
export const DELETE_ITEM_SPECIFICATION = 269003;
export const ARCHIVE_ITEM_SPECIFICATION = 269004;
export const UNARCHIVE_ITEM_SPECIFICATION = 269005;