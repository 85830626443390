import { schema } from "normalizr";
import { RoleSchema } from "@modules/settings/user-management/role/schema";

const UserRoleSchema = new schema.Entity("userRoles", {
  role: RoleSchema
});

const UserRoleArraySchema = new schema.Array(UserRoleSchema);

export {
  UserRoleSchema,
  UserRoleArraySchema
};