export const OFFER_LETTER_LIST_REQUEST = "OFFER_LETTER_LIST_REQUEST"; 
export const OFFER_LETTER_LIST_SUCCESS = "OFFER_LETTER_LIST_REQUEST_SUCCESS";
export const OFFER_LETTER_CREATE_REQUEST = "OFFER_LETTER_CREATE_REQUEST"; 
export const OFFER_LETTER_DETAIL_REQUEST = "OFFER_LETTER_DETAIL_REQUEST"; 
export const OFFER_LETTER_ARCHIVE_REQUEST = "OFFER_LETTER_ARCHIVE_REQUEST"; 
export const OFFER_LETTER_DELETE_REQUEST = "OFFER_LETTER_DELETE_REQUEST"; 
export const OFFER_LETTER_UNARCHIVE_REQUEST = "OFFER_LETTER_UNARCHIVE_REQUEST"; 
export const OFFER_LETTER_EDIT_REQUEST = "OFFER_LETTER_EDIT_REQUEST"; 
export const OFFER_LETTER_CREATE_FORM_NAME = "createOfferLetter"; 
export const OFFER_LETTER_EDIT_FORM_NAME = "editOfferLetter"; 
export const OFFER_LETTER_AUTOCOMPLETE_REQUEST = "OFFER_LETTER_AUTOCOMPLETE_REQUEST";
export const OFFER_LETTER_AUTOCOMPLETE_SUCCESS = "OFFER_LETTER_AUTOCOMPLETE_SUCCESS";
export const OFFER_LETTER_SUBMIT_RULES = { 
  jobApplicantId: {
    required: true
  },
  offerDate: {
    required: true
  },
  offerLetterTerms: {
    required: true,
    type: "arrayInline",
    subField: [
      {
        name: "offerTermId",
      },
      {
        name: "value"
      }
    ]
  },
  termAndCondition: {
    required: true
  }
};
export const READ_OFFER_LETTER = 162000;
export const CREATE_OFFER_LETTER = 162001;
export const UPDATE_OFFER_LETTER = 162002;
export const DELETE_OFFER_LETTER = 162003;
export const ARCHIVE_OFFER_LETTER = 162004;
export const UNARCHIVE_OFFER_LETTER = 162005;
export const PRINT_OFFER_LETTER = 162007;
export const SUBMIT_OFFER_LETTER = 162011;
export const ACCEPT_OFFER_LETTER = 162012;
export const CANCEL_OFFER_LETTER = 162013;
export const REJECT_OFFER_LETTER = 162014;

export const OFFER_LETTER_STATUS_DRAFT = 1;
export const OFFER_LETTER_STATUS_DRAFT_TITLE = "Draft";
export const OFFER_LETTER_STATUS_SUBMIT = 2;
export const OFFER_LETTER_STATUS_SUBMIT_TITLE = "Submitted";
export const OFFER_LETTER_STATUS_ACCEPT = 3;
export const OFFER_LETTER_STATUS_ACCEPT_TITLE = "Accepted";
export const OFFER_LETTER_STATUS_REJECT = 4;
export const OFFER_LETTER_STATUS_REJECT_TITLE = "Rejected";
export const OFFER_LETTER_STATUS_CANCEL = 5;
export const OFFER_LETTER_STATUS_CANCEL_TITLE = "Cancelled";


export const OFFER_LETTER_STATUS = [
  {
    id: OFFER_LETTER_STATUS_DRAFT,
    name: OFFER_LETTER_STATUS_DRAFT_TITLE
  },
  {
    id: OFFER_LETTER_STATUS_SUBMIT,
    name: OFFER_LETTER_STATUS_SUBMIT_TITLE,
  },
  {
    id: OFFER_LETTER_STATUS_ACCEPT,
    name: OFFER_LETTER_STATUS_ACCEPT_TITLE,
  },
  {
    id: OFFER_LETTER_STATUS_REJECT,
    name: OFFER_LETTER_STATUS_REJECT_TITLE,
  },
  {
    id: OFFER_LETTER_STATUS_CANCEL,
    name: OFFER_LETTER_STATUS_CANCEL_TITLE,
  }
];

export const OFFER_LETTER_STATUS_SUBMITTED_REQUEST = "OFFER_LETTER_STATUS_SUBMITTED_REQUEST";
export const OFFER_LETTER_STATUS_ACCEPTED_REQUEST = "OFFER_LETTER_STATUS_ACCEPTED_REQUEST";
export const OFFER_LETTER_STATUS_REJECTED_REQUEST = "OFFER_LETTER_STATUS_REJECTED_REQUEST";
export const OFFER_LETTER_STATUS_CANCELLED_REQUEST = "OFFER_LETTER_STATUS_CANCELLED_REQUEST";

export const OFFER_LETTER_TERM_FIELD_NAME = "offerLetterTerms";