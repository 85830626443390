import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

// Components
import Input from "@cores/components/Input";
import Editor from "@cores/components/Editor";
import Title from "@cores/components/title";
import EmployeeLanguage from "../form-component/employee-language";
import EmployeeEducation from "../form-component/employee-education";
import EmployeeWorkExperience from "../form-component/employee-work-experience";
import NationalityAutoComplete from "@cores/components/auto-complete/nationality";
import IdentificationTypeAutoComplete from "@cores/components/auto-complete/identification-type";

class Bio extends Component {

  render() {
    const { 
      editable,
      openModalEdit,
      openModalCreate,
      onSaveAndContinueEditComponent: OnSaveAndContinueEditComponent, 
    } = this.props;
    return (
      <Fragment>
        {!editable ? (
          <OnSaveAndContinueEditComponent />
        ) : (
          <Row>
            <Col xs="12">
              <Title title="Identification" />
            </Col>
            <Col md="6" xs="12">
              <NationalityAutoComplete />
            </Col>
            <Col md="6" xs="12">
              <IdentificationTypeAutoComplete />
            </Col>
            <Col md="6" xs="12">
              <Input 
                name="identificationNo"
                label="Identification No"
              />
            </Col>
            <Col xs="12">
              <Title title="Bio" />
            </Col>
            <Col xs="12">
              <Editor name="biography" />
            </Col>
            <Col xs="12">
              <Title title="Education" />
            </Col>
            <Col xs="12">
              <EmployeeEducation 
                openModalEdit={openModalEdit}
                openModalCreate={openModalCreate}
              />
            </Col>
            <Col xs="12">
              <Title title="Work Experience" />
            </Col>
            <Col xs="12">
              <EmployeeWorkExperience 
                openModalEdit={openModalEdit}
                openModalCreate={openModalCreate}
              />
            </Col>
            <Col xs="12">
              <Title title="Language" />
            </Col>
            <Col xs="12">
              <EmployeeLanguage 
                openModalEdit={openModalEdit}
                openModalCreate={openModalCreate}
              />
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

Bio.propTypes = {
  editable: PropTypes.bool,
  openModalEdit: PropTypes.func,
  openModalCreate: PropTypes.func,
  onSaveAndContinueEditComponent: PropTypes.any,
};

export default Bio;