export const SUPPLIER_TYPE_LIST_REQUEST = "SUPPLIER_TYPE_LIST_REQUEST"; 
export const SUPPLIER_TYPE_LIST_SUCCESS = "SUPPLIER_TYPE_LIST_REQUEST_SUCCESS";
export const SUPPLIER_TYPE_CREATE_REQUEST = "SUPPLIER_TYPE_CREATE_REQUEST"; 
export const SUPPLIER_TYPE_DETAIL_REQUEST = "SUPPLIER_TYPE_DETAIL_REQUEST"; 
export const SUPPLIER_TYPE_ARCHIVE_REQUEST = "SUPPLIER_TYPE_ARCHIVE_REQUEST"; 
export const SUPPLIER_TYPE_DELETE_REQUEST = "SUPPLIER_TYPE_DELETE_REQUEST"; 
export const SUPPLIER_TYPE_UNARCHIVE_REQUEST = "SUPPLIER_TYPE_UNARCHIVE_REQUEST"; 
export const SUPPLIER_TYPE_EDIT_REQUEST = "SUPPLIER_TYPE_EDIT_REQUEST"; 
export const SUPPLIER_TYPE_CREATE_FORM_NAME = "createSupplierType"; 
export const SUPPLIER_TYPE_EDIT_FORM_NAME = "editSupplierType"; 
export const SUPPLIER_TYPE_AUTOCOMPLETE_REQUEST = "SUPPLIER_TYPE_AUTOCOMPLETE_REQUEST";
export const SUPPLIER_TYPE_AUTOCOMPLETE_SUCCESS = "SUPPLIER_TYPE_AUTOCOMPLETE_SUCCESS";
export const SUPPLIER_TYPE_SUBMIT_RULES = {
  name: {
    required: true,
  },
  status: {
    required: true,
  }
};
export const SUPPLIER_TYPE_STATUS = [
  {
    id: 1,
    name: "Active"
  },
  {
    id:  -1,
    name: "Deactive"
  },
];

export const READ_SUPPLIER_TYPE = 209000;
export const CREATE_SUPPLIER_TYPE = 209001;
export const UPDATE_SUPPLIER_TYPE = 209002;
export const DELETE_SUPPLIER_TYPE = 209003;
export const ARCHIVE_SUPPLIER_TYPE = 209004;
export const UNARCHIVE_SUPPLIER_TYPE = 209005;