import * as Constants from "../constants";

const initialState = {
  id: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
  case Constants.POS_SESSION_SUCCESS:
    return {
      ...state,
      id: action.id
    };
  default:
    return state;
  }
}