// Libs
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { reduxForm, SubmissionError } from "redux-form";

// Component
import Input from "@modules/cores/components/Input";

// Helper
import { validate } from "src/helpers";
import { resetPassword } from "src/services/authentication";


export const RESET_PASSWORD_REQUEST_RULES = (values) => {
  const { password } = values;
  const rules = {
    password: {
      required: true,
      type: "password"
    },
    confirmPassword: {
      required: true,
    },
  };
  rules.confirmPassword["equalsTo"] = {
    name: "password",
    value: password,
    message: "Both passwords do not match"
  };
  return rules;
};

function ResetPasswordForm(props) {

  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { code } = props;

  const onFinish = async (values) => {
    let error = null;
    error = validate(RESET_PASSWORD_REQUEST_RULES(values), values);
    if (error != null) {
      throw new SubmissionError(error);
    } else {
      setLoading(true);
      const newValues = {...values};
      newValues.code = code;
      await resetPassword(newValues).then(() => {
        setLoading(false);
        message.success("Password is reset successfully");
        history.push("/login");
      }).catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
    }
  };

  const { handleSubmit } = props;
  return (
    <>
      <Form onFinish={handleSubmit(onFinish)} layout="vertical">
        <Input
          type="Password"
          name="password"
          placeholder="Password"
        />
        <Input
          type="Password"
          name="confirmPassword"
          placeholder="Confirm Password"
        />
        <Button
          block
          ghost
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Reset
        </Button>
      </Form>
    </>
  );
}

ResetPasswordForm.propTypes = {
  code: PropTypes.number,
  handleSubmit: PropTypes.func,
};

const mapStateToProps = () => {
  const subDomain = location.host.split(".")[0];
  return {
    initialValues: {
      subDomain,
    }
  };
};

export default connect(mapStateToProps)(reduxForm({
  enableReinitialize: true,
})(ResetPasswordForm));