export const INCENTIVE_TEMPLATE_LIST_REQUEST = "INCENTIVE_TEMPLATE_LIST_REQUEST"; 
export const INCENTIVE_TEMPLATE_LIST_SUCCESS = "INCENTIVE_TEMPLATE_LIST_REQUEST_SUCCESS";
export const INCENTIVE_TEMPLATE_CREATE_REQUEST = "INCENTIVE_TEMPLATE_CREATE_REQUEST"; 
export const INCENTIVE_TEMPLATE_DETAIL_REQUEST = "INCENTIVE_TEMPLATE_DETAIL_REQUEST"; 
export const INCENTIVE_TEMPLATE_ARCHIVE_REQUEST = "INCENTIVE_TEMPLATE_ARCHIVE_REQUEST"; 
export const INCENTIVE_TEMPLATE_DELETE_REQUEST = "INCENTIVE_TEMPLATE_DELETE_REQUEST"; 
export const INCENTIVE_TEMPLATE_UNARCHIVE_REQUEST = "INCENTIVE_TEMPLATE_UNARCHIVE_REQUEST"; 
export const INCENTIVE_TEMPLATE_EDIT_REQUEST = "INCENTIVE_TEMPLATE_EDIT_REQUEST"; 

export const INCENTIVE_TEMPLATE_CREATE_FORM_NAME = "createIncentiveTemplate"; 
export const INCENTIVE_TEMPLATE_EDIT_FORM_NAME = "editIncentiveTemplate"; 
export const INCENTIVE_TEMPLATE_FIELD_NAME = "employeePayrollIncentives";

export const INCENTIVE_TEMPLATE_AUTOCOMPLETE_REQUEST = "INCENTIVE_TEMPLATE_AUTOCOMPLETE_REQUEST";
export const INCENTIVE_TEMPLATE_AUTOCOMPLETE_SUCCESS = "INCENTIVE_TEMPLATE_AUTOCOMPLETE_SUCCESS";

export const INCENTIVE_TEMPLATE_SUBMIT_RULES = { 
  name: {
    required: true,
  },
};
export const READ_INCENTIVE_TEMPLATE = 244000;
export const CREATE_INCENTIVE_TEMPLATE = 244001;
export const UPDATE_INCENTIVE_TEMPLATE = 244002;
export const DELETE_INCENTIVE_TEMPLATE = 244003;
export const ARCHIVE_INCENTIVE_TEMPLATE = 244004;
export const UNARCHIVE_INCENTIVE_TEMPLATE = 244005;