import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import classnames from "classnames";
import { Select, Form } from "antd";
import { FormText } from "reactstrap";

// Style
import "./index.less";

const FormItem = Form.Item;
const Option = Select.Option;

class AutoCompleteStaticField extends Component {

  onChange = (value) => {
    value = value == undefined ? null : value;
    const { 
      onChange,
      dataSource,
      onSelectChange,
      input: { onChange: reduxFormChange },
    } = this.props;
    reduxFormChange(value);
    if (onChange) {
      onChange(value);
    }
    if (onSelectChange) {
      const objectValue = _.find(dataSource, (val) => val.id === value);
      onSelectChange(objectValue);
    }
  }

  renderValues = () => {
    const { mode } = this.props;
    let { input: { value }, dataSource, stringValue } = this.props;
    if (mode == "multiple") {
      value = (value && dataSource && dataSource.length > 0) ? value : [];
      value = value ? value : undefined;
      if (value.length > 0) {
        value = value.map(val => Number(val));
      }
    } else {
      value = (value && dataSource && dataSource.length > 0) ? value : undefined;
      value = typeof(value) == "string" ? stringValue ? value : Number(value) : value;
    }
    return value;
  }

  render() {
    const { 
      tip,
      size,
      placeholder,
      label,
      required,
      mode,
      disabled,
      titleKey,
      valueKey,
      optionRender,
      withFilter,
      autoFocus,
      className,
      unselectable,
      defaultValue,
      existingData,
      selectedValue,
      addonBefore,
      addonAfter,
      meta: { touched, error },
    } = this.props;
    let { style, dataSource } = this.props;
    const validateStatus = classnames(touched && error ? "error" : "success");
    const addOnClassName = classnames((addonBefore != null || addonAfter != null) && "ant-input-group");
    const values = this.renderValues();
    if (label == null && withFilter) {
      const defaultStyle = {
        width: "auto",
        minWidth: "150px"
      };
      style = Object.assign(defaultStyle, style);
    }
    if (existingData && existingData.length > 0) {
      dataSource = dataSource.filter(function(item) {
        const matchedData  = existingData.find(function(data) {
          const existed = item.id === (data.existedKey || data.id);
          return existed;
        });
        return !matchedData;
      });
    }
    if (selectedValue && selectedValue.length > 0) {
      dataSource = dataSource.concat(selectedValue);
    }
    dataSource = _.uniqBy(dataSource, "id");
    return (
      <Fragment>
        <FormItem
          label={label} 
          validateStatus={validateStatus}
          help={error}
          required={required}
        >
          <span className="ant-input-group-wrapper">
            <span className={`ant-input-wrapper ${addOnClassName}`}>
              {addonBefore && (
                <span className="ant-input-group-addon">{addonBefore}</span>
              )}
              <Select
                showSearch
                style={style}
                className={className}
                placeholder={placeholder}
                optionFilterProp="children"
                onChange={this.onChange}
                value={values == undefined ? defaultValue : values}
                mode={mode}
                size={size}
                disabled={disabled}
                autoFocus={autoFocus}
                allowClear={!required}
                filterOption={(input, option) => 
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dataSource.map((value, index) => {
                  if (typeof value == "string") {
                    return (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    );
                  } else {
                    return (
                      <Option key={index} value={value[valueKey]} disabled={value[valueKey] == unselectable}>
                        {typeof optionRender != "function"
                          ? value[titleKey]
                          : optionRender(value)}
                      </Option>
                    );
                  }
                })}
              </Select>
              {addonAfter && (
                <span className="ant-input-group-addon">
                  {addonAfter}
                </span>
              )}
            </span>
          </span>
        </FormItem>
        {tip && (
          <FormText
            color="muted"
            className="mt-0 mb-2"
          >
            {tip}
          </FormText>
        )}
      </Fragment>
    );
  }
}

AutoCompleteStaticField.propTypes = {
  tip: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
  onChange: PropTypes.func,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  valueKey: PropTypes.string,
  titleKey: PropTypes.string,
  optionRender: PropTypes.func,
  style: PropTypes.object,
  withFilter: PropTypes.bool,
  stringValue: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  unselectable: PropTypes.number,
  onSelectChange: PropTypes.func,
  size: PropTypes.any,
  defaultValue: PropTypes.any,
  existingData: PropTypes.array,
  selectedValue: PropTypes.array,
  addonBefore: PropTypes.any,
  addonAfter: PropTypes.any,
};

AutoCompleteStaticField.defaultProps = {
  valueKey: "id",
  titleKey: "name",
  mode: "single",
  existingData: [],
  selectedValue: [],
};

class StaticAutoComplete extends Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field 
        component={AutoCompleteStaticField}
        {...props}
      />
    );
  }
}

export default StaticAutoComplete;