import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { denormalize } from "normalizr";

// Actions
import { getLeaveRequestDetail } from "@modules/hr/leave/leave-request/actions";
import { LeaveRequestSchema } from "@modules/hr/leave/leave-request/schema";
import DetailComponent from "@modules/hr/leave/leave-request/components/detail-component";

class LeaveRequestDetail extends React.Component {

  constructor(props) {
    super(props);
    this.getDetailData();
  }

  getDetailData = () => {
    const { loadData }= this.props;
    loadData();
  }

  render() {
    const { id, entities } = this.props;
    const objectEntities = denormalize(id, LeaveRequestSchema, entities);
    return (
      <Fragment>
        {objectEntities != null && (
          <DetailComponent entity={objectEntities} currentUserPage={true} />
        )}
      </Fragment>
    );
  }
}

LeaveRequestDetail.propTypes = {
  id: PropTypes.number,
  entities: PropTypes.object,
  loadData: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    entities: state.entities,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id } = ownProps;
  return {
    loadData: () => dispatch(getLeaveRequestDetail(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequestDetail);