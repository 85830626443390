export const CONTRACTOR_LIST_REQUEST = "CONTRACTOR_LIST_REQUEST"; 
export const CONTRACTOR_LIST_SUCCESS = "CONTRACTOR_LIST_REQUEST_SUCCESS";
export const CONTRACTOR_CREATE_REQUEST = "CONTRACTOR_CREATE_REQUEST"; 
export const CONTRACTOR_DETAIL_REQUEST = "CONTRACTOR_DETAIL_REQUEST"; 
export const CONTRACTOR_ARCHIVE_REQUEST = "CONTRACTOR_ARCHIVE_REQUEST"; 
export const CONTRACTOR_DELETE_REQUEST = "CONTRACTOR_DELETE_REQUEST"; 
export const CONTRACTOR_UNARCHIVE_REQUEST = "CONTRACTOR_UNARCHIVE_REQUEST"; 
export const CONTRACTOR_EDIT_REQUEST = "CONTRACTOR_EDIT_REQUEST"; 
export const CONTRACTOR_CREATE_FORM_NAME = "createContractor"; 
export const CONTRACTOR_EDIT_FORM_NAME = "editContractor"; 
export const CONTRACTOR_AUTOCOMPLETE_REQUEST = "CONTRACTOR_AUTOCOMPLETE_REQUEST";
export const CONTRACTOR_AUTOCOMPLETE_SUCCESS = "CONTRACTOR_AUTOCOMPLETE_SUCCESS";
export const CONTRACTOR_SUBMIT_RULES = { 
  name: {
    required: true
  },
  contact: {
    required: true
  },
  currencyId: {
    required: true
  },
};
export const READ_CONTRACTOR = 245000;
export const CREATE_CONTRACTOR = 245001;
export const UPDATE_CONTRACTOR = 245002;
export const DELETE_CONTRACTOR = 245003;
export const ARCHIVE_CONTRACTOR = 245004;
export const UNARCHIVE_CONTRACTOR = 245005;
export const BATCH_DELETE_CONTRACTOR = 245006;
export const PRINT_CONTRACTOR = 245007;
export const IMPORT_CONTRACTOR = 245008;
export const EXPORT_CONTRACTOR = 245009;
export const REPORT_CONTRACTOR = 245010;