import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import { FormText } from "reactstrap";

const FormItem = Form.Item;

class InputField extends Component {

  onChange = (event) => {
    const { input: { onChange }, handleChange } = this.props;
    const { target: { value } } = event;
    onChange(value);
    if (handleChange) {
      handleChange(value);
    }
  }

  onBlur = () => {
    const { input: { onChange, value, onBlur } } = this.props;
    onChange(value);
    onBlur();
  }

  render() {
    const { 
      tip,
      label, 
      icon, 
      type,
      placeholder,
      required,
      disabled,
      onPressEnter,
      hide,
      customRef,
      meta: { touched, error },
      input: { value, onFocus },
      ...props
    } = this.props;
    let InputComponent = Input;
    if (type) {
      InputComponent = Input[type];
    }
    const validateStatus = classnames(touched && error ? "error" : "success");
    const className = classnames(hide && "d-none");
    return (
      <Fragment>
        <FormItem 
          label={label} 
          validateStatus={validateStatus}
          help={error}
          required={required}
        >
          <InputComponent
            value={value}
            ref={customRef}
            prefix={icon}
            placeholder={placeholder}
            type={type}
            className={className}
            autoComplete="off"
            onChange={this.onChange}
            disabled={disabled}
            onPressEnter={onPressEnter}
            onBlur={this.onBlur}
            onFocus={onFocus}
            {...props}
          />
        </FormItem>
        {tip && (
          <FormText
            color="muted"
            className="mt-0 mb-2"
          >
            {tip}
          </FormText>
        )}
      </Fragment>
    );
  }
}

InputField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  hide: PropTypes.bool,
  tip: PropTypes.string,
  customRef: PropTypes.any,
};

InputField.defaultProps = {
  required: false,
  hide: false,
};

class InputRedux extends Component {

  render() {
    const { customRef, ...props } = this.props;
    return (
      <Field 
        component={InputField}
        customRef={customRef}
        {...props} 
      />
    );
  }
}

InputRedux.propTypes = {
  customRef: PropTypes.any,
};

export default InputRedux;