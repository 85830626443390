import { schema } from "normalizr";
import { WarehouseSchema } from "../../../stock/warehouse/schema";
import { ItemSchema } from "@modules/inventory/item/item/schema";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";

const JunkOutItemSchema = new schema.Entity("assetRequestItems", {
  item: ItemSchema
});
const AssetRequestItemArraySchema = new schema.Array(JunkOutItemSchema);

const AssetRequestSchema = new schema.Entity("assetRequests", {
  assetRequestItems: AssetRequestItemArraySchema,
  warehouse: WarehouseSchema,
  employee: EmployeeSchema
});

const AssetRequestArraySchema = new schema.Array(AssetRequestSchema);

export { AssetRequestSchema, AssetRequestArraySchema };