export const EMAIL_ACCOUNT_LIST_REQUEST = "EMAIL_ACCOUNT_LIST_REQUEST";
export const EMAIL_ACCOUNT_LIST_SUCCESS = "EMAIL_ACCOUNT_LIST_REQUEST_SUCCESS";

export const EMAIL_ACCOUNT_CREATE_REQUEST = "EMAIL_ACCOUNT_CREATE_REQUEST";
export const EMAIL_ACCOUNT_CREATE_SUCCESS = "EMAIL_ACCOUNT_CREATE_SUCCESS";

export const EMAIL_ACCOUNT_DETAIL_REQUEST = "EMAIL_ACCOUNT_DETAIL_REQUEST";

export const EMAIL_ACCOUNT_DELETE_REQUEST = "EMAIL_ACCOUNT_DELETE_REQUEST";

export const EMAIL_ACCOUNT_ARCHIVE_REQUEST = "EMAIL_ACCOUNT_ARCHIVE_REQUEST";

export const EMAIL_ACCOUNT_UNARCHIVE_REQUEST = "EMAIL_ACCOUNT_UNARCHIVE_REQUEST";

export const EMAIL_ACCOUNT_UPDATE_REQUEST = "EMAIL_ACCOUNT_UPDATE_REQUEST";

export const EMAIL_ACCOUNT_AUTOCOMPLETE_REQUEST = "EMAIL_ACCOUNT_AUTOCOMPLETE_REQUEST";
export const EMAIL_ACCOUNT_AUTOCOMPLETE_SUCCESS = "EMAIL_ACCOUNT_AUTOCOMPLETE_REQUEST_SUCCESS";

export const EMAIL_ACCOUNT_CREATE_FORM_NAME = "createEmailAccount";
export const EMAIL_ACCOUNT_EDIT_FORM_NAME = "editEmailAccount";

export const EMAIL_ACCOUNT_DEFAULT_SETTING_KEYS = ["smtpServer", "smtpPort", "useSSL"];
export const EMAIL_ACCOUNT_PASSWORD_PLACEHOLDER = "********";

export const READ_EMAIL_ACCOUNT = 109000;
export const CREATE_EMAIL_ACCOUNT = 109001;
export const UPDATE_EMAIL_ACCOUNT = 109002;
export const DELETE_EMAIL_ACCOUNT = 109003;
export const ARCHIVE_EMAIL_ACCOUNT = 109004;
export const UNARCHIVE_EMAIL_ACCOUNT = 109005;

export const defaultServices = [
  {
    name: "Gmail",
    id: "gmail",
  },
  {
    name: "Outlook",
    id: "outlook",
  },
  {
    name: "Yahoo",
    id: "yahoo",
  },
  {
    name: "Mailgun",
    id: "mailgun"
  },
  {
    name: "Sendgrid",
    id: "sendgrid"
  },
];

export const defaultServiceSettings = {
  gmail: {
    smtpServer: "smtp.gmail.com",
    smtpPort: "465",
    useSSL: true,
  },
  outlook: {
    smtpServer: "smtp.live.com",
    smtpPort: "25",
    useSSL: true,
  },
  yahoo: {
    smtpServer: "smtp.mail.yahoo.com",
    smtpPort: "465",
    useSSL: true,
  },
  mailgun: {
    smtpServer: "smtp.mailgun.org",
    smtpPort: "587",
    useSSL: false,
  },
  sendgrid: {
    smtpServer: "smtp.sendgrid.net",
    smtpPort: "587",
    useSSL: false,
  },
};