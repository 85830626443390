export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_REQUEST_SUCCESS";
export const CATEGORY_AUTOCOMPLETE_REQUEST = "CATEGORY_AUTOCOMPLETE_REQUEST";
export const CATEGORY_AUTOCOMPLETE_SUCCESS = "CATEGORY_AUTOCOMPLETE_SUCCESS";
export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_DETAIL_REQUEST = "CATEGORY_DETAIL_REQUEST";
export const CATEGORY_ARCHIVE_REQUEST = "CATEGORY_ARCHIVE_REQUEST";
export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_UNARCHIVE_REQUEST = "CATEGORY_UNARCHIVE_REQUEST";
export const CATEGORY_EDIT_REQUEST = "CATEGORY_EDIT_REQUEST";
export const CATEGORY_CREATE_FORM_NAME = "createCategory";
export const CATEGORY_EDIT_FORM_NAME = "editCategory";
export const CATEGORY_SUBMIT_RULES = {
  name: {
    required: true
  },
};
export const READ_CATEGORY = 201000;
export const CREATE_CATEGORY = 201001;
export const UPDATE_CATEGORY = 201002;
export const DELETE_CATEGORY = 201003;
export const ARCHIVE_CATEGORY = 201004;
export const UNARCHIVE_CATEGORY = 201005;