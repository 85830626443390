export default {
  "edit": "កែសម្រួល",
  "date": "កាលបរិច្ឆេទ",
  "create": "បង្កើតថ្មី",
  "delete": "លុប",
  "archive": "លុបបណ្ណោះអាសន្ន",
  "loading": "កំពុងដំណេីរការ...",
  "comment": "មតិ",
  "send": "បញ្ជូន",
  "sending": "កំពុងបញ្ជូន...",
  "writeComment": "សរសេរ...",
  "activityLog": "កំណត់ហេតុសកម្មភាព",
  "action": "មុខងារ",
  "additionalInfo": "ពត៍មានបន្ថែម",
  "print": "ព្រីន",
  "back": "ត្រលប់",
  "unarchive": "បង្ហាញឡើងវិញ",
  "duplicate": "ចំលង",
  "creator": "បង្កើតដោយ",
  "createdAt": "បង្កើត នៅ",
  "updatedAt": "កែប្រែ នៅ",
  "watchers": "អ្នកសង្កេត",
  "save": "រក្សាទុក",
  "cancel": "បោះបង់",
  "seeMore": "បន្ថែម",
};