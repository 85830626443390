import * as EntityConstants from "@/constants/entity";

export function addEntity(entityName, key, values) {
  return {
    type: EntityConstants.ADD_ENTITY,
    entityName,
    key,
    values
  };
}

export function mergeEntity(entityName, key, entity) {
  return {
    type: EntityConstants.MERGE_ENTITY,
    entityName,
    key,
    entity
  };
}

export function mergeEntities(entities) {
  return {
    type: EntityConstants.MERGE_ENTITIES,
    entities
  };
}

export function mergeAndReplace(entities) {
  return {
    type: EntityConstants.MERGE_AND_REPlACE,
    entities
  };
}

export function syncEntities(entity, key, idAttribute) {
  return {
    type: EntityConstants.SYNC_ENTITIES,
    entity,
    key,
    idAttribute
  };
}

export function syncArrayEntities(entity, key) {
  return {
    type: EntityConstants.SYNC_ARRAY_ENTITIES,
    entity,
    key
  };
}

export function deleteEntity(entityId, entityName) {
  return {
    type: EntityConstants.DELETE_ENTITY,
    entityId,
    entityName
  };
}

export function addEntityPermission(roleId, entityPermissionId) {
  return {
    type: EntityConstants.ADD_ENTITY_PERMISSION,
    roleId,
    entityPermissionId
  };
}