import React from "react";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import * as QueryString from "query-string";
import { history } from "@/shared/utils/history";
import { Modal, Badge, Tooltip, Typography } from "antd";

const confirm = Modal.confirm;
const warning = Modal.warning;

const { Paragraph } = Typography;

// Constants
import * as StatusConstants from "../../constants/status";
import * as DefaultConstants from "../../constants/default-constants"; 
import * as ListLayoutConstants from "../../components/layout/list-layout/constant";
import { OPTION_TYPE_ALL, OPTION_TYPE_TO_DAY, OPTION_TYPE_CUSTOM, renderDateRangeOption } from "@cores/components/auto-complete/date-range-option/constants";

export function getQueryParamUrl() {
  const query = QueryString.parse(location.search, {
    arrayFormat: "bracket"
  });
  return query;
}

export function getQueryParamUrlWithProps(props) {
  const query = QueryString.parse(props.location.search, {
    arrayFormat: "bracket"
  });
  return query;
}

export function updateQueryParamUrl(query) {
  history.push({
    search: QueryString.stringify(query, { arrayFormat: "bracket" })
  });
}

export const getPageNumber = (props) => {
  const params = QueryString.parse(props.location.search);
  let page = 0;
  const reg = /^\d+$/;
  if (params != null && params.page != null && params.page >= 0) {
    if(params.page.match(reg)) {
      page = params.page;
      if (page >= 1) {
        page = page - 1;
      }
      return parseInt(page);
    } else {
      return false;
    }
  }
};

export const getSortOption = (location) => {
  const params = QueryString.parse(location.search);
  return {
    sortBy: params.sortBy,
    order: params.order,
  };
};

export const getParamFilter = (props, keys) => {
  let query = getQueryParamUrlWithProps(props);
  const filter = {};
  keys && keys.map(key => {
    const value = query[key];
    if (value) {
      filter[key] = query[key];
    }
  });
  return filter;
};

export const getSortKeys = (location) => {
  const keys = ["order", "sortBy"];
  let query = QueryString.parse(location.search, {arrayFormat: "bracket"});
  const sort = {};
  keys && keys.map(key => {
    const value = query[key];
    if (value) {
      sort[key] = query[key];
    }
  });
  return sort;
};

export const generateQuery = (value) => {
  let query = QueryString.parse(location.search, {arrayFormat: "bracket"});
  query["page"] = value;
  const page = QueryString.stringify(query, { arrayFormat: "bracket" });
  return page;
};

export const getParamLimit = (props) => {
  let query = getQueryParamUrlWithProps(props);
  const limit = query["limit"];
  const pageSize = ListLayoutConstants.LIST_LAYOUT_PAGE_SIZE;
  const isPageSizeDefined = _.indexOf(pageSize, limit);
  if(isPageSizeDefined == -1) {
    return ListLayoutConstants.LIST_LAYOUT_DEFAULT_PAGE_SIZE;
  } else {
    return limit;
  }
};

export function modalConfirmDelete(id, entity, callback, value) {
  confirm({
    title: `Do you want to delete this ${entity} ?`,
    content: "Once you delete it, it cannot be undone.",
    okText: "Delete",
    okType: "danger",
    width: "450px",
    onOk() {
      if (value) {
        return Promise.resolve(callback(value));
      }
      return Promise.resolve(callback(id));
    }
  });
}

export function modalConfirmArchive(id, entity, callback, onFinish) {
  confirm({
    title: `Do you want to archive this ${entity} ?`,
    content: "Once you archive it, it will disappear from list.",
    okText: "Archive",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(id)).then(() => {
        if (onFinish) {
          onFinish();
        }
      });
    }
  });
}

export function modalConfirmUnarchive(id, entity, callback, onFinish) {
  confirm({
    title: `Do you want to unarchive this ${entity} ?`,
    content: "Once you unarchive it, it will appear on the list.",
    okText: "Unarchive",
    okType: "primary",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(id)).then(() => {
        if (onFinish) {
          onFinish();
        }
      });
    }
  });
}

export function getBreadcrumbPageLabel(entity) {
  const name = entity && entity.match(/[A-Z][a-z]+/g);
  if(name && name.length > 1) {
    const page = name.join(" ");
    return page;
  }
  return entity;
}

export function getEntityName(entity) {
  const name = entity && entity.match(/[A-Z][a-z]+/g);
  if (name && name.length > 1) {
    const page = name.join("");
    return page;
  }
  return entity;
}

export function getPathName(path, length) {
  const rootPaths = [...path];
  rootPaths.splice(0, 1);
  rootPaths.splice(path.length - length, 1);
  return rootPaths.join("/");
}

export function getMainPath(path) {
  const rootPaths = [...path];
  rootPaths.splice(0, 0);
  rootPaths.splice(path.length - 2, 2);
  return rootPaths.join("/");
}

export function modalConfirmDialog(title, content, callback, values, width, okType, onFinish) {
  return confirm({
    title: `Are you sure to ${title} ?`,
    content: content,
    okText: "Yes",
    okType: okType || "danger",
    width: width || "470px",
    onOk() {
      return Promise.resolve(callback(values)).then(() => {
        if (onFinish) {
          onFinish();
        }
      }).catch((error) => {
        onFinish(error);
      });
    },
  });
}

export function toRelative(value) {
  const relative = moment(value, "").fromNow();
  return relative;
}

export function toDate(value) {
  if (value) {
    const date = moment(value).format(DefaultConstants.DEFAULT_DISPLAY_DATE_TIME_FORMAT);
    return date;
  }
  return null;
}

export function toShortDate(value) {
  if (value) {
    const date = moment(value).format(DefaultConstants.DEFAULT_DISPLAY_DATE_FORMAT);
    return date;
  }
  return null;
}

export function toShortDateFilter(value) {
  if (value) {
    const date = moment(value, DefaultConstants.DEFAULT_DISPLAY_DATE_FORMAT);
    return date;
  }
  return null;
}

export function toTime(value) {
  if (value) {
    const time = moment(value, DefaultConstants.DEFAULT_TIME_FORMAT).format(DefaultConstants.DEFAULT_DISPLAY_TIME_FORMAT);
    return time;
  }
  return null;
}

export function toQueryParamDate(value) {
  const date = moment(value).format(DefaultConstants.DEFAULT_ASYNC_DATE_FORMAT);
  return date;
}

export function toQueryParamMonth(value) {
  const month = moment(value).format(DefaultConstants.DEFAULT_ASYNC_MONTH_FORMAT);
  return month;
}

export function toQueryParamYear(value) {
  const month = moment(value).format(DefaultConstants.DEFAULT_DISPLAY_YEAR_FORMAT);
  return month;
}

export function validatePhoneNumber(value) {
  const valid = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
  return valid.test(value);
} 

export function renderStatus(value, isBoolean, isQuestion) {
  let status = null;
  switch(value) {
  case StatusConstants.STATUS_ACTIVE_ID:
  case StatusConstants.STATUS_BOOLEAN_TRUE_ID: {
    if (isBoolean) {
      status = "True";
    } else if (isQuestion) {
      status = "Yes";
    } else {
      status = <Badge status="success" text={StatusConstants.STATUS_ACTIVE_NAME} />;
    }
    break;
  }
  case StatusConstants.STATUS_DEACTIVE_ID:
  case StatusConstants.STATUS_BOOLEAN_FALSE_ID: {
    if (isBoolean) {
      status = "False";
    } else if (isQuestion) {
      status = "No";
    } else {
      status = <Badge status="error" text={StatusConstants.STATUS_DEACTIVE_NAME} />;
    }
    break;
  }
  default:
    status;
  }
  return status;
}

export function modalConfirmDeleteAllSession(employeeId, callback) {
  confirm({
    title: "Do you want to clear all sessions ?",
    content:
      "This will log you out of ISERP from every device you're currently logged in on.",
    okText: "Delete",
    okType: "danger",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(employeeId));
    }
  });
}

export function modalConfirmChangeUserStatus(id, status, callback) {
  let title = null;
  let content = null;
  let okType = null;
  let okText = null;
  if (status == 1) {
    title = "activate";
    content = "it will be able to login to the system";
    okType = "primary";
    okText = "Activate";
  } else {
    title = "deactivate";
    content = "it will no longer be able to login to the system";
    okType = "danger";
    okText = "Deactivate";
  }
  confirm({
    title: `Are you sure you want to ${title} this user account ?`,
    content: `Once this user account is ${title}d, ${content}`,
    okText: okText,
    okType: okType,
    width: "520px",
    onOk() {
      return Promise.resolve(callback({ id, status }));
    }
  });
}

export function modalWarningLoginBeforeIsOverAndLogOut(callback) {
  warning({
    title: "Login Restriction",
    content: "You are not allowed to use system at this hour. You will be logged out automatically.",
    width: "450px",
    keyboard: false,
    maskClosable: false,
    onOk() {
      return Promise.resolve(callback());
    },
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getBooleanString(value, type=false) {
  if (value == true) {
    return type ? "Yes" : "True";
  } else {
    return type ? "No" : "False";
  }
}

export function currencyValue(value) {
  const format = DefaultConstants.DEFAULT_CURRENCY_FORMAT;
  const formatedValue = numeral(value).format(format);
  return `$ ${formatedValue}`;
}

export function convertLocationParams(props) {
  const { location: { search } } = props;
  let paramQuery = search;
  paramQuery = search.slice(1);
  paramQuery = paramQuery.split("=");
  const paramName = paramQuery && paramQuery[0];
  const paramValue = paramQuery && Number(paramQuery[1]);
  return {
    paramName,
    paramValue,
  };
}

export function renderIdsValues(name, isSingle) {
  let values = [];
  const query = getQueryParamUrl();
  const valueFormUrl = query[name];
  if (isSingle) {
    values = [Number(valueFormUrl)];
  } else {
    if (valueFormUrl && valueFormUrl.length > 0) {
      values = valueFormUrl.map(value => Number(value));
    }
  }
  return values;
}

export function queryParamsDate(values, format, toFormat) {
  const date = moment(values, format || DefaultConstants.DEFAULT_ASYNC_DATE_FORMAT);
  return date.format(toFormat || DefaultConstants.DEFAULT_ASYNC_DATE_FORMAT);
}

export function convertFieldToDisplay(field) {
  let value = field.replace(/([A-Z])/g, " $1");
  const firstCharacter = value[0];
  value = value.replace(/^./, firstCharacter.toUpperCase());
  return value;
}

export function getDayLabel(numberOfDay) {
  const dayLabel = numberOfDay > 1 ? "days" : "day";
  return `${numberOfDay} ${dayLabel}`;
}

export function getLabelWithFirstOfString(string) {
  string = string.toLowerCase();
  string = capitalizeFirstLetter(string);
  let newString = getBreadcrumbPageLabel(string);
  newString = newString.split(" ");
  newString = newString.map((value) => value.charAt(0));
  newString = newString.join((""));
  return newString.toUpperCase();
}


export const renderMonth = (id, short) => {
  let month = null;
  switch(id) {
  case 1:
    month = short ? "Jan" : "January";
    break;
  case 2:
    month = short ? "Feb" : "February";
    break;
  case 3:
    month = short ? "Mar" : "March";
    break;
  case 4:
    month = short ? "Apr" : "April";
    break;
  case 5:
    month = short ? "May" : "May";
    break;
  case 6:
    month = short ? "June" : "June";
    break;
  case 7:
    month = short ? "July" : "July";
    break;
  case 8:
    month = short ? "Aug" : "August";
    break;
  case 9:
    month = short ? "Sept" : "September";
    break;
  case 10:
    month = short ? "Oct" : "October";
    break;
  case 11:
    month = short ? "Nov" : "November";
    break;
  case 12:
    month = short ? "Dec" : "December";
    break;
  default:
    month;
  }
  return month;
};

export const renderDay = (id, short) => {
  let month = null;
  switch(id) {
  case 1:
    month = short ? "Mon" : "Monday";
    break;
  case 2:
    month = short ? "Tue" : "TuesDay";
    break;
  case 3:
    month = short ? "Wed" : "Wednesday";
    break;
  case 4:
    month = short ? "Thu" : "Thursday";
    break;
  case 5:
    month = short ? "Fri" : "Friday";
    break;
  case 6:
    month = short ? "Sat" : "Saturday";
    break;
  case 7:
    month = short ? "Sun" : "Sunday";
    break;
  default:
    month;
  }
  return month;
};

export function modalConfirmDeleteComment(
  entity,
  entityId,
  id,
  callback
) {
  confirm({
    title: "Do you want to delete this comment ?",
    content: "Once you delete it, it cannot be undone.",
    okText: "Delete",
    okType: "danger",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(entity, entityId, id));
    }
  });
}

export function renderDisplayTextFromTextArea(texts) {
  if (texts) {
    return (
      <div className="editor-text" dangerouslySetInnerHTML={{ __html: texts.replace(/\n/g, "") }}></div>
    );
  }
  return null;
}

export function getEmployeeLabel(numberOfEmployee) {
  if (numberOfEmployee >= 0) {
    const employeeLabel = numberOfEmployee > 1 ? "employees" : "employee";
    return `${numberOfEmployee} ${employeeLabel}`;
  }
  return null;
}

export function getHourLabel(numberOfHour) {
  if (numberOfHour != null) {
    const hourLabel = numberOfHour > 1 ? "hours" : "hour";
    return `${numberOfHour} ${hourLabel}`;
  }
  return null;
}

export function getMinuteLabel(numberOfMinute) {
  if (numberOfMinute) {
    const minuteLabel = numberOfMinute > 1 ? "minutes" : "minute";
    return `${numberOfMinute} ${minuteLabel}`;
  }
}

export function getMonthLabel(numberOfMonth) {
  const monthLabel = `${numberOfMonth} ${numberOfMonth > 1 ? "months" : "month"}`;
  return monthLabel;
}

export function renderGender(value) {
  let gender = null;
  if (value == "Miss" || value == "Mrs." || value == "Ms.")  {
    gender = "Female";
  } else if (value == "Mr.") {
    gender = "Male";
  } else {
    gender = "Other";
  }
  return gender;
}

export function confirmDeleteModal(values, entity, callback) {
  confirm({
    title: `Do you want to delete this ${entity} ?`,
    content: "Once you delete it, it cannot be undone.",
    okText: "Delete",
    okType: "danger",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(values));
    }
  });
}

export const validateValueTypeUrl = (value) => {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/; //eslint-disable-line
  return regexp.test(value);
};

export function validateUrlTypeImage(value) {
  var reg = /^((https?|ftp):)?\/\/.*(jpeg|jpg|JPG|png|gif|bmp)$/;
  return reg.test(value);
}

export function validateTypeDate(value) {
  if (typeof value == "string") {
    return moment(value, moment.ISO_8601, true).isValid();
  }
  return false;
}

export const getDateList = () => { 
  const postfixes = { 
    1: "st", 
    2: "nd", 
    3: "rd", 
    21: "st", 
    22: "nd", 
    23: "rd", 
    31: "st", 
  }; 
  const dateList = []; 
  for (let i = 0; i < 31; i++) { 
    let name = `${i+1}th`; 
    const postfix = postfixes[i+1]; 
    if (postfix) { 
      name = `${i+1}${postfix}`; 
    } 
    dateList.push({
      id: i+1,
      name
    }); 
  } 
  return dateList; 
};

export const getBooleanStringWithBadge = (value, type=true) => {
  if (value == true) {
    return <Badge status="success" text={type ? "Yes" : "True"} />;
  } else {
    return <Badge status="warning" text={type ? "No" : "False"} />;
  }
};

export const getDefaultCurrentDateRange = (values, startKey="from", endKey="to", type="day") => {
  const {
    filter,
    filter: {
      dateType,
    }
  } = values;
  const start = filter[startKey];
  const end = filter[endKey];
  if (start == null && end == null  && dateType == null) {
    values.filter[startKey] = toQueryParamDate(moment().startOf(type));
    values.filter[endKey] = toQueryParamDate(moment().endOf(type));
    if (type == "day") {
      values.filter["dateType"] = OPTION_TYPE_TO_DAY;
    }
    if (type == "all") {
      values.filter["dateType"] = OPTION_TYPE_ALL;
      delete filter[startKey];
      delete filter[endKey];
    }
  } 
  return values;
};

export function getDefaultDateRange(values, startKey="from", endKey="to", type="month") {
  if (values[startKey] == null) {
    values[startKey] = toQueryParamDate(moment().startOf(type));
  }
  if (values[endKey] == null) {
    values[endKey] = toQueryParamDate(moment().startOf(type));
  }
  return values;
}

export const getShortNameWithTooltip = (text, textLength=75, placement="right") => {
  const trimmedText = text.length > textLength ? text.substring(0, textLength - 3) + "..." : text;
  return (
    <Tooltip placement={placement} title={text}>
      {trimmedText}
    </Tooltip>
  );
};

export const truncateTextWithTooltip = (text) => {
  return (
    <Tooltip placement="right" title={text}>
      <Paragraph ellipsis={true} className="mb-0">
        {text}
      </Paragraph>
    </Tooltip>
  );
};

export function getQueryParamFromLocalStorage() {
  const activeFilter = localStorage.getItem("FILTER_ACTIVE");
  const value = localStorage.getItem(activeFilter);
  const query = value != null ? JSON.parse(value) : {};
  return query;
}

export function updateQueryParamToLocalStorage(query) {
  const activeFilter = localStorage.getItem("FILTER_ACTIVE");
  localStorage.setItem(activeFilter, JSON.stringify(query));
}

export function getPageNumberLabel(numberOfPage) {
  const pageNumberLabel = `${numberOfPage} ${numberOfPage > 1 ? "pages" : "page"}`;
  return pageNumberLabel;
}

export const renderType = (id, dataSource) => {
  if (id) {
    const matched = dataSource.find((value) => value.id == id);
    if (matched) {
      return matched.name;
    }
  }
  return "N/A";
};

export const getFilterObjectFromFilterDataSource = (values) => {
  let newValues = {};
  for (const property in values) {
    if (values[property] && values[property].type == "Array") {
      newValues[property] = values[property].value;
    }
    else if (values[property] && values[property].type == "Boolean") {
      newValues[property] = values[property].value;
    } else if (property == "dateType") {
      newValues["dateType"] = Number(values[property].value);
      if (Number(values[property].value) == OPTION_TYPE_CUSTOM) {
        newValues["from"] = values[property]["from"];
        newValues["to"] = values[property]["to"];
      } else if (Number(values[property].value) == OPTION_TYPE_ALL) {
        delete newValues.from;
        delete newValues.to;
      } else {
        const { from, to } = renderDateRangeOption(Number(values[property].value));
        newValues.from = from;
        newValues.to = to;
      }
    } 
    else {
      newValues[property] = values[property].value;
    }     
  }                     
  return newValues;
};

export const getFilterObjectFromQuery = (values) => {
  let newValues = {};
  for (const property in values) {
    newValues[property] = values[property];
    if (_.isArray(values[property])) {
      newValues[property] = values[property] && values[property].map((value) => Number(value));
    }
    if (values[property] == "true") {
      newValues[property] = true;
    }
    if (values[property] == "false") {
      newValues[property] = false;
    }
    if (property == "dateType") {
      newValues["dateType"] = Number(values[property]);
      if (Number(values[property]) == OPTION_TYPE_CUSTOM) {
        newValues["from"] = values["from"];
        newValues["to"] = values["to"];
      } else if (Number(values[property]) == OPTION_TYPE_ALL) {
        delete newValues.from;
        delete newValues.to;
      } else {
        const { from, to } = renderDateRangeOption(Number(values[property]));
        newValues.from = from;
        newValues.to = to;
      }
    }
  }
  return newValues;
};

export const getFilterValuesFromQuery = (values) => {
  let newValues = {};
  for (const property in values) {
    newValues[property] = {
      value: values[property],
      type: "String"
    };
    if (_.isArray(values[property])) {
      newValues[property] = {
        value: values[property] && values[property].map((value) => Number(value)),
        type: "Array"
      };
    }
    if (values[property] == "true") {
      newValues[property] = {
        type: "Boolean",
        value: true
      };
    }
    if (values[property] == "false") {
      newValues[property] = {
        type: "Boolean",
        value: false
      };
    }
    if (property == "dateType") {
      if (Number(values[property]) == OPTION_TYPE_CUSTOM) {
        const from = values["from"];
        const to = values["to"];
        newValues[property] = {
          type: "DateType",
          value: OPTION_TYPE_CUSTOM,
          from,
          to
        };
      } else {
        newValues[property] = {
          type: "DateType",
          value: Number(values[property]),
        };
      }
    }
    if (property == "from") {
      delete newValues.from;
    }
    if (property == "to") {
      delete newValues.to;
    }
  }
  delete newValues.view;
  return newValues;
};

export const getFilterSlug = (entity) => {
  const result = entity.split(" ").join("_").toLowerCase();
  return result;
};

export function getUpperCaseText(entity) {
  const name = entity && entity.match(/[A-Z][a-z]+/g);
  if(name && name.length > 1) {
    const text = name.join("_");
    return text.toUpperCase();
  }
  return entity.toUpperCase();
}

export function numberToString(num){
  var ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
    "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
    "seventeen", "eighteen", "nineteen"];
  var tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty","ninety"];

  var numString = num.toString();

  if (num < 0) throw new Error("Negative numbers are not supported.");

  if (num === 0) return "zero";

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + " " + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[numString[0]] + " hundred";
    else
      return ones[numString[0]] + " hundred and " + numberToString(+(numString[1] + numString[2]));
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + " thousand";
    if (end < 100) return ones[numString[0]] + " thousand and " + numberToString(end);
    return ones[numString[0]] + " thousand " + numberToString(end);
  }
}

export function validateTypeVideoFile(file) {
  const types = /(\.|\/)(mp3|mp4)$/i;
  return types.test(file);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}