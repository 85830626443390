/* eslint-disable react/no-unescaped-entities */
import React from "react";
import moment from "moment";
import { Card } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import AuthenticationLayout from "@/components/layout/authentication-layout";
import AuthenticationImage from "../../../images/authentication.png";

// components
import { resetVerifyCode } from "@/services/authentication";


const StylesComponents = styled.div `
  /* .ant-card-body {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  } */
  .image-box {
    width: 60%;
    display: table;
    margin:auto;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .text {
    margin-top: 15px;
  }
  .text h6 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  .list {
    display: table;
    margin: auto;
  }
  .list li {
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    font-size:14px;
    margin-left:5px;
  }
  .otp .input-styles {
    width: 100% !important;
    height: 2.5rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 0px;
    border: 1px solid rgba(0,0,0,0.3);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }
`;

const Verification = (props) => {

  const { onSetOtp, setVerified, verifyToken } = props;
  const history = useHistory();
  const otpInputRef = React.useRef(null);
  const [code, setCode] = React.useState(null);
  const [timeLeft, setTimeLeft] = React.useState(60);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onBackToFirstInput = () => {
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
    otpInputRef.current.focusPrevInput();
  };

  const onCodeChange = async (code) => {
    const subDomain = location.host.split(".")[0];
    const values = {
      code,
      subDomain,
      verifyToken,
    };
    setCode(code);     
    setError(null);
    if (code.length == 6) {
      await resetVerifyCode(values).then((res) => {
        if(res) {
          localStorage.setItem("LOGIN_CREDENTIAL", JSON.stringify(res));
          history.push("/dashboard");
        }
        if (setVerified) {
          setVerified(true);
        }
        if (onSetOtp) {
          onSetOtp(code);
        }
        setError(null);
      }).catch((err) => {
        setError(err?.message);
        setTimeout(() => {
          setCode(null);
          setError(null);
          onBackToFirstInput();
        }, 2000);
      });
    }
  };
  if(timeLeft == 0) {
    setVerified(false);
  }

  return (
    <AuthenticationLayout displayImage={false}>
      <StylesComponents>
        <Card>
          <div className="image-box">
            <img src={AuthenticationImage} className="image"/>
          </div>
          <div className="text">
            <h6>Authenticate Your Account</h6>
            <ol className="list">
              <li>Open <span style={{fontWeight:900}}>ISERP Guard</span> app on your mobile phone</li>
              <li>Enter 6 digit code from your mobile phone</li>
            </ol>
          </div>
          <div className="d-flex align-items-center justify-content-center otp">
            <OtpInput 
              value={code}
              numInputs={6}
              errorStyle="error"
              ref={otpInputRef}
              hasErrored={error != null}
              shouldAutoFocus={true}
              onChange={onCodeChange}
              inputStyle="input-styles"
            />
          </div>
          {error && (
            <div className="text-center mt-1 text-danger">
              {error}
            </div>
          )}
          <br />
          <div className="d-flex justify-content-center mt-2">
            <div>
              {timeLeft == 0 ? (
                <h6>
                  Didn't received code ?
                </h6>
              ) : (
                <h6>
                  Please type the verification code sent {moment.utc(timeLeft*1000).format("mm:ss")}
                </h6>
              )}
            </div>
          </div>
        </Card>
      </StylesComponents>
    </AuthenticationLayout>
  );
};

Verification.propTypes = {
  onSetOtp: PropTypes.func,
  setVerified: PropTypes.func,
  verifyToken: PropTypes.any,
};

export default Verification;