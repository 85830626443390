const ACCOUNTING_PATH = "accounting";
const LIST_PATH = "list";
const BANKING_PATH = "banking";

export const accounting = [
  //list
  {
    name: "Journal Entry List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/journal-entry`,
  },
  {
    name: "Journal Entry Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/journal-entry`,
  },
  {
    name: "Create Journal Entry",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/journal-entry`,
  },
  {
    name: "Edit Journal Entry",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/journal-entry`,
  },
  {
    name: "Expense List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/expense`,
  },
  {
    name: "Expense Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/expense`,
  },
  {
    name: "Create Expense",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/expense`,
  },
  {
    name: "Edit Expense",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/expense`,
  },
  {
    name: "Supplier Deposit List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-deposit`,
  },
  {
    name: "Supplier Deposit Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-deposit`,
  },
  {
    name: "Create Supplier Deposit",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-deposit`,
  },
  {
    name: "Edit Supplier Deposit",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-deposit`,
  },
  {
    name: "Supplier Refund List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-refund`,
  },
  {
    name: "Supplier Refund Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-refund`,
  },
  {
    name: "Create Supplier Refund",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-refund`,
  },
  {
    name: "Edit Supplier Refund",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/supplier-refund`,
  },
  {
    name: "Customer Deposit List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-deposit`,
  },
  {
    name: "Customer Deposit Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-deposit`,
  },
  {
    name: "Create Customer Deposit",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-deposit`,
  },
  {
    name: "Edit Customer Deposit",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-deposit`,
  },
  {
    name: "Customer Refund List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-refund`,
  },
  {
    name: "Customer Refund Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-refund`,
  },
  {
    name: "Create Customer Refund",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-refund`,
  },
  {
    name: "Edit Customer Refund",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-refund`,
  },
  {
    name: "Customer Commission List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-commission`,
  },
  {
    name: "Customer Commission Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/customer-commission`,
  },
  {
    name: "Chart Of Account List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/chart-of-account`,
  },
  {
    name: "Chart Of Account Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/chart-of-account`,
  },
  {
    name: "Create Chart Of Account",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/chart-of-account`,
  },
  {
    name: "Edit Chart Of Account",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/chart-of-account`,
  },
  {
    name: "Payment Method List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/payment-method`,
  },
  {
    name: "Payment Method Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/payment-method`,
  },
  {
    name: "Create Payment Method",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/payment-method`,
  },
  {
    name: "Edit Payment Method",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/payment-method`,
  },
  {
    name: "Contractor List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/contractor`,
  },
  {
    name: "Contractor Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/contractor`,
  },
  {
    name: "Create Contractor",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/contractor`,
  },
  {
    name: "Edit Contractor",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/contractor`,
  },
  {
    name: "End Of Day List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/end-of-day`,
  },
  {
    name: "End Of Day Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${LIST_PATH}/end-of-day`,
  },
  //banking
  {
    name: "Cheque Entry List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/cheque-entry`,
  },
  {
    name: "Cheque Entry Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/cheque-entry`,
  },
  {
    name: "Bank List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank`,
  },
  {
    name: "Bank Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank`,
  },
  {
    name: "Create Bank",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank`,
  },
  {
    name: "Edit Bank",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank`,
  },
  {
    name: "Bank  Entry List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank-entry`,
  },
  {
    name: "Bank  Entry Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank-entry`,
  },
  {
    name: "Bank Transfer List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank-transfer`,
  },
  {
    name: "Bank Transfer Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank-transfer`,
  },
  {
    name: "Create Bank Transfer",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank-transfer`,
  },
  {
    name: "Edit Bank Transfer",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/bank-transfer`,
  },
  {
    name: "Loan List",
    type: "list",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/loan`,
  },
  {
    name: "Loan Detail",
    type: "detail",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/loan`,
  },
  {
    name: "Create Loan",
    type: "create",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/loan`,
  },
  {
    name: "Edit Loan",
    type: "edit",
    slug: `${ACCOUNTING_PATH}/${BANKING_PATH}/loan`,
  },
];
