export const READ_BANK_ENTRY = 229000;
export const POST_BANK_ENTRY = 229001;
export const CANCEL_BANK_ENTRY = 229002;

export const BANK_ENTRY_LIST_REQUEST = "ENTRY_ENTRY_LIST_REQUEST";
export const BANK_ENTRY_LIST_SUCCESS = "ENTRY_ENTRY_LIST_REQUEST_SUCCESS";
export const BANK_ENTRY_AUTOCOMPLETE_REQUEST = "ENTRY_ENTRY_AUTOCOMPLETE_REQUEST";
export const BANK_ENTRY_DETAIL_REQUEST = "ENTRY_ENTRY_DETAIL_REQUEST";
export const BANK_ENTRY_POST_REQUEST = "BANK_ENTRY_POST_REQUEST";
export const BANK_ENTRY_CANCEL_REQUEST = "BANK_ENTRY_CANCEL_REQUEST";

export const BANK_ENTRY_STATUS_DRAFT = 1;
export const BANK_ENTRY_STATUS_DRAFT_TITLE = "Draft";
export const BANK_ENTRY_STATUS_POST = 2;
export const BANK_ENTRY_STATUS_POST_TITLE = "Posted";
export const BANK_ENTRY_STATUS_CANCEL = 3;
export const BANK_ENTRY_STATUS_CANCEL_TITLE = "Cancelled";

export const BANK_ENTRY_STATUS = [
  {
    id: BANK_ENTRY_STATUS_DRAFT,
    name: BANK_ENTRY_STATUS_DRAFT_TITLE,
  },
  {
    id: BANK_ENTRY_STATUS_POST,
    name: BANK_ENTRY_STATUS_POST_TITLE,
  },
  {
    id: BANK_ENTRY_STATUS_CANCEL,
    name: BANK_ENTRY_STATUS_CANCEL_TITLE,
  },
];

export const BANK_ENTRY_TYPE_IN = 1;
export const BANK_ENTRY_TYPE_IN_TITLE = "In";
export const BANK_ENTRY_TYPE_OUT = 2;
export const BANK_ENTRY_TYPE_OUT_TITLE = "Out";

export const BANK_ENTRY_TYPE = [
  {
    id: BANK_ENTRY_TYPE_IN,
    name: BANK_ENTRY_TYPE_IN_TITLE,
  },
  {
    id: BANK_ENTRY_TYPE_OUT,
    name: BANK_ENTRY_TYPE_OUT_TITLE,
  },
];


export const BANK_ENTRY_REFERENCE_INVOICE = "InvoicePayment";
export const BANK_ENTRY_REFERENCE_PURCHASE_ORDER = "PurchaseOrderPayment";