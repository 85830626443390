// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Upload, message } from "antd";
import styled from "styled-components";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// Util
import { getAccessToken } from "@/shared/utils";

const WrapperUpload = styled(Upload) `
  .ant-upload {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    height: ${props => props.minHeight != null ? props.minHeight : "160px"} !important;

    img {
      width: 100%;
      object-fit: contain;
      height: ${props => props.minHeight != null ? props.minHeight : "160px"} !important;
    }
  }
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class UploadImageBox extends Component {

  state = {
    imageUrl: null,
    loading: false,
  };

  componentDidMount() {
    const { value, customFieldValue } = this.props;
    this.setState({
      imageUrl: customFieldValue ? customFieldValue.value : value
    });
    
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { value, customFieldValue } = newProps;
    this.setState({
      imageUrl: customFieldValue ? customFieldValue.value : value
    });
    
  }

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ 
        loading: true 
      });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
      const { onUploaded } = this.props;
      if (onUploaded) {
        onUploaded(info.file.response.url);
      }
    }
  }

  beforeUpload = (file) => {
    const { isMaximum } = this.props;
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isJPG && !isPNG) {
      message.error("You can only upload JPG or PNG file!");
    }
    if (isMaximum) {
      const isLessThan2MB = file.size / 1024 / 1024 < 2;
      if (!isLessThan2MB) {
        message.error("Image must smaller than 2MB!");
      }
      return (isJPG || isPNG) && isLessThan2MB;
    }
    return (isJPG || isPNG);
  }

  render() {
    const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}> {loading ? "Uploading..." : "Upload"}</div>
      </div>
    );
    const token = getAccessToken();
    const { path, disabled, minHeight } = this.props;
    console.log("this.props",this.props);
    return (
      <WrapperUpload
        minHeight={minHeight}
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={`/api/file/v1/upload?path=${path}`}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        headers={{Authorization: `Bearer ${token && token.accessToken}`}}
        withCredentials
        disabled={disabled}
      >
        {imageUrl ? <img src={imageUrl} /> : uploadButton}
      </WrapperUpload>
    );
  }
}

UploadImageBox.propTypes = {
  onUploaded: PropTypes.func,
  path: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isMaximum: PropTypes.bool,
  minHeight: PropTypes.string,
  customFieldValue: PropTypes.object,
};

UploadImageBox.defaultProps = {
  isMaximum: true,
};

export default (UploadImageBox);