import * as Constants from "../constants";

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
  case Constants.FAVORITE_REPORT_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  case Constants.FAVORITE_REPORT_CREATE_SUCCESS:
    return {
      ...state,
      list: [
        ...state.list,
        action.id
      ]
    };
  case Constants.FAVORITE_REPORT_DELETE_SUCCESS: {
    let newList = [...state.list];
    const deleteIndex = newList.indexOf(action.id);
    if (deleteIndex !== -1) {
      newList.splice(deleteIndex, 1);
    }
    return {
      ...state,
      list: [...newList]
    };
  }
  default:
    return state;
  }
}