import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/asset-request/v1";

export const getAssetRequest = (values) => {
  const url = `${API_URL}/list`;
  values = getDefaultCurrentDateRange(values);
  return GETList(url, values);
};

export function createAssetRequest(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getAssetRequestAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getAssetRequestDetail(id) {
  const url = `${API_URL}`;
  return GETDetail(url, id);
}

export function editAssetRequest(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveAssetRequest(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveAssetRequest(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteAssetRequest(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function submitAssetRequest(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function approveAssetRequest(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectAssetRequest(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function cancelAssetRequest(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function processAssetRequest(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/process`;
  return PUTWithToken(url, values);
}