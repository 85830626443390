import React, { Component } from "react";
import PropTypes from "prop-types";
import { denormalize } from "normalizr";
import { connect } from "react-redux";
import { Modal } from "antd";

// Action
import { getCustomFieldByEntityToModalCreate } from "@modules/settings/setup/custom-field/actions";
import { CustomFieldArraySchema } from "@modules/settings/setup/custom-field/schema";
import { CUSTOMER_FIELD_REQUEST_BY_ENTITY_REQUEST } from "@modules/settings/setup/custom-field/constants";

// Components
import FormComponent from "./form-component";

class CreateModal extends Component {

  static propTypes = {
    label: PropTypes.string,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    entities: PropTypes.object,
    customFields: PropTypes.array,
    formComponent: PropTypes.any,
    loadingCustomField: PropTypes.bool,
  }

  render() {
    const { 
      label, 
      visible, 
      onCancel, 
      entities,
      customFields, 
      formComponent, 
      loadingCustomField,
      ...props 
    } = this.props;
    const customFieldDataSource = denormalize(customFields, CustomFieldArraySchema, entities);
    return (
      <Modal
        width="1300px"
        footer={null}
        visible={visible}
        keyboard={false}
        maskClosable={false}
        onCancel={onCancel}
        title={`Create ${label}`}
        destroyOnClose={true}
      >
        {formComponent && (
          <FormComponent 
            formComponent={formComponent}
            customFieldDataSource={customFieldDataSource}
            loadingCustomField={loadingCustomField}
            {...props}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { entity } = ownProps;
  if (entity) {
    return {
      entities: state.entities,
      customFields: state.customField[entity] || [],
      loadingCustomField: state.request[`${CUSTOMER_FIELD_REQUEST_BY_ENTITY_REQUEST}_${entity}`],
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomFieldByEntity: (entityName) => dispatch(getCustomFieldByEntityToModalCreate(entityName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);