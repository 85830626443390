import {
  GETList,
  GETDetail,
  GETWithToken,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/purchase-order/v1";

export const getPurchaseOrder = (values) => {
  const url = `${API_URL}/list`;
  if (!values.filter.noDateRange) {
    values = getDefaultCurrentDateRange(values);
  }
  return GETList(url, values);
};

export function createPurchaseOrder(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getPurchaseOrderAutoComplete(query, extra) {
  let url = `${API_URL}/autocomplete`;
  return GETAutoComplete(url, query, extra);
}

export function getPurchaseOrderDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editPurchaseOrder(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archivePurchaseOrder(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchivePurchaseOrder(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deletePurchaseOrder(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function submitPurchaseOrder(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function processPurchaseOrder(id) {
  const url = `${API_URL}/${id}/process`;
  return PUTWithToken(url);
}

export function updatePurchaseOrderReference(values) {
  const { id, ...rest } = values;
  const url = `${API_URL}/${id}/reference`;
  return PUTWithToken(url, rest);
}

export function updatePurchaseOrderNote(values) {
  const { id, ...rest } = values;
  const url = `${API_URL}/${id}/note`;
  return PUTWithToken(url, rest);
}

export function cancelPurchaseOrder(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function voidPurchaseOrder(id) {
  const url = `${API_URL}/${id}/void`;
  return PUTWithToken(url);
}

export function closePurchaseOrder(id) {
  const url = `${API_URL}/${id}/close`;
  return PUTWithToken(url);
}

export function payPurchaseOrder(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/payment`;
  return PUTWithToken(url, values);
}

export function payBatchPurchaseOrder(values) {
  const url = `${API_URL}/payment-batch`;
  return PUTWithToken(url, values);
}

export function getPurchaseOrderHistory(id) {
  const url = `${API_URL}/${id}/purchase-order-log`;
  return GETWithToken(url);
}

export function receivePurchaseOrder(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/receive`;
  return PUTWithToken(url, values);
}

export function setToDraftPurchaseOrder(id) {
  const url = `${API_URL}/${id}/draft`;
  return PUTWithToken(url);
}

export function approvePurchaseOrder(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectPurchaseOrder(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function mergePurchaseOrder(values) {
  const { id } = values;
  const url = `${API_URL}/merge/${id}`;
  return POSTWithToken(url, values);
}

export function splitPurchaseOrder(values) {
  const { id } = values;
  const url = `${API_URL}/split/${id}`;
  return POSTWithToken(url, values);
}