export const BANK_TRANSFER_LIST_REQUEST = "BANK_TRANSFER_LIST_REQUEST"; 
export const BANK_TRANSFER_LIST_SUCCESS = "BANK_TRANSFER_LIST_REQUEST_SUCCESS";
export const BANK_TRANSFER_CREATE_REQUEST = "BANK_TRANSFER_CREATE_REQUEST"; 
export const BANK_TRANSFER_DETAIL_REQUEST = "BANK_TRANSFER_DETAIL_REQUEST"; 
export const BANK_TRANSFER_ARCHIVE_REQUEST = "BANK_TRANSFER_ARCHIVE_REQUEST"; 
export const BANK_TRANSFER_DELETE_REQUEST = "BANK_TRANSFER_DELETE_REQUEST"; 
export const BANK_TRANSFER_UNARCHIVE_REQUEST = "BANK_TRANSFER_UNARCHIVE_REQUEST"; 
export const BANK_TRANSFER_EDIT_REQUEST = "BANK_TRANSFER_EDIT_REQUEST"; 
export const BANK_TRANSFER_CREATE_FORM_NAME = "createBankTransfer"; 
export const BANK_TRANSFER_EDIT_FORM_NAME = "editBankTransfer"; 
export const BANK_TRANSFER_AUTOCOMPLETE_REQUEST = "BANK_TRANSFER_AUTOCOMPLETE_REQUEST";
export const BANK_TRANSFER_AUTOCOMPLETE_SUCCESS = "BANK_TRANSFER_AUTOCOMPLETE_SUCCESS";
export const FROM_BANK_AUTO_COMPLETE_REQUEST = "FROM_BANK_AUTO_COMPLETE_REQUEST";
export const TO_BANK_AUTO_COMPLETE_REQUEST = "TO_BANK_AUTO_COMPLETE_REQUEST";
export const BANK_TRANSFER_SUBMIT_REQUEST = "BANK_TRANSFER_SUBMIT_REQUEST";

export const READ_BANK_TRANSFER = 177000;
export const CREATE_BANK_TRANSFER = 177001;
export const UPDATE_BANK_TRANSFER = 177002;
export const ARCHIVE_BANK_TRANSFER = 177004;
export const UNARCHIVE_BANK_TRANSFER = 177005;
export const DELETE_BANK_TRANSFER = 177003;
export const SUBMIT_BANK_TRANSFER = 177011;

export const BANK_TRANSFER_STATUS_DRAFT = 1;
export const BANK_TRANSFER_STATUS_SUBMIT = 2;
export const BANK_TRANSFER_STATUS_DRAFT_TITLE = "Draft";
export const BANK_TRANSFER_STATUS_SUBMIT_TITLE = "Submitted";

export const BANK_TRANSFER_STATUS_DATASOURCE = [
  {
    id: BANK_TRANSFER_STATUS_DRAFT,
    name: BANK_TRANSFER_STATUS_DRAFT_TITLE
  },
  {
    id: BANK_TRANSFER_STATUS_SUBMIT,
    name: BANK_TRANSFER_STATUS_SUBMIT_TITLE
  }
];
