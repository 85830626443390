import { schema } from "normalizr";
import { ExpenseLinesArraySchema } from "./expense-line";

const ExpenseSchema = new schema.Entity("expenses", {
  expenseLines: ExpenseLinesArraySchema,
}, {
  idAttribute: "expenseNo"
});
const ExpenseArraySchema = new schema.Array(ExpenseSchema);

export { ExpenseSchema, ExpenseArraySchema };