export const FORM_LIST_REQUEST = "FORM_LIST_REQUEST"; 
export const FORM_LIST_SUCCESS = "FORM_LIST_REQUEST_SUCCESS";

export const FORM_CREATE_REQUEST = "FORM_CREATE_REQUEST"; 
export const FORM_DETAIL_REQUEST = "FORM_DETAIL_REQUEST"; 

export const FORM_ARCHIVE_REQUEST = "FORM_ARCHIVE_REQUEST"; 
export const FORM_DELETE_REQUEST = "FORM_DELETE_REQUEST"; 

export const FORM_UNARCHIVE_REQUEST = "FORM_UNARCHIVE_REQUEST"; 
export const FORM_EDIT_REQUEST = "FORM_EDIT_REQUEST"; 

export const FORM_CREATE_FORM_NAME = "createForm"; 
export const FORM_EDIT_FORM_NAME = "editForm"; 

export const FORM_AUTOCOMPLETE_REQUEST = "FORM_AUTOCOMPLETE_REQUEST";
export const FORM_AUTOCOMPLETE_SUCCESS = "FORM_AUTOCOMPLETE_SUCCESS";

export const APPRAISAL_FORM_CONTROL_CREATE = "APPRAISAL_FORM_CONTROL_CREATE";

export const FORM_SUBMIT_RULES = { 
  title: {
    required: true,
  },
};

export const READ_FORM = 128000;
export const CREATE_FORM = 128001;
export const UPDATE_FORM = 128002;
export const DELETE_FORM = 128003;
export const ARCHIVE_FORM = 128004;
export const UNARCHIVE_FORM = 128005;