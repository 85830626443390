import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getUserAccountAutoComplete, createUserAccount } from "@modules/settings/user-management/user-account/actions";
import FormComponent from "@modules/settings/user-management/user-account/components/form-component";

// Constant 
import { 
  USER_ACCOUNT_AUTOCOMPLETE_REQUEST,
  USER_ACCOUNT_CREATE_REQUEST,
  CREATE_USER_ACCOUNT
} from "@modules/settings/user-management/user-account/constants";
import { userSubmitRules } from "@modules/settings/user-management/user-account/helper";

class UserAccountAutoComplete extends Component {

  render() {
    const { 
      ids,
      hasEmployee,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        formComponent={FormComponent}
        createAction={createUserAccount}
        permissionCode={CREATE_USER_ACCOUNT}
        submitRules={userSubmitRules}
        createRequestName={USER_ACCOUNT_CREATE_REQUEST} 
        optionRender={(value) => `${value.firstName} ${value.lastName}`}
        extra={{
          ids,
          hasEmployee,
        }}
        {...props}
      />
    );
  }
}

UserAccountAutoComplete.propTypes = {
  ids: PropTypes.array,
  hasEmployee: PropTypes.any,
};

UserAccountAutoComplete.defaultProps = {
  label: "User Account",
  name: "userAccountId",
  required: true,
  action: getUserAccountAutoComplete,
  requestName: USER_ACCOUNT_AUTOCOMPLETE_REQUEST,
};

export default UserAccountAutoComplete;