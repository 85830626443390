// Lib
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Button } from "antd";
import { change } from "redux-form";
import { connect } from "react-redux";
import { PhoneOutlined, MailOutlined, GlobalOutlined, HighlightOutlined } from "@ant-design/icons";
import { FacebookOutlined, InstagramOutlined, YoutubeOutlined, SendOutlined } from "@ant-design/icons";

// Component
import Input from "@cores/components/Input";
import Title from "@cores/components/title";
import CheckBox from "@cores/components/check-box";
import Upload from "@cores/components/upload-image";
import Editor from "@cores/components/Editor";
import ColorPicker from "@cores/components/color-picker";
import NoSeriesListAutoComplete from "@cores/components/auto-complete/no-series-list";
import ChartAccountAutoComplete from "@cores/components/auto-complete/chart-of-account";

// constants
import { 
  COMPANY_NAVIGATION_DEFAULT_BACKGROUND_COLOR,
  COMPANY_NAVIGATION_DEFAULT_LINK_COLOR,
  COMPANY_NAVIGATION_DEFAULT_ACTIVE_LINK_COLOR,
  COMPANY_NAVIGATION_DEFAULT_ACTIVE_BACKGROUND_COLOR,
} from "../../constants";

class FormComponent extends Component {

  onResetToDefaultColor = () => {
    const { updateField } = this.props;
    updateField("navBackground", COMPANY_NAVIGATION_DEFAULT_BACKGROUND_COLOR);
    updateField("navLinkColor", COMPANY_NAVIGATION_DEFAULT_LINK_COLOR);
    updateField("activeColor", COMPANY_NAVIGATION_DEFAULT_ACTIVE_LINK_COLOR);
    updateField("activeBackground", COMPANY_NAVIGATION_DEFAULT_ACTIVE_BACKGROUND_COLOR);
  }

  render() {
    const { 
      editable, 
      isAccountingInstalled,
    } = this.props;
    const noSeriesListId = [];
    const chartAccountId = [];
    if (editable) {
      const { 
        entity: { 
          defaultVATChartAccount, 
          noSeriesList, 
        }
      } = this.props;
      noSeriesListId.push(noSeriesList && noSeriesList.id);
      chartAccountId.push(defaultVATChartAccount && defaultVATChartAccount.id);
    }
    return (
      <Fragment>
        <Row>
          <Col
            xl="2" lg="3" md="4" xs="12"
            className="pr-md-0 mb-3 mb-md-2"
          >
            <Upload 
              name="logoImageUrl"
              path="/core/company/profile"
            />
          </Col>
          <Col xl="10" lg="9" md="8" xs="12">
            <Row>
              <Col md="6" xs="12">
                <Input
                  autoFocus 
                  required        
                  label="Company Name"
                  name="name"
                />
              </Col>
              <Col md="6" xs="12">
                <Input
                  required
                  label="Contact Phone"
                  name="contactPhone"
                  addonBefore={<PhoneOutlined />}
                />
              </Col>
              <Col md="6" xs="12">
                <Input
                  required
                  label="Email"
                  name="contactEmail"
                  addonBefore={<MailOutlined />}
                />
              </Col>
              <Col md="6" sm="12" xs="12">
                <Input 
                  addonBefore={<GlobalOutlined />}
                  label="Website"
                  name="website"
                />
              </Col>
              <Col xs="12" className="my-1">
                <CheckBox
                  name="isDefault" 
                  label="Default Company" 
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <Title title="Social Media" />
          </Col>
          <Col md="6" sm="12" xs="12">
            <Input 
              name="facebookUrl"
              label="Facebook URL"
              addonBefore={<FacebookOutlined />}
            />
          </Col>
          <Col md="6" sm="12" xs="12">
            <Input 
              name="instagramUrl"
              label="Instagram URL"
              addonBefore={<InstagramOutlined />}
            />
          </Col>
          <Col md="6" sm="12" xs="12">
            <Input 
              name="youtubeUrl"
              label="Youtube URL"
              addonBefore={<YoutubeOutlined />}
            />
          </Col>
          <Col md="6" sm="12" xs="12">
            <Input 
              name="telegramUrl"
              label="Telegram URL"
              addonBefore={<SendOutlined />}
            />
          </Col>
          <Col xs="12">
            <Title title="About Company" />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <Input 
              label="Description"
              name="description"
              type="TextArea"
              rows="4"
            />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <Input
              label="Description Khmer"
              name="descriptionKh"
              type="TextArea"
              rows="4"
            />
          </Col>
          <Col xs="12">
            <Title title="Address" />
          </Col>
          <Col md="6" sm="12" xs="12">
            <Input 
              label="Latitude"
              name="latitude"
            />
          </Col>
          <Col md="6" sm="12" xs="12">
            <Input 
              label="Longitude"
              name="longitude"
            />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <Input
              required
              label="Address"
              name="address"
              type="TextArea"
              rows="4"
            />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <Input
              label="Address Kh"
              name="addressKh"
              type="TextArea"
              rows="4"
            />
          </Col>
          <Col xs="12">
            <Title title="Terms Conditions and Privacy Policy" />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <Editor
              label="Terms and Conditions"
              name="term"
            />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <Editor
              label="Privacy"
              name="privacy"
            />
          </Col>
          <Col xs="12">
            <Title title="Company Name & Logo" />
          </Col>
          <Col md="6" xs="12">
            <CheckBox
              name="showLogo" 
              label="Display Company Logo" 
            />
          </Col>
          <Col md="6" xs="12">
            <CheckBox
              name="showName" 
              label="Display Company Name" 
            />
          </Col>
          <Col xs="12" className="mt-4">
            <Title title="Navigation Color" />
          </Col>
          <Col md="6" xs="12">
            <ColorPicker 
              name="navBackground"
              label="Nav Bar Background"
            />
          </Col>
          <Col md="6" xs="12">
            <ColorPicker 
              name="navLinkColor"
              label="Nav Link Color"
            />
          </Col>
          <Col md="6" xs="12">
            <ColorPicker 
              name="activeColor"
              label="Active Link Color"
            />
          </Col>
          <Col md="6" xs="12">
            <ColorPicker 
              name="activeBackground"
              label="Active Background Color"
            />
          </Col>
          <Col md="6" xs="12">
            <Button 
              ghost
              size="small"
              type="primary" 
              icon={<HighlightOutlined />}
              onClick={this.onResetToDefaultColor} 
            >
              Reset to default color
            </Button>
          </Col>
          <Col xs="12">
            <Title title="Letter Head" />
          </Col>
          <Col xs="12">
            <Editor 
              name="letterHead"
            />
          </Col>
          {isAccountingInstalled && (
            <Fragment>
              <Col xs="12" className="mt-4">
                <Title title="Accounting" />
              </Col>
              <Col md="6" xs="12">
                <NoSeriesListAutoComplete
                  ids={noSeriesListId} 
                  label="VAT No Series List"
                />
              </Col>
              <Col md="6" xs="12">
                <ChartAccountAutoComplete 
                  ids={chartAccountId}
                  name="defaultVATChartAccountId"
                  label="Default VAT Chart of Account"
                />
              </Col>
              <Col md="6" xs="12">
                <Input
                  required
                  name="vatNumber"
                  label="VAT Number"
                />
              </Col>
            </Fragment>
          )}
        </Row>
      </Fragment>
    );
  }
}

FormComponent.propTypes = {
  editable: PropTypes.bool,
  entity: PropTypes.object,
  updateField: PropTypes.func,
  isAccountingInstalled: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    updateField: (field, value) => dispatch(change(formName, field, value)),
  };
};

export default connect(null, mapDispatchToProps)(FormComponent);