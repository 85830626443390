import {
  GETList, 
  GETWithToken,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
} from "@/services/base-service";
import moment from "moment";
import { toQueryParamDate } from "@modules/helpers";
import { ATTENDANCE_STATUS_INVALID } from "../constants";

const ATTENDANCE_URL = "/api/employee-attendance/v1";

export function getAttendance(values) { 
  const url = `${ATTENDANCE_URL}/list`;
  let { filter: { from, to } } = values;
  if (from == null && to == null) {
    values.filter.from = toQueryParamDate(moment().startOf("month"));
    values.filter.to = toQueryParamDate(moment().endOf("month"));
  }
  return GETList(url, values);
}

export function getInvalidAttendance(values) { 
  const url = `${ATTENDANCE_URL}/list`;
  values.filter.status = ATTENDANCE_STATUS_INVALID;
  return GETList(url, values);
}

export function createEmployeeAttendance(values){
  const url = `${ATTENDANCE_URL}/create`;
  return POSTWithToken(url, values);
}

export function attendanceCheckIn(values) {
  const url = `${ATTENDANCE_URL}/checkin`;
  return POSTWithToken(url, values);
}

export function attendanceCheckOut(values) {
  const url = `${ATTENDANCE_URL}/checkout`;
  return POSTWithToken(url, values);
}

export function editAttendance(values) {
  const { id } = values;
  const url = `${ATTENDANCE_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function createAttendance(values) {
  const url = `${ATTENDANCE_URL}/create`;
  return POSTWithToken(url, values);
}

export function approveAttendance(id) {
  const url = `${ATTENDANCE_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectAttendance(id) {
  const url = `${ATTENDANCE_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function deleteAttendance(id) {
  const url = `${ATTENDANCE_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function mergeAttendance(values) {
  const url = `${ATTENDANCE_URL}/join`;
  return PUTWithToken(url, values);
}

export function getAttendanceSummary(values) {
  const { employeeId } = values;
  const url = `${ATTENDANCE_URL}/${employeeId}/summary`;
  return GETWithToken(url, values);
}

export function approveAllAttendance(values) {
  const url = `${ATTENDANCE_URL}/approve-all`;
  return PUTWithToken(url, values);
}

export function attendanceLog(id) {
  const url = `${ATTENDANCE_URL}/${id}/log`;
  return GETWithToken(url);
}

export function getPreviousAttendanceRecord(attendanceDate, userId) {
  const url = `${ATTENDANCE_URL}/previous?attendanceDate=${attendanceDate}&userId=${userId}`;
  return GETWithToken(url);
}