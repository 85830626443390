import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, message } from "antd";
import { Link } from "react-router-dom";
import { toDate } from "@modules/helpers";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { attendanceLog } from "../../services";

class AttendanceHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      loading: true,
    };
    this.onGetHistory();
  }

  onGetHistory = () => {
    const { id } = this.props;
    attendanceLog(id).then((response) => {
      if (response) {
        this.setState({
          dataSource: response.data,
          loading: false,
          error: null,
        });
      }
    }).catch((error) => {
      this.setState({
        loading: false,
      });
      message.error(error.message);
    });
  }
  
  static propTypes = {
    id: PropTypes.number
  }

  columns = () => {
    return [
      {
        title: "No",
        dataIndex: "no",
        width: "5%",
        render: (_, __, index) => index + 1
      },
      {
        title: "Time In",
        dataIndex: "timeIn",
        width: "20%",
        render: (text, row) => {
          const { newTimeIn, oldTimeIn } = row;
          if ((newTimeIn.rawValue == oldTimeIn.rawValue)) {
            return newTimeIn.rawValue;
          } else {
            return (
              <div className="d-flex">
                <div className="text-danger mr-1">{oldTimeIn != null ? oldTimeIn.formattedValue :  "N/A"}</div>
                <div><ArrowRightOutlined /></div>
                <div className="text-success ml-1">{newTimeIn.formattedValue}</div>
              </div>
            );
          }
        }
      },
      {
        title: "Time Out",
        dataIndex: "timeOut",
        width: "20%",
        render: (text, row) => {
          const { newTimeOut, oldTimeOut } = row;
          if ((oldTimeOut && (oldTimeOut.rawValue == newTimeOut.rawValue))) {
            return newTimeOut.rawValue;
          } else {
            return (
              <div className="d-flex">
                <div className="text-danger mr-1">{oldTimeOut != null ? oldTimeOut.formattedValue : "N/A"}</div>
                <div><ArrowRightOutlined /></div>
                <div className="text-success ml-1">{newTimeOut.formattedValue}</div>
              </div>
            );
          }
        }
      },
      {
        title: "Update At",
        dataIndex: "createdAt",
        width: "20%",
        render: (createdAt) => toDate(createdAt)
      },
      {
        title: "Update By",
        dataIndex: "creator",
        width: "20%",
        render: (creator) => {
          if(creator) {
            return (
              <Link to={`../employee-directory/employee/${creator.id}`}>
                {creator.firstName} {creator.lastName}
              </Link>
            ); 
          }
        }
      },
    ];
  }

  render() {
    const { dataSource, loading } = this.state;
    return (
      <Table 
        size="small"
        pagination={false}
        dataSource={dataSource} 
        columns={this.columns()} 
        rowKey={(value) => value.id}
        loading={{
          tip: "Loading...",
          spinning: loading,
          indicator: <LoadingOutlined spin />
        }}
      />
    );
  }
}

export default AttendanceHistory;