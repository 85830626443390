// Libs
import React, { Component } from "react";

// Components
import FilterComponent from "@cores/components/filter-box";
import AsyncPaymentTerm from "@cores/components/async-auto-complete/payment-term";
import AsyncCustomerCompany from "@cores/components/async-auto-complete/customer-company";
import AsyncSelect from "@cores/components/async-auto-complete/async-status-auto-complete";

// Constants
import { CUSTOMER_TYPE_DATASOURCE, CUSTOMER_STATUS_DATA_SOURCE } from "../../constants";

class FilterBox extends Component {

  render() {
    return (
      <FilterComponent {...this.props}>
        <AsyncPaymentTerm />
        <AsyncCustomerCompany />
        <AsyncSelect 
          name="type"
          mode="single"
          dataSource={CUSTOMER_TYPE_DATASOURCE}
        />
        <AsyncSelect 
          name="status"
          mode="multiple"
          dataSource={CUSTOMER_STATUS_DATA_SOURCE}
        />
      </FilterComponent>
    );
  }
}

export default FilterBox;