// Libs
import React, { Component } from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { Link } from "react-router-dom";

// Utils
import { getEmployeeJobHistory } from "../../../actions";
import { toShortDate } from "@modules/helpers";
import { EMPLOYEE_JOB_HISTORY_REQUEST } from "../../../constants";
import { EmployeeJobHistoryArraySchema } from "../../../schema/employee-job-history";

// Component
import CurrencyBox from "@cores/components/currency-box";

class JobHistoryList extends Component {

  state = {
    page: 0
  }

  componentDidMount = () => {
    const { page } = this.state;
    this.onLoadJobHistory(page);
  }

  onLoadJobHistory = (page) => {
    const { getEmployeeJobHistory } = this.props;
    getEmployeeJobHistory(page);
  }
    
  onPaginationChange = (page) => {
    this.setState({
      page: page - 1 
    }, () => {
      const { page } = this.state;
      this.onLoadCustomerDepositHistory(page);
    });
  }

  columns = () => {
    const { isCurrentUserPage } = this.props;
    return [
      {
        title: "Position",
        dataIndex: "position",
        render: (position) => {
          if (position) {
            if (isCurrentUserPage) {
              return position.name;
            }
            return (
              <Link to={`../position/${position.id}`}>
                {position.name}
              </Link>
            );
          }
          return null;
        }
      },
      {
        title: "Salary",
        dataIndex: "salary",
        render: (salary, row) => {
          const { currency } = row;
          if (currency) {
            return (
              <CurrencyBox 
                value={salary} 
                currency={currency} 
              />
            );
          }
          return <CurrencyBox value={salary} />;
        },
      },
      {
        title: "Evaluation Rate",
        dataIndex: "evaluationRate",
        render: (evaluationRate) => `${evaluationRate} Points`
      },
      {
        title: "Effective Date",
        dataIndex: "createdAt",
        render: (date) => toShortDate(date)
      },
      {
        title: "Description",
        dataIndex: "description",
      },
    ];
  }

  render() {
    const { entities, jobHistories, loading, pagination, size } = this.props;
    const jobHistoryDataSource = denormalize(jobHistories, EmployeeJobHistoryArraySchema, entities);
    const { page } = this.state;
    const current = page != null ? page + 1 : 1;
    return (
      <Table 
        rowKey="id"
        size={size}
        loading={{
          tip: "Loading...",
          spinning: loading
        }}
        columns={this.columns()} 
        className="table-responsive"
        dataSource={jobHistoryDataSource} 
        pagination={{
          current: current,
          onChange: this.onPaginationChange,
          total: pagination && pagination.total,
          pageSize: pagination && pagination.limit,
        }}
      />
    );
  }
}

JobHistoryList.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
  entities: PropTypes.object,
  dataSource: PropTypes.array,
  pagination: PropTypes.object,
  jobHistories: PropTypes.array,
  isCurrentUserPage: PropTypes.bool,
  getEmployeeJobHistory: PropTypes.func,
};

JobHistoryList.defaultProps = {
  jobHistories: [],
  size: "small",
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const { 
    request, 
    entities,
    pagination, 
    employeeJobHistory, 
  } = state;
  return {
    entities,
    jobHistories: employeeJobHistory[id],
    loading: request[`${EMPLOYEE_JOB_HISTORY_REQUEST}_${id}`],
    pagination: pagination[`${EMPLOYEE_JOB_HISTORY_REQUEST}_${id}`],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id } = ownProps;
  return {
    getEmployeeJobHistory: () => dispatch(getEmployeeJobHistory(id)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(JobHistoryList);