export const CUSTOMIZE_ITEM_LIST_REQUEST = "CUSTOMIZE_ITEM_LIST_REQUEST";
export const CUSTOMIZE_ITEM_LIST_SUCCESS = "CUSTOMIZE_ITEM_LIST_REQUEST_SUCCESS";

export const CUSTOMIZE_ITEM_AUTOCOMPLETE_REQUEST = "CUSTOMIZE_ITEM_AUTOCOMPLETE_REQUEST";
export const CUSTOMIZE_ITEM_AUTOCOMPLETE_SUCCESS = "CUSTOMIZE_ITEM_AUTOCOMPLETE_SUCCESS";

export const CUSTOMIZE_ITEM_FROM_REQUEST = "CUSTOMIZE_ITEM_FROM_REQUEST";
export const CUSTOMIZE_ITEM_TO_REQUEST = "CUSTOMIZE_ITEM_TO_REQUEST";

export const CUSTOMIZE_ITEM_CREATE_REQUEST = "CUSTOMIZE_ITEM_CREATE_REQUEST";
export const CUSTOMIZE_ITEM_DETAIL_REQUEST = "CUSTOMIZE_ITEM_DETAIL_REQUEST";
export const CUSTOMIZE_ITEM_ARCHIVE_REQUEST = "CUSTOMIZE_ITEM_ARCHIVE_REQUEST";
export const CUSTOMIZE_ITEM_DELETE_REQUEST = "CUSTOMIZE_ITEM_DELETE_REQUEST";
export const CUSTOMIZE_ITEM_UNARCHIVE_REQUEST = "CUSTOMIZE_ITEM_UNARCHIVE_REQUEST";
export const CUSTOMIZE_ITEM_EDIT_REQUEST = "CUSTOMIZE_ITEM_EDIT_REQUEST";

export const CUSTOMIZE_ITEM_SUBMIT_REQUEST = "CUSTOMIZE_ITEM_SUBMIT_REQUEST";
export const CUSTOMIZE_ITEM_APPROVE_REQUEST = "CUSTOMIZE_ITEM_APPROVE_REQUEST";
export const CUSTOMIZE_ITEM_REJECT_REQUEST = "CUSTOMIZE_ITEM_REJECT_REQUEST";
export const CUSTOMIZE_ITEM_POST_REQUEST = "CUSTOMIZE_ITEM_POST_REQUEST";

export const CUSTOMIZE_ITEM_CREATE_FORM_NAME = "createCustomizeItem";
export const CUSTOMIZE_ITEM_EDIT_FORM_NAME = "editCustomizeItem";
export const CUSTOMIZE_ITEM_REMOVE_FIELD_NAME = "removeItems";
export const CUSTOMIZE_ITEM_ADD_FIELD_NAME = "addItems";

export const CUSTOMIZE_ITEM_SUBMIT_RULES = {
  name: {
    required: true,
  },
  warehouseId: {
    required: true
  },
  fromId: {
    required: true,
  },
  toId: {
    required: true,
  },
  toQuantity: {
    required: true,
  },
  fromQuantity: {
    required: true,
  },
  removeItems: {
    type: "arrayInline",
    subField: [
      {
        name: "itemId"
      },
      {
        name: "quantity",
        type: "number",
        min: 1,
      },
      {
        name: "cost",
        type: "number",
      }
    ],
  },
  addItems: {
    type: "arrayInline",
    subField: [
      {
        name: "itemId"
      },
      {
        name: "quantity",
        type: "number",
        min: 1,
      },
      {
        name: "cost",
        type: "number",
      }
    ],
  }
};

export const CUSTOMIZE_ITEM_TYPE_ADD_ID = 1;
export const CUSTOMIZE_ITEM_TYPE_ADD_TITLE = "Add";
export const CUSTOMIZE_ITEM_TYPE_REMOVE_ID = 2;
export const CUSTOMIZE_ITEM_TYPE_REMOVE_TITLE = "Remove";

export const CUSTOMIZE_ITEM_STATUS_DRAFT_ID = 1;
export const CUSTOMIZE_ITEM_STATUS_DRAFT_TITLE = "Draft";
export const CUSTOMIZE_ITEM_STATUS_SUBMITTED_ID = 2;
export const CUSTOMIZE_ITEM_STATUS_SUBMITTED_TITLE = "Submitted";
export const CUSTOMIZE_ITEM_STATUS_APPROVED_ID = 3;
export const CUSTOMIZE_ITEM_STATUS_APPROVED_TITLE = "Approved";
export const CUSTOMIZE_ITEM_STATUS_REJECTED_ID = 4;
export const CUSTOMIZE_ITEM_STATUS_REJECTED_TITLE = "Rejected";
export const CUSTOMIZE_ITEM_STATUS_POST_ID = 5;
export const CUSTOMIZE_ITEM_STATUS_POST_TITLE = "Posted";

export const CUSTOMIZE_ITEM_STATUS = [
  {
    id: CUSTOMIZE_ITEM_STATUS_DRAFT_ID,
    name: CUSTOMIZE_ITEM_STATUS_DRAFT_TITLE,
  },
  {
    id: CUSTOMIZE_ITEM_STATUS_SUBMITTED_ID,
    name: CUSTOMIZE_ITEM_STATUS_SUBMITTED_TITLE,
  },
  {
    id: CUSTOMIZE_ITEM_STATUS_APPROVED_ID,
    name: CUSTOMIZE_ITEM_STATUS_APPROVED_TITLE,
  },
  {
    id: CUSTOMIZE_ITEM_STATUS_REJECTED_ID,
    name: CUSTOMIZE_ITEM_STATUS_REJECTED_TITLE,
  },
  {
    id: CUSTOMIZE_ITEM_STATUS_POST_ID,
    name: CUSTOMIZE_ITEM_STATUS_POST_TITLE,
  }
];

export const CUSTOMIZE_ITEM_TYPE = [
  {
    id: CUSTOMIZE_ITEM_TYPE_ADD_ID,
    name: CUSTOMIZE_ITEM_TYPE_ADD_TITLE,
  },
  {
    id: CUSTOMIZE_ITEM_TYPE_REMOVE_ID,
    name: CUSTOMIZE_ITEM_TYPE_REMOVE_TITLE,
  }
];

export const READ_CUSTOMIZE_ITEM = 230000;
export const CREATE_CUSTOMIZE_ITEM = 230001;
export const UPDATE_CUSTOMIZE_ITEM = 230002;
export const DELETE_CUSTOMIZE_ITEM = 230003;
export const ARCHIVE_CUSTOMIZE_ITEM = 230004;
export const UNARCHIVE_CUSTOMIZE_ITEM = 230005;
export const PRINT_CUSTOMIZE_ITEM = 230007;
export const SUBMIT_CUSTOMIZE_ITEM = 230011;
export const APPROVE_CUSTOMIZE_ITEM = 230012;
export const REJECT_CUSTOMIZE_ITEM = 230013;
export const POST_CUSTOMIZE_ITEM = 230016;