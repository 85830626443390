import * as Constants from "../constants";

export default function(state = {}, action) {
  switch (action.type) {
  case Constants.EDUCATION_SUCCESS:
    return {
      ...state,
      [action.employeeId]: action.educations
    };
  case Constants.EDUCATION_ADD_NEW:
    return {
      ...state,
      [action.employeeId]: [
        action.id, 
        ...state[action.employeeId]
      ]
    };
  case Constants.EDUCATION_DELETE: {
    const deletedIndex = state[action.employeeId].indexOf(action.id);
    let educations = state[action.employeeId];
    educations.splice(deletedIndex, 1);
    return {
      ...state,
      [action.employeeId]: educations
    };
  }
  default:
    return state;
  }
}