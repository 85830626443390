// Lib
import React, { Component } from "react";
import { Form, Button } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";

// Component
import FilterComponent from "@cores/components/filter-box";
import AsyncCheckBox from "@cores/components/async-check-box";
import AsyncDateRangePicker from "@cores/components/async-date-range-picker";
import AsyncEmployeeAutoComplete from "@cores/components/async-auto-complete/employee";
import AsyncStatusAutoComplete from "@cores/components/async-auto-complete/async-status-auto-complete";

// 
import { getQueryParamUrl, toQueryParamDate, getQueryParamFromLocalStorage, updateQueryParamToLocalStorage } from "@modules/helpers";

// Constants
import { ATTENDANCE_STATUS_DATA_SOURCE } from "../../constants";
import { Fragment } from "react";

const FormItem = Form.Item;

class FilterBox extends Component {

  onChange = (checked) => {
    let query = getQueryParamFromLocalStorage();
    if (checked) {
      delete query.employee;
    }
    updateQueryParamToLocalStorage(query);
  }

  beforeUpdateQuery = (query) => {
    if (query.includeAll) {
      delete query.includeAll;
    }
    return query;
  }

  onLastMonthClick = () => {
    let query = getQueryParamFromLocalStorage();
    const startOfLastMonth = moment().subtract(1, "month").startOf("month");
    const endOfLastMonth = moment().subtract(1, "month").endOf("month");
    query["from"] = toQueryParamDate(startOfLastMonth);
    query["to"] = toQueryParamDate(endOfLastMonth);
    updateQueryParamToLocalStorage(query);
  }

  render() {
    const { 
      currentUser, 
      isRangeOnly,
      children,
      viewOtherEmployee, 
      isCurrentUserPage,
      ...props
    } = this.props;
    const employeeId = currentUser && currentUser.employee && currentUser.employee.id;
    const query = getQueryParamUrl();
    let isShowCurrentUser = true;
    if (query.includeAll) {
      isShowCurrentUser = false;
    }
    return (
      <FilterComponent 
        enableText={false}
        enableArchiveFilter={false}
        extraFilter={
          (viewOtherEmployee && !isCurrentUserPage) && (
            <Fragment>
              <FormItem>
                <AsyncCheckBox 
                  label="All" 
                  name="includeAll" 
                  onChange={this.onChange}
                />
              </FormItem>
              <FormItem>
                <AsyncCheckBox 
                  label="Terminated" 
                  name="terminated" 
                />
              </FormItem>
            </Fragment>
          )} 
        {...props}
      >
        {viewOtherEmployee && !isCurrentUserPage && (
          <AsyncEmployeeAutoComplete 
            mode="single" 
            extraIds={[employeeId]}
            beforeUpdateQuery={this.beforeUpdateQuery}
            defaultValue={isShowCurrentUser ? employeeId : undefined}
          />
        )}
        {children}
        <AsyncDateRangePicker 
          showToday
          toName="to"
          fromName="from"
          typeOfRange="month"
        />
        <FormItem>
          <Button 
            icon={<CalendarOutlined />}
            onClick={() => this.onLastMonthClick()}
          >
            Last Month
          </Button>
        </FormItem>
        {!isRangeOnly && (
          <AsyncStatusAutoComplete 
            dataSource={ATTENDANCE_STATUS_DATA_SOURCE} 
          />
        )}
      </FilterComponent>
    );
  }
}

FilterBox.propTypes = {
  children: PropTypes.any,
  isRangeOnly: PropTypes.bool,
  currentUser: PropTypes.object,
  viewOtherEmployee: PropTypes.bool,
  isCurrentUserPage: PropTypes.bool,
};

export default FilterBox;