import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { denormalize } from "normalizr";
import { Select, Form, Divider } from "antd";
import { PlusCircleOutlined, FileSearchOutlined } from "@ant-design/icons";

// utils
import { createSubmitAction } from "@/helpers";
import { getPermissionAllowed } from "@modules/utils/value-selector";
import { CustomFieldArraySchema } from "@modules/settings/setup/custom-field/schema";
import * as CreateLayoutConstants from "@/components/layout/create-layout/constant";

// Components
import ModalCreate from "./create-modal";

// Style
import "./index.less";

const Option = Select.Option;

class SelectAutoCompleteField extends React.Component {

  state = {
    visible: false,
    newData: [],
  }

  onShowModal = () => {
    this.setState({
      visible: true,
    });
  }

  onCancel = () => {
    this.setState({
      visible: false,
    });
  }

  onChangeHandler = (value) => {
    const { 
      handleChange, 
      input: { onChange }
    } = this.props;
    handleChange(value, onChange);
  }

  onHandleBlur = (e) => {
    const { handleBlur } = this.props;
    if (handleBlur) {
      handleBlur(e);
    }
  }

  renderValues = () => {
    const { 
      mode,
      loading,
      stringValue,
    } = this.props;
    let { input: { value } } = this.props;
    if (mode == "multiple") {
      value = value ? value : [];
      if (value.length > 0) {
        value = value.map(val => Number(val));
      }
      value = loading ? [] : value;
    } else {
      value = typeof(value) == "string" ? stringValue ? value : Number(value) : value;
      value = loading ? undefined : value;
      value = value ? value : undefined;
    }
    return value;
  }

  handleSubmitHandler = (values, dispatch) => {
    const {
      label, 
      createAction, 
      beforeSubmit,
      entities,
      customFields,
      onSelectChange,
      input: { onChange, name },
    } = this.props;
    const customFieldDataSource = denormalize(customFields, CustomFieldArraySchema, entities);
    let { submitRules } = this.props;
    values[CreateLayoutConstants.SAVE_AND_CONTINUE_CREATE] = true;
    values["formName"] = `create${name}`;
    values["entityTitle"] = label;
    if (typeof submitRules === "function") {
      submitRules = submitRules(values);
    }
    if (customFieldDataSource && customFieldDataSource.length > 0) {
      const subFields = [];
      customFieldDataSource.map((value, index) => {
        const { name, id, isRequired } = value;
        const customFieldSubmitRulesSubFields = {
          name: `${id}_${name}`,
          required: isRequired,
          inputKey: index
        };
        subFields.push(customFieldSubmitRulesSubFields);
      });
      const customFieldSubmitRules = {
        customFieldsArrayField: {
          type: "arrayInlineCustomField",
          subFields
        }
      };
      submitRules = Object.assign(submitRules, customFieldSubmitRules);
    }
    if (values.customFieldsArrayField && values.customFieldsArrayField.length > 0) {
      const newCustomFieldValues = [];
      values.customFieldsArrayField.map((value) => {
        let subValue = {};
        if (value) {
          const fieldKey = Object.keys(value).toString();
          const customFieldKey = fieldKey.split("_");
          const id = Number(customFieldKey[0]);
          subValue.id = id;
          subValue.customFieldValue = {
            value: value[fieldKey]
          };
        }
        newCustomFieldValues.push(subValue);
      });
      values.customFields = newCustomFieldValues;
    }
    return createSubmitAction(submitRules, createAction, () => {
      if (beforeSubmit) {
        return beforeSubmit(values);
      } else {
        return values;
      }
    })(values, dispatch).then((value) => {
      if (value != null) {
        const { newData } = this.state;
        if (newData.length > 0) {
          const newValue = [value];
          this.setState({
            newData: [
              ...newValue,
              ...this.state.newData,
            ]
          });
        } else {
          this.setState({
            newData: [value]
          });
        }
        onChange(value.id);
        if (onSelectChange) {
          onSelectChange(value);
        }
        this.onCancel();
      }
    });
  }

  render() {
    const { 
      label,
      entity,
      required,
      meta: { touched, error },
      input: { onFocus, name },
      valueKey,
      titleKey,
      optionRender,
      optionDisabled,
      mode,
      loading,
      onSearch,
      withFilter,
      stringValue,
      advance,
      onShowModal,
      maxWidth,
      disabled,
      isAllowCreate,
      formComponent,
      onSubmit,
      createLoading,
      requestName,
      initialValues,
      ...props
    } = this.props;
    let { style } = this.props;
    const { visible } = this.state;
    let { placeholder, autoCompleteDataSource } = this.props;
    placeholder = loading ? "Loading..." : placeholder;
    const validateStatus = classnames(touched && error ? "error" : "success");
    const addOnClassName = classnames(advance && "ant-input-group");
    const disabledClassName = classnames(disabled == true && "disabled");
    const values = this.renderValues();
    if (label == null && withFilter) {
      const defaultStyle = {
        width: "auto",
        minWidth: placeholder == "Item" ? "300px" : "150px",
      };
      style = Object.assign(defaultStyle, style);
    }
    if (label == null) {
      const defaultStyle = {
        maxWidth: maxWidth || "300px",
      };
      style = Object.assign(defaultStyle, style);
    }
    const { newData } = this.state;
    if (newData.length > 0) {
      autoCompleteDataSource = [
        ...newData,
        ...autoCompleteDataSource
      ];
    }
    return (
      <Fragment>
        <Form.Item
          label={label} 
          validateStatus={validateStatus}
          help={mode == "single" ? error : error && error._error || error}
          required={required}
        >
          <span className="ant-input-group-wrapper">
            <span className={`ant-input-wrapper ${addOnClassName}`}>
              <Select
                mode={mode}
                showSearch
                style={style}
                loading={loading}
                onFocus={onFocus}
                onSearch={onSearch}
                disabled={disabled}
                allowClear={!required}
                onBlur={this.onHandleBlur}
                value={undefined || values}
                placeholder={placeholder}
                filterOption={false}
                optionFilterProp="children"
                onChange={this.onChangeHandler}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {isAllowCreate && !(label == null && withFilter) && (
                      <Fragment>
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{ padding: "2px 10px 5px", cursor: "pointer" }}
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => this.onShowModal()}
                        >
                          <PlusCircleOutlined /> Add New
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
                {...props}
              >
                {autoCompleteDataSource && autoCompleteDataSource.map((val, key) => {
                  const value = typeof val[valueKey] == "string" ? stringValue ? val[valueKey] : parseInt(val[valueKey]) : val[valueKey];
                  return (
                    <Option disabled={optionDisabled == null ? false : optionDisabled(val)} key={key} value={value}>
                      {typeof optionRender != "function"
                        ? val[titleKey]
                        : optionRender(val)}
                    </Option>
                  );
                })}
              </Select>
              {advance && (
                <span className={`ant-input-group-addon ${disabledClassName}`} onClick={disabled == true ? null : onShowModal}>
                  <FileSearchOutlined />
                </span>
              )}
            </span>
          </span>
        </Form.Item>
        <ModalCreate 
          label={label}
          entity={entity}
          visible={visible}
          onSave={onSubmit}
          form={`create${name}`}
          onCancel={this.onCancel}      
          formName={`create${name}`}
          formComponent={formComponent}
          createLoading={createLoading}
          requestName={requestName}
          handleSubmitHandler={this.handleSubmitHandler}
          initialValues={initialValues}
        />
      </Fragment>
    );
  }
}

SelectAutoCompleteField.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  mode: PropTypes.string,
  entity: PropTypes.any,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  advance: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  valueKey: PropTypes.string,
  handleBlur: PropTypes.func,
  withFilter: PropTypes.bool,
  titleKey: PropTypes.string,
  maxWidth: PropTypes.string,
  stringValue: PropTypes.bool,
  onShowModal: PropTypes.func,
  filterOption: PropTypes.func,
  optionRender: PropTypes.func,
  optionDisabled: PropTypes.func,
  handleChange: PropTypes.func,
  isAllowCreate: PropTypes.bool,
  placeholder: PropTypes.string,
  createLoading: PropTypes.bool,
  createAction: PropTypes.func,
  formComponent: PropTypes.string,
  submitRules: PropTypes.any,
  beforeSubmit: PropTypes.any,
  requestName: PropTypes.string,
  entities: PropTypes.object,
  customFields: PropTypes.array,
  autoCompleteDataSource: PropTypes.array,
  onSelectChange: PropTypes.func,
  initialValues: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const { 
    entity ,
    permissionCode, 
    createRequestName, 
  } = ownProps;
  return {
    entities: state.entities,
    customFields: state.customField[entity] || [],
    createLoading: state.request[createRequestName],
    isAllowCreate: getPermissionAllowed(state, permissionCode),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { input: { name } } = ownProps; 
  return {
    onSubmit: () => dispatch(submit(`create${name}`))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAutoCompleteField);