export const DEFAULT_DISPLAY_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DISPLAY_DATE_AND_MONTH_FORMAT = "DD/MM";
export const DEFAULT_ASYNC_DATE_FORMAT = "DD-MM-YYYY";
export const DEFAULT_DISPLAY_MONTH_FORMAT = "MMM";
export const DEFAULT_ASYNC_MONTH_FORMAT = "MM";
export const DEFAULT_DISPLAY_YEAR_FORMAT = "YYYY";
export const DEFAULT_DISPLAY_MONTH_YEAR_FORMAT = "MMM-YYYY";
export const DEFAULT_ASYNC_MONTH_YEAR_FORMAT = "MM-YYYY";
export const DEFAULT_DISPLAY_DATE_TIME_FORMAT = "DD/MM/YYYY hh:mm a";
export const DEFAULT_DISPLAY_DATE_AND_TIME_FORMAT = "DD-MM-YYYY hh:mm a";
export const DEFAULT_DISPLAY_TIME_FORMAT = "hh:mm a";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";
export const DEFAULT_CURRENCY_FORMAT = "0,0.00";
export const DEFAULT_YEAR_AND_DATE_FORMAT = "YYYY-MM-DD";
export const CURRENCY_DEFAULT_NUMBER_FORMAT = "0,0.00";
export const DEFAULT_MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSSZ";

export const MONTH_DATA_SOURCE = [
  {
    id: 1,
    name: "January",
    shortName: "Jan",
  },
  {
    id: 2,
    name: "February",
    shortName: "Feb",
  },
  {
    id: 3,
    name: "March",
    shortName: "Mar",
  },
  {
    id: 4,
    name: "April",
    shortName: "Apr",
  },
  {
    id: 5,
    name: "May",
    shortName: "May",
  },
  {
    id: 6,
    name: "June",
    shortName: "June",
  },
  {
    id: 7,
    name: "July",
    shortName: "July",
  },
  {
    id: 8,
    name: "August",
    shortName: "Aug",
  },
  {
    id: 9,
    name: "September",
    shortName: "Sept",
  },
  {
    id: 10,
    name: "October",
    shortName: "Oct",
  },
  {
    id: 11,
    name: "November",
    shortName: "Nov",
  },
  {
    id: 12,
    name: "December",
    shortName: "Dec",
  },
];

export const DAY_DATA_SOURCE = [
  {
    id: 1,
    name: "Monday",
    shortName: "Mon",
  },
  {
    id: 2,
    name: "TuesDay",
    shortName: "Tue",
  },
  {
    id: 3,
    name: "Wednesday",
    shortName: "Wed",
  },
  {
    id: 4,
    name: "Thursday",
    shortName: "Thu",
  },
  {
    id: 5,
    name: "Friday",
    shortName: "Fri",
  },
  {
    id: 6,
    name: "Saturday",
    shortName: "Sat",
  },
  {
    id: 7,
    name: "Sunday",
    shortName: "Sun",
  },
];