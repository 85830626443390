import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// Style
import "./style.less";

class DisableBox extends React.Component {

  render() {
    const {
      tag: Tag,
      message,
      className,
      description,
    } = this.props;
    return (
      <div className="wrapper-overlay-box">
        <div className="overlay-box-content">
          {_.isArray(message) ? (
            <Fragment>
              <Tag className={`text-primary ${className}`}>
                {message["title"]}
              </Tag>
              <h5 className={`text-primary ${className}`}>
                {message["description"]}
              </h5>
              {message.map(value => {
                return (
                  <Fragment key={value}>
                    <ul>
                      <li className="text-primary">
                        <h5>{value}</h5>
                      </li>
                    </ul>
                  </Fragment>
                );
              })}
            </Fragment>
          ) : (
            <Fragment>
              <Tag className={`text-primary ${className}`}>
                {message}
              </Tag>
              <h6 className={`text-primary font-weight-normal ${className}`}>
                {description}
              </h6>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

DisableBox.propTypes = {
  tag: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

DisableBox.defaultProps = {
  tag: "h4",
};

export default DisableBox;