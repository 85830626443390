/* eslint-disable indent */
/* eslint-disable no-unused-vars */
// Libs
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import styled from "styled-components";
import { connect } from "react-redux";
import tinycolor from "tinycolor2";
import { Menu, Layout, Button, Dropdown, Avatar, Switch } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import Sun from "@/images/icons/sun.svg";
import Moon from "@/images/icons/moon.svg";

// Util
import { setLocale } from "@/actions/locale";
import { getDefaultCompany } from "@modules/utils/value-selector";

// Components
import HeaderMenu from "./menu";
import SearchBox from "./search-box";
// import LanguageDropDown from "./language";

// Actions
import {
  markAllRead,
  getNotificationUnreadCount,
} from "@/actions/notification";

// Style
import "./index.less";
import AvatarDropdown from "./avatar-dropdown";
import NoticeIcon from "./notice-icon";

const { Header } = Layout;

const HeaderCollapse = styled(Header)`
  width: ${(props) =>
    props.shouldToggle && props.collapsed
      ? "calc(100% - 80px)"
      : "calc(100% - 0px)"} !important;
  background: ${(props) =>
    props.isDark == true ? "#1f1f1f" : props.navBackground} !important;

  .ant-menu {
    background: ${(props) =>
      props.isDark == true ? "#1f1f1f" : props.navBackground} !important;
    a {
      color: ${(props) =>
        props.isDark == true ? "#FFFFFF" : props.navLinkColor} !important;
    }
  }

  .ant-menu-item:hover {
    background: ${(props) =>
      props.isDark == true
        ? "inhertit"
        : tinycolor(props.navBackground)
            .lighten()
            .toString()} !important;
  }

  .ant-menu-item > a {
    color: ${(props) =>
      props.isDark == true ? "#FFFFFF" : props.navLinkColor} !important;
  }

  .ant-menu-item-selected {
    background: ${(props) =>
      props.isDark == true
        ? "inhertit"
        : tinycolor(props.navBackground)
            .lighten()
            .toString()} !important;
    a {
      color: ${(props) =>
        props.isDark == true
          ? props.activeColor
          : props.activeColor} !important;
    }
  }

  .icon,
  .anticon,
  span {
    color: ${(props) =>
      props.isDark == true ? "inhertit" : props.navLinkColor} !important;
  }

  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-switch {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
`;

const HeaderWrapper = styled(Header)`
  background: ${(props) =>
    props.isDark == true ? "#1f1f1f" : props.navBackground} !important;
  .icon,
  .anticon,
  span {
    color: ${(props) =>
      props.isDark == true ? "inhertit" : props.navLinkColor} !important;
  }

  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-switch {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
`;

const WrapperDropDownItem = styled.div`
  .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: ${(props) =>
      props.isDark == true
        ? "inhertit"
        : tinycolor(props.navBackground)
            .lighten()
            .toString()} !important;
    a {
      color: ${(props) =>
        props.isDark == true
          ? props.activeColor
          : props.activeColor} !important;
    }
  }

  .ant-menu {
    background: ${(props) =>
      props.isDark == true ? "#1f1f1f" : props.navBackground} !important;
    a {
      color: ${(props) =>
        props.isDark == true ? "#FFFFFF" : props.navLinkColor} !important;
    }
  }
`;

function LayoutHeader(props) {
  // const [fullScreen, setFullScreen] = useState(false);
  const [dropDownVisible, setDropDownVisible] = React.useState(false);

  React.useEffect(() => {
    const { getNotificationUnreadCount } = props;
    getNotificationUnreadCount();
  }, []);

  const renderHeaderMenu = (rest) => {
    return (
      <WrapperDropDownItem {...rest}>
        <HeaderMenu mode="inline" theme={props.theme} isCollapseType={true}>
          <Menu.Divider />
          {/* <Menu.Item key="1">
            <SearchBox
              width="100%"
              isCollapse={false}
            />
          </Menu.Item> */}
          <Menu.Item className="right">
            <AvatarDropdown
              theme={theme}
              isDark={theme == "dark"}
              activeColor={activeColor}
              navLinkColor={navLinkColor}
              navBackground={navBackground}
              activeBackground={activeBackground}
            />
          </Menu.Item>
        </HeaderMenu>
      </WrapperDropDownItem>
    );
  };

  // const onFullScreen = () => {
  //   var element = document.querySelector("body");
  //   element.requestFullscreen().then(() => {
  //     setFullScreen(true);
  //   }).catch((error) => {
  //     message.error(error.message);
  //   });
  // };

  // const onExitFullScreen = () => {
  //   document.exitFullscreen().then(() => {
  //     setFullScreen(false);
  //   }).catch((error) => {
  //     message.error(error.message);
  //   });
  // };

  const { onThemeChange } = props;

  const onThemeSwitchChange = (checked) => {
    const theme = checked ? "light" : "dark";
    onThemeChange(theme);
  };

  const {
    lang,
    count,
    theme,
    setLocale,
    collapsed,
    markAllRead,
    shouldToggle,
    getDefaultCompany,
  } = props;
  const {
    showLogo,
    showName,
    logoImageUrl,
    activeColor,
    navLinkColor,
    navBackground,
    activeBackground,
    name: companyName,
  } = getDefaultCompany;
  return (
    <Media queries={{ small: { maxWidth: 1024 } }}>
      {(matches) =>
        !matches.small ? (
          <HeaderCollapse
            theme={theme}
            collapsed={collapsed}
            isDark={theme == "dark"}
            activeColor={activeColor}
            shouldToggle={shouldToggle}
            navLinkColor={navLinkColor}
            navBackground={navBackground}
            activeBackground={activeBackground}
            className="fixed-header header-padding"
          >
            <Fragment>
              <div className="d-flex align-items-center header px-2">
                <span
                  className="text-white company-name"
                  style={{ minWidth: "220px" }}
                >
                  {showLogo && <Avatar shape="square" src={logoImageUrl} />}{" "}
                  {showName && companyName}
                </span>
                <HeaderMenu theme={theme} />
              </div>
              <div className="d-flex align-items-center right">
                {/* {fullScreen ? (
                  <FullscreenExitOutlined 
                    alt="Exit Fullscreen"
                    onClick={onExitFullScreen}
                  />
                ) : (
                  <FullscreenOutlined 
                    alt="Fullscreen"
                    onClick={onFullScreen}
                  />
                )} */}
                <div className="action">
                  <SearchBox />
                </div>
                <div className="action">
                  <Switch
                    // className="mx-1"
                    checked={theme == "light"}
                    onChange={onThemeSwitchChange}
                    defaultChecked={theme == "light"}
                    checkedChildren={<img src={Moon} className="d-flex" />}
                    unCheckedChildren={<img src={Sun} className="d-flex" />}
                  />
                </div>
                {/* <Notification
                  count={count}
                  theme={theme}
                  isDark={theme == "dark"}
                  onMarkAllRead={markAllRead}
                /> */}
                {/* <ShortcutCreate /> */}
                {/* <LanguageDropDown 
                  lang={lang}
                  onClick={(lang) => setLocale(lang)} 
                /> */}
                <NoticeIcon
                  count={count}
                  className="action"
                  clearText="Empty"
                />
                <AvatarDropdown
                  theme={theme}
                  isDark={theme == "dark"}
                  activeColor={activeColor}
                  navLinkColor={navLinkColor}
                  navBackground={navBackground}
                  activeBackground={activeBackground}
                />
              </div>
            </Fragment>
          </HeaderCollapse>
        ) : (
          <HeaderWrapper
            theme={theme}
            className="fixed-header"
            isDark={theme == "dark"}
            activeColor={activeColor}
            navLinkColor={navLinkColor}
            navBackground={navBackground}
            activeBackground={activeBackground}
          >
            <div className="d-flex align-items-center">
              <span className="text-white company-name">{companyName}</span>
            </div>
            <div className="d-flex align-items-center ml-auto right">
              <Switch
                checked={theme == "light"}
                onChange={onThemeSwitchChange}
                defaultChecked={theme == "light"}
                checkedChildren={<img src={Moon} className="d-flex" />}
                unCheckedChildren={<img src={Sun} className="d-flex" />}
              />
              <NoticeIcon count={count} className="action" clearText="Empty" />
              <Dropdown
                trigger={["click"]}
                open={dropDownVisible}
                onOpenChange={setDropDownVisible}
                overlayClassName="wrapper-menu-dropdown"
                overlay={() =>
                  renderHeaderMenu({
                    navBackground,
                    navLinkColor,
                    activeColor,
                    activeBackground,
                    isDark: theme == "dark",
                  })
                }
              >
                <span>
                  <Button ghost type="primary">
                    <BarsOutlined className="d-flex" />
                  </Button>
                </span>
              </Dropdown>
            </div>
          </HeaderWrapper>
        )
      }
    </Media>
  );
}

LayoutHeader.propTypes = {
  theme: PropTypes.string,
  lang: PropTypes.string,
  logo: PropTypes.string,
  count: PropTypes.number,
  onToggle: PropTypes.func,
  collapsed: PropTypes.bool,
  setLocale: PropTypes.func,
  markAllRead: PropTypes.func,
  onThemeChange: PropTypes.func,
  shouldToggle: PropTypes.bool,
  getDefaultCompany: PropTypes.object,
  getNotificationUnreadCount: PropTypes.func,
};

LayoutHeader.defaultProps = {
  shouldToggle: false,
  collapsed: false,
};

const mapStateToProps = (state) => {
  return {
    lang: state.locale.locale,
    count: state.notification.count,
    getDefaultCompany: getDefaultCompany(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocale: (lang) => dispatch(setLocale(lang)),
    markAllRead: () => dispatch(markAllRead()),
    getNotificationUnreadCount: () => dispatch(getNotificationUnreadCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHeader);
