import { onCustomerPriceListValidation } from "../helpers";

export const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_REQUEST_SUCCESS";
export const CUSTOMER_CREATE_REQUEST = "CUSTOMER_CREATE_REQUEST";
export const CUSTOMER_DETAIL_REQUEST = "CUSTOMER_DETAIL_REQUEST";
export const CUSTOMER_ARCHIVE_REQUEST = "CUSTOMER_ARCHIVE_REQUEST";
export const CUSTOMER_DELETE_REQUEST = "CUSTOMER_DELETE_REQUEST";
export const CUSTOMER_UNARCHIVE_REQUEST = "CUSTOMER_UNARCHIVE_REQUEST";
export const CUSTOMER_EDIT_REQUEST = "CUSTOMER_EDIT_REQUEST";
export const CUSTOMER_AUTOCOMPLETE_REQUEST = "CUSTOMER_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_CREATE_FORM_NAME = "createCustomer";
export const CUSTOMER_EDIT_FORM_NAME = "editCustomer";
export const CUSTOMER_PRICE_LIST_FIELD_ARRAY_NAME = "customerPriceLists";
export const SUSPEND_CUSTOMER_REQUEST = "SUSPEND_CUSTOMER_REQUEST";
export const ACTIVE_CUSTOMER_REQUEST = "ACTIVE_CUSTOMER_REQUEST";
export const CREATE_USER_FOR_CUSTOMER_REQUEST = "CREATE_USER_FOR_CUSTOMER_REQUEST";

export const CUSTOMER_SUBMIT_RULES = (values) => {
  const { type, isAccountingInstalled, isAllowedCustomerCommission, creditDay, unlimitedCredit } = values;
  return {
    firstName: {
      required: true
    },
    type: {
      required: true
    },
    customerPriceLists: {
      required: false,
      type: "array",
      validation: onCustomerPriceListValidation,
    },
    customerCompanyId: {
      required: type == CUSTOMER_TYPE_COMPANY_ID
    },
    address: {
      required: type != CUSTOMER_TYPE_GENERAL_ID
    },
    phoneNumber: {
      required: type != CUSTOMER_TYPE_GENERAL_ID
    },
    receivableChartAccountId: {
      required: isAccountingInstalled
    },
    bidChartAccountId: {
      required: isAccountingInstalled
    },
    customerCommissionChartAccountId: {
      required: isAccountingInstalled && isAllowedCustomerCommission
    },
    defaultPaymentId: {
      required: type == CUSTOMER_TYPE_COMPANY_ID
    },
    paymentTermId: {
      required: type == CUSTOMER_TYPE_INDIVIDUAL_ID
    },
    creditLimit: {
      required: type == CUSTOMER_TYPE_INDIVIDUAL_ID && creditDay > 0 && !unlimitedCredit,
    },
  };
};

export const READ_CUSTOMER = 192000;
export const CREATE_CUSTOMER = 192001;
export const UPDATE_CUSTOMER = 192002;
export const DELETE_CUSTOMER = 192003;
export const ARCHIVE_CUSTOMER = 192004;
export const UNARCHIVE_CUSTOMER = 192005;
export const VIEW_UPDATE_CUSTOMER_CHART_ACCOUNT = 192011;
export const SUSPEND_CUSTOMER = 192012;
export const ACTIVE_CUSTOMER = 192013;
export const CREATE_USER_FOR_CUSTOMER = 192014;

export const CUSTOMER_TYPE_INDIVIDUAL_ID = 1;
export const CUSTOMER_TYPE_INDIVIDUAL_NAME = "Individual";
export const CUSTOMER_TYPE_COMPANY_ID = 2;
export const CUSTOMER_TYPE_COMPANY_NAME = "Company";
export const CUSTOMER_TYPE_GENERAL_ID = 3;
export const CUSTOMER_TYPE_GENERAL_NAME = "General";
export const CUSTOMER_TYPE_APP_ID = 4;
export const CUSTOMER_TYPE_APP_NAME = "App";

export const CUSTOMER_TYPE_DATASOURCE = [
  {
    id: CUSTOMER_TYPE_INDIVIDUAL_ID,
    name: CUSTOMER_TYPE_INDIVIDUAL_NAME
  },
  {
    id: CUSTOMER_TYPE_COMPANY_ID,
    name: CUSTOMER_TYPE_COMPANY_NAME
  },
  {
    id: CUSTOMER_TYPE_GENERAL_ID,
    name: CUSTOMER_TYPE_GENERAL_NAME
  },
  {
    id: CUSTOMER_TYPE_APP_ID,
    name: CUSTOMER_TYPE_APP_NAME
  },
];

export const CUSTOMER_STATUS_ACTIVE = 1;
export const CUSTOMER_STATUS_ACTIVE_TITLE = "Active";
export const CUSTOMER_STATUS_INACTIVE = 2;
export const CUSTOMER_STATUS_INACTIVE_TITLE = "Inactive";
export const CUSTOMER_STATUS_SUSPEND = 3;
export const CUSTOMER_STATUS_SUSPEND_TITLE = "Suspend";

export const CUSTOMER_STATUS_DATA_SOURCE = [
  {
    id: CUSTOMER_STATUS_ACTIVE,
    name: CUSTOMER_STATUS_ACTIVE_TITLE
  },
  {
    id: CUSTOMER_STATUS_INACTIVE,
    name: CUSTOMER_STATUS_INACTIVE_TITLE
  },
  {
    id: CUSTOMER_STATUS_SUSPEND,
    name: CUSTOMER_STATUS_SUSPEND_TITLE
  }
];