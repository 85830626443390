// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "@cores/components/select-autocomplete";

// Action 
import { getNoSeriesListAutoComplete } from "@modules/settings/setup/no-series-list/actions";

// Constant 
import { NO_SERIES_LIST_AUTOCOMPLETE_REQUEST } from "@modules/settings/setup/no-series-list/constants";

class NoSeriesListAutoComplete extends Component {

  render() {
    const {  
      ids,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        titleKey="code"
        extra={{
          ids
        }}
        {...props}
      />
    );
  }
}

NoSeriesListAutoComplete.propTypes = {
  ids: PropTypes.array,
};

NoSeriesListAutoComplete.defaultProps = {
  label: "No Series List",
  name: "noSeriesListId",
  required: true,
  action: getNoSeriesListAutoComplete,
  requestName: NO_SERIES_LIST_AUTOCOMPLETE_REQUEST,
};

export default NoSeriesListAutoComplete;