// Libs
import React, { Component, Fragment } from "react";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PrinterOutlined, BarsOutlined } from "@ant-design/icons";

// Action 
import { getAll } from "@modules/hr/payroll/payslip/actions";
import { PayslipArraySchema } from "@modules/hr/payroll/payslip/schema";

// Constant
import { 
  REPORT_TEMPLATE_FORMAT_PDF,
  REPORT_TEMPLATE_FORMAT_EXCEL,
} from "@modules/settings/setup/report-template/constants";
import { 
  PAYSLIP_LIST_REQUEST, 
  PAYSLIP_STATUS_COMPLETED 
} from "@modules/hr/payroll/payslip/constants";

// Components
import PayslipDetail from "./payslip-detail";
import ListLayout from "@/components/layout/list-layout";
import PayslipConfirmPassword from "../payslip-confirm-password";
import FilterComponent from "@modules/hr/payroll/payslip/components/filter-box";

// Utils
import { renderMonth } from "@modules/helpers";
import * as services from "@/services/print-format";
import { paySlipStatus } from "@modules/hr/payroll/payslip/helpers";
import { getAccessToken } from "@/shared/utils";
import { REPORT_TEMPLATE_ENGINE_STIMULSOFT } from "src/modules/settings/setup/report-template/constants";

class PayslipList extends Component {

  state = {
    visible: false,
    data: null,
    loading: true,
    error: false,
    reportType: null,
    payslipId: null,
    viewDetail: false,
    visibleDetail: false,
    stimulsoftLoaded: false,
  };

  componentWillUnmount() {
    const iframes = document.getElementById("iframe-print");
    if (iframes) {
      document.body.removeChild(iframes);
    }
  }

  arrayBuffer2String = (buffer) => {
    return String.fromCharCode.apply(null, new Uint8Array(buffer));
  }

  onVisible = (id, viewDetail) => {
    this.setState({
      payslipId: id,
      visible: true,
      viewDetail,
      visibleDetail: false,
    });
  }

  onVisibleDetail = () => {
    this.setState({
      visibleDetail: true,
      visible: false
    });
  }

  onDismiss = () => {
    this.setState({
      visible: false,
      visibleDetail: false,
    });
  }

  loadStimulsoftReport = () => {
    if (this.state.stimulsoftLoaded == false) {
      let reportScript = document.querySelector("script[src=\"/report/stimulsoft.reports.js\"]");
      if (!reportScript) {
        reportScript = document.createElement("script");
        reportScript.type = "application/javascript";
        reportScript.src = "/report/stimulsoft.reports.js";
        document.body.appendChild(reportScript);
      } else {
        this.loadStimulsoftViewer();
      }
      reportScript.addEventListener("load", this.loadStimulsoftViewer);
    }
  };

  loadStimulsoftViewer = () => {
    let viewerScript = document.querySelector("script[src=\"/report/stimulsoft.viewer.js\"]");
    if (!viewerScript) {
      viewerScript = document.createElement("script");
      viewerScript.type = "application/javascript";
      viewerScript.src = "/report/stimulsoft.viewer.js";
      document.body.appendChild(viewerScript);
      viewerScript.addEventListener("load", this.onStimulsoftScriptLoaded);
    } else {
      this.onStimulsoftScriptLoaded();
    }
  };

  onStimulsoftScriptLoaded = () => {
    this.setState({
      stimulsoftLoaded: true
    });
  }

  componentDidMount() {
    this.loadStimulsoftReport();
  }
  

  onSuccess = (viewDetail) => {
    if (viewDetail) {
      this.onVisibleDetail();
    } else {
      const { payslipId } =  this.state;
      const values = {
        id: payslipId, 
        entity: "Payslip", 
      };
      services
        .getPrintFormatTemplate(values)
        .then((response) => {
          let data = null;
          let reportType = null;
          const contentType = response.headers["content-type"];
          if (contentType == "application/pdf") {
            reportType = REPORT_TEMPLATE_FORMAT_PDF;
            data = response.data;
            this.onPrintPopUp(data);
          } else if (contentType == "application/json; charset=utf-8") {
            const responseString = this.arrayBuffer2String(response.data);
            data = JSON.parse(responseString);
            if (data.engine == "stimulsoft") {
              reportType = REPORT_TEMPLATE_ENGINE_STIMULSOFT;
              const Stimulsoft = window.Stimulsoft;
              const viewerFrame = document.getElementById("stiPrintReportFrame");
              if (viewerFrame) {
                document.body.removeChild(viewerFrame);
              }
              const viewer = new Stimulsoft.Viewer.StiViewer(undefined, "StiViewer", false);
              const report = new Stimulsoft.Report.StiReport();
              window.StiOptions.WebServer.url = `/stimulsoft?token=${getAccessToken().accessToken}`;
              window.StiOptions.WebServer.encryptData = false;
              Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkA7O0by5iYg3RMl+uVw+MLyU5ammyq1KqqRoZ1EYrLOKC9l9" + 
                "exQ+CcTwr4fzpzxXVXOGulB2GDqGLKaLwtiYGfS0qTP3U9zGEAqWMXyCbGuHs7WMEh4c3XfFu342ztaTpzKjM3LuRK" + 
                "BWhw6qwp3JbE119KtrBBaQiUHI6/v6mG+i4Z6CPRCa+WMxay4ypKUsaXM3iyVa3atjD/GeYGspP8ww91yziZVXlQnT" + 
                "SBJfO5cPxy3gasrLlZRdFzg4//qr0l8asV5+O6u88zLE0d0PJoYQLIbx3su6igwN/i9uargZsHvGpYFrPQWhNBBULb" + 
                "G4wifK+E4YwOpEmPqox4DD17PqW9YuC31MUpZ38C2B7rmod6cWHKw3PtMLiU1fN+STId4u087iaek7gN59HxTSvKJu" + 
                "QWjgrfwXqkE6q4ttyzlg7Tdf5HglkgIYy2nVx9Wkj0yJucK3obwzj+g6a05O/5+KSMs9rjknZL1T3isodRv0lB1nDp" + 
                "VNSNN8hJ//9gzc8=";
              fetch(data.content)
                .then(res => res.json())
                .then((template) => {
                  report.load(template);
                  const param = report.dictionary.variables.getByName("id");
                  if (param != null) {
                    param.value = payslipId;
                  }
                  viewer.report = report;
                  viewer.renderHtml("stimulsoft");
                  viewer.onShowReport = () => {
                    report.print();
                  };
                });
            } else {
              reportType = REPORT_TEMPLATE_FORMAT_EXCEL;
            }
          }
          this.setState({
            error: false,
            loading: false,
            reportType,
          });
        }).catch(() => {
          this.setState({
            error: true,
            loading: false,
            reportType: null,
          });
        });
    }
  }

  onPrintPopUp = (data) => {
    var file = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(file);
    const iframe = document.createElement("iframe");
    iframe.id = "iframe-print";
    iframe.src = url;
    iframe.style = "display: none";
    document.body.append(iframe);
    iframe.focus();
    iframe.contentWindow.print();
  }

  columns = () => {
    return [
      {
        title: "Name",
        sortable: true,
        sortKey: "name",
        dataIndex: "name",
        width: "35%",
      },
      {
        title: "Month-Year",
        dataIndex: "month",
        width: "15%",
        render: (month, row) => {
          const { year } = row;
          return (
            `${renderMonth(month, true)} - ${year}`
          );
        }
      },
      {
        title: "Salary Structure",
        sortable: false,
        width: "20%",
        render: (text, row) => {
          const salaryStructure = row.employee.payroll && row.employee.payroll.salaryStructure;
          if (salaryStructure) {
            return (
              <Fragment>
                {salaryStructure.name}
              </Fragment>
            );
          }
        }
      },
      {
        title: "Status",
        width: "20%",
        dataIndex: "status",
        render: (status) => paySlipStatus(status)
      },
      {
        title: "Print",
        width: "10%",
        dataIndex: "id",
        render: (id) => {
          return (
            <div className="d-flex">
              <Button 
                size="small"
                type="primary" 
                className="mr-1"
                icon={<BarsOutlined />}
                onClick={() => this.onVisible(id, true)} 
              >
                View
              </Button>
              <Button 
                size="small"
                type="primary" 
                icon={<PrinterOutlined />}
                onClick={() => this.onVisible(id, false)} 
              />
            </div>
          );
        }
      }
    ];
  }

  renderFilterComponent = (props) => {
    return (
      <FilterComponent 
        hideEmployee={true}
        {...props}
      />
    );
  }

  render() {
    const filterKeys = [
      "status",
      "month",
      "year",
      "employee",
      "salaryStructure"
    ];
    const { permission, id, currentUser } = this.props;
    const { visible, viewDetail, visibleDetail, payslipId } = this.state;
    return (
      <Fragment>
        <ListLayout
          className="m-0"
          title="Payslip"
          action={getAll}
          showHeader={false}
          creatable={false}
          tableSize="small"
          reducerName="payslip"
          filterKeys={filterKeys}
          permission={permission}
          isPermissionAllowed={true}
          schema={PayslipArraySchema}
          columns={this.columns()}
          requestName={PAYSLIP_LIST_REQUEST}
          filterComponent={this.renderFilterComponent}
          extraFilter={(filter) => {
            filter.employee = [id];
            filter.status = [PAYSLIP_STATUS_COMPLETED];
            return filter;
          }}
        />
        <Modal 
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          onCancel={this.onDismiss}
          visible={visible || visibleDetail}
          width={visibleDetail ? "1200px" :"400px"}
          title={visibleDetail ? "Payslip Detail" : "Confirm Password"}
        >
          {visible && (
            <PayslipConfirmPassword 
              form="ConfirmPayslipPassword" 
              username={currentUser.clientId} 
              onSuccess={this.onSuccess}
              onDismiss={this.onDismiss}
              viewDetail={viewDetail}
            />
          )}
          {visibleDetail && (
            <>
              <PayslipDetail id={payslipId} />
            </>
          )}
        </Modal>
        <div style={{ height: 1200, display: "none" }} id="stimulsoft"></div>
      </Fragment>
    );
  }
}

PayslipList.propTypes = {
  id: PropTypes.number,
  match: PropTypes.object,
  currentUser: PropTypes.object,
  permission: PropTypes.object,
};

export default connect()(PayslipList);