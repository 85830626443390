import { schema } from "normalizr";
import { WarehouseSchema } from "../../warehouse/schema";
// import { SupplierSchema } from "../../supplier/schema";

const StockTransferSchema = new schema.Entity("stockTransfers", {
  sourceWarehouse: WarehouseSchema,
  destinationWarehouse: WarehouseSchema
},
{
  idAttribute: "stockTransferNo",
}
);
const StockTransferArraySchema = new schema.Array(StockTransferSchema);

export { StockTransferSchema, StockTransferArraySchema };