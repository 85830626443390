export const ENTITY_AUTOCOMPLETE_REQUEST = "ENTITY_AUTOCOMPLETE_REQUEST";
import * as PrintFormatConstants from "@/constants/print-format";
import { REPORT_TEMPLATE_ENGINE_STIMULSOFT } from "../../report-template/constants";

export const PRINT_FORMAT_LIST_REQUEST = "PRINT_FORMAT_LIST_REQUEST";
export const PRINT_FORMAT_LIST_SUCCESS = "PRINT_FORMAT_LIST_REQUEST_SUCCESS";
export const PRINT_FORMAT_AUTOCOMPLETE_REQUEST = "PRINT_FORMAT_AUTOCOMPLETE_REQUEST";
export const PRINT_FORMAT_AUTOCOMPLETE_SUCCESS = "PRINT_FORMAT_AUTOCOMPLETE_SUCCESS";
export const PRINT_FORMAT_CREATE_REQUEST = "PRINT_FORMAT_CREATE_REQUEST";
export const PRINT_FORMAT_DETAIL_REQUEST = "PRINT_FORMAT_DETAIL_REQUEST";
export const PRINT_FORMAT_ARCHIVE_REQUEST = "PRINT_FORMAT_ARCHIVE_REQUEST";
export const PRINT_FORMAT_DELETE_REQUEST = "PRINT_FORMAT_DELETE_REQUEST";
export const PRINT_FORMAT_UNARCHIVE_REQUEST = "PRINT_FORMAT_UNARCHIVE_REQUEST";
export const PRINT_FORMAT_EDIT_REQUEST = "PRINT_FORMAT_EDIT_REQUEST";
export const PRINT_FORMAT_CREATE_FORM_NAME = "createPrintFormat";
export const PRINT_FORMAT_EDIT_FORM_NAME = "editPrintFormat";
export const PRINT_FORMATE_CLONE_FORM_NAME = "clonePrintFormat";


export const READ_PRINT_FORMAT = 117000;
export const CREATE_PRINT_FORMAT = 117001;
export const UPDATE_PRINT_FORMAT = 117002;
export const DELETE_PRINT_FORMAT = 117003;
export const ARCHIVE_PRINT_FORMAT = 117004;
export const UNARCHIVE_PRINT_FORMAT = 117005;

export const PRINT_FORMAT_SUBMIT_RULES = (values) => {
  const { options } = values;
  let newFormat = null;
  if (options) {
    const { format } = options;
    newFormat = format;
  }
  if (values.engine == REPORT_TEMPLATE_ENGINE_STIMULSOFT) {
    return {
      title: {
        required: true,
      },
      entityId: {
        required: true,
      },
    };
  }
  const rules = {
    title: {
      required: true,
    },
    engine: {
      required: true,
    },
    format: {
      required: true,
    },
    entityId: {
      required: true,
    },
    content: {
      required: true,
    },
    status: {
      required: true,
    },
    options: {
      type: "object",
      format: {
        required: true,
      },
      width: {
        required: newFormat == PRINT_FORMAT_PAGE_FORMAT_CUSTOM,
      },
      height: {
        required: newFormat == PRINT_FORMAT_PAGE_FORMAT_CUSTOM,
      },
      top: {
        required: true,
      },
      bottom: {
        required: true,
      },
      right: {
        required: true,
      },
      left: {
        required: true,
      },
      orientation: {
        required: true,
      },
    }
  };
  return rules;
};

export const PRINT_FORMAT_STATUS = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Deactive",
  }
];

export const PRINT_FORMAT_PAGE_FORMAT_DATASOURCE = [
  {
    id: 1,
    name: "A3",
  },
  {
    id: 2,
    name: "A4",
  },
  {
    id: 3,
    name: "A5",
  },
  {
    id: 4,
    name: "Legal",
  },
  {
    id: 5,
    name: "Letter",
  },
  {
    id: 6,
    name: "Tabloid",
  },
  {
    id: 7,
    name: "Custom",
  },
];

export const PRINT_FORMAT_PAGE_FORMAT_CUSTOM = "Custom";

export const PRINT_FORMAT_ORIENTATION_DATASOURCE = [
  {
    name: "Portrait",
    value: "portrait",
  },
  {
    name: "Landscape",
    value: "landscape",
  }, 
];

export const PRINT_FORMAT_ENTITY_NAME_DATASOURCE = [
  {
    id: PrintFormatConstants.SALE_ORDER_ID,
    name: PrintFormatConstants.SALE_ORDER_NAME,
  },
  {
    id: PrintFormatConstants.INVOICE_ID,
    name: PrintFormatConstants.INVOICE_NAME,
  },
  {
    id: PrintFormatConstants.PURCHASE_ORDER_ID,
    name: PrintFormatConstants.PURCHASE_ORDER_NAME,
  },
  {
    id: PrintFormatConstants.DELIVERY_ORDER_ID,
    name: PrintFormatConstants.DELIVERY_ORDER_NAME,
  },
  {
    id: PrintFormatConstants.CHART_ACCOUNT_ID,
    name: PrintFormatConstants.CHART_ACCOUNT_NAME,
  },
];