export const CASH_ADVANCED_LIST_REQUEST = "CASH_ADVANCED_LIST_REQUEST"; 
export const CASH_ADVANCED_LIST_SUCCESS = "CASH_ADVANCED_LIST_REQUEST_SUCCESS";
export const CASH_ADVANCED_CREATE_REQUEST = "CASH_ADVANCED_CREATE_REQUEST"; 
export const CASH_ADVANCED_DETAIL_REQUEST = "CASH_ADVANCED_DETAIL_REQUEST"; 
export const CASH_ADVANCED_ARCHIVE_REQUEST = "CASH_ADVANCED_ARCHIVE_REQUEST"; 
export const CASH_ADVANCED_DELETE_REQUEST = "CASH_ADVANCED_DELETE_REQUEST"; 
export const CASH_ADVANCED_UNARCHIVE_REQUEST = "CASH_ADVANCED_UNARCHIVE_REQUEST"; 
export const CASH_ADVANCED_EDIT_REQUEST = "CASH_ADVANCED_EDIT_REQUEST"; 
export const CASH_ADVANCED_CREATE_FORM_NAME = "createCashAdvanced"; 
export const CASH_ADVANCED_EDIT_FORM_NAME = "editCashAdvanced"; 
export const CASH_ADVANCED_AUTOCOMPLETE_REQUEST = "CASH_ADVANCED_AUTOCOMPLETE_REQUEST";
export const CASH_ADVANCED_AUTOCOMPLETE_SUCCESS = "CASH_ADVANCED_AUTOCOMPLETE_SUCCESS";
export const CASH_ADVANCED_SUBMIT_RULES = { 
  employeeId: {
    required: true,
  },
  amount: {
    required: true,
  },
  duration: {
    required: true,
  },
  interestRate: {
    required: true,
  },
  effectiveMonth: {
    required: true,
  },
  effectiveYear: {
    required: true,
  },
  paymentType: {
    required: true
  },
  notation: {
    required: true
  }
};
export const READ_CASH_ADVANCED = 235000;
export const CREATE_CASH_ADVANCED = 235001;
export const UPDATE_CASH_ADVANCED = 235002;
export const DELETE_CASH_ADVANCED = 235003;
export const ARCHIVE_CASH_ADVANCED = 235004;
export const UNARCHIVE_CASH_ADVANCED = 235005;
export const REPORT_CASH_ADVANCED = 71006;
export const SUBMIT_CASH_ADVANCED = 235013;
export const APPROVE_CASH_ADVANCED = 235014;
export const REJECT_CASH_ADVANCED = 235015;
export const POST_CASH_ADVANCED = 235016;
export const DRAFT_CASH_ADVANCED = 235017;
export const PAY_CASH_ADVANCED = 235018;

export const CASH_ADVANCED_SUBMIT_REQUEST = "CASH_ADVANCED_SUBMIT_REQUEST";
export const CASH_ADVANCED_APPROVE_REQUEST = "CASH_ADVANCED_APPROVE_REQUEST";
export const CASH_ADVANCED_REJECT_REQUEST = "CASH_ADVANCED_REJECT_REQUEST";
export const CASH_ADVANCED_POST_REQUEST = "CASH_ADVANCED_POST_REQUEST";
export const CASH_ADVANCED_SET_TO_DRAFT_REQUEST = "CASH_ADVANCED_SET_TO_DRAFT_REQUEST";
export const CASH_ADVANCED_PAY_REQUEST = "CASH_ADVANCED_PAY_REQUEST";

export const CASH_ADVANCED_POST_FORM_NAME = "postCashAdvanced";

export const CASH_ADVANCED_STATUS_DRAFT = 1;
export const CASH_ADVANCED_STATUS_DRAFT_TITLE = "Draft";
export const CASH_ADVANCED_STATUS_SUBMITTED = 2;
export const CASH_ADVANCED_STATUS_SUBMITTED_TITLE = "Submitted";
export const CASH_ADVANCED_STATUS_APPROVED = 3;
export const CASH_ADVANCED_STATUS_APPROVED_TITLE = "Approved";
export const CASH_ADVANCED_STATUS_REJECTED = 4;
export const CASH_ADVANCED_STATUS_REJECTED_TITLE = "Rejected";
export const CASH_ADVANCED_STATUS_POST = 5;
export const CASH_ADVANCED_STATUS_POST_TITLE = "Posted";
export const CASH_ADVANCED_STATUS_PAID = 6;
export const CASH_ADVANCED_STATUS_PAID_TITLE = "Paid";

export const CASH_ADVANCED_LIST_STATUS_UNPAID = 1;
export const CASH_ADVANCED_LIST_STATUS_UNPAID_TITLE = "Unpaid";
export const CASH_ADVANCED_LIST_STATUS_PAID = 2;
export const CASH_ADVANCED_LIST_STATUS_PAID_TITLE = "Paid";

export const PAYMENT_TYPE_FIXED_ID = 1;
export const PAYMENT_TYPE_FIXED_NAME = "Fixed";
export const PAYMENT_TYPE_FLEXIBLE_ID = 2;
export const PAYMENT_TYPE_FLEXIBLE_NAME = "Flexible";
export const PAYMENT_TYPE_DATA_SOURCE = [
  {
    id: PAYMENT_TYPE_FIXED_ID,
    name: PAYMENT_TYPE_FIXED_NAME,
  },
  {
    id: PAYMENT_TYPE_FLEXIBLE_ID,
    name: PAYMENT_TYPE_FLEXIBLE_NAME
  }
];

export const CASH_ADVANCED_STATUS_DATA_SOURCE = [
  {
    id: CASH_ADVANCED_STATUS_DRAFT,
    name: CASH_ADVANCED_STATUS_DRAFT_TITLE,
  },
  {
    id: CASH_ADVANCED_STATUS_SUBMITTED,
    name: CASH_ADVANCED_STATUS_SUBMITTED_TITLE,
  },
  {
    id: CASH_ADVANCED_STATUS_APPROVED,
    name: CASH_ADVANCED_STATUS_APPROVED_TITLE,
  },
  {
    id: CASH_ADVANCED_STATUS_REJECTED,
    name: CASH_ADVANCED_STATUS_REJECTED_TITLE,
  },
  {
    id: CASH_ADVANCED_STATUS_POST,
    name: CASH_ADVANCED_STATUS_POST_TITLE
  },
  {
    id: CASH_ADVANCED_STATUS_PAID,
    name: CASH_ADVANCED_STATUS_PAID_TITLE
  },
];