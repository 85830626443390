import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Upload from "./field";

const renderFileUpload = ({
  input: { onChange, value },
  ...props
}) => {
  return (
    <Upload
      onChange={onChange}
      value={value}
      {...props} 
    />
  );
};

renderFileUpload.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  })
};

class ReduxFileUpload extends React.Component {

  handleChange = (value, onChange) => {
    onChange(value || null);
    const { onChange: handleChange } = this.props;
    if (handleChange) {
      handleChange(value || null);
    }
  }

  render() {
    const {
      name,
      path,
      label,
      icon,
      required,
      onSuccess,
      beforeUpload,
      showUploadList,
    } = this.props;
    return (
      <Field
        name={name}
        icon={icon}
        path={path}
        label={label}
        fileName={label}
        required={required}
        onSuccess={onSuccess}
        beforeUpload={beforeUpload}
        component={renderFileUpload}
        showUploadList={showUploadList}
        handleChange={this.handleChange}
      />
    );
  }
}

ReduxFileUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onSuccess: PropTypes.func,
  required: PropTypes.bool,
  beforeUpload: PropTypes.func,
  showUploadList: PropTypes.bool,
};

ReduxFileUpload.defaultProps = {
  required: false,
};

export default ReduxFileUpload;