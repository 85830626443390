import { schema } from "normalizr";

// Schemas
import { PlanSchema } from "@modules/hr/appraisal/plan/schema";
import { DepartmentArraySchema } from "@modules/hr/employee-directory/department/schema";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";

const appraisalAnniversarySchema = new schema.Entity("anniversaries", {
  appraisalPlan: PlanSchema,
  employee: EmployeeSchema,
  department: DepartmentArraySchema
});

const appraisalAnniversaryArraySchema = new schema.Array(appraisalAnniversarySchema);

export { appraisalAnniversarySchema, appraisalAnniversaryArraySchema };