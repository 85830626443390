import * as EntityConstants from "../../constants/entity";
import merge from "lodash/fp/merge";

const initialState = {
  employees: {},
  positions: {},
  customerGroups: {},
  roles: {},
  contract: {},
  detailSales: {},
  userAccounts: {},
  departments: {},
  permissions: {},
  categories: {},
  warehouses: {},
  priceLists: {},
  suppliers: {},
  customers: {},
  purchaseOrders: {},
  items: {},
  currencies: {},
  chartOfAccounts: {},
  chartOfAccountTypes: {},
  currencyExchanges: {},
  journalEntries: {},
  journalEntryBatches: {},
  saleOrders: {},
  employeeJobHistories: {},
  itemInStocks: {},
  saleInvoices: {},
  paymentMethods: {},
  expenseTypes: {},
  stockTransfers: {},
  chequeEntries: {},
  expenses: {},
  banks: {},
  shippers: {},
  itemMovementLogs: {},
  companyGroups: {},
  promotions: {},
  saleOrderItems: {},
  trucks: {},
  deliveryOrders: {},
  customerDeposits: {},
  reportTemplates: {},
  printFormats: {},
  paymentTerms: {},
  expenseLines: {},
  supplierDeposits: {},
  processPayrolls: {},
  customizeItems: {},
  payslips: {},
  attachments: {},
  countries: {},
  activityLogs: {},
  chartAccountTypes: {},
  bankTransfers: {},
  notifications: {},
  comments: {},
  jobTypes: {},
  companies: {},
  apps: {},
  educations: {},
  employeeContacts: {},
  workExperiences: {},
  languages: {},
  employeePayrolls: {},
  workingDays: {},
  taxes: {},
  deductionTypes: {},
  earningTypes: {},
  salaryStructures: {},
  saleCommissions: {},
  jobOpenings: {},
  recruitmentStages: {},
  offerTerms: {},
  offerLetters: {},
  jobApplicants: {},
  employmentCards: {},
  leaveTypes: {},
  leaveAllocations: {},
  leaveRequests: {},
  leavePolicies: {},
  holidayLists: {},
  holidayGroups: {},
  employeeLeaveApprovers: {},
  employeeLeavePolicies: {},
  employeeLeaveSettings: {},
  employeeLeaveBalances: {},
  attendances: {},
  payslipBatch: {},
  emailTemplates: {},
  emailAccounts: {},
  permissionGroups: {},
  permissionGroupByRoles: {},
  sessions: {},
  noSeriesLists: {},
  customerRefunds: {},
  customerCommissions: {},
  bankEntries: {},
  bankHistories: {},
  purchaseOrderTypes: {},
  supplierTypes: {},
  itemDepartments: {},
  brands: {},
  tags: {},
  itemAttributes: {},
  customerCompanies: {},
  saleTeams: {},
  installations: {},
  services: {},
  stockOuts: {},
  cashAdvances: {},
  itemInstallations: {},
  logInApprovals: {},
  supplierRefunds: {},
  syncItems: {},
  installationTemplates: {},
  customFields: {},
  quotations: {},
  quotationVersions: {},
  employeePayrollIncentives: {},
  payslipBatchEmployees: {},
  contractors: {},
  incentiveTemplates: {},
  eodCustomerCommissions: {},
  eodCustomerDeposits: {},
  eodCustomerRefunds: {},
  eodExpenses: {},
  eodInvoices: {},
  eodPurchaseOrders: {},
  eodSupplierRefunds: {},
  bids: {},
  saleReceipts: {},
  warranties: {},
  assets: {},
  assetRequests: {},
  assetLogs: {},
  junkOuts: {},
  favoriteReports: {},
  saleHistories: {},
  plans: {},
  forms: {},
  anniversaries: {},
  integrations: {},
  remoteConfigurations: {},
  banners: {},
  contacts: {},
  serviceHistories: {},
  shipperPayments: {},
  junkOutTypes: {},
  loans: {},
  itemCatalogs: {},
  orders:{},
  carts: {},
  goals: {},
  targets: {},
  itemSpecifications: {},
  itemSpecificationTemplates: {},
  itemCostings: {},
  rooms: {},
  meetings: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
  case EntityConstants.MERGE_ENTITY:
    return mergeEntity(state, action.entityName, action.key, action.entity);
  case EntityConstants.ADD_ENTITY:
    return addEntity(state, action.entityName, action.key, action.values);
  case EntityConstants.MERGE_ENTITIES:
    return mergeEntities(state, action.entities);
  case EntityConstants.MERGE_AND_REPlACE:
    return mergeAndReplace(state, action.entities);
  case EntityConstants.SYNC_ENTITIES:
    return syncEntities(state, action.entity, action.key, action.idAttribute);
  case EntityConstants.SYNC_ARRAY_ENTITIES:
    return syncArrayEntities(state, action.entity, action.key);
  case EntityConstants.DELETE_ENTITY:
    return deleteEntity(state, action.entityId, action.entityName);
  case EntityConstants.ADD_ENTITY_PERMISSION:
    return addEntityPermission(state, action.roleId, action.entityPermissionId);
  }
  return state;
}

function addEntity(state, entityName, key, values) {
  return {
    ...state,
    [entityName]: {
      ...state[entityName],
      [key]: values
    }
  };
}

function mergeEntity(state, entityName, key, entity) {
  return {
    ...state,
    [entityName]: {
      ...state[entityName],
      [key]: entity
    }
  };
}

function mergeEntities(state, entities) {
  return merge(state, entities);
}

function mergeAndReplace(state, entities) {
  const newState = {
    ...state,
  };
  for (const key in entities) {
    const newEntities = entities[key];
    for (const primarykey in newEntities) {
      if (newState[key] == null) {
        newState[key] = {};
      }
      newState[key][primarykey] = newEntities[primarykey];
    }
  }
  return newState;
}

function syncEntities(state, entity, key, idAttribute) {
  const idName = idAttribute || "id";
  return { 
    ...state, 
    [key]: { 
      ...state[key], 
      [entity[idName]]: entity 
    } 
  };
}

function syncArrayEntities(state, entity, key) {
  return { ...state, [key]: entity };
}

function deleteEntity(state, entityId, entityName) {
  const entities = state[entityName];
  delete entities[entityId];
  return {
    ...state,
    [entityName]: {
      ...entities
    }  
  };
}

function addEntityPermission(state, roleId, entityPermissionId) {
  const entities = state["roles"];
  const role = entities[roleId];
  const entityPermissions = [
    ...role.entityPermissions
  ];
  entityPermissions.push(entityPermissionId);
  return {
    ...state,
    roles: {
      ...entities,
      [roleId]: {
        ...role,
        entityPermissions
      }
    }
  };
}