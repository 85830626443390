import { schema } from "normalizr";
import { RecipientArraySchema } from "./recipients";

const NotificationSchema = new schema.Entity("notifications", {
  notificationRecipients : RecipientArraySchema  
});

const NotificationArraySchema = new schema.Array(NotificationSchema);

export {
  NotificationSchema,
  NotificationArraySchema
};