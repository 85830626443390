
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, List, Card, Tooltip, Badge } from "antd";

// Utils
import { NOTIFICATION_USER_BLOCK } from "@/constants/notification";
import { getNotificationMetaData } from "@/helpers/notification";
import { toDate, toRelative } from "@modules/helpers";

// styles
import "./noticelist.less";

const NoticeList = (props) => {
  const {
    count,
    loading,
    onSeeAll,
    onItemClick,
    onMarkAllRead,
    dataSource = [],
    buildNotificationActions,
  } = props;

  if ((!dataSource || dataSource.length == 0) && loading == false) {
    return (
      <Card bodyStyle={{padding: 0}} title="Notifications" size="small" className="list">
        <div className="notFound">
          <img
            src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
            alt="not found"
          />
          <div>No notification</div>
        </div>
      </Card>
    );
  }

  return (
    <Card bodyStyle={{padding: 0}} title="Notifications" size="small">
      <List
        size="small"
        className="list"
        loading={loading}
        dataSource={dataSource}
        renderItem={(item) => {
          let { creator, createdAt, notificationRecipients }  = item;
          const meta = getNotificationMetaData(item);
          const { text, url, actions, shouldRenderActionButton } = meta;
          const actionsButton = buildNotificationActions(actions, item, shouldRenderActionButton);
          if (creator == null) {
            creator = NOTIFICATION_USER_BLOCK;
          }
          const itemCls = classNames("item", {
            "read": notificationRecipients[0] && notificationRecipients[0].isSeen,
          });
          return (
            <List.Item
              key={item.id}
              className={itemCls}
              onClick={() => onItemClick(item, url)}
            >
              <List.Item.Meta
                className="meta"
                avatar={
                  creator.profileImageUrl ? 
                    <Avatar className="avatar" src={creator.profileImageUrl} /> : 
                    <Avatar icon={<UserOutlined />} />
                }
                title={
                  <div className="title">
                    {`${creator.firstName} ${creator.lastName}`}
                    <div className="extra">
                      {actionsButton}
                    </div>
                  </div>
                }
                description={
                  <div>
                    <div className="description">{text}</div>
                    <div className="datetime">
                      <Tooltip 
                        arrowPointAtCenter
                        placement="topLeft" 
                        title={toDate(createdAt)} 
                      >
                        <small>
                          {toRelative(createdAt)}
                        </small>
                      </Tooltip></div>
                  </div>
                }
              />
              {(notificationRecipients[0] && notificationRecipients[0].isSeen == false) && <Badge status="processing" />}
            </List.Item>
          );
        }}
      />
      <div className="bottomBar">
        {count > 0 ? (
          <div onClick={() => onMarkAllRead()} className="text-primary">
            Mark All Read
          </div>
        ) : (
          <div>
            Mark All Read
          </div>
        )}
        <div onClick={() => onSeeAll()}>
          See All
        </div>
      </div>
    </Card>
  );
};

NoticeList.propTypes = {
  count: PropTypes.number,
  loading: PropTypes.bool,
  onSeeAll: PropTypes.func,
  onItemClick: PropTypes.func,
  onMarkAllRead: PropTypes.func,
  dataSource: PropTypes.array,
  buildNotificationActions: PropTypes.any,
};

export default NoticeList;