import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { Field } from "redux-form";
import classnames from "classnames";
import { FormText } from "reactstrap";
import PhoneInput from "react-phone-number-input";

const FormItem = Form.Item;

function InputField(props) {

  const onChange = (value) => {
    const { input: { onChange }, handleChange } = props;
    const newValue = value == undefined ? "" : value;
    onChange(newValue);
    if (handleChange) {
      handleChange(newValue);
    }
  };

  const { 
    tip,
    label, 
    hide,
    required,
    disabled,
    placeholder,
    meta: { touched, error },
    input: { value, onFocus },
    ...rest
  } = props;
  const validateStatus = classnames(touched && error ? "error" : "success");
  const className = classnames(hide && "d-none");
  return (
    <Fragment>
      <FormItem 
        help={error}
        label={label} 
        required={required}
        validateStatus={validateStatus}
      >
        <PhoneInput
          value={value}
          international
          onFocus={onFocus}
          defaultCountry="KH"
          onChange={onChange}
          disabled={disabled}
          withCountryCallingCode
          placeholder={placeholder}
          className={`${className} ant-input`}
          {...rest}
        />
      </FormItem>
      {tip && (
        <FormText
          color="muted"
          className="mt-0 mb-2"
        >
          {tip}
        </FormText>
      )}
    </Fragment>
  );
}

InputField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  tip: PropTypes.string,
};

InputField.defaultProps = {
  required: false,
  hide: false,
};

function InputRedux(props) {
  return (
    <Field 
      component={InputField}
      {...props} 
    />
  );
}

export default InputRedux;