import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

// Components
import Login from "../payslip-confirm-password";
import JobHistoryList from "@modules/hr/employee-directory/employee/components/detail-component/job-history-list";

class JobHistory extends Component {

  state = {
    visible: false,
  }

  onSuccess = () => {
    this.setState({
      visible: true
    });
  }

  render() {
    const { visible } = this.state;
    const { id, currentUser } = this.props;
    return (
      <div className="p-3">
        {visible ? (
          <JobHistoryList id={id} size="small" isCurrentUserPage={true} />
        ) : (
          <Row>
            <Col md={{size: 4, offset: 4}} xs="12" className="p-4">
              <h4 className="text-center">Please confirm your password to continue</h4>
              <Login 
                form="confirmPassWord" 
                onSuccess={this.onSuccess}
                username={currentUser.clientId} 
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

JobHistory.propTypes = {
  id: PropTypes.number,
  currentUser: PropTypes.object,
};

export default JobHistory;