import * as accounting from "accounting";

export const JOURNAL_ENTRY_REQUEST= "JOURNAL_ENTRY_REQUEST";
export const JOURNAL_ENTRY_SUCCESS= "JOURNAL_ENTRY_REQUEST_SUCCESS";
  
export const JOURNAL_ENTRY_AUTOCOMPLETE_REQUEST = "JOURNAL_ENTRY_AUTOCOMPLETE_REQUEST";
export const JOURNAL_ENTRY_AUTOCOMPLETE_SUCCESS = "JOURNAL_ENTRY_AUTOCOMPLETE_SUCCESS";

export const JOURNAL_ENTRY_CREATE_REQUEST= "JOURNAL_ENTRY_CREATE_REQUEST";
export const JOURNAL_ENTRY_DETAIL_REQUEST= "JOURNAL_ENTRY_DETAIL_REQUEST";
export const JOURNAL_ENTRY_EDIT_REQUEST= "JOURNAL_ENTRY_EDIT_REQUEST";
export const JOURNAL_ENTRY_ARCHIVE_REQUEST= "JOURNAL_ENTRY_ARCHIVE_REQUEST";
export const JOURNAL_ENTRY_UNARCHIVE_REQUEST= "JOURNAL_ENTRY_UNARCHIVE_REQUEST";
export const JOURNAL_ENTRY_DELETE_REQUEST= "JOURNAL_ENTRY_DELETE_REQUEST";
export const JOURNAL_ENTRY_BATCH_DELETE_REQUEST = "JOURNAL_ENTRY_BATCH_DELETE_REQUEST";

export const JOURNAL_ENTRY_STATUS_DRAFT = 1;
export const JOURNAL_ENTRY_STATUS_DRAFT_TITLE = "Draft";

export const JOURNAL_ENTRY_STATUS_POSTED = 2;
export const JOURNAL_ENTRY_STATUS_POSTED_TITLE = "Posted";

export const JOURNAL_ENTRY_BATCH_STATUS = [
  {
    id: JOURNAL_ENTRY_STATUS_DRAFT,
    name: JOURNAL_ENTRY_STATUS_DRAFT_TITLE
  },
  {
    id: JOURNAL_ENTRY_STATUS_POSTED,
    name: JOURNAL_ENTRY_STATUS_POSTED_TITLE,
  },
];

export const JOURNAL_ENTRY_REVERSED = true;
export const JOURNAL_ENTRY_REVERSED_TITLE= "Reversed";

export const JOURNAL_ENTRY_CREATE_FORM_NAME = "createJournalEntry";
export const JOURNAL_ENTRY_EDIT_FORM_NAME = "editJournalEntry";

export const JOURNAL_ENTRY_POST_REQUEST = "JOURNAL_ENTRY_POST_REQUEST";
export const JOURNAL_ENTRY_REVERSE_REQUEST = "JOURNAL_ENTRY_REVERSE_REQUEST";

export const JOURNAL_ENTRY_REFERENCE_TYPE_GENERAL_JOURNAL = 1;
export const JOURNAL_ENTRY_REFERENCE_TYPE_PURCHASE = 2;
export const JOURNAL_ENTRY_REFERENCE_TYPE_SALE = 3;
export const JOURNAL_ENTRY_REFERENCE_TYPE_STOCK_IN = 4;
export const JOURNAL_ENTRY_REFERENCE_TYPE_STOCK_OUT = 5;
export const JOURNAL_ENTRY_REFERENCE_TYPE_STOCK_TRANSFER = 6;
export const JOURNAL_ENTRY_REFERENCE_TYPE_STOCK_ADJUSTMENT = 7;
export const JOURNAL_ENTRY_REFERENCE_TYPE_EXPENSE = 8;
export const JOURNAL_ENTRY_REFERENCE_TYPE_CUSTOMER_DEPOSIT = 9;
export const JOURNAL_ENTRY_REFERENCE_TYPE_CUSTOMER_REFUND = 10;

export const READ_JOURNAL_ENTRY_BATCH = 185000;
export const CREATE_JOURNAL_ENTRY_BATCH = 185001;
export const UPDATE_JOURNAL_ENTRY_BATCH = 185002;
export const DELETE_JOURNAL_ENTRY_BATCH = 185003;
export const ARCHIVE_JOURNAL_ENTRY_BATCH = 185004;
export const UNARCHIVE_JOURNAL_ENTRY_BATCH = 185005;
export const REVERSE_JOURNAL_ENTRY_BATCH = 185011;
export const POST_JOURNAL_ENTRY_BATCH = 185012;
export const POST_MULTIPLE_JOURNAL_ENTRY_BATCH = 185013;

export const JOURNAL_ENTRY_SUBMIT_RULES = {
  date: {
    required: true,
  },
  reference: {
    required: true,
  },
  journalEntries: {
    required: true,
    type: "arrayInline",
    message: "Please add at least 2 journal entries",
    subFields: [
      {
        name: "chartAccountId"
      },
      {
        name: "debitAmount",
        type: "number",
      },
      {
        name: "creditAmount",
        type: "number",
      },
    ],
    validation: (values) => {
      let credit = 0;
      let debit = 0;
      if (values && values.length < 2) {
        return {
          message: "Please add at least 2 journal entries"
        }; 
      } else {
        values.map(value => {
          credit += value.creditAmount;
          debit += value.debitAmount;
        });
        credit = parseFloat(accounting.toFixed(credit, 2));
        debit = parseFloat(accounting.toFixed(debit, 2));
        if (credit != debit) {
          return {
            message: "Credit and debit must be balanced"
          }; 
        }
      }
    }
  }
};

export const JOURNAL_ENTRIES_CREATE_FORM_NAME = "addJournalEntries";
export const JOURNAL_ENTRIES_EDIT_FORM_NAME = "editJournalEntries";
export const JOURNAL_ENTRIES_ARRAY_FIELD_NAME = "journalEntries";

export const REVERSE_JOURNAL_ENTRIES_FORM_NAME = "reverseJournalEntry";
export const REVERSE_JOURNAL_ENTRIES_SUBMIT_RULES = {
  date: {
    required: true,
  },
  reference: {
    required: true,
  },
  description: {
    required: true,
  },
};

export const CHILD_JOURNAL_ENTRY_BATCH_REQUEST = "CHILD_JOURNAL_ENTRY_BATCH_REQUEST";
export const CHILD_JOURNAL_ENTRY_BATCH_SUCCESS = "CHILD_JOURNAL_ENTRY_BATCH_REQUEST_SUCCESS";