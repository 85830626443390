import { getAccessToken } from "@/shared/utils";
import { POST, GET } from "./base-service";

export function getSubscribers(entity, entityId) {
  if (entity == null) {
    return Promise.reject("entity cannot be null");
  }
  if (entityId == null) {
    return Promise.reject("entityId cannot be null");
  }
  const token = getAccessToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }

  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };

  return GET(`/api/subscription/v1/subscribers?entity=${entity}&entityId=${entityId}`, null, headers);
}

export function subscribeEntity(entity, entityId) {
  if (entity == null) {
    return Promise.reject("entity cannot be null");
  }
  if (entityId == null) {
    return Promise.reject("entityId cannot be null");
  }
  const token = getAccessToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }

  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };

  const data = { entity, entityId };

  return POST("/api/subscription/v1/subscribe", data, headers);
}

export function unsubscribeEntity(entity, entityId) {
  if (entity == null) {
    return Promise.reject("entity cannot be null");
  }
  if (entityId == null) {
    return Promise.reject("entityId cannot be null");
  }
  const token = getAccessToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }

  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };

  const data = { entity, entityId };

  return POST("/api/subscription/v1/unsubscribe", data, headers);
}

export function getSubscribersList(entity) {
  if (entity == null) {
    return Promise.reject("entity cannot be null");
  }
  const token = getAccessToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }

  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };

  return GET(`/api/subscription/v1/check-subscription?entity=${entity}`, null, headers);
}

export function subscribeEntityList(entity) {
  if (entity == null) {
    return Promise.reject("entity cannot be null");
  }
  const token = getAccessToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }

  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };

  return POST("/api/subscription/v1/subscribe", entity , headers);
}

export function unsubscribeEntityList(entity) {
  if (entity == null) {
    return Promise.reject("entity cannot be null");
  }
  const token = getAccessToken();
  if (token == null) {
    return Promise.reject("Invalid access token");
  }

  const headers = {
    "Authorization": `Bearer ${token.accessToken}`
  };

  return POST("/api/subscription/v1/unsubscribe", entity , headers);
}