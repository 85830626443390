export const GOAL_LIST_REQUEST = "GOAL_LIST_REQUEST"; 
export const GOAL_LIST_SUCCESS = "GOAL_LIST_REQUEST_SUCCESS";

export const GOAL_CREATE_REQUEST = "GOAL_CREATE_REQUEST"; 
export const TARGET_CREATE_REQUEST = "TARGET_CREATE_REQUEST"; 
export const GOAL_DETAIL_REQUEST = "GOAL_DETAIL_REQUEST"; 
export const GOAL_ARCHIVE_REQUEST = "GOAL_ARCHIVE_REQUEST"; 
export const GOAL_DELETE_REQUEST = "GOAL_DELETE_REQUEST"; 
export const TARGET_DELETE_REQUEST = "TARGET_DELETE_REQUEST"; 
export const TARGET_EDIT_REQUEST = "TARGET_EDIT_REQUEST"; 
export const CLOSE_GOAL_REQUEST = "CLOSE_GOAL_REQUEST"; 

export const GOAL_UNARCHIVE_REQUEST = "GOAL_UNARCHIVE_REQUEST"; 
export const GOAL_EDIT_REQUEST = "GOAL_EDIT_REQUEST"; 

export const GOAL_CREATE_FORM_NAME = "createGoal"; 
export const GOAL_EDIT_FORM_NAME = "editGoal";

export const GOAL_AUTOCOMPLETE_REQUEST = "GOAL_AUTOCOMPLETE_REQUEST";
export const GOAL_AUTOCOMPLETE_SUCCESS = "GOAL_AUTOCOMPLETE_SUCCESS";
export const ADD_COLLECTION_REQUEST = "ADD_COLLECTION_REQUEST";

export const GOAL_SUBMIT_RULES = {
  name: {
    required: true,
  },
  startDate: {
    required : true,
  },
  endDate: {
    required : true,
  }
};

export const READ_GOAL = 267000;
export const CREATE_GOAL = 267001;
export const UPDATE_GOAL = 267002;
export const DELETE_GOAL = 267003;
export const ARCHIVE_GOAL = 267004;
export const UNARCHIVE_GOAL = 267005;
export const CLOSE_GOAL = 267011;

export const CREATE_TARGET = 268001;
export const UPDATE_TARGET = 268002;
export const DELETE_TARGET = 268003;

export const TARGET_PRIVILEGE_TYPE_PRIVATE = 1;
export const TARGET_PRIVILEGE_TYPE_PRIVATE_TITLE = "Private";
export const TARGET_PRIVILEGE_TYPE_PUBLIC = 2;
export const TARGET_PRIVILEGE_TYPE_PUBLIC_TITLE = "Public";

export const TARGET_PRIVILEGE_TYPE_DATA_SOURCE = [
  {
    id: TARGET_PRIVILEGE_TYPE_PRIVATE,
    name: TARGET_PRIVILEGE_TYPE_PRIVATE_TITLE,
  },
  {
    id: TARGET_PRIVILEGE_TYPE_PUBLIC,
    name: TARGET_PRIVILEGE_TYPE_PUBLIC_TITLE,
  }
];

export const GOAL_STATUS_ACTIVE = 1;
export const GOAL_STATUS_ACTIVE_TITLE = "Active";
export const GOAL_STATUS_CLOSE = 2;
export const GOAL_STATUS_CLOSE_TITLE = "Close";

export const GOAL_STATUS_DATA_SOURCE = [
  {
    id: GOAL_STATUS_ACTIVE,
    name: GOAL_STATUS_ACTIVE_TITLE,
  },
  {
    id: GOAL_STATUS_CLOSE,
    name: GOAL_STATUS_CLOSE_TITLE,
  }
];

export const GOAL_CLOSE_TYPE_SUCCESS = 1;
export const GOAL_CLOSE_TYPE_SUCCESS_TITLE = "Success";
export const GOAL_CLOSE_TYPE_FAIL = 2;
export const GOAL_CLOSE_TYPE_FAIL_TITLE = "Fail";

export const GOAL_CLOSE_TYPE_DATA_SOURCE = [
  {
    id: GOAL_CLOSE_TYPE_SUCCESS,
    name: GOAL_CLOSE_TYPE_SUCCESS_TITLE,
  },
  {
    id: GOAL_CLOSE_TYPE_FAIL,
    name: GOAL_CLOSE_TYPE_FAIL_TITLE,
  }
];

export const TARGET_DATA_SOURCE_SALE_INVOICE = 1;
export const TARGET_DATA_SOURCE_SALE_INVOICE_TITLE = "Sale Invoice";
export const TARGET_DATA_SOURCE_INVOICE_PAYMENT = 2;
export const TARGET_DATA_SOURCE_INVOICE_PAYMENT_TITLE = "Invoice Payment";

export const TARGET_DATA_SOURCE_TYPE_DATA_SOURCE = [
  {
    id: TARGET_DATA_SOURCE_SALE_INVOICE,
    name: TARGET_DATA_SOURCE_SALE_INVOICE_TITLE,
  },
  {
    id: TARGET_DATA_SOURCE_INVOICE_PAYMENT,
    name: TARGET_DATA_SOURCE_INVOICE_PAYMENT_TITLE,
  }
];

export const TARGET_TYPE_NUMBER = 1;
export const TARGET_TYPE_NUMBER_TITLE = "Number";
export const TARGET_TYPE_CURRENCY = 2;
export const TARGET_TYPE_CURRENCY_TITLE = "Currency";
export const TARGET_TYPE_DATA_SOURCE = 3;
export const TARGET_TYPE_DATA_SOURCE_TITLE = "Data Source";

export const TARGET_TYPE = [
  {
    id: TARGET_TYPE_NUMBER,
    name: TARGET_TYPE_NUMBER_TITLE,
  },
  {
    id: TARGET_TYPE_CURRENCY,
    name: TARGET_TYPE_CURRENCY_TITLE,
  },
  {
    id: TARGET_TYPE_DATA_SOURCE,
    name: TARGET_TYPE_DATA_SOURCE_TITLE,
  }
];

export const TARGET_SUBMIT_RULES = (values) => {
  const { type, privilegeType, departmentIds, userIds } = values;
  return {
    name: {
      required: true,
    },
    type: {
      required: true,
    },
    value: {
      required: true,
    },
    privilegeType: {
      required: true,
    },
    userIds: {
      required: privilegeType == TARGET_PRIVILEGE_TYPE_PRIVATE && (departmentIds == null || departmentIds && departmentIds.length == 0),
      type: "array",
    },
    departmentIds: {
      required: privilegeType == TARGET_PRIVILEGE_TYPE_PRIVATE && (userIds == null || userIds && userIds.length == 0),
      type: "array",
    },
    dataSourceType: {
      required: type == TARGET_TYPE_DATA_SOURCE,
    },
    dateRange: {
      required: type == TARGET_TYPE_DATA_SOURCE,
    },
  };
};
