import { schema } from "normalizr";
import { ItemSchema } from "@modules/inventory/item/item/schema";
import { PromotionArraySchema } from "@sale/sale-center/promotion/schema";
import { SaleCommissionSchema } from "@sale/setup/sale-commission/schema";

const SaleOrderItemSchema = new schema.Entity("saleOrderItems", {
  item: ItemSchema,
  promotions: PromotionArraySchema,
  saleCommission: SaleCommissionSchema
});

const SaleOrderItemArraySchema = new schema.Array(SaleOrderItemSchema);

export { SaleOrderItemSchema, SaleOrderItemArraySchema };