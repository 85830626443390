import { schema } from "normalizr";
import { UserAccountSchema } from "@modules/settings/user-management/user-account/schema";
import { EmployeeArraySchema } from "./index";

const EmployeePayrollIncentiveSchema = new schema.Entity("employeePayrollIncentives", {
  creator: UserAccountSchema,
  employees: EmployeeArraySchema
});
const EmployeePayrollIncentiveArraySchema = new schema.Array(EmployeePayrollIncentiveSchema);

export { EmployeePayrollIncentiveSchema, EmployeePayrollIncentiveArraySchema };