export const DEPARTMENT_LIST_REQUEST = "ITEM_DEPARTMENT_LIST_REQUEST"; 
export const DEPARTMENT_LIST_SUCCESS = "ITEM_DEPARTMENT_LIST_REQUEST_SUCCESS";
export const DEPARTMENT_CREATE_REQUEST = "ITEM_DEPARTMENT_CREATE_REQUEST"; 
export const DEPARTMENT_DETAIL_REQUEST = "ITEM_DEPARTMENT_DETAIL_REQUEST"; 
export const DEPARTMENT_ARCHIVE_REQUEST = "ITEM_DEPARTMENT_ARCHIVE_REQUEST"; 
export const DEPARTMENT_DELETE_REQUEST = "ITEM_DEPARTMENT_DELETE_REQUEST"; 
export const DEPARTMENT_UNARCHIVE_REQUEST = "ITEM_DEPARTMENT_UNARCHIVE_REQUEST"; 
export const DEPARTMENT_EDIT_REQUEST = "ITEM_DEPARTMENT_EDIT_REQUEST"; 
export const DEPARTMENT_CREATE_FORM_NAME = "createDepartment"; 
export const DEPARTMENT_EDIT_FORM_NAME = "editDepartment"; 
export const DEPARTMENT_AUTOCOMPLETE_REQUEST = "ITEM_DEPARTMENT_AUTOCOMPLETE_REQUEST";
export const DEPARTMENT_AUTOCOMPLETE_SUCCESS = "ITEM_DEPARTMENT_AUTOCOMPLETE_SUCCESS";
export const DEPARTMENT_SUBMIT_RULES = {
  name: {
    required: true
  },
  code: {
    required: true
  }
};
export const READ_DEPARTMENT = 223000;
export const CREATE_DEPARTMENT = 223001;
export const UPDATE_DEPARTMENT = 223002;
export const DELETE_DEPARTMENT = 223003;
export const ARCHIVE_DEPARTMENT = 223004;
export const UNARCHIVE_DEPARTMENT = 223005;
export const IMPORT_DEPARTMENT = 223008;