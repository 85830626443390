import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  DELETEWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/customer-commission/v1";

import { getDefaultCurrentDateRange } from "@modules/helpers";
import { GETWithToken } from "src/services/base-service";

export const getCustomerCommissionWithCurrentUser = (values) => {
  const url = `${API_URL}/me?includes[]=customer&includes[]=invoice`;
  values = getDefaultCurrentDateRange(values);
  return GETList(url, values);
};

export const getCustomerCommission = (values) => {
  const url = `${API_URL}/list?includes[]=customer&includes[]=invoice`;
  values = getDefaultCurrentDateRange(values);
  return GETList(url, values);
};

export function createCustomerCommission(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getTotalCustomerCommission(values) {
  return GETWithToken(`${API_URL}/summary`, values);
}

export function getCustomerCommissionAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getCustomerCommissionDetail(customerCommissionNo) {
  const url = `${API_URL}/no`;
  return GETDetail(url, customerCommissionNo);
}

export function getCustomerCommissionDetailWithCurrentUser(id) {
  const url = `${API_URL}/me`;
  return GETDetail(url, id);
}

export function editCustomerCommission(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveCustomerCommission(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveCustomerCommission(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function clearCustomerCommission(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/clear`;
  return PUTWithToken(url, values);
}

export function cancelCustomerCommission(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function getCustomerCommissionTotal(values) {
  const url = `${API_URL}/me/total`;
  return GETWithToken(url, values);
}