import _ from "lodash";

// Utils
import { notificationEntities } from "./notification";
import { notificationActionType } from "./notification-action-type";
import * as NotificationConstants from "@/constants/notification";

export function getNotificationMetaData(value) {
  let { entity, payload, entityId, type } = value;
  const attribute = notificationEntities[entity];
  let text = "N/A";
  let actions = [];
  let shouldRenderActionButton = false;
  if (NotificationConstants.NOTIFICATION_GENERAL_RANGE.indexOf(type) == -1) {
    const matchedType = attribute.message && attribute.message[type] || `Updated ${entity}`;
    if (matchedType) {
      const types = typeof matchedType;
      if (types === "object") {
        if (matchedType.render) {
          text = matchedType.render(value);
        }
      } else if (types === "string") {
        text = matchedType;
      }
      const action = matchedType.action;
      let shouldRender = false;
      if (action && action.shouldRender != null) {
        if (typeof action.shouldRender == "function") {
          shouldRender = action.shouldRender(value);
        }
      }
      if (shouldRender != null) {
        const actionItems = action && action.items;
        if (actionItems) {
          actions = actionItems;
        }
      }
      shouldRenderActionButton = shouldRender;
    }
  } else {
    // Build notification text for general actions
    let entityLabel = "";
    if (attribute && attribute.labelKey) {
      if (_.isArray(attribute.labelKey)) {
        const labels = [];
        attribute.labelKey.map(key => {
          labels.push(payload[key]);
        });
        entityLabel = `- ${labels.join(" ")}`;
      } else {
        if (payload && payload.attribute && payload.attribute.labelKey) {
          entityLabel = payload[attribute.labelKey];
        }
      }
    } else {
      entityLabel = "";
    }
    if (attribute && attribute.newEntityName) {
      entity = attribute.newEntityName;
    }
    text = `${notificationActionType(type)} ${entity} ${entityLabel}`;
  }
  let entityKey= entityId;
  if (attribute && attribute.entityKey) {
    entityKey = attribute && attribute.entityKey(value);
  }
  if (attribute && attribute.reloadWhenSuccess != null) {
    if (typeof attribute.reloadWhenSuccess == "function") {
      attribute.reloadWhenSuccess = attribute.reloadWhenSuccess(value);
    }
  }
  let url = `${attribute && attribute.path}/${entityKey}`;
  if (typeof (attribute && attribute.path) == "function") {
    url = attribute.path(entityKey, value);
  }
  return {
    ...attribute,
    text,
    url,
    actions,
    shouldRenderActionButton,
  };
}

export function activityType(value) { 
  let status = null; 
  switch(value) { 
  case 1: 
    status = "created"; 
    break; 
  case 2: 
    status = "updated"; 
    break; 
  case 3: 
    status = "archived"; 
    break; 
  case 4: 
    status = "deleted"; 
    break; 
  default: 
    status; 
  } 
  return status; 
}