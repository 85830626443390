import React, { Component } from "react";
import PropTypes from "prop-types";

// Component 
import AutoComplete from "../../select-autocomplete";

// Action 
import { getLeaveTypeAutoComplete } from "@modules/hr/leave/leave-type/actions";

// Constant 
import { LEAVE_TYPE_AUTOCOMPLETE_REQUEST } from "@modules/hr/leave/leave-type/constants";

class LeaveTypeAutoComplete extends Component {

  render() {
    const { 
      ids,
      includeDetail,
      includeLeavePolicy,
      ...props
    } = this.props; 
    return (
      <AutoComplete
        extra={{
          ids,
          includeDetail,
          includeLeavePolicy
        }}
        {...props}
      />
    );
  }
}

LeaveTypeAutoComplete.propTypes = {
  ids: PropTypes.array,
  includeDetail: PropTypes.bool,
  includeLeavePolicy: PropTypes.bool,
};

LeaveTypeAutoComplete.defaultProps = {
  label: "Leave Type",
  name: "leaveTypeId",
  required: true,
  action: getLeaveTypeAutoComplete,
  requestName: LEAVE_TYPE_AUTOCOMPLETE_REQUEST,
};

export default LeaveTypeAutoComplete;