export const INSTALLATION_TEMPLATE_LIST_REQUEST = "INSTALLATION_TEMPLATE_LIST_REQUEST"; 
export const INSTALLATION_TEMPLATE_LIST_SUCCESS = "INSTALLATION_TEMPLATE_LIST_REQUEST_SUCCESS";
export const INSTALLATION_TEMPLATE_CREATE_REQUEST = "INSTALLATION_TEMPLATE_CREATE_REQUEST"; 
export const INSTALLATION_TEMPLATE_DETAIL_REQUEST = "INSTALLATION_TEMPLATE_DETAIL_REQUEST"; 
export const INSTALLATION_TEMPLATE_ARCHIVE_REQUEST = "INSTALLATION_TEMPLATE_ARCHIVE_REQUEST"; 
export const INSTALLATION_TEMPLATE_DELETE_REQUEST = "INSTALLATION_TEMPLATE_DELETE_REQUEST"; 
export const INSTALLATION_TEMPLATE_UNARCHIVE_REQUEST = "INSTALLATION_TEMPLATE_UNARCHIVE_REQUEST"; 
export const INSTALLATION_TEMPLATE_EDIT_REQUEST = "INSTALLATION_TEMPLATE_EDIT_REQUEST"; 

export const INSTALLATION_TEMPLATE_CREATE_FORM_NAME = "createInstallationTemplate"; 
export const INSTALLATION_TEMPLATE_EDIT_FORM_NAME = "editInstallationTemplate"; 
export const INSTALLATION_TEMPLATE_FORM_NAME = "installationItemTemplate";

export const INSTALLATION_TEMPLATE_AUTOCOMPLETE_REQUEST = "INSTALLATION_TEMPLATE_AUTOCOMPLETE_REQUEST";
export const INSTALLATION_TEMPLATE_AUTOCOMPLETE_TREE_REQUEST = "INSTALLATION_TEMPLATE_AUTOCOMPLETE_TREE_REQUEST";
export const INSTALLATION_TEMPLATE_AUTOCOMPLETE_SUCCESS = "INSTALLATION_TEMPLATE_AUTOCOMPLETE_SUCCESS";
export const INSTALLATION_TEMPLATE_SUBMIT_RULES = { 
  name: {
    required: true,
  },
  installationIds: {
    required: true,
    type: "array"
  },
};
export const READ_INSTALLATION_TEMPLATE = 242000;
export const CREATE_INSTALLATION_TEMPLATE = 242001;
export const UPDATE_INSTALLATION_TEMPLATE = 242002;
export const DELETE_INSTALLATION_TEMPLATE = 242003;
export const ARCHIVE_INSTALLATION_TEMPLATE = 242004;
export const UNARCHIVE_INSTALLATION_TEMPLATE = 242005;