import React from "react";

// Libs
import { Badge } from "antd";

// Utils
import * as PayslipConstants from "../constants";

export const paySlipStatus = (id) => {
  let status = null;
  switch(id) {
  case PayslipConstants.PAYSLIP_STATUS_DRAFT:
    status = <Badge status="default" text={PayslipConstants.PAYSLIP_STATUS_DRAFT_TITLE} />;
    break;
  case PayslipConstants.PAYSLIP_STATUS_COMPUTED:
    status = <Badge status="processing" text={PayslipConstants.PAYSLIP_STATUS_COMPUTED_TITLE} />;
    break;
  case PayslipConstants.PAYSLIP_STATUS_CONFIRMED:
    status = <Badge status="success" text={PayslipConstants.PAYSLIP_STATUS_CONFIRMED_TITLE} />;
    break;
  case PayslipConstants.PAYSLIP_STATUS_CANCELLED:
    status = <Badge status="error" text={PayslipConstants.PAYSLIP_STATUS_CANCELLED_TITLE} />;
    break;
  case PayslipConstants.PAYSLIP_STATUS_COMPLETED:
    status = <Badge status="success" text={PayslipConstants.PAYSLIP_STATUS_COMPLETED_TITLE} />;
    break;
  default:
    status;
  }
  return status;
};

export const paySlipStatusTitle = (id) => {
  let status = null;
  switch(id) {
  case PayslipConstants.PAYSLIP_STATUS_DRAFT:
    status = PayslipConstants.PAYSLIP_STATUS_DRAFT_TITLE;
    break;
  case PayslipConstants.PAYSLIP_STATUS_COMPUTED:
    status = PayslipConstants.PAYSLIP_STATUS_COMPUTED_TITLE;
    break;
  case PayslipConstants.PAYSLIP_STATUS_CONFIRMED:
    status = PayslipConstants.PAYSLIP_STATUS_CONFIRMED_TITLE;
    break;
  case PayslipConstants.PAYSLIP_STATUS_CANCELLED:
    status = PayslipConstants.PAYSLIP_STATUS_CANCELLED_TITLE;
    break;
  case PayslipConstants.PAYSLIP_STATUS_COMPLETED:
    status = PayslipConstants.PAYSLIP_STATUS_COMPLETED_TITLE;
    break;
  default:
    status;
  }
  return status;
};