import { schema } from "normalizr";
import { EmployeeSchema } from "@modules/hr/employee-directory/employee/schema";
import { EmployeePayrollIncentiveArraySchema } from "@modules/hr/employee-directory/employee/schema/employee-payroll-incentive";
import { EarningTypeSchema, EarningTypeInputSchema } from "../../earning-type/schema";
import { DeductionTypeSchema } from "../../deduction-type/schema";

const PayslipComputationSchema = new schema.Entity("payslipComputations", {
  earningType: EarningTypeSchema,
  deductionType: DeductionTypeSchema,
});

const PayslipInputSchema = new schema.Entity("payslipInputs", {
  earningTypeInput: EarningTypeInputSchema
});
const PayslipInputArraySchema = new schema.Array(PayslipInputSchema);

const PayslipComputationArraySchema = new schema.Array(PayslipComputationSchema);

const PayslipVariableSchema = new schema.Entity("payslipVariables");
const PayslipVariableArraySchema = new schema.Array(PayslipVariableSchema);

const PayslipSchema = new schema.Entity("payslips", {
  employee: EmployeeSchema,
  computations: PayslipComputationArraySchema,
  inputs: PayslipInputArraySchema,
  employeePayrollIncentives: EmployeePayrollIncentiveArraySchema,
  variables: PayslipVariableArraySchema
});

const PayslipArraySchema = new schema.Array(PayslipSchema);

export { PayslipSchema, PayslipArraySchema, PayslipComputationSchema, PayslipInputArraySchema };