import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Webcam from "react-webcam";
import { Button, Modal, Result } from "antd";
import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";

// Util
import { getAccessToken } from "@/shared/utils";

// Component
import UploadImageBox from "./upload-image-box";

const renderUploadImageBox = ({
  webcamValue,
  input: { onChange, value },
  ...props
}) => {
  if (webcamValue) {
    onChange(webcamValue);
  }
  return (
    <UploadImageBox
      onUploaded={onChange}
      value={webcamValue || value}
      {...props} 
    />
  );
};

renderUploadImageBox.propTypes = {
  webcamValue: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  })
};

class ReduxUploadImageBox extends React.Component {

  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.state = {
      error: null,
      loading: false,
      visible: false,
      webcamValue: null,
    };
  }

  onVisible = () => {
    this.setState({
      visible: true,
      loading: true,
    }, () => {
      setTimeout(() => {
        this.setState({ 
          loading: false 
        });
      },
      3000);
    });
  }

  onCancel = () => {
    this.setState({
      visible: false,
    });
  }

  handleChange = (value, onChange) => {
    onChange(value || null);
    if (this.props.onChange) {
      this.props.onChange(value || null);
    }
  }

  handleOk = () => {
    const { path } = this.props;
    const imageSrc = this.webcamRef.current.getScreenshot();
    const timestamp = new Date().getUTCMilliseconds();
    const file = this.dataURLtoFile(imageSrc, `${timestamp}.png`);
    const formData = new FormData();
    formData.append("file", file);
    const token = getAccessToken();
    fetch(`/api/file/v1/upload?path=${path}`, {
      method: "POST",
      body: formData,
      headers: {Authorization: `Bearer ${token && token.accessToken}`}
    })
      .then(res => res.json())
      .then(images => {
        this.setState({ 
          webcamValue: images.url,
          visible: false,
        });
      });
  };

  dataURLtoFile = (dataUrl, filename) => {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
    while(n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  onLoadError = () => {
    this.setState({
      error: "Can not connect to camera or device not found",
    });
  }

  onUserMedia = () => {
    this.setState({
      error: null,
    });
  }

  renderFooter = () => {
    return (
      <Button
        ghost
        type="primary"
        icon={<CameraOutlined />}
        style={{padding: "0 5px"}}
        onClick={() => this.handleOk()}
      >
        Capture
      </Button>
    );
  } 

  render() {
    const {
      name,
      path,
      withWebcam,
      ...props
    } = this.props;
    const { visible, webcamValue, error, loading } = this.state;
    return (
      <Fragment>
        <Field
          name={name}
          component={renderUploadImageBox}
          handleChange={this.handleChange}
          path={path}
          webcamValue={webcamValue}
          {...props}
        />
        {withWebcam && (
          <Button 
            block
            className="my-2"  
            icon={<CameraOutlined />}
            onClick={this.onVisible}
          >
            Open Webcam
          </Button>
        )}
        <Modal
          title="Webcam"
          width="700px"
          visible={visible}
          onCancel={this.onCancel}
          destroyOnClose={true}
          footer={error == null ? this.renderFooter() : null}
        >
          {visible && (
            <Fragment>
              {loading ? (
                <LoadingOutlined 
                  style={{fontSize: "125px"}} 
                  className="d-flex align-items-center justify-content-center" 
                />
              ) : (
                <Fragment>
                  {error == null ? (
                    <Webcam 
                      audio={false}
                      mirrored={true}
                      ref={this.webcamRef}
                      onUserMedia={() => this.onUserMedia()}
                      onUserMediaError={() => this.onLoadError()}
                      screenshotFormat="image/png"
                    />
                  ) : (
                    <Result
                      status="warning"
                      title={error}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

ReduxUploadImageBox.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  onChange: PropTypes.func,
  withWebcam: PropTypes.bool
};

export default ReduxUploadImageBox;