export const PAYSLIP_BATCH_LIST_REQUEST = "PAYSLIP_BATCH_LIST_REQUEST"; 
export const PAYSLIP_BATCH_LIST_SUCCESS = "PAYSLIP_BATCH_LIST_REQUEST_SUCCESS";

export const PAYSLIP_BATCH_DETAIL_REQUEST = "PAYSLIP_BATCH_DETAIL_REQUEST";
export const PAYSLIP_LIST_FOR_BATCH = "PAYSLIP_LIST_FOR_BATCH";

export const PAYSLIP_BATCH_ARCHIVE_REQUEST = "PAYSLIP_BATCH_ARCHIVE_REQUEST";

export const PAYSLIP_BATCH_DELETE_REQUEST = "PAYSLIP_BATCH_DELETE_REQUEST";

export const PAYSLIP_BATCH_UNARCHIVE_REQUEST = "PAYSLIP_BATCH_UNARCHIVE_REQUEST";

export const PAYSLIP_BATCH_AUTOCOMPLETE_REQUEST = "PAYSLIP_BATCH_AUTOCOMPLETE_REQUEST";
export const PAYSLIP_BATCH_AUTOCOMPLETE_SUCCESS = "PAYSLIP_BATCH_AUTOCOMPLETE_SUCCESS";

export const PAYSLIP_BATCH_CONFIRM_REQUEST = "PAYSLIP_BATCH_CONFIRM_REQUEST";
export const PAYSLIP_BATCH_CONFIRM_SUCCESS = "PAYSLIP_BATCH_CONFIRM_SUCCESS";

export const PAYSLIP_BATCH_CANCEL_REQUEST = "PAYSLIP_BATCH_CANCEL_REQUEST";
export const PAYSLIP_BATCH_CANCEL_SUCCESS = "PAYSLIP_BATCH_CANCEL_SUCCESS";

export const PAYSLIP_BATCH_DRAFT_REQUEST = "PAYSLIP_BATCH_DRAFT_REQUEST";
export const PAYSLIP_BATCH_DRAFT_SUCCESS = "PAYSLIP_BATCH_DRAFT_SUCCESS";

export const PAYSLIP_BATCH_COMPUTATION_CREATE_REQUEST = "PAYSLIP_BATCH_COMPUTATION_CREATE_REQUEST";
export const PAYSLIP_BATCH_COMPUTATION_CREATE_SUCCESS = "PAYSLIP_BATCH_COMPUTATION_CREATE_SUCCESS";

export const PAYSLIP_BATCH_GENERATE_REQUEST = "PAYSLIP_BATCH_GENERATE_REQUEST";
export const PAYSLIP_BATCH_COMPLETE_REQUEST = "PAYSLIP_BATCH_COMPLETE_REQUEST";

export const PAYSLIP_BATCH_CREATE_REQUEST = "PAYSLIP_BATCH_CREATE_REQUEST";
export const PAYSLIP_BATCH_CREATE_SUCCESS = "PAYSLIP_BATCH_CREATE_SUCCESS";

export const PAYSLIP_BATCH_UPDATE_REQUEST = "PAYSLIP_BATCH_UPDATE_REQUEST";

export const PAYSLIP_BATCH_CREATE_FORM_NAME = "createPayslipBatch";
export const PAYSLIP_BATCH_EDIT_FORM_NAME = "editPayslipBatch";
export const PAYROLL_INCENTIVE_CREATE_FORM = "createPayrollIncentive";

export const READ_PAYSLIP_BATCH_CODE = 170000;
export const CREATE_PAYSLIP_BATCH_CODE = 170001;
export const UPDATE_PAYSLIP_BATCH_CODE = 170002;
export const DELETE_PAYSLIP_BATCH_CODE = 170003;
export const ARCHIVE_PAYSLIP_BATCH_CODE = 170004;
export const UNARCHIVE_PAYSLIP_BATCH_CODE = 170005;
export const BATCH_DELETE_PAYSLIP_BATCH_CODE = 170006;
export const PRINT_PAYSLIP_BATCH_CODE = 170007;
export const GENERATE_PAYSLIP_BATCH_CODE = 170011;
export const COMPUTE_PAYSLIP_BATCH_CODE = 170012;
export const CONFIRM_PAYSLIP_BATCH_CODE = 170013;
export const CANCEL_PAYSLIP_BATCH_CODE = 170014;
export const COMPLETE_PAYSLIP_BATCH_CODE = 170015;
export const DRAFT_PAYSLIP_BATCH_CODE = 170016;

export const PAYSLIP_BATCH_STATUS_DRAFT = 1;
export const PAYSLIP_BATCH_STATUS_DRAFT_TITLE = "Draft";
export const PAYSLIP_BATCH_STATUS_COMPUTED = 2;
export const PAYSLIP_BATCH_STATUS_COMPUTED_TITLE = "Computed";
export const PAYSLIP_BATCH_STATUS_CONFIRMED = 3;
export const PAYSLIP_BATCH_STATUS_CONFIRMED_TITLE = "Confirmed";
export const PAYSLIP_BATCH_STATUS_CANCELLED = 4;
export const PAYSLIP_BATCH_STATUS_CANCELLED_TITLE = "Cancelled";
export const PAYSLIP_BATCH_STATUS_COMPLETED = 5;
export const PAYSLIP_BATCH_STATUS_COMPLETED_TITLE = "Completed";

export const PAYSLIP_BATCH_STATUS_DATASOURCE = [
  {
    id: PAYSLIP_BATCH_STATUS_DRAFT,
    name: PAYSLIP_BATCH_STATUS_DRAFT_TITLE,
  },
  {
    id: PAYSLIP_BATCH_STATUS_COMPUTED,
    name: PAYSLIP_BATCH_STATUS_COMPUTED_TITLE,
  },
  {
    id: PAYSLIP_BATCH_STATUS_CONFIRMED,
    name: PAYSLIP_BATCH_STATUS_CONFIRMED_TITLE,
  },
  {
    id: PAYSLIP_BATCH_STATUS_CANCELLED,
    name: PAYSLIP_BATCH_STATUS_CANCELLED_TITLE,
  },
  {
    id: PAYSLIP_BATCH_STATUS_COMPLETED,
    name: PAYSLIP_BATCH_STATUS_COMPLETED_TITLE,
  },
];

export const PAYSLIP_BATCH_SUBMIT_RULES = {
  name: {
    required: true
  },
  month: {
    required: true
  },
  year: {
    required: true
  },
  employeeIds: {
    required: true,
    type: "array",
    message: "Please select at least 1 employee"
  },
};

export const PAYSLIP_SALE_INCENTIVE_FILTER_BY_SOLD_DATE = 1;
export const PAYSLIP_SALE_INCENTIVE_FILTER_BY_PAID_DATE = 2;
export const PAYSLIP_SALE_INCENTIVE_FILTER_BY_SOLD_DATE_TITLE = "Sold Date";
export const PAYSLIP_SALE_INCENTIVE_FILTER_BY_PAID_DATE_TITLE = "Paid Date";

export const PAYSLIP_SALE_INCENTIVE_FILTER_BY = [
  {
    id: PAYSLIP_SALE_INCENTIVE_FILTER_BY_SOLD_DATE,
    name: PAYSLIP_SALE_INCENTIVE_FILTER_BY_SOLD_DATE_TITLE,
  },
  {
    id: PAYSLIP_SALE_INCENTIVE_FILTER_BY_PAID_DATE,
    name: PAYSLIP_SALE_INCENTIVE_FILTER_BY_PAID_DATE_TITLE,
  },
];

export const PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_EARNING_TYPE = 1;
export const PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE = 2;
export const PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_EARNING_TYPE_TITLE = "Earning Type";
export const PAYSLIP_SALE_INCENTIVE_VALUE_SOURCE_SALE_INCENTIVE_TITLE = "Sale Incentive";