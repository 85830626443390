import { EMPLOYEE_LEAVE_BALANCE_HISTORY_SUCCESS } from "../constants";

const initialState = {
  list: []
};

export default function(state = initialState, action) {
  switch (action.type) {
  case EMPLOYEE_LEAVE_BALANCE_HISTORY_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  default:
    return state;
  }
}