export const READ_CASH_ADVANCED_SETTING_CODE = 235011;
export const UPDATE_CASH_ADVANCED_SETTING_CODE = 235012;
export const CASH_ADVANCED_SETTING_PATH = "/human-resource/cash-advance/cash-advanced-setting";

export const CASH_ADVANCED_SETTING_SUBMIT_RULES = {
  defaultInterestRate: {
    required: true,
  },
  defaultPaymentType: {
    required: true,
  },
  defaultCashAdvancedIncomeChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
  defaultCashAdvancedReceivableChartAccount: {
    type: "object",
    value: {
      required: true
    }
  },
};