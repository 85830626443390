import { normalize } from "normalizr";
import { mergeEntities, syncEntities, mergeEntity } from "@/actions/entity";
import { EmployeeSchema, EmployeeArraySchema } from "../schema";
import { EmployeePayrollSchema } from "../schema/employee-payroll";
import { 
  EmployeeSaleCommissionSchema, 
  EmployeeSaleCommissionArraySchema, 
} from "../schema/employee-sale-commission";
import { appraisalAnniversarySchema } from "@/modules/hr/appraisal/anniversary/schema";
import { EmployeePayrollIncentiveArraySchema } from "../schema/employee-payroll-incentive";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createArchiveAction,
  createDeleteAction,
  createUnarchiveAction,
  createNewEntityAction,
  createEntityActionAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as Constants from "../constants";
import { setPagination } from "@/actions/pagination";
import { EmployeeContactSchema } from "../schema/employee-contact";
import { EducationSchema, EducationArraySchema } from "../schema/employee-education";
import { LanguageArraySchema, LanguageSchema } from "../schema/employee-language";
import { workExperienceArraySchema, workExperienceSchema } from "../schema/employee-work-experience";
import { EmployeeJobHistoryArraySchema } from "../schema/employee-job-history";
import { EmployeeLeaveSettingSchema } from "../schema/employee-leave-setting";
import { EmployeeLeaveBalanceArraySchema } from "../schema/employee-leave-balance";
import { PayslipComputationSchema } from "@modules/hr/payroll/payslip/schema";

const path = "/human-resource/employee-directory/employee";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getEmployee,
    schema: EmployeeArraySchema,
    requestName: Constants.EMPLOYEE_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getEmployeeAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getEmployeeAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const createEmployee = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createEmployee,
    requestName: Constants.EMPLOYEE_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getEmployeeDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getEmployeeDetail,
    requestName: Constants.EMPLOYEE_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: EmployeeSchema,
  };
  return createDetailAction(options, id);
};

export const editEmployee = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editEmployee,
    requestName: Constants.EMPLOYEE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "employees",
    schema: EmployeeSchema,
    successMessage: "Employee is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveEmployee = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveEmployee,
    requestName: Constants.EMPLOYEE_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Employee is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteEmployee = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteEmployee,
    requestName: Constants.EMPLOYEE_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Employee is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveEmployee = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveEmployee,
    requestName: Constants.EMPLOYEE_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Employee is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const terminateEmployee = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.terminateEmployee,
    requestName: Constants.EMPLOYEE_TERMINATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Employee is terminated successfully!",
  };
  return createArchiveAction(options, id);
};

export const activeEmployee = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.activeEmployee,
    requestName: Constants.EMPLOYEE_ACTIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Employee is activated successfully!",
  };
  return createArchiveAction(options, id);
};

export const promoteEmployee = (values) => (dispatch, getState) => {
  const { appraisalAnniversaryId } = values;
  const options = {
    serviceMethod: service.promoteEmployee,
    requestName: Constants.EMPLOYEE_PROMOTE_REQUEST,
    dispatch,
    getState,
    schema: appraisalAnniversaryId ? appraisalAnniversarySchema : null,
    successMessage: "Employee is evaluated successfully"
  };
  return createEntityActionAction(options, values);
};

export const getEmployeeJobHistory = (id) => (dispatch, getState) => {

  const successAction = (payload) => {
    return {
      type: Constants.EMPLOYEE_JOB_HISTORY_SUCCESS,
      id,
      payload,
    };
  };

  const onSuccess = (json) => {
    const { data, ...pagination } = json;
    dispatch(setPagination(pagination, `${Constants.EMPLOYEE_JOB_HISTORY_REQUEST}_${id}`));
    return data; 
  };

  const options = {
    serviceMethod: service.getEmployeeJobHistory,
    requestName: `${Constants.EMPLOYEE_JOB_HISTORY_REQUEST}_${id}`,
    dispatch,
    getState,
    schema: EmployeeJobHistoryArraySchema,
    successAction,
    onSuccess
  };
  return createDetailAction(options, id);
};

export const getEducation = (id) => (dispatch, getState) => {

  const getEducationSuccess = (educations, employeeId) => {
    return {
      type: Constants.EDUCATION_SUCCESS,
      educations: educations,
      employeeId
    };
  };

  const options = {
    serviceMethod: service.getEducation,
    schema: EducationArraySchema,
    requestName: Constants.EDUCATION_REQUEST,
    dispatch,
    getState,
    successAction: getEducationSuccess
  };
  return createListAction(options, id);
};

export const createEducation = (values) => (dispatch, getState) => {

  const createEducationSuccess = (id, employeeId) => {
    return {
      type: Constants.EDUCATION_ADD_NEW,
      id,
      employeeId
    };
  };

  const normalizeAction = (response, values) => {
    const normalized = normalize(response, EducationSchema);
    dispatch(mergeEntities(normalized.entities));
    const { employeeId } = values;
    dispatch(createEducationSuccess(normalized.result, employeeId));
  };

  values.shouldContinueCreate = true;

  const options = {
    serviceMethod: service.createEducation,
    requestName: Constants.CREATE_EDUCATION_REQUEST,
    dispatch,
    getState,
    entityName: "Education",
    formName: Constants.EDUCATION_FORM_NAME,
    successAction: normalizeAction
  };
  return createNewEntityAction(options, values);
};

export const editEducation = (values) => (dispatch, getState) => {

  const options = {
    serviceMethod: service.editEducation,
    requestName: Constants.EDIT_EDUCATION_REQUEST,
    dispatch,
    getState,
    entityName: "educations",
    schema: EducationSchema,
    successMessage: "Education is updated successfully!",
  };
  return createEditAction(options, values);
};

export const deleteEducation = (values) => (dispatch, getState) => {

  const { id, employeeId } = values;
  const deleteEducationSuccess = () => {
    return {
      type: Constants.EDUCATION_DELETE,
      id,
      employeeId
    };
  };

  const options = {
    dispatch,
    getState,
    serviceMethod: service.deleteEducation,
    successAction: deleteEducationSuccess,
    requestName: Constants.DELETE_EDUCATION_REQUEST,
    successMessage: "Education is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const getEmployeeContact = (id) => (dispatch, getState) => {

  const getEmployeeContactSuccess = (employeeContact, employeeId) => {
    return {
      type: Constants.EMPLOYEE_CONTACT_SUCCESS,
      employeeContact,
      employeeId
    };
  };

  const options = {
    dispatch,
    getState,
    schema: EmployeeContactSchema,
    successAction: getEmployeeContactSuccess,
    serviceMethod: service.getEmployeeContact,
    requestName: Constants.EMPLOYEE_CONTACT_REQUEST,
  };
  return createDetailAction(options, id);
};

export const updateEmployeeConatct = (values) => (dispatch, getState) => {

  const successAction = (json) => {
    const state = getState();
    const employeeContact = state.entities?.employeeContacts?.[values.employeeId];
    employeeContact.employeeContact = json;
    dispatch(mergeEntity("loans", values.employeeId, employeeContact));
  };

  const options = {
    serviceMethod: service.updateEmployeeConatct,
    // schema: EmployeeContactSchema,
    requestName: Constants.EDIT_EMPLOYEE_CONTACT_REQUEST,
    dispatch,
    getState,
    successAction,
    successMessage: "Employee contact is updated successfully!",
  };
  return createEntityActionAction(options, values);
};

export const getWorkExperience = (id) => (dispatch, getState) => {

  const getWorkExperienceSuccess = (workExperience, employeeId) => {
    return {
      type: Constants.WORK_EXPERIENCE_SUCCESS,
      workExperience: workExperience,
      employeeId
    };
  };

  const options = {
    serviceMethod: service.getWorkExperience,
    schema: workExperienceArraySchema,
    requestName: Constants.WORK_EXPERIENCE_REQUEST,
    dispatch,
    getState,
    successAction: getWorkExperienceSuccess
  };
  return createListAction(options, id);
};

export const createWorkExperience = (values) => (dispatch, getState) => {

  const createWorkExperienceSuccess = (id, employeeId) => {
    return {
      type: Constants.WORK_EXPERIENCE_ADD_NEW,
      id,
      employeeId
    };
  };

  const normalizeAction = (response, values) => {
    const normalized = normalize(response, workExperienceSchema);
    dispatch(mergeEntities(normalized.entities));
    const { employeeId } = values;
    dispatch(createWorkExperienceSuccess(normalized.result, employeeId));
  };

  values.shouldContinueCreate = true;

  const options = {
    serviceMethod: service.createWorkExperience,
    requestName: Constants.CREATE_WORK_EXPERIENCE_REQUEST,
    dispatch,
    getState,
    entityName: "Experience",
    successAction: normalizeAction
  };
  return createNewEntityAction(options, values);
};

export const editWorkExperience = (values) => (dispatch, getState) => {

  const options = {
    serviceMethod: service.editWorkExperience,
    requestName: Constants.EDIT_WORK_EXPERIENCE_REQUEST,
    dispatch,
    getState,
    entityName: "workExperiences",
    schema: workExperienceSchema,
    successMessage: "Experience is updated successfully!",
  };
  return createEditAction(options, values);
};

export const deleteWorkExperience = (values) => (dispatch, getState) => {
  
  const { id, employeeId } = values;
  const deleteWorkExperienceSuccess = () => {
    return {
      type: Constants.WORK_EXPERIENCE_DELETE,
      id,
      employeeId
    };
  };

  const options = {
    serviceMethod: service.deleteWorkExperience,
    requestName: Constants.DELETE_WORK_EXPERIENCE_REQUEST,
    dispatch,
    getState,
    successMessage: "Experience is deleted successfully!",
    successAction: deleteWorkExperienceSuccess
  };
  return createDeleteAction(options, id);
};

export const getLanguage = (id) => (dispatch, getState) => {

  const getLanguageSuccess = (languages, employeeId) => {
    return {
      type: Constants.LANGUAGE_SUCCESS,
      languages: languages,
      employeeId
    };
  };

  const options = {
    serviceMethod: service.getLanguage,
    schema: LanguageArraySchema,
    requestName: Constants.LANGUAGE_REQUEST,
    dispatch,
    getState,
    successAction: getLanguageSuccess
  };
  return createListAction(options, id);
};

export const createLanguage = (values) => (dispatch, getState) => {

  const createLanguageSuccess = (id, employeeId) => {
    return {
      type: Constants.LANGUAGE_ADD_NEW,
      id,
      employeeId
    };
  };

  const normalizeAction = (response, values) => {
    const normalized = normalize(response, LanguageSchema);
    dispatch(mergeEntities(normalized.entities));
    const { employeeId } = values;
    dispatch(createLanguageSuccess(normalized.result, employeeId));
  };

  values.shouldContinueCreate = true;

  const options = {
    dispatch,
    getState,
    entityName: "Language",
    formName: "addEmployeeLanguageForm",
    serviceMethod: service.createLanguage,
    requestName: Constants.CREATE_LANGUAGE_REQUEST,
    successAction: normalizeAction
  };
  return createNewEntityAction(options, values);
};

export const editLanguage = (values) => (dispatch, getState) => {

  const options = {
    dispatch,
    getState,
    schema: LanguageSchema,
    entityName: "languages",
    serviceMethod: service.editLanguage,
    requestName: Constants.EDIT_LANGUAGE_REQUEST,
    successMessage: "Language is updated successfully!",
  };
  return createEditAction(options, values);
};

export const deleteLanguage = (values) => (dispatch, getState) => {
  
  const { id, employeeId } = values;
  const deleteLanguageSuccess = () => {
    return {
      type: Constants.LANGUAGE_DELETE,
      id,
      employeeId
    };
  };

  const options = {
    dispatch,
    getState,
    serviceMethod: service.deleteLanguage,
    requestName: Constants.DELETE_LANGUAGE_REQUEST,
    successMessage: "Education is deleted successfully!",
    successAction: deleteLanguageSuccess
  };
  return createDeleteAction(options, id);
};

// Employee Payroll
export const getEmployeePayroll = (id) => (dispatch, getState) => {

  const getEmployeePayrollSuccess = (payload) => {
    return {
      type: Constants.EMPLOYEE_PAYROLL_SUCCESS,
      payload,
      id
    };
  };

  const jsonSuccess = (response) => {
    if (response.id == null) {
      response.id = -1;
    } else {
      // response.id = response.employee.id;
    }
    return response;
  };

  const options = {
    dispatch,
    getState,
    onSuccess: jsonSuccess,
    schema: EmployeePayrollSchema,
    successAction: getEmployeePayrollSuccess,
    serviceMethod: service.getEmployeePayroll,
    requestName: Constants.EMPLOYEE_PAYROLL_REQUEST,
  };
  return createDetailAction(options, id);
};

export const createEmployeePayroll = (values) => (dispatch, getState) => {

  const normalizeAction = (response) => {
    const normalized = normalize(response, EmployeePayrollSchema);
    dispatch(mergeEntities(normalized.entities));
  };

  const redirectUrl = (json) => {
    return `${path}/${json.employee.id}/payroll`;
  };

  const options = {
    serviceMethod: service.createEmployeePayroll,
    requestName: Constants.EMPLOYEE_PAYROLL_CREATE_REQUEST,
    dispatch,
    getState,
    entityName: "Employee payroll",
    redirectUrl: redirectUrl,
    successAction: normalizeAction
  };
  return createNewEntityAction(options, values);
};

export const editEmployeePayroll = (values) => (dispatch, getState) => {
  
  const redirectUrl = (json) => {
    return `${path}/${json.employee.id}/payroll`;
  };

  const options = {
    serviceMethod: service.editEmployeePayroll,
    requestName: Constants.EMPLOYEE_PAYROLL_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: redirectUrl,
    entityName: "employeePayrolls",
    schema: EmployeePayrollSchema,
    syncArray: true,
    syncArrayEntityName: "employeePayrollIncentives",
    successMessage: "Employee payroll is updated successfully!",
  };
  return createEditAction(options, values);
};

export const getEmploymentCardAndSignature = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getEmploymentCardAndSignature,
    schema: EmployeeSchema,
    requestName: Constants.EMPLOYMENT_CARD_AND_SIGNATURE_REQUEST,
    dispatch,
    getState,
  };
  return createDetailAction(options, id);
};

export const editEmploymentCardAndSignature = (values) => (dispatch, getState) => {

  const redirectUrl = (json) => {
    return `${path}/${json.id}/employment-card-and-signature`;
  };

  const options = {
    serviceMethod: service.editEmploymentCardAndSignature,
    requestName: Constants.EMPLOYMENT_CARD_AND_SIGNATURE_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl,
    entityName: "employees",
    schema: EmployeeSchema,
    successMessage: "Employment card and signature is updated successfully!",
  };
  return createEditAction(options, values);
};

export const getEmployeeLeaveSetting = (id) => (dispatch, getState) => {

  const getEmployeeLeaveSettingSuccess = (payload, employeeId) => {
    return {
      type: Constants.EMPLOYEE_LEAVE_SETTING_SUCCESS,
      payload,
      employeeId
    };
  };

  const onSuccess = (response) => {
    if (response.id == null) {
      response.id = -1;
    } else {
      response.id = response.employee.id;
    }
    return response;
  };

  const options = {
    serviceMethod: service.getEmployeeLeaveSetting,
    schema: EmployeeLeaveSettingSchema,
    requestName: Constants.EMPLOYEE_LEAVE_SETTING_REQUEST,
    dispatch,
    getState,
    successAction: getEmployeeLeaveSettingSuccess,
    onSuccess,
  };
  return createDetailAction(options, id);
};

export const getLeaveBalanceHistory = (values) => (dispatch, getState) => {

  const options = {
    serviceMethod: service.getLeaveBalanceHistory,
    schema: EmployeeLeaveBalanceArraySchema,
    requestName: Constants.EMPLOYEE_LEAVE_BALANCE_HISTORY_REQUEST,
    dispatch,
    getState,
  };
  return createListAction(options, values);
};

export const editEmployeeLeaveSetting = (values) => (dispatch, getState) => {

  const normalizeAction = (response) => {
    const normalized = normalize(response, EmployeeLeaveSettingSchema);
    dispatch(mergeEntities(normalized.entities));
    dispatch(syncEntities(normalized.entities.employeeLeaveSettings[normalized.result], "employeeLeaveSettings"));
  };

  const redirectUrl = (json) => {
    return `${path}/${json.employee.id}/leave-setting`;
  };

  const options = {
    serviceMethod: service.editEmployeeLeaveSetting,
    requestName: Constants.EMPLOYEE_LEAVE_SETTING_EDIT_REQUEST,
    dispatch,
    getState,
    entityName: "Leave setting",
    redirectUrl: redirectUrl,
    successAction: normalizeAction
  };
  return createNewEntityAction(options, values);
};

export const getEmployeeSaleCommission = (values) => (dispatch, getState) => {
  
  const options = {
    serviceMethod: service.getEmployeeSaleCommission,
    schema: EmployeeSaleCommissionArraySchema,
    requestName: Constants.EMPLOYEE_SALE_COMMISSION_REQUEST,
    dispatch,
    getState,
  };

  return createListAction(options, values);
};

export const updateEmployeeSaleCommission = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.updateEmployeeSaleCommission,
    schema: EmployeeSaleCommissionSchema,
    requestName: Constants.UPDATE_EMPLOYEE_SALE_COMMISSION_REQUEST,
    dispatch,
    getState,
    successMessage: "Sale commission is updated successfully!",
  };
  return createEntityActionAction(options, values);
};

export const getEmployeePayrollIncentive = (employeeId, payslipId) => (dispatch, getState) => {

  const onSuccess = (employeePayrollIncentives) => {
    const newEmployeePayrollIncentives = [];
    employeePayrollIncentives.map(values => {
      if (values.payslipSaleIncentives && values.payslipSaleIncentives.length > 0) {
        values.payslipSaleIncentives.map(incentive => {
          const newIncentive = { ...incentive };
          const employee = values.employees.find(employee => newIncentive.employeeId == employee.id);
          if (employee != null) {
            newIncentive.employeePayrollIncentiveId = values.id;
            newIncentive.id = `${values.id}-${employee.id}`;
            newIncentive.name = values.name;
            newIncentive.notation = values.notation;
            newIncentive.creator = values.creator;
            newIncentive.employee = employee;
            newEmployeePayrollIncentives.push(newIncentive);
          }
        });
      } else {
        if (values.employees == null || values.employees.length == 0) {
          const newValues = { ...values };
          delete newValues.payslipSaleIncentives;
          delete newValues.employees;
          newValues.employeePayrollIncentiveId = values.id;
          newValues.id = `${values.id}`;
          newValues.value = 0;
          newEmployeePayrollIncentives.push(newValues);
        } else {
          values.employees.map(employee => {
            const newValues = { ...values };
            delete newValues.payslipSaleIncentives;
            delete newValues.employees;
            newValues.employeePayrollIncentiveId = values.id;
            newValues.id = `${values.id}-${employee.id}`;
            newValues.employee = employee;
            newValues.value = 0;
            newEmployeePayrollIncentives.push(newValues);
          });
        }
      }
    });
    return newEmployeePayrollIncentives; 
  };

  const afterMerge = (state, normalized) => {
    const payslips = state.entities.payslips;
    const payslip = payslips[payslipId];
    payslip.employeePayrollIncentives = normalized.result;
    dispatch(mergeEntity("payslips", payslipId, payslip));
  };

  const options = {
    serviceMethod: service.getEmployeePayrollIncentive,
    schema: EmployeePayrollIncentiveArraySchema,
    requestName: Constants.EMPLOYEE_PAYROLL_INCENTIVES_REQUEST,
    onSuccess,
    afterMerge,
    dispatch,
    getState,
  };

  return createDetailAction(options, { employeeId, payslipId });
};

export const createEmployeePayrollIncentive = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createEmployeePayrollIncentive,
    requestName: Constants.PAYROLL_INCENTIVE_CREATE_REQUEST,
    dispatch,
    getState,
    schema: PayslipComputationSchema,
    entityName: "payslipComputations",
    successMessage: "Sale commission is saved successfully",
  };
  return createEditAction(options, values);
};