export const ITEM_MOVEMENT_LOG_LIST_REQUEST = "ITEM_MOVEMENT_LOG_LIST_REQUEST"; 
export const ITEM_MOVEMENT_LOG_LIST_SUCCESS = "ITEM_MOVEMENT_LOG_LIST_REQUEST_SUCCESS";

export const ITEM_MOVEMENT_LOG_TYPE_PURCHASE_ORDER = 1;
export const ITEM_MOVEMENT_LOG_TYPE_PURCHASE_ORDER_TITLE = "Purchase Order";
export const ITEM_MOVEMENT_LOG_TYPE_SALE = 2;
export const ITEM_MOVEMENT_LOG_TYPE_SALE_TITLE = "Sale";
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_IN = 3;
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_IN_TITLE = "Stock In";
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_OUT = 4;
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_OUT_TITLE = "Stock Out";
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_TRANSFER = 5;
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_TRANSFER_TITLE = "Stock Transfer";
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_ADJUSTMENT = 6;
export const ITEM_MOVEMENT_LOG_TYPE_STOCK_ADJUSTMENT_TITLE = "Stock Adjustment";
export const ITEM_MOVEMENT_LOG_TYPE_JUNK_OUT = 7;
export const ITEM_MOVEMENT_LOG_TYPE_JUNK_OUT_TITLE = "Junk Out";
export const ITEM_MOVEMENT_LOG_TYPE_ASSET_REQUEST = 8;
export const ITEM_MOVEMENT_LOG_TYPE_ASSET_REQUEST_TITLE = "Asset Request";
export const ITEM_MOVEMENT_LOG_TYPE_CUSTOMIZE_ITEM = 9;
export const ITEM_MOVEMENT_LOG_TYPE_CUSTOMIZE_ITEM_TITLE = "Customize Item";
export const ITEM_MOVEMENT_LOG_TYPE_ASSET_RETURN = 10;
export const ITEM_MOVEMENT_LOG_TYPE_ASSET_RETURN_TITLE = "Asset Return";
export const ITEM_MOVEMENT_LOG_TYPE_SALE_EXCHANGE = 11;
export const ITEM_MOVEMENT_LOG_TYPE_SALE_EXCHANGE_TITLE = "Sale Exchange";
export const ITEM_MOVEMENT_LOG_TYPE_SALE_RETURN= 12;
export const ITEM_MOVEMENT_LOG_TYPE_SALE_RETURN_TITLE = "Sale Return";

export const READ_ITEM_MOVEMENT_LOG = 218000;

export const ITEM_MOVEMENT_LOG_TYPE_DATA_SOURCE = [
  {
    id: ITEM_MOVEMENT_LOG_TYPE_PURCHASE_ORDER,
    name: ITEM_MOVEMENT_LOG_TYPE_PURCHASE_ORDER_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_SALE,
    name: ITEM_MOVEMENT_LOG_TYPE_SALE_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_STOCK_IN,
    name: ITEM_MOVEMENT_LOG_TYPE_STOCK_IN_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_STOCK_OUT,
    name: ITEM_MOVEMENT_LOG_TYPE_STOCK_OUT_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_STOCK_TRANSFER,
    name: ITEM_MOVEMENT_LOG_TYPE_STOCK_TRANSFER_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_STOCK_ADJUSTMENT,
    name: ITEM_MOVEMENT_LOG_TYPE_STOCK_ADJUSTMENT_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_JUNK_OUT,
    name: ITEM_MOVEMENT_LOG_TYPE_JUNK_OUT_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_ASSET_REQUEST,
    name: ITEM_MOVEMENT_LOG_TYPE_ASSET_REQUEST_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_CUSTOMIZE_ITEM,
    name: ITEM_MOVEMENT_LOG_TYPE_CUSTOMIZE_ITEM_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_ASSET_RETURN,
    name: ITEM_MOVEMENT_LOG_TYPE_ASSET_RETURN_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_SALE_EXCHANGE,
    name: ITEM_MOVEMENT_LOG_TYPE_SALE_EXCHANGE_TITLE,
  },
  {
    id: ITEM_MOVEMENT_LOG_TYPE_SALE_RETURN,
    name: ITEM_MOVEMENT_LOG_TYPE_SALE_RETURN_TITLE,
  },
  
];