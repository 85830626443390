import { EMPLOYEE_LEAVE_SETTING_SUCCESS } from "../constants";

export default function(state = {}, action) {
  switch (action.type) {
  case EMPLOYEE_LEAVE_SETTING_SUCCESS:
    return {
      ...state,
      [action.employeeId]: action.payload
    };
  default:
    return state;
  }
}