import { 
  JOB_APPLICANT_LIST_SUCCESS,
  JOB_APPLICANT_KANBAN_SUCCESS,
  JOB_APPLICANT_REORDER_RECRUITMENT_STAGE_SUCCESS,
  JOB_APPLICANT_REORDER_SUCCESS,
} from "../constants";

const initialState = {
  list: [],
  kanban: {
    recruitmentStages: [],
    items: []
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
  case JOB_APPLICANT_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  case JOB_APPLICANT_KANBAN_SUCCESS: 
    return {
      ...state,
      kanban: {
        recruitmentStages: action.recruitmentStages,
        items: action.jobApplicants
      }
    };
  case JOB_APPLICANT_REORDER_RECRUITMENT_STAGE_SUCCESS:
    return {
      ...state,
      kanban: {
        ...state.kanban,
        recruitmentStages: action.recruitmentStages,
      }
    };
  case JOB_APPLICANT_REORDER_SUCCESS: 
    return {
      ...state,
      kanban: {
        recruitmentStages: action.recruitmentStages,
        items: action.jobApplicants
      }
    };
  default:
    return state;
  }
}