import {
  GETList, 
  GETDetail,
  POSTWithToken,
  DELETEWithToken,
  PUTWithToken,
  GETAutoComplete,
} from "@/services/base-service";
import { getDefaultCurrentDateRange } from "@modules/helpers";

const API_URL = "/api/junk-out/v1";

export const getJunkOut = (values) => {
  const url = `${API_URL}/list`;
  values = getDefaultCurrentDateRange(values);
  return GETList(url, values);
};

export function createJunkOut(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getJunkOutAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getJunkOutDetail(id) {
  const url = `${API_URL}/no`;
  return GETDetail(url, id);
}

export function editJunkOut(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}

export function archiveJunkOut(id) {
  const url = `${API_URL}/${id}/soft`;
  return DELETEWithToken(url);
}

export function unarchiveJunkOut(id) {
  const url = `${API_URL}/${id}/unarchive`;
  return PUTWithToken(url);
}

export function deleteJunkOut(id) {
  const url = `${API_URL}/${id}`;
  return DELETEWithToken(url);
}

export function submitJunkOut(id) {
  const url = `${API_URL}/${id}/submit`;
  return PUTWithToken(url);
}

export function approveJunkOut(id) {
  const url = `${API_URL}/${id}/approve`;
  return PUTWithToken(url);
}

export function rejectJunkOut(id) {
  const url = `${API_URL}/${id}/reject`;
  return PUTWithToken(url);
}

export function cancelJunkOut(id) {
  const url = `${API_URL}/${id}/cancel`;
  return PUTWithToken(url);
}

export function processJunkOut(values) {
  const { id } = values;
  const url = `${API_URL}/${id}/post`;
  return PUTWithToken(url, values);
}