import React from "react";
import { QUOTATION_STOCK_STATUS_OUT_OF_STOCK } from "../../sale-order/constants";

export const QUOTATION_LIST_REQUEST = "QUOTATION_LIST_REQUEST";
export const QUOTATION_AUTO_COMPLETE_REQUEST = "QUOTATION_AUTO_COMPLETE_REQUEST";
export const QUOTATION_LIST_SUCCESS = "QUOTATION_LIST_REQUEST_SUCCESS";
export const QUOTATION_CREATE_REQUEST = "QUOTATION_CREATE_REQUEST";
export const QUOTATION_DETAIL_REQUEST = "QUOTATION_DETAIL_REQUEST";
export const QUOTATION_ARCHIVE_REQUEST = "QUOTATION_ARCHIVE_REQUEST";
export const QUOTATION_DELETE_REQUEST = "QUOTATION_DELETE_REQUEST";
export const QUOTATION_UNARCHIVE_REQUEST = "QUOTATION_UNARCHIVE_REQUEST";
export const QUOTATION_EDIT_REQUEST = "QUOTATION_EDIT_REQUEST";
export const QUOTATION_SUBMIT_REQUEST = "QUOTATION_SUBMIT_REQUEST";
export const QUOTATION_PROCESS_REQUEST = "QUOTATION_PROCESS_REQUEST";
export const QUOTATION_CANCEL_REQUEST = "QUOTATION_CANCEL_REQUEST";
export const QUOTATION_VOID_REQUEST = "QUOTATION_VOID_REQUEST";
export const QUOTATION_ITEM_REQUEST_APPROVAL_REQUEST = "QUOTATION_ITEM_REQUEST_APPROVAL_REQUEST";
export const QUOTATION_APPROVE_REQUEST = "QUOTATION_APPROVE_REQUEST";
export const QUOTATION_REJECT_REQUEST = "QUOTATION_REJECT_REQUEST";
export const QUOTATION_CLONE_REQUEST = "QUOTATION_CLONE_REQUEST";
export const QUOTATION_DRAFT_REQUEST = "QUOTATION_DRAFT_REQUEST";
export const QUOTATION_ITEM_APPROVE_REQUEST = "QUOTATION_ITEM_APPROVE_REQUEST";
export const QUOTATION_ITEM_REJECT_REQUEST = "QUOTATION_ITEM_REJECT_REQUEST";
export const QUOTATION_ITEM_REQUEST_APPROVAL = "QUOTATION_ITEM_REQUEST_APPROVAL";
export const QUOTATION_VERSION_REQUEST = "QUOTATION_VERSION_REQUEST";
export const QUOTATION_VERSION_SUCCESS = "QUOTATION_VERSION_SUCCESS";

export const QUOTATION_CREATE_FORM_NAME = "createQuotation";
export const QUOTATION_EDIT_FORM_NAME = "editQuotation";
export const QUOTATION_PROCESS_ITEM_FORM_NAME = "processItemForm";
export const QUOTATION_ITEM_APPROVAL_FORM_NAME = "approveQuotationItem";
export const QUOTATION_ITEM_ARRAY_FIELD_NAME = "quotationItems";

export const QUOTATION_TYPE_APPROVAL = 1;
export const QUOTATION_TYPE_VERSION_LIST = 2;
export const QUOTATION_TYPE_CLONE = 3;
export const QUOTATION_VERSION_FORM_NAME = "versionQuotation";
export const QUOTATION_VERSION_SUBMIT_RULES = {
  id: {
    required: true
  }
};

export const QUOTATION_SUBMIT_RULES = (values) => {
  const { stockStatus } = values;
  return {
    employeeId: {
      required: true,
    },
    customerId: {
      required: true,
    },
    paymentTermId: {
      required: true,
    },
    quotationDate: {
      required: true,
    },
    expireDate: {
      required: true,
    },
    preferredPaymentMethodId: {
      required: true,
    },
    estimateDuration: {
      required: stockStatus == QUOTATION_STOCK_STATUS_OUT_OF_STOCK
    },
    quotationItems: {
      required: true,
      type: "array",
      message: "Please select at least 1 item",
      validation: (values) => {
        let error = false;
        let parentName = [];
        values.map((value) => {
          const { hasChild, children, name } = value;
          if (hasChild == true && children.length == 0) {
            error = true;
            parentName = parentName.concat(name);
          }
        });
        if (error) {
          const message = parentName.map((val, index) => {
            return (
              <div key={index}>
                <b>{val}</b> must be have at least 1 sub item
              </div>
            );
          });
          return {
            message
          };
        }
      }
    }
  };
};

export const READ_QUOTATION = 195000;
export const CREATE_QUOTATION = 195001;
export const UPDATE_QUOTATION = 195002;
export const DELETE_QUOTATION = 195003;
export const ARCHIVE_QUOTATION = 195004;
export const UNARCHIVE_QUOTATION = 195005;
export const PRINT_QUOTATION = 195007;
export const UPDATE_QUOTATION_VERSION = 195011;
export const CLONE_QUOTATION = 195012;
export const REQUEST_APPROVAL_QUOTATION = 195013;
export const APPROVE_QUOTATION = 195014;
export const REJECT_QUOTATION = 195015;
export const ACCEPT_QUOTATION = 195016;
export const MARK_AS_REJECT_QUOTATION = 195017;
export const APPROVE_QUOTATION_ITEM = 195018;
export const REJECT_QUOTATION_ITEM = 195019;
export const VIEW_ALL_USER_QUOTATION = 195021;
export const VIEW_OTHER_USER_QUOTATION = 195022;
export const VIEW_SALE_TEAM_QUOTATION = 195023;
export const QUOTATION_ITEM_APPROVAL = 195024;
export const SUBMIT_QUOTATION = 195025;
export const DRAFT_QUOTATION = 195026;
export const VIEW_CUSTOMER_COMMISSION_QUOTATION = 195027;
export const CUSTOMER_COMMISSION_QUOTATION = 195028;
export const CANCEL_QUOTATION = 195030;
export const VIEW_OWN_SALE_TEAM_QUOTATION  = 195031;

export const QUOTATION_STATUS_DRAFT = 1;
export const QUOTATION_STATUS_DRAFT_TITLE = "Draft";
export const QUOTATION_STATUS_REJECTED = 2;
export const QUOTATION_STATUS_REJECTED_TITLE = "Rejected";
export const QUOTATION_STATUS_ACCEPTED = 3;
export const QUOTATION_STATUS_ACCEPTED_TITLE = "Accepted";
export const QUOTATION_STATUS_SALE_ORDER = 4;
export const QUOTATION_STATUS_SALE_ORDER_TITLE = "Sale Order";
export const QUOTATION_STATUS_WAITING_APPROVAL = 5;
export const QUOTATION_STATUS_WAITING_APPROVAL_TITLE = "Waiting Approval";
export const QUOTATION_STATUS_APPROVED = 6;
export const QUOTATION_STATUS_APPROVED_TITLE = "Approved";
export const QUOTATION_STATUS_SUBMITTED = 7;
export const QUOTATION_STATUS_SUBMITTED_TITLE = "Submitted";
export const QUOTATION_STATUS_CANCELLED = 8;
export const QUOTATION_STATUS_CANCELLED_TITLE = "Cancelled";
export const QUOTATION_STATUS_COMPLETED = 9;
export const QUOTATION_STATUS_COMPLETED_TITLE = "Completed";
export const QUOTATION_STATUS_DATA_SOURCE = [
  {
    id: QUOTATION_STATUS_DRAFT,
    name: QUOTATION_STATUS_DRAFT_TITLE,
  },
  {
    id: QUOTATION_STATUS_REJECTED,
    name: QUOTATION_STATUS_REJECTED_TITLE,
  },
  {
    id: QUOTATION_STATUS_ACCEPTED,
    name: QUOTATION_STATUS_ACCEPTED_TITLE,
  },
  {
    id: QUOTATION_STATUS_SALE_ORDER,
    name: QUOTATION_STATUS_SALE_ORDER_TITLE,
  },
  {
    id: QUOTATION_STATUS_WAITING_APPROVAL,
    name: QUOTATION_STATUS_WAITING_APPROVAL_TITLE,
  },
  {
    id: QUOTATION_STATUS_APPROVED,
    name: QUOTATION_STATUS_APPROVED_TITLE,
  },
  {
    id: QUOTATION_STATUS_SUBMITTED,
    name: QUOTATION_STATUS_SUBMITTED_TITLE,
  },
  {
    id: QUOTATION_STATUS_CANCELLED,
    name: QUOTATION_STATUS_CANCELLED_TITLE,
  },
  {
    id: QUOTATION_STATUS_COMPLETED,
    name: QUOTATION_STATUS_COMPLETED_TITLE,
  },
];

export const QUOTATION_ITEM_STATUS_DRAFT = 1;
export const QUOTATION_ITEM_STATUS_DRAFT_TITLE = "Draft";
export const QUOTATION_ITEM_STATUS_REJECTED = 2;
export const QUOTATION_ITEM_STATUS_REJECTED_TITLE = "Rejected";
export const QUOTATION_ITEM_STATUS_APPROVED = 3;
export const QUOTATION_ITEM_STATUS_APPROVED_TITLE = "Approved"; 

export const INSTALLATION_SUBMIT_RULES = {
  installationId: {
    required: true,
    type: "array",
    message: "Please select at least 1",
  },
};

export const QUOTATION_APPROVE_SUBMIT_RULES = {
  quotationItems: {
    required: true,
    type: "arrayInline",
    subFields: [
      {
        name: "price",
        type: "number",
        min: 0,
      },
    ]
  },
};