// Lib
import React from "react";

// Components
import Form from "./form";
import Otp from "../otp";
import ResetPassword from "../reset-password/form";
import AuthenticationLayout from "@/components/layout/authentication-layout";

function ForgotPassword() {

  const [email, setEmail] = React.useState(null);
  const [otp, setOtp] = React.useState(null);
  const [verified, setVerified] = React.useState(null);
  return (
    <>
      {verified ? (
        <AuthenticationLayout>
          <ResetPassword 
            code={otp}
            form="resetPassword" 
            setVerified={setVerified}
          />
        </AuthenticationLayout>
      ) : (
        <>
          {email ? (
            <Otp 
              otp={otp}
              email={email} 
              onSetOtp={setOtp} 
              setVerified={setVerified}
            />
          ) : (
            <AuthenticationLayout>
              <Form 
                onSetOtp={setOtp}
                onSetEmail={setEmail}
                form="forgotPassword" 
              />
            </AuthenticationLayout>
          )}
        </>
      )}
    </>
  );
}

export default ForgotPassword;