import * as NotificationConstants from "@/constants/notification";

const initialState = {
  list: [],
  listAll: [],
  count: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
  case NotificationConstants.NOTIFICATION_LIST_SUCCESS: 
    return {
      ...state,
      list: action.payload
    };
  case NotificationConstants.NOTIFICATION_LIST_NEXT_PAGE: 
    return {
      ...state,
      list: [
        ...state.list,
        ...action.payload
      ]
    };
  case NotificationConstants.NOTIFICATION_UNREAD_COUNT_SUCCESS: 
    return {
      ...state,
      count: action.count
    };
  case NotificationConstants.NOTIFICATION_DID_RECEIVE:
    return {
      ...state,
      list: [
        action.notification,
        ...state.list,
      ],
      count: state.count + 1
    };
  case NotificationConstants.NOTIFICATION_READ_SUCCESS:
    return {
      ...state,
      count: state.count > 0 ? (state.count - 1) : 0
    };
  case NotificationConstants.NOTIFICATION_MARK_ALL_READ_SUCCESS:
    return {
      ...state,
      count: 0
    };
  case NotificationConstants.NOTIFICATION_ALL_LIST_SUCCESS: 
    return {
      ...state,
      listAll: action.notifications
    };
  default:
    return state;
  }
}