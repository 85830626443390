export const PROMOTION_LIST_REQUEST = "PROMOTION_LIST_REQUEST";
export const PROMOTION_LIST_SUCCESS = "PROMOTION_LIST_REQUEST_SUCCESS";
export const PROMOTION_CREATE_REQUEST = "PROMOTION_CREATE_REQUEST";
export const PROMOTION_DETAIL_REQUEST = "PROMOTION_DETAIL_REQUEST";
export const PROMOTION_ARCHIVE_REQUEST = "PROMOTION_ARCHIVE_REQUEST";
export const PROMOTION_DELETE_REQUEST = "PROMOTION_DELETE_REQUEST";
export const PROMOTION_UNARCHIVE_REQUEST = "PROMOTION_UNARCHIVE_REQUEST";
export const PROMOTION_EDIT_REQUEST = "PROMOTION_EDIT_REQUEST";

export const PROMOTION_CREATE_FORM_NAME = "createPromotion";
export const PROMOTION_EDIT_FORM_NAME = "editPromotion";

export const PROMOTION_AUTOCOMPLETE_REQUEST = "PROMOTION_AUTOCOMPLETE_REQUEST";
export const PROMOTION_AUTOCOMPLETE_SUCCESS = "PROMOTION_AUTOCOMPLETE_SUCCESS";
export const PROMOTION_EFFECTIVE_ON_AUTOCOMPLETE_REQUEST =
  "PROMOTION_EFFECTIVE_ON_AUTOCOMPLETE_REQUEST";

export const READ_PROMOTION = 228000;
export const CREATE_PROMOTION = 228001;
export const UPDATE_PROMOTION = 228002;
export const ARCHIVE_PROMOTION = 228003;
export const UNARCHIVE_PROMOTION = 228004;
export const DELETE_PROMOTION = 228005;

export const PROMOTION_TYPE_PERCENTAGE = 1;
export const PROMOTION_TYPE_FIXED = 2;
export const PROMOTION_TYPE_BUY_X_GET_Y = 3;

export const PROMOTION_TYPE_PERCENTAGE_TITLE = "Percentage";
export const PROMOTION_TYPE_FIXED_TITLE = "Amount";
export const PROMOTION_TYPE_BUY_X_GET_Y_TITLE = "Buy X Get Y";

export const AVAILABLE_FOR_ALL = 1;
export const AVAILABLE_FOR_SPECIFIC = 2;
export const AVAILABLE_FOR_CATEGORY = 3;

export const AVAILABLE_FOR_ALL_TITLE = "All";
export const AVAILABLE_FOR_SPECIFIC_TITLE = "Specific";
export const AVAILABLE_FOR_CATEGORY_TITLE = "Category";

export const PROMOTION_VAT_TYPE_ON_GROSS = 1;
export const PROMOTION_VAT_TYPE_ON_GROSS_TITLE = "VAT On Gross";
export const PROMOTION_VAT_TYPE_ON_NET = 2;
export const PROMOTION_VAT_TYPE_ON_NET_TITLE = "VAT On Net";

export const PROMOTION_VAT_TYPE_DATA_SOURCE = [
  {
    id: PROMOTION_VAT_TYPE_ON_GROSS,
    name: PROMOTION_VAT_TYPE_ON_GROSS_TITLE,
  },
  {
    id: PROMOTION_VAT_TYPE_ON_NET,
    name: PROMOTION_VAT_TYPE_ON_NET_TITLE,
  },
];

export const PROMOTION_TYPE_DATA_SOURCE = [
  {
    id: PROMOTION_TYPE_PERCENTAGE,
    name: PROMOTION_TYPE_PERCENTAGE_TITLE,
  },
  {
    id: PROMOTION_TYPE_FIXED,
    name: PROMOTION_TYPE_FIXED_TITLE,
  },
  {
    id: PROMOTION_TYPE_BUY_X_GET_Y,
    name: PROMOTION_TYPE_BUY_X_GET_Y_TITLE,
  },
];

export const AVAILABLE_FOR_DATA_SOURCE = [
  {
    id: AVAILABLE_FOR_ALL,
    name: AVAILABLE_FOR_ALL_TITLE,
  },
  {
    id: AVAILABLE_FOR_SPECIFIC,
    name: AVAILABLE_FOR_SPECIFIC_TITLE,
  },
  {
    id: AVAILABLE_FOR_CATEGORY,
    name: AVAILABLE_FOR_CATEGORY_TITLE,
  },
];
