import { 
  AttendanceArraySchema, 
  AttendanceSchema,
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createNewEntityAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import { normalize } from "normalizr";
import * as AttendanceConstant from "../constants";
import { mergeEntities } from "@/actions/entity";

export const getAll = (values) => (dispatch, getState) => {
  values.state = getState();
  const options = {
    serviceMethod: service.getAttendance,
    schema: AttendanceArraySchema,
    requestName: AttendanceConstant.ATTENDANCE_REQUEST,
    dispatch,
    getState,
  };
  return createListAction(options, values);
};

export const getInvalidAttendance = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getInvalidAttendance,
    schema: AttendanceArraySchema,
    requestName: AttendanceConstant.ATTENDANCE_INVALID_REQUEST,
    dispatch,
    getState,
  };
  return createListAction(options, values);
};

export const attendanceCheckIn = (values) => (dispatch, getState) => {

  const successAction = (result) => {
    return {
      type: AttendanceConstant.ATTENDANCE_CHECK_IN_SUCCESS,
      attendances: result,
    };
  };

  const options = {
    schema: AttendanceSchema,
    dispatch,
    getState,
    requestName: AttendanceConstant.ATTENDANCE_CHECK_IN_REQUEST,
    serviceMethod: service.attendanceCheckIn,
    successMessage: "Check in successfully",
    formName: AttendanceConstant.ATTENDANCE_CHECK_IN_FORM,
    successAction,
  };

  return createDetailAction(options, values);
};

export const attendanceCheckOut = (values) => (dispatch, getState) => {

  const options = {
    schema: AttendanceSchema,
    dispatch,
    getState,
    requestName: AttendanceConstant.ATTENDANCE_CHECK_OUT_REQUEST,
    serviceMethod: service.attendanceCheckOut,
    successMessage: "Check out successfully",
    redirect: false,
    formName: AttendanceConstant.ATTENDANCE_CHECK_OUT_FORM,
  };
  return createDetailAction(options, values);

};

export const deleteAttendance = (id) => (dispatch, getState) => 
{
  const onSuccess = () => {
    return {
      type: AttendanceConstant.ATTENDANCE_DELETE_SUCCESS,
      payload: id,
    };
  };

  const options = {
    dispatch,
    getState,
    requestName: AttendanceConstant.ATTENDANCE_DELETE_REQUEST,
    serviceMethod: service.deleteAttendance,
    successMessage: "Attendance record is deleted successfully",
    successAction: onSuccess
  };

  return createDeleteAction(options, id);
};

export const editAttendance = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editAttendance,
    requestName: AttendanceConstant.ATTENDANCE_EDIT_REQUEST,
    dispatch,
    getState,
    entityName: "attendances",
    schema: AttendanceSchema,
    successMessage: "Attendance record is updated successfully!",
  };
  return createEditAction(options, values);
};

export const createAttendance = (values) => (dispatch, getState) => {

  const onSuccess = (json) => {
    const normalized = normalize(json, AttendanceSchema);
    dispatch(mergeEntities(normalized.entities));
    return {
      type: AttendanceConstant.ATTENDANCE_CHECK_IN_SUCCESS,
      attendances: normalized.result,
    };
  };

  const options = {
    serviceMethod: service.createAttendance,
    requestName: AttendanceConstant.ATTENDANCE_CREATE_REQUEST,
    dispatch,
    getState,
    schema: AttendanceSchema,
    redirect: false,
    onSuccess,
    successMessage: "Attendance record is created successfully!",
  };
  return createNewEntityAction(options, values);
};

export const approveAttendance = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveAttendance,
    requestName: AttendanceConstant.ATTENDANCE_APPROVE_REQUEST,
    dispatch,
    getState,
    entityName: "attendances",
    schema: AttendanceSchema,
    successMessage: "Attendance record is approved successfully!",
  };
  return createEditAction(options, id);
};

export const rejectAttendance = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.rejectAttendance,
    requestName: AttendanceConstant.ATTENDANCE_REJECT_REQUEST,
    dispatch,
    getState,
    entityName: "attendances",
    schema: AttendanceSchema,
    successMessage: "Attendance record is rejected successfully!",
  };
  return createEditAction(options, id);
};

export const mergeAttendance = (values) => (dispatch, getState) => {

  const onSuccess = (json) => {
    const { deletedId } = json;
    return {
      type: AttendanceConstant.ATTENDANCE_DELETE_SUCCESS,
      payload: deletedId,
    };
  };

  const options = {
    serviceMethod: service.mergeAttendance,
    requestName: AttendanceConstant.ATTENDANCE_MERGE_REQUEST,
    dispatch,
    getState,
    entityName: "attendances",
    schema: AttendanceSchema,
    successMessage: "Attendance record is merged successfully!",
    successAction: onSuccess
  };

  return createEditAction(options, values);
};

export const getAttendanceSummary = (values) => (dispatch, getState) => {

  const onNewNormalize = (payload) => {
    const onSuccess = {
      type: AttendanceConstant.ATTENDANCE_SUMMARY_SUCCESS,
      payload,
    };
    return dispatch(onSuccess);
  };

  const options = {
    serviceMethod: service.getAttendanceSummary,
    schema: AttendanceSchema,
    requestName: AttendanceConstant.ATTENDANCE_SUMMARY_REQUEST,
    dispatch,
    getState,
    onNewNormalize
  };
  return createDetailAction(options, values);
};

export const approveAllAttendance = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.approveAllAttendance,
    requestName: AttendanceConstant.ATTENDANCE_APPROVE_ALL_REQUEST,
    dispatch,
    getState,
    redirectUrl: () => "/human-resource/attendance/view",
    successMessage: "Attendance record is approved successfully!",
  };
  return createEditAction(options, values);
};