export const NO_SERIES_LIST_LIST_REQUEST = "NO_SERIES_LIST_LIST_REQUEST"; 
export const NO_SERIES_LIST_LIST_SUCCESS = "NO_SERIES_LIST_LIST_REQUEST_SUCCESS";
export const NO_SERIES_LIST_CREATE_REQUEST = "NO_SERIES_LIST_CREATE_REQUEST"; 
export const NO_SERIES_LIST_DETAIL_REQUEST = "NO_SERIES_LIST_DETAIL_REQUEST"; 
export const NO_SERIES_LIST_EDIT_REQUEST = "NO_SERIES_LIST_EDIT_REQUEST"; 
export const NO_SERIES_LIST_CREATE_FORM_NAME = "createNoSeriesList"; 
export const NO_SERIES_LIST_EDIT_FORM_NAME = "editNoSeriesList"; 
export const NO_SERIES_LIST_AUTOCOMPLETE_REQUEST = "NO_SERIES_LIST_AUTOCOMPLETE_REQUEST";
export const NO_SERIES_LIST_AUTOCOMPLETE_SUCCESS = "NO_SERIES_LIST_AUTOCOMPLETE_SUCCESS";
export const NO_SERIES_LIST_SUBMIT_RULES = {
  code: {
    required: true,
  },
  startAt: {
    required: true,
  },
  endAt: {
    required: true,
  },
  entity: {
    required: true,
  },
  incrementBy: {
    required: true,
  }
};
export const READ_NO_SERIES_LIST = 113000;
export const CREATE_NO_SERIES_LIST = 113001;
export const UPDATE_NO_SERIES_LIST = 113002;