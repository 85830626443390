import React from "react";
import * as Constants from "../constants";
import { Badge } from "antd";

export const renderUserAccountStatus = (id) => {
  let status = null;
  switch(id) {
  case Constants.USER_ACCOUNT_STATUS_ACTIVE:
    status = <Badge status="success" text={Constants.USER_ACCOUNT_STATUS_ACTIVE_TITLE} />;
    break;
  case Constants.USER_ACCOUNT_STATUS_DEACTIVE:
    status = <Badge status="error" text={Constants.USER_ACCOUNT_STATUS_DEACTIVE_TITLE} />;
    break;
  case Constants.USER_ACCOUNT_STATUS_SUSPEND:
    status = <Badge status="error" text={Constants.USER_ACCOUNT_STATUS_SUSPEND_TITLE} />;
    break;
  default:
    status;
  }
  return status;
};

export const renderUserAccountType = (value) => {
  let type = null;
  switch(value) {
  case Constants.UER_TYPE_PASSWORD_ID:
    type = Constants.UER_TYPE_PASSWORD_NAME;
    break;
  case Constants.UER_TYPE_CREDENTAIL_ID:
    type = Constants.UER_TYPE_CREDENTAIL_NAME;
    break;
  case Constants.UER_TYPE_APP_ID:
    type = Constants.UER_TYPE_APP_NAME;
    break;
  case Constants.UER_TYPE_CUSTOMER_ID:
    type = Constants.UER_TYPE_CUSTOMER_NAME;
    break;
  default:
    type;
  }
  return type;
};

export const userSubmitRules = (values) => {

  const {
    password,
    isGeneratePassword = false,
  } = values;

  const rules = {
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    clientId: {
      required: true,
    },
    password: {
      required: !isGeneratePassword,
      type: "password"
    },
    confirmPassword: {
      required: !isGeneratePassword,
    },
  };

  if (!isGeneratePassword) {
    rules.confirmPassword["equalsTo"] = {
      name: "password",
      value: password,
      message: "Both passwords do not match"
    };
  }
  return rules;
};

export const userEditSubmitRules = () => {
  const rules = {
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    clientId: {
      required: true,
    },
    status: {
      required: true,
    },
  };
  return rules;
};

export const changeUserPasswordSubmitRules = (values) => {
  const { newPassword } = values;
  const rules = {
    oldPassword: {
      required: true,
    },
    newPassword: {
      required: true,
      type: "password"
    },
    confirmPassword: {
      required: true,
    },
  };
  rules.confirmPassword["equalsTo"] = {
    name: "password",
    value: newPassword,
    message: "Both passwords do not match"
  };
  return rules;
};

export const resetUserPasswordSubmitRules = (values) => {
  const { newPassword } = values;
  const rules = {
    newPassword: {
      required: true,
      type: "password"
    },
    confirmPassword: {
      required: true,
    },
  };
  rules.confirmPassword["equalsTo"] = {
    name: "password",
    value: newPassword,
    message: "Both passwords do not match"
  };
  return rules;
};