export const ACTIVITY_LOG_REQUEST = "ACTIVITY_LOG_REQUEST";
export const ACTIVITY_LOG_SUCCESS = "ACTIVITY_LOG_SUCCESS";
export const ACTIVITY_LOG_SUCCESS_NEXT = "ACTIVITY_LOG_SUCCESS_NEXT";

export const ACTIVITY_LOG_DETAIL_REQUEST = "ACTIVITY_LOG_DETAIL_REQUEST";
export const ACTIVITY_LOG_DETAIL_SUCCESS = "ACTIVITY_LOG_DETAIL_SUCCESS";

export const USER_BLOCK = {
  firstName: "Anonymous",
  lastName: "(Deleted User)"
};

export const ignoreKeys = [
  "id",
  "state",
  "creator",
  "version",
  "updatedAt",
  "createdAt",
  "createdBy",
  "updatedBy",
];

export const ACTIVITY_TYPE_CREATED = 1;
export const ACTIVITY_TYPE_UPDATED = 2;
export const ACTIVITY_TYPE_ARCHIVED = 3;
export const ACTIVITY_TYPE_DELETED = 4;

export const READ_ACTIVITY_LOG_CODE = 100000;
export const CREATE_ACTIVITY_LOG_CODE = 100001;
export const UPDATE_ACTIVITY_LOG_CODE = 100002;
export const DELETE_ACTIVITY_LOG_CODE = 100003;