import { 
  DepartmentSchema,
  DepartmentArraySchema, 
} from "../schema";
import {
  createEditAction,
  createListAction,
  createDetailAction,
  createDeleteAction,
  createArchiveAction,
  createUnarchiveAction,
  createNewEntityAction,
  createAutoCompleteAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as DepartmentConstants from "../constants";

const path = "/human-resource/employee-directory/department";

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getDepartment,
    schema: DepartmentArraySchema,
    requestName: DepartmentConstants.DEPARTMENT_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const createDepartment = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.createDepartment,
    requestName: DepartmentConstants.DEPARTMENT_CREATE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
  };
  return createNewEntityAction(options, values);
};

export const getDepartmentAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getDepartmentAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getDepartmentDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getDepartmentDetail,
    requestName: DepartmentConstants.DEPARTMENT_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: DepartmentSchema,
  };
  return createDetailAction(options, id);
};

export const editDepartment = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.editDepartment,
    requestName: DepartmentConstants.DEPARTMENT_EDIT_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    entityName: "departments",
    schema: DepartmentSchema,
    successMessage: "Department is updated successfully",
  };
  return createEditAction(options, values);
};

export const archiveDepartment = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveDepartment,
    requestName: DepartmentConstants.DEPARTMENT_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Department is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const deleteDepartment = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.deleteDepartment,
    requestName: DepartmentConstants.DEPARTMENT_DELETE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Department is deleted successfully!",
  };
  return createDeleteAction(options, id);
};

export const unarchiveDepartment = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveDepartment,
    requestName: DepartmentConstants.DEPARTMENT_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Department is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};