import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import * as moment from "moment";
import classnames from "classnames";
import { FormText } from "reactstrap";
import { Form, TimePicker as AntTimePicker } from "antd";
import { DEFAULT_DISPLAY_TIME_FORMAT } from "@/constants/default-constants";

const FormItem = Form.Item;

class TimePickerField extends Component {

  onChange = (value, rawValue) => {
    const { input: { onChange }, handleChange } = this.props;
    onChange(rawValue);
    if (handleChange) {
      handleChange(rawValue, value);
    }
  }

  onBlur = () => {
    const { input: { onChange, value, onBlur } } = this.props;
    onChange(value);
    onBlur();
  }

  render() {
    const { 
      tip,
      label, 
      allowEmpty,
      placeholder,
      required,
      meta: { touched, error },
      input: { value, onBlur, onFocus },
      ...props
    } = this.props;
    const formattedValue = moment(value, DEFAULT_DISPLAY_TIME_FORMAT);
    const validateStatus = classnames(touched && error ? "error" : "success");
    return (
      <Fragment>
        <FormItem 
          label={label} 
          validateStatus={validateStatus}
          help={error}
          required={required}
        >
          <AntTimePicker
            use12Hours
            className="w-100"
            allowEmpty={allowEmpty}
            format={DEFAULT_DISPLAY_TIME_FORMAT}
            onChange={this.onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value && formattedValue}
            placeholder={placeholder}
            {...props}
          />
        </FormItem>
        {tip && (
          <FormText
            color="muted"
            className="mt-0 mb-2"
          >
            {tip}
          </FormText>
        )}
      </Fragment>
    );
  }
}

TimePickerField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  tip: PropTypes.string,
};

TimePickerField.defaultProps = {
  required: false,
  allowEmpty: true,
};

class TimePicker extends Component {

  render() {
    const { ...props } = this.props;
    return (
      <Field 
        component={TimePickerField}
        {...props} 
      />
    );
  }
}

export default TimePicker;