const DELIVERY_PATH = "/delivery";
const DELIVERY_MANAGEMENT_PATH = "delivery-management";


export const delivery = [
  {
    name: "Delivery Order List",
    type: "list",
    slug: `${DELIVERY_PATH}/${DELIVERY_MANAGEMENT_PATH}/delivery-order`,
  },
  {
    name: "Delivery Order Detail",
    type: "detail",
    slug: `${DELIVERY_PATH}/${DELIVERY_MANAGEMENT_PATH}/delivery-order`,
  },
];