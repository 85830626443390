import { 
  GETWithToken, 
  PUTWithToken, 
  POSTWithToken,
  DELETEWithToken,
} from "./base-service";

const WIDGET_URL = "/api/widget/v1";
const DASHBOARD_URL = "/api/dashboard/v1/me";
const DASHBOARD_WIDGET_URL = "/api/dashboard-widget/v1";

export function getDashboardList() {
  return GETWithToken(DASHBOARD_URL);
}

export function getDashBoardWidget(query, endPoint) {
  return GETWithToken(endPoint, query);
}

export function getAllModules() {
  const url = `${WIDGET_URL}/module/list`;
  return GETWithToken(url);
}

export function filterWidget(values) {
  const url = `${WIDGET_URL}/filter`;
  const query = {};
  if (values.slug != "all") {
    query.moduleSlug = values.slug;
  } else {
    delete query.moduleSlug;
  }
  if (values.query != null) {
    query.query = values.query;
    delete query.moduleSlug;
  }
  return GETWithToken(url, query);
}

export function updateDashboardOptions(values) {
  const { dashboardId, widgetId } = values;
  const url = `${DASHBOARD_WIDGET_URL}/${dashboardId}/${widgetId}`;
  return PUTWithToken(url, values);
}

export const addDashboardDashboard = (values) => {
  const url = `${DASHBOARD_WIDGET_URL}/create`;
  return POSTWithToken(url, values);
}; 

export const deleteDashBoardWidget = (id) => {
  const url = `${DASHBOARD_WIDGET_URL}/${id}`;
  return DELETEWithToken(url);
};