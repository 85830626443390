//action type 
export const CONTRACT_LIST_REQUEST = "CONTRACT_LIST_REQUEST";
export const CONTRACT_LIST_REQUEST_SUCCESS = "CONTRACT_LIST_REQUEST_SUCCESS";
export const CONTRACT_CREATE_REQUEST = "CONTRACT_CREATE_REQUEST";
export const CONSTRACT_DETAIL_REQUEST = "CONSTRACT_DETAIL_REQUEST";
export const CONTRACT_EDIT_REQUEST = "CONTRACT_EDIT_REQUEST";

export const CONTRACT_FORM_NAME ="contract";


export const CONTRACT_TYPE_CUSTOMER = 1;
export const CONTRACT_TYPE_CUSTOMER_TITLE = "Customer";
export const CONTRACT_TYPE_EMPLOYEE = 2;
export const CONTRACT_TYPE_EMPLOYEE_TITLE = "Employee";
export const CONTRACT_TYPE_SUPPLER = 3;
export const CONTRACT_TYPE_SUPPLER_TITLE = "Suppler";
//type
export const CONTRACT_TYPE = [
  {
    id: CONTRACT_TYPE_CUSTOMER,
    name: CONTRACT_TYPE_CUSTOMER_TITLE,
  },
  {
    id: CONTRACT_TYPE_EMPLOYEE,
    name: CONTRACT_TYPE_EMPLOYEE_TITLE,
  },
  {
    id: CONTRACT_TYPE_SUPPLER,
    name: CONTRACT_TYPE_SUPPLER_TITLE,
  }
];


export const CONTRACT_SUBMIT_RULES = (values) => { 
  const { type } = values;
  return {
    name: {
      required: true,
    },
    type:{
      required: true,
    },
    startDate:{
      required: true,
    },
    endDate:{
      required: true,
    },
    customerId: {
      required: type === CONTRACT_TYPE_CUSTOMER,
    },
    employeeId: {
      required: type === CONTRACT_TYPE_EMPLOYEE,
    },
    supplierId: {
      required: type === CONTRACT_TYPE_SUPPLER,
    }
  };
};

// permission code
export const EDIT_CONTRACT = 271002;
export const READ_CONTRACT = 271000;
export const CREAT_CONTRACT = 271001;
export const PRINT_CONTRACT = 271007;
export const IMPORT_CONTRACT = 271008;
export const EXPORT_CONTRACT = 271009;
export const REPORT_CONTRACT = 271010;
export const DELETE_CONTRACT = 271003;
export const ARCHIVE_CONTRACT = 271004;
export const UNARCHIVE_CONTRACT = 271005;
export const BATCH_DELETE_CONTRACT = 271006;
