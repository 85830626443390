export const END_OF_DAY_INVOICE_REQUEST = "END_OF_DAY_INVOICE_REQUEST";
export const END_OF_DAY_INVOICE_SUCCESS = "END_OF_DAY_INVOICE_REQUEST_SUCCESS";
export const SALE_INVOICE_ACKNOWLEDGE_REQUEST = "SALE_INVOICE_ACKNOWLEDGE_REQUEST";
export const SALE_INVOICE_UNACKNOWLEDGE_REQUEST = "SALE_INVOICE_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_INVOICE = 198024;
export const UNACKNOWLEDGE_INVOICE = 198025;

export const END_OF_DAY_EXPENSE_REQUEST = "END_OF_DAY_EXPENSE_REQUEST";
export const END_OF_DAY_EXPENSE_SUCCESS = "END_OF_DAY_EXPENSE_REQUEST_SUCCESS";
export const EXPENSE_ACKNOWLEDGE_REQUEST = "EXPENSE_ACKNOWLEDGE_REQUEST";
export const EXPENSE_UNACKNOWLEDGE_REQUEST = "EXPENSE_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_EXPENSE = 183016;
export const UNACKNOWLEDGE_EXPENSE = 183017;

export const END_OF_DAY_CUSTOMER_DEPOSIT_REQUEST = "END_OF_DAY_CUSTOMER_DEPOSIT_REQUEST";
export const END_OF_DAY_CUSTOMER_DEPOSIT_SUCCESS = "END_OF_DAY_CUSTOMER_DEPOSIT_REQUEST_SUCCESS";
export const CUSTOMER_DEPOSIT_ACKNOWLEDGE_REQUEST = "CUSTOMER_DEPOSIT_ACKNOWLEDGE_REQUEST";
export const CUSTOMER_DEPOSIT_UNACKNOWLEDGE_REQUEST = "CUSTOMER_DEPOSIT_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_CUSTOMER_DEPOSIT = 181012;
export const UNACKNOWLEDGE_CUSTOMER_DEPOSIT = 181013;

export const END_OF_DAY_SUMMARY_REQUEST = "END_OF_DAY_SUMMARY_REQUEST";
export const END_OF_DAY_SUMMARY_SUCCESS = "END_OF_DAY_SUMMARY_REQUEST_SUCCESS";

export const END_OF_DAY_CUSTOMER_COMMISSION_REQUEST = "END_OF_DAY_CUSTOMER_COMMISSION_REQUEST";
export const END_OF_DAY_CUSTOMER_COMMISSION_SUCCESS = "END_OF_DAY_CUSTOMER_COMMISSION_REQUEST_SUCCESS";
export const CUSTOMER_COMMISSION_ACKNOWLEDGE_REQUEST = "CUSTOMER_COMMISSION_ACKNOWLEDGE_REQUEST";
export const CUSTOMER_COMMISSION_UNACKNOWLEDGE_REQUEST = "CUSTOMER_COMMISSION_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_CUSTOMER_COMMISSION = 232009;
export const UNACKNOWLEDGE_CUSTOMER_COMMISSION = 232010;

export const END_OF_DAY_CUSTOMER_REFUND_REQUEST = "END_OF_DAY_CUSTOMER_REFUND_REQUEST";
export const END_OF_DAY_CUSTOMER_REFUND_SUCCESS = "END_OF_DAY_CUSTOMER_REFUND_REQUEST_SUCCESS";
export const CUSTOMER_REFUND_ACKNOWLEDGE_REQUEST = "CUSTOMER_REFUND_ACKNOWLEDGE_REQUEST";
export const CUSTOMER_REFUND_UNACKNOWLEDGE_REQUEST = "CUSTOMER_REFUND_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_CUSTOMER_REFUND = 182012;
export const UNACKNOWLEDGE_CUSTOMER_REFUND = 182013;

export const END_OF_DAY_SUPPLIER_REFUND_REQUEST = "END_OF_DAY_SUPPLIER_REFUND_REQUEST";
export const END_OF_DAY_SUPPLIER_REFUND_SUCCESS = "END_OF_DAY_SUPPLIER_REFUND_REQUEST_SUCCESS";
export const SUPPLIER_REFUND_ACKNOWLEDGE_REQUEST = "SUPPLIER_REFUND_ACKNOWLEDGE_REQUEST";
export const SUPPLIER_REFUND_UNACKNOWLEDGE_REQUEST = "SUPPLIER_REFUND_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_SUPPLIER_REFUND = 240012;
export const UNACKNOWLEDGE_SUPPLIER_REFUND = 240013;

export const END_OF_DAY_PURCHASE_ORDER_REQUEST = "END_OF_DAY_PURCHASE_ORDER_REQUEST";
export const END_OF_DAY_PURCHASE_ORDER_SUCCESS = "END_OF_DAY_PURCHASE_ORDER_REQUEST_SUCCESS";
export const PURCHASE_ORDER_ACKNOWLEDGE_REQUEST = "PURCHASE_ORDER_ACKNOWLEDGE_REQUEST";
export const PURCHASE_ORDER_UNACKNOWLEDGE_REQUEST = "PURCHASE_ORDER_UNACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_PURCHASE_ORDER = 189026;
export const UNACKNOWLEDGE_PURCHASE_ORDER = 189027;

export const ACKNOWLEDGE_STATUS_DRAFT = 1;
export const ACKNOWLEDGE_STATUS_DRAFT_TITLE = "Draft";
export const ACKNOWLEDGE_STATUS_ACKNOWLEDGED = 2;
export const ACKNOWLEDGE_STATUS_ACKNOWLEDGED_TITLE = "Acknowledged";

export const END_OF_DAY_STATUS_OPEN = 1;
export const END_OF_DAY_STATUS_CLOSE = 2;

export const END_OF_DAY_CLOSE_REQUEST = "END_OF_DAY_CLOSE_REQUEST";

export const END_OF_DAY_STATUS_DATASOURCE = [
  {
    id: ACKNOWLEDGE_STATUS_DRAFT,
    name: ACKNOWLEDGE_STATUS_DRAFT_TITLE
  },
  {
    id: ACKNOWLEDGE_STATUS_ACKNOWLEDGED,
    name: ACKNOWLEDGE_STATUS_ACKNOWLEDGED_TITLE
  }
];

export const READ_END_OF_DAY_SUMMARY = 189011;
export const READ_END_OF_DAY = 189012;
export const CLOSE_END_OF_DAY = 189002;