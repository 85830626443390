// Lib
import React, { Component } from "react";

// Components
import LoginForm from "./form";
import { LOGIN_TYPE, MACHINE_ID } from "@/constants/authentication";

class Login extends Component {

  componentDidMount() {
    this.handleListenerLogout();
    this.handleListenerMachineId();
  }

  handleListenerLogout = () => {
    window.addEventListener("storage", () => {
      const localClientCredential = localStorage.getItem(LOGIN_TYPE);
      if (localClientCredential) {
        window.location.href = "/dashboard";
      }
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener("storage", null);
  }   

  handleListenerMachineId = () => {
    window.addEventListener("storage", () => {
      const machineId = localStorage.getItem(MACHINE_ID);
      if (machineId == null) {
        location.reload();
      }
    });
  }
  
  render() {
    return (
      <LoginForm form="loginForm" />
    );
  }
}

export default Login;