import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import entities from "./entities";
import error from "./error";
import request from "./request";
import pagination from "./pagination";
import menu from "./sidebar";
import autoComplete from "./auto-complete";
import clearError from "./clear-error";
import dashboard from "./dashboard";
import activityLog from "./activity-log";
import locale from "./locale";
import filter from "./filter";
import attachment from "./attachment";

// CurrentUser
import currentUser from "./current-user";
import company from "./company";
import subscription from "./subscription";
import notification from "./notification";
import comment from "./comment";
import configuration from "./configuration";
import app from "../modules/apps/reducers";

// Human Resource
import payslip from "@/modules/hr/payroll/payslip/reducers";
import payslipBatch from "@/modules/hr/payroll/payslip-batch/reducers";
import processPayroll from "@/modules/hr/payroll/process-payroll/reducers";
import employee from "@modules/hr/employee-directory/employee/reducers";
import employeeJobHistory from "@modules/hr/employee-directory/employee/reducers/employee-job-history";
import position from "@modules/hr/employee-directory/position/reducers";
import department from "@modules/hr/employee-directory/department/reducers";
import jobType from "@modules/hr/employee-directory/job-type/reducers";
import employeeContact from "@modules/hr/employee-directory/employee/reducers/employee-contact";
import employeeEducation from "@modules/hr/employee-directory/employee/reducers/employee-education";
import employeeWorkExperience from "@modules/hr/employee-directory/employee/reducers/employee-work-experience";
import employeeLanguage from "@modules/hr/employee-directory/employee/reducers/employee-language";
import employmentCard from "@modules/hr/employee-directory/employment-card/reducers";
import employeePayroll from "@modules/hr/employee-directory/employee/reducers/employee-payroll";
import workingDay from "@modules/hr/attendance/working-day/reducers";
import deductionType from "@modules/hr/payroll/deduction-type/reducers";
import earningType from "@modules/hr/payroll/earning-type/reducers";
import incentiveTemplate from "@modules/hr/payroll/incentive-template/reducers";
import salaryStructure from "@modules/hr/payroll/salary-structure/reducers";
import jobOpening from "@modules/hr/recruitment/job-opening/reducers";
import recruitmentStage from "@modules/hr/recruitment/recruitment-stage/reducers";
import offerTerm from "@modules/hr/recruitment/offer-term/reducers";
import offerLetter from "@modules/hr/recruitment/offer-letter/reducers";
import jobApplicant from "@modules/hr/recruitment/job-applicant/reducers";
import leaveType from "@modules/hr/leave/leave-type/reducers";
import leaveAllocation from "@modules/hr/leave/leave-allocation/reducers";
import leaveRequest from "@modules/hr/leave/leave-request/reducers";
import leavePolicy from "@modules/hr/leave/leave-policy/reducers";
import holidayGroup from "@modules/hr/leave/holiday-group/reducers";
import employeeLeaveSetting from "@modules/hr/employee-directory/employee/reducers/employee-leave-setting";
import employeeLeaveBalance from "@modules/hr/employee-directory/employee/reducers/employee-leave-balance";
import attendance from "@modules/hr/attendance/attendance-entry/reducers";
import attendanceSummary from "@modules/hr/attendance/attendance-entry/reducers/attendance-summary";
import cashAdvanced from "@modules/hr/cash-advanced/cash-advanced/reducers";
import saleCommissionHistory from "@modules/hr/employee-directory/employee/reducers/employee-sale-commission-history";
import plan from "@modules/hr/appraisal/plan/reducers";
import appraisalForm from "@modules/hr/appraisal/form/reducers";
import anniversary from "@modules/hr/appraisal/anniversary/reducers";
import contract from "../modules/hr/contract/contract/reducers";
import meeting from "../modules/hr/meeting/meeting/reducers";
import room from "../modules/hr/meeting/room/reducers";

// Inventory
import item from "@modules/inventory/item/item/reducers";
import category from "@modules/inventory/item/category/reducers";
import priceList from "@modules/inventory/item/price-list/reducers";
import warehouse from "@modules/inventory/stock/warehouse/reducers";
import itemInStock from "@modules/inventory/item/item/reducers/view-item-in-stock";
import stockTransfer from "@modules/inventory/stock/stock-transfer/reducers";
import itemReserveLog  from "@modules/inventory/item/item/reducers/view-item-reserve-log";
import customizeItem  from "@modules/inventory/item/customize-item/reducers";
import itemDepartment  from "@modules/inventory/item/department/reducers";
import brand from "@modules/inventory/item/brand/reducers";
import tag from "@modules/inventory/item/tag/reducers";
import itemAttribute from "@modules/inventory/item/attribute/reducers";
import itemCostingLog from "@modules/inventory/item/item/reducers/item-costing-log";
import itemCosting from "@modules/inventory/item/item/reducers/item-costing";
import stockOut from "@modules/inventory/stock/stock-out/reducers";
import junkOut from "@modules/inventory/stock/junk-out/reducers";
import junkOutType from "@modules/inventory/stock/junk-out-type/reducers";
import itemMovementLog from "@modules/inventory/stock/item-movement-log/reducers";
import syncItem from "@modules/sync-item/sync-item/reducers";
import deliveryOrder from "@modules/delivery/delivery-management/delivery-order/reducers";
import warranty from "@modules/inventory/item/warranty/reducers";
import asset from "@modules/inventory/asset/asset/reducers";
import assetRequest from "@modules/inventory/asset/asset-request/reducers";
import assetLog from "@modules/inventory/asset/asset-log/reducers";
import itemSpecification from "@modules/inventory/item/item-specification/reducers";
import itemSpecificationTemplate from "@modules/inventory/item/item-specification-template/reducers";

// Purchase
import supplier from "@modules/purchase/list/supplier/reducers";
import purchaseOrder from "@modules/purchase/list/purchase-order/reducers";
import supplierType from "@modules/purchase/list/supplier-type/reducers";
import purchaseOrderType from "@modules/purchase/list/purchase-order-type/reducers";
import shipper from "@modules/purchase/list/shipper/reducers";
import shipperPayment from "@modules/purchase/list/shipper-payment/reducers";
import purchaseOrderHistory from "@modules/purchase/list/purchase-order/reducers/purchase-order-history";

// Accounting
import customerCommission from "@modules/accounting/list/customer-commission/reducers";
import chartOfAccount from "@modules/accounting/list/chart-of-account/reducers";
import chartAccountHistory from "@modules/accounting/list/chart-of-account/reducers/chart-account-history";
import journalEntryBatch from "@modules/accounting/list/journal-entry/reducers";
import paymentMethod from "@modules/accounting/list/payment-method/reducers";
import expenseType from "@modules/accounting/list/expense-type/reducers";
import customerDeposit from "@modules/accounting/list/customer-deposit/reducers"; 
import customerRefund from "@modules/accounting/list/customer-refund/reducers"; 
import chequeEntry from "@modules/accounting/banking/cheque-entry/reducers";
import bankEntry from "@modules/accounting/banking/bank-entry/reducers";
import expense from "@modules/accounting/list/expense/reducers";
import bank from "@modules/accounting/banking/bank/reducers";
import bankTransfer from "@modules/accounting/banking/bank-transfer/reducers";
import supplierDeposit from "@modules/accounting/list/supplier-deposit/reducers";
import supplierRefund from "@modules/accounting/list/supplier-refund/reducers";
import customerDepositHistory from "@modules/accounting/list/customer-deposit/reducers/deposit-history"; 
import supplierDepositHistory from "@modules/accounting/list/supplier-deposit/reducers/deposit-history"; 
import bankSummary from "@modules/accounting/banking/bank/reducers/bank-summary";
import totalCustomerCommission from "@modules/accounting/banking/bank/reducers/customer-commission";
import bankHistory from "@modules/accounting/banking/bank/reducers/bank-history";
import bankCashOnHandDeposit from "@modules/accounting/banking/bank/reducers/cash-on-hand-deposit";
import contractor from "@modules/accounting/list/contractor/reducers";
import eodCustomerCommission from "@modules/accounting/list/end-of-day/reducers/customer-commission";
import eodCustomerDeposit from "@modules/accounting/list/end-of-day/reducers/customer-deposit";
import eodCustomerRefund from "@modules/accounting/list/end-of-day/reducers/customer-refund";
import eodExpense from "@modules/accounting/list/end-of-day/reducers/expense";
import eodInvoice from "@modules/accounting/list/end-of-day/reducers/invoice";
import eodPurchaseOrder from "@modules/accounting/list/end-of-day/reducers/purchase-order";
import eodSummary from "@modules/accounting/list/end-of-day/reducers/summary";
import eodSupplierRefund from "@modules/accounting/list/end-of-day/reducers/supplier-refund";
import loan from "@modules/accounting/banking/loan/reducers";

// Sale
import customer from "@sale/customer-center/customer/reducers";
import saleOrder from "@sale/sale-center/sale-order/reducers";
import saleInvoice from "@sale/sale-center/sale-invoice/reducers";
import companyGroup from "@sale/customer-center/company-group/reducers";
import promotion from "@sale/sale-center/promotion/reducers";
import paymentTerm from "@sale/setup/payment-term/reducers";
import saleCommission from "@sale/setup/sale-commission/reducers";
import customerCompany from "@sale/customer-center/customer-company/reducers";
import customerGroup from "@sale/customer-center/customer-group/reducers";
import saleTeam from "@sale/setup/sale-team/reducers";
import installation from "@sale/service/installation/reducers";
import installationTemplate from "@sale/service/installation-template/reducers";
import service from "@sale/service/service/reducers";
import itemInstallation from "@sale/service/item-installation/reducers";
import quotation from "@sale/sale-center/quotation/reducers";
import quotationVersion from "@sale/sale-center/quotation/reducers/quotation-version";
import bid from "@sale/sale-center/bid/reducers";
import saleReceipt from "@sale/sale-center/sale-receipt/reducers";
import serviceHistory from "@sale/service/service/reducers/history";
import detailSales from "@sale/sale-center/detailed-sale/reducers";

// Settings
import userAccount from "@modules/settings/user-management/user-account/reducers";
import session from "@modules/settings/user-management/user-account/reducers/session";
import role from "@modules/settings/user-management/role/reducers";
import permission from "@modules/settings/user-management/role/reducers/permission";
import permissionGroupByRole from "@modules/settings/user-management/role/reducers/permission";
import permissionGroup from "@modules/settings/user-management/role/reducers/permission-group";
import currency from "@modules/settings/setup/currency/reducers";
import currencyExchange from "@modules/settings/setup/currency-exchange/reducers";
import reportTemplate from "@modules/settings/setup/report-template/reducers";
import printFormat from "@modules/settings/setup/print-format/reducers";
import companyBranch from "@modules/settings/setup/company/reducers";
import tax from "@modules/settings/setup/tax/reducers";
import emailTemplate from "@modules/settings/email/email-template/reducers";
import emailTemplateVariable from "@modules/settings/email/email-template/reducers/email-template-variable";
import emailAccount from "@modules/settings/email/email-account/reducers";
import noSeriesList from "@modules/settings/setup/no-series-list/reducers";
import logInApproval from "@modules/settings/user-management/log-in-approval/reducers";
import customField from "@modules/settings/setup/custom-field/reducers";
import configurationList from "@modules/settings/setup/configuration/reducers";
import integration from "@modules/settings/integrations/integration/reducers";
import goal from "@modules/settings/goal/goal/reducers";

// Pos
import pos from "@modules/point-of-sale/pos/reducers";
import posSession from "@modules/point-of-sale/pos/reducers/session";
import saleHistory from "@modules/point-of-sale/sale-history/reducers";
import posBalance from "@modules/point-of-sale/pos/reducers/pos-balance";
import posInvoice from "@modules/point-of-sale/pos/reducers/pos-invoice";
import posFullScreen from "@modules/point-of-sale/pos/reducers/full-screen";

// Report
import favoriteReport from "@modules/reports/reducers"; 

// Website
import banner from "@modules/website/store-front/banner/reducers";
import itemCatalog from "@modules/website/store-front/item-catalog/reducers";
import contact from "@modules/website/support/contact/reducers";
import order from "@modules/website/e-commerce/order/reducers";
import cart from "@modules/website/e-commerce/cart/reducers";

export default combineReducers({
  form: formReducer.plugin(
    {
      createJournalEntry: clearError,
      editJournalEntry: clearError,
      createStockTransfer: clearError,
      editStockTransfer: clearError,
      createExpense: clearError,
      editExpense: clearError,
      createPurchaseOrder: clearError,
      editPurchaseOrder: clearError,
      createSaleOrder: clearError,      
      editSaleOrder: clearError,
      editLeaveSettingForm: clearError,
      editEmployeePayrollForm: clearError,
      createEmployeePayrollForm: clearError,
      stockTransferItems: clearError,
    }
  ),
  currentUser,
  userAccount,
  pagination,
  entities,
  request,
  error,
  menu,
  position,
  role,
  permission,
  department,
  category,
  autoComplete,
  employee,
  warehouse,
  supplier,
  customer,
  purchaseOrder,
  item,
  currency,
  customerCommission,
  currencyExchange,
  journalEntryBatch,
  saleOrder,
  employeeJobHistory,
  itemInStock,
  saleInvoice,
  paymentMethod,
  expenseType,
  stockTransfer,
  chequeEntry,
  expense,
  bank,
  dashboard,
  shipper,
  logInApproval,
  priceList,
  companyGroup,
  promotion,
  deliveryOrder,
  customerDeposit,
  reportTemplate,
  configuration,
  printFormat,
  paymentTerm,
  supplierDeposit,
  processPayroll,
  itemReserveLog,
  customerDepositHistory,
  supplierDepositHistory,
  customizeItem,
  payslip,
  chartOfAccount,
  purchaseOrderHistory,
  attachment,
  company,
  activityLog,
  bankTransfer,
  subscription,
  notification,
  comment,
  jobType,
  companyBranch,
  employeeContact,
  employeeEducation,
  employeeWorkExperience,
  employeeLanguage,
  employeePayroll,
  workingDay,
  tax,
  deductionType,
  earningType,
  salaryStructure,
  saleCommission,
  jobOpening,
  recruitmentStage,
  offerTerm,
  offerLetter,
  jobApplicant,
  employmentCard,
  leaveType,
  leaveAllocation,
  leaveRequest,
  leavePolicy,
  holidayGroup,
  employeeLeaveSetting,
  employeeLeaveBalance,
  attendance,
  attendanceSummary,
  payslipBatch,
  emailTemplate,
  emailTemplateVariable,
  emailAccount,
  permissionGroupByRole,
  permissionGroup,
  session,
  noSeriesList,
  customerRefund,
  bankSummary,
  bankCashOnHandDeposit,
  bankEntry,
  bankHistory,
  purchaseOrderType,
  supplierType,
  itemDepartment,
  brand,
  tag,
  itemAttribute,
  customerCompany,
  customerGroup,
  saleTeam,
  installation,
  service,
  itemCostingLog,
  itemCosting,
  stockOut,
  cashAdvanced,
  itemInstallation,
  supplierRefund,
  warranty,
  detailSales,
  syncItem,
  installationTemplate,
  customField,
  quotation,
  quotationVersion,
  saleCommissionHistory,
  contractor,
  incentiveTemplate,
  eodCustomerCommission,
  eodCustomerDeposit,
  eodCustomerRefund,
  eodExpense,
  eodInvoice,
  eodPurchaseOrder,
  eodSummary,
  eodSupplierRefund,
  pos,
  bid,
  configurationList,
  saleReceipt,
  asset,
  assetRequest,
  itemMovementLog,
  assetLog,
  junkOut,
  app,
  posSession,
  posFullScreen,
  favoriteReport,
  posBalance,
  posInvoice,
  locale,
  saleHistory,
  chartAccountHistory,
  plan,
  appraisalForm,
  anniversary,
  contract,
  integration,
  banner,
  itemCatalog,
  contact,
  order,
  cart,
  serviceHistory,
  shipperPayment,
  junkOutType,
  filter,
  loan,
  goal,
  itemSpecification,
  itemSpecificationTemplate,
  totalCustomerCommission,
  meeting,
  room,
});