import {
  GETList,
  GETDetail,
  PUTWithToken,
  POSTWithToken,
  GETAutoComplete,
} from "@/services/base-service";

const API_URL = "/api/no-series-list/v1";

export const getNoSeriesList = (values) => {
  const url = `${API_URL}/list`;
  return GETList(url, values);
};

export function createNoSeriesList(values) {
  const url = `${API_URL}/create`;
  return POSTWithToken(url, values);
}

export function getNoSeriesListAutoComplete(query, extra) {
  const url = `${API_URL}/autoComplete`;
  return GETAutoComplete(url, query, extra);
}

export function getNoSeriesListDetail(id) {
  return GETDetail(API_URL, id);
}

export function editNoSeriesList(values) {
  const { id } = values;
  const url = `${API_URL}/${id}`;
  return PUTWithToken(url, values);
}