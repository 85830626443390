// Utils
import { 
  CustomerCommissionSchema,
  CustomerCommissionArraySchema, 
} from "../schema";
import {
  createListAction,
  createDetailAction,
  createArchiveAction,
  createUnarchiveAction,
  createAutoCompleteAction,
  createEntityActionAction,
} from "@modules/utils/action-creator";
import * as service from "../services";
import * as CustomerCommissionConstants from "../constants";

const path = "/accounting/list/customer-commission";

export const getCustomerCommissionWithCurrentUser = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomerCommissionWithCurrentUser,
    schema: CustomerCommissionArraySchema,
    requestName: CustomerCommissionConstants.CUSTOMER_COMMISSION_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getAll = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomerCommission,
    schema: CustomerCommissionArraySchema,
    requestName: CustomerCommissionConstants.CUSTOMER_COMMISSION_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

export const getCustomerCommissionAutoComplete = (query, extra) => (dispatch, getState) => {
  const { requestName } = extra;
  const options = {
    serviceMethod: service.getCustomerCommissionAutoComplete,
    requestName: requestName,
    dispatch,
    getState,
  };
  return createAutoCompleteAction(options, query, extra);
};

export const getCustomerCommissionDetail = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getCustomerCommissionDetail,
    requestName: CustomerCommissionConstants.CUSTOMER_COMMISSION_DETAIL_REQUEST,
    dispatch,
    getState,
    schema: CustomerCommissionSchema,
  };
  return createDetailAction(options, id);
};

export const archiveCustomerCommission = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.archiveCustomerCommission,
    requestName: CustomerCommissionConstants.CUSTOMER_COMMISSION_ARCHIVE_REQUEST,
    dispatch,
    getState,
    redirectUrl: path,
    successMessage: "Customer commission is archived successfully!",
  };
  return createArchiveAction(options, id);
};

export const unarchiveCustomerCommission = (id) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.unarchiveCustomerCommission,
    requestName: CustomerCommissionConstants.CUSTOMER_COMMISSION_UNARCHIVE_REQUEST,
    dispatch,
    getState,
    successMessage: "Customer commission is unarchived successfully!",
  };
  return createUnarchiveAction(options, id);
};

export const clearCustomerCommission = (values) => (dispatch, getState) => {
  const options = {
    schema: CustomerCommissionSchema,
    serviceMethod: service.clearCustomerCommission,
    requestName: CustomerCommissionConstants.CLEAR_CUSTOMER_COMMISSION_REQUEST,
    getState,
    dispatch,
    successMessage: "Customer commission is cleared successfully!",
  };
  return createEntityActionAction(options, values);
};

export const cancelCustomerCommission = (id) => (dispatch, getState) => {
  const options = {
    schema: CustomerCommissionSchema,
    serviceMethod: service.cancelCustomerCommission,
    requestName: CustomerCommissionConstants.CANCEL_CUSTOMER_COMMISSION_REQUEST,
    getState,
    dispatch,
    successMessage: "Customer commission is cancelled successfully!",
  };
  return createEntityActionAction(options, id);
};