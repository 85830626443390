import * as AttendanceConstant from "../constants";
import _ from "lodash";

const initialState = {
  list: [],
  invalidList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
  case AttendanceConstant.ATTENDANCE_SUCCESS:
    return {
      ...state,
      list: action.payload
    };
  case AttendanceConstant.ATTENDANCE_INVALID_SUCCESS:
    return {
      ...state,
      invalidList: action.payload
    };
  case AttendanceConstant.ATTENDANCE_CHECK_IN_SUCCESS:
    return {
      ...state,
      list: [action.attendances, ...state.list]
    };
  case AttendanceConstant.ATTENDANCE_DELETE_SUCCESS: {
    const { list } = state;
    const { payload } = action;
    const index = _.findIndex(list, (value) => value == payload);
    let attendances = [
      ...list
    ];
    attendances.splice(index, 1);
    return { 
      ...state,
      list: attendances
    };
  }
  default:
    return state;
  }
}