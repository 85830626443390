import {
  GETList, 
  PUTWithToken,
} from "@/services/base-service";

const API_URL = "/api/asset/v1";

export const getAsset = (values) => {
  const url = `${API_URL}/list`;
  values.filter.includes = ["employee", "item", "itemSerial"];
  return GETList(url, values);
};

export function transferAsset(values){
  const { id } = values;
  const url = `${API_URL}/${id}/transfer`;
  return PUTWithToken(url, values);
}

export function assetBackToStock(values){
  const { id } = values;
  const url = `${API_URL}/${id}/back-to-stock`;
  return PUTWithToken(url, values);
}