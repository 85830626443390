import React from "react";
import PropTypes from "prop-types";
import { useLocalStorageState, useRequest } from "ahooks";
import * as AuthenticationConstants from "@/constants/authentication";
import * as moment from "moment";
import * as service from "@/services/authentication";
import { DEFAULT_DISPLAY_DATE_TIME_FORMAT } from "@/constants/default-constants";
import { Spin } from "antd";

export const ClientAuthContext = React.createContext();

export const ClientAuthContextProvider = (props) => {
  const [clientCredential, setClientCredential] = useLocalStorageState(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
  const { data, loading, run } = useRequest(() => service.authenticateClientCredential(AuthenticationConstants.CLIENT_CREDENTIAL_VALUES), {
    manual: true,
    onSuccess: (accessToken) => {
      setClientCredential(accessToken);
    },
  });
  React.useEffect(() => {
    if (clientCredential == null) {
      run();
    } else {
      const { expiresIn } = clientCredential;
      const momentExpireDate = moment(expiresIn).format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      const expireDate = moment(momentExpireDate);
      const now = moment().format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      const currentDate = moment(now);
      const isExpired = expireDate.isBefore(currentDate);
      if (isExpired) {
        run();
      }
    }
  }, []);
  if (clientCredential == null && data != null && loading == true) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <Spin size="large" tip="We're waking everyone up to start working" />
      </div>
    );
  }
  return (
    <ClientAuthContext.Provider value={{ clientCredential }}>
      {clientCredential && props.children}
    </ClientAuthContext.Provider>
  );
};

export const useClientAuth = () => React.useContext(ClientAuthContext);

ClientAuthContextProvider.propTypes = {
  children: PropTypes.any,
};