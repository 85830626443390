import React from "react";
import PropTypes from "prop-types";
import { Button, Form } from "antd";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { reduxForm } from "redux-form";
import { SaveOutlined } from "@ant-design/icons";
import { SubmissionError } from "redux-form";

// Components
import Input from "@cores/components/Input";
import Checkbox from "@cores/components/check-box";

// Utils
import { createSubmitAction } from "@/helpers";
import { editFilter, createFilter } from "@/actions/filter";
import { 
  getFilterSlug, 
  getQueryParamUrl, 
  getFilterValuesFromQuery, 
} from "@modules/helpers";
import { 
  FILTER_CREATE_REQUEST, 
  FILTER_EDIT_REQUEST, 
} from "@/constants/filter";

function FilterForm(props) {

  const { 
    edit, 
    loading,
    onDismiss, 
    handleSubmit, 
  } = props;

  const beforeSubmit = (values) => {
    const newValue = {...values};
    newValue.slug = getFilterSlug(values.name);
    if (newValue.slug != "all") {
      return newValue;           
    }
    throw new SubmissionError({
      name: "You can not use this name"
    });                                                                                  
  };

  const onSubmit = (values, dispatch) => {
    const rules = {
      name: {
        required: true,
      }
    };
    const action = edit ? editFilter : createFilter;
    createSubmitAction(
      rules, 
      action, 
      beforeSubmit
    )(values, dispatch).then((response) => {
      if (response?.error == null) {
        onDismiss();
      }
    });
  };
  
  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
      <Row>
        <Col xs="12">
          <Input 
            autoFocus
            required
            name="name"
            label="Name"
          />
        </Col>
        <Col xs="12">
          <Checkbox 
            name="isDefault"
            label="Is Default"
          />
        </Col>
        <Col xs="12">
          <Button
            ghost
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<SaveOutlined />}
            className="float-right"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

FilterForm.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { matched, edit, entity, order } = ownProps;
  const query = getQueryParamUrl();
  const requestName = edit ? FILTER_EDIT_REQUEST : FILTER_CREATE_REQUEST;
  return {
    initialValues: {
      entity,
      id: edit ? matched && matched.id : null,
      name: edit ? matched && matched.name : "",
      slug: edit ? matched && matched.slug : "",
      isDefault: edit ? matched && matched.isDefault : false,
      order: edit ? matched && matched.order : order,
      filters: getFilterValuesFromQuery(query)
    },
    loading: state.request[requestName],
  };
};

const ReduxFormConnect = reduxForm({
  enableReinitialize: true,
})(FilterForm);

export default connect(mapStateToProps)(ReduxFormConnect);