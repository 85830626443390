import { normalize } from "normalizr";
import { reset } from "redux-form";
import { message } from "antd";

// Utils
import { setError } from "@/actions/error";
import { setAutoComplete } from "@/actions/auto-complete";
import { setDashboard } from "@/actions/dashboard";
import { setPagination } from "@/actions/pagination";
import { setRequestInProcess } from "@/actions/request";
import { mergeEntities, syncEntities, syncArrayEntities } from "@/actions/entity";
import * as CreateLayoutConstants from "@/components/layout/create-layout/constant";
import { history } from "@/shared/utils/history";
import { CLEAR_ERROR } from "@/constants/clear-error";
import { mergeAndReplace } from "src/actions/entity";

export const createListAction = (options, values) => {
  const {
    serviceMethod,
    schema,
    requestName,
    dispatch,
    getState,
    errorHandler,
    beforeMerge,
    afterMerge,
    onNewData,
    isDefaultResponse  = true,
  } = options;

  let { successAction } = options;

  const newRequestName = values && values.requestName;

  const getListSuccess = (objectIds) => {
    return {
      type: `${requestName}_SUCCESS`,
      payload: objectIds
    };
  };

  if (successAction == null) {
    successAction = getListSuccess;
  }

  const requestInProcess = getState().request[(newRequestName || requestName)];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, (newRequestName || requestName)));
  return serviceMethod(values)
    .then((response) => {
      if (isDefaultResponse) {
        let { data, ...pagination } = response;
        if (onNewData) {
          data = onNewData(response);
        } 
        const normalized = normalize(data, schema);
        if (beforeMerge) {
          beforeMerge(getState(), normalized);
        }
        dispatch(mergeEntities(normalized.entities));
        if (afterMerge) {
          afterMerge(getState(), normalized);
        }
        dispatch(setPagination(pagination, (newRequestName || requestName)));
        dispatch(successAction(normalized.result, values));
      } else {
        dispatch(successAction(response));
      }
      dispatch(setRequestInProcess(false, (newRequestName || requestName)));
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, (newRequestName || requestName)));
        dispatch(setRequestInProcess(false, (newRequestName || requestName)));
        if (errorHandler) {
          errorHandler(error);
        }
      });
    });
};

export const createNewEntityAction = (options, values) => {
  const {
    serviceMethod,
    requestName,
    dispatch,
    getState,
    redirectUrl,
    errorHandler,
    successAction,
    onShouldContinueCreate,
    entityName,
    errorMessage,
    redirect=true,
    displayMessage=true,
    onSuccess,
  } = options;

  let { successMessage } = options;
  if (successMessage == null) {
    const { entityTitle, actionType } = values;
    const action = actionType && actionType == "duplicated" ? "duplicated" : "created";
    successMessage = `${entityTitle || entityName} is ${action} successfully`;
  }

  const requestInProcess = getState().request[requestName];
  
  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  const newValues = { ...values };
  delete newValues[CreateLayoutConstants.SAVE_AND_CONTINUE_CREATE];
  delete newValues[CreateLayoutConstants.SAVE_AND_CONTINUE_EDIT];
  delete newValues.formName;
  delete newValues.entityTitle;
  delete newValues.saveAndContinueKey;
  delete newValues.notRedirectPage;
  return serviceMethod(newValues)
    .then((json) => {
      dispatch(setRequestInProcess(false, requestName));
      const { 
        shouldContinueCreate, 
        shouldContinueEdit,
        saveAndContinueKey,
        formName
      } = values;
      if (redirect) {
        if (shouldContinueCreate) {
          dispatch(reset(formName));
          if (onShouldContinueCreate) {
            onShouldContinueCreate(values.formName);
          }
        } else if (shouldContinueEdit) {
          history.push(`${redirectUrl}/${json.id}/${saveAndContinueKey}`);
        } else {
          if (typeof redirectUrl == "function") {
            history.push(redirectUrl(json));        
          } else {
            history.push(`${redirectUrl}/${json.id}`);        
          }
        }
      }
      if (successAction) {
        successAction(json, values);
      }
      if (onSuccess) {
        dispatch(onSuccess(json));
      }
      if (displayMessage) {
        message.success(successMessage);
      }
      return json;
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          let { message: newErrorMessage, errorCode } = error;
          if (errorMessage) {
            const newMessage = errorMessage(errorCode);
            if (newMessage != null) {
              newErrorMessage = newMessage ; 
            }
          }
          message.error(newErrorMessage);
        }
      });
      return error;
    });
};

export const createDetailAction = (options, id, ...extra) => {
  const {
    schema,
    dispatch,
    getState,
    onSuccess,
    entityName,
    requestName,
    errorHandler,
    serviceMethod,
    successAction,
    afterMerge,
    onNewNormalize,
    successMessage,
  } = options;

  const requestInProcess = getState().request[requestName];
  
  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  dispatch(setError(false, requestName));
  return serviceMethod(id, ...extra)
    .then((json) => {
      if (onNewNormalize) {
        onNewNormalize(json);
      } else {
        if (onSuccess) {
          json = onSuccess(json);
        }
        if (schema) {
          const normalized = normalize(json, schema);
          dispatch(mergeAndReplace(normalized.entities));
          if (afterMerge) {
            afterMerge(getState(), normalized);
          }
          if (entityName) {
            dispatch(syncEntities(normalized.entities[entityName][normalized.result], entityName));
          }
          if (successAction) {
            dispatch(successAction(normalized.result, id, json));
          }
        } else {
          if (successAction) {
            dispatch(successAction(json));
          }
        }
      }
      if (successMessage) {
        message.success(successMessage);
        message.success(successMessage);
      }
      dispatch(setRequestInProcess(false, requestName));
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setRequestInProcess(false, requestName));
        dispatch(setError(error, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          const { statusCode } = error; 
          if (statusCode != 404 && statusCode != 500) {
            message.error(error.message);
            message.error(error.message);
          }
        }
      });
    });
};

export const createEditAction = (options, values) => {
  const {
    schema,
    dispatch,
    getState,
    entityName,
    redirectUrl,
    requestName,
    errorHandler,
    serviceMethod,
    successMessage,
    successAction,
    idAttribute,
    onSuccess,
    syncArray,
    showMessage=true,
    syncArrayEntityName,
  } = options;

  const { notRedirectPage } = values;

  const requestInProcess = getState().request[requestName];
  
  if (requestInProcess) {
    return;
  }
  const newValues = {...values};
  delete newValues.notRedirectPage;
  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(values)
    .then((json) => {
      if (successAction) {
        dispatch(successAction(json));
      }
      if (entityName) {
        const normalized = normalize(json, schema);
        dispatch(mergeEntities(normalized.entities));
        dispatch(syncEntities(normalized.entities[entityName][normalized.result], entityName, idAttribute));
        if (syncArray) {
          dispatch(syncArrayEntities(normalized.entities[syncArrayEntityName || entityName], syncArrayEntityName || entityName));
        }
      }
      dispatch(setRequestInProcess(false, requestName));
      if (onSuccess) {
        onSuccess(json);
      }
      if (redirectUrl != null && notRedirectPage == null) {
        if (typeof redirectUrl == "function") {
          history.push(redirectUrl(json));        
        } else {
          history.push(`${redirectUrl}/${json.id}`);        
        }
      }
      if (successMessage) {
        message.success(successMessage);
      }
      return json;
    })
    .catch(error => {
      dispatch(setRequestInProcess(false, requestName));
      Promise.resolve(error).then(error => {
        if (showMessage) {
          if (errorHandler) {
            errorHandler(error);
          } else { 
            message.error(error.message);
          }
        }
      });
      return error;
    });
};

export const createDeleteAction = (options, id) => {
  const {
    serviceMethod,
    requestName,
    dispatch,
    getState,
    errorHandler,
    redirectUrl,
    successAction,
    successMessage,
    onSuccess,
  } = options;
  
  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(id)
    .then(() => {
      dispatch(setRequestInProcess(false, requestName));
      if (successAction) {
        dispatch(successAction());
      }
      if (onSuccess) {
        onSuccess();
      }
      if (redirectUrl) {
        history.push(redirectUrl);
      }
      if (successMessage) {
        message.success(successMessage);
      }
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createArchiveAction = (options, id) => {
  const {
    serviceMethod,
    requestName,
    dispatch,
    getState,
    errorHandler,
    redirectUrl,
    successMessage,
  } = options;

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(id)
    .then(() => {
      dispatch(setRequestInProcess(false, requestName));
      if (redirectUrl) {
        history.push(redirectUrl);
      }
      message.success(successMessage);
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createUnarchiveAction = (options, id) => {
  const {
    serviceMethod,
    requestName,
    dispatch,
    getState,
    errorHandler,
    successMessage,
  } = options;

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(id)
    .then(() => {
      dispatch(setRequestInProcess(false, requestName));
      message.success(successMessage);
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createAutoCompleteAction = (options, query, extra) => {
  const {
    serviceMethod,
    requestName,
    dispatch,
    getState,
    errorHandler,
    onNewData,
  } = options;

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(query, extra)
    .then((response) => {
      let { data } = response;
      if (onNewData) {
        data = onNewData(response);
      }
      dispatch(setAutoComplete(data, requestName));
      dispatch(setRequestInProcess(false, requestName));
      return data;
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createEntityActionAction = (options, id) => {
  const {
    serviceMethod,
    schema,
    requestName,
    dispatch,
    getState,
    errorHandler,
    successMessage,
    redirectUrl,
    successAction,
    entityName,
    shouldSyncEntities,
    idAttribute,
  } = options;

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }


  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(id)
    .then((response) => {
      let normalized = null;
      if (schema) {
        normalized = normalize(response, schema);
        dispatch(mergeEntities(normalized.entities));
        if (entityName && shouldSyncEntities) {
          dispatch(syncEntities(normalized.entities[entityName][normalized.result], entityName, idAttribute));
        }
      }
      dispatch(setRequestInProcess(false, requestName));
      if (redirectUrl) {
        if (typeof redirectUrl == "function") {
          history.push(redirectUrl(response));        
        } else {
          history.push(`${redirectUrl}/${response.id}`);        
        }
      }
      if (successAction) {
        successAction(response, normalized);
      }
      if (successMessage) {
        message.success(successMessage);
      }
      return response;
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createTreeAction = (options, values) => {
  const {
    serviceMethod,
    schema,
    requestName,
    dispatch,
    getState,
    errorHandler,
    entityName
  } = options;

  let { successAction } = options;
  
  const newRequestName = values && values.requestName;

  const getListSuccess = (objectIds) => {
    return {
      type: `${requestName}_SUCCESS`,
      payload: objectIds
    };
  };

  if (successAction == null) {
    successAction = getListSuccess;
  }

  const requestInProcess = getState().request[(newRequestName || requestName)];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, (newRequestName || requestName)));
  return serviceMethod(values)
    .then((response) => {
      const normalized = normalize(response, schema);
      dispatch(mergeEntities(normalized.entities));
      dispatch(syncArrayEntities(normalized.entities[entityName], entityName));
      dispatch(successAction(normalized.result, values));
      dispatch(setRequestInProcess(false, (newRequestName || requestName)));
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, (newRequestName || requestName)));
        dispatch(setRequestInProcess(false, (newRequestName || requestName)));
        if (errorHandler) {
          errorHandler(error);
        }
      });
    });
};

export const clearErrorForm = (form, field) => {
  return {
    type: CLEAR_ERROR,
    form,
    field,
  };
};

export const createDashboardAction = (options, query, endPoint) => {
  const {
    serviceMethod,
    requestName,
    dispatch,
    getState,
    errorHandler,
  } = options;

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(query, endPoint)
    .then((response) => {
      dispatch(setDashboard(response, requestName));
      dispatch(setRequestInProcess(false, requestName));
      return response;
    })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createListWithSeeMoreAction = (options, values) => {
  const {
    id,
    page,
  } = values;
  
  const {
    schema,
    dispatch,
    getState,
    requestName,
    errorHandler,
    serviceMethod,
    getDataSuccess,
    getDataSuccessAddArray,
  } = options;

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }
  
  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(values)
    .then(
      (response) => {
        const { data, ...pagination } = response;
        const normalized = normalize(data, schema);
        dispatch(mergeEntities(normalized.entities));
        dispatch(setPagination(pagination, requestName));
        if (id && page > 0) {
          dispatch(getDataSuccessAddArray(normalized.result, id));
        } else if (id && page == 0) {
          dispatch(getDataSuccess(normalized.result, id));
        } else if (!id && page > 0) {
          dispatch(getDataSuccessAddArray(normalized.result));
        } else {
          dispatch(getDataSuccess(normalized.result));
        }
        dispatch(setRequestInProcess(false, requestName));
      })
    .catch(error => {
      Promise.resolve(error).then(error => {
        dispatch(setError(error, requestName));
        dispatch(setRequestInProcess(false, requestName));
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
    });
};

export const createNewEntityWithSeeMoreAction = (options, values) => {
  const {
    schema,
    dispatch,
    getState,
    entityName,
    requestName,
    errorHandler,
    serviceMethod,
    successMessage,
    formName,
    onSuccess,
    afterMerge,
    onNewData,
  } = options;

  const requestInProcess = getState().request[requestName];
  
  if (requestInProcess) {
    return;
  }
  
  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(values)
    .then((json) => {
      if (onNewData) {
        json = onNewData(json);
      }
      const normalized = normalize(json, schema);
      dispatch(mergeEntities(normalized.entities));
      if (entityName) {
        dispatch(syncEntities(
          normalized.entities[entityName][normalized.result], 
          entityName
        ));
      }
      if (afterMerge) {
        afterMerge(getState(), normalized);
      }
      if (onSuccess) {
        dispatch(onSuccess(normalized.result));
      }
      if (formName) {
        dispatch(reset(formName));
      }
      if (successMessage) {
        message.success(successMessage);
      }
      dispatch(setRequestInProcess(false, requestName));
    })
    .catch((error) => {
      dispatch(setRequestInProcess(false, requestName));
      Promise.resolve(error).then(error => {
        if (errorHandler) {
          errorHandler(error);
        } else { 
          message.error(error.message);
        }
      });
      return error;
    });
};