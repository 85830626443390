import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Pagination, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// Constants
import * as Constants from "./constant";
const { Text } = Typography;

class Counter extends React.Component {
  
  render() {
    const {
      onClick,
      loading,
      className,
      selectedTitle,
      pagination: {
        limit,
        offset,
        total,
        count,
      },
      handlePaginationChange,
      handlePageSizeChange,
      defaultCurrent,
      dataSource,
      selected,
      lastUpdated
    } = this.props;
    return (
      <div className={`d-flex justify-content-between align-items-center ${className}`}>
        <div className="d-flex align-items-center">
          <Typography.Text>
            {dataSource.length == 0 ? 0 : offset + 1 || 0} - {count + offset || 0} <FormattedMessage id="listLayout.of" /> {total || 0} 
            <SyncOutlined 
              className="ml-1"
              onClick={onClick} 
              type="sync" 
              spin={loading}
            />
            {selected > 0 && (
              <span style={{ marginLeft: 8 }}>
                Selected {selected} {selected > 1 ? `${selectedTitle}s` : selectedTitle}
              </span>
            )}
            {lastUpdated != null && (
              <Text type="secondary" className="ml-1">Last updated: {lastUpdated}</Text> 
            )}
          </Typography.Text>
        </div>
        <div className="d-flex align-items-center">
          <Pagination 
            size="small" 
            showSizeChanger 
            total={total} 
            pageSize={limit}
            current={defaultCurrent}
            onChange={handlePaginationChange}
            onShowSizeChange={handlePageSizeChange}
            pageSizeOptions={Constants.LIST_LAYOUT_PAGE_SIZE}
          />
        </div>
      </div>
    );
  }
}

Counter.propTypes = {
  spin: PropTypes.bool,
  lastUpdated: PropTypes.string,
  className: PropTypes.string,
  selectedTitle: PropTypes.string,
  defaultCurrent: PropTypes.number,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    count: PropTypes.number,
    total: PropTypes.number
  }),
  handlePaginationChange: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  dataSource: PropTypes.array,
  selected: PropTypes.number,
};

Counter.defaultProps = {
  selected: 0,
  selectedTitle: "Item",
  pagination: {
    limit: 0,
    offset: 0,
    count: 0,
    total: 0,
  },
};

export default Counter;