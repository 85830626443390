import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { FormattedMessage } from "react-intl";
import { Spin, Card, Form, Row, Col } from "antd";

// component
import Header from "./header";
import DisableBox from "@modules/cores/components/disabled-box";

import Title from "@cores/components/title";
import { withReduxForm } from "@modules/cores/components/form";
import CustomFieldField from "@modules/settings/setup/custom-field/components/render-input";

// Utils
import { createSubmitAction } from "@/helpers";
import { getBreadcrumbPageLabel } from "@modules/helpers";
import * as CreateLayoutConstants from "./constant";

function submit(values, dispatch, props) {
  const { 
    form,
    title,
    newSubmit,
    createAction, 
    beforeSubmit,
    beforeSubmissionError, 
    shouldContinueCreate,
    shouldContinueEdit, 
    saveAndContinueKey,
    customFieldDataSource,
    onCreateSuccess,
  } = props;
  let { 
    rules
  } = props;
  values[CreateLayoutConstants.SAVE_AND_CONTINUE_CREATE] = shouldContinueCreate;
  values[CreateLayoutConstants.SAVE_AND_CONTINUE_EDIT] = shouldContinueEdit;
  values["formName"] = form;
  values.saveAndContinueKey = saveAndContinueKey;
  values["entityTitle"] = getBreadcrumbPageLabel(title);
  if (typeof rules === "function") {
    rules = rules(values);
  }
  if (customFieldDataSource && customFieldDataSource.length > 0) {
    const subFields = [];
    customFieldDataSource.map((value, index) => {
      const { name, id, isRequired } = value;
      const customFieldSubmitRulesSubFields = {
        name: `${id}_${name}`,
        required: isRequired,
        inputKey: index
      };
      subFields.push(customFieldSubmitRulesSubFields);
    });
    const customFieldSubmitRules = {
      customFieldsArrayField: {
        type: "arrayInlineCustomField",
        subFields
      }
    };
    rules = Object.assign(rules, customFieldSubmitRules);
  }
  if (values.customFieldsArrayField && values.customFieldsArrayField.length > 0) {
    const newCustomFieldValues = [];
    values.customFieldsArrayField.map((value) => {
      let subValue = {};
      if (value) {
        const fieldKey = Object.keys(value).toString();
        const customFieldKey = fieldKey.split("_");
        const id = Number(customFieldKey[0]);
        subValue.id = id;
        subValue.customFieldValue = {
          value: value[fieldKey]
        };
      }
      newCustomFieldValues.push(subValue);
    });
    values.customFields = newCustomFieldValues;
  }
  
  if (newSubmit) {
    newSubmit(values, dispatch, props);
  } else {
    if (typeof rules === "function") {
      rules = rules(values);
    }
    return createSubmitAction(rules, createAction, () => {
      if (beforeSubmit) {
        return beforeSubmit(values);
      } else {
        return values;
      }
    },
    true, () => {
      if (beforeSubmissionError) {
        beforeSubmissionError(values);
      }
    }
    )(values, dispatch).then((value) => {
      if (value != null) {
        if (onCreateSuccess) {
          onCreateSuccess(value);
        }
      }
    });
  }
}

class CreateLayoutForm extends Component {

  render() {
    const { 
      form,
      title,
      onSave,
      loading,
      children,  
      className,
      showHeader,
      fullWrapper,
      handleSubmit, 
      extraButtons,
      shouldDisabled,
      disabledMessage,
      extraMenuDropDown,
      shouldContinueEdit,
      customFieldDataSource,
      onSaveAndContinueEdit,
      onSaveAndContinueCreate,
      formComponent: FormComponent,
      ...props
    } = this.props;
    const isMatch = window.matchMedia("(max-width: 1440px)").matches;
    return (
      <Fragment>
        {showHeader && (
          <Header 
            title={title}
            onSave={onSave}
            loading={loading} 
            extraButtons={extraButtons}
            handleSubmit={handleSubmit} 
            shouldDisabled={shouldDisabled}        
            extraMenuDropDown={extraMenuDropDown}
            shouldContinueEdit={shouldContinueEdit}
            onSaveAndContinueCreate={onSaveAndContinueCreate}
            onSaveAndContinueEdit={onSaveAndContinueEdit}
          />
        )}
        <Row>
          <Col 
            xs={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xl={(fullWrapper || isMatch) ? {span: 24, offset: 0} : {span: 16, offset: 4}}
          >
            <Spin 
              spinning={loading} 
              tip={<FormattedMessage id="createLayout.loading" />}
            >
              <Card 
                bordered={false} 
                className={className}
                bodyStyle={{padding: "16px"}} 
              >
                <Form onFinish={() => handleSubmit()} layout="vertical">
                  <FormComponent 
                    formName={form}
                    onSave={onSave}
                    onSaveAndContinueEdit={onSaveAndContinueEdit}
                    onSaveAndContinueCreate={onSaveAndContinueCreate}
                    {...props}
                  />
                  {customFieldDataSource.length > 0 && (
                    <Fragment>
                      <Title title={<FormattedMessage id="createLayout.additionalInfo" />} />
                      <FieldArray 
                        name="customFieldsArrayField" 
                        component={CustomFieldField} 
                        customFieldDataSource={customFieldDataSource}
                      />
                    </Fragment>
                  )}
                </Form>
                {children}
                {shouldDisabled && (
                  disabledMessage ? (
                    <DisableBox
                      tag="h4"
                      className="text-center"
                      message={disabledMessage}
                    />
                  ) : (
                    <DisableBox
                      className="text-center"
                      message={`This ${getBreadcrumbPageLabel(title)} cannot be create.`}
                    />
                  )
                )}
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

CreateLayoutForm.propTypes = {
  children: PropTypes.node,
  entity: PropTypes.string,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  form: PropTypes.string,
  title: PropTypes.string,
  fullWrapper: PropTypes.bool,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  formComponent: PropTypes.any,
  extraButtons: PropTypes.any,
  handleSubmit: PropTypes.func,
  shouldDisabled: PropTypes.bool,
  shouldContinueEdit: PropTypes.bool,
  extraMenuDropDown: PropTypes.array,
  onSaveAndContinueEdit: PropTypes.func,
  onSaveAndContinueCreate: PropTypes.func,
  customFieldDataSource: PropTypes.array,
  disabledMessage: PropTypes.string,
  newSubmit: PropTypes.func,
};

CreateLayoutForm.defaultProps = {
  loading: false,
  className: "m-3",
};

const mapStateToProps = (state, ownProps) => {
  const { 
    requestName,
    beforeInitialValues, 
    customFieldDataSource,
  } = ownProps;
  let initialValues = {};
  if (beforeInitialValues) {
    initialValues = beforeInitialValues();
  }
  if (customFieldDataSource && customFieldDataSource.length > 0) {
    const customFieldsArrayField = [];
    customFieldDataSource.map((value) => {
      const { id, name } = value;
      customFieldsArrayField.push({
        [`${id}_${name}`]: null,
      });
    });
    initialValues = Object.assign(initialValues, {customFieldsArrayField});
  }
  return {
    loading: state.request[requestName],
    initialValues: {
      ...initialValues
    }
  };
};

export default withReduxForm({
  onSubmit: submit,
}, mapStateToProps)(CreateLayoutForm);